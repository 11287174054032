import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { pickupCancelled } from "../../../modules/orders/pickupCancelled";

const ShipmentCancelModel = ({
  cancelShipment,
  setCancelShipment,
}: any) => {
  const dispatch = useDispatch();
  const [error, setError]=useState("")
 
  useEffect(()=>{
  setError("")
  },[cancelShipment?.visible])

    const handleSubmit =()=>{
      if(cancelShipment.Reason =="")
      {
        setError("*Required!")
      } 
      else{
        dispatch<any>(pickupCancelled(cancelShipment));
        setCancelShipment({ ...cancelShipment, visible: "none" });
      }
        }
  return (
    <div
      className="modal modal-order-popup"
      id="myModal"
      tabIndex={-1}
      role="dialog"
      style={{ display: cancelShipment.visible }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="order-modal">
            <div className="order-modal-head border-none">Cancel Pickup</div>

       

            <div className="form-group spacer-feedback-label">
              <label className="label-modal">Reason for cancellation</label>
              <textarea
                className="form-control buyer-textarea input-modal"
                rows={3}
                maxLength={500}
                placeholder="Write something"
                value={
                  cancelShipment.Reason 
                }
                onChange={(e) =>
                {  setCancelShipment({
                    ...cancelShipment,
                    Reason: e.target.value ,
                  });
                setError("")
                }
                }
              ></textarea>
              <p>
                {cancelShipment.Reason === null
                  ? 0
                  : cancelShipment?.Reason?.length}
                /500
              </p>
              <p style={{color:"red", fontSize:12}}>
                {error && error}
               
              </p>
            </div>

            <div className="modal-btn-group spacer-feedback-btn">
              <button
                className="modal-add-btn modal-cancel-order-btn"
                onClick={() => {
                 handleSubmit()
                }}
               
              >
                Cancel Pickup
              </button>
              <button
                className="modal-cancel-btn"
                onClick={() =>
                  setCancelShipment({
                    ...cancelShipment,
                    visible: "none",
                    Reason: "",
                  })
                }
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipmentCancelModel;
