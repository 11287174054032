import { AppThunk } from "../../store"
import {cartItemsSuccess} from "./slice"

export const setCartItems = (data: any): AppThunk => async (dispatch) => {
    try {
        dispatch(cartItemsSuccess(data));
    } catch (err) {
        
    }
}
