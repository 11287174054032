import { AppThunk } from "../../store"
import {cartItemsSuccess, cartItemsReset} from "./slice"
import { fetchCartCount } from "./api";

export const getCartItems = (): AppThunk => async (dispatch) => {
    try {
        const cartData: any = await fetchCartCount()

        if (cartData.success === "N") {
            dispatch(cartItemsReset());
        }
        else {
            dispatch(cartItemsSuccess(cartData.data));
           
            localStorage.setItem("userCartItem", cartData.data);
        }

    } catch (err) {
        dispatch(cartItemsReset());
    }
}
