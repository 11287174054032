import { AppThunk } from "../../store";
import { Orders } from "../../utils/types";
import { SaveDataReturnFailed, SaveDataReturnSuccess } from "./slice";
import { postCancel, postReturnRequestBuyer, setTracking } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const orderReturnRequestBuyer =
  (data: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch<any>(StartProgress());

      const ordersData: any = await postReturnRequestBuyer(data);
      

      if (ordersData.success === "N") {
        
        dispatch(SaveDataReturnFailed(ordersData));
      } else {
        
        dispatch(SaveDataReturnSuccess(ordersData));
      }

      //return DashboardData
    } catch (err) {
      
      dispatch(SaveDataReturnFailed(err));
      //return err.toString()
    }
    dispatch<any>(EndProgress());
  };
