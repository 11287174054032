import axios from "axios";
import { fetchRefreshToken } from "../modules/userLogin/api";

export default class Api {
  static getBaseURL() {
    return "";
  }

  static async headers() {
    const access_token = localStorage.getItem("authorization");

    if (access_token) {
      return {
        Authorization: "Bearer " + access_token,
        "Content-Type": "application/json",
      };
    } else {
      return {
        "Content-Type": "application/json",
        Authorization: "",
      };
    }
  }

  static get(route: string, params: any) {
    return this.xhr(route, params, "GET");
  }

  static post(route: string, params: any) {
    return this.xhr(route, params, "POST");
  }

  static put(route: string, params: any) {
    return this.xhr(route, params, "PUT");
  }

  static patch(route: string, params: any) {
    return this.xhr(route, params, "PATCH");
  }

  static delete(route: string, params: any) {
    return this.xhr(route, params, "DELETE");
  }

  static async xhr(route: string, params: any, verb: any): Promise<any> {
    const url = Api.getBaseURL() + route;
    const headers = await Api.headers();
    const options = Object.assign(
      { method: verb, headers: headers, url: url },
      params ? { data: params } : null
    );

    return axios(options)
      .then((resp) => {
        return resp.data;
      })
      .catch(async (error) => {

        if (
          error.response.status == 401 &&
          localStorage.getItem("autoLogout") === null &&
          localStorage.getItem("refresh") !== null
        ) {

          const UserData: any = await fetchRefreshToken({
            jwtToken: localStorage.getItem("authorization"),
            refreshToken: localStorage.getItem("refresh"),
          });

          if (UserData.success === "N") {
            localStorage.removeItem("authorization");
            localStorage.removeItem("refresh");
            localStorage.removeItem("error");
            window.location.reload();
          } else {
            localStorage.setItem("authorization", UserData.jwtToken);
            Api.xhr(route, params, verb);
          }
        }
        throw error;
      });
  }
}
