import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Login, Register } from "../../utils/types";

type RegisterState = {
  register: Register["register"] | null;
  error: any | null;
};

const initialState: RegisterState = {
  register: null,
  error: null,
};

const slice = createSlice({
  name: "register",
  initialState,
  reducers: {
    RegisterSuccess: (state, action: PayloadAction<any>) => {
      state.register = action.payload;
      state.error = null;
      
    },
    RegisterFailed: (state, action: PayloadAction<any>) => {
      state.register = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },

    ResetRegister: (state) => {
      state.register = null;
      state.error = null;
    },
  },
});

export const register = slice.reducer;

export const {
  RegisterFailed,
  RegisterSuccess,

  ResetRegister,
} = slice.actions;
