import { AppThunk } from "../../../../store";
import {

  
  ProductReviewFailed,
  ProductReviewSuccess,
} from "./slice";
import { fetchProductReview } from "./api";
import { EndProgress } from "../../../progressBar/endProgress";
import { StartProgress } from "../../../progressBar/startProgress";

export const getProductReview =
  (params:any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
      const data: any = await fetchProductReview(params);
      

      if (data.success === "N") {
        
         dispatch(
          ProductReviewFailed(data));
      
      } else {
        
      dispatch(ProductReviewSuccess(data));
      }
    } catch (err) {
      
     dispatch(ProductReviewFailed(err));
    }
    dispatch<any>(EndProgress());
  };
