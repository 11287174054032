import React, { useEffect, useRef, useState } from "react";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { DefaultLayout } from "../../layout/DefaultLayout";
import HeaderNormal from "../../components/header/HeaderNormal";
import Footer from "../../components/footer/Footer";
import { RootState } from "../../utils/types";
import { accountResponseSelector } from "../../modules/buyer/myEfindit/account/selectors";
import { notify } from "../../modules/notification/notify";
import { constant } from "../../utils/constant";
import { reset } from "../../modules/buyer/myEfindit/account/reset";
import { activateUser } from "../../modules/buyer/myEfindit/account/activateUser";

export const ActivationUser = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let accountResponse = useSelector<RootState, RootState["account"]>(
    accountResponseSelector
  );
  const { state } = useLocation();
  const activationToken: any = state;
  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  
    dispatch<any>(activateUser(activationToken ));
  };
  
  const isFirstRender = React.useRef(true);

  

  useEffect(() => {
    if (isFirstRender.current) {
      dispatch<any>(reset("resetActiveUser"));
    }
    isFirstRender.current=false;
  }, []);
  

  useEffect(() => {
    if (!isFirstRender.current) {

     
 
      if (accountResponse.activateUserError != null) {
        notify(accountResponse.activateUserError, constant.ERROR); 
      dispatch<any>(reset("resetActiveUser"));

      }
     
      
    }
  }, [accountResponse.activateUser, accountResponse.activateUserError]);
 
  return (
    <DefaultLayout>
      <HeaderNormal activation={true} />
      <ToastContainer />

      <section className="login-section min-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="login-container">
                <form className="form-block">
                  <h2 className="h2-head text-center">Account deactivated</h2>

                

                {accountResponse?.activateUser?.success==="Y" ?
                
                <div>         <p
                className=" p-2 pl-2 ml-2 mt-3"
                style={{
                  fontSize: "18px",
                  backgroundColor: "#90EE90",
                }}
              >
              Your activation request sent successfully to Admin. 
              </p>
            
                </div> :
                <>
                  <div className="signin-text text-center">
                  Please click the button below to activate your account
                </div>
                <button
                    type="submit"
                    className="btn btn-primary login-btn form-button"
                    onClick={(e: any) => handleSubmit(e)}
                  >
                    Activate
                  </button>
                  
                </>
                  }
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </DefaultLayout>
  );
};
