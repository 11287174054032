import { AppThunk } from "../../store";
import { DashboardSuccess, DashboardFailed } from "./slice";
import { fetchDashboard } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const getDashboard = (): AppThunk => async (dispatch) => {
  dispatch<any>(StartProgress());
  try {
    const UserData: any = await fetchDashboard();

    if (UserData.success === "N") {
      dispatch(DashboardFailed(UserData));
    } else {
      dispatch(DashboardSuccess(UserData));
    }
  } catch (err) {
    dispatch(DashboardFailed(err));
  }
  dispatch<any>(EndProgress());
};
