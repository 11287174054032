import React, { useEffect, useRef, useState } from 'react'
import { DefaultLayout } from '../../../layout/DefaultLayout'
import BuyerFooter from '../buyerFooter'
import BuyerHeader from '../BuyerHeader'
import CategoryTreeFilter from './CategoryTreeFilter';
import "./store.scss";
import Plus from "../../../assets/images/plus.png"
import Minus from "../../../assets/images/minus.png"
import DefaultImage from"../../../assets/images/defaultImage.png";
import Grid from "../../../assets/images/Shape 1.png"
import HeartIcon from "../../../assets/images/blue-heart.png"
import PhoneIcon from "../../../assets/images/phone-icon.png"
import BlueStar from "../../../assets/images/blueStar.png"

import { buyerProductSelector } from '../../../modules/buyer/product/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../utils/types';

import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { BASE_URLS } from '../../../utils/api.urls';
import { activityResponseSelector } from '../../../modules/buyer/myEfindit/activity/selectors';
import { useNavigate, useParams } from 'react-router-dom';
import { resetDetail } from '../../../modules/buyer/product/resetDetail';
import { getRecentlyViewed } from '../../../modules/buyer/myEfindit/activity/getRecentlyViewed';
import { getProductsSellerStore } from '../../../modules/buyer/product/getProductsSellerStore';
import ReactPaginate from 'react-paginate';
import { CommonResponseSelector } from '../../../modules/common/selectors';
import { getCommonData } from '../../../modules/common/getCommonData';
import { Rating } from 'react-simple-star-rating';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify';

const SellerStorePage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
  const { Id } = useParams();
  const { categoryId } = useParams();


    const isFirstRender2 = useRef(true);
  const isFirstRender = useRef(true);
    const productResponse = useSelector<RootState, RootState["buyerProduct"]>(
        buyerProductSelector
      );
      let activityResponse = useSelector<RootState, RootState["activity"]>(
        activityResponseSelector
      );
      const commonResponse = useSelector<RootState, RootState["common"]>(
        CommonResponseSelector
      );
      const [filter, setFilter] = useState<any>({
        search:  "",
        auction:"",
        fixed: "",
        buyingFormat:"",
        condition: "" ,
        userId: "",
        showItemPriceFrom:""      ,
        minPrice:"",
        maxPrice:"",
        shortBy: "",
        categoryId:"",

            brand:"",
            freeReturns: "",
            returnsAccepted: "",
        soldItems:"",
        dealAndSavings: "",
        completedListings:"",
        endingWithIn: "",
        noOfBidsFrom:"",
        minBiddingNo:"",
        maxBiddingNo:"",
            saleItem: "",
            bestOffer: "",
            freeInternationalShipping: "",
        new1:"",
        used1:"",
        listing:"",
        notSpecified1:"",
      });
    const [subCategory, setSubCategory] = useState<any>();
    const [priceRadio, setPriceRadio] = useState("");
    const [price, setPrice] = useState({
      min: "",
      max: "",
    });
    const [toggleCategories, setToggleCategories] = useState<any>([]);
    const [search, setSearch] = useState("");
  
    const [pageView, setPageView] = useState("0");

    const [pageCount, setPageCount] = useState(0);
    const [itemCount, setItemCount] = useState(16);
    const [currentPage, setcurrentPage] = useState(0);
    const handleRadioClick = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setPriceRadio(value);
        if (value === "five")
          setFilter({
            ...filter,
            showItemPriceFrom: "true",
            maxPrice: 5,
            minPrice: "",
          });
        else if (value === "eight")
          setFilter({
            ...filter,
            showItemPriceFrom: "true",
            minPrice: 5,
            maxPrice: 8,
          });
        else if (value === "overEight")
          setFilter({
            ...filter,
            showItemPriceFrom: "true",
            minPrice: 8,
            maxPrice: "",
          });
      };
const handleCategoryToggle =(id:any)=>{

    let data=[...toggleCategories]
    let index=toggleCategories.findIndex((item:any)=>item.id==id)
    data[index].show=!data[index].show;
    setToggleCategories(data)
}

      const handlePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
    
        setPrice({ ...price, [name]: value });
      };
    

      useEffect(()=>{
        if(productResponse?.productSellerStore)
        {
        let categories:any=[];
        productResponse?.productSellerStore?.data?.categories?.forEach((element)=>{
            categories.push({
                id:element.id,
                show:false
            })
        })
        setToggleCategories(categories)

        }

      },[productResponse?.productSellerStore?.data?.categories])

      const sliderSettings = {
        320: {
          slidesPerView: 2,
        },
        480: {
          slidesPerView: 2,
        },
        576: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 5,
          spaceBetween: 20
        }
      };
    
      const callSearch = () => {
        dispatch<any>(
            getProductsSellerStore({
              currentPage: currentPage + 1,
              rows: itemCount,
              sellerId:Id,
            //   search: filter.search,
              filter: filter,
            })
          ).then(() => {
        window.scrollTo(0, 0);

            if (currentPage === 0) setPageCount(0);
          });
          if (commonResponse?.common == null) dispatch<any>(getCommonData());
    
        dispatch<any>(
          getRecentlyViewed({
            currentPage: 1,
            rows: 10,
          })
        );
      };
      useEffect(() => {
        if (!isFirstRender2.current) {
          callSearch();
        }
        isFirstRender2.current = false;
      }, [currentPage, itemCount, filter]);
    
      useEffect(() => {
        if (isFirstRender.current) {
          callSearch();
        }
        isFirstRender.current = false;
      }, [Id]);
      useEffect(() => {
      if(categoryId)
      {
        setFilter({...filter, categoryId:categoryId})
      }
      }, [categoryId]);
      
      const handlePageChange = (selectedObject: any) => {
        setcurrentPage(selectedObject.selected);
      };
      const handleItemsChange = (event: any) => {
        setItemCount(event.target.value);
        setcurrentPage(0);
      };

      const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setFilter({ ...filter, [name]: checked ? "true" : "" });
      };

      if (productResponse.productSellerStore?.data?.productDetail !== null && pageCount === 0) {
        setPageCount(
          Math.ceil(
            productResponse.productSellerStore?.data?.totalResult / itemCount
          )
        );
      }
 
      useEffect(()=>{
        setcurrentPage(0);
      },[filter])
      
      const handleKeyPress = (event:any) => {
        if (event.key === 'Enter') {
          // Handle the Enter key press here
          setFilter({...filter, search:search})}
          // You can call a function or perform any action
        }
        const handleDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
          setFilter({ ...filter, [e.target.name]: e.target.value });
        };
  return (
 <DefaultLayout>

    <BuyerHeader/>
<ToastContainer/>

    <main className="min-section">
    <div className="container">

      <div className="bestStore">
        <div className="img">
          <img src={   BASE_URLS.AWS_URL +
                                        
                                        productResponse?.productSellerStore?.data?.sellerDetails?.profilePic}   
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src = DefaultImage;
                                        }}
                                        style={{height:"100%", width:"100%" }}
                                        alt="store image" />
        </div>
        <div className="desc">
          <div className="title">
            <h3>{productResponse?.productSellerStore?.data?.sellerDetails?.storeName}</h3>
            <div className="socialLinks">
            { productResponse?.productSellerStore?.data?.sellerSocialMedia?.map((social)=> <a href={social.link} className="msg"> <img src={BASE_URLS.AWS_URL+social.logo}  alt="logo"/> </a>
              )}
            </div>
          </div>
          <div className="profile-contact-panel d-flex">
          <div className="d-flex">
                      <span className="star-img-icon"></span>
                      <span>
                       {productResponse?.productSellerStore?.data?.sellerDetails?.sellerFeedbackPercentage? productResponse?.productSellerStore?.data?.sellerDetails?.sellerFeedbackPercentage : "0"}%      feedback</span>
                      <span className="pipe-span">|</span>
                    </div>
                    <div className="d-flex">
                    <Rating
                                initialValue={productResponse?.productSellerStore?.data?.sellerDetails?.sellerTotalRating}
                                readonly={true}
                               
                                size={16}
                              />
                      <span className="pipe-span">|</span>
                   
                  
                    </div>
                    <div className="d-flex">
                    <img className='mr-2' src={HeartIcon} alt=""/> {productResponse?.productSellerStore?.data?.sellerDetails?.sellerSaved ? " Saved" :null}
                    <span className="pipe-span">|</span>

                      </div>
                      <div className="d-flex">
                      <span style={{cursor:"pointer"}} onClick={() => {
                                   !productResponse?.productSellerStore?.data?.sellerDetails?.isSelfStore &&     navigate(
                                          "/buyer/contactSeller",
                                         {  state: {product:{sellerId:Id}
                                          ,route:`/buyer/sellerStore/${Id}`}},
                                          
                                        );
                                      }}><img src={PhoneIcon} alt=""/> Contact seller</span>


                      </div>
         

          </div>
       
          <div className="txt mt-2">
          {productResponse?.productSellerStore?.data?.sellerDetails?.storeDescription}
          </div>
        </div>
      </div>

      <section className="withFilter">
        <div className="left-filter">
        
          <div className="filters">
          {/* <div className="filterList">
          <p className="filter-main-title">Category</p>
                  <div className="filter-drop open">
                    <ul>
                      <CategoryTreeFilter
                        setSubCategory={setSubCategory}
                      ></CategoryTreeFilter>
                    </ul>
                  </div>
                </div> */}

                <div className="filterList">
                {/* <p className="filter-main-title">Shop by category</p> */}

                <div className="filter-title">
                <strong>Category</strong>
              </div>
 {toggleCategories.length>0 && productResponse.productSellerStore?.data?.categories?.map ((item, Index:number)=>
     {   
        
        let catItem=toggleCategories?.find((cat:any)=>cat.id==item.id)

        return<>  
            
          {item.childs &&  
              <div className="filterExpand">
              <button className="t" onClick={()=>handleCategoryToggle(item.id)}>
              <img src={catItem?.show? Minus: Plus} alt=""/>
              </button>
              </div> }
              <div className="filter-title" style={{cursor:"pointer"}} onClick={()=>setFilter({...filter, categoryId:item.id})} >
                {item.name}
              </div>
          {catItem?.show &&    <div className="filter-drop open">
                <ul>
                    {
                        item.childs?.map((data:any)=>
                  <li><div style={{cursor:"pointer"}} onClick={()=>setFilter({...filter, categoryId:data.id})}>- {data.name}</div></li>
                        
                        )
                    }
                  
                </ul>
              </div>}

              </> }   )}
              
              <div className="filter-title" style={{cursor:"pointer"}} onClick={()=>setFilter({...filter, categoryId:""})}>
                All
              </div>

            </div>
                <div className="filterList">
              <button className="toggleBtn">
                <img src="images/Rounded Rectangle 1 copy 2.png" alt=""/>
              </button>
              <div className="filter-title">
                <strong>Condition</strong>
              </div>
              <div className="filter-drop">
                <ul className="p-0">
                  <li>
                    <div className="custom-check">
                      New
                      <div className="wrap">
                      <input
                        type="checkbox"
                        id="new1"
                        name="new1"
                        onChange={(e:any)=>handleCheckboxClick(e)}
                        checked={filter.new1 === "true" ? true : false}
                      />
                      <label htmlFor="new1"></label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="custom-check">
                    Used
                    <div className="wrap">
                      <input
                        type="checkbox"
                        id="used1"
                        name="used1"
                        onChange={(e:any)=>handleCheckboxClick(e)}
                        checked={filter.used1 === "true" ? true : false}
                      />
                      <label htmlFor="used1"></label>
                    </div>
                    </div>
                  </li>
                  <li>
                    <div className="custom-check">
                    Not specified
                    <div className="wrap">
                      <input
                        type="checkbox"
                        id="notSpecified1"
                        name="notSpecified1"
                        onChange={(e:any)=>handleCheckboxClick(e)}
                        checked={filter.notSpecified1 === "true" ? true : false}
                      />
                      <label htmlFor="notSpecified1"></label>
                    </div>
                    </div>
                  </li>
             
                </ul>
              </div>
            </div>
               
                <div className="filterList">
                  <button className="toggleBtn">
                    <img src="images/Rounded Rectangle 1 copy 2.png" alt="" />
                  </button>
                  <div className="filter-title">
                    <strong>Price</strong>
                  </div>
                  <div className="filter-drop">
                    <ul className="p-0">
                      <li>
                        {/* <div className="custom-check">
                          Under $5.00
                          <div className="wrap">
                            <input
                              type="radio"
                              id="five"
                              name="five"
                              onChange={handleRadioClick}
                            />
                        </div> */}
                        {/* </div> */}
                        <div className="radio">
                          <input
                            id="five"
                            type="radio"
                            value="five"
                            name="five"
                            checked={priceRadio === "five" ? true : false}
                            onChange={(e) => handleRadioClick(e)}
                          />
                          <label htmlFor="five" className="radio-label">
                            Under $5.00
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="radio">
                          <input
                            id="eight"
                            value="eight"
                            name="eight"
                            onChange={(e) => handleRadioClick(e)}
                            type="radio"
                            checked={priceRadio === "eight" ? true : false}
                          />
                          <label htmlFor="eight" className="radio-label">
                            $5.00 to $8.00
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="radio">
                          <input
                            id="overEight"
                            value="overEight"
                            name="overEight"
                            onChange={(e) => handleRadioClick(e)}
                            checked={priceRadio === "overEight" ? true : false}
                            type="radio"
                          />
                          <label htmlFor="overEight" className="radio-label">
                            Over $8.00
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="min-max">
                          <input
                            type="number"
                            min="0"
                            placeholder="min"
                            name="min"
                            value={price.min}
                            onChange={handlePrice}
                          />
                          <span>-</span>
                          <input
                            type="number"
                            min="0"
                            placeholder="max"
                            name="max"
                            value={price.max}
                            onChange={handlePrice}
                          />
                          <button
                            className="go"
                            onClick={() => {
                              setPriceRadio("");
                              setFilter({
                                ...filter,
                                showItemPriceFrom:price.min === "" && price.max === "" ? "false" : "true",
                                minPrice:
                                  price.min && Number(price.min) > 0
                                    ? price.min
                                    : "",
                                maxPrice:
                                  price.max && Number(price.max) > 0
                                    ? price.max
                                    : "",
                              });
                            }}
                            // disabled={price.min === "" && price.max === ""}
                          >
                            go
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
          </div>

        
        </div>

        <div className="right-wrapper">
          <section className="prod-filter">
            <div className="top">
              <div className="row justify-content-between">
                <div className="col-md-auto">
                <div className="tab-links clearfix">
                    <button className={filter.buyingFormat==="" ? "active" :""} onClick={()=>setFilter({ ...filter, buyingFormat: "" })}>All listings</button>
                    {commonResponse?.common?.format?.map((item, Index) => (
                          <button className={filter.buyingFormat==item.value ? "active" :""} onClick={()=>setFilter({ ...filter, buyingFormat: item.value })}  value={item.value} key={item.key}>
                            {item.label}
                          </button>
                        ))}
                  </div>
                </div>
                <div className="col-md-auto ml-auto">
                  <div className="searchBox">
                    <input type="text" value={search} onKeyDown={handleKeyPress} onChange={(e:any)=>setSearch(e.target.value)} placeholder="Search within this store"/>
                    <button className="searchBtn" onClick={()=>setFilter({...filter, search:search})}>Search</button>
                  </div>
                </div>
              </div>
              <div className="border-top pt-3 mt-3">
                <div className="row justify-content-between align-item-center">
                  <div className="col-md-auto">
                    <div className="result-found pt-0 mt-0 border-0">
                      <strong className="fw700">{productResponse?.productSellerStore?.data?.totalResult}</strong>
                      &nbsp;results found
                    </div>
                  </div>
                  <div className="col-md-auto">
                    <div className="row">
                      <div className="col-auto">
                        <div className="sort-select">
                          <label htmlFor="">Sort</label>
                          <div className="selectBox">
                          <select name="shortBy" id="" value={filter.shortBy} onChange={(e)=>handleDropdownChange(e)}>
                                <option value="">Best Match</option>
                                <option value="LowestPrice">Lowest Price</option>
                                <option value="HighestPrice">Highest Price</option>
                                <option value="NewlyListed">Newly Listed</option>
                                <option value="BidsFewestFirst">Number of bids:fewest first</option>
                                <option value="BidsMostFirst">Number of bids:most first</option>


                               
                              </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto">
                        <div className="sort-select view-select">
                          <label htmlFor="">View</label>
                          <div className={`selectBox${pageView=="0" ?" css-list":""}`}>
                            <select name="" id="" value={pageView} onChange={(e:any)=>setPageView(e.target.value)}>
                              <option value="0" >list</option>
                              <option value="1" >grid</option>

                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        
            </div>
          </section>

        {  <section className="deals-group pt-2">
            <div className={`deal-group-list  ${pageView=="0" ? "one-col" : "four-col"}`}>
              <div className="wrap">
               { productResponse?.productSellerStore?.data?.productDetail?.map((product)=>
                <div className="recent-list-item"   
                 
               
                  
                >
                  <i className="timer-icon"></i>
                  <i className="blue-star"></i>
                  <figure className="recent-media"  onClick={() =>
                  dispatch<any>(
                    resetDetail()).then(()=>navigate(`/buyer/productDetail/${product.productId}`))         
                  
                }>
                    <div style={{cursor:"pointer"}}><img src={   BASE_URLS.AWS_URL + "/Thumb"+
                                        
                                        product.defaultImage}   
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src = DefaultImage;
                                        }}
                                        alt="recently"
                                        /></div>
                  </figure>
                  <div className="recent-details">
                    <div className="recent-title">{product.title}</div>
                     
                    <div className="fi-stars">
                    <Rating
                                initialValue={product.productRating}
                                readonly={true}
                                // allowFraction={true}
                                /* Available Props */
                                size={16}
                              />
                    </div>
                    <div className="recent-price">US ${product?.buyNowPrice ? product.buyNowPrice :product.actualPrice}</div>
                  { product.actualPrice && <div className="recent-price-small">
                      <span className="price-cut"><del>US ${product.actualPrice}</del></span>
                      <span>|</span>
                      <span className="price-bold">{product.offer ? product.offer : "0"}% OFF</span>
                    </div>}
                    {/* <div className="fi-ship-status small">${product?.shippingCost ? product.shippingCost : "0"} Shipping</div> */}
                   { 
                   product?.quantity >0 &&
                   <div className="fi-stock-status small">{product?.quantity<5 && `Only ${product.quantity} left`}</div>
              } 
                 { 
                   product?.quantity == null &&
                   <div className="fi-stock-status small">Out of stock</div>
              } 
              <p className="fi-stock-status small">     {product.sold
                                ? product.sold +" Sold"
                                : 
                                "0 Sold"
                                }</p> 
                 </div>
                </div>)}
                { productResponse?.productSellerStore?.data?.productDetail== null && (
                      <h3>No Results Found</h3>
                    )}
              </div>
  
              <nav className="pagination-block">
                    <p className="total">
                      {productResponse?.productSellerStore?.data?.totalResult}{" "}
                      items total
                    </p>

                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      forcePage={currentPage}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "pagination justify-content-center mt-4"
                      }
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                    <div className="customSelect">
                      <label htmlFor="">Items per page :</label>
                      <div className="selectBox">
                        <select name="" id="" onChange={handleItemsChange}>
                        <option value="16">16</option>
                            <option value="24">24</option>

                            <option value="32">32</option>
                        </select>
                      </div>
                    </div>
                  </nav>
            </div>
          </section>
}
         { activityResponse?.recentlyViewed?.data?.products && <section className="recently-view-section mb-5">
                <div className="h2-block pt-0">
                  <h2 className="h2-section-title">Recently View</h2>
                </div>
                <div className="recent-view-container">
                  <div className="prev-slide">
                    <i className="prev-icon"></i>
                  </div>
                  <div className="next-slide">
                    <i className="next-icon"></i>
                  </div>

                  <div className="recent-listing-block">
                  <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={20}
                        slidesPerView={5}
                        breakpoints={sliderSettings}
                        navigation={{
                          nextEl: '.next-slide',
                          prevEl: '.prev-slide',
                        }}
                        pagination={{ clickable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log("slide change")}
                      >
                        {activityResponse?.recentlyViewed?.data?.products?.map(
                          (item, Index) => (
                            <SwiperSlide
                              className="recentListSwiper"
                              onClick={() =>
                                dispatch<any>(resetDetail()).then(()=>navigate(`/buyer/productDetail/${item.productId}/${item.variantId}`))
                              }
                            >
                              <div className="recent-list-item">
                                <figure className="recent-media">
                                  <a href="#">
                                    <img
                                      src={
                                        BASE_URLS.AWS_URL +
                                        "/Thumb" +
                                        item.defaultImage
                                      }
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = DefaultImage;
                                      }}
                                      alt="recently"
                                    />
                                  </a>
                                </figure>
                                <div className="recent-details">
                                  <div className="recent-price">
                                   US $
                                    {item.buyNowPrice
                                      ? item.buyNowPrice
                                      : item.actualPrice}
                                  </div>
                                  {item.buyNowPrice && (
                                    <div className="recent-price-small">
                                      <span className="price-cut">
                                      <del>US ${item.actualPrice}</del>
                                      </span>
                                      <span>|</span>
                                      <span className="price-bold">
                                        {item.offer}% OFF
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </SwiperSlide>
                          )
                        )}
                      </Swiper>
                  </div>
                </div>
              </section>       } 
        </div>
      </section>
    </div>

  </main>
    <BuyerFooter/>
 </DefaultLayout>
  )
}

export default SellerStorePage