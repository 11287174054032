import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../../components/header/Header";
import { DefaultLayout } from "../../../layout/DefaultLayout";
import { dateToLocal } from "../../../modules/date/date";
import { getOrders } from "../../../modules/orders/getOrders";
import { SellerOrdersResponseSelector } from "../../../modules/orders/selectors";
import { RootState } from "../../../utils/types";
import ReactPaginate from "react-paginate";
import { BASE_URLS } from "../../../utils/api.urls";
import { getCommonData } from "../../../modules/common/getCommonData";
import { CommonResponseSelector } from "../../../modules/common/selectors";
import { getBuyerNames } from "../../../modules/orders/getBuyerNames";
import { setTrackingManually } from "../../../modules/orders/setTrackingManually";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notify } from "../../../modules/notification/notify";
import { constant } from "../../../utils/constant";
import { resetOrder } from "../../../modules/orders/resetOrder";
import { SellerProductResponseSelector } from "../../../modules/sellerProduct/selector";
import { getActiveListing } from "../../../modules/sellerProduct/getActiveListing";
import { reset } from "../../../modules/sellerProduct/reset";
import { resetCategorySuccess } from "../../../modules/categories/slice";
import ProdNavPanel from "../listing/ProdNavPanel";
import ProductFilter from "../listing/ProductFilter";
import { CategoryResponseSelector } from "../../../modules/categories/selector";
import { getSellerCategories } from "../../../modules/categories/getSellerCategories";
import { changeProductStatus } from "../../../modules/sellerProduct/changeProductStatus";
import SellerBreadCrumbs from "../../../components/sellerBreadCrumbs/SellerBreadCrumbs";
import Footer from "../../../components/footer/Footer";
import HeaderNavigationSeller from "../HeaderNavigationSeller";
import BidsModal from "./BidsModal";
import { getBidsSeller } from "../../../modules/sellerProduct/getBidsSeller";
// import OffersModal from "./OffersModal";

const BidsSeller = () => {
  const product = useSelector<RootState, RootState["sellerProduct"]>(
    SellerProductResponseSelector
  );
  const commonResponse = useSelector<RootState, RootState["common"]>(
    CommonResponseSelector
  );
  const categoryResponse = useSelector<RootState, RootState["categories"]>(
    CategoryResponseSelector
  );
  const navigate = useNavigate();
  const [bidsModal, setBidsModal] = useState({
    visible:"none",
    productId:"",
    image:"",
    title:"",
    basePrice:"",
    maxBids:"",
    totalBids:""

  });
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(10);
  const [currentPage, setcurrentPage] = useState(0);
  const [filter, setFilter] = useState({
    CategoryId: "",
    Search: "",
    Format: "",
    StartDate: "",
    EndDate: "",
  });
  const isFirstRender = useRef(true);
  const isFirstRender2 = useRef(true);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  if (product?.bids?.data?.bids !== null && pageCount === 0) {
    setPageCount(Math.ceil(product?.bids?.data?.totalResult / itemCount));
  }
  useEffect(() => {
    if (isFirstRender.current) {
      dispatch<any>(
        getBidsSeller({
          currentPage: currentPage + 1,
          rows: itemCount,
          CategoryId: filter.CategoryId,
          Search: filter.Search,
          Format: filter.Format,
          StartDate: filter.StartDate,
          EndDate: filter.EndDate,
        })
      ).then(() => {
        if (currentPage === 0) setPageCount(0);
      });
    }
    isFirstRender.current = false;

    if (commonResponse?.common === null) dispatch<any>(getCommonData());
    dispatch<any>(getSellerCategories());
  }, []);

  useEffect(() => {
    if (!isFirstRender2.current) {
      dispatch<any>(
        getBidsSeller({
          currentPage: currentPage + 1,
          rows: itemCount,
          CategoryId: filter.CategoryId,
          Search: filter.Search,
          Format: filter.Format,
          StartDate: filter.StartDate,
          EndDate: filter.EndDate,
        })
      ).then(() => {
        if (currentPage === 0) setPageCount(0);
      });
    }
    isFirstRender2.current = false;
  }, [currentPage, itemCount, filter]);

  useEffect(() => {
    if (product.statusChange != null) {
      if (product.statusChange.success == "Y") {
        notify(product.statusChange.message, constant.SUCCESS);
        dispatch<any>(
          getBidsSeller({
            currentPage: currentPage + 1,
            rows: itemCount,
            CategoryId: filter.CategoryId,
            Search: filter.Search,
            Format: filter.Format,
            StartDate: filter.StartDate,
            EndDate: filter.EndDate,
          })
        ).then(() => {
          if (currentPage === 0) setPageCount(0);
        });
      } else if (product.statusChange.success == "N") {
        notify(product.statusChange.message, constant.ERROR);
      }
    }
    if (product.statusChangeError !== null) {
      notify(product.statusChangeError, constant.ERROR);
    }
    dispatch<any>(reset("saveData"));
  }, [product.statusChange, product.statusChangeError]);

  const handlePageChange = (selectedObject: any) => {
    setcurrentPage(selectedObject.selected);
  };

  const handleItemsChange = (event: any) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };
  const handleFilterChange = (event: any) => {
    setcurrentPage(0);
    if (event.target.name === "Search")
      setFilter({ ...filter, Search: search });
    else setFilter({ ...filter, [event.target.name]: event.target.value });
  };
  const resetFilter = () => {
    setcurrentPage(0);
    setFilter({
      CategoryId: "",
      Search: "",
      Format: "",
      StartDate: "",
      EndDate: "",
    });
    setSearch("");
  };
  
  const closeModal=()=>{
    setBidsModal({  visible:"none",
    productId:"",
    image:"",
    title:"",
    basePrice:"",
    maxBids:"",
    totalBids:""});
  }

  return (
    <DefaultLayout>
      <Header />
     { bidsModal?.visible==="block" && <BidsModal  bidsModal={bidsModal} closeModal={closeModal}/>}
      <ToastContainer />

    
      <section className="seller-breadcrumb-block">
        <div className="container">
          <ul>
            <li className="text">Listing</li>
            <li className="pipe">|</li>
            <li className="text-link">
              <a href="#">{localStorage.getItem("UserName")}</a>
            </li>
          </ul>
        </div>
      </section>

     <HeaderNavigationSeller
     currentPage="Bids"
     />

   
      <main className="seller-page-section">
        <div className="container seller-orders-section">
          <div className="row">
          <div className="col-md-2">
      <div className="leftNavBar">
        <ul>
          <li>
            <Link
              style={
              { background: "#d7d7d7", fontWeight: "450", color: "#000" }
                 
              }
              to={"/seller/bids"}
            >
              All bids
            </Link>
          </li>
          
          
        </ul>

     
      </div>
    </div>
            <div className="col-md-10">
              <div className="product-listing-status-block d-flex">
                <div className="prod-grid-block fixed">
                  <label>Fixed Price Product</label>
                  <div>${product?.bids?.data?.fixedPriceProduct ? product?.bids?.data?.fixedPriceProduct  :"0"}</div>
                </div>
                <div className="prod-grid-block bid">
                  <label>Product with bid</label>
                  <div>${product?.bids?.data?.productWithBid ?product?.bids?.data?.productWithBid :"0" }</div>
                </div>
                <div className="prod-grid-block quantity">
                  <label>Active Product / Quantity</label>
                  <div>
                    {product?.bids?.data?.activeProduct}/
                    {product?.bids?.data?.quantity}
                  </div>
                </div>
                <div className="prod-grid-block stock">
                  <label>Out of stock</label>
                  <div>{product?.bids?.data?.outOfStock}</div>
                </div>
              </div>
              <ProductFilter
                setFilter={setFilter}
                filter={filter}
                common={commonResponse}
                categories={categoryResponse}
                handleFilterChange={handleFilterChange}
                setSearch={setSearch}
                search={search}
                resetFilter={resetFilter}
                type="Offers"
              />
              <div className="filter-result mt-3">
                <div className="total-result d-flex justify-content-between mb-4">
                  <h5>Results: {product?.bids?.data?.totalResult}</h5>
                  <ul className="d-flex">
                    {/* <li>
                      <a href="#">Print</a>
                    </li>
                    <li className="pipe">|</li>
                    <li>
                      <a href="#">Download reports</a>
                    </li> */}
                  </ul>
                </div>

                <div className="result-table-wrapper">
                  <table className="result-table">
                    <thead>
                      <tr>
                        <th className="">Action</th>
                        <th className="">Status</th>

                        <th className="">Photo</th>
                        <th>Title</th>

                        {/* <th>Custom Label(SKU)</th> */}
                        <th>Base price</th>
                        <th>Quantity available</th>
                        <th>Bids</th>
                      </tr>
                    </thead>
                    <tbody>
                      {product?.bids?.data?.bids?.map(
                        (order, Index: number) => (
                          <tr>
                            <td className="action">
                            <div  className="d-flex justify-content-start">
                            <div className="mr-4">  <button
                                type="button"
                                className="btn btn-light"
                                style={{backgroundColor:"#D3D3D3"}}
                                onClick={() =>
                              
                                  dispatch<any>(reset("bidsUsers")).then (()=> setBidsModal({...bidsModal,image:order.productImage, basePrice:order.actualPrice.toString() , visible:"block", productId: order.productId , title:order?.productName , totalBids:order.bids.toString(), maxBids:order.maxBid.toString()}))
                                  
                                }
                              >
                               View bids
                              </button>
                              </div>
                             {/* <div><button
                                type="button"
                                className={`${
                                  order.active == "Y"
                                    ? "btn btn-danger"
                                    : "btn btn-success"
                                }`}
                                onClick={() =>
                                  dispatch<any>(
                                    changeProductStatus({
                                      Id: order.productId,
                                      Active: order.active === "Y" ? "N" : "Y",
                                    })
                                  )
                                }
                              >
                                {order.active == "Y"
                                  ? "Deactivate"
                                  : "Activate"}
                              </button>
                              </div> */}
                          
                              </div>
                            </td>
                            <td
                              style={{
                                color: order.active == "Y" ? "green" : "red",
                              }}
                            >
                              {order.active == "Y"
                                ? "Activated"
                                : "Deactivated"}
                            </td>
                            <td className="product-information">
                              <figure
                                style={{
                                  height: 60,
                                  width: 60,
                                }}
                              >
                                <img
                                  src={
                                    BASE_URLS.AWS_URL +
                                    "/Thumb" +
                                    order.productImage
                                  }
                                  alt="dummy image"
                                  style={{
                                    height: 50,
                                    width: 50,
                                  }}
                                />
                              </figure>
                            </td>
                            <td className="">
                              <div
                                className="product-detail"
                             
                               
                              >
                                <h6>{order.productName}</h6>
                              </div>
                            </td>

                            {/* <td className="sold-for">check</td> */}
                            <td className="total"> ${order.actualPrice}</td>
                            <td className="total">{order.quantity}</td>
                            <td className="total">
                            {`$ ${order?.maxBid} | ${order?.bids} Bids`}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>

                <nav className="pagination-block ">
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    forcePage={currentPage}
                    onPageChange={handlePageChange}
                    containerClassName={
                      "pagination justify-content-center mt-4"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />

                  <div className="total-items-block">
                    <label>Items per page:</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={handleItemsChange}
                      value={itemCount}
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      { bidsModal.visible==="block"  &&   <div className="modal-backdrop show"></div>}
    </DefaultLayout>
  );
};

export default BidsSeller;
