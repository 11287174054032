import { AppThunk } from "../../../store";
import { ProductStoreFailed, ProductStoreSuccess } from "./slice";
import { fetchProductsSellerStore, fetchSearchResult } from "./api";
import { EndProgress } from "../../progressBar/endProgress";
import { StartProgress } from "../../progressBar/startProgress";

export const getProductsSellerStore =
  (params: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());

    try {
      const data: any = await fetchProductsSellerStore(params);
      

      if (data.success === "N") {
        
        dispatch(ProductStoreFailed(data));
      } else {
        
        dispatch(ProductStoreSuccess(data));
      }
    } catch (err) {
      
      dispatch(ProductStoreFailed(err));
    }
    dispatch<any>(EndProgress());
  };
