import React from 'react'
import Messages from './messages'

const Inbox = () => {
  return (
   <Messages nav="inbox"/>
  )
}

export default Inbox
