import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import { getUserLogin } from "../../modules/userLogin/getUserLogin";
// import { UserLoginResponseSelector } from "../../modules/userLogin/selectors";
import { RootState } from "../../utils/types";
// import * as Yup from "yup";
import DefaultImage from "../../assets/images/defaultImage.png";
import "react-toastify/dist/ReactToastify.css";

// import {
//   LoginSocialFacebook,
//   LoginSocialApple,
//   IResolveParams,
// } from "reactjs-social-login";
// import { GoogleLogin } from "react-google-login";
// import { gapi } from "gapi-script";
// import { getUserLoginSocial } from "../../modules/userLogin/getUserLoginSocial";
// import { setSocialDetails } from "../../modules/userLogin/setSocialDetails";
import { DefaultLayout } from "../../layout/DefaultLayout";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
// import layerOne from "../../../src/assets/images/Layer 1.jpg";
import { homepageSelector } from "../../modules/buyer/homepage/selectors";
import BuyerHeader from "../buyer/BuyerHeader";
import { BASE_URLS } from "../../utils/api.urls";
import { getRecentlyViewed } from "../../modules/buyer/myEfindit/activity/getRecentlyViewed";
import { activityResponseSelector } from "../../modules/buyer/myEfindit/activity/selectors";
import BuyerFooter from "../buyer/buyerFooter";
import { resetDetail } from "../../modules/buyer/product/resetDetail";
import { ToastContainer } from "react-toastify";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {getWebPImage, imageOnError} from "../../utils/helpers";
import { RecentViewLoading } from "./recentlyViewLoading";
import { FindLoading } from "./findLoading";
import { constant } from "../../utils/constant";

export const Home = (props: any) => {
  const homepageResponse = useSelector<RootState, RootState["homepage"]>(homepageSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [bannerLoad, setBannerLoad] = useState(false);

  let activityResponse = useSelector<RootState, RootState["activity"]>(activityResponseSelector);

  const sliderSettings = {
    320: { slidesPerView: 2, },
    480: { slidesPerView: 2, },
    576: { slidesPerView: 3, },
    1200:{ 
           slidesPerView: 5, 
           spaceBetween: 20 
         }
  };

  const sliderFindSettings = {
    320: { slidesPerView: 1, },
    480: { slidesPerView: 1, },
    576: { slidesPerView: 3, },
    1200:{
           slidesPerView: 5,
           spaceBetween: 20
         }
  };

  useEffect(() => {
    dispatch<any>(getRecentlyViewed({currentPage: 1,rows: 10,}));
  }, []);

  // HANDLE DUPLICATE IN RECENTLY VIEW:------------
  // const seenIds = new Set();

  // const uniqueItems = activityResponse?.recentlyViewed?.data?.products.filter(item => {
  //     if (seenIds.has(item.productId)) {
  //         return false; // Duplicate found, don't include
  //     } else {
  //         seenIds.add(item.productId); // Mark ID as seen
  //         return true; // Include the item
  //     }
  // });

//---------------------------------------------------------------------

  return (
    <DefaultLayout>
      <BuyerHeader  currentPage="home"/>
      <ToastContainer/>
      <main className="min-section">
        <section className="banner-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {homepageResponse?.homepage?.data?.buyerHomePageBanner ? "": (<div className="banner-box loading-animate"></div>)} 
                <Swiper
                  // install Swiper modules
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  spaceBetween={50}
                  slidesPerView={1}
                  navigation
                  pagination={{ clickable: true }}
                  onSwiper={(swiper) => console.log(swiper)}
                  onSlideChange={() => console.log("slide change")}
                >
                {homepageResponse?.homepage?.data?.buyerHomePageBanner?.map(
                  (banner, Index) => (
                    <SwiperSlide key={Index}
                        className="banner intro-slide"
                        style={{
                          backgroundImage: `url(${
                            BASE_URLS.AWS_URL + banner.desktopBannerImg
                          })`,
                        }}
                    >
                    </SwiperSlide>
                  ))
                }
                </Swiper>
              </div>
            </div>
          </div>
        </section>

        {homepageResponse?.homepage?.data?.buyerHomePageDealProduct ? 
          (
            <section className="deals-section">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="deals-container">
                      <div className="deals-listing-block">
                        <div className="swiper-container swiper dealSwiper">
                          <h2 className="h2-section-title">Deals of the Day</h2>
                          <div className="deal-group-list">
                            <div className="wrap">
                              <Swiper
                                // install Swiper modules
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                spaceBetween={20}
                                slidesPerView={4}
                                navigation
                                pagination={{ clickable: true }}
                                onSwiper={(swiper) => console.log(swiper)}
                                onSlideChange={() => console.log("slide change")}
                              >
                                {homepageResponse?.homepage?.data?.buyerHomePageDealProduct?.map(
                                  (product, Index: number) => 
                                    (
                                      <SwiperSlide key={Index} onClick={() => navigate(`/buyer/productDetail/${product.productId}`) }>
                                        <div className="recent-list-item" onClick={() => dispatch<any>(resetDetail()).then(()=> navigate( `/buyer/productDetail/${product.productId}`))}>
                                          <i className="timer-icon"></i>
                                          <figure className="recent-media">
                                            <img src={ BASE_URLS.AWS_URL + "/Thumb" + product.defaultImage } alt=""/>
                                          </figure>
                                          <div className="recent-details">
                                            <div className="recent-title">{product?.title.length>40 ?product.title.substring(0,40 )+"..." : product?.title}</div>
                                            <div className="recent-price">$ {product.buyNowPrice ? product.buyNowPrice : product.actualPrice}</div>
                                            <div className="recent-price-small">
                                              <span className="price-cut">US ${product.actualPrice}</span>
                                              <span>|</span>
                                              <span className="price-bold">{product.salesDiscount}% OFF</span>
                                              { product?.isShippingFree &&  <div className="fi-ship-status small">Free shipping</div>}
                                              { product?.quantity < constant?.ALMOST_GONE_QUANTITY &&   <div className="fi-stock-status small">Almost Gone</div>}
                                            </div>
                                          </div>
                                        </div>
                                      </SwiperSlide>
                                    )
                                  )
                                }
                              </Swiper>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : null
        }

        {localStorage.getItem("authorization") !== null && localStorage.getItem("role") === "Customer" && activityResponse?.recentlyViewed?.data? (
          <section className="recently-view-section">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="h2-block"><h2 className="h2-section-title">Recently View</h2></div>
                  <div className="recent-view-container">
                    <div className="prev-slide"><i className="prev-icon"></i></div>
                    <div className="next-slide"><i className="next-icon"></i></div>
                    <div className="recent-listing-block">
                      {activityResponse?.recentlyViewed?.data?.products? "" : (<RecentViewLoading/>)}
                      <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={50}
                        slidesPerView={5}
                        breakpoints={sliderSettings}
                        navigation={{ nextEl: '.next-slide', prevEl: '.prev-slide', }}
                        pagination={{ clickable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log("slide change")}
                      >
                        {activityResponse?.recentlyViewed?.data?.products?.map(
                          (item, Index) => 
                            (
                              <SwiperSlide key={Index} className="recentListSwiper" onClick={() => dispatch<any>(resetDetail()).then(()=>navigate(`/buyer/productDetail/${item.productId}/${item.variantId}`))}>
                                <div className="recent-list-item">
                                  <figure className="recent-media">
                                    <picture>
                                      <source srcSet={getWebPImage(item.defaultImage)} type="image/webp"/>
                                      <LazyLoadImage src={BASE_URLS.AWS_URL + "/Thumb" + item.defaultImage} onError={imageOnError} />
                                    </picture>  
                                  </figure>
                                  <div className="recent-details">
                                    <div className="recent-title">
                                      {item.title ? item.title.substring(0, 40) : 'N/A'}
                                      {item.title ? item.title.length > 40 && "..." : 'N/A'}
                                    </div>
                                    <div className="recent-price">${item.buyNowPrice ? item.buyNowPrice : (item.actualPrice ? item.actualPrice : 0)}</div>
                                    {/* {item.actualPrice && ( */}
                                      <div className="recent-price-small">
                                        <span className="price-cut"><del>US ${item.actualPrice ? item.actualPrice : 0}</del></span>
                                        <span> | </span>
                                        <span className="price-bold">{item.offer ? item.offer : "0"}% OFF</span>
                                      </div>
                                    {/* )} */}
                                  </div>
                                </div>
                              </SwiperSlide>
                            )
                          )
                        }
                      </Swiper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}

       {homepageResponse?.homepage?.data?.findWhatYouNeedCategory? (                               
        <section className="find-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="h2-block">
                  <h2 className="h2-section-title">Find what you need</h2>
                </div>
                <div className="find-block">
                <Swiper
                  // install Swiper modules
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  spaceBetween={20}
                  slidesPerView={6}
                  breakpoints={sliderFindSettings}
                  navigation
                  onSwiper={(swiper) => console.log(swiper)}
                  className="findSwiper"
                  onSlideChange={() => console.log("slide change")}
                >
                  {homepageResponse?.homepage?.data?.findWhatYouNeedCategory?.map(
                    (category, Index) => (
                      <SwiperSlide key={Index} className="recentListSwiper" onClick={() => navigate(`/buyer/productCategory/${category.categoryId}/${category.categoryName}`)}>
                        <div>
                          <div className="category-list">
                            <figure className="category-media">
                              <a href="#">
                                <LazyLoadImage
                                  src={  BASE_URLS.AWS_URL +category?.image}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = DefaultImage;
                                  }}
                                  style={{width:244, height:207}}
                                />
                              </a>
                            </figure>
                          </div>
                          <div className="category-content">
                            <h4 className="category-name">
                              <a href="#">{category.categoryName}</a>
                            </h4>
                          </div>
                        </div>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
                </div>
              </div>
            </div>
          </div>
        </section>
       ): 
      <FindLoading/>}

        {/* <section className="find-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="h2-block">
                  <h2 className="h2-section-title">Top Rated</h2>
                </div>
              </div>
            </div>
          </div>
        </section> */}

      </main>
      <BuyerFooter />
    </DefaultLayout>
  );
};
