import Api from "../../utils/api";
import { API_URLS } from "../../utils/api.urls";
const d = new Date();
let TimeZone = d.getTimezoneOffset() * -1;
export const fetchSales = async (): Promise<any> => {
  const data = await Api.get(
    API_URLS.DASHBOARD_SALES + "?timeZone=" + TimeZone,
    []
  );
  return data;
};
