
const getProductAllVar = (pinfo:any) => {
    let newData:any = []

    pinfo.map((item:any) => {
        
        JSON.parse(item.variant).map((attr:any) => { 
         newData.push(attr)
        })
    })
    return newData;

}

export const getVariantWithOptions = (pinfo:any) => {
    let variant = getProductAllVar(pinfo);

        //convert the object into an object of key (state) and value (names)
            variant = variant.reduce(function(results:any, senator:any){
            if (!results[senator.AttName]) results[senator.AttName] = [];
            

            results[ senator.AttName ].push(senator.Option);
            return results;
        }, {});

        //convert the object into an uniquearray of objects
        variant = Object.keys(variant).map(function(state){
            return { AttName: state, Option: variant[state].filter((x:any, i:any, a:any) => a.indexOf(x) === i) };
        });

        return variant;
}

const contains = (arr:any, obj:any) => {
    let i = arr.length;
    while (i--) {
       if (JSON.stringify(arr[i]) === JSON.stringify(obj)) {
           return true;
       }
    }
    return false;
  }
  
 export const isEqual = (obj1:any, obj2:any) => {
    let n = 0
    if (obj1.length !== obj2.length) {
        return false;
    }
    for (let i = 0; i < obj1.length; i++) {
        if (contains(obj2, obj1[i])) {
          n++
        }
    }
    return n === obj1.length
  }
 

