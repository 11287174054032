import { AppThunk } from "../../store";
import { SaveStoreSuccess, SaveStoreFailed } from "./slice";
import { setStore } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const saveStore =
  (data: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
      const UserData: any = await setStore(data);

      if (UserData.success === "N") {
        dispatch(SaveStoreFailed(UserData));
      } else {
        dispatch(SaveStoreSuccess(UserData));
      }
    } catch (err) {
      dispatch(SaveStoreFailed(err));
    }
    dispatch<any>(EndProgress());
  };
