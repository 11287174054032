import { AppThunk } from "../../store"
import {
  WebsiteSingleContentFailed,
  WebsiteSingleContentSuccess
} from "./slice"
import { fetchSinglePage } from "./api"
import { EndProgress } from "../progressBar/endProgress";
import { StartProgress } from "../progressBar/startProgress";


export const getSingleWebPage = (data: any): AppThunk => async (dispatch) => {

  dispatch<any>(StartProgress());
  try {

    const webPageData: any = await fetchSinglePage(data)

    if (webPageData.value?.length===0) {
      dispatch(WebsiteSingleContentFailed(webPageData));
    }
    else {
      dispatch(WebsiteSingleContentSuccess(webPageData));
    }

  } catch (err) {
    dispatch(WebsiteSingleContentFailed(err));
  }
  dispatch<any>(EndProgress());
}