import { AppThunk } from "../../../../store"
import {
    ProfileSuccess,
    ProfileFailed
} from "./slice"
import { fetchProfile } from "./api";
import { EndProgress } from "../../../progressBar/endProgress";
import { StartProgress } from "../../../progressBar/startProgress";


export const getBuyerProfile = (): AppThunk => async (dispatch) => {

    dispatch<any>(StartProgress());
    try {

        const profileData: any = await fetchProfile()

        if (profileData.success === "N") {
            
            dispatch(ProfileFailed(profileData));
        }
        else {
           
            dispatch(ProfileSuccess(profileData));
        }

    } catch (err) {
        
        dispatch(ProfileFailed(err));
    }
    dispatch<any>(EndProgress());
}