import { AppThunk } from "../../../../store"
import {
    DeleteSavedPaymentsSuccess,
    DeleteSavedPaymentsFailed
} from "./slice"
import { deleteSavedPayments, fetchProfile, fetchSavedPayments } from "./api";
import { EndProgress } from "../../../progressBar/endProgress";
import { StartProgress } from "../../../progressBar/startProgress";


export const removeSavedPayments = (params:any): AppThunk => async (dispatch) => {

    dispatch<any>(StartProgress());
    try {

        const data: any = await deleteSavedPayments(params)
        

        if (data.success === "N") {
            
            dispatch(DeleteSavedPaymentsFailed(data));
        }
        else {
           
            dispatch(DeleteSavedPaymentsSuccess(data));
        }

    } catch (err) {
        
        dispatch(DeleteSavedPaymentsFailed(err));
    }
    dispatch<any>(EndProgress());
}