import { AppThunk } from "../../../store";
import { SaveForLaterFailed, SaveForLaterSuccess } from "./slice";
import { getSaveForCart, postToCart, removeFromCart, saveForCart } from "./api";
import { EndProgress } from "../../progressBar/endProgress";
import { StartProgress } from "../../progressBar/startProgress";

export const getSaveForLaterCart =
  (): AppThunk =>
  async (dispatch) => {
    try {
      dispatch<any>(StartProgress());

      const data: any = await getSaveForCart();
      

      if (data.success === "N") {
        
        dispatch(SaveForLaterFailed(data));
      } else {
        
        dispatch(SaveForLaterSuccess(data));
      }
    } catch (err) {
      
      dispatch(SaveForLaterFailed(err));
    }
    dispatch<any>(EndProgress());
  };
