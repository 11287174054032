import Api from "../../utils/api";
import { API_URLS } from "../../utils/api.urls";

export const fetchUserLoginData = async (params: any | null): Promise<any> => {
  const data = await Api.post(API_URLS.USER_LOGIN, params);
  return data;
};

export const fetchUserLoginDataSocial = async (
  params: any | null
): Promise<any> => {
  const data = await Api.post(API_URLS.USER_LOGIN + "/Social", params);
  return data;
};
export const fetchRefreshToken = async (params: any | null): Promise<any> => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(params),
  };
  const response = await fetch(API_URLS.GET_REFRESH_TOKEN, requestOptions);
  const data = await response.json();
  return data;
};

export const deleteRefreshToken = async (params: any | null): Promise<any> => {
  const data = await Api.delete(API_URLS.DELETE_REFRESH_TOKEN, params);
  return data;
};

export const sendVerificationEmail = async (params: any | null): Promise<any> => {
  const data = await Api.post(API_URLS.GET_VERIFICATION_LINK, params);
  return data;
};