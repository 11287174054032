import { AppThunk } from "../../store"
import {
  SaveListingDetailsSuccess,
  SaveListingDetailsFailed
} from "./slice"
import { postListingDetails } from "./api"
import { EndProgress } from "../progressBar/endProgress";
import { StartProgress } from "../progressBar/startProgress";


export const saveListingDetails = (data: any): AppThunk => async (dispatch) => {

  dispatch<any>(StartProgress());
  try {

    const listingData: any = await postListingDetails(data)
    

    if (listingData.success === "N") {
      
      dispatch(SaveListingDetailsFailed(listingData));
    }
    else {
     
      dispatch(SaveListingDetailsSuccess(listingData));
    }

  } catch (err) {
    
    dispatch(SaveListingDetailsFailed(err));
  }

  dispatch<any>(EndProgress());
}