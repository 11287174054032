import React, { useEffect, useRef, useState } from "react";
import HeaderNormal from "../../../../components/header/HeaderNormal";
import FooterNormal from "../../../../components/footer/FooterNormal";
import { DefaultLayout } from "../../../../layout/DefaultLayout";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../utils/types";
import { CommonResponseSelector } from "../../../../modules/common/selectors";
import { getStateByCountryCode } from "../../../../modules/common/getStateByCountryCode";
import { getCommonData } from "../../../../modules/common/getCommonData";
import { getCountries } from "../../../../modules/common/getCountries";
import { saveAddress } from "../../../../modules/sellerStore/saveAddress";
import { sellerStoreResponseSelector } from "../../../../modules/sellerStore/selectors";
import { notify } from "../../../../modules/notification/notify";
import { reset } from "../../../../modules/sellerStore/reset";
import { constant } from "../../../../utils/constant";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import BuyerFooter from "../../../buyer/buyerFooter";

export const StoreAddress = (props: any) => {
  interface adressValues {
    firstName: string;
    middleName: string;
    lastName: string;
    mobileNo: number | null;
    address1: string;
    address2: string;
    city: string;
    stateId: string | null;
    countryId: string | null;
    zipCode: number | null;
    callingCode: number;
  }

  const [addressData, setAddressData] = useState<adressValues>({
    firstName: "",
    middleName: "",
    lastName: "",
    mobileNo: null,
    address1: "",
    address2: "",
    city: "",
    stateId: null,
    countryId: null,
    zipCode: null,
    callingCode: 0,
  });

  const ShippingSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed ")
      .matches(/^\S*$/, "Name without spaces")
      .matches(/^\w*$/, "Name without special chars")
      .required("*Required!"),
    lastName: Yup.string()
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed ")
      .matches(/^\S*$/, "Name without spaces")
      .matches(/^\w*$/, "Name without special chars")
      .required("*Required!"),
    countryId: Yup.string().nullable().required("*Required!"),
    stateId: Yup.string().nullable().required("*Required!"),
    city: Yup.string().required("*Required!"),
    mobileNo: Yup.string()
      .typeError("you must specify a number")
      .matches(/^[0-9]+$/, "Must be only digits")
      .required("*Required!"),
    zipCode: Yup.string()
      .required("*Required!"),
    address1: Yup.string().required("*Required!"),
    // address2: Yup.string().required("*Required!"),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const commonResponse = useSelector<RootState, RootState["common"]>(
    CommonResponseSelector
  );
  const addressStore = useSelector<RootState, RootState["sellerStore"]>(
    sellerStoreResponseSelector
  );
  const submitFormData = (values: any) => {

    dispatch<any>(saveAddress(JSON.stringify(values)));
  };

  const onChange = (e: any) => {
    setAddressData({
      ...addressData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCountryChange = (e: any) => {
    let countryCode =
      e.target[e.target.selectedIndex].getAttribute("data-code");
    let callingCode =
      e.target[e.target.selectedIndex].getAttribute("calling-code");
    setAddressData({
      ...addressData,
      [e.target.name]: e.target.value,
      callingCode: callingCode,
    });
    dispatch<any>(getStateByCountryCode(countryCode));
  };
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      // loadScript(
      //   `https://maps.googleapis.com/maps/api/js?key=${constant.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places`,
      //   () => handleScriptLoad(autoCompleteRef)
      // );
      if (commonResponse.common === null) dispatch<any>(getCommonData());
      if (commonResponse.countries === null) dispatch<any>(getCountries());
    }
    isFirstRender.current = false;
  }, []);

  useEffect(() => {
    // dispatch<any>(EndProgress());
    if (addressStore.saveData != null) {
      if (addressStore.saveData.success === "Y") {
        notify(addressStore.saveData.message, constant.SUCCESS);
        localStorage.setItem("storeAddressLinked", "true");

        navigate("/seller/payment-info", { replace: true });
      } else if (addressStore.saveData.success === "N") {
        notify(addressStore.saveData.message, constant.ERROR);
      }

      // dispatch<any>(resetBanner());
    }
    if (addressStore.saveDataError != null) {
      notify(addressStore.saveDataError, "error");
    }

    dispatch<any>(reset());
  }, [addressStore]);
  return (
    <DefaultLayout>
      <HeaderNormal />
      <ToastContainer />

      <section className="login-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="login-container">
                <Formik
                  initialValues={addressData}
                  validationSchema={ShippingSchema}
                  enableReinitialize={true}
                  onSubmit={(values) => {
                    submitFormData(values);
                  }}
                >
                  {({ handleSubmit, errors, touched }) => (
                    <>
                      <Form>
                        <div className="form-block">
                          <h2 className="h2-head text-center">
                            Your Store Details
                          </h2>

                          <div className="signin-text text-center">
                            Please enter your Store Information
                          </div>

                          <div className="account-block">
                            <div className="form-group u-name">
                              <input
                                type="text"
                                className="form-control login-input"
                                placeholder="First Name"
                                name="firstName"
                                onChange={onChange}
                                value={addressData.firstName}
                              />
                              {errors.firstName && touched.firstName ? (
                                <div className="invalid-feedback d-block pb-0">
                                  {errors.firstName}
                                </div>
                              ) : null}
                            </div>

                            <div className="form-group u-name">
                              <input
                                type="text"
                                className="form-control login-input"
                                placeholder="Last Name"
                                name="lastName"
                                onChange={onChange}
                                value={addressData.lastName}
                              />
                              {errors.lastName && touched.lastName ? (
                                <div className="invalid-feedback d-block pb-0">
                                  {errors.lastName}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control login-input"
                              placeholder="Mobile no"
                              name="mobileNo"
                              onChange={onChange}
                              value={
                                addressData.mobileNo !== null
                                  ? addressData.mobileNo
                                  : ""
                              }
                            />
                            {errors.mobileNo && touched.mobileNo ? (
                              <div className="invalid-feedback d-block pb-0">
                                {errors.mobileNo}
                              </div>
                            ) : null}
                          </div>

                          <div className="form-group">
                            <textarea
                              className="form-control login-input"
                              placeholder="Address One"
                              name="address1"
                              onChange={onChange}
                              value={addressData.address1}
                            ></textarea>
                            {errors.address1 && touched.address1 ? (
                              <div className="invalid-feedback d-block pb-0">
                                {errors.address1}
                              </div>
                            ) : null}
                          </div>

                          <div className="form-group">
                            <textarea
                              className="form-control login-input"
                              placeholder="Address Two"
                              name="address2"
                              onChange={onChange}
                              value={addressData.address2}
                            ></textarea>
                            {errors.address2 && touched.address2 ? (
                              <div className="invalid-feedback d-block pb-0">
                                {errors.address2}
                              </div>
                            ) : null}
                          </div>

                          <div className="form-group">
                            <select
                              className="form-control login-input"
                              name="countryId"
                              value={
                                addressData.countryId === null
                                  ? ""
                                  : addressData.countryId
                              }
                              onChange={handleCountryChange}
                            >
                              <option value="">Choose country</option>
                              {commonResponse.countries?.value.map(
                                (item, Index) =>
                                  item.Active === "Y" && (
                                    <option
                                      value={item.Id}
                                      data-code={item.CountryCode}
                                      calling-code={item.CallingCode}
                                    >
                                      {item.CountryName}
                                    </option>
                                  )
                              )}
                            </select>
                            {errors.countryId && touched.countryId ? (
                              <div className="invalid-feedback d-block pb-0">
                                {errors.countryId}
                              </div>
                            ) : null}
                          </div>

                          <div className="form-group">
                            <select
                              className="form-control login-input"
                              name="stateId"
                              value={
                                addressData.stateId === null
                                  ? ""
                                  : addressData.stateId
                              }
                              onChange={onChange}
                            >
                              <option value="">Choose state</option>
                              {commonResponse.state?.value.map(
                                (item, Index) =>
                                  item.Active === "Y" && (
                                    <option value={item.Id}>
                                      {item.StateName}
                                    </option>
                                  )
                              )}
                            </select>
                            {errors.stateId && touched.stateId ? (
                              <div className="invalid-feedback d-block pb-0">
                                {errors.stateId}
                              </div>
                            ) : null}
                          </div>

                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control login-input"
                              placeholder="City"
                              name="city"
                              onChange={onChange}
                              value={
                                addressData.city !== null
                                  ? addressData.city
                                  : ""
                              }
                            />
                            {errors.city && touched.city ? (
                              <div className="invalid-feedback d-block pb-0">
                                {errors.city}
                              </div>
                            ) : null}
                          </div>

                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control login-input"
                              placeholder="Zip code"
                              name="zipCode"
                              onChange={onChange}
                              value={
                                addressData.zipCode !== null
                                  ? addressData.zipCode
                                  : ""
                              }
                            />
                            {errors.zipCode && touched.zipCode ? (
                              <div className="invalid-feedback d-block pb-0">
                                {errors.zipCode}
                              </div>
                            ) : null}
                          </div>

                          <br />
                          <button
                            type="submit"
                            className="btn btn-primary login-btn form-button"
                          >
                            Continue
                          </button>
                        </div>
                      </Form>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
       <BuyerFooter />
    </DefaultLayout>
  );
};
