import React from "react";
import { Navigate } from "react-router-dom";

const RequireAuthBuyer = ({ children }: any) => {
  const accessToken = localStorage.getItem("authorization");
  const role = localStorage.getItem("role");
  if (accessToken == null || role !== "Customer")
    return <Navigate to="/login" />;

  return children;
};

export default RequireAuthBuyer;
