import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { DefaultLayout } from "../../../../../layout/DefaultLayout";
import { activityResponseSelector } from "../../../../../modules/buyer/myEfindit/activity/selectors";
import { BASE_URLS } from "../../../../../utils/api.urls";
import { RootState } from "../../../../../utils/types";
import BuyerHeader from "../../../BuyerHeader";
import GroupTab from "../../groupTab";
import ActivityNavPanel from "../ActivityNavPanel";
import DefaultImage from "../../../../../assets/images/defaultImage.png";
import NoteBidsOffersModel from "../NoteBidsOffersModel";
import { notify } from "../../../../../modules/notification/notify";
import { constant } from "../../../../../utils/constant";
import { resetActivity } from "../../../../../modules/buyer/myEfindit/activity/resetActivity";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "./savedSellers.scss";
import { getSavedSellers } from "../../../../../modules/buyer/myEfindit/activity/getSavedSellers";
import BuyerFooter from "../../../buyerFooter";
import dotImage from "../../../../../assets/images/3dot.png";
import { deleteSavedSeller } from "../../../../../modules/buyer/myEfindit/activity/deleteSavedSeller";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SavedSellers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isFirstRender = useRef(true);
  const isFirstRender2 = useRef(true);


  let activityResponse = useSelector<RootState, RootState["activity"]>(
    activityResponseSelector
  );

  interface NoteValues {
    visible: string;
    Id: string | number;
    Note: string;
  }
  const [note, setNote] = useState<NoteValues>({
    visible: "none",
    Id: "",
    Note: "",
  });
const [filter, setFilter]= useState(1);
  const callSavedSellers = () => {
    dispatch<any>(getSavedSellers(filter));
  };

  useEffect(() => {
    if (activityResponse.noteSave != null) {
      if (activityResponse.noteSave.success == "Y") {
        notify(activityResponse.noteSave.message, constant.SUCCESS);
        callSavedSellers();
      } else if (activityResponse.noteSave.success == "N") {
        notify(activityResponse.noteSave.message, constant.ERROR);
      }

      setNote({
        visible: "none",
        Id: "",
        Note: "",
      });
    }
    if (activityResponse.noteError !== null) {
      notify(activityResponse.noteError, constant.ERROR);
    }
    dispatch<any>(resetActivity());
  }, [activityResponse.noteSave, activityResponse.noteError]);

  useEffect(() => {
    if (activityResponse.saveDataSeller != null) {
      if (activityResponse.saveDataSeller.success == "Y") {
        notify("Seller removed", constant.SUCCESS);
        callSavedSellers();
      } else if (activityResponse.saveDataSeller.success == "N") {
        notify(activityResponse.saveDataSeller.message, constant.ERROR);
      }

    }
    if (activityResponse.saveDataError !== null) {
      notify(activityResponse.saveDataError, constant.ERROR);
    }
    dispatch<any>(resetActivity());
  }, [activityResponse.saveDataSeller, activityResponse.saveDataError]);

  useEffect(() => {
    if (isFirstRender.current) {
      callSavedSellers();
    }
    isFirstRender.current = false;
  }, []);
  useEffect(() => {
    if (!isFirstRender2.current) {
      callSavedSellers();
    }
    isFirstRender2.current = false;
  }, [filter]);
  return (
    <DefaultLayout>
      <BuyerHeader />
      <ToastContainer />
      <NoteBidsOffersModel
        note={note}
        setNote={setNote}
        checked={"SavedSellers"}
      />
      <main className="min-section">
        <div className="container">
          <h1 className="sectionTitle mt-5">My eFindit</h1>

          <GroupTab currentPage="Activity" />
          <section className="withFilter">
            <ActivityNavPanel currentPage="SavedSellers" />

            <div className="right-wrapper">
              <section className="deals-group pt-0">
                <div className="deal-group-list four-col bgLight mt-0">
                  <div className="right-wrap-title-block">
                    <div className="row align-items-center">
                      <div className="col-md-auto">
                        <div className="right-wrap-title">Saved sellers</div>
                      </div>
                    </div>
                  </div>
                  <div className="bidListCont savedSeller">
                    <div className="table-responsive">
                    {activityResponse?.savedSellers?.data !== null &&
                      <table className="table ph-tr-top">
                        <tr className="head">
                          <td colSpan={2} className="text-right">
                            <table className="w-100">
                              <tr>
                                <td className="p-0">
                                  <div className="sortSelect d-inline-block">
                                    <label htmlFor="">Sort:</label>
                                    <div className="selectBox">
                                      <select name="" id="" onChange={(e:any)=>setFilter(e.target.value)}>
                                        <option value="1" >Date added </option>
                                        <option value="2"  >Name </option>
                                      </select>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                      }

                      <div className="wraper">
                        {activityResponse?.savedSellers?.data?.map(
                          (item, Index) => (
                            <div className="savedSelerIteam">
                              <div className="media">
                              <LazyLoadImage
                                className="mr-3"
                                src={BASE_URLS.AWS_URL + item.profilePic}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = DefaultImage;
                                  }}
                                />
                                
                                <div className="media-body">
                                  <p className="title"> {item.storeName}</p>
                                  <p className="subTitle">
                                    <Link to={`/buyer/sellerStore/${item.sellerId}`}>Visit store </Link>
                                  </p>
                                {item.note &&  <p style={{fontSize:12}}>Note to self: {item.note  } {item.note !== null && (
                                      <><br/>  <span
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setNote({
                                              ...note,
                                              visible: "block",
                                              Id: item.id,
                                              Note:
                                                item.note === null
                                                  ? ""
                                                  : item.note,
                                            });
                                          }}
                                          className="editNote"
                                        >
                                          Edit note
                                        </span></>
                                      )}</p>}
                                </div>
                              </div>
                              <div className="actionWrap mt-5">
                                <div className="action-icon wishlist" onClick={()=>  dispatch<any>(deleteSavedSeller(item.id))}>
                                  <i className="wishlist-icon"></i>
                                </div>
                                <div className="action-icon drop">
                                  <div className="dropdown">
                                    <span
                                      className="dropdown-toggle"
                                      aria-hidden="true"
                                      id="dropdownMenuButton"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src={dotImage} alt=""></img>
                                      </span>
                                    <i className="fa fa-ellipsis-v"></i>
                                    <div
                                      className="dropdown-menu dropdown-menu-right"
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      
                                      <div className="dropdown-item" onClick={() => {
                                        navigate(
                                          `/buyer/sellerStore/${item.sellerId}`,
                                         
                                          
                                        );
                                      }}>
                                        View profile
                                      </div>
                                      <div className="dropdown-item" onClick={() => {
                                        navigate(
                                          "/buyer/contactSeller",
                                         {  state: {product:item,route:"/buyer/activity/savedSellers"}},
                                          
                                        );
                                      }}>
                                        Contact seller
                                      </div>
                                      {/* <a className="dropdown-item" href="#">
                                        {" "}
                                        Subscribe to newsletter
                                      </a> */}
                                      <div
                                        className="dropdown-item"
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setNote({
                                            ...note,
                                            visible: "block",
                                            Id: item.id,
                                            Note:
                                              item.note === null
                                                ? ""
                                                : item.note,
                                          });
                                        }}
                                      >
                                        {" "}
                                        Add note to self
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                      {activityResponse?.savedSellers?.data === null && (
                        <div className="no-data-css">
                          <h3>No records found</h3>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div>
      </main>

      <BuyerFooter />
    </DefaultLayout>
  );
};
export default SavedSellers;
