import { AppThunk } from "../../store";
import { CancelOrderDetailSuccess, CancelOrderDetailFailed } from "./slice";
import { fetchCancelOrderDetail } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const getCancelOrderDetail =
  (data: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch<any>(StartProgress());

      const ordersData: any = await fetchCancelOrderDetail(data);
      

      if (ordersData.success === "N") {
        
        dispatch(CancelOrderDetailFailed(ordersData));
      } else {
        
        dispatch(CancelOrderDetailSuccess(ordersData));
      }

      //return DashboardData
    } catch (err) {
      
      dispatch(CancelOrderDetailFailed(err));
      //return err.toString()
    }
    dispatch<any>(EndProgress());
  };
