import { AppThunk } from "../../../../store";

import { reset, resetOrderDetails, resetSaveProductFeedback, resetSaveSellerFeedback } from "./slice";

export const resetActivity =
  (data = ""): AppThunk =>
  async (dispatch) => {
    try {
      if(data==="productFeedback")
      dispatch(resetSaveProductFeedback());
else if(data==="sellerFeedback")
dispatch(resetSaveSellerFeedback());
else if(data==="orderDetails")
dispatch(resetOrderDetails());
else
      dispatch(reset());
    } catch (err) {
      
    }
  };
