import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { saveOffer } from "../../../modules/buyer/product/saveOffer";
import { orderCancelled } from "../../../modules/orders/orderCancelled";

const ReviewOfferModel = ({
reviewOffer,
setReviewOffer

  
}: any) => {





  return (
    <div
      className="modal modal-order-popup "
      id="myModal"
      tabIndex={-1}
      role="dialog"
      style={{ display: reviewOffer.visible,
      
      }}
    >
      <div className="modal-dialog" style={{maxWidth:350}} role="document">
        <div className="modal-content">
        <div className="modal-header">
        <h2 className="modal-title" id="exampleModalLabel">Offer Sent!</h2>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>setReviewOffer({...reviewOffer, visible:"none"})}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body" >
       <div style={{padding:3, fontSize:15}}> Please wait for the seller to accept, decline or counter in the next 24 hours( before your offer expires ) </div>
      </div>
  
        </div>
      </div>
    </div>
  );
};

export default ReviewOfferModel;
