import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import * as Yup from "yup";
import { DefaultLayout } from "../../layout/DefaultLayout";
import { notify } from "../../modules/notification/notify";
import { reset } from "../../modules/userRegister/reset";
import { RegisterResponseSelector } from "../../modules/userRegister/selectors";
import { setRegisterUser } from "../../modules/userRegister/setRegisterUser";
import { RootState } from "../../utils/types";
import "react-toastify/dist/ReactToastify.css";
import FooterNormal from "../../components/footer/FooterNormal";

export const Register = (props: any) => {
  interface formValues {
    emailId: string;
    password: string;
    firstName: string;
    lastName: string;
    // roleId: any;
  }
  const [user, setUserValue] = useState<formValues>({
    emailId: "",
    password: "",
    firstName: "",
    lastName: "",
    // roleId: 2,
  });
  const dispatch = useDispatch();
  var registerResponse = useSelector<RootState, RootState["register"]>(
    RegisterResponseSelector
  );
  const handleChange = (e: any) => {
    setUserValue({ ...user, [e.target.name]: e.target.value });
  };
  const ProfileSchema = Yup.object().shape({
    emailId: Yup.string().email("Please enter a valid email.").min(2, "Too Short!").required("*Required!"),
    password: Yup.string()
      .min(8, "Must Contain at least 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character.")
      .max(15, "Max length:15 characters!")
      .required("*Required!")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain at least 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character."
      ),
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("*Required!").matches(/^[A-Za-z]+$/,"Only alphabets allowed."),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("*Required!").matches(/^[A-Za-z]+$/,"Only alphabets allowed."),
  });

  useEffect(() => {
    if (registerResponse.register != null) {
      if (registerResponse.register.success == "Y") {
        notify(registerResponse.register.message, "success");
      } else if (registerResponse.register.success == "N") {
        notify(registerResponse.register.message, "error");
      }
      setUserValue({
        emailId: "",
        password: "",
        firstName: "",
        lastName: "",
        // roleId: 2,
      });
      dispatch<any>(reset());
    }
    if (registerResponse.error != null) {
      notify(registerResponse.error, "error");
      dispatch<any>(reset());
    }
  }, [registerResponse]);
  return (
    <DefaultLayout>
      <ToastContainer />
     
        <header className="header">
          <div className="logo-section">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="logo-container">
                    <div className="logo">
                    <Link to={"/home"}>
                        <i className="logo-img"></i>
                      </Link>
                    </div>
                    <div className="login-link">
                      Already a member? Sign in as:{" "}
                      <Link to={"/login-seller"}>Seller</Link>
                      {"  "}
                      <Link to={"/login"}>Buyer</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <section className="signup-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="signup-container">
                  <Formik
                    initialValues={user}
                    validationSchema={ProfileSchema}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                      dispatch<any>(reset()).then(
                        dispatch<any>(setRegisterUser(user))
                      );
                    }}
                  >
                    {({ errors, touched }) => (
                      <div className="form-block">
                        <h2 className="h2-head text-center mb-5">
                          Create an account
                        </h2>
                        {/* <div className="signup-options">
                          <div className="ch-item">
                            <p>
                              <input
                                type="radio"
                                id="customner"
                                name="customner"
                                value={2}
                                onChange={(e: any) =>
                                  setUserValue({
                                    ...user,
                                    roleId: e.target.value,
                                  })
                                }
                                checked={user.roleId == 2}
                              />
                              <label htmlFor="customner">
                                Personal account
                              </label>
                            </p>
                          </div>
                          <div className="ch-item">
                            <p>
                              <input
                                type="radio"
                                id="seller"
                                name="seller"
                                value={3}
                                onChange={(e) =>
                                  setUserValue({
                                    ...user,
                                    roleId: e.target.value,
                                  })
                                }
                                checked={user.roleId == 3}
                              />
                              <label htmlFor="seller">Business account</label>
                            </p>

                            <span className="query-icon"></span>

                            <div className="query-popup query-popup-form">
                              <div className="query-popup-container">
                                <a href="#" className="close-popup-link">
                                  <i className="popup-close-icon"></i>
                                </a>
                                <div className="query-block">
                                  <div className="bold">
                                    You should choose this option if:
                                  </div>
                                  <ul>
                                    <li>you are a business</li>
                                    <li>you are a nonprofit</li>
                                    <li>
                                      you regularly sell a large number of goods
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <Form>
                          <div className="account-block">
                            <div className="form-group u-name">
                              <input
                                type="text"
                                className="form-control login-input"
                                placeholder="First Name"
                                name="firstName"
                                onChange={handleChange}
                                value={user.firstName}
                              />
                              {errors.firstName && touched.firstName ? (
                                <div className="invalid-feedback d-block">
                                  {errors.firstName}
                                </div>
                              ) : null}
                            </div>

                            <div className="form-group u-name">
                              <input
                                type="text"
                                className="form-control login-input"
                                placeholder="Last Name"
                                name="lastName"
                                onChange={handleChange}
                                value={user.lastName}
                              />
                              {errors.lastName && touched.lastName ? (
                                <div className="invalid-feedback d-block">
                                  {errors.lastName}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control login-input"
                              placeholder="Email"
                              value={user.emailId}
                              name="emailId"
                              onChange={handleChange}
                            />
                            {errors.emailId && touched.emailId ? (
                              <div className="invalid-feedback d-block">
                                {errors.emailId}
                              </div>
                            ) : null}
                          </div>

                          <div className="form-group">
                            <input
                              type="password"
                              className="form-control login-input"
                              placeholder="Password"
                              name="password"
                              onChange={handleChange}
                              value={user.password}
                            />
                            {errors.password && touched.password ? (
                              <div className="invalid-feedback d-block">
                                {errors.password}
                              </div>
                            ) : null}
                          </div>

                          <div className="terms-text">
                            By clicking <a href="#">Register</a>, you agree that
                            you have read and accepted our{" "}
                            <a href="#">User agreement</a>,{" "}
                            <a href="#">User Privacy Notice</a>,{" "}
                            <a href="#">Rules and Policies</a>.
                          </div>

                          <button
                            type="submit"
                            className="btn btn-primary signup-btn form-button"
                          >
                            Register
                          </button>
                        </Form>
                      </div>
                    )}
                  </Formik>

                  {/*
                  <div className="line">
                    <div>
                      <span>or</span>
                    </div>
                  </div>
                  <div className="social-btn-block">
                    <button className="btn btn-outline-primary f">
                      <i className="f-icon"></i>
                      <span>Register with Facebook</span>
                    </button>
                    <button className="btn btn-outline-primary g">
                      <i className="g-icon"></i>
                      <span>Register with Google</span>
                    </button>
                    <button className="btn btn-outline-primary a">
                      <i className="a-icon"></i>
                      <span>Register with Apple</span>
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="signup-help-section">
            <div className="signup-popup-block">
              <div className="query-popup signup-popup-help">
                <div className="query-popup-container">
                  <a href="#" className="close-popup-link">
                    <i className="popup-close-icon"></i>
                  </a>
                  <div className="query-block">
                    <div className="help-heading">FAQs</div>
                    <p>Do I need a business acount?</p>
                    <p>
                      You should register for a business account if you'd like
                      to carry out any of the following activities on efindit.
                    </p>
                    <ul>
                      <li>List item that you've made or bought to resell</li>
                      <li>Regularly sell a large number of goods</li>
                      <li>Buy items for your business</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="query-popup signup-popup-help">
                <div className="query-popup-container">
                  <a href="#" className="close-popup-link">
                    <i className="popup-close-icon"></i>
                  </a>
                  <div className="query-block">
                    <div className="help-heading">Experiencing issues?</div>
                    <p>
                      Tell us about any difficulties you had during registration
                    </p>
                    <p>
                      You should register for a business account if you'd like
                      to carry out any of the following activities on efindit.
                    </p>
                    <div className="form-group">
                      <textarea className="form-control" rows={3}></textarea>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary signup-btn form-button"
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="signup-icon-block">
              <a href="#" className="help-icon-link">
                <i className="help-icon"></i>
              </a>
              <div className="help-icon-border"></div>
              <a href="#" className="msg-icon-link">
                <i className="msg-icon"></i>
              </a>
            </div>
          </div>
        </section>

    <FooterNormal/>
  
    </DefaultLayout>
  );
};
