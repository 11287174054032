import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Rating } from "react-simple-star-rating";
import { setRecentlyViewed } from '../../../modules/buyer/product/setRecentlyViewed';
import { BASE_URLS } from '../../../utils/api.urls';
import { getVariantWithOptions, isEqual } from "./helpers";
import { dateToLocal } from '../../../modules/date/date';
import CardList from "../../../assets/images/card-list.png"
import moment from 'moment';

interface Props {
    product: any;
    productId: string;
    setVariantDetail: any;
    setQuantityDropdown: any;
    setQuantity:any;
    setImageGallery: any;
    imageGallery: any;
    variantId:any;
}

const NewDetails = ({ product, productId,variantId, setVariantDetail,setQuantity, setQuantityDropdown, setImageGallery, imageGallery }: Props) => {

    const [variant, setVariant] = useState([]);
    const isFirstRender = useRef(true);
    const isFirstRender2 = useRef(true);
    const isFirstRender3 = useRef(true);

    const dispatch = useDispatch();

    const [availbleError, setAvailbleError] = useState("");
    const [attribute, setAttribute] = useState<any>([]);




    useEffect(() => {

        if (isFirstRender.current) {
            if (product?.productDetails?.data?.productVariantInfos) {
                let variantData = getVariantWithOptions(product?.productDetails?.data?.productVariantInfos)
                setVariant(variantData);
                let data:any=[];
                variantData.forEach((item:any)=>{
                    data.push({
                        AttName:item.AttName,
                        Option:""

                    })

                })
                setAttribute(data)
            }
            setAvailbleError("")
        }
        isFirstRender.current = false;
    }, []);

    const getVariantImageByColor = (color: string, count: number = 10) => {

        let newData: any = "";

        product?.productDetails?.data?.productVariantImages &&
            product?.productDetails?.data?.productVariantImages.map((img: any, index: number) => {

                if (img.option == color) {
                    if (count == 1) {
                        newData = JSON.parse(img.imgName)[0]
                    } else {
                        newData = JSON.parse(img.imgName);
                    }
                }
            })
        return newData;
    }


    const getvariantList = (selectedData: any) => {
        let itemData: any = "";

        product?.productDetails?.data?.productVariantInfos.map((item: any, index: number) => {
            if (isEqual(JSON.parse(item.variant), selectedData)) {

                itemData = item;
            }
        })

        return itemData;

    }

    const [selectedVariantData, setSelectedVariantData] = useState<any>({});
    const [selectedVariant, setSelectedVariant] = useState<any>([]);
   
    const handleVariantSelect = (e: any, Index:number) => {

        let attrKey = e.target.getAttribute("data-name");

        let objectVar = {
            'AttName': e.target.getAttribute("data-name"),
            'Option': e.target.value
        }
        let data=[...attribute];
        let index=data.findIndex((attr:any)=> attr.AttName==attrKey)
       data[index]["Option"]=e.target.value;
       setAttribute(data)
        let check = checkSelectedVariantExist(attrKey);
        check ? setSelectedVariant([...check, objectVar]) : setSelectedVariant([...selectedVariant, objectVar])

    }

    const checkSelectedVariantExist = (attrKey: any) => {
        let newArray: any = "";
        selectedVariant.map((item: any, index: any) => {
            if (item.AttName == attrKey) {
                newArray = selectedVariant.filter((vararray: any) => vararray.AttName !== attrKey)
            }
        })
        return newArray;
    }


    const handleColorVariant = (e: any) => {

        let id = e.target.id;
        Array.from(document.querySelectorAll('.v_image')).forEach((el) => el.classList.remove('selected'));

        e.target.classList.add('selected');
        let color = e.target.getAttribute("data-color");

        colorVariantSelection(color)

    }

    const defaultSelectColorVariant = (color: any) => {
        // let element = document.getElementById("vimg-0") as HTMLElement
        // element.classList.add("selected");
        colorVariantSelection(color)

    }
    const [newGallery, setNewGallery] = useState<any>([]);
    const colorVariantSelection = (color: any) => {
        let allImages = getVariantImageByColor(color)
        
        //set thumbnail images 
        let thumbnailArray: any = []
        if(allImages!=="")
        {allImages?.map((img: any) => {
            thumbnailArray.push({
                image: BASE_URLS.AWS_URL + img.ImgName
            }
            )
        })
}
        setImageGallery([])
        setNewGallery(thumbnailArray)

        let objectColor = {
            'AttName': 'Color',
            'Option': color
        }

        let check = checkSelectedVariantExist('Color');

        check ? setSelectedVariant([...check, objectColor]) : setSelectedVariant([...selectedVariant, objectColor])

    }

    useEffect(() => {
       variant && imageGallery.length == 0 && setImageGallery(newGallery)

    }, [newGallery, imageGallery]);


    const updateVariantPrice = (selectedVariant: any) => {
        let varItem: any = {}

        if (variant.length == selectedVariant.length) {

            varItem = getvariantList(selectedVariant)

            if (varItem != "") {
                setSelectedVariantData(varItem)
                setAvailbleError("")
            } else {

                setAvailbleError("This combination is not available.")
                setSelectedVariantData("")

                setQuantityDropdown([])
                setQuantity(1)

            }
        }

    }

    const findMinPriceVariant=()=>{
        let data=product.productDetails.data.productVariantInfos[0] ;
let min=product.productDetails.data.productVariantInfos[0].buyNowPrice ? product.productDetails.data.productVariantInfos[0].buyNowPrice : product.productDetails.data.productVariantInfos[0].actualPrice;
        product.productDetails.data.productVariantInfos.forEach((item:any)=>
        {
            let price=item.buyNowPrice? item.buyNowPrice :item.actualPrice;
            if(price < min)
            {
                min=price;
                data=item
            }
        })
        return data;
    }
    //defaut seleted first image if image variant availble
    useEffect(() => {
        variant.length != 0 && variant.map((item) => {


         
            
        })
   
        
    }, [variant]);

    useEffect(() => {
      if(isFirstRender2.current && (variantId!=="null"  && variantId!==undefined))
{
        attribute.length != 0  &&     product.productDetails.data.productVariantInfos.forEach((item:any)=>
        {
            let attri=[...attribute];
            if(item.id==variantId)
            {   
                
                JSON.parse(item.variant).forEach((data:any)=>{

                    if(data.AttName=="Color")
                    {
                        defaultSelectColorVariant(data.Option)
                    }

        let index=attri.findIndex((attr:any)=> attr.AttName==data.AttName)
                    attri[index]["Option"]=data.Option
                 
                   
                })
                isFirstRender2.current=false
                setAttribute(attri)
                setSelectedVariant(attri)
            }
        })}

    if(isFirstRender2.current && attribute.length != 0 )
        {
            let attri=[...attribute];
            let item=findMinPriceVariant();
            JSON.parse(item.variant).forEach((data:any)=>{

                if(data.AttName=="Color")
                {

                    defaultSelectColorVariant(data.Option)
                }

    let index=attri.findIndex((attr:any)=> attr.AttName==data.AttName)
                attri[index]["Option"]=data.Option
             
               
            })
            isFirstRender2.current=false
            setAttribute(attri)
            setSelectedVariant(attri)
        }
    }, [attribute]);
    useEffect(() => {
        if (variant.length != 0 && selectedVariant.length != 0) {
            updateVariantPrice(selectedVariant);
        }


    }, [selectedVariant]);

    useEffect(() => {
        if (selectedVariantData.id !== undefined && productId !== "") {
            setVariantDetail(selectedVariantData)
            dispatch<any>(setRecentlyViewed({ productId: productId, VaraintId: selectedVariantData.id }));
            let newQuantiy = [];
            for (
                let i = 1;
                i <= selectedVariantData?.quantity && i <= 10;
                i++
            )
                newQuantiy.push(i);

            setQuantityDropdown(newQuantiy);
            setQuantity(1)


        }
        else
            setVariantDetail({})
    }, [selectedVariantData]);



    return (
        <div className="productText">
            <h2>
                {
                    product?.productDetails?.data?.productDetail?.title
                }
            </h2>
            <p className="condition">
                Condition:{" "}
                {
                    product.productDetails?.data?.productDetail.condition
                }
                
            </p>

            <div className="productRating">
                <div className="productStars">
                    <Rating
                        initialValue={
                            product.productDetails?.data?.productDetail.productRating
                        }
                        readonly={true}
                        // onClick={(rate) =>
                        //   handleRating(rate, "productRating")
                        // }
                        /* Available Props */
                        size={17}
                    />
                </div>
                <div className="reviews">
                <span style={{
                            color:"#3453c8",
                            fontSize:12
                        }}>
                        {product.productDetails?.data?.productDetail.totalProductRating
                            ? product.productDetails?.data?.productDetail.totalProductRating
                            : 0}{" "}
                        Product rating
                    </span>
                </div>
            </div>
            <div>

            </div>

            <div className="prodOverview">
                {/* <div className="row">
                    <div className="col-lg-3">
                        <div className="smallTitle">Condition :</div>
                    </div>
                    <div className="col-lg-9">
                        <strong>
                            {
                                product.productDetails?.data?.productDetail.condition
                            }
                        </strong>
                       
                    </div>
                </div> */}
                <div className="product-row">
                    <div className="product-label">
                        <div className="smallTitle">Availibility :</div>
                    </div>
                    <div className="product-label-content">
                        <strong className="boldText">
                            {variant.length != 0 ?
                                selectedVariantData?.quantity
                                :
                                product.productDetails?.data?.productDetail.quantity
                            }{" "}
                            available
                        </strong>{" "}
                    </div>
                </div>

                {/* /
                <div className="row">
                    <div className="col-lg-3">
                        <div className="smallTitle">Availibility :</div>
                    </div>
                    <div className="col-lg-9">
                        <strong className="boldText">
                            {variant.length != 0 ?
                                selectedVariantData?.quantity
                                :
                                product.productDetails?.data?.productDetail.quantity
                            }{" "}
                            available
                        </strong>{" "}
                        {" "}
                        <span style={{
                            color:"#3453c8",
                            fontSize:12
                        }}>
                            {
                                variant.length != 0 ?
                                    selectedVariantData?.sold
                                    :
                                    product.productDetails?.data?.productDetail.sold
                                        ? product.productDetails?.data?.productDetail.sold
                                        : 0}
                            {"  "}
                            sold
                        </span> 
                    </div>
                </div>
                */}

            </div>
            {product?.productDetails?.data?.productVariantInfos != null &&
                <div className="prodOverview">
                    <h4>Select Variant</h4>

                    {
                        variant.length != 0 && variant.map(
                            (item, Index) => (
                                
                                <div className="product-row mt-3" key={Index}>
                                    <div className="product-label">
                                        <div className="smallTitle">{item['AttName']} :</div>
                                    </div>
                                    <div className="product-label-content">
                                        {
                                            item['AttName'] == 'Color' ?
                                                <>
                                                    {
                                                        Object.values(item['Option']).map((opt: any, index2) => (
                                                            <div className="variant_img" key={index2}>
                                                                <img style={{height:100, width:100}}  src={BASE_URLS.AWS_URL  + getVariantImageByColor(opt, 1).ImgName} alt="" id={"vimg-" + index2} data-color={opt} onClick={handleColorVariant} className={`v_image ${opt==attribute[Index].Option? "selected":""}`}/>
                                                            </div>

                                                        ))
                                                    }

                                                </>
                                                :
                                                <select value={attribute[Index]  ? attribute[Index].Option :""}  id={item['AttName']} className="form-control variant_dropdown" data-name={item['AttName']} onChange={(e)=>handleVariantSelect(e, Index)}>
                                                    <option value="">Select {item['AttName']}</option>
                                                    {

                                                        Object.values(item['Option']).map((opt: any, index3) => (
                                                            <option id={item['AttName']+opt} value={opt} key={index3}>{opt}</option>
                                                        ))
                                                    }


                                                </select>
                                        }
                                    </div>
                                </div>
                            ))
                    }

                    {availbleError && <div className="error">{availbleError}</div>}

                </div>
            }
            <div className="prodOverview">
                <div className="product-row">
                    <div className="product-label">
                        <div className="smallTitle">Price :</div>
                    </div>
                    <div className="product-label-content">
                        <div className="priceTag">
                            US $

                            {variant.length != 0 ?
                                selectedVariantData?.buyNowPrice ? selectedVariantData?.buyNowPrice : selectedVariantData?.actualPrice

                                :

                                product.productDetails?.data?.productDetail.buyNowPrice
                                    ? product.productDetails?.data?.productDetail.buyNowPrice
                                    : product.productDetails?.data?.productDetail.actualPrice} {" "}
                            <a href="" className="i-icon">
                                <img src="images/i-icon.png" alt="" />
                            </a>
                        </div>
                        
                        { (
                            <div className="discounted">
                                Was:{" "}
                                <del>
                                    US $
                                    {variant.length != 0 ?
                                selectedVariantData?.actualPrice

                                :

                                 product.productDetails?.data?.productDetail.actualPrice}
                                </del>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="prodOverview sold">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="smallTitle soldItems">
                            {product.productDetails?.data?.productDetail.sold
                                ? product.productDetails?.data?.productDetail.sold
                                : 0}{" "}
                            sold
                        </div>
                    </div>
                    {/* <div className="col-lg-4">
                        <div className="smallTitle">
                            Same day shipping check
                        </div>
                    </div> */}
                    <div className="col-lg-6">
                        <div className="smallTitle">
                            {
                                product.productDetails?.data?.productDetail?.returnInDays
                            }
                            -day returns. {product.productDetails?.data?.productDetail?.buyerPaysForReturnShipping &&
                                "Buyer pays for return shipping."}
                        </div>
                    </div>
                </div>
            </div>
            <div className="prodOverview border-0">
                <div className="product-row">
                 {( product.productDetails?.data?.productDetail?.buy2ItemDiscount  || product.productDetails?.data?.productDetail?.buy3ItemDiscount    || product.productDetails?.data?.productDetail?.buy4ItemDiscount ) 
  &&  <div className="product-label">
                        <div className="smallTitle">Discounts :</div>
                    </div>}
                    <div className="product-label-content">
                    {product.productDetails?.data?.productDetail?.buy2ItemDiscount  &&<><p >
                   <strong> Buy 2 and save <span style={{color:"green", fontWeight:"bold"}}>{ product.productDetails?.data?.productDetail?.buy2ItemDiscount }%</span> off each item
                   </strong>      </p>   <br/></> }
                        
                   {product.productDetails?.data?.productDetail?.buy2ItemDiscount  &&<><p >
                   <strong> Buy 3 and save <span style={{color:"green", fontWeight:"bold"}}>{ product.productDetails?.data?.productDetail?.buy3ItemDiscount }%</span> off each item
                   </strong>      </p>   <br/></> }
                   {product.productDetails?.data?.productDetail?.buy2ItemDiscount  &&<><p >
                   <strong> Buy 4 or more and save <span style={{color:"green", fontWeight:"bold"}}>{ product.productDetails?.data?.productDetail?.buy4ItemDiscount }%</span> off each item
                   </strong>      </p>  </> }
                      
                    </div>
                </div>
            </div>
         { product.productDetails?.data?.productDetail?.shippingCharges  &&  <div className="prodOverview border-0">
                <div className="product-row">
                 {   <div className="product-label">
                        <div className="smallTitle">Shipping :</div>
                    </div>}
                    <div className="product-label-content">
                       {product.productDetails?.data?.productDetail?.shippingCharges  && <strong>
                            ${ product.productDetails?.data?.productDetail?.shippingCharges[0].price} 
                        </strong>}
                        {/* <p>
                            International shipment of items may be subject to
                            customs processing and additional charges.
                        </p>
                        <p>
                            Located in: Dallas, Texas, United States Ships to:
                            United States and many other countries |{" "}
                            <a href="" className="seeDetails">
                                {" "}
                                See details
                            </a>
                        </p> */}
                    </div>
                </div>
            </div>}
         {  <div className="prodOverview border-0 pt-0">
                <div className="product-row">
                    <div className="product-label">
                        <div className="smallTitle">Delivery:</div>
                    </div>
                    <div className="product-label-content">
                        <strong>Estimated date</strong>{" "}
                        <strong className="boldText">
                            { product.productDetails?.data?.productDetail.estimatedDeliveryDateAndTime ? moment(dateToLocal(product.productDetails?.data?.productDetail.estimatedDeliveryDateAndTime)).format("DD MMM YYYY") : "Not Deliverable"}
                        </strong>
                        <p>
                            Seller ships within 1 day after
                           receiving cleared payment
                        </p>
                    </div>
                </div>
            </div>}
            <div className="prodOverview border-0 pt-0">
                <div className="product-row payment-row">
                    <div className="product-label">
                        <div className="smallTitle">Payments :</div>
                    </div>
                    <div className="product-label-content">
                        <div>
                            <img src={CardList} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="prodOverview border-0 pt-0">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="smallTitle">Returns :</div>
                    </div>
                    <div className="col-lg-9">
                        <strong className="boldText">
                            {
                                product.productDetails?.data?.productDetail?.returnInDays
                            }{" "}
                            day returns.
                            {product.productDetails?.data?.productDetail?.buyerPaysForReturnShipping &&
                                "Buyer pays for return shipping "}
                        </strong>{" "}
                     
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default NewDetails
