import { AppThunk } from "../../../../store";
import {
  RecentlyViewedDeleteFailed,
  RecentlyViewedDeleteSuccess,
} from "./slice";
import { clearRecentlyViewed, fetchRecentlyViewed } from "./api";
import { EndProgress } from "../../../progressBar/endProgress";
import { StartProgress } from "../../../progressBar/startProgress";

export const deleteRecentlyViewed = (): AppThunk => async (dispatch) => {
  dispatch<any>(StartProgress());
  try {
    const data: any = await clearRecentlyViewed();
    

    if (data.success === "N") {
      
      dispatch(RecentlyViewedDeleteFailed(data));
    } else {
      
      dispatch(RecentlyViewedDeleteSuccess(data));
    }
  } catch (err) {
    
    dispatch(RecentlyViewedDeleteFailed(err));
  }
  dispatch<any>(EndProgress());
};
