import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ResetPassword } from "../../utils/types";

type initialDataState = {
  data: ResetPassword | null;
  error: any | null;
};

const initialState: initialDataState = {
  data: null,
  error: null,
};
const slice = createSlice({
  name: "resetpassword",
  initialState,
  reducers: {
    resetData: (state) => {
      state.data = null;
      state.error = null;
    },
    resetPasswordSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = null;
      
    },
    resetPasswordFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.data = null;
      var errorMessage = action.payload?.errors?.Password
        ? action.payload.errors.Password
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
  },
});

export const resetpassword = slice.reducer;

export const { resetPasswordFailed, resetPasswordSuccess, resetData } =
  slice.actions;
