import { AppThunk } from "../../../../store";
import { SaveDataProductFailed, SaveDataProductSuccess } from "./slice";
import { deleteWatchlistItems } from "./api";
import { EndProgress } from "../../../progressBar/endProgress";
import { StartProgress } from "../../../progressBar/startProgress";

export const deleteWatchlist =
  (params: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
      const data: any = await deleteWatchlistItems(params);
      

      if (data.success === "N") {
        
        dispatch(SaveDataProductFailed(data));
      } else {
        
        dispatch(SaveDataProductSuccess(data));
      }
    } catch (err) {
      
      dispatch(SaveDataProductFailed(err));
    }
    dispatch<any>(EndProgress());
  };
