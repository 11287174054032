import { AppThunk } from "../../store"
import {
  SaveShippingDetailsSuccess,
  SaveShippingDetailsFailed
} from "./slice"
import { postShippingDetails } from "./api"
import { EndProgress } from "../progressBar/endProgress";
import { StartProgress } from "../progressBar/startProgress";


export const saveShippingDetails = (data: any): AppThunk => async (dispatch) => {

  dispatch<any>(StartProgress());
  try {

    const shippingData: any = await postShippingDetails(data)

    if (shippingData.success === "N") {
      
      dispatch(SaveShippingDetailsFailed(shippingData));
    }
    else {
     
      dispatch(SaveShippingDetailsSuccess(shippingData));
    }

  } catch (err) {
    
    dispatch(SaveShippingDetailsFailed(err));
  }

  dispatch<any>(EndProgress());
}