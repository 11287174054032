import { AppThunk } from "../../../store";
import { SaveDataFailed, SaveDataSuccess } from "./slice";
import { deleteAllSaveSearch, deleteSaveSearch, postRecentlyViewed, postToSaveSearch } from "./api";

export const removeAllSavedSearch =
  (): AppThunk =>
  async (dispatch) => {
    try {
      const data: any = await deleteAllSaveSearch();
      

      if (data.success === "N") {
        
        dispatch(SaveDataFailed(data));
      } else {
        
        dispatch(SaveDataSuccess(data));
      }
    } catch (err) {
      
      dispatch(SaveDataFailed(err));
    }
  };
