import React, { useEffect, useState } from 'react'
import "./printOrder.scss"
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllOrdersReport } from '../../../modules/orders/getAllOrdersReport'
import { SellerOrdersResponseSelector } from '../../../modules/orders/selectors'
import { RootState } from '../../../utils/types'
import { dateToLocal } from '../../../modules/date/date'
import { resetOrder } from '../../../modules/orders/resetOrder'
import { DefaultLayout } from '../../../layout/DefaultLayout'
const PrintOrders = () => {
    const {state}= useLocation()
  const navigate = useNavigate();

    const dispatch = useDispatch();
    let order = useSelector<RootState, RootState["sellerOrders"]>(
        SellerOrdersResponseSelector
      );

      const [reportData, setReportData] = useState([] as any);
      const [type, setType] = useState("");


useEffect(()=>{
    dispatch<any>(
        getAllOrdersReport({
          currentPage: 1,
          rows:state?.rows ,
          userId: state?.userId,
          orderId: state?.orderId,
          StartDate: state?.StartDate,
          EndDate: state?.EndDate,
          type:state?.type
        }))
        setType(state?.type)
},[state])

useEffect(() => {
    if (order?.sellerOrdersReport) {
      let userData: any = [];
     
      order?.sellerOrdersReport?.data?.orders?.map((order:any) => {
          let datalist: any = {};
       
          datalist["orderId"] = order?.orderId;
          datalist["buyerName"] = order?.buyerName;
          datalist["productName"] = order?.productName;
          datalist["condition"] = order?.condition;
          datalist["quantity"] = order?.quantity;
          datalist["stockAvailable"] = order?.stockAvailable;
          datalist["soldFor"] = order?.soldFor;
          datalist["total"] = order?.total;
          datalist["trackingNo"] = order?.trackingNo;
          datalist["orderDate"] = order.orderDate !== null
          ? dateToLocal(order.orderDate)
          : "";
          datalist["buyerPaidDate"] = order.buyerPaidDate !== null
          ? dateToLocal(order.buyerPaidDate)
          : "";
          datalist["zipCode"] = order?.zipCode;
          datalist["status"] = state?.type==="Delivered" ? "Delivered": order?.status;
          datalist["shippingAmount"] = order?.shippingAmount;
          datalist["sellerNote"] = order?.sellerNote;
          datalist["shippingDate"] = order.shippingDate !== null
          ? dateToLocal(order.shippingDate)
          : "";

         
          userData.push(datalist);
        });
      setReportData(userData);
      
      dispatch<any>(resetOrder("reportAllOrder"))
    } 
  }, [order?.sellerOrdersReport]);

  useEffect(()=>{
   if(reportData.length!==0 )
   window.print()
},[reportData])
  return (
    <DefaultLayout>
    <div>
      <button className='btn btn-primary m-3 p-2' onClick={()=>   state?.type==""?     dispatch<any>(resetOrder("reportAllOrder")).then(()=> navigate("/seller/orders",
                            ) )   : state?.type=="Awaiting" ?   dispatch<any>(resetOrder("reportAllOrder")).then(()=>navigate("/seller/awaitingShip",
                            ) ): state?.type=="PaidShipped"?  dispatch<any>(resetOrder("reportAllOrder")).then(()=> navigate("/seller/orderPaidShipped",
                            )) :state?.type=="Delivered" ?  dispatch<any>(resetOrder("reportAllOrder")).then(()=>navigate("/seller/deliveredOrder",
                            )) :""} >Go Back</button>
    <table className="tablePrint">
    <thead>
      <tr>
   


    { <>  <th className="order-width">Order Details</th>
        <th>Buyer name</th>
        <th>Quantity</th>
        <th className="date-width">Sold for</th>
        <th>Total</th>
        <th>Tracking no</th>
        <th>Date Sold</th>
        <th>Date Buyer Paid</th>
        <th className="date-width">Zip code</th>
        <th>Status</th>
        <th>Shipping Amount</th>
        <th>Note</th>
        <th>Shipping Date</th>
        </>
}
        {/* <th className="order-width">Order Details</th>
        <th>Status</th>
        <th>Qty</th>
        <th>Sold for</th>
        <th>Total</th>
        <th>Tracking no</th>
        <th className="date-width">Sale date</th>
        <th>Buyer paid date</th>
        <th>Zip code</th>
        <th>Engage Buyer</th>
        <th>Tracking no</th>
        <th className="date-width">Sale date</th>
        <th>Buyer paid date</th>
        <th>Zip code</th>
        <th>Engage Buyer</th> */}
      </tr>
    </thead>
    <tbody>
    {reportData?.map((order:any)=>
      <tr>
        
        <td className="order-details">
          <div>
           
            <div className="d-flex product-information">
            
              <div className="product-detail">
                <h6>{order.productName} </h6>
                <p>Condition:</p>
                <p>{order.condition}</p>
                <p>Order ID:</p>
                <p>{order.orderId}</p>
              </div>
            </div>
          </div>
        </td>
        <td>{order.buyerName}</td>
        <td className="qty">
          <span>{order?.quantity}</span> (Available {order?.stockAvailable})
        </td>
        <td className="sold-for">${order.soldFor}</td>
        <td className="total">${order.total}</td>
        <td className="total">{order.trackingNo}</td>
        <td className="date-sold">{order.orderDate}</td>
        <td className="date-buyer-paid">{order.buyerPaidDate}</td>
        <td className="zip-code">{order.zipCode}</td>
        
        <td className="total">{order.status}</td>
        <td className="date-sold">{order.shippingAmount}</td>
        <td className="date-buyer-paid">{order.sellerNote}</td>
        <td className="zip-code">{order.shippingDate}</td>
       
      </tr>)}
      
     
    </tbody>
  </table>
  </div>
  </DefaultLayout>
  )
}

export default PrintOrders