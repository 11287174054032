import { AppThunk } from "../../../../store";
import {

  
  SingleProductReviewFailed,
  SingleProductReviewSuccess,
} from "./slice";
import { fetchFeedback, fetchSingleReview, saveFeedback } from "./api";
import { EndProgress } from "../../../progressBar/endProgress";
import { StartProgress } from "../../../progressBar/startProgress";

export const getSingleProductReview =
  (params:number): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
      const data: any = await fetchSingleReview(params);
      

      if (data.success === "N") {
        
         dispatch(
          SingleProductReviewFailed(data));
      
      } else {
        
      dispatch(SingleProductReviewSuccess(data));
      }
    } catch (err) {
      
     dispatch(SingleProductReviewFailed(err));
    }
    dispatch<any>(EndProgress());
  };
