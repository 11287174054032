import { AppThunk } from "../../store";
import { LisitngSuccess, ListingFailed } from "./slice";
import { fetchScheduledListing, fetchUnsoldListing } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const getScheduledListing =
  (data: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
      const productData: any = await fetchScheduledListing(data);

      if (productData.success === "N") {
        dispatch(ListingFailed(productData));
      } else {
        dispatch(LisitngSuccess(productData));
      }
    } catch (err) {
      dispatch(ListingFailed(err));
    }
    dispatch<any>(EndProgress());
  };
