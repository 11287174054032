import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";


export const RecentViewLoading = (props: any) => {

  const sliderSettings = {
    320: {
      slidesPerView: 2,
    },
    480: {
      slidesPerView: 2,
    },
    576: {
      slidesPerView: 3,
    },
    1200: {
      slidesPerView: 5,
      spaceBetween: 20
    }
  };

  return (
        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={50}
          slidesPerView={5}
          breakpoints={sliderSettings}
          navigation={{
            nextEl: '.next-slide',
            prevEl: '.prev-slide',
          }}
          pagination={{ clickable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          
              <SwiperSlide className="recentListSwiper recentViewLoading">
                <div className="recent-list-item">
                  
                  <figure className="recent-media loading-animate">
                  </figure>

                  <div className="recent-details">
                    <div className="recent-price loading-animate"></div>
                    <div className="recent-price-small loading-animate"></div>
                  </div>
                </div>
              </SwiperSlide>


              <SwiperSlide className="recentListSwiper recentViewLoading">
                <div className="recent-list-item">
                  
                  <figure className="recent-media loading-animate">
                  </figure>

                  <div className="recent-details">
                    <div className="recent-price loading-animate"></div>
                    <div className="recent-price-small loading-animate"></div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="recentListSwiper recentViewLoading">
                <div className="recent-list-item">
                  
                  <figure className="recent-media loading-animate">
                  </figure>

                  <div className="recent-details">
                    <div className="recent-price loading-animate"></div>
                    <div className="recent-price-small loading-animate"></div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="recentListSwiper recentViewLoading">
                <div className="recent-list-item">
                  
                  <figure className="recent-media loading-animate">
                  </figure>

                  <div className="recent-details">
                    <div className="recent-price loading-animate"></div>
                    <div className="recent-price-small loading-animate"></div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="recentListSwiper recentViewLoading">
                <div className="recent-list-item">
                  
                  <figure className="recent-media loading-animate">
                  </figure>

                  <div className="recent-details">
                    <div className="recent-price loading-animate"></div>
                    <div className="recent-price-small loading-animate"></div>
                  </div>
                </div>
              </SwiperSlide>
        </Swiper>
  );

}