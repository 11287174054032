import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from "react";
import { useNavigate } from "react-router-dom";
import { deleteToken } from "../modules/userLogin/deleteToken";
import { UserLoginResponseSelector } from "../modules/userLogin/selectors";
import { ResetUserLogin } from "../modules/userLogin/slice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../utils/types";
import { resetLogout } from "../modules/userLogin/resetLogout";

const SessionLogout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userResponse = useSelector<RootState, RootState["login"]>(
    UserLoginResponseSelector
  );

  // const doSomethingBeforeUnload = () => {};
  // const setupBeforeUnloadListener = () => {
  //   window.addEventListener("beforeunload", (ev) => {
  //     ev.preventDefault();
  //     doSomethingBeforeUnload();
  //   });
  // };
  // useEffect(() => {
  //   setupBeforeUnloadListener();
    
  // });
  useEffect(() => {
    if (userResponse.deleteToken != null && userResponse.deleteToken === true) {
      dispatch<any>(ResetUserLogin());

      localStorage.removeItem("authorization");
      localStorage.removeItem("refresh");
      localStorage.removeItem("error");
      localStorage.removeItem("userId");
      
      localStorage.removeItem("storeAddressLinked");
      localStorage.removeItem("storeNameLinked");
      localStorage.removeItem("payPalAccountLinked");
      localStorage.removeItem("UserName");
      localStorage.removeItem("productId");
      localStorage.removeItem("userCartItem");
      
      let role = localStorage.getItem("role");
      if (role === "Customer") {
        localStorage.removeItem("role");
        window.location.reload();
        // navigate("/login");
      } else if (role === "Seller") {
        localStorage.removeItem("role");

        navigate("/login-seller");
      }
      // dispatch<any>(resetLogout());
    }
  }, [userResponse]);

  useEffect(() => {
    if (
      userResponse.deleteToken === true ||
      (localStorage.getItem("autoLogout") &&
        userResponse.deleteToken === false) ||
      (userResponse.deleteTokenError !== null &&
        userResponse.deleteToken === false)
    ) 
    
    
    {
      dispatch<any>(ResetUserLogin());
      localStorage.removeItem("authorization");
      localStorage.removeItem("refresh");
      localStorage.removeItem("error");
      localStorage.removeItem("userId");
      localStorage.removeItem("storeAddressLinked");
      localStorage.removeItem("storeNameLinked");
      localStorage.removeItem("payPalAccountLinked");
      
      localStorage.removeItem("UserName");
      localStorage.removeItem("productId");
      let role = localStorage.getItem("role");
      if (role === "Customer") {
        localStorage.removeItem("role");
        window.location.reload();
        // navigate("/login");
      } else if (role === "Seller") {
        localStorage.removeItem("role");

        navigate("/login-seller");
      }
      // dispatch<any>(resetLogout());
    }
  }, [userResponse]);

  return <Fragment />;
};

export default SessionLogout;
