import { AppThunk } from "../../../store";
import { SaveWatchlistFailed, SaveWatchlistSuccess} from "./slice";
import { postRecentlyViewed, postWatchlist } from "./api";

export const setWatchlist =
  (params: any): AppThunk =>
  async (dispatch) => {
    try {
      const data: any = await postWatchlist(params);
      

      if (data.success === "N") {
        
        dispatch(SaveWatchlistFailed(data));
      } else {
        
        dispatch(SaveWatchlistSuccess(data));
      }
    } catch (err) {
      
      dispatch(SaveWatchlistFailed(err));
    }
  };
