import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { deleteToken } from "../../modules/userLogin/deleteToken";
import { setSocialDetails } from "../../modules/userLogin/setSocialDetails";
import { reset } from "../../modules/sellerProduct/reset";
import { resetLogout } from "../../modules/userLogin/resetLogout";

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Logout = () => {
    dispatch<any>(
      setSocialDetails({
        provider: "",
        profile: "",
        type: "logout",
      })
    )
      .then(dispatch<any>(deleteToken({})))
      .then(dispatch<any>(resetLogout()));
  };
  return (
    <div>
      {" "}
      <header className="header">
        <div className="logo-section">
          <div className="container">
            <div className="row seller-header-row">
              <div className="col-md-2">
                <div className="logo">
                  <Link
                 
                    to="/seller/overview"
                  >
                    <i className="logo-img"></i>
                  </Link>
                </div>
              </div>
              <div className="col-md-10">
                <div className="top-section seller-header-section">
                  <div className="top-section-right">
                    <ul className="list-unstyled">
                      <li>
                        <a
                          href="#"
                          onClick={() => {
                            navigate("/seller/storeFeedback");
                          }}
                        >
                          My store
                        </a>
                      </li>
                      <li>
                        <a href="#" onClick={Logout}>
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
