import { AppThunk } from "../../store";
import { Orders } from "../../utils/types";
import { OrderSuccess, OrderFailed } from "./slice";
import { fetchAwaitingShipment, fetchOrders } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const getAwaitingShipment =
  (data: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch<any>(StartProgress());

      const ordersData: any = await fetchAwaitingShipment(data);
      

      if (ordersData.success === "N") {
        
        dispatch(OrderFailed(ordersData));
      } else {
        
        dispatch(OrderSuccess(ordersData));
      }

      //return DashboardData
    } catch (err) {
      
      dispatch(OrderFailed(err));
      //return err.toString()
    }
    dispatch<any>(EndProgress());
  };
