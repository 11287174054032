import { AppThunk } from "../../store";
import { BidsSellerFailed, BidsSellerSuccess } from "./slice";
import { fetchActiveListing, fetchBidsSeller, fetchOffersSeller } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const getBidsSeller =
  (data: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
      const productData: any = await fetchBidsSeller(data);

      if (productData.success === "N") {
        dispatch(BidsSellerFailed(productData));
      } else {
        dispatch(BidsSellerSuccess(productData));
      }
    } catch (err) {
      dispatch(BidsSellerFailed(err));
    }
    dispatch<any>(EndProgress());
  };
