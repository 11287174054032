import React from "react";
interface Props {
  advance: any;
  setAdvance: any;
}

const AdvanceSearch = ({ advance, setAdvance }: Props) => {
  const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setAdvance({ ...advance, [name]: checked ? "true" : "" });
  };
  const reset = () => {
    setAdvance({
      ...advance,
      auction: "",
      fixed: "",
      condition: "",
      categoryId: "",
      startPrice: "",
      endPrice: "",
      titleAndDescription: "",
      showItemPriceFrom: "",
      minPrice: "",
      maxPrice: "",
      new: "",
      used: "",
      listing: "",
      notSpecified: "",
      soldItems: "",
      completedListings: "",
      noOfBidsFrom: "",
      minBiddingNo: "",
      maxBiddingNo: "",
    });
  };
  return (
    <div className="advancedSearch" style={{ display: advance.visible }}>
      <div className="advSearchWrap">
        <div className="container">
          <div className="rowWrap">
            <div className="row">
              <div className="col-lg-3">
                <div className="titleName">Search including</div>
                <div>
                  <div className="custom-check mb-3">
                    Title and description
                    <div className="wrap">
                      <input
                        type="checkbox"
                        id="titleAndDescription"
                        name="titleAndDescription"
                        onChange={handleCheckboxClick}
                        checked={
                          advance.titleAndDescription === "true" ? true : false
                        }
                      />
                      <label htmlFor="titleAndDescription"></label>
                    </div>
                  </div>

                  <div className="custom-check mb-3">
                    Completed listings
                    <div className="wrap">
                      <input
                        type="checkbox"
                        id="completedListings"
                        name="completedListings"
                        onChange={handleCheckboxClick}
                        checked={
                          advance.completedListings === "true" ? true : false
                        }
                      />
                      <label htmlFor="completedListings"></label>
                    </div>
                  </div>

                  <div className="custom-check mb-3">
                    Sold listings
                    <div className="wrap">
                      <input
                        type="checkbox"
                        id="soldItems"
                        name="soldItems"
                        onChange={handleCheckboxClick}
                        checked={advance.soldItems === "true" ? true : false}
                      />
                      <label htmlFor="soldItems"></label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="titleName">Price</div>
                <div>
                  <div className="custom-check mb-3">
                    Show items priced from $
                    <div className="wrap">
                      <input
                        type="checkbox"
                        id="showItemPriceFrom"
                        name="showItemPriceFrom"
                        onChange={handleCheckboxClick}
                        checked={
                          advance.showItemPriceFrom === "true" ? true : false
                        }
                      />
                      <label htmlFor="showItemPriceFrom"></label>
                    </div>
                  </div>
                  <div className="fromTo">
                    <input
                      type="number"
                      placeholder="00"
                      min="0"
                      value={advance.minPrice}
                      onChange={(e) =>
                        setAdvance({ ...advance, minPrice: e.target.value })
                      }
                    />
                    <span>to $</span>
                    <input
                      type="number"
                      placeholder="00"
                      min="0"
                      value={advance.maxPrice}
                      onChange={(e) =>
                        setAdvance({ ...advance, maxPrice: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="titleName">Buying formats</div>
                <div>
                  <div className="custom-check mb-3">
                    Auction
                    <div className="wrap">
                      <input
                        type="checkbox"
                        id="auction"
                        name="auction"
                        onChange={handleCheckboxClick}
                        checked={advance.auction === "true" ? true : false}
                      />
                      <label htmlFor="auction"></label>
                    </div>
                  </div>

                  <div className="custom-check mb-3">
                    Fixed price
                    <div className="wrap">
                      <input
                        type="checkbox"
                        id="fixed"
                        name="fixed"
                        onChange={handleCheckboxClick}
                        checked={advance.fixed === "true" ? true : false}
                      />
                      <label htmlFor="fixed"></label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="titleName">Condition</div>
                <div>
                  <div className="custom-check mb-3">
                    New
                    <div className="wrap">
                      <input
                        type="checkbox"
                        id="new"
                        name="new"
                        onChange={handleCheckboxClick}
                        checked={advance.new === "true" ? true : false}
                      />
                      <label htmlFor="new"></label>
                    </div>
                  </div>

                  <div className="custom-check mb-3">
                    Used
                    <div className="wrap">
                      <input
                        type="checkbox"
                        id="used"
                        name="used"
                        onChange={handleCheckboxClick}
                        checked={advance.used === "true" ? true : false}
                      />
                      <label htmlFor="used"></label>
                    </div>
                  </div>

                  <div className="custom-check mb-3">
                    Not specified
                    <div className="wrap">
                      <input
                        type="checkbox"
                        id="notSpecified"
                        name="notSpecified"
                        onChange={handleCheckboxClick}
                        checked={advance.notSpecified === "true" ? true : false}
                      />
                      <label htmlFor="notSpecified"></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rowWrap">
            <div className="row">
              <div className="col-lg-8">
                <div className="titleName">Show results</div>
                <div className="row">
                  <div className="col-md-auto">
                    <div className="custom-check mb-3">
                      Listings
                      <div className="wrap">
                        <input
                          type="checkbox"
                          id="listing"
                          name="listing"
                          onChange={handleCheckboxClick}
                          checked={advance.listing === "true" ? true : false}
                        />
                        <label htmlFor="listing"></label>
                      </div>
                    </div>

                    {/* <div className="twoCol">
                      <select name="" id="" className="form-control">
                        <option value="">Select</option>
                      </select>
                      <select name="" id="" className="form-control">
                        <option value="">Select</option>
                      </select>
                    </div> */}
                  </div>
                  <div className="col-md-auto">
                    <div className="custom-check mb-3">
                      Number of bids from
                      <div className="wrap">
                        <input
                          type="checkbox"
                          id="noOfBidsFrom"
                          name="noOfBidsFrom"
                          onChange={handleCheckboxClick}
                          checked={
                            advance.noOfBidsFrom === "true" ? true : false
                          }
                        />
                        <label htmlFor="noOfBidsFrom"></label>
                      </div>
                    </div>
                    <div className="fromTo">
                      <input
                        type="number"
                        placeholder="00"
                        min="0"
                        value={advance.minBiddingNo}
                        onChange={(e) =>
                          setAdvance({
                            ...advance,
                            minBiddingNo: e.target.value,
                          })
                        }
                      />
                      <span>to $</span>
                      <input
                        type="number"
                        placeholder="00"
                        min="0"
                        value={advance.maxBiddingNo}
                        onChange={(e) =>
                          setAdvance({
                            ...advance,
                            maxBiddingNo: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="rowWrap">
            <div className="row">
              <div className="col-lg-2 col-md-3">
                <div className="titleName">Sort by</div>
                <select name="" id="" className="form-control w-100">
                  <option value="">Best Match</option>
                </select>
              </div>
              <div className="col-lg-2 col-md-3">
                <div className="titleName">View results</div>
                <select name="" id="" className="form-control w-100">
                  <option value="">All Items</option>
                </select>
              </div>
            </div>
          </div> */}
          <div className="buttonCol">
            <button className="clearBtn as-btn" onClick={() => reset()}>
              Clear search
            </button>
            <button
              className="cancelBtn as-btn"
              onClick={() =>
                setAdvance({
                  ...advance,
                  visible: advance.visible === "none",
                })
              }
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvanceSearch;
