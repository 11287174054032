import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../../components/header/Header";
import { DefaultLayout } from "../../../layout/DefaultLayout";
import { dateToLocal } from "../../../modules/date/date";
import { getOrders } from "../../../modules/orders/getOrders";
import { SellerOrdersResponseSelector } from "../../../modules/orders/selectors";
import { RootState } from "../../../utils/types";
import ReactPaginate from "react-paginate";
import { BASE_URLS } from "../../../utils/api.urls";
import { getCommonData } from "../../../modules/common/getCommonData";
import { CommonResponseSelector } from "../../../modules/common/selectors";
import { getBuyerNames } from "../../../modules/orders/getBuyerNames";
import { setTrackingManually } from "../../../modules/orders/setTrackingManually";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notify } from "../../../modules/notification/notify";
import { constant } from "../../../utils/constant";
import { resetOrder } from "../../../modules/orders/resetOrder";
import { SellerProductResponseSelector } from "../../../modules/sellerProduct/selector";
import { getActiveListing } from "../../../modules/sellerProduct/getActiveListing";
import { getSoldListing } from "../../../modules/sellerProduct/getSoldListing";
import { reset } from "../../../modules/sellerProduct/reset";
import { resetCategorySuccess } from "../../../modules/categories/slice";
import ProdNavPanel from "./ProdNavPanel";
import ProductFilter from "./ProductFilter";
import { common } from "../../../modules/common/slice";
import { getSellerCategories } from "../../../modules/categories/getSellerCategories";
import { CategoryResponseSelector } from "../../../modules/categories/selector";
import { changeProductStatus } from "../../../modules/sellerProduct/changeProductStatus";
import HeaderNavigationSeller from "../HeaderNavigationSeller";
import { getListingReport } from "../../../modules/sellerProduct/getListingReport";
import { CSVLink } from "react-csv";

const SoldListing = () => {
  const product = useSelector<RootState, RootState["sellerProduct"]>(
    SellerProductResponseSelector
  );
  const commonResponse = useSelector<RootState, RootState["common"]>(
    CommonResponseSelector
  );
  const categoryResponse = useSelector<RootState, RootState["categories"]>(
    CategoryResponseSelector
  );
  const navigate = useNavigate();
  const csvLink = useRef<any>()
  const [reportData, setReportData] = useState([] as any);
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(10);
  const [currentPage, setcurrentPage] = useState(0);
  const [filter, setFilter] = useState({
    CategoryId: "",
    Search: "",
    Format: "",
    StartDate: "",
    EndDate: "",
  });
  const isFirstRender = useRef(true);
  const isFirstRender2 = useRef(true);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  if (product?.listing?.data?.products !== null && pageCount === 0) {
    setPageCount(Math.ceil(product?.listing?.data?.totalResult / itemCount));
  }
  useEffect(() => {
    if (isFirstRender.current) {
      dispatch<any>(
        getSoldListing({
          currentPage: currentPage + 1,
          rows: itemCount,
          CategoryId: filter.CategoryId,
          Search: filter.Search,
          Format: filter.Format,
          StartDate: filter.StartDate,
          EndDate: filter.EndDate,
        })
      ).then(() => {
        if (currentPage === 0) setPageCount(0);
      });
    }
    isFirstRender.current = false;
    if (commonResponse?.common === null) dispatch<any>(getCommonData());
    dispatch<any>(getSellerCategories());
  }, []);

  useEffect(() => {
    if (!isFirstRender2.current) {
      dispatch<any>(
        getSoldListing({
          currentPage: currentPage + 1,
          rows: itemCount,
          CategoryId: filter.CategoryId,
          Search: filter.Search,
          Format: filter.Format,
          StartDate: filter.StartDate,
          EndDate: filter.EndDate,
        })
      ).then(() => {
        if (currentPage === 0) setPageCount(0);
      });
    }

    isFirstRender2.current = false;
  }, [currentPage, itemCount, filter]);

  useEffect(() => {
    if (product.statusChange != null) {
      if (product.statusChange.success == "Y") {
        notify(product.statusChange.message, constant.SUCCESS);
        dispatch<any>(
          getSoldListing({
            currentPage: currentPage + 1,
            rows: itemCount,
            CategoryId: filter.CategoryId,
            Search: filter.Search,
            Format: filter.Format,
            StartDate: filter.StartDate,
            EndDate: filter.EndDate,
          })
        ).then(() => {
          if (currentPage === 0) setPageCount(0);
        });
      } else if (product.statusChange.success == "N") {
        notify(product.statusChange.message, constant.ERROR);
      }
    }
    if (product.statusChangeError !== null) {
      notify(product.statusChangeError, constant.ERROR);
    }
    dispatch<any>(reset("saveData"));
  }, [product.statusChange, product.statusChangeError]);

  const handlePageChange = (selectedObject: any) => {
    setcurrentPage(selectedObject.selected);
  };

  const handleItemsChange = (event: any) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };
  const handleFilterChange = (event: any) => {
    setcurrentPage(0);
    if (event.target.name === "Search")
      setFilter({ ...filter, Search: search });
    else setFilter({ ...filter, [event.target.name]: event.target.value });
  };
  const resetFilter = () => {
    setcurrentPage(0);
    setFilter({
      CategoryId: "",
      Search: "",
      Format: "",
      StartDate: "",
      EndDate: "",
    });
    setSearch("");
  };
  useEffect(() => {
    if (product?.listingReport) {
      let userData: any = [];
      product?.listingReport?.data?.products?.map((product:any) => {
          let datalist: any = {};
       
          datalist["active"] = product?.active =="Y" ? "Activated" : "Deactivated";
          datalist["buyNowPrice"] = product?.buyNowPrice;
          datalist["productName"] = product?.productName;
          datalist["quantity"] = product?.quantity;
          datalist["upc"] = product?.upc;
          datalist["relistStatus"] = product?.relistStatus;
          datalist["views"] = product?.views;

          userData.push(datalist);
        });
      setReportData(userData);
    } 
  }, [product?.listingReport]);


useEffect(()=>{

if(reportData.length>0)
{
csvLink.current.link.click()}
},[reportData])
  return (
    <DefaultLayout>
      <Header />
      <ToastContainer />

      <HeaderNavigationSeller
     currentPage="Listing"
     />

      <main className="seller-page-section">
        <div className="container seller-orders-section">
          <div className="row">
            <ProdNavPanel currentPage="Sold" />
            <div className="col-md-10">
              <ProductFilter
                setFilter={setFilter}
                filter={filter}
                common={commonResponse}
                categories={categoryResponse}
                handleFilterChange={handleFilterChange}
                setSearch={setSearch}
                search={search}
                resetFilter={resetFilter}
              />
              <div className="filter-result mt-3">
                <div className="total-result d-flex justify-content-between mb-4">
                  <h5>Results: {product?.listing?.data?.totalResult}</h5>
           { product?.listing?.data?.totalResult!==0 &&       <ul className="d-flex">
                    <li>
                    <span style={{cursor:"pointer", color:"blue"}}  onClick={()=>
                          navigate("/seller/printUnsoldListing", {
                            state: {
                              currentPage: 1,
                              rows:product?.listing?.data?.totalResult ,
                              CategoryId: filter.CategoryId,
                              Search: filter.Search,
                              Format: filter.Format,
                              StartDate: filter.StartDate,
                              EndDate: filter.EndDate,
                              type:"Sold",},
                          })
                         } >Print</span>
                    </li>
                    <li className="pipe">|</li>
                    <li>
                    <span style={{cursor:"pointer", color:"blue"}} onClick={()=>dispatch<any>(
      getListingReport({
        currentPage: 1,
        rows:product?.listing?.data?.totalResult ,
        CategoryId: filter.CategoryId,
        Search: filter.Search,
        Format: filter.Format,
        StartDate: filter.StartDate,
        EndDate: filter.EndDate,
        type:"Sold"
      }))} >Download reports</span>
      <CSVLink
         data={reportData}
         filename='report'
         className='hidden'
         onClick={() =>
          dispatch<any>(reset("report"))
            .then({
              done() {},
            })
        }
         headers={constant?.UNSOLD_LISTING}
         ref={csvLink}
         target='_blank'
      />
                    </li>
                  </ul>}
                </div>

                <div>
                  <table className="result-table">
                    <thead>
                      <tr>
                        <th className="action-width">Action</th>
                        <th>Status</th>
                        <th className="">Photo</th>
                        <th>Title</th>

                        <th>Current price</th>
                        <th>Quantity available</th>
                        <th>Views</th>
                        <th>Relist status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {product?.listing?.data?.products?.map(
                        (order: any, Index: number) => (
                          <tr>
                            <td className="action">
                              <button
                                type="button"
                                className={`${
                                  order.active == "Y"
                                    ? "btn btn-danger"
                                    : "btn btn-success"
                                }`}
                                onClick={() =>
                                  dispatch<any>(
                                    changeProductStatus({
                                      Id: order.productId,
                                      Active: order.active === "Y" ? "N" : "Y",
                                    })
                                  )
                                }
                              >
                                {order.active == "Y"
                                  ? "Deactivate"
                                  : "Activate"}
                              </button>
                            </td>
                            <td
                              style={{
                                color: order.active == "Y" ? "green" : "red",
                              }}
                            >
                              {order.active == "Y"
                                ? "Activated"
                                : "Deactivated"}
                            </td>
                            <td className="product-information">
                              <figure
                          style={{
                            height: 60,
                            width: 60,
                            cursor:"pointer"
                          }}
                          onClick={() => {
                            localStorage.setItem(
                              "productId",
                              order.productId
                            );
                            dispatch<any>(reset())
                              .then(dispatch<any>(resetCategorySuccess()))
                              .then(navigate("/seller/editListing"));
                          }}
                              >
                                <img
                                  src={BASE_URLS.AWS_URL +"/Thumb" + order.productImage}
                                  alt="dummy image"
                                  style={{
                                    height: 50,
                                    width: 50,
                                  }}
                                />
                              </figure>
                            </td>
                            <td className="">
                              <div
                                className="product-detail"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  localStorage.setItem(
                                    "productId",
                                    order.productId
                                  );
                                  dispatch<any>(reset())
                                    .then(dispatch<any>(resetCategorySuccess()))
                                    .then(navigate("/seller/editListing"));
                                }}
                              >
                                <h6>{order.productName}</h6>
                              </div>
                            </td>

                            <td className="total"> ${order.buyNowPrice}</td>
                            <td className="date-sold">{order.quantity}</td>
                            <td className="date-buyer-paid">{order.views}</td>
                            <td className="zip-code">{order.relistStatus}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
                <nav className="pagination-block ">
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    forcePage={currentPage}
                    onPageChange={handlePageChange}
                    containerClassName={
                      "pagination justify-content-center mt-3"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />

                  <div className="total-items-block">
                    <label>Items per page:</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={handleItemsChange}
                      value={itemCount}
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </main>
    </DefaultLayout>
  );
};

export default SoldListing;
