import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TotalCartItems } from "../../utils/types";

type CartItemsState = {
  totalProducts: TotalCartItems["totalProducts"] | null;
};

const initialState: CartItemsState = {
  totalProducts: 0
};

const slice = createSlice({
  name: "progress",
  initialState,
  reducers: {
    cartItemsSuccess: (state, action: PayloadAction<any>) => {
      state.totalProducts = action.payload;
    },
    cartItemsReset: (state) => {
      state.totalProducts = 0;
    },
  },
});

export const totalCartItems = slice.reducer;

export const { cartItemsSuccess, cartItemsReset } =
  slice.actions;
