import { AppThunk } from "../../store"
import {SinglePageReset} from "./slice"

export const resetSinglePage = (): AppThunk => async (dispatch) => {
    try {
        dispatch(SinglePageReset());
    } catch (err) {
        
    }
}
