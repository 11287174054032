import Api from "../../../../utils/api";
import { API_URLS } from "../../../../utils/api.urls";


export const fetchProfile = async (): Promise<any> => {
    const data = await Api.get(
        API_URLS.PROFILE + '/' +
        "?$expand=Addresses($expand=Country($select=CountryName)&$expand=State($select=StateName))",
        []
    );
    return data;
};

export const updateUserNameApi = async (params: any | null): Promise<any> => {
    const data = await Api.post(API_URLS.BUYER.SAVE_USERNAME, params);
    return data;
};
export const fetchSavedPayments = async (): Promise<any> => {
    const data = await Api.get(API_URLS.BUYER.SAVED_PAYMENTS, []);
    return data;
};

export const deleteSavedPayments = async (params:string): Promise<any> => {
    const data = await Api.delete(API_URLS.BUYER.SAVED_PAYMENTS+"/"+params, []);
    return data;
};

export const updateMobileNoApi = async (params: any | null): Promise<any> => {
    const data = await Api.post(API_URLS.BUYER.SAVE_MOBILE_NO, params);
    return data;
};
export const deactivateUserApi = async (params: any | null): Promise<any> => {
    const data = await Api.post(API_URLS.BUYER.DEACTIVATE_USER, params);
    return data;
};
export const deactivateSellerApi = async (params: any | null): Promise<any> => {
    const data = await Api.post(API_URLS.DEACTIVATE_SELLER, params);
    return data;
};

export const activateUserApi = async (params: any | null): Promise<any> => {
    const data = await Api.patch(API_URLS.ACTIVATE_USER+params, []);
    return data;
};

export const updateProfileAddressApi = async (params: any | null): Promise<any> => {
    const data = await Api.post(API_URLS.BUYER.SAVE_PROFILE_ADDRESS, params);
    return data;
};

export const updateShippingAddressApi = async (params: any | null): Promise<any> => {
    const data = await Api.post(API_URLS.BUYER.SAVE_SHIPPING_ADDRESS, params);
    return data;
};

export const postDefaultShippingAddressApi = async (params: any | null): Promise<any> => {
    const data = await Api.patch(API_URLS.BUYER.SAVE_SHIPPING_ADDRESS_DEFAULT+"/"+params, []);
    return data;
};
export const deleteShippingAddressApi = async (Id: number | null): Promise<any> => {
    const data = await Api.delete(API_URLS.BUYER.DELETE_SHIPPING_ADDRESS + "/" + Id, []);
    return data;
};

