import React from "react";
import { Link, useNavigate } from "react-router-dom";

interface Props {
  filter: any;
  common: any;
  handleFilterChange: Function;
  setSearch: any;
  search: string;
  resetFilter: any;
}
const PaymentFilter = ({

  filter,

  setSearch,
  search,
 
  handleFilterChange,
  resetFilter,
  
}: Props) => {

  const navigate = useNavigate();
  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      // Handle the Enter key press here
      handleFilterChange({
        target: {
          name: "Search",
        },
      })
      // You can call a function or perform any action
    }
  };
  return (
    <div  className="all-orders-filters all-products-filter d-flex justify-content-between">
    <div className="listing-date-filter d-flex">
       <div className="date-filter-product-group d-flex">
       
        <div className="search-flex-format d-flex">
          <div className="form-group ml-3">
            <label>Payment type</label>
            <select
              className="form-control input-seller"
              name="Format"
              value={filter.Format}
              onChange={(e) => handleFilterChange(e)}
            >
              <option value="">All</option>
              <option value="1">Refund</option>
            
            </select>
          </div>
        </div>
      </div>

      <div className="search-filter-product-group d-flex">
        <div className="form-group ml-3 search-cat-form-group">
          <label>Duration</label>
          <select
            className="form-control input-seller"
            name="Days"
            value={filter.Days}
            onChange={(e) => handleFilterChange(e)}
          >
            <option value="">Select</option>
            <option value="90">Last 90 days</option>
            <option value="45">Last 45 days</option>
            <option value="30">Last 30 days</option>

           
          </select>
        </div>

        {<div className="form-group ">
          <label className="pl-10" style={{ paddingLeft: 10 }}>
            Search by order id
          </label>
          <div className="d-flex ml-3">
            <div className="d-flex search-bar">
              <input
                type="text"
                placeholder="Search"
                className="form-control"
                value={search}
                onKeyDown={handleKeyPress}

                onChange={(e) => setSearch(e.target.value)}
              />
              <button
                className="search-icon"
                name="Search"
                onClick={(e) =>
                  handleFilterChange({
                    target: {
                      name: "Search",
                    },
                  })
                }
              >
                <span></span>
              </button>
            </div>

            <button className="reload-icon" onClick={() => resetFilter()}>
              <span></span>
            </button>
          </div>
        </div>}
      </div>
    </div>
 
    </div>
  );
};

export default PaymentFilter;
