import React, { useEffect, useState } from 'react'
// import "./printOrder.scss"
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../utils/types'
import { dateToLocal } from '../../../modules/date/date'
import { getListingReport } from '../../../modules/sellerProduct/getListingReport'
import { SellerProductResponseSelector } from '../../../modules/sellerProduct/selector'
import { reset } from '../../../modules/sellerProduct/reset'
import { DefaultLayout } from '../../../layout/DefaultLayout'
const PrintEndedListing = () => {
    const {state}= useLocation()
    const dispatch = useDispatch();
  const navigate = useNavigate();
   

      const [reportData, setReportData] = useState([] as any);
      const product = useSelector<RootState, RootState["sellerProduct"]>(
        SellerProductResponseSelector
      );

useEffect(()=>{
    dispatch<any>(
      getListingReport({
        currentPage: 1,
        rows:state?.rows ,
        CategoryId: state?.CategoryId,
        Search: state?.Search,
        Format: state?.Format,
        StartDate: state?.StartDate,
        EndDate: state?.EndDate,
        type:state?.type
      }))
},[state])

useEffect(() => {
  if (product?.listingReport) {
    let userData: any = [];
    product?.listingReport?.data?.products?.map((product:any) => {
        let datalist: any = {};
     
        datalist["active"] = product?.active =="Y" ? "Activated" : "Deactivated";
        datalist["views"] = product?.views;
        datalist["buyNowPrice"] = product?.buyNowPrice;
        datalist["productName"] = product?.productName;
        datalist["quantity"] = product?.quantity;
        datalist["upc"] = product?.upc;
        userData.push(datalist);
      });
    setReportData(userData);
dispatch<any>(reset("report"))

  } 
}, [product?.listingReport]);

  useEffect(()=>{
   if(reportData.length!==0 )
   window.print()
},[reportData])
  return (
    <DefaultLayout>
    <div>
    <button className='btn btn-primary m-3 p-2' onClick={()=>     dispatch<any>(reset("report")).then(()=> navigate("/seller/endedListing",
))} >Go Back</button>
    <table className="result-table">
    <thead>
      <tr>
   


    { <>  <th className="order-width">Product name</th>
        <th>Status</th>
        <th>Current price</th>
        <th>Available quantity</th>
        <th>UPC</th>

        <th > views</th>

        
        </>
}
        
      </tr>
    </thead>
    <tbody>
    {reportData?.map((order:any)=>
      <tr>
        
        <td className="order-details">
          <div>
           
            <div className="d-flex product-information">
            
              <div className="product-detail">
                <h6>{order.productName} </h6>
                
              </div>
            </div>
          </div>
        </td>
        <td>{order.active}</td>

        <td className="sold-for">${order.buyNowPrice}</td>
        <td className="qty">
          <span>{order?.quantity}</span> 
        </td>
        <td className="total">{order.upc}</td>
        <td className="total">{order.views}</td>
       
       
      </tr>)}
      
     
    </tbody>
  </table>
  </div>
  </DefaultLayout>
  )
}

export default PrintEndedListing;