import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Sales } from "../../utils/types";

type SalesState = {
  sales: Sales["sales"] | null;
  error: any | null;
};

const initialState: SalesState = {
  sales: null,
  error: null,
};

const slice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    SalesSuccess: (state, action: PayloadAction<any>) => {
      state.sales = action.payload;
      state.error = null;
      
    },
    SalesFailed: (state, action: PayloadAction<any>) => {
      state.sales = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },

    ResetDashboard: (state) => {
      state.sales = null;
      state.error = null;
    },
  },
});

export const sales = slice.reducer;

export const {
  SalesFailed,
  SalesSuccess,

  ResetDashboard,
} = slice.actions;
