import { AppThunk } from "../../store";
import { PaymentFailed, PaymentSuccess } from "./slice";
import { fetchActiveListing, fetchBidsSeller, fetchOffersSeller, fetchPaymentsSeller } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const getPaymentsSeller =
  (data: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
      const productData: any = await fetchPaymentsSeller(data);

      if (productData.success === "N") {
        dispatch(PaymentFailed(productData));
      } else {
        dispatch(PaymentSuccess(productData));
      }
    } catch (err) {
      dispatch(PaymentFailed(err));
    }
    dispatch<any>(EndProgress());
  };
