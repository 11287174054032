import { AppThunk } from "../../store";
import { StateSuccess, StateFailed } from "./slice";
import { fetchStateByCountry } from "./api";
import { EndProgress } from "../progressBar/endProgress";
import { StartProgress } from "../progressBar/startProgress";

export const getStateByCountryCode =
  (data: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch<any>(StartProgress());
      const stateData: any = await fetchStateByCountry(data);

      if (stateData.success === "N") {
        
        dispatch(StateFailed(stateData));
      } else {
        
        dispatch(StateSuccess(stateData));
      }
    } catch (err) {
      
      dispatch(StateFailed(err));
    }
    dispatch<any>(EndProgress());
  };
