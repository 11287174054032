import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getForgetPassword } from "../../modules/forgetPassword/getForgetPassword";
import { reset } from "../../modules/forgetPassword/reset";
import { forgetPasswordResponseSelector } from "../../modules/forgetPassword/selectors";
import { notify } from "../../modules/notification/notify";
import { RootState } from "../../utils/types";
import "react-toastify/dist/ReactToastify.css";
import { sendMobileOtp } from "../../modules/mobileOtp/sendMobileOtp";
import { mobileOtpResponseSelector } from "../../modules/mobileOtp/selectors";
import { resetOtp } from "../../modules/mobileOtp/resetOtp";
import { verifyMobileOtp } from "../../modules/verifyOtp/verifyMobileOtp";
import { verifyOtpResponseSelector } from "../../modules/verifyOtp/selectors";
import { resetVerifyOtp } from "../../modules/verifyOtp/resetVerifyOtp";

const ForgotPassword = () => {
  const navigate = useNavigate();

  let forgotPasswordResponse = useSelector<
    RootState,
    RootState["forgotpassword"]
  >(forgetPasswordResponseSelector);

  let mobileOtp = useSelector<RootState, RootState["mobileotp"]>(
    mobileOtpResponseSelector
  );

  let verifyOtpResponse = useSelector<RootState, RootState["verifyotp"]>(
    verifyOtpResponseSelector
  );

  const [visibile, setVisible] = useState(false);
  const [mobileNum, setMobileNum] = useState("");
  const [data, setData] = useState({
    emailId: "",
    mobileNo: "",
    otp: "",
  });
  const setUserData = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (forgotPasswordResponse?.data != null) {
      if (forgotPasswordResponse.data.success == "Y") {
        notify(forgotPasswordResponse.data.message, "success");
      } else if (forgotPasswordResponse.data.success == "N") {
        notify(forgotPasswordResponse.data.message, "error");
      }
      setData({
        emailId: "",
        mobileNo: "",
        otp: "",
      });
      dispatch<any>(reset());
    }
    if (forgotPasswordResponse?.error != null) {
      notify(forgotPasswordResponse.error, "error");
      dispatch<any>(reset());
    }
  }, [forgotPasswordResponse]);
  useEffect(() => {
    if (mobileOtp.data != null) {
      setVisible(true);
      notify(mobileOtp.data.message, "success");

      setData({
        emailId: "",
        mobileNo: "",
        otp: "",
      });
    }
    if (mobileOtp.error != null) {
      notify(mobileOtp.error, "error");
      dispatch<any>(resetOtp());
    }
  }, [mobileOtp]);

  const sendEmail = (e: any) => {
    e.preventDefault();
    dispatch<any>(getForgetPassword(data));
  };
  const sendOtp = (e: any) => {
    e.preventDefault();
    setMobileNum(data.mobileNo);
    dispatch<any>(sendMobileOtp(data));
  };
  const verifyOtp = (e: any) => {
    e.preventDefault();

    dispatch<any>(
      verifyMobileOtp({
        mobileNo: mobileNum,
        otp: data.otp,
      })
    );
  };

  useEffect(() => {
    if (verifyOtpResponse?.data != null) {
      if (verifyOtpResponse.data.success == "Y") {
        setVisible(true);
        notify(verifyOtpResponse.data.message, "success");
      } else if (verifyOtpResponse.data.success == "N") {
        notify(verifyOtpResponse.data.message, "error");
      }
      setData({
        emailId: "",
        mobileNo: "",
        otp: "",
      });
      navigate("/reset-password/reset-pass?id=" + verifyOtpResponse.data.data);
      dispatch<any>(resetVerifyOtp());
      dispatch<any>(resetOtp());
    }
    if (verifyOtpResponse?.error != null) {
      notify(verifyOtpResponse.error, "error");
      dispatch<any>(resetVerifyOtp());
    }
  }, [verifyOtpResponse]);
  return (
    <div>
      <ToastContainer />

      <header className="header">
        <div className="logo-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="logo-container">
                  <div className="logo">
                  <Link to={"/home"}>   <i className="logo-img"></i></Link>
                   
                 
                  </div>
                  <div className="login-link">
                    New User? <Link to={"/register"}>create an account</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="forgot-section min-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="forgot-container">
                <form className="form-block">
                  <h2 className="h2-head text-center">Get help signing in</h2>
                  <div className="forgot-text">
                    For your security, we need to make sure it's really you
                    <br />
                    {/* How do you want us to contact you? */}
                  </div>

                  <div className="forgot-list-block fg-border">
                    <div className="forgot-list-item">
                      <div className="forgot-list-img">
                        <i className="icon-email"></i>
                      </div>
                      <div className="forgot-list-text">
                        <div className="forgot-large-text">Send an email</div>
                        <div className="forgot-small-text">
                          We'll email a password reset link to the below email
                        </div>
                        <div
                          className="form-group"
                          style={{ paddingTop: 20, paddingBottom: 20 }}
                        >
                          <input
                            type="text"
                            className="form-control login-input"
                            placeholder="Email"
                            value={data.emailId}
                            name="emailId"
                            onChange={setUserData}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="forgot-btn">
                      <button
                        className="btn btn-primary form-button"
                        onClick={(e: any) => sendEmail(e)}
                      >
                        Send Email
                      </button>
                    </div>
                  </div>

                  {/* <div className="forgot-list-block">
                    <div className="forgot-list-item">
                      <div className="forgot-list-img">
                        <i className="icon-lock"></i>
                      </div>
                      <div className="forgot-list-text spacer">
                        <div className="forgot-large-text">
                          Send an OTP in my registered mobile
                        </div>
                        <div className="forgot-small-text">
                          We'll text you at below number with a security code.
                        </div>
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control login-input"
                            placeholder="Mobile number"
                            value={data.mobileNo}
                            name="mobileNo"
                            onChange={setUserData}
                            required
                            style={{ marginTop: 20, marginBottom: 20 }}
                          />
                          {mobileOtp?.data?.success === "Y" && (
                            <>
                              <input
                                type="text"
                                className="form-control login-input"
                                placeholder="Verify Otp"
                                value={data.otp}
                                name="otp"
                                onChange={setUserData}
                                required
                                style={{ marginTop: 20, marginBottom: 20 }}
                              />
                              <button
                                className="btn btn-primary form-button"
                                onClick={(e: any) => verifyOtp(e)}
                              >
                                Verify Code
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="forgot-btn">
                      <button
                        className="btn btn-primary form-button"
                        onClick={(e: any) => sendOtp(e)}
                      >
                        Send Code
                      </button>
                    </div>
                  </div> */}

                  {/* <div className="forgot-bottom-text">
                    <p>
                      *By selecting this method, you agree to receiver text or
                      pre-recorded messages to this number.
                      <br />
                      Cell charges may apply.
                    </p>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <div className="footer-bottom">
          <p className="text-center">
            Copyright &copy;2023 eFindit. All rights reserved
          </p>
        </div>
      </footer>
    </div>
  );
};

export default ForgotPassword;
