import { AppThunk } from "../../../../store"
import {
  
    SaveDefaultAddressSuccess,
    SaveDefaultAddressFailed
} from "./slice"
import { postDefaultShippingAddressApi } from "./api";
import { EndProgress } from "../../../progressBar/endProgress";
import { StartProgress } from "../../../progressBar/startProgress";



export const setDeafultShippingAddress = (data: any): AppThunk => async (dispatch) => {

    dispatch<any>(StartProgress());
    try {

        const bannerData: any = await postDefaultShippingAddressApi(data)
        

        if (bannerData.success === "N") {
            
            dispatch(  
                SaveDefaultAddressFailed(bannerData));
        }
        else {
           
            dispatch(SaveDefaultAddressSuccess(bannerData));
        }

    } catch (err) {
        
        dispatch(SaveDefaultAddressFailed(err));
    }
    dispatch<any>(EndProgress());
}