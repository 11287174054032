import { AppThunk } from "../../../store";
import { resetDetails } from "./slice";

export const resetDetail = (): AppThunk => async (dispatch) => {
  try {
    dispatch(resetDetails());
  } catch (err) {
    
  }
};
