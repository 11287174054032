import { AppThunk } from "../../store"
import {
    CountriesSuccess,
    CountriesFailed
} from "./slice"
import { fetchCountry } from "./api";


export const getCountries = (): AppThunk => async (dispatch) => {
    try {

        const countriesData: any = await fetchCountry()

        if (countriesData.success === "N") {
            
            dispatch(CountriesFailed(countriesData));
        }
        else {
           
            dispatch(CountriesSuccess(countriesData));
        }

    } catch (err) {
        
        dispatch(CountriesFailed(err));
    }
}