import { AppThunk } from "../../store"
import {
    progressResetSuccess,

} from "./slice"

export const ResetProgress = (): AppThunk => async (dispatch) => {
    try {

        dispatch(progressResetSuccess());

    } catch (err) {
        
    }
}
