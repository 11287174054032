import { AppThunk } from "../../store";
import { ListingReportSuccess, ListingReportFailed } from "./slice";
import { fetchActiveListing, fetchDraftListing, fetchEndedListing, fetchScheduledListing, fetchSoldListing, fetchUnsoldListing } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const getListingReport =
  (data: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
let productData:any;
      if(data?.type==="Unsold")
productData = await fetchUnsoldListing(data);

if(data?.type==="Sold")
productData = await  fetchSoldListing(data);

if(data?.type==="Draft")
productData = await fetchDraftListing(data);
if(data?.type==="Scheduled")
productData = await fetchScheduledListing(data);
if(data?.type==="Ended")
productData = await fetchEndedListing(data);
if(data?.type==="")
    productData= await fetchActiveListing(data);

      if (productData.success === "N") {
        dispatch(ListingReportFailed(productData));
      } else {
        dispatch(ListingReportSuccess(productData));
      }
    } catch (err) {
      dispatch(ListingReportFailed(err));
    }
    dispatch<any>(EndProgress());
  };
