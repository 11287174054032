import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderCancelled } from "../../../modules/orders/orderCancelled";
import { createShipmentSave } from "../../../modules/orders/createShipmentSave";
import moment from "moment";
import { getShipperBuyerDetail } from "../../../modules/orders/getShipperBuyerDetail";
import { SellerOrdersResponseSelector } from "../../../modules/orders/selectors";
import { RootState } from "../../../utils/types";
import { updatePickupDate } from "../../../modules/orders/updatePickupDate";

const UpdatePickupDateModel = ({
  createShipment,
  setCreateShipment,
  
}: any) => {
  const dispatch = useDispatch();

  // const [date, setDate] = useState(
  //     "");


  const dateCheck =()=>{
    const date1 = moment(new Date());
const date2 = moment(createShipment.date);

// Calculate the absolute difference in hours between the two dates
const hoursDifference = Math.abs(date2.diff(date1, 'hours'));

// Calculate the total difference in days and time
const daysDifference = Math.floor(hoursDifference / 24);
const remainingHours = hoursDifference % 24;
if (daysDifference > 10 || (daysDifference === 10 && remainingHours > 0)) {
  return true
} else {
  return false
}







  }
  const onChange = (e: any) => {
    if(e.target.name=="PlannedPickupDateAndTime")
    {
      let newDate=new Date(e.target.value);
      setCreateShipment({
        ...createShipment,
        [e.target.name]:  moment(newDate).format('YYYY-MM-DDTHH:mm:ss Z'),
        
        date:e.target.value
      });
    
    }
    else
      {  setCreateShipment({
          ...createShipment,
          [e.target.name]: e.target.value,
        });}
      };

      // useEffect(()=>{
      //   dispatch<any>(getShipperBuyerDetail({orderId:createShipment.orderId}))
      // },[createShipment.orderId])
  return (
    <div
      className="modal modal-order-popup"
      id="myModal"
      tabIndex={-1}
      role="dialog"
      style={{ display: createShipment.visible }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="order-modal">
            <div className="order-modal-head border-none">Update pickup date and time </div>
       
            <div className="form-group spacer-feedback-select">
              {/* <label className="label-modal">
                Pickup date and time
              </label> */}
              <p style={{fontSize:12}}>(Note: Date should not be in past and it should be within 10 days from current date.)</p>
              <input  type="datetime-local" name="PlannedPickupDateAndTime" value={createShipment.date} onChange={onChange} style={{width:"100%" , padding:4, fontSize:14}} id="birthdaytime"   />
                  
              { createShipment?.ShippingDateAndTime?.length===0 &&  <p style={{color:"red"}} >
               Required
              </p>}
             { dateCheck()}
              { (moment(createShipment?.date).isBefore(new Date()) ||  dateCheck())  && <p style={{color:"red", fontSize:10}} >
              The entered date is either a past date or more than 10 days in the future.
              </p>}
            </div>

         

            <div className="modal-btn-group spacer-feedback-btn">
              <button
                className="modal-add-btn modal-cancel-order-btn"
                onClick={() => {
                  dispatch<any>(updatePickupDate({  "PlannedPickupDateAndTime": createShipment.PlannedPickupDateAndTime,
                  "PickUpId": createShipment.PickUpId,
                  }));
                  setCreateShipment({ ...createShipment, visible: "none" });
                }}
                disabled={
                 
                  createShipment.ShippingDateAndTime === ""
                    ? true
                    : false
                }
              >
                Update 
              </button>
              <button
                className="modal-cancel-btn"
                onClick={() =>
                  setCreateShipment({
                    ...createShipment,
                    visible: "none",
                    reason: null,
                    description: null,
                  })
                }
              >
              Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePickupDateModel;
