import { AppThunk } from "../../store"
import { ResetPassword } from "../../utils/types"
import {
  resetPasswordSuccess,
  resetPasswordFailed
} from "./slice"
import { resetPassword } from "./api"

export const getResetPassword = (Data:any): AppThunk => async (dispatch) => {
  try { 
    const UserData: any = await resetPassword(Data)
    dispatch(resetPasswordSuccess(UserData))
  } catch (err) { 
    dispatch(resetPasswordFailed(err)) 
  }
}
