import { AppThunk } from "../../../../store";
import { SaveDataSellerFailed, SaveDataSellerSuccess } from "./slice";
import {  removeSavedSeller } from "./api";
import { EndProgress } from "../../../progressBar/endProgress";
import { StartProgress } from "../../../progressBar/startProgress";

export const deleteSavedSeller = (params:number): AppThunk => async (dispatch) => {
  dispatch<any>(StartProgress());
  try {
    const data: any = await removeSavedSeller(params);
    

    if (data.success === "N") {
      
      dispatch(SaveDataSellerFailed(data));
    } else {
      
      dispatch(SaveDataSellerSuccess(data));
    }
  } catch (err) {
    
    dispatch(SaveDataSellerFailed(err));
  }
  dispatch<any>(EndProgress());
};
