import moment from "moment";

export const dateToLocal = (date: Date) => {
  let requestedDate = new Date(date);
  requestedDate = new Date(
    requestedDate.getTime() - requestedDate.getTimezoneOffset() * 60 * 1000
  );
  // return requestedDate.toLocaleString();
  return   moment.utc(date).local().format('MM/DD/YYYY h:mm:ss a')
  ;

};
