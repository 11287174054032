import { AppThunk } from "../../store";
import { SaveStoreAddressSuccess, SaveStoreAddressFailed } from "./slice";
import { setStoreAddress } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const saveAddress =
  (data: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
      const UserData: any = await setStoreAddress(data);

      if (UserData.success === "N") {
        dispatch(SaveStoreAddressFailed(UserData));
      } else {
        dispatch(SaveStoreAddressSuccess(UserData));
      }
    } catch (err) {
      dispatch(SaveStoreAddressFailed(err));
    }
    dispatch<any>(EndProgress());
  };
