import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCategoryTree } from "../../../modules/categories/getCategoryTree";
import { CategoryResponseSelector } from "../../../modules/categories/selector";
import { RootState } from "../../../utils/types";

const CategoryTree = () => {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const categoryResponse = useSelector<RootState, RootState["categories"]>(
    CategoryResponseSelector
  );

  let count = 0;

  // useEffect(() => {
  //   if (isFirstRender.current) {
  //     dispatch<any>(getCategoryTree());
  //   }
  //   isFirstRender.current = false;
  // }, []);

  const OptionData = (cat: any) => {
    return (
      <>
        {cat.active === "Y" && (
          <option key={cat.id} value={cat.id}>
            {/* {"\xa0\xa0".repeat(count)}{cat.name} */}
            {" - ".repeat(count)}
            {cat.name}
          </option>
        )}

        {(() => {
          if (cat.childs && cat.childs.length) {
            return [
              (count = count + 1),
              cat.childs.map(OptionData),
              (count = count - 1),
            ];
          } else {
            return "";
          }
        })()}
      </>
    );
  };

  return (
    <>
      {categoryResponse.categoryTree &&
        categoryResponse.categoryTree.data.map(OptionData)}
    </>
  );
};

export default CategoryTree;
