import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SellerProduct } from "../../utils/types";

type TrafficState = {
  sellerProductDetails: SellerProduct["sellerProductDetails"] | null;
  error: any | null;
  saveData: SellerProduct["saveData"] | null;
  statusChange: SellerProduct["statusChange"] | null;
  statusChangeError: any | null;

  listing: SellerProduct["listing"] | null;
  listingError: any | null;
  platformFees: SellerProduct["platformFees"] | null;
  platformFeesError: any | null;
  courierService: SellerProduct["courierService"] | null;
  courierServiceError: any | null;
  listingReport: SellerProduct["listingReport"] | null;
  listingReportError: any | null;
  attributes: SellerProduct["attributes"] | null;
  attributesError: any | null;
  deleteVariant: SellerProduct["deleteVariant"] | null;
  deleteVariantError: any | null;
  removeImage: SellerProduct["removeImage"] | null;
  offers: SellerProduct["offers"] | null;
  offersError: any | null;
  bids: SellerProduct["bids"] | null;
  bidsError: any | null;
  offersUsers: SellerProduct["offersUsers"] | null;
  offersUsersError: any | null;
  bidsUsers: SellerProduct["bidsUsers"] | null;
  bidsUsersError: any | null;
  dealsAddedBySeller: SellerProduct["dealsAddedBySeller"] | null;
  productsByCategory: SellerProduct["productsByCategory"] | null;
  dealsSeller: SellerProduct["dealsSeller"] | null;
  dealsSellerError: any | null;
  payments: SellerProduct["payments"] | null;
  paymentsError: any | null;
  paymentsReport: SellerProduct["payments"] | null;
  paymentsReportError: any | null;
};

const initialState: TrafficState = {
  sellerProductDetails: null,
  error: null,
  saveData: null,
  listing: null,
  listingError: null,
  courierService: null,
  courierServiceError: null,
  listingReport: null,
  listingReportError: null,
  attributes: null,
  attributesError: null,
  deleteVariant: null,
  deleteVariantError: null,
  offers: null,
  offersError: null,
  bids: null,
  bidsError: null,
  removeImage: null,
  statusChange: null,
  statusChangeError: null,
  offersUsers: null,
  offersUsersError: null,
  bidsUsers: null,
  bidsUsersError: null,
  dealsAddedBySeller:null,
  productsByCategory:null,
  dealsSeller: null,
  dealsSellerError: null,
  platformFees: null,
  platformFeesError: null,
  payments: null,
  paymentsError: null,
  paymentsReport: null,
  paymentsReportError: null,
  
};

const slice = createSlice({
  name: "sellerProduct",
  initialState,
  reducers: {
    SellerProductDetailSuccess: (state, action: PayloadAction<any>) => {
      state.sellerProductDetails = action.payload;
      state.error = null;
      
    },
    SellerProductDetailFailed: (state, action: PayloadAction<any>) => {
      state.sellerProductDetails = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
    PaymentSuccess: (state, action: PayloadAction<any>) => {
      state.payments = action.payload;
      state.paymentsError = null;
      
    },
    PaymentFailed: (state, action: PayloadAction<any>) => {
      state.payments = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.paymentsError = errorMessage;
    },
    PaymentReportSuccess: (state, action: PayloadAction<any>) => {
      state.paymentsReport = action.payload;
      state.paymentsReportError = null;
      
    },
    PaymentReportFailed: (state, action: PayloadAction<any>) => {
      state.paymentsReport = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.paymentsReportError = errorMessage;
    },
    RemoveImageSuccess: (state, action: PayloadAction<any>) => {
      state.removeImage = action.payload;
      state.error = null;
      
    },
    ResetDeleteVariant: (state) => {
      state.deleteVariant = null;
      state.deleteVariantError = null;
    },
    ResetReport: (state) => {
      state.listingReport = null;
      state.listingReportError = null;
      state.paymentsReport=null;
      state.paymentsReportError=null;
    },
    RemoveImageFailed: (state, action: PayloadAction<any>) => {
      state.removeImage = null;
      let errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
      
    },
    LisitngSuccess: (state, action: PayloadAction<any>) => {
      state.listing = action.payload;
      state.listingError = null;
      
    },
    ListingFailed: (state, action: PayloadAction<any>) => {
      state.listing = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.listingError = errorMessage;
    },
    ListingReportSuccess: (state, action: PayloadAction<any>) => {
      state.listingReport = action.payload;
      state.listingReportError = null;
      
    },
    ListingReportFailed: (state, action: PayloadAction<any>) => {
      state.listingReport = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.listingReportError = errorMessage;
    },
    PlatformFeesSuccess: (state, action: PayloadAction<any>) => {
      state.platformFees = action.payload;
      state.platformFeesError = null;
      
    },
    PlatformFeesFailed: (state, action: PayloadAction<any>) => {
      state.platformFees = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.platformFeesError = errorMessage;
    },
    CourierServiceSuccess: (state, action: PayloadAction<any>) => {
      state.courierService = action.payload;
      state.courierServiceError = null;
      
    },
    CourierServiceFailed: (state, action: PayloadAction<any>) => {
      state.courierService = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.courierServiceError = errorMessage;
    },
    DealsSellerSuccess: (state, action: PayloadAction<any>) => {
      state.dealsSeller = action.payload;
      state.dealsSellerError = null;
      
    },
    DealsSellerFailed: (state, action: PayloadAction<any>) => {
      state.dealsSeller = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.dealsSellerError = errorMessage;
    },
    OffersSuccess: (state, action: PayloadAction<any>) => {
      state.offers = action.payload;
      state.offersError = null;
      
    },
    OffersFailed: (state, action: PayloadAction<any>) => {
      state.offers = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.offersError = errorMessage;
    },
    BidsSellerSuccess: (state, action: PayloadAction<any>) => {
      state.bids = action.payload;
      state.bidsError = null;
      
    },
    BidsSellerFailed: (state, action: PayloadAction<any>) => {
      state.bids = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.bidsError = errorMessage;
    },
    DealsAddedBySellerSuccess: (state, action: PayloadAction<any>) => {
      state.dealsAddedBySeller = action.payload;
      state.error = null;
      
    },
    DealsAddedBySellerFailed: (state, action: PayloadAction<any>) => {
      state.dealsAddedBySeller = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
    OffersUsersSuccess: (state, action: PayloadAction<any>) => {
      state.offersUsers = action.payload;
      state.offersUsersError = null;
      
    },
    OffersUsersFailed: (state, action: PayloadAction<any>) => {
      state.offersUsers = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.offersUsersError = errorMessage;
    },
    ProductsByCategorySuccess: (state, action: PayloadAction<any>) => {
      state.productsByCategory = action.payload;
      state.error = null;
      
    },
    ProductsByCategoryFailed: (state, action: PayloadAction<any>) => {
      state.productsByCategory = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
    BidsUsersSuccess: (state, action: PayloadAction<any>) => {
      state.bidsUsers = action.payload;
      state.bidsUsersError = null;
      
    },
    BidsUsersFailed: (state, action: PayloadAction<any>) => {
      state.bidsUsers = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.bidsUsersError = errorMessage;
    },

    ResetSellerProductDetails: (state) => {
      state.sellerProductDetails = null;
      state.error = null;
    },
    ResetSaveData: (state) => {
      state.saveData = null;
      state.error = null;
      state.statusChange = null;
      state.statusChangeError = null;
    },
    ResetOffersUsers: (state) => {
      state.offersUsers = null;
      state.offersUsersError = null;
     
    },
    ResetBidsUsers: (state) => {
      state.bidsUsers = null;
      state.bidsUsersError = null;
     
    },
    SaveListingDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.saveData = action.payload;
      state.error = null;

      
    },
    SaveListingDetailsFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.saveData = action.payload;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
    SaveSellingDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.saveData = action.payload;
      state.error = null;
      
    },
    SaveSellingDetailsFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.saveData = action.payload;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
    SaveData: (state, action: PayloadAction<any>) => {
      state.saveData = action.payload;
      state.error = null;
      
    },
    SaveDataFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.saveData = action.payload;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
    ChangeProductStatusSuccess: (state, action: PayloadAction<any>) => {
      state.statusChange = action.payload;
      state.statusChangeError = null;
      
    },
    ChangeProductStatusFailed: (state, action: PayloadAction<any>) => {
      state.statusChangeError = action.payload;
      state.statusChange = action.payload;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.statusChangeError = errorMessage;
    },
    SaveShippingDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.saveData = action.payload;
      state.error = null;
      
    },
    SaveShippingDetailsFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.saveData = action.payload;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
    SaveSellFasterSuccess: (state, action: PayloadAction<any>) => {
      state.saveData = action.payload;
      state.error = null;
      
    },
    SaveSellFasterFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.saveData = action.payload;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
    AttributesSuccess: (state, action: PayloadAction<any>) => {
      state.attributes = action.payload;
      state.attributesError = null;
      
    },
    AttributesFailed: (state, action: PayloadAction<any>) => {
      
      state.attributes = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.attributesError = errorMessage;
    },
    DeleteVariantSuccess: (state, action: PayloadAction<any>) => {
      state.deleteVariant = action.payload;
      state.deleteVariantError = null;
      
    },
    DeleteVariantFailed: (state, action: PayloadAction<any>) => {
      
      
      state.deleteVariant = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.deleteVariantError = errorMessage;
    },
  },
});

export const sellerProduct = slice.reducer;

export const {
  SellerProductDetailFailed,
  SellerProductDetailSuccess,
  ResetSellerProductDetails,
  SaveListingDetailsSuccess,
  SaveListingDetailsFailed,
  SaveSellingDetailsSuccess,
  SaveSellingDetailsFailed,
  SaveShippingDetailsSuccess,
  SaveShippingDetailsFailed,
  SaveSellFasterSuccess,
  SaveSellFasterFailed,
  ResetSaveData,
  LisitngSuccess,
  ListingFailed,
  RemoveImageFailed,
  RemoveImageSuccess,
  ChangeProductStatusFailed,
  ChangeProductStatusSuccess,
  AttributesFailed,
  AttributesSuccess,
  DeleteVariantFailed,
  DeleteVariantSuccess,
  ResetDeleteVariant,
  OffersFailed,
  OffersSuccess,
  OffersUsersFailed,
  OffersUsersSuccess,
  ResetOffersUsers,
  SaveData,
  SaveDataFailed,
  BidsSellerFailed,
  BidsSellerSuccess,
  BidsUsersFailed,
  BidsUsersSuccess,
  ResetBidsUsers,
  DealsAddedBySellerFailed,
  DealsAddedBySellerSuccess,
  ProductsByCategoryFailed,
  ProductsByCategorySuccess,
  DealsSellerFailed,
  DealsSellerSuccess,
  ListingReportSuccess,
  ListingReportFailed,
  CourierServiceFailed,
  CourierServiceSuccess,
  ResetReport,
  PlatformFeesFailed,
  PlatformFeesSuccess,
  PaymentSuccess,
  PaymentFailed,
  PaymentReportFailed,
  PaymentReportSuccess
} = slice.actions;
