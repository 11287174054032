import { AppThunk } from "../../store";
import { Buyers } from "../../utils/types";
import { CommonSuccess, CommonFailed } from "./slice";
import { fetchCommonData } from "./api";
import { EndProgress } from "../progressBar/endProgress";
import { StartProgress } from "../progressBar/startProgress";

export const getCommonData = (): AppThunk => async (dispatch) => {
  try {
    dispatch<any>(StartProgress());

    const CommonData: any = await fetchCommonData();
    

    if (CommonData === 1) {
      
      dispatch(CommonFailed(CommonData));
    } else {
      dispatch(CommonSuccess(CommonData));
    }

    //return DashboardData
  } catch (err) {
    
    dispatch(CommonFailed(err));
    //return err.toString()
  }
  dispatch<any>(EndProgress());
};
