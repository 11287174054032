import { AppThunk } from "../../../../store";
import { fetchReturnOrderTrackingDetails_Buyer } from "./api";
import { ReturnOrderTrackingDetails_Buyer_Success, ReturnOrderTrackingDetails_Buyer_Failed } from "./slice";

export const getReturnOrderTrackingDetails_Buyer = (params: any): AppThunk => async (dispatch) => {
    try {
      const data: any = await fetchReturnOrderTrackingDetails_Buyer(params);
      if (data.success === "N") {
        dispatch(ReturnOrderTrackingDetails_Buyer_Failed(data));
      } else {
        dispatch(ReturnOrderTrackingDetails_Buyer_Success(data));
      }
    } catch (err) {
      dispatch(ReturnOrderTrackingDetails_Buyer_Failed(err));
    }
  };
