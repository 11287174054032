import React from "react";

function SellerBreadCrumbs() {
  return (
    <section className="seller-breadcrumb-block">
      <div className="container">
        <ul>
          <li className="text">Seller Dashboard</li>
          <li className="pipe">|</li>
          <li className="text-link"><a href="#">{localStorage.getItem("UserName")}</a></li>
        </ul>
      </div>
    </section>
  );
}

export default SellerBreadCrumbs;
