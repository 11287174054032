import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SocialMedias } from "../../utils/types";

type ReturnSocialMediaState = {
  error: any | null;
  socialMedias: SocialMedias["socialMedias"] | null;
  saveData: SocialMedias["saveData"] | null;
};

const initialState: ReturnSocialMediaState = {
  error: null,
  socialMedias: null,
  saveData: null,
};

const slice = createSlice({
  name: "socialMedias",
  initialState,
  reducers: {
    resetSocialMediaSuccess: (state) => {
      
      state.saveData = null;
      state.error = null;
    },

    SocialMediaSuccess: (state, action: PayloadAction<any>) => {
      
      
      state.socialMedias = action.payload;
      state.error = null;
    },
    SocialMediaFailed: (state, action: PayloadAction<any>) => {
      
      state.error = action.payload;
      state.socialMedias = null;
      state.saveData = action.payload;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
  },
});

export const socialMedias = slice.reducer;

export const {
  SocialMediaSuccess,
  SocialMediaFailed,

  resetSocialMediaSuccess,
} = slice.actions;
