import { AppThunk } from "../../../store";
import { PlaceBidSuccess, PlaceBidFailed } from "./slice";
import { postPlaceBid } from "./api";
import { EndProgress } from "../../progressBar/endProgress";
import { StartProgress } from "../../progressBar/startProgress";

export const saveBid =
  (params: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());

    try {
      const data: any = await postPlaceBid(params);
      

      if (data.success === "N") {
        
        dispatch(PlaceBidFailed(data));
      } else {
        
        dispatch(PlaceBidSuccess(data));
      }
    } catch (err) {
      
      dispatch(PlaceBidFailed(err));
    }
    dispatch<any>(EndProgress());
  };
