import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { RootState } from "../../utils/types";
import { DefaultLayout } from "../../layout/DefaultLayout";
import HeaderNormal from "../../components/header/HeaderNormal";
import Footer from "../../components/footer/Footer";
import { verifyEmail } from "../../modules/confirmEmail/verifyEmail";
import { confirmEmailresponseSelector } from "../../modules/confirmEmail/selectors";
import { notify } from "../../modules/notification/notify";
import { reset } from "../../modules/confirmEmail/reset";

export const ConfirmEmail = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let confirmEmailResponse = useSelector<RootState, RootState["confirmEmail"]>(
    confirmEmailresponseSelector
  );

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate("/login-seller", { replace: true });
  };
  const search = useLocation().search;
  const isFirstRender = React.useRef(true);

  const id = new URLSearchParams(search).get("id");


  useEffect(() => {
    if (isFirstRender.current) {
      if (id !== null) {
        dispatch<any>(verifyEmail(id));
      }
    }
    isFirstRender.current = false;
  }, [id]);

  useEffect(() => {
    if (confirmEmailResponse?.data != null) {
      if (confirmEmailResponse.data?.success === "Y") {
      }
    }

    // dispatch<any>(reset());
  }, [confirmEmailResponse]);

  return (
    <DefaultLayout>
      <header className="header">
        <div className="logo-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="logo">
                  <a href="#">
                    <i className="logo-img"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <ToastContainer />

      <section className="login-section min-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="login-container">
                <form className="form-block">
                  {confirmEmailResponse?.error !== null && (
                    <div className="alert alert-danger mt-5" role="alert">
                      {confirmEmailResponse?.error}
                    </div>
                  )}
                  {confirmEmailResponse?.data?.success === "Y" && (
                    <>
                      <div
                        className="alert alert-success "
                        style={{ fontSize: "15px" }}
                        role="alert"
                      >
                        Your Email has been verified 
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary login-btn form-button"
                        onClick={(e: any) => handleSubmit(e)}
                      >
                        Click here to login
                      </button>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </DefaultLayout>
  );
};
