import { AppThunk } from "../../store"
import {
    SingleCategoryTemplateSuccess,
    SingleCategoryTemplateFailed
} from "./slice"
import { fetchCategoryTemplateByCategoryId } from "./api";


export const viewItemSpecification = (data: any): AppThunk => async (dispatch) => {
    try {

        const categoryData: any = await fetchCategoryTemplateByCategoryId(data)
        

        if (categoryData.success === "N") {
            
            dispatch(SingleCategoryTemplateFailed(categoryData));
        }
        else {
           
            dispatch(SingleCategoryTemplateSuccess(categoryData));
        }

    } catch (err) {
        
        dispatch(SingleCategoryTemplateFailed(err));
    }
}