import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { DefaultLayout } from "../../../../../layout/DefaultLayout";
import BuyerHeader from "../../../BuyerHeader";
import { RootState } from "../../../../../utils/types";
import { SellerOrdersResponseSelector } from "../../../../../modules/orders/selectors";
import { API_URLS, BASE_URLS } from "../../../../../utils/api.urls";
import BuyerFooter from "../../../buyerFooter";
import { resetActivity } from "../../../../../modules/buyer/myEfindit/activity/resetActivity";
import { getOrderDetailBuyer } from "../../../../../modules/buyer/myEfindit/activity/getOrderDetailBuyer";
import { activityResponseSelector } from "../../../../../modules/buyer/myEfindit/activity/selectors";
import { dateToLocal } from "../../../../../modules/date/date";
import axios from "axios";
import moment from "moment";
// import Header from "../../../components/header/Header";
// import { DefaultLayout } from "../../../layout/DefaultLayout";
// import { RootState } from "../../../utils/types";
// import { ToastContainer } from "react-toastify";
// import { getOrderDetail } from "../../../modules/orders/getOrderDetail";
// import { SellerOrdersResponseSelector } from "../../../modules/orders/selectors";
// import { BASE_URLS } from "../../../utils/api.urls";
// import ContactBuyer from "./ContactBuyer";
// import OrderFeedback from "./OrderFeedback";
// import { CommonResponseSelector } from "../../../modules/common/selectors";
// import { notify } from "../../../modules/notification/notify";
// import { constant } from "../../../utils/constant";
// import { resetOrder } from "../../../modules/orders/resetOrder";
// import "react-toastify/dist/ReactToastify.css";
// import { getCommonData } from "../../../modules/common/getCommonData";
// import Footer from "../../../components/footer/Footer";

const OrderDetailBuyer = () => {
  const { state } = useLocation();
  // const orderID: any = state;
  const isFirstRender = useRef(true);
  const dispatch = useDispatch();
  let order = useSelector<RootState, RootState["sellerOrders"]>(
    SellerOrdersResponseSelector
  );
  let activityResponse = useSelector<RootState, RootState["activity"]>(
    activityResponseSelector
  );
  // const commonResponse = useSelector<RootState, RootState["common"]>(
  //   CommonResponseSelector
  // );
  useEffect(() => {
    if (state ) {
      dispatch<any>(resetActivity("orderDetails")).then(
        dispatch<any>(getOrderDetailBuyer( state?.orderId ))
      );
      // if (commonResponse?.common === null) dispatch<any>(getCommonData());
    }
    // isFirstRender.current = false;
  }, [state]);
  const downloadPDF = async () => {
  
    try {
      const access_token = localStorage.getItem("authorization");


 
  const response = await axios.get(API_URLS.ORDER_INVOICE+state?.orderId, {responseType: 'blob',headers:{ Authorization: "Bearer " + access_token,}});
  
 
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = url;
      link.download = 'document.pdf';
      link.click();
  
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  // useEffect(() => {
  //   if (order.saveData != null) {
  //     if (order.saveData.success == "Y") {
  //       notify(order.saveData.message, constant.SUCCESS);
  //     } else if (order.saveData.success == "N") {
  //       notify(order.saveData.message, constant.ERROR);
  //     } else if (order.saveDataError !== null)
  //       notify("PreDefinedMessage or Message is required", constant.ERROR);
  //     dispatch<any>(resetOrder());
  //     setFeedback({
  //       visible: "none",
  //       orderId: "",
  //       definedMessage: null,
  //       message: "",
  //     });

  //     setContactBuyer({
  //       visible: "none",
  //       orderId: "",
  //       message: "",
  //       sendCopy: false,
  //     });
  //   }
  // }, [order.saveData, order.saveDataError]);
  return (
    <DefaultLayout>
      <BuyerHeader />
      {/* <ToastContainer /> */}
      {/* <ContactBuyer
        contactBuyer={contactBuyer}
        setContactBuyer={setContactBuyer}
      />

      <OrderFeedback
        feedback={feedback}
        setFeedback={setFeedback}
        commonResponse={commonResponse}
      /> */}
      <section className="seller-breadcrumb-links">
        <div className="container">
          <ul>
            <li>
              <Link to={"home"}>eFindit</Link>
            </li>
            <li className="spacer">{">"}</li>
            <li>
              <Link to={"/buyer/activity/purchaseHistory"}>purchase history</Link>
            </li>
            <li className="spacer">{">"}</li>
            <li className="active">Order detail</li>
          </ul>
        </div>
      </section>

      <main className="seller-page-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="order-detail-heading">
                <h2 className="seller-head-style">Order detail</h2>
                <button className="btn btn-primary" onClick={downloadPDF}>Download invoice</button>
              </div>
              
              <div className="order-detail-block">
                <div className="order-id-style">
                <> Status:{" "} {activityResponse?.orderDetails?.data?.status}<br/>
                  Order date:{" "} {dateToLocal(activityResponse?.orderDetails?.data?.orderDate)}<br/>
                  </> 
                  {/* <a href="#">{order?.orderDetail?.data?.buyerName}</a> */}
                </div>
                <div className="order-detail-block-inner">
                  <div className="order-detail-section-l">
                    {/* <div className="order-estimate-block"> */}
                    {  state?.estimteDelivery && 
                  <div className="order-estimate-block"> <span>Estimate delivery{" "}</span> 
                      <strong>
                         {moment(dateToLocal(state?.estimteDelivery)).format("DD MMM YYYY")}
                      </strong>
                      </div> 
                      }
                      {  activityResponse?.orderDetails?.data?.deliveryDate && 
                  <div className="order-estimate-block"> <span>Delivery date{" "}</span> 
                      <strong>
                        {moment(dateToLocal(activityResponse?.orderDetails?.data?.deliveryDate)).format("DD MMM YYYY") }
                      </strong>
                      </div> 
                      }

                    {/* </div> */}
                    <div className="order-estimate-product">
                      <div className="table-order-detail">
                          <div className="order-detail-img-content">
                            <div className="order-detail-img-l">
                              <img
                                src={
                                  BASE_URLS.AWS_URL +
                                  "/Thumb" +
                                  state?.productImage
                                }
                                style={{
                                  height: 60,
                                  width: 60,
                                }}
                              />
                            </div>
                            <div className="order-detail-content-r">
                              <label className="order-product-label">
                                {state?.productName}
                              </label>
                              <p className="order-product-text">
                                Condition :{" "}
                                {state?.condition}

                                {state?.variant && JSON.parse(state?.variant)?.map((product:any)=>(
                                 <> <br/> <span> {`${product.AttName} : ${product.Option}`} </span></>
                                  ))}
                              </p>
                              <table className="order-item-table">
                                <tr>
                                  <td>
                                    <label className="order-item-label">
                                      Order Id
                                    </label>
                                    <p className="order-item-text">{state?.orderId}</p>
                                  </td>
                                  <td>
                                    <label className="order-item-label">
                                      Price
                                    </label>
                                    <p className="order-item-text">
                                      $ {state?.totalPrice}
                                    </p>
                                  </td>
                                  <td>
                                    <label className="order-item-label">
                                      Quantity
                                    </label>
                                    <p className="order-item-text">
                                      {state?.quantity}
                                    </p>
                                  </td>
                                </tr>
                              { activityResponse?.orderDetails?.data?.status==="Cancelled" &&
                              <> <tr className="order-estimate-block">
                                  Reason for cancel
                                </tr>
                                <tr className="order-item-label">
                                <>  {activityResponse?.orderDetails?.data?.reason}
                                <br/>

                                  {
                                    activityResponse?.orderDetails?.data
                                      ?.cancelledDescription
                                  }</>
                                </tr></>}
                                { (activityResponse?.orderDetails?.data?.returnReason!==null || activityResponse?.orderDetails?.data?.returnDescription!==null ) &&
                              <> <tr className="order-estimate-block">
                                  Reason for return
                                </tr>
                                <tr className="order-item-label">
                                <>  {activityResponse?.orderDetails?.data?.returnReason}
                                <br/>
                                  {
                                    activityResponse?.orderDetails?.data
                                      ?.returnDescription
                                  }</>
                                </tr></>}
                              </table>
                            </div>
                            {/* <td align="right" valign="top">
                              <div className="order-button-block">
                                <button
                                  className="list-button"
                                  onClick={() =>
                                    setFeedback({
                                      ...feedback,
                                      visible: "block",
                                      orderId: orderID,
                                    })
                                  }
                                >
                                  Leave feedback
                                </button>

                                <button
                                  className="list-button bg-none"
                                  onClick={() =>
                                    setContactBuyer({
                                      ...contactBuyer,
                                      visible: "block",
                                      orderId: orderID,
                                    })
                                  }
                                >
                                  Contact buyer
                                </button>
                              </div>
                            </td> */}
                          </div>
                      </div>
                    </div>
                  </div>

                  <div className="order-detail-section-r">
                    <div className="delivery-add-block">
                      <div className="delivery-add-title">Delivery address</div>
                      <div className="delivery-small-text">
                        {activityResponse?.orderDetails?.data?.buyerName}
                      </div>
                      <div className="delivery-add-text">
                        {activityResponse?.orderDetails?.data?.address1},
                        <br /> {activityResponse?.orderDetails?.data?.address2 && <>{activityResponse?.orderDetails?.data?.address2 }<br /> </>   }
                         {activityResponse?.orderDetails?.data?.zipCode}
                        <br /> {activityResponse?.orderDetails?.data?.state}
                        <br /> {activityResponse?.orderDetails?.data?.country}
                        <br /> 
                        {activityResponse?.orderDetails?.data?.mobileNo}
                      </div>
                    </div>

                    <div className="order-total-block">
                      <div className="delivery-add-title order-total-btm">
                        Order total
                      </div>
                   
                      <div className="order-total-row border-btm">
                        <div className="order-total-col-l">Item price </div>
                        <div className="order-total-col-r">
                          $ {activityResponse?.orderDetails?.data?.itemPrice}
                        </div>
                      </div>
                      <div className="order-total-row border-btm">
                        <div className="order-total-col-l">Shipping price</div>
                        <div className="order-total-col-r">
                          {" "}
                          $ {activityResponse?.orderDetails?.data?.shippingPrice ? activityResponse?.orderDetails?.data?.shippingPrice  : 0 }
                        </div>
                      </div>
                      <div className="order-total-row border-btm">
                        <div className="order-total-col-l">Tax</div>
                        <div className="order-total-col-r">
                          ${activityResponse?.orderDetails?.data?.tax}
                        </div>
                      </div>
                      {/* <div className="order-total-row border-btm">
                        <div className="order-total-col-l">
                          Receivable amount
                        </div>
                        <div className="order-total-col-r">
                          $
                          {Math.round(
                            (order?.orderDetail?.data?.price *
                              order?.orderDetail?.data?.quantity -
                              order?.orderDetail?.data?.shipmentAmount -
                              order?.orderDetail?.data?.platformFee -
                              order?.orderDetail?.data?.transactionFee) *
                              100
                          ) / 100}
                        </div>
                      </div> */}
                      <div className="order-total-row border-btm">
                        <div className="order-total-col-l">Order total</div>
                        <div className="order-total-col-r">
                          ${" "}
                          {activityResponse?.orderDetails?.data?.orderTotal}
                        </div>
                      </div>
                      {/* <div className="order-total-row">
                        <div className="order-total-col-l">Postage</div>
                        <div className="order-total-col-r">check</div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <BuyerFooter />
    </DefaultLayout>
  );
};

export default OrderDetailBuyer;
