import { AppThunk } from "../../store";
import { SaveDataFailed, SaveData } from "./slice";
import { deleteDealSeller, fetchActiveListing, fetchDealsSeller, fetchOffersSeller } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const removeDealsSeller =
  (data: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
      const productData: any = await deleteDealSeller(data);
      if (productData.success === "N") {
        dispatch(SaveDataFailed(productData));
      } else {
        dispatch(SaveData(productData));
      }
    } catch (err) {
      dispatch(SaveDataFailed(err));
    }
    dispatch<any>(EndProgress());
  };
