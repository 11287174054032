import { AppThunk } from "../../../../store"
import {
    SaveProfileAddressSuccess,
    SaveProfileAddressFailed,
    SaveProfileAddressError
} from "./slice"
import { updateProfileAddressApi } from "./api";
import { EndProgress } from "../../../progressBar/endProgress";
import { StartProgress } from "../../../progressBar/startProgress";



export const updateProfileAddress = (data: any): AppThunk => async (dispatch) => {

    dispatch<any>(StartProgress());
    try {

        const bannerData: any = await updateProfileAddressApi(data)
        

        if (bannerData.success === "N") {
            
            dispatch(SaveProfileAddressFailed(bannerData));
        }
        else {
           
            dispatch(SaveProfileAddressSuccess(bannerData));
        }

    } catch (err) {
        
        dispatch(SaveProfileAddressError(err));
    }
    dispatch<any>(EndProgress());
}