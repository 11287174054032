import { AppThunk } from "../../../../store";
import { SavedSellersFailed, SavedSellersSuccess } from "./slice";
import { fetchSavedSellers } from "./api";
import { EndProgress } from "../../../progressBar/endProgress";
import { StartProgress } from "../../../progressBar/startProgress";

export const getSavedSellers = (params:number): AppThunk => async (dispatch) => {
  dispatch<any>(StartProgress());
  try {
    const data: any = await fetchSavedSellers(params);
    

    if (data.success === "N") {
      
      dispatch(SavedSellersFailed(data));
    } else {
      
      dispatch(SavedSellersSuccess(data));
    }
  } catch (err) {
    
    dispatch(SavedSellersFailed(err));
  }
  dispatch<any>(EndProgress());
};
