import { AppThunk } from "../../../store";
import { DealsFailed, DealsSuccess } from "../deals/slice";
import { fetchDeals } from "./api";
import { EndProgress } from "../../progressBar/endProgress";
import { StartProgress } from "../../progressBar/startProgress";

export const getDeals =
  (params: string): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
      const data: any = await fetchDeals(params);

      if (data.success === "N") {
        dispatch(DealsFailed(data));
      } else {
        dispatch(DealsSuccess(data));
      }
    } catch (err) {
      dispatch(DealsFailed(err));
    }
    dispatch<any>(EndProgress());
  };
