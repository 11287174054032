import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Sales, SellerStore } from "../../utils/types";

type SalesState = {
  sellerStore: SellerStore["sellerStore"] | null;
  error: any | null;
  saveData: SellerStore["saveData"] | null;
  saveDataError: any | null;
  saveProfilePic: SellerStore["saveProfilePic"] | null;
  saveProfilePicError: any | null;
  feedback: SellerStore["feedback"] | null;
  feedbackError: string | null;
};

const initialState: SalesState = {
  sellerStore: null,
  error: null,
  saveDataError: null,
  saveData: null,
  saveProfilePic: null,
  saveProfilePicError: null,
  feedback: null,
  feedbackError: null,
};

const slice = createSlice({
  name: "sellerStore",
  initialState,
  reducers: {
    StoreSuccess: (state, action: PayloadAction<any>) => {
      state.sellerStore = action.payload;
      state.error = null;
      
    },
    StoreFailed: (state, action: PayloadAction<any>) => {
      state.sellerStore = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
    FeedbackSuccess: (state, action: PayloadAction<any>) => {
      state.feedback = action.payload;
      state.feedbackError = null;
    },
    FeedbackFailed: (state, action: PayloadAction<any>) => {
      state.feedback = null;
      let errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.feedbackError = errorMessage;
    },
    SaveStoreSuccess: (state, action: PayloadAction<any>) => {
      state.saveData = action.payload;
      state.saveDataError = null;
      
    },
    SaveStoreFailed: (state, action: PayloadAction<any>) => {
      state.saveData = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.saveDataError = errorMessage;
    },
    SaveStoreAddressSuccess: (state, action: PayloadAction<any>) => {
      state.saveData = action.payload;
      state.saveDataError = null;
      
    },
    SaveStoreAddressFailed: (state, action: PayloadAction<any>) => {
      state.saveData = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.saveDataError = errorMessage;
    },
    SaveProfileSuccess: (state, action: PayloadAction<any>) => {
      state.saveProfilePic = action.payload;
      state.saveProfilePicError = null;
      
    },
    SaveProfileFailed: (state, action: PayloadAction<any>) => {
      state.saveProfilePic = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.saveProfilePicError = errorMessage;
    },
    ResetStore: (state) => {
      state.sellerStore = null;
      state.error = null;
      state.saveData = null;
      state.saveDataError = null;
      state.saveProfilePic = null;
      state.saveProfilePicError = null;
    },
  },
});

export const sellerStore = slice.reducer;

export const {
  StoreFailed,
  StoreSuccess,
  SaveStoreFailed,
  SaveStoreSuccess,
  SaveProfileSuccess,
  SaveProfileFailed,
  ResetStore,
  SaveStoreAddressFailed,
  SaveStoreAddressSuccess,
  FeedbackFailed,
  FeedbackSuccess,
} = slice.actions;
