import { AppThunk } from "../../store";
import { fetchCategories, fetchSellerCategories } from "./api";
import { SellerCategorySuccess, SellerCategoryFailed } from "./slice";
import { EndProgress } from "../progressBar/endProgress";
import { StartProgress } from "../progressBar/startProgress";

export const getSellerCategories = (): AppThunk => async (dispatch) => {
  dispatch<any>(StartProgress());
  try {
    const categoryData: any = await fetchSellerCategories();
    

    if (categoryData.success === "N") {
      
      dispatch(SellerCategoryFailed(categoryData));
    } else {
      
      dispatch(SellerCategorySuccess(categoryData));
    }
  } catch (err) {
    
    dispatch(SellerCategoryFailed(err));
  }
  dispatch<any>(EndProgress());
};
