import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DefaultLayout } from "../../../layout/DefaultLayout";
import { getProductDetail } from "../../../modules/buyer/product/getProductDetail";
import { buyerProductSelector } from "../../../modules/buyer/product/selectors";
import { BASE_URLS } from "../../../utils/api.urls";
import { RootState } from "../../../utils/types";
import BuyerFooter from "../buyerFooter";
import BuyerHeader from "../BuyerHeader";
import "./product-detail.scss";
import DefaultImage from "../../../assets/images/defaultImage.png";
import BlueStar from "../../../assets/images/blueStar.png";
import Star from "../../../assets/images/Layer 555.png";
import { saveSeller } from "../../../modules/buyer/product/saveSeller";
import { constant } from "../../../utils/constant";
import { notify } from "../../../modules/notification/notify";
import { resetSave } from "../../../modules/buyer/product/resetSave";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setRecentlyViewed } from "../../../modules/buyer/product/setRecentlyViewed";
import { addToCart } from "../../../modules/buyer/product/addToCart";
import NewDetails from "./NewDetails";
import ProductBuy from "./ProductBuy";
import AuctionDetails from "./AuctionDetails";
import ProductBid from "./ProductBid";
import { saveBid } from "../../../modules/buyer/product/saveBid";
import { setWatchlist } from "../../../modules/buyer/product/setWatchlist";
import SliderImage from "react-zoom-slider";
import MakeOfferModel from "./MakeOfferModel";
import ReviewOfferModel from "./ReviewOfferModel";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { getProductReview } from "../../../modules/buyer/myEfindit/activity/getProductReview";
import { activityResponseSelector } from "../../../modules/buyer/myEfindit/activity/selectors";
import { Rating } from "react-simple-star-rating";
import { dateToLocal } from "../../../modules/date/date";
import ReviewSection from "./ReviewSection";
import { setCartItems } from "../../../modules/cartItems/setCartItems";
import { getCartItems } from "../../../modules/cartItems/getCartItems";

import { postStoreTraffic } from "../../../modules/buyer/product/api";
const ProductDetail = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { Id } = useParams();
  const { variId } = useParams();
  const { date } = useParams();
  const navigate = useNavigate();
  const isFirstRender = useRef(true);
  interface offerValues {
    visible: string;
    productId: string;
    offerAmount: string;
    Quantity:number
    message: string | null;
  }
const[reviewOffer, setReviewOffer] =useState({
  visible:"none"
})
  const [makeOffer, setMakeOffer] = useState<offerValues>({
    visible: "none",
    productId: "",
    Quantity:1,
    offerAmount: "",
    message: null,
  });
  let productResponse = useSelector<RootState, RootState["buyerProduct"]>(
    buyerProductSelector
  );

  let activityResponse = useSelector<RootState, RootState["activity"]>(
    activityResponseSelector
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    if (Id !== undefined) {
      setMakeOffer({...makeOffer,productId:Id})
      dispatch<any>(resetSave()).then(()=>dispatch<any>(getProductDetail(Id)))  ;
      dispatch<any>(getProductReview({productId:Id,
        currentPage:  1,
        rows: 4,
      }))

    }
  }, [Id,variId, date]);

  const [quantityDropdown, setQuantityDropdown] = useState<number[]>([]);
  const [quantity, setQuantity] = useState(1);
  const [variant, setVariant] = useState<any>({
  });

  const [imageGallery, setImageGallery] = useState<any>([]);
  

  useEffect(() => {
   if((variId==undefined  ||  variId=="null") && productResponse?.productDetails?.data?.productVariantInfos==null ){ let quantiy = [];
    for (
      let i = 1;
      i <= productResponse?.productDetails?.data?.productDetail.quantity && i <= 10;
      i++
    )
      quantiy.push(i);
    setQuantityDropdown(quantiy);
  }
    let newGallery:any = [
      // {
      //   image: BASE_URLS.AWS_URL  + productResponse?.productDetails?.data?.productDetail.defaultImage,
      //   // text : "img1"
      // }
    ];

    productResponse?.productDetails?.data?.productDetail.images?.map((img:any, index:any)=>{
      newGallery.push({
        image: BASE_URLS.AWS_URL + img,
      })
    })
    setImageGallery(newGallery);
    if (productResponse?.productDetails?.data?.productVariantInfos === null && isFirstRender.current) {
      dispatch<any>(setRecentlyViewed({ productId: Id }));
        postStoreTraffic({storeId: productResponse?.productDetails?.data?.productDetail?.storedId,
        sellerId: productResponse?.productDetails?.data?.productDetail?.sellerId,
        ProductId:Id})
      
      isFirstRender.current = false
    }
  }, [productResponse?.productDetails?.data?.productDetail]);



  useEffect(() => {
    if (productResponse?.saveSeller != null) {
      if (productResponse?.saveSeller.success == "Y") {
        notify(productResponse?.saveSeller.message, constant.SUCCESS);
      } else if (productResponse?.saveSeller.success == "N") {
        notify(productResponse?.saveSeller.message, constant.ERROR);
      }
    }
    if (productResponse?.saveSellerError !== null) {
      notify(productResponse?.saveSellerError, constant.ERROR);
    }
    dispatch<any>(resetSave());
  }, [productResponse?.saveSeller, productResponse?.saveSellerError]);


const handleBuyItNow =()=>{

  const accessToken = localStorage.getItem("authorization");
  const role = localStorage.getItem("role");
  if (accessToken == null || role !== "Customer")
  {
    notify("Login as a customer to complete the action", constant.ERROR)
  }
 else if((productResponse?.productDetails?.data?.productVariantInfos!==null && variant.id===undefined) || quantityDropdown.length==0)
  {

   notify("No quantity available", constant.ERROR)
  }
  else
  {
    navigate(
      "/buyer/checkout",
      {
        state: {ProductId: Id,
        VariantId: variant.id,
        quantity:quantity,
        type:"BuyItNow",
        CartIds: [
           
         
        ]},

      }
    )
  }

}

  const handleAddToCart = () => {
   
   if((productResponse?.productDetails?.data?.productVariantInfos!==null && variant.id===undefined) || quantityDropdown.length==0)
   {

    notify("No quantity available", constant.ERROR,"noQuant")
   }
   else
   {
    localStorage.getItem("authorization") &&
    localStorage.getItem("role") === "Customer"
    ? dispatch<any>(
      addToCart({
        isCart: false,
        Cart:[{
        productId: Id,
        quantity: quantity,
        VariantId:variant.id,
      }]})
    )
    : addToCartLocal()
   }
    
  }

  const handleReviewOffer=()=>{
    setReviewOffer({...reviewOffer, visible:"block"})
   
  }

  const handleMakeOffer=()=>{
    const accessToken = localStorage.getItem("authorization");
    const role = localStorage.getItem("role");
    if (accessToken == null || role !== "Customer")
    {
      notify("Login as a customer to complete the action", constant.ERROR,"Customer")
    }
    else
    setMakeOffer({
      ...makeOffer,
      visible: "block",
     
    });
  }
  const handleAddToWatchlist= () => {
    const accessToken = localStorage.getItem("authorization");
    const role = localStorage.getItem("role");
    if (accessToken == null || role !== "Customer")
    {
      notify("Login as a customer to complete the action", constant.ERROR, "customer")
    }
    else if(productResponse?.productDetails?.data?.productVariantInfos!==null && variant.id!==undefined)
    dispatch<any>(
      setWatchlist({
        ProductId: Id,
        VaraintId: variant.id,
      }))
      
     else if(productResponse?.productDetails?.data?.productVariantInfos===null)
      dispatch<any>(
        setWatchlist({
          ProductId: Id,
        }))
    else
      notify("Please select all the attributes for variant", constant.ERROR ,"variant")
  }
  const handlePlaceBid = (newBid: any) => {

    const accessToken = localStorage.getItem("authorization");
    const role = localStorage.getItem("role");
    if (accessToken == null || role !== "Customer")
    {
      notify("Login as a customer to save this search", constant.ERROR,"customer")
    }
    else 
    dispatch<any>(saveBid({
      productId: Id,
      biddingAmount: newBid
    }))
  }


  const addToCartLocal = () => {
    const copyCartObj = JSON.parse(localStorage.getItem("cart") || "[]");
    let itemAlreadyInCart =variant.id ?  copyCartObj.find(
      (item: any) => (item.productId === Id && item.variantId===variant.id)
    ) :copyCartObj.find(
      (item: any) => (item.productId === Id )
    )  ;
    if (itemAlreadyInCart !== undefined) {
  
      copyCartObj?.forEach((product: any) => {
     if(variant.id)  
    {  if (product.productId === Id && product.variantId===variant.id) {
          product.quantity = quantity;
        }}
        else if(product.productId === Id)
        {
          product.quantity = quantity;

        }
      });
    } else if(productResponse?.productDetails?.data?.productVariantInfos!==null && variant.id!==undefined)
    {  copyCartObj.push({
        productId: Id,
        title: productResponse?.productDetails?.data?.productDetail.title,
        condition:
          productResponse?.productDetails?.data?.productDetail.condition,
        quantity: quantity,
        variantId:variant.id,
        variant:variant.variant,
        maxQuantity:
          variant.quantity,
        actualPrice:
          variant.actualPrice,
        buyNowPrice:
          variant.buyNowPrice,
        shippingCost:
          productResponse?.productDetails?.data?.productDetail.shippingCost,
        defaultImage:
          productResponse?.productDetails?.data?.productDetail.defaultImage,
        storeName:
          productResponse?.productDetails?.data?.productDetail.storeName,
      });}
      else
      {  copyCartObj.push({
        productId: Id,
        title: productResponse?.productDetails?.data?.productDetail.title,
        condition:
          productResponse?.productDetails?.data?.productDetail.condition,
        quantity: quantity,
        variantId:null,
        variant:null,

        maxQuantity:
          productResponse?.productDetails?.data?.productDetail.quantity,
        actualPrice:
          productResponse?.productDetails?.data?.productDetail.actualPrice,
        buyNowPrice:
          productResponse?.productDetails?.data?.productDetail.buyNowPrice,
        shippingCost:
          productResponse?.productDetails?.data?.productDetail.shippingCost,
        defaultImage:
          productResponse?.productDetails?.data?.productDetail.defaultImage,
        storeName:
          productResponse?.productDetails?.data?.productDetail.storeName,
      });}
    localStorage.setItem("cart", JSON.stringify(copyCartObj));
    notify("Added to cart", constant.SUCCESS,"addedCart");
    let cartData = JSON.parse(localStorage.getItem("cart") || "[]");
    let total=0;
   
   
    cartData.map((item:any)=>{
      total=total+ item.quantity;
    })
    
  
    dispatch<any>(setCartItems(total));
  };
  useEffect(() => {
    if (productResponse?.saveData != null) {
      if (productResponse?.saveData.success == "Y") {
        notify("Added to cart", constant.SUCCESS,"cartAdded");
        dispatch<any>(getCartItems());
      } else if (productResponse?.saveData.success == "N") {
        notify(productResponse?.saveData.message, constant.ERROR,"cartAdded");
      }
    }
    if (productResponse?.saveDataError !== null) {
      notify(productResponse?.saveDataError, constant.ERROR,"cartAdded");
    }
    dispatch<any>(resetSave());
  }, [productResponse?.saveData, productResponse?.saveDataError]);

  useEffect(() => {
    if (productResponse?.saveWatchlist != null) {
      if (productResponse?.saveWatchlist.success == "Y") {
        notify("Added to watchlist", constant.SUCCESS,"watchlistAdd");
      } else if (productResponse?.saveWatchlist.success == "N") {
        notify(productResponse?.saveWatchlist.message, constant.ERROR,"watchlistAdd");
      }
    }
    if (productResponse?.saveWatchlistError !== null) {
      notify(productResponse?.saveWatchlistError, constant.ERROR,"watchlistAdd");
    }
    dispatch<any>(resetSave());
  }, [productResponse?.saveWatchlist, productResponse?.saveWatchlistError]);
  useEffect(() => {
    if (productResponse?.saveBid != null) {
      if (productResponse?.saveBid.success == "Y") {
        notify("Bid placed successfully", constant.SUCCESS,"bidsuccess");
        dispatch<any>(getProductDetail(Id));
      } else if (productResponse?.saveBid.success == "N") {
        notify(productResponse?.saveBid.message, constant.ERROR,"biderror");
      }
    }
    if (productResponse?.saveBidError !== null) {
      notify(productResponse?.saveBidError, constant.ERROR,"watchlistError");
    }
    dispatch<any>(resetSave());
  }, [productResponse?.saveBid, productResponse?.saveBidError]);

  useEffect(() => {
    if (productResponse?.saveOffer != null) {
      if (productResponse?.saveOffer.success == "Y") {
        // notify("Offer placed successfully", constant.SUCCESS);
        dispatch<any>(getProductDetail(Id)).then(()=>  setReviewOffer({...reviewOffer, visible:"block"}));
      } else if (productResponse?.saveOffer.success == "N") {
        notify(productResponse?.saveOffer.message, constant.ERROR);
      }
    }
    if (productResponse?.saveOfferError !== null) {
      notify(productResponse?.saveOfferError, constant.ERROR);
    }
    dispatch<any>(resetSave());
  }, [productResponse?.saveOffer, productResponse?.saveOfferError]);
  return (
  
productResponse?.productDetailsError ?
<DefaultLayout>
<BuyerHeader />
<div className="pt-5 login-section" style={{ textAlign:"center"}}><h1>Product not available</h1></div>
<BuyerFooter/>
</DefaultLayout> : <DefaultLayout>
      <BuyerHeader />
      <ToastContainer />
<MakeOfferModel 
makeOffer={makeOffer}
setMakeOffer={setMakeOffer}
product={productResponse?.productDetails?.data?.productDetail}
quantityDropdown={quantityDropdown}
/>
<ReviewOfferModel
reviewOffer={reviewOffer}
setReviewOffer={setReviewOffer}
/>
      <main className="min-section">
        <div className="container">
          <div className="productDetailsBlock">
            <div className="row">
              <div className="col-xl-9">
                <div className="productDetailDes">
                  <div className="productMediaInfo" style={{}}>
                 { imageGallery.length != 0 &&  
                 <SliderImage
                    data={imageGallery}
                    width="400px"
                    showDescription={true}
                    direction="right"
                  /> 
                  }
                  </div>
                  {productResponse?.productDetails?.data?.productDetail.format == 1 ?
                    <NewDetails variantId={variId} setVariantDetail={setVariant} product={productResponse} setQuantity={setQuantity} setQuantityDropdown={setQuantityDropdown} productId={Id ? Id : ""} setImageGallery={setImageGallery} imageGallery={imageGallery} /> 
                    : <AuctionDetails product={productResponse} />}

                </div>
              </div>
              <div className="col-xl-3">
                {/* <div className="socialLinks" style={{textAlign:"left"}}>
                  {productResponse?.productDetails?.data?.sellerSocialMediaLinks?.map(
                    (social, Index) => (
                      <a
                        href={social.link}
                        key={social.logo}
                        target="_blank"
                        className=""
                      >
                        {" "}
                        <img src={BASE_URLS.AWS_URL + social.logo} style={{height:20,width:"35px"}} alt="" />
                      </a>
                    )
                  )}
                
                </div> */}


                {productResponse?.productDetails?.data?.productDetail.format == 1 ?
                  <ProductBuy
                    product={productResponse}
                    variantDetail={variant}
                    handleBuyItNow={handleBuyItNow}
                    quantity={quantity}
                    handleAddToWatchlist={handleAddToWatchlist}
                    quantityDropdown={quantityDropdown}
                    setQuantity={setQuantity}
                    handleAddToCart={handleAddToCart}
                    handleMakeOffer={handleMakeOffer}
                    handleReviewOffer={handleReviewOffer}
                    /> :
                    productResponse?.productDetails?.data?.productDetail.format == 2 ? 
                    

                  <ProductBid
                    product={productResponse}
                    handlePlaceBid={handlePlaceBid}
                    handleAddToWatchlist={handleAddToWatchlist}
                    
                    />
                    : null
                }

                <div className="sellerInfo">
                  <h4>Seller information</h4>
                  <div className="feedbacks">
                    <div className="img">
                      {" "}
                      <img src={BlueStar} alt="" />
                    </div>
                    <div className="txt">
                      <div className="title">
                        <strong>
                          {
                            productResponse?.productDetails?.data?.productDetail
                              ?.storeName
                          }
                        </strong>
                        <span>
                          (
                          {productResponse?.productDetails?.data?.productDetail
                            ?.totalSellerRating
                            ? productResponse?.productDetails?.data
                              ?.productDetail?.totalSellerRating
                            : 0}
                          <img src={Star} alt="" />)
                        </span>
                      </div>
                      <div className="percent">
                        {productResponse?.productDetails?.data?.productDetail
                          ?.sellerPositiveFeedback
                          ? productResponse?.productDetails?.data?.productDetail
                            ?.sellerPositiveFeedback
                          : 0}
                        % Positive feedback
                      </div>
                    </div>
                  </div>
                  <div className="sellerLinks">
                    <div className="save"
                      onClick={() =>
                        !productResponse?.productDetails?.data?.productDetail.isSelfProduct && dispatch<any>(
                          saveSeller({
                            SellerId:
                              productResponse?.productDetails?.data
                                ?.productDetail?.sellerId,
                          })
                        )
                      }
                    >
                      <i></i> Save this Seller
                    </div>
                    <div className="contact" onClick={() => {
                                 !productResponse?.productDetails?.data?.productDetail.isSelfProduct &&       navigate(
                                          "/buyer/contactSeller",
                                         {  state: {product:productResponse?.productDetails?.data
                                          ?.productDetail,route:`/buyer/productDetail/${Id}`}},
                                          
                                        );
                                      }}>
                      <i></i> Contact seller
                    </div>
                    <div className="visit" 
                    onClick={() => {
                      navigate(
                        `/buyer/sellerStore/${productResponse?.productDetails?.data
                          ?.productDetail?.sellerId}`,
                       
                        
                      );
                    }}
                    >
                      <i></i> Visit store
                    </div>
                    <div className="other" onClick={() => {
                      navigate(
                        `/buyer/sellerStore/${productResponse?.productDetails?.data
                          ?.productDetail?.sellerId}/${productResponse?.productDetails?.data
                            ?.productDetail?.categoryId}`,
                       
                        
                      );
                    }}>
                      <i></i> See other items
                    </div>
                  </div>
                </div>
                <div className="ads">
                  <img src="images/paypal-ad.png" alt="" />
                </div>
              </div>
            </div>

            <section className="recently-view-section camera sponsored d-none">
              <div className="row">
                <div className="col-md-12">
                  <div className="recent-view-container">
                    <a
                      href="javascript:void(0);"
                      className="prev-slide swiper-button-disabled"
                      tabIndex={-1}
                      role="button"
                      aria-label="Previous slide"
                      aria-controls="swiper-wrapper-cc5306f105f94523a"
                      aria-disabled="true"
                    >
                      <i className="prev-icon"></i>
                    </a>
                    <a
                      href="javascript:void(0);"
                      className="next-slide"
                      tabIndex={0}
                      role="button"
                      aria-label="Next slide"
                      aria-controls="swiper-wrapper-cc5306f105f94523a"
                      aria-disabled="false"
                    >
                      <i className="next-icon"></i>
                    </a>

                    <div className="recent-listing-block">
                      <div className="h2-block mb-4 p-0">
                        <h2 className="h2-section-title text-uppercase">
                          Similar sponsored items
                        </h2>
                      </div>
                      {/* <div className="swiper-container swiper recentListSwiper swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events">
                  <div className="swiper-wrapper" id="swiper-wrapper-ed466cc2eecc5371" aria-live="polite" style="transform: translate3d(0px, 0px, 0px);">

                    <div className="swiper-slide swiper-slide-active" role="group" aria-label="1 / 8" style="width: 280.6px; margin-right: 20px;">
                      <div className="recent-list-item">
                        <i className="timer-icon"></i>
                        <figure className="recent-media">
                          <a href="#"><img src="images/Layer 912.png"></a>
                        </figure>
                        <div className="recent-details">
                          <div className="recent-title">KENOSHA KICKERS
                            Short-Sleeve Unisex T-Shirt</div>
                          <div className="recent-price">$17.99</div>
                          <div className="recent-price-small">
                            <span className="price-cut">US $34.77</span>
                            <span>|</span>
                            <span className="price-bold">20% OFF</span>
                            <div className="referbished">
                              <img src="images/Layer 793.png" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="swiper-slide swiper-slide-next" role="group" aria-label="2 / 8" style="width: 280.6px; margin-right: 20px;">
                      <div className="recent-list-item">
                        <i className="timer-icon"></i>
                        <figure className="recent-media">
                          <a href="#"><img src="images/Layer 912.png"></a>
                        </figure>
                        <div className="recent-details">
                          <div className="recent-title">KENOSHA KICKERS
                            Short-Sleeve Unisex T-Shirt</div>
                          <div className="recent-price">$17.99</div>
                          <div className="recent-price-small">
                            <span className="price-cut">US $34.77</span>
                            <span>|</span>
                            <span className="price-bold">20% OFF</span>
                            <div className="fi-stock-status">Almost Gone</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="swiper-slide" role="group" aria-label="3 / 8" style="width: 280.6px; margin-right: 20px;">
                      <div className="recent-list-item">
                        <i className="timer-icon"></i>
                        <figure className="recent-media">
                          <a href="#"><img src="images/Layer 912.png"></a>
                        </figure>
                        <div className="recent-details">
                          <div className="recent-title">KENOSHA KICKERS
                            Short-Sleeve Unisex T-Shirt</div>
                          <div className="recent-price">$17.99</div>
                          <div className="recent-price-small">
                            <span className="price-cut">US $34.77</span>
                            <span>|</span>
                            <span className="price-bold">20% OFF</span>
                            <div className="fi-ship-status">Free shipping</div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="swiper-slide" role="group" aria-label="4 / 8" style="width: 280.6px; margin-right: 20px;">
                      <div className="recent-list-item">
                        <i className="timer-icon"></i>
                        <figure className="recent-media">
                          <a href="#"><img src="images/Layer 912.png"></a>
                        </figure>
                        <div className="recent-details">
                          <div className="recent-title">KENOSHA KICKERS
                            Short-Sleeve Unisex T-Shirt</div>
                          <div className="recent-price">$17.99</div>
                          <div className="recent-price-small">
                            <span className="price-cut">US $34.77</span>
                            <span>|</span>
                            <span className="price-bold">20% OFF</span>
                            <div className="referbished">
                              <img src="images/Layer 793.png" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="swiper-slide" role="group" aria-label="5 / 8" style="width: 280.6px; margin-right: 20px;">
                      <div className="recent-list-item">
                        <i className="timer-icon"></i>
                        <figure className="recent-media">
                          <a href="#"><img src="images/Layer 912.png"></a>
                        </figure>
                        <div className="recent-details">
                          <div className="recent-title">KENOSHA KICKERS
                            Short-Sleeve Unisex T-Shirt</div>
                          <div className="recent-price">$17.99</div>
                          <div className="recent-price-small">
                            <span className="price-cut">US $34.77</span>
                            <span>|</span>
                            <span className="price-bold">20% OFF</span>
                            <div className="referbished">
                              <img src="images/Layer 793.png" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="swiper-slide" role="group" aria-label="6 / 8" style="width: 280.6px; margin-right: 20px;">
                      <div className="recent-list-item">
                        <i className="timer-icon"></i>
                        <figure className="recent-media">
                          <a href="#"><img src="images/Layer 912.png"></a>
                        </figure>
                        <div className="recent-details">
                          <div className="recent-title">KENOSHA KICKERS
                            Short-Sleeve Unisex T-Shirt</div>
                          <div className="recent-price">$17.99</div>
                          <div className="recent-price-small">
                            <span className="price-cut">US $34.77</span>
                            <span>|</span>
                            <span className="price-bold">20% OFF</span>
                            <div className="referbished">
                              <img src="images/Layer 793.png" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="swiper-slide" role="group" aria-label="7 / 8" style="width: 280.6px; margin-right: 20px;">
                      <div className="recent-list-item">
                        <i className="timer-icon"></i>
                        <figure className="recent-media">
                          <a href="#"><img src="images/Layer 912.png"></a>
                        </figure>
                        <div className="recent-details">
                          <div className="recent-title">KENOSHA KICKERS
                            Short-Sleeve Unisex T-Shirt</div>
                          <div className="recent-price">$17.99</div>
                          <div className="recent-price-small">
                            <span className="price-cut">US $34.77</span>
                            <span>|</span>
                            <span className="price-bold">20% OFF</span>
                            <div className="referbished">
                              <img src="images/Layer 793.png" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="swiper-slide" role="group" aria-label="8 / 8" style="width: 280.6px; margin-right: 20px;">
                      <div className="recent-list-item">
                        <i className="timer-icon"></i>
                        <figure className="recent-media">
                          <a href="#"><img src="images/Layer 912.png"></a>
                        </figure>
                        <div className="recent-details">
                          <div className="recent-title">KENOSHA KICKERS
                            Short-Sleeve Unisex T-Shirt</div>
                          <div className="recent-price">$17.99</div>
                          <div className="recent-price-small">
                            <span className="price-cut">US $34.77</span>
                            <span>|</span>
                            <span className="price-bold">20% OFF</span>
                            <div className="referbished">
                              <img src="images/Layer 793.png" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* <section className="recently-view-section camera sponsored">
        <div className="row">
          <div className="col-md-12">
            
            <div className="recent-view-container">
              <a href="javascript:void(0);" className="prev-slide swiper-button-disabled" tabindex="-1" role="button" aria-label="Previous slide" aria-controls="swiper-wrapper-cc5306f105f94523a" aria-disabled="true"><i className="prev-icon"></i></a>
              <a href="javascript:void(0);" className="next-slide" tabindex="0" role="button" aria-label="Next slide" aria-controls="swiper-wrapper-cc5306f105f94523a" aria-disabled="false"><i className="next-icon"></i></a>

              <div className="recent-listing-block">
                <div className="h2-block mb-4 p-0">
                  <h2 className="h2-section-title text-uppercase">Sponsored items from this seller</h2>
                </div>
                <div className="swiper-container swiper recentListSwiper swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events">
                  <div className="swiper-wrapper" id="swiper-wrapper-ed466cc2eecc5371" aria-live="polite" style="transform: translate3d(0px, 0px, 0px);">

                    <div className="swiper-slide swiper-slide-active" role="group" aria-label="1 / 8" style="width: 280.6px; margin-right: 20px;">
                      <div className="recent-list-item">
                        <i className="timer-icon"></i>
                        <figure className="recent-media">
                          <a href="#"><img src="images/Layer 912.png"></a>
                        </figure>
                        <div className="recent-details">
                          <div className="recent-title">KENOSHA KICKERS
                            Short-Sleeve Unisex T-Shirt</div>
                          <div className="recent-price">$17.99</div>
                          <div className="recent-price-small">
                            <span className="price-cut">US $34.77</span>
                            <span>|</span>
                            <span className="price-bold">20% OFF</span>
                            <div className="referbished">
                              <img src="images/Layer 793.png" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="swiper-slide swiper-slide-next" role="group" aria-label="2 / 8" style="width: 280.6px; margin-right: 20px;">
                      <div className="recent-list-item">
                        <i className="timer-icon"></i>
                        <figure className="recent-media">
                          <a href="#"><img src="images/Layer 912.png"></a>
                        </figure>
                        <div className="recent-details">
                          <div className="recent-title">KENOSHA KICKERS
                            Short-Sleeve Unisex T-Shirt</div>
                          <div className="recent-price">$17.99</div>
                          <div className="recent-price-small">
                            <span className="price-cut">US $34.77</span>
                            <span>|</span>
                            <span className="price-bold">20% OFF</span>
                            <div className="fi-stock-status">Almost Gone</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="swiper-slide" role="group" aria-label="3 / 8" style="width: 280.6px; margin-right: 20px;">
                      <div className="recent-list-item">
                        <i className="timer-icon"></i>
                        <figure className="recent-media">
                          <a href="#"><img src="images/Layer 912.png"></a>
                        </figure>
                        <div className="recent-details">
                          <div className="recent-title">KENOSHA KICKERS
                            Short-Sleeve Unisex T-Shirt</div>
                          <div className="recent-price">$17.99</div>
                          <div className="recent-price-small">
                            <span className="price-cut">US $34.77</span>
                            <span>|</span>
                            <span className="price-bold">20% OFF</span>
                            <div className="fi-ship-status">Free shipping</div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="swiper-slide" role="group" aria-label="4 / 8" style="width: 280.6px; margin-right: 20px;">
                      <div className="recent-list-item">
                        <i className="timer-icon"></i>
                        <figure className="recent-media">
                          <a href="#"><img src="images/Layer 912.png"></a>
                        </figure>
                        <div className="recent-details">
                          <div className="recent-title">KENOSHA KICKERS
                            Short-Sleeve Unisex T-Shirt</div>
                          <div className="recent-price">$17.99</div>
                          <div className="recent-price-small">
                            <span className="price-cut">US $34.77</span>
                            <span>|</span>
                            <span className="price-bold">20% OFF</span>
                            <div className="referbished">
                              <img src="images/Layer 793.png" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="swiper-slide" role="group" aria-label="5 / 8" style="width: 280.6px; margin-right: 20px;">
                      <div className="recent-list-item">
                        <i className="timer-icon"></i>
                        <figure className="recent-media">
                          <a href="#"><img src="images/Layer 912.png"></a>
                        </figure>
                        <div className="recent-details">
                          <div className="recent-title">KENOSHA KICKERS
                            Short-Sleeve Unisex T-Shirt</div>
                          <div className="recent-price">$17.99</div>
                          <div className="recent-price-small">
                            <span className="price-cut">US $34.77</span>
                            <span>|</span>
                            <span className="price-bold">20% OFF</span>
                            <div className="referbished">
                              <img src="images/Layer 793.png" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="swiper-slide" role="group" aria-label="6 / 8" style="width: 280.6px; margin-right: 20px;">
                      <div className="recent-list-item">
                        <i className="timer-icon"></i>
                        <figure className="recent-media">
                          <a href="#"><img src="images/Layer 912.png"></a>
                        </figure>
                        <div className="recent-details">
                          <div className="recent-title">KENOSHA KICKERS
                            Short-Sleeve Unisex T-Shirt</div>
                          <div className="recent-price">$17.99</div>
                          <div className="recent-price-small">
                            <span className="price-cut">US $34.77</span>
                            <span>|</span>
                            <span className="price-bold">20% OFF</span>
                            <div className="referbished">
                              <img src="images/Layer 793.png" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="swiper-slide" role="group" aria-label="7 / 8" style="width: 280.6px; margin-right: 20px;">
                      <div className="recent-list-item">
                        <i className="timer-icon"></i>
                        <figure className="recent-media">
                          <a href="#"><img src="images/Layer 912.png"></a>
                        </figure>
                        <div className="recent-details">
                          <div className="recent-title">KENOSHA KICKERS
                            Short-Sleeve Unisex T-Shirt</div>
                          <div className="recent-price">$17.99</div>
                          <div className="recent-price-small">
                            <span className="price-cut">US $34.77</span>
                            <span>|</span>
                            <span className="price-bold">20% OFF</span>
                            <div className="referbished">
                              <img src="images/Layer 793.png" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="swiper-slide" role="group" aria-label="8 / 8" style="width: 280.6px; margin-right: 20px;">
                      <div className="recent-list-item">
                        <i className="timer-icon"></i>
                        <figure className="recent-media">
                          <a href="#"><img src="images/Layer 912.png"></a>
                        </figure>
                        <div className="recent-details">
                          <div className="recent-title">KENOSHA KICKERS
                            Short-Sleeve Unisex T-Shirt</div>
                          <div className="recent-price">$17.99</div>
                          <div className="recent-price-small">
                            <span className="price-cut">US $34.77</span>
                            <span>|</span>
                            <span className="price-bold">20% OFF</span>
                            <div className="referbished">
                              <img src="images/Layer 793.png" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

            <section className="tabsBlock">
              <div className="tabsWraper">
                <div className="tabLink">
                  <span  style={{cursor:"pointer"}} className="reportItem"  onClick={() => {
                                        navigate(
                                          "/buyer/reportItem",
                                          {
                                            state: {product:{productName: productResponse?.productDetails?.data?.productDetail?.title ,
                                               storeName:productResponse?.productDetails?.data?.productDetail?.storeName,
                                               productImage:productResponse?.productDetails?.data?.productDetail?.defaultImage,
                                               productId:Id,
                                              },
                                            
                                            route:`/buyer/productDetail/${Id}`},
                                          }
                                        );
                                      }}>
                    Report item
                  </span>
                  <ul>
                    <li className="active">
                      <a href="#">Description</a>
                    </li>
                    {/* <li>
                      <a href="#">Shipping and payments</a>
                    </li> */}
                  </ul>
                </div>

                <div className="tabContent">
                  <div className="row justify-content-between align-items-center">
                    <div className="col-auto">
                      <div className="note">
                        Seller assumes all responsibility for this listing.
                      </div>
                      {/* <div className="update">
                        Last updated on Jul 09, 2021 15:01:30 PDT{" "}
                        <a href="">View all revisions</a>
                      </div> */}
                    </div>
                    <div className="col-auto">
                      {/* <div className="itemNum">
                        item number: <strong> 114853777507</strong>
                      </div> */}
                    </div>
                  </div>

                  <div className="tabContetWrap">
                    <h3>About this product</h3>

                    <div className="sellerNote">
                      {/* <div className="row mb-3">
                        <div className="col-lg-3 col-xl-1 txtLabel">
                          Condition:
                        </div>
                        <div className="col-lg-9 col-xl-10 txtDesc">
                          <strong>{productResponse?.productDetails?.data?.productDetail?.condition}</strong>
                        </div>
                      </div> */}
                      <div className="row">
                        <div className="col-lg-3 col-xl-1 txtLabel">
                          Additional Features:
                        </div>
                        <div className="col-lg-9 col-xl-10 txtDesc">
                          <p dangerouslySetInnerHTML={{__html: productResponse?.productDetails?.data?.productDetail?.additonalFeatures}}>
                          
                            {/* {productResponse?.productDetails?.data?.productDetail?.note} */}
                          </p>
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-lg-3 col-xl-1 txtLabel">
                          Seller notes:
                        </div>
                        <div className="col-lg-9 col-xl-10 txtDesc">
                          <p >
                          
                            {productResponse?.productDetails?.data?.productDetail?.note}
                          </p>
                        </div>
                      </div> */}
                    </div>

                    {/* <div className="sellerDescTable">
                      <table>
                       
                        {productResponse?.productDetails?.data?.productDetail
                    ?.productSpecification &&
                    JSON.parse(
                      productResponse?.productDetails?.data?.productDetail
                        ?.productSpecification
                    ).map((element: any, Index: number) => {
                      return (
                        <tr key={Index}>
                          <td>{element.Title}s</td>
                          <td>{element.Text}s</td>
                        </tr>
                      );
                    })}
                      </table>
                    </div> */}
                  </div>
                </div>
              </div>
            </section>

            <section className="aboutProduct">
              <h3>Item specification</h3>

              <h4>Product information</h4>
              <p>
                {productResponse?.productDetails?.data?.productDetail?.note}
              </p>

              <div className="productFeatureTable">
                <table>
                  <thead>
                    <tr>
                      <th>Product identifiers</th>
                      <td></td>
                    </tr>
                  </thead>

                  {productResponse?.productDetails?.data?.productDetail
                    ?.productSpecification &&
                    JSON.parse(
                      productResponse?.productDetails?.data?.productDetail
                        ?.productSpecification
                    ).map((element: any, Index: number) => {
                      return (
                        <tr key={Index}>
                          <th>{element.Title}s</th>
                          <td>{element.Text}s</td>
                        </tr>
                      );
                    })}
                </table>
              </div>
              {/* <div className="productFeatureTable">
                <table>
                  <thead>
                    <tr>
                      <th>Product Key Features</th>
                      <td></td>
                    </tr>
                  </thead>
                  <tr>
                    <th>Network</th>
                    <td>Unlocked</td>
                  </tr>
                  <tr>
                    <th>Operating System</th>
                    <td>iOS</td>
                  </tr>
                  <tr>
                    <th>Storage Capacity</th>
                    <td>32 GB</td>
                  </tr>
                  <tr>
                    <th>Color</th>
                    <td>Black</td>
                  </tr>
                  <tr>
                    <th>Model Number</th>
                    <td>A1660 (CDMA + GSM)</td>
                  </tr>
                  <tr>
                    <th>Connectivity</th>
                    <td>Bluetooth, Wi-Fi, NFC</td>
                  </tr>
                  <tr>
                    <th>Processor</th>
                    <td>Quad Core</td>
                  </tr>
                  <tr>
                    <th>Style</th>
                    <td>Bar</td>
                  </tr>
                  <tr>
                    <th>Camera Resolution</th>
                    <td> 12.0 MP</td>
                  </tr>
                  <tr>
                    <th>Screen Size</th>
                    <td>4.7 in</td>
                  </tr>
                  <tr>
                    <th>RAM</th>
                    <td>2 GB</td>
                  </tr>
                </table>
              </div>
              <div className="productFeatureTable">
                <table>
                  <thead>
                    <tr>
                      <th>Dimensions</th>
                      <td></td>
                    </tr>
                  </thead>
                  <tr>
                    <th>Weight</th>
                    <td>4.87 Oz</td>
                  </tr>
                  <tr>
                    <th>Depth</th>
                    <td>0.28in.</td>
                  </tr>
                  <tr>
                    <th>Height</th>
                    <td>5.44in.</td>
                  </tr>
                  <tr>
                    <th>Width</th>
                    <td>2.64in.</td>
                  </tr>
                </table>
              </div> */}
            </section>

     { Id &&    <ReviewSection Id={Id} product={productResponse?.productDetails} />}
          </div>
        </div>
      </main>
      <BuyerFooter />
      
  { (reviewOffer.visible==="block"  || makeOffer.visible=="block") &&   <div className="modal-backdrop show"></div>}
    </DefaultLayout>
  );
};

export default ProductDetail;
