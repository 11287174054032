import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { DefaultLayout } from "../../../../../layout/DefaultLayout";
import { getPurchaseHistory } from "../../../../../modules/buyer/myEfindit/activity/getPurchaseHistory";
import { activityResponseSelector } from "../../../../../modules/buyer/myEfindit/activity/selectors";
import { dateToLocal } from "../../../../../modules/date/date";
import { notify } from "../../../../../modules/notification/notify";
import { BASE_URLS } from "../../../../../utils/api.urls";
import { constant } from "../../../../../utils/constant";
import { RootState } from "../../../../../utils/types";
import BuyerHeader from "../../../BuyerHeader";
import GroupTab from "../../groupTab";
import ActivityNavPanel from "../ActivityNavPanel";
import "./purchase.scss"
import "react-toastify/dist/ReactToastify.css";
import { getCommonData } from "../../../../../modules/common/getCommonData";
import { CommonResponseSelector } from "../../../../../modules/common/selectors";
import OrderCancelModel from "../../../../seller/order/OrderCancelModel";
import { SellerOrdersResponseSelector } from "../../../../../modules/orders/selectors";
import { resetOrder } from "../../../../../modules/orders/resetOrder";
import { Link, useNavigate } from "react-router-dom";
import { resetDetail } from "../../../../../modules/buyer/product/resetDetail";
import ReturnOrderModel from "../ReturnOrderlModel";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {getWebPImage, imageOnError} from "../../../../../utils/helpers";
import { resetActivity } from "../../../../../modules/buyer/myEfindit/activity/resetActivity";
import moment from "moment";

const PurchaseHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isFirstRender2 = useRef(true);
  const isFirstRender = useRef(true);

  let activityResponse = useSelector<RootState, RootState["activity"]>(
    activityResponseSelector
  );
  let commonResponse = useSelector<RootState, RootState["common"]>(
    CommonResponseSelector
  );

  let order = useSelector<RootState, RootState["sellerOrders"]>(
    SellerOrdersResponseSelector
  );

  interface filterValues {
    status:number,
    search:string,
    lastDays:number | string
  }
  const [filter, setFilter] = useState<filterValues>({
    status:0,
    search:"",
    lastDays:""
  });
  const [search, setSearch] = useState("");

  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(16);
  const [currentPage, setcurrentPage] = useState(0);
  
  const [typeCheck, setTypeCheck] = useState<any>("All");
  interface cancelValues {
    visible: string;
    orderId: string;
    reason: string | null;
    description: string | null;
  }

  const [cancelOrder, setCancelOrder] = useState<cancelValues>({
    visible: "none",
    orderId: "",
    reason: null,
    description: null,
  });

  const [returnOrder, setReturnOrder] = useState<cancelValues>({
    visible: "none",
    orderId: "",
    reason: null,
    description: null,
  });
  if (activityResponse.purchaseHistory !== null && pageCount === 0) {
    setPageCount(
      Math.ceil(
        activityResponse?.purchaseHistory?.data?.totalResult / itemCount
      )
    );
  }

  const handlePageChange = (selectedObject: any) => {
    setcurrentPage(selectedObject.selected);
  };

  const handleItemsChange = (event: any) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };

  useEffect(() => {
    if (order.saveData != null) {
      if (order.saveData.success == "Y") {
        notify(order.saveData.message, constant.SUCCESS);
        dispatch<any>(
          getPurchaseHistory({
            currentPage: currentPage + 1,
            rows: itemCount,
            status: filter.status,
            filter:filter,
          })
        ).then(() => {
          if (currentPage === 0) setPageCount(0);
        });
      } else if (order.saveData.success == "N") {
        notify(order.saveData.message, constant.ERROR);
      }

      setCancelOrder({
        visible: "none",
        orderId: "",
        description: null,
        reason: null,
      });
    }
    if (order.saveDataError !== null) {
      notify(order.saveDataError, constant.ERROR);
    }
    dispatch<any>(resetOrder());
  }, [order.saveData, order.saveDataError]);

  useEffect(() => {
    if (order.saveReturnBuyer != null) {
      if (order.saveReturnBuyer.success == "Y") {
        notify(order.saveReturnBuyer.message, constant.SUCCESS);
        // dispatch<any>(
        //   getPurchaseHistory({
        //     currentPage: currentPage + 1,
        //     rows: itemCount,
        //     status: filter.status,
        //     filter:filter,
        //   })
        // ).then(() => {
        //   if (currentPage === 0) setPageCount(0);
        // });
        navigate(
          "/buyer/returnDetail",
          {
            state: order.saveReturnBuyer.data,
          }
        );
      } else if (order.saveReturnBuyer.success == "N") {
        notify(order.saveReturnBuyer.message, constant.ERROR);
      }

      setReturnOrder({
        visible: "none",
        orderId: "",
        description: null,
        reason: null,
      });
    }
    if (order.saveReturnBuyerError !== null) {
      notify(order.saveReturnBuyerError, constant.ERROR);
    }
    dispatch<any>(resetOrder());
  }, [order.saveReturnBuyer, order.saveReturnBuyerError]);
  useEffect(() => {
    if (!isFirstRender2.current) {
      dispatch<any>(
        getPurchaseHistory({
          currentPage: currentPage + 1,
          rows: itemCount,
          status: filter.status,
          filter:filter,
        })
      ).then(() => {
        window.scrollTo(0, 0);
        if (currentPage === 0) setPageCount(0);
      });
      // dispatch<any>(resetActivity("sellerFeedback")).then(()=> dispatch<any>(resetActivity("orderDetails")));
    }
    isFirstRender2.current = false;
  }, [currentPage, itemCount, filter]);
  useEffect(() => {
    if (isFirstRender.current) {
      dispatch<any>(
        getPurchaseHistory({
          currentPage: currentPage + 1,
          rows: itemCount,
          status: filter.status,
          filter:filter,
        })
      ).then(() => {
        if (currentPage === 0) setPageCount(0);
      });
    }
    commonResponse.common === null && dispatch<any>(getCommonData());
    isFirstRender.current = false;
  }, []);


  useEffect(()=>{
    setcurrentPage(0);
  },[filter])

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      // Handle the Enter key press here
      setFilter({...filter, search:search})
      // You can call a function or perform any action
    }
  };
  return (
    <div>
      <DefaultLayout>
        <BuyerHeader />
        <ToastContainer />
        <OrderCancelModel
          cancelOrder={cancelOrder}
          setCancelOrder={setCancelOrder}
          commonResponse={commonResponse}
        />
          <ReturnOrderModel
          returnOrder={returnOrder}
          setReturnOrder={setReturnOrder}
          commonResponse={commonResponse}
        />
        <main className="min-section">
          <div className="container">
            <h1 className="sectionTitle mt-5">My eFindit</h1>

            <GroupTab currentPage="Activity" />
            <section className="withFilter">
              <ActivityNavPanel currentPage="PurchaseHistory" />

              <div className="right-wrapper">
                <section className="deals-group pt-0">
                  <div className="deal-group-list four-col bgLight mt-0">
                    <div className="right-wrap-title-block">
                      <div className="row align-items-center">
                        <div className="col-md-auto">
                          <div className="right-wrap-title">
                            Purchase history
                          </div>
                        </div>
                        <div className="col-md-auto">
                          {commonResponse?.common?.purchaseHistory?.map(
                            (item, Index) => (
                              <div className="radio d-inline-block mr-4">
                                <input
                                  id={item.label}
                                  name={item.label}
                                  type="radio"
                                  defaultChecked={
                                    item.label === "All" ? true : false
                                  }
                                  checked={
                                    item.label === typeCheck ? true : false
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setTypeCheck(item.label);
                                      setFilter({...filter, status: item.value});
                                      setcurrentPage(0);
                                    }
                                  }}
                                />
                                <label
                                  htmlFor={item.label}
                                  className="radio-label"
                                >
                                  {item.label}
                                </label>
                              </div>
                            )
                          )}


                        </div>
                        <div className="col-md-auto">
                        <div className="searchBox">
                    <input type="text" value={search} 
                onKeyDown={handleKeyPress}
                    
                    onChange={(e:any)=>setSearch(e.target.value)} placeholder="Search using order name or id"/>
                    <button className="searchBtn" onClick={()=>setFilter({...filter, search:search})}>Search</button>
                  </div>
                  </div>
                      </div>
                    </div>
                    <div className="bidListCont purchase-history-wrapper">
                      <div className="table-responsive">
                        <table className="table ph-tr-top">
                          <tr className="head">
                            <td colSpan={2} className="text-right">
                              <table className="w-100">
                                <tr>
                                  <td className="p-0">
                                  <div className="sortSelect d-inline-block">
                        <label htmlFor="">See orders from:&nbsp;</label>
                        <div className="selectBox">
                          <select
                            name=""
                            id=""
                            onChange={(e) =>
                              setFilter({...filter , lastDays: e.target.value!=="" ? parseInt(e.target.value) :""})
                            }
                          >
                            <option value="">Select</option>
                            <option value="30">Last 30 days</option>
                            <option value="60">Last 60 days</option>
                          </select>
                        </div>
                      </div>
                                  
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <table className="table">
                          {activityResponse?.purchaseHistory?.data?.orders?.map(
                            (item, Index) => (
                              <tr className="ph-row">
                                <td width="210" className="ph-product-img">
                                  <div
                                    className=""
                                    style={{ color: "#5d5d5d", fontSize: 12 }}
                                  >
                                    Order date and time
                                  </div>
                                  <h4>{dateToLocal(item.orderDate)}</h4>
                                  <p className="itemNumber">
                                    Item sold by{" "}
                                    <span style={{ color: "#3453c8" }}>
                                      {item.storeName}
                                    </span>
                                  </p>
                                  
                                  
                                  <div onClick={() =>
        dispatch<any>(
          resetDetail()).then(()=>navigate(`/buyer/productDetail/${item.productId}/${item.variantId}/${(new Date()).toISOString()}`))      
      }>
                                  <picture>
                                      <source srcSet={getWebPImage(item.productImage)} type="image/webp"/>
                                      <LazyLoadImage
                                        className="prod-img"
                                        src={BASE_URLS.AWS_URL +
                                          "/Thumb" +
                                          item.productImage}
                                          onError={imageOnError}
                                        />
                                    </picture>
                                  </div>
                                  
                                </td>
                                <td width="310" className="ph-product-info">
                                  <div
                                    className=""
                                    style={{ color: "#5d5d5d", fontSize: 12 }}
                                  >
                                    Order Id
                                  </div>
                                  <div className="itemNumber">
                                    ( {item.orderId})
                                  </div>

                                  <h4 className="itemTitle">
                                    {item.productName}
                                  </h4>

                                  <div className="itemNumber">
                                    <p>{item.status}</p>
                                    {
                                  JSON.parse(item.variant)?.map((product:any)=>(
                                  <p> {`${product.AttName} : ${product.Option}`} </p>
                                  ))

                                 }
                                    <p>Quantity : {item.quantity}</p>
                                  </div>
                                  {item.estimteDelivery !== null && (
                                    <div className="itemNumber">
                                      <p>Estimated delivery</p>
                                      <h3 style={{ color: "black" }}>
                                        {moment(dateToLocal(item.estimteDelivery)).format("DD MMM YYYY")}
                                      </h3>
                                    </div>
                                  )}
                                  {item.itemShipped &&
                                    item.deliveryDate === null && (
                                      <p
                                        style={{
                                          color: "#3453c8",
                                          fontSize: 12,
                                        }}
                                      >
                                        Item Dispatched
                                      </p>
                                    )}
                                  {item.deliveryDate !== null && (
                                    <p style={{ color: "green", fontSize: 12 }}>
                                      <span>Item delivered</span> <br />
                                      <span>
                                        {moment(dateToLocal(item.deliveryDate)).format("DD MMM YYYY")}
                                      </span>
                                    </p>
                                  )}
                                </td>

                                <td className="ph-product-amount">
                                  <div className="date">Amount paid</div>
                                  <div className="bid-price">
                                    ${(item.totalPrice +item.shippingCost).toFixed(2)}
                                  </div>
                                </td>

                                <td width="200" className="ph-button-col">
                                  {item.cancellationAllowed &&
                                    item.deliveryDate === null && (
                                      <div
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        className="buyNow"
                                        onClick={() => {
                                          setCancelOrder({
                                            ...cancelOrder,
                                            visible: "block",
                                            orderId: item.orderId,
                                          });
                                        }}
                                      >
                                        Cancel order
                                      </div>
                                    )}
                                  {item.returnAllowed &&
                                    item.deliveryDate !== null && (
                                      <button className="viewSeller"  onClick={() => {
                                        dispatch<any>(resetActivity("sellerFeedback")).then(()=> dispatch<any>(resetActivity("orderDetails"))).then(
                                          setReturnOrder({
                                          ...returnOrder,
                                          visible: "block",
                                          orderId: item.orderId,
                                        }));
                                      }}>
                                        Return Order
                                      </button>
                                    )}
                                      { item.deliveryDate !== null &&  item.status!=="Cancelled" &&   <button
                                      className="blueBorderBtn"
                                      onClick={() => {
                                        navigate(
                                          "/buyer/activity/leaveFeedback",
                                          {
                                            state: item,
                                          }
                                        );
                                      }}
                                    >
                                      Leave feedback
                                    </button>}
                                  {item.deliveryDate === null && item.shipmentId ? (
                                  
                                  item.status!=="Cancelled" &&  <button
                                    className="blueBorderBtn"
                                    onClick={() => {
                                     dispatch<any>(resetOrder("details")).then(()=> navigate(
                                        "/buyer/activity/trackOrder",{
                                          state:item.shipmentId
                                        }
                                      ));
                                    }}
                                  >
                                   Track item
                                  </button>
                                  ) : (
                                <>  
                                
                          
                                    <button
                                      className="blueBorderBtn"
                                      onClick={() => {
                                        navigate(
                                          "/buyer/contactSeller",
                                          {
                                            state: {product:item,route:"/buyer/activity/purchaseHistory"},
                                          }
                                        );
                                      }}
                                    >
                                      Contact seller
                                    </button>
                                    <button
                                      className="blueBorderBtn"
                                      onClick={() => {
                                        navigate(
                                          "/buyer/reportItem",
                                          {
                                            state: {product:item,route:"/buyer/activity/purchaseHistory"},
                                          }
                                        );
                                      }}
                                    >
                                      Report item
                                    </button>
                                 { item.returnId &&  <button
                                      className="blueBorderBtn"
                                      onClick={() => {
                                        dispatch<any>(resetActivity("sellerFeedback")).then(()=> dispatch<any>(resetActivity("orderDetails"))).then(
                                        navigate(
                                          "/buyer/returnDetail",
                                          {
                                            state: item.returnId,
                                          }
                                        ));
                                      }}
                                    >
                                      Return details
                                    </button>}
                                  
                                  
                                    
                                    </>
                                  )}
                       <button
                                      className="blueBorderBtn"
                                      onClick={() => {
                                        navigate(
                                          "/buyer/orderDetail",
                                          {
                                            state: item,
                                          }
                                        );
                                      }}
                                    >
                                      View details
                                    </button>
                                  {/* <div className="selectBox">
                                    <select name="" id="">
                                      <option value="">More Action</option>
                                      <option value="">More Action</option>
                                    </select>
                                  </div> */}
                                </td>
                              </tr>
                            )
                          )}
                        </table>
                      </div>
                    </div>

                    <nav className="pagination-block">
                      <p className="total">
                        {activityResponse?.purchaseHistory?.data?.totalResult}{" "}
                        items total
                      </p>

                      <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        forcePage={currentPage}
                        onPageChange={handlePageChange}
                        containerClassName={
                          "pagination justify-content-center mt-4"
                        }
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                      <div className="customSelect">
                        <label htmlFor="">Items per page :</label>
                        <div className="selectBox">
                          <select name="" id="" onChange={handleItemsChange}>
                          <option value="16">16</option>
                            <option value="24">24</option>

                            <option value="32">32</option>
                          </select>
                        </div>
                      </div>
                    </nav>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </main>
        { cancelOrder.visible==="block"  || returnOrder.visible=="block"  &&   <div className="modal-backdrop show"></div>}
      </DefaultLayout>
    </div>
  );
};

export default PurchaseHistory;
