import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dashboard, Login, Register, SellerTraffic } from "../../utils/types";

type DashboardState = {
  dashboard: Dashboard["dashboard"] | null;
  error: any | null;
  tasks: Dashboard["tasks"] | null;
  tasksError: any | null;
  feedbacks: Dashboard["feedbacks"] | null;
  feedbackError: any | null;
};

const initialState: DashboardState = {
  dashboard: null,
  error: null,
  tasks: null,
  tasksError: null,
  feedbacks: null,
  feedbackError: null,
};

const slice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    DashboardSuccess: (state, action: PayloadAction<any>) => {
      state.dashboard = action.payload;
      state.error = null;
      
    },
    DashboardFailed: (state, action: PayloadAction<any>) => {
      state.dashboard = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
    DashboardTasksSuccess: (state, action: PayloadAction<any>) => {
      state.tasks = action.payload;
      state.tasksError = null;
      
    },
    DashboardTasksFailed: (state, action: PayloadAction<any>) => {
      state.tasks = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.tasksError = errorMessage;
    },
    DashboardFeedbackSuccess: (state, action: PayloadAction<any>) => {
      state.feedbacks = action.payload;
      state.feedbackError = null;
      
    },
    DashboardFeedbackFailed: (state, action: PayloadAction<any>) => {
      state.feedbacks = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.feedbackError = errorMessage;
    },
    ResetDashboard: (state) => {
      state.dashboard = null;
      state.error = null;
      state.tasks = null;
      state.tasksError = null;
    },
  },
});

export const dashboard = slice.reducer;

export const {
  DashboardFailed,
  DashboardSuccess,
  DashboardTasksFailed,
  DashboardTasksSuccess,
  ResetDashboard,
  DashboardFeedbackFailed,
  DashboardFeedbackSuccess,
} = slice.actions;
