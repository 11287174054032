import React, { useEffect, useRef, useState } from 'react'
import SideNav from '../sideNav'
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../../../../modules/forgetPassword/changePassword';
import { notify } from '../../../../../modules/notification/notify';
import { RootState } from '../../../../../utils/types';
import { forgetPasswordResponseSelector } from "../../../../../modules/forgetPassword/selectors";
import { constant } from '../../../../../utils/constant';
import { ToastContainer } from 'react-toastify';
import GroupTab from '../../groupTab';
import BuyerHeader from '../../../BuyerHeader';
import BuyerFooter from '../../../buyerFooter';
import HeaderNavigationSeller from '../../../../seller/HeaderNavigationSeller';
import Header from '../../../../../components/header/Header';
import { reset } from '../../../../../modules/forgetPassword/reset';


const Security = () => {

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    CurrentPassword: "",
    password: "",
    confirmPassword: ""
  })

  let passwordResponse = useSelector<RootState, RootState["forgotpassword"]>(forgetPasswordResponseSelector);

  const handleOnChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  const PasswordScheme = Yup.object().shape({
    CurrentPassword: Yup.string()
      .required("*Required!"),
    password: Yup.string()
      .required('Please enter your password.')
      .min(8, 'Minimum limit is 8 Characters including One Uppercase, One Lowercase, One Number and One Special Case Character').max(15, 'Maximum limit is 15 Characters including One Uppercase, One Lowercase, One Number and One Special Case Character').matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain at least 8 Characters including One Uppercase, One Lowercase, One Number and One Special Case Character"
      ).test(
        "Is same as previous?",
        "New password cannot be same as your current password",
        (value) =>
          value === undefined ||
          value === null ||
          ( (formData?.CurrentPassword ?  formData?.CurrentPassword !== value  :true))
      ),
    confirmPassword: Yup.string()
      .required('Please retype your new password.')
      .oneOf([Yup.ref('password')], 'Your passwords do not match.')
  });

  useEffect(() => {

      if (passwordResponse.data != null) {
        if (passwordResponse.data.success === "Y") {
          notify(passwordResponse.data.message, constant.SUCCESS);
          setFormData({
            CurrentPassword: "",
            password: "",
            confirmPassword: ""
          })
        } 
        // else if (passwordResponse.data.success === "N") {
        //   notify(passwordResponse.data.message, constant.ERROR);
        // }
      }
      if (passwordResponse.error != null) {
        notify(passwordResponse.error, constant.ERROR);
    }
    dispatch<any>(reset())
}, [passwordResponse.data, passwordResponse.error]);

  return (
    <>
      {localStorage.getItem("role")==="Customer" && <BuyerHeader />}
     {localStorage.getItem("role")==="Seller" &&  <> <Header />  <section className="seller-breadcrumb-block">
        <div className="container">
          <ul>
            <li className="text">Account</li>
            <li className="pipe">|</li>
            <li className="text-link">
              <a href="#">{localStorage.getItem("UserName")}</a>
            </li>
          </ul>
        </div>
      </section>   <HeaderNavigationSeller
     currentPage="Account"
     /></>}

   
      <main className="min-section">
        <div className="container">
        {localStorage.getItem("role")==="Customer" && <>   <h1 className="sectionTitle mt-5">My eFindit</h1>

           <GroupTab currentPage="Account" />
</>}
          <section className="withFilter">
            <SideNav />
            <ToastContainer />
            <div className="right-wrapper">
              <section className="deals-group pt-0">
                <div className="deal-group-list four-col bg-white mt-0">
                  <div className="right-wrap-title-block">
                    <div className="row align-items-center">
                      <div className="col-md-auto">
                        <div className="right-wrap-title">Sign in and security</div>
                      </div>
                    </div>
                  </div>
                  <Formik
                    initialValues={formData}
                    validationSchema={PasswordScheme}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                      dispatch<any>(changePassword({
                        password: formData.password,
                        CurrentPassword: formData.CurrentPassword
                      }));
                    }}
                  >
                    {({ errors, touched }) => (
                      <>
                        <Form>
                          <div className="accountInfo signForm">
                            <div className="acountRow">
                              <div className="row">
                                <div className="col-lg-4">
                                  <div className="title">Password</div>
                                </div>
                                <div className="col-lg-8">
                                  <p>Create a password or modify your existing one.</p>
                                  <div className="row formRow mt-5">
                                    <div className="col-md-5">
                                      <label htmlFor="">Current password <span className="required">*</span></label>
                                      <input type="text" autoComplete="off" maxLength={20} value={formData.CurrentPassword} name="CurrentPassword" onChange={handleOnChange} className="formControl" />
                                      {errors.CurrentPassword && touched.CurrentPassword ? (
                                        <div className="required">
                                          {errors.CurrentPassword}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="row formRow">
                                    <div className="col-md-5">
                                      <label htmlFor="">New password <span className="required">*</span></label>
                                      <input type="password" autoComplete="off" maxLength={20} onChange={handleOnChange} value={formData.password} name="password" className="formControl" />
                                      {errors.password && touched.password ? (
                                        <div className="required">
                                          {errors.password}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="row formRow">
                                    <div className="col-md-5">
                                      <label htmlFor="">Confirm new password <span className="required">*</span></label>
                                      <input type="text" autoComplete="off" maxLength={20} onChange={handleOnChange} name="confirmPassword" value={formData.confirmPassword} className="formControl" />
                                      {errors.confirmPassword && touched.confirmPassword ? (
                                        <div className="required">
                                          {errors.confirmPassword}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="pt-3">
                                    <button className="save" type="submit">Save</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className="acountRow">
                              <div className="row">
                                <div className="col-lg-4">
                                  <div className="title">Social sign in</div>
                                </div>
                                <div className="col-lg-5">
                                  <p>Link your social account for faster sign in and checkout.</p>
                                </div>
                                <div className="col-lg-3 text-lg-right">
                                  <button className="btn edit">Edit</button>
                                </div>
                              </div>
                            </div>
                            <div className="acountRow">
                              <div className="row">
                                <div className="col-lg-4">
                                  <div className="title">Social sign in</div>
                                </div>
                                <div className="col-lg-8">
                                  <p className="mb-5">Link your social account for faster sign in and checkout.</p>
                                  <div className="acountRow pb-3 mb-4">
                                    <div className="row">
                                      <div className="col-lg-4">
                                        <p className="linked">Google</p>
                                      </div>
                                      <div className="col-lg-4">
                                        <p className="linked">Linked</p>
                                      </div>
                                      <div className="col-lg-4 text-lg-right">
                                        <button className="btn edit">Edit</button>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="acountRow pb-3 mb-4">
                                    <div className="row">
                                      <div className="col-lg-4">
                                        <p>Google</p>
                                      </div>
                                      <div className="col-lg-4">
                                        <p>Linked</p>
                                      </div>
                                      <div className="col-lg-4 text-lg-right">
                                        <button className="btn edit">Edit</button>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="acountRow">
                                    <div className="row">
                                      <div className="col-lg-4">
                                        <p>Google</p>
                                      </div>
                                      <div className="col-lg-4">
                                        <p>Linked</p>
                                      </div>
                                      <div className="col-lg-4 text-lg-right">
                                        <button className="btn edit">Edit</button>
                                      </div>
                                    </div>
                                  </div>                     
                                </div>
                              </div>
                            </div>
                            <div className="acountRow">
                              <div className="row">
                                <div className="col-lg-4">
                                  <div className="title">Sign in preferences</div>
                                </div>
                                <div className="col-lg-5">
                                  <p className="mb-5 pTitle">Manage your general sign in preferences.</p>
                                  <div className="custom-check">
                                    Keep me signed in on this device*
                                    <div className="wrap">
                                      <input type="checkbox" id="cb10"/>
                                      <label htmlFor="cb10"></label>
                                    </div>
                                  </div>
                                  <div className="custom-check">
                                    Sign me in automatically to Community discussion areas*
                                    <div className="wrap">
                                      <input type="checkbox" id="cb10"/>
                                      <label htmlFor="cb10"></label>
                                    </div>
                                  </div>
                                  <small>*Depending on your country: include chat rooms, discussion boards, sFindit Groups and Answer Center. 
                                    You'll need to sign in again if you close and reopen your browser to access the forums.</small>

                                    <div>
                                      <button className="save">save</button>
                                      <button className="cancel">cancel</button>
                                    </div>
                                </div>
                                <div className="col-lg-3 text-lg-right">
                                  <button className="btn edit">Edit</button>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </Form>
                      </>
                    )}
                  </Formik>
                </div>
              </section>

            </div>
          </section>
        </div>
      </main>
     <BuyerFooter/>
    </>
  )
}

export default Security
