import React, { useEffect, useState } from 'react'
// import "./printOrder.scss"
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllOrdersReport } from '../../../modules/orders/getAllOrdersReport'
import { SellerOrdersResponseSelector } from '../../../modules/orders/selectors'
import { RootState } from '../../../utils/types'
import { dateToLocal } from '../../../modules/date/date'
import { resetOrder } from '../../../modules/orders/resetOrder'
import { DefaultLayout } from '../../../layout/DefaultLayout'
const PrintOrdersCancelled = () => {
    const {state}= useLocation()
  const navigate = useNavigate();

    const dispatch = useDispatch();
    let order = useSelector<RootState, RootState["sellerOrders"]>(
        SellerOrdersResponseSelector
      );

      const [reportData, setReportData] = useState([] as any);
      const [type, setType] = useState("");


useEffect(()=>{
    dispatch<any>(
        getAllOrdersReport({
          currentPage: 1,
          rows:state?.rows ,
          userId: state?.userId,
          orderId: state?.orderId,
          StartDate: state?.StartDate,
          EndDate: state?.EndDate,
          type:state?.type
        }))
        setType(state?.type)
},[state])

useEffect(() => {
  if (order?.sellerOrdersReport) {
    let userData: any = [];
   
    order?.sellerOrdersReport?.data?.orders.map((order:any) => {
        let datalist: any = {};
     
        datalist["orderId"] = order?.orderId;
        datalist["buyerName"] = order?.buyerName;
        datalist["productName"] = order?.productName;
        datalist["condition"] = order?.condition;
        datalist["refund"] = order?.refund;
        datalist["cancelledDescription"] = order?.cancelledDescription;
        datalist["reason"] = order?.reason;
       
        datalist["requestedDate"] = order.requestedDate !== null
        ? dateToLocal(order.requestedDate)
        : "";
       
        datalist["status"] = order?.status;
      

       
        userData.push(datalist);
      });
    setReportData(userData);
    dispatch<any>(resetOrder("reportAllOrder"))

  } 
}, [order?.sellerOrdersReport]);

  useEffect(()=>{
   if(reportData.length!==0 )
   window.print()
},[reportData])
  return (
    <DefaultLayout>
    <div>
      <button className='btn btn-primary m-3 p-2' onClick={()=>     dispatch<any>(resetOrder("reportAllOrder")).then(()=> navigate("/seller/orderCancellation",
  ))} >Go Back</button>
    <table className="result-table">
    <thead>
      <tr>
   


    { <>  <th className="order-width">Order Details</th>
        <th>Buyer name</th>
        <th>Refund</th>
        <th className="date-width">Requested Date</th>
        <th>Reason</th>
        <th>Cancelled Description</th>
        <th>Status</th>
        <th>Note</th>
        
        </>
}
        {/* <th className="order-width">Order Details</th>
        <th>Status</th>
        <th>Qty</th>
        <th>Sold for</th>
        <th>Total</th>
        <th>Tracking no</th>
        <th className="date-width">Sale date</th>
        <th>Buyer paid date</th>
        <th>Zip code</th>
        <th>Engage Buyer</th>
        <th>Tracking no</th>
        <th className="date-width">Sale date</th>
        <th>Buyer paid date</th>
        <th>Zip code</th>
        <th>Engage Buyer</th> */}
      </tr>
    </thead>
    <tbody>
    {reportData?.map((order:any)=>
      <tr>
        
        <td className="order-details">
          <div>
           
            <div className="d-flex product-information">
            
              <div className="product-detail">
                <h6>{order.productName} </h6>
                <p>Condition:</p>
                <p>{order.condition}</p>
                <p>Order ID:</p>
                <p>{order.orderId}</p>
              </div>
            </div>
          </div>
        </td>
        <td>{order.buyerName}</td>
     
        <td className="sold-for">${order.refund}</td>
        
        <td className="date-sold">{order.requestedDate}</td>
        <td className="date-buyer-paid">{order.reason}</td>
        <td className="zip-code">{order.cancelledDescription}</td>
        
        <td className="total">{order.status}</td>
        <td className="date-buyer-paid">{order.sellerNote}</td>
       
      </tr>)}
      
     
    </tbody>
  </table>
  </div>
  </DefaultLayout>
  )
}

export default PrintOrdersCancelled