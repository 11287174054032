import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { stat } from "fs";
import { Paypal, Register } from "../../utils/types";

type PaypalState = {
  paypalAccountLink: Paypal["paypalAccountLink"] | null;
  paypalLink: Paypal["paypalLink"] | null;
  paypalLinkError: any | null;
  paypalAccountLinkError: any | null;
  delinkPaypal: Paypal["delinkPaypal"] | null;
  delinkPaypalError: any | null;
};

const initialState: PaypalState = {
  paypalAccountLink: null,
  paypalAccountLinkError: null,
  paypalLinkError: null,
  paypalLink: null,
  delinkPaypalError: null,
  delinkPaypal: null,
};


const slice = createSlice({
  name: "paypal",
  initialState,
  reducers: {
    PaypalAccountLinkSuccess: (state, action: PayloadAction<any>) => {
      state.paypalAccountLink = action.payload;
      state.paypalAccountLinkError = null;
      
    },
    PaypalAccountLinkFailed: (state, action: PayloadAction<any>) => {
      state.paypalAccountLink = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.paypalAccountLinkError = errorMessage;
    },
    PaypalLinkSuccess: (state, action: PayloadAction<any>) => {
      state.paypalLink = action.payload;
      state.paypalLinkError = null;
      
    },
    PaypalLinkFailed: (state, action: PayloadAction<any>) => {
      state.paypalLink = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.paypalLinkError = errorMessage;
    },
    DelinkPaypalSuccess: (state, action: PayloadAction<any>) => {
      state.delinkPaypal = action.payload;
      state.delinkPaypalError = null;
      
    },
    DelinkPaypalFailed: (state, action: PayloadAction<any>) => {
      state.delinkPaypal = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.delinkPaypalError = errorMessage;
    },
    ResetPaypal: (state) => {
      state.paypalAccountLink = null;
      state.paypalLink = null;
      state.paypalLinkError = null;
      state.paypalAccountLinkError = null;
      state.delinkPaypal=null;
      state.delinkPaypalError=null;
    },
  },
});

export const paypal = slice.reducer;

export const {
  PaypalAccountLinkFailed,
  PaypalAccountLinkSuccess,
  PaypalLinkFailed,
  PaypalLinkSuccess,
  ResetPaypal,
  DelinkPaypalFailed,
  DelinkPaypalSuccess
} = slice.actions;
