import { AppThunk } from "../../store";
import { PaypalAccountLinkSuccess, PaypalAccountLinkFailed } from "./slice";
import { linkPaypalAccount } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const setPaypalAccount =
  (Data: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
      const UserData: any = await linkPaypalAccount(Data);

      if (UserData.success === "N") {
        dispatch(PaypalAccountLinkFailed(UserData));
      } else {
        dispatch(PaypalAccountLinkSuccess(UserData));
      }
    } catch (err) {
      dispatch(PaypalAccountLinkFailed(err));
    }
    dispatch<any>(EndProgress());
  };
