import React from "react";
import { Link, useNavigate } from "react-router-dom";

interface Props {
  setFilter: any;
  filter: any;
  common: any;
  categories: any;
  handleFilterChange: any;
  setSearch: any;
  search: string;
  resetFilter: any;
  type?:string;
  addDealButton?:boolean;
}
const ProductFilter = ({
  setFilter,
  filter,
  common,
  setSearch,
  search,
  categories,
  handleFilterChange,
  resetFilter,
  type,
  addDealButton
}: Props) => {

  const navigate = useNavigate();
  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      // Handle the Enter key press here
      handleFilterChange({
        target: {
          name: "Search",
        },
      })
      // You can call a function or perform any action
    }
  };
  return (
    <div  className="all-orders-filters all-products-filter d-flex justify-content-between">
    <div className="listing-date-filter d-flex">
      {type!=="Offers" && <div className="date-filter-product-group d-flex">
        <div className="d-flex">
          <div className="form-group">
            <label>Start date</label>
            <input
              type="date"
              className="form-control input-seller"
              // disabled={scheduleIsDisabled}
              name="scheduleDate"
              max={filter.EndDate}
              // min={minDate}
              value={filter.StartDate}
              onChange={(e) =>
                setFilter({ ...filter, StartDate: e.target.value })
              }
            />
          </div>
          <div className="form-group ml-3">
            <label>End date</label>
            <input
              type="date"
              className="form-control input-seller"
              // disabled={scheduleIsDisabled}
              name="scheduleDate"
              min={filter.StartDate}
              // min={minDate}
              value={filter.EndDate}
              onChange={(e) =>
                setFilter({ ...filter, EndDate: e.target.value })
              }
            />
          </div>
        </div>
        <div className="search-flex-format d-flex">
          <div className="form-group ml-3">
            <label>Search by format</label>
            <select
              className="form-control input-seller"
              name="Format"
              value={filter.Format}
              onChange={(e) => handleFilterChange(e)}
            >
              <option value="">Select</option>
              {common?.common?.format.map((item: any, Index: number) => (
                <option value={item.value} key={item.key}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>}

      <div className="search-filter-product-group d-flex">
        <div className="form-group ml-3 search-cat-form-group">
          <label>Search by category</label>
          <select
            className="form-control input-seller"
            name="CategoryId"
            value={filter.CategoryId}
            onChange={(e) => handleFilterChange(e)}
          >
            <option value="">Select</option>
            {categories?.sellerCategories?.data !== null &&
              categories?.sellerCategories?.data.map(
                (item: any, Index: number) => (
                  <option value={item.categoryId} key={Index}>
                    {item.categoryName}
                  </option>
                )
              )}
          </select>
        </div>

        {!addDealButton&& <div className="form-group ">
          <label className="pl-10" style={{ paddingLeft: 10 }}>
            Search by title
          </label>
          <div className="d-flex ml-3">
            <div className="d-flex search-bar">
              <input
                type="text"
                placeholder="Search"
                className="form-control"
                value={search}
                onKeyDown={handleKeyPress}
                onChange={(e) => setSearch(e.target.value)}
              />
              <button
                className="search-icon"
                name="Search"
                onClick={(e) =>
                  handleFilterChange({
                    target: {
                      name: "Search",
                    },
                  })
                }
              >
                <span></span>
              </button>
            </div>

            <button className="reload-icon" onClick={() => resetFilter()}>
              <span></span>
            </button>
          </div>
        </div>}
      </div>
    </div>
   {addDealButton && <div className="">
    <button
                                type="button"
                                className="btn-lg btn-primary mt-2"
                                // style={{backgroundColor:"#D3D3D3"}}
                                onClick={() =>
                              
                                navigate("/seller/addProductInDeal")
                                  
                                }
                              >
                             +  Add new product
                              </button>
    </div>}
    </div>
  );
};

export default ProductFilter;
