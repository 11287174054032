import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { orderCancelled } from "../../../modules/orders/orderCancelled";

const OrderCancelModel = ({
  cancelOrder,
  setCancelOrder,
  commonResponse,
}: any) => {
  const dispatch = useDispatch();
const [error, setError]=useState("")
 
useEffect(()=>{
setError("")
},[cancelOrder?.visible])

const handleSubmit =()=>{
if( cancelOrder.description === null &&
  cancelOrder.reason === null)
{
  setError("*Required!")
} 
else{
  dispatch<any>(orderCancelled(cancelOrder));
  setCancelOrder({ ...cancelOrder, visible: "none" });
}
  }
  return (
    <div
      className="modal modal-order-popup"
      id="myModal"
      tabIndex={-1}
      role="dialog"
      style={{ display: cancelOrder.visible }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="order-modal">
            <div className="order-modal-head border-none">Cancel order</div>

            <div className="form-group spacer-feedback-select">
              <label className="label-modal">
                Select from predefined message
              </label>
              <select
                className="form-control input-modal"
                value={cancelOrder.reason !== null ? cancelOrder.reason : ""}
                onChange={(e) =>
                  {setCancelOrder({
                    ...cancelOrder,
                    reason: e.target.value !== "" ? e.target.value : null,
                  });
                  setError("");}
                }
              >
                <option value="">Select</option>
                { localStorage.getItem("authorization") !== null &&
                        localStorage.getItem("role") === "Customer" ? 
                        commonResponse?.common?.buyerCancellationReason?.map(
                          (item: any, Index: number) => (
                            <option key={item.key} value={item.value}>
                              {item.label}
                            </option>
                          )
                        )
                        
                        :      commonResponse?.common?.orderCancelledReason?.map(
                  (item: any, Index: number) => (
                    <option key={item.key} value={item.value}>
                      {item.label}
                    </option>
                  )
                )}
              </select>
            </div>

            <div className="form-group spacer-feedback-label">
              <label className="label-modal">Or Type anything</label>
              <textarea
                className="form-control buyer-textarea input-modal"
                rows={3}
                maxLength={500}
                placeholder="write some thing"
                value={
                  cancelOrder.description !== null
                    ? cancelOrder.description
                    : ""
                }
                onChange={(e) =>
                 { setCancelOrder({
                    ...cancelOrder,
                    description: e.target.value !== "" ? e.target.value : null,
                  })
                  setError("");}
                }
              ></textarea>
              <p>
                {cancelOrder.description === null
                  ? 0
                  : cancelOrder?.description?.length}
                /500
              </p>
            </div>
            <p style={{color:"red", fontSize:12}}>
                {error && error}
               
              </p>
            <div className="modal-btn-group spacer-feedback-btn">
              <button
                className="modal-add-btn modal-cancel-order-btn"
                onClick={() => {
                 handleSubmit()
                }}
                // disabled={
                //   cancelOrder.description === null &&
                //   cancelOrder.reason === null
                //     ? true
                //     : false
                // }
              >
                Cancel order
              </button>
              <button
                className="modal-cancel-btn"
                onClick={() =>
                  setCancelOrder({
                    ...cancelOrder,
                    visible: "none",
                    reason: null,
                    description: null,
                  })
                }
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCancelModel;
