import React from "react";
import LoadingBar from "react-top-loading-bar";
import { ProgressResponseSelector } from "../modules/progressBar/selector";
import { RootState } from "../utils/types";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ResetProgress } from "../modules/progressBar/resetProgress";
import SessionLogout from "../session/SessionLogout";
import Header from "../components/header/Header";

export const DefaultLayout = ({ children }: any) => {
  const dispatch = useDispatch();
  const progressResponse = useSelector<RootState, RootState["progress"]>(
    ProgressResponseSelector
  );

  const onLoaderFinished = () => {
    dispatch<any>(ResetProgress());
  };

  return (
    <div>
      <LoadingBar
        color="#F08000"
        progress={progressResponse.progress}
        onLoaderFinished={onLoaderFinished}
      />
      <SessionLogout />

      <div className="body">{children}</div>
    </div>
  );
};
