import { AppThunk } from "../../../store";
import { CartFailed, CartSuccess } from "./slice";
import { fetchCart, postToCart } from "./api";
import { EndProgress } from "../../progressBar/endProgress";
import { StartProgress } from "../../progressBar/startProgress";

export const getCart = (): AppThunk => async (dispatch) => {
  try {
    dispatch<any>(StartProgress());

    const data: any = await fetchCart();
    

    if (data.success === "N") {
      
      dispatch(CartFailed(data));
    } else {
      
      dispatch(CartSuccess(data));
    }
  } catch (err) {
    
    dispatch(CartFailed(err));
  }
  dispatch<any>(EndProgress());
};
