import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VerifyOtp } from "../../utils/types";

type initialDataState = {
  data: VerifyOtp | null;
  error: any | null;
};

const initialState: initialDataState = {
  data: null,
  error: null,
};
const slice = createSlice({
  name: "verifyotp",
  initialState,
  reducers: {
    reset: (state) => {
      state.data = null;
      state.error = null;
    },
    verifyOtpSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = null;
      
    },
    verifyOtpFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.data = null;
      var errorMessage = action.payload.errors.MobileNo
        ? action.payload.errors.MobileNo
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
  },
});

export const verifyotp = slice.reducer;

export const { verifyOtpFailed, verifyOtpSuccess, reset } = slice.actions;
