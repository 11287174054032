import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BuyerProduct } from "../../../utils/types";

type activityState = {
  buyerProduct: BuyerProduct["buyerProduct"] | null;

  error: any | null;
  // productSellerStore
  searchResult: BuyerProduct["searchResult"] | null;
  searchResultError: any | null;
  productSellerStore: BuyerProduct["productSellerStore"] | null;
  productSellerStoreError: any | null;
  productCategoryPage: BuyerProduct["productCategoryPage"] | null;
  productCategoryPageError: any | null;
  productDetails: BuyerProduct["productDetails"] | null;
  productDetailsError: any | null;
  saveSeller: BuyerProduct["saveSeller"] | null;
  saveSellerError: any | null;
  saveRecentlyViewed: BuyerProduct["saveRecentlyViewed"] | null;
  saveRecentlyViewedError: any | null;
  saveData: BuyerProduct["saveData"] | null;
  saveBid: BuyerProduct["saveData"] | null;
  saveDataError: any | null;
  
  saveBidError: any | null;
  saveOffer: BuyerProduct["saveData"] | null;
  saveOfferError: any | null;
  saveForLater: BuyerProduct["saveForLater"] | null;
  saveForLaterError: any | null;
  cart: BuyerProduct["cart"] | null;
  cartError: any | null;
  checkout: BuyerProduct["checkout"] | null;
  checkoutError: any | null;
  saveWatchlist: BuyerProduct["saveWatchlist"] | null;
  saveWatchlistError: any | null;
  saveSearch: BuyerProduct["saveSearch"] | null;
  saveSearchError: any | null;
};

const initialState: activityState = {
  buyerProduct: null,
  // 
  error: null,
  searchResult: null,
  searchResultError: null,
  productSellerStore: null,
  productSellerStoreError: null,
  productCategoryPage: null,

  productCategoryPageError: null,
  productDetails: null,

  productDetailsError: null,
  saveSeller: null,

  saveSellerError: null,
  saveRecentlyViewed: null,

  saveRecentlyViewedError: null,
  saveData: null,

  saveDataError: null,
  saveWatchlist: null,

  saveWatchlistError: null,
  saveSearch: null,

  saveSearchError: null,
  cart: null,
  cartError: null,
  checkout: null,
  checkoutError: null,
  saveBid: null,

  saveBidError: null,
   saveOffer: null,

  saveOfferError: null,
  saveForLater: null,

  saveForLaterError: null,
};
const slice = createSlice({
  name: "buyerProduct",
  initialState,
  reducers: {
    reset: (state) => {
      
      state.saveSellerError = null;
      state.saveBid=null;
      state.saveBidError=null;
      state.saveSeller = null;
      state.saveData = null;
      state.saveDataError = null;
      state.saveWatchlist=null;
      state.saveWatchlistError=null;
      state.saveOffer=null;
      state.saveOfferError=null;
    },
    resetDetails: (state) => {
      state.productDetails = null;
      state.productDetailsError = null;
      state.checkout=null;
      state.checkoutError=null;
    
    },
    SuggestionSuccess: (state, action: PayloadAction<any>) => {
      state.buyerProduct = action.payload;
      state.error = null;
      
    },
    SuggestionFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.buyerProduct = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.error = errorMessage;
    },
    SearchSuccess: (state, action: PayloadAction<any>) => {
      state.searchResult = action.payload;
      state.searchResultError = null;
      
    },
    SearchFailed: (state, action: PayloadAction<any>) => {
      state.searchResultError = action.payload;
      state.searchResult = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.searchResultError = errorMessage;
    },
    ProductStoreSuccess: (state, action: PayloadAction<any>) => {
      state.productSellerStore = action.payload;
      state.productSellerStoreError = null;
      
    },
    ProductStoreFailed: (state, action: PayloadAction<any>) => {
      state.productSellerStoreError = action.payload;
      state.productSellerStore = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.productSellerStoreError = errorMessage;
    },
    ProductCategorySuccess: (state, action: PayloadAction<any>) => {
      state.productCategoryPage = action.payload;
      state.productCategoryPageError = null;
      
    },
    ProductCategoryFailed: (state, action: PayloadAction<any>) => {
      state.productCategoryPageError = action.payload;
      state.productCategoryPage = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.productCategoryPageError = errorMessage;
    },
    ProductDetailSuccess: (state, action: PayloadAction<any>) => {
      state.productDetails = action.payload;
      state.productDetailsError = null;
      
    },
    ProductDetailFailed: (state, action: PayloadAction<any>) => {
      state.productDetailsError = action.payload;
      state.productDetails = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.productDetailsError = errorMessage;
    },
    SaveSellerSuccess: (state, action: PayloadAction<any>) => {
      state.saveSeller = action.payload;
      state.saveSellerError = null;
      
    },
    SaveSellerFailed: (state, action: PayloadAction<any>) => {
      state.saveSellerError = action.payload;
      state.saveSeller = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.saveSellerError = errorMessage;
    },
    SaveForLaterSuccess: (state, action: PayloadAction<any>) => {
      state.saveForLater = action.payload;
      state.saveForLaterError = null;
      
    },
    SaveForLaterFailed: (state, action: PayloadAction<any>) => {
      state.saveForLaterError = action.payload;
      state.saveForLater = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.saveForLaterError = errorMessage;
    },
    SaveRecentlyViewedSuccess: (state, action: PayloadAction<any>) => {
      state.saveRecentlyViewed = action.payload;
      state.saveRecentlyViewedError = null;
      
    },
    SaveRecentlyViewedFailed: (state, action: PayloadAction<any>) => {
      state.saveRecentlyViewedError = action.payload;
      state.saveRecentlyViewed = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.saveRecentlyViewedError = errorMessage;
    },
    SaveDataSuccess: (state, action: PayloadAction<any>) => {
      state.saveData = action.payload;
      state.saveDataError = null;
      
    },
    SaveDataFailed: (state, action: PayloadAction<any>) => {
      state.saveDataError = action.payload;
      state.saveData = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.saveDataError = errorMessage;
    },
    SaveWatchlistSuccess: (state, action: PayloadAction<any>) => {
      state.saveWatchlist = action.payload;
      state.saveWatchlistError = null;
      
    },
    SaveWatchlistFailed: (state, action: PayloadAction<any>) => {
      state.saveWatchlistError = action.payload;
      state.saveWatchlist = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.saveWatchlistError = errorMessage;
    },
    CartSuccess: (state, action: PayloadAction<any>) => {
      state.cart = action.payload;
      state.cartError = null;
      
    },
    CartFailed: (state, action: PayloadAction<any>) => {
      state.cartError = action.payload;
      state.cart = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.cartError = errorMessage;
    },
    CheckoutSuccess: (state, action: PayloadAction<any>) => {
      state.checkout = action.payload;
      state.checkoutError = null;
      
    },
    CheckoutFailed: (state, action: PayloadAction<any>) => {
      state.checkoutError = action.payload;
    //   if(action?.payload?.status==200)
    //  { state.checkout=action.payload;
    // }
  
       state.checkout =  null
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.checkoutError = errorMessage;
    },
    PlaceBidSuccess: (state, action: PayloadAction<any>) => {
      state.saveBid = action.payload;
      state.saveBidError = null;
      
    },
    PlaceBidFailed: (state, action: PayloadAction<any>) => {
      state.saveBidError = action.payload;
      state.saveBid = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.saveBidError = errorMessage;
    },
    PlaceOfferSuccess: (state, action: PayloadAction<any>) => {
      state.saveOffer = action.payload;
      state.saveOfferError = null;
      
    },
    PlaceOfferFailed: (state, action: PayloadAction<any>) => {
      state.saveOfferError = action.payload;
      state.saveOffer = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.saveOfferError = errorMessage;
    },
    SaveSearchSuccess: (state, action: PayloadAction<any>) => {
      state.saveSearch = action.payload;
      state.saveSearchError = null;
      
    },
    SaveSearchFailed: (state, action: PayloadAction<any>) => {
      state.saveSearchError = action.payload;
      state.saveSearch = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.saveSearchError = errorMessage;
    },
  },
});

export const buyerProduct = slice.reducer;

export const {
  SuggestionFailed,
  SuggestionSuccess,
  SearchFailed,
  SearchSuccess,
  ProductCategoryFailed,
  ProductCategorySuccess,
  ProductDetailFailed,
  ProductDetailSuccess,
  SaveSellerFailed,
  SaveSellerSuccess,
  reset,
  SaveRecentlyViewedFailed,
  SaveRecentlyViewedSuccess,
  SaveDataFailed,
  SaveDataSuccess,
  CartFailed,
  CartSuccess,
  PlaceBidSuccess,
  PlaceBidFailed,
  resetDetails,
  SaveWatchlistFailed,
  SaveWatchlistSuccess,
  SaveSearchFailed,
  SaveSearchSuccess,
  ProductStoreSuccess,
  ProductStoreFailed,
  PlaceOfferFailed,
  PlaceOfferSuccess,
  SaveForLaterFailed,
  SaveForLaterSuccess,
  CheckoutFailed,
  CheckoutSuccess
} = slice.actions;
