import axios from "axios";
import Api from "../../../../utils/api";
import { API_URLS } from "../../../../utils/api.urls";

const d = new Date();
let TimeZone = d.getTimezoneOffset() * -1;

export const fetchPurchaseHistory = async (params: any): Promise<any> => {
  const data = await Api.get(
    API_URLS.PURCHASE_HISTORY +
      "PageNumber=" +
      params.currentPage +

     " &Search="+params.filter.search+ "&Days="+ params.filter.lastDays+
      "&TotalRows=" +
      params.rows +
      "&status=" +
      params.status,
    []
  );
  return data;
};
export const fetchOrderDetailBuyer = async (params: any): Promise<any> => {
  const data = await Api.get(
    API_URLS.ORDER_DETAIL_BUYER +params,
      
    []
  );
  return data;
};

export const fetchReturnDetailBuyer = async (params: any): Promise<any> => {
  const data = await Api.get(
    API_URLS.RETURN_ORDERS + "/shipmentdetail/"+params,
      
    []
  );
  return data;
};

export const fetchReturnOrderTrackingDetails_Buyer = async (params: any): Promise<any> =>{
  const data = await Api.get(API_URLS.GET_TRACKING_DETAILS_URL+"/"+params,[]);
  return data;
}

export const fetchWatchlist = async (params: any): Promise<any> => {
  const data = await Api.get(
    API_URLS.BUYER.GET_WATCHLIST +
      "PageNumber=" +
      params.currentPage +
      "&TotalRows=" +
      params.rows

     +
    "&Days=" +
    params.status,
    []
  );
  return data;
};

export const fetchSavedSellers = async (params:any): Promise<any> => {
  const data = await Api.get(API_URLS.BUYER.GET_SAVED_SELLERS+"?filter="+params, []);
  return data;
};

export const fetchRecentlyViewed = async (params: any): Promise<any> => {
  const data = await Api.get(
    API_URLS.RECENTLY_VIEWED +
      "?PageNumber=" +
      params.currentPage +
      "&TotalRows=" +
      params.rows,
    []
  );
  return data;
};

export const fetchBuyAgain = async (params: any): Promise<any> => {
  const data = await Api.get(
    API_URLS.BUY_AGAIN +
      "PageNumber=" +
      params.currentPage +
      "&TotalRows=" +
      params.rows +
      "&Days=" +
      params.filter +
      "&TimeZone=" +
      TimeZone,
    []
  );
  return data;
};

export const clearRecentlyViewed = async (): Promise<any> => {
  const data = await Api.delete(API_URLS.RECENTLY_VIEWED, []);
  return data;
};
export const fetchBids = async (params: any): Promise<any> => {
  const data = await Api.get(
    API_URLS.BIDS +
      "PageNumber=" +
      params.currentPage +
      "&TotalRows=" +
      params.rows,
    []
  );
  return data;
};

export const fetchOffers = async (params: any): Promise<any> => {
  const data = await Api.get(
    API_URLS.OFFERS +
      "PageNumber=" +
      params.currentPage +
      "&TotalRows=" +
      params.rows,
    []
  );
  return data;
};

export const saveOffersNote = async (params: any | null): Promise<any> => {
  const data = await Api.post(API_URLS.BUYER.SAVE_OFFFER_NOTE, params);
  return data;
};
export const saveWatchlistNotes = async (params: any | null): Promise<any> => {
  const data = await Api.post(API_URLS.BUYER.SAVE_WATCHLIST_NOTE, params);
  return data;
};

export const postSavedSellersNote = async (
  params: any | null
): Promise<any> => {
  const data = await Api.post(
    API_URLS.BUYER.GET_SAVED_SELLERS + "/Note",
    params
  );
  return data;
};

export const removeSavedSeller = async (
  params: any | null
): Promise<any> => {
  const data = await Api.delete(API_URLS.BUYER.SAVED_SELLER+"/"+ params, []);
  return data;
};

export const deleteWatchlistItems = async (
  params: any | null
): Promise<any> => {
  const data = await Api.delete(API_URLS.BUYER.DELETE_WATCHLIST, params);
  return data;
};

export const deleteOffersItems = async (
  params: any | null
): Promise<any> => {
  const data = await Api.delete(API_URLS.OFFERS, params);
  return data;
};
export const postCounterBuyer = async (
  params: any | null
): Promise<any> => {
  const data = await Api.post(API_URLS.COUNTER_OFFER_BUYER, params);
  return data;
};
export const postAcceptRejectCounter = async (
  params: any | null
): Promise<any> => {
  const data = await Api.post(API_URLS.OFFER_ACTION_BUYER, params);
  return data;
};
export const saveBidsNote = async (params: any | null): Promise<any> => {
  const data = await Api.post(API_URLS.BUYER.SAVE_BID_NOTE, params);
  return data;
};

export const saveFeedback = async (params: any | null): Promise<any> => {
  const data = await Api.post(API_URLS.BUYER.SAVE_FEEDBACK, params);
  return data;
};

export const saveContactSeller = async (params: any | null): Promise<any> => {
  const data = await Api.post(API_URLS.BUYER.CONTACT_SELLER, params);
  return data;
};
export const createReturnLabel = async (params: any | null): Promise<any> => {
  const data = await Api.post(API_URLS.RETURN_ORDERS+"/label", params);
  return data;
};
export const createReturnShipment = async (params: any | null): Promise<any> => {
  const data = await Api.post(API_URLS.BUYER.RETURN_SHIPMENT_MANUAL_URL, params);
  return data;
};
export const fetchCourierCharges = async (params: any | null): Promise<any> => {
  const data = await Api.get(API_URLS.RETURN_ORDERS+`/returnshippingrates?ReturnId=${params.returnId}&CourierServiceId=${params.courierServiceId}&TimeZone=${TimeZone}`,[]);
  return data;
};
export const saveProductReview= async (params: any | null): Promise<any> => {
  const data = await Api.post(API_URLS.BUYER.SAVE_PRODUCT_REVIEW, params);
  return data;
};
export const saveVoteProductReview= async (params: any | null): Promise<any> => {
  const data = await Api.post(API_URLS.BUYER.SAVE_VOTE, params);
  return data;
};
export const fetchFeedback = async (params:number): Promise<any> => {
  const data = await Api.get(API_URLS.BUYER.SAVE_FEEDBACK +"/GetFeedback?orderId="+params, []);
  return data;
};
export const fetchSingleReview = async (params:number): Promise<any> => {
  const data = await Api.get(API_URLS.BUYER.GET_SINGLE_REVIEW +"/"+params, []);
  return data;
};
export const fetchProductReview = async (params:any): Promise<any> => {
  const data = await Api.get(API_URLS.BUYER.SAVE_PRODUCT_REVIEW +"/?ProductId="+params.productId + "&PageNumber=" +
  params.currentPage +
  "&TotalRows=" +
  params.rows, []);
  return data;
};