import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deactivateBuyer } from "../../../../../modules/buyer/myEfindit/account/deactivateBuyer";
import { deactivateSeller } from "../../../../../modules/buyer/myEfindit/account/deactivateSeller";
import { Form, Formik } from "formik";
import * as Yup from "yup";

// import { orderCancelled } from "../../../modules/orders/orderCancelled";

const AccountDeactivateModel = ({
  deactivateUserModal,
  setDeactivateUserModal,
  commonResponse,
}: any) => {
  const dispatch = useDispatch();
  interface deactivateUserValues {
    visible: string;
    reason: string | number ;
    description: string | null;
  }
  const [deactivateUser, setDeactivateUser] = useState<deactivateUserValues>({
    visible: "none",
    reason: "",
    description:null,
  });
  const handleSubmit=()=>{

    if(localStorage.getItem("authorization") !== null &&
    localStorage.getItem("role") === "Customer")
    {

      dispatch<any>(deactivateBuyer(deactivateUser));

    }
    else
    dispatch<any>(deactivateSeller(deactivateUser));

                  setDeactivateUser({ ...deactivateUser, visible: "none" });
  }


  // const checkDisabled =()=>{
  //   if(deactivateUser?.reason==="")
  //   {
  //     return true;
  //   }
  //   else if(deactivateUser?.reason==255 && deactivateUser?.description===null)
  //   {
  //     return true;
  //   } 
  //   else 
  //   return false;
  // }


  const ProfileSchema = Yup.object().shape({
    reason: Yup.string().required("*Required!!"),
    description: deactivateUser?.reason==255? Yup.string().required("*Required!!") :Yup.string().nullable(),
    
    
  });
  return (
    <div
      className="modal modal-order-popup"
      id="myModal"
      tabIndex={-1}
      role="dialog"
      style={{ display: deactivateUserModal.visible }}
    > <Formik
    initialValues={deactivateUser}
    validationSchema={ProfileSchema}
    enableReinitialize={true}
    onSubmit={(values) => {
      
      if(localStorage.getItem("authorization") !== null &&
      localStorage.getItem("role") === "Customer")
      {
  
        dispatch<any>(deactivateBuyer(deactivateUser));
  
      }
      else
      dispatch<any>(deactivateSeller(deactivateUser));
  
      setDeactivateUserModal({ ...deactivateUserModal, visible: "none" });
    
    }}
  >
    {({ errors, touched }) => (
         <Form>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="order-modal">
            <div className="order-modal-head border-none">Deactivate Account</div>

            <div className="form-group spacer-feedback-select">
              <label className="label-modal">
                Select reason of account deactivation <span className="red-star">*</span>
              </label>
              <select
                className="form-control input-modal"
                value={deactivateUser.reason}
                onChange={(e) =>
                  setDeactivateUser({
                    ...deactivateUser,
                    reason: e.target.value ,
                    description:e.target.value=='255' ? "": null
                  })
                }
              >
                <option value="">Select</option>
                { localStorage.getItem("authorization") !== null &&
                        localStorage.getItem("role") === "Customer" ? 
                        commonResponse?.common?.accountDeactivationReason?.map(
                          (item: any, Index: number) => (
                            <option key={item.key} value={item.value}>
                              {item.label}
                            </option>
                          )
                        )
                        
                        :      commonResponse?.common?.sellerAccountDeactivationReason?.map(
                  (item: any, Index: number) => (
                    <option key={item.key} value={item.value}>
                      {item.label}
                    </option>
                  )
                )}
              </select>
              {errors.reason && touched.reason ? (
                                <div className="invalid-feedback d-block">
                                  {errors.reason}
                                </div>
                              ) : null}
            </div>
          { deactivateUser.reason==255 && <div className="form-group spacer-feedback-label">
              <label className="label-modal">Type Reason  <span className="red-star">*</span> </label>
              <textarea
                className="form-control buyer-textarea input-modal"
                rows={3}
                maxLength={100}
                placeholder="Write something"
                value={
                  deactivateUser.description !== null
                    ? deactivateUser.description
                    : ""
                }
                onChange={(e) =>
                  setDeactivateUser({
                    ...deactivateUser,
                    description: e.target.value ,
                  })
                }
              ></textarea>
              <p>
                {deactivateUser.description === null
                  ? 0
                  : deactivateUser?.description?.length}
                /100
              </p>
              {errors.description && touched.description ? (
                          <div className="required">{errors.description}</div>
                        ) : null}
            </div>}
         <div><p style={{fontSize:15}}>Note: Deactivating your account will deactivate your {localStorage.getItem("authorization") !== null &&
                        localStorage.getItem("role") === "Customer" ? "seller" : "buyer"} account as well.</p></div>

            <div className="modal-btn-group spacer-feedback-btn">
              <button
                type="submit"
                className="modal-add-btn modal-cancel-order-btn"
                // onClick={
                //  handleSubmit
                // }
                // disabled={
                 
                //   checkDisabled()
                // }
              >
                Deactivate
              </button>
              <button
                className="modal-cancel-btn"
                onClick={() =>
                  setDeactivateUserModal({
                    ...deactivateUserModal,
                    visible: "none",
                    // reason: "",
                   
                  })
                }
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      </Form>
        )}
        </Formik>
    </div>
  );
};

export default AccountDeactivateModel;
