import { AppThunk } from "../../store";
import { DeleteRefreshTokenFailed, DeleteRefreshTokenSuccess } from "./slice";
import { deleteRefreshToken } from "./api";
import { EndProgress } from "../progressBar/endProgress";
import { StartProgress } from "../progressBar/startProgress";
// import { EndProgress } from "../progressBar/endProgress";
// import { StartProgress } from "../progressBar/startProgress";

export const deleteToken =
  (data: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
      const UserData: any = await deleteRefreshToken(data);

      if (UserData.success === "N") {
        dispatch(DeleteRefreshTokenFailed(UserData));
      } else {
        dispatch(DeleteRefreshTokenSuccess(UserData));
      }
    } catch (err) {
      dispatch(DeleteRefreshTokenFailed(err));
    }
    dispatch<any>(EndProgress());
  };
