import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Rating } from "react-simple-star-rating";
import { ToastContainer } from "react-toastify";
import { DefaultLayout } from "../../../../../layout/DefaultLayout";
import { deleteRecentlyViewed } from "../../../../../modules/buyer/myEfindit/activity/deleteRecentlyViewed";
import { getRecentlyViewed } from "../../../../../modules/buyer/myEfindit/activity/getRecentlyViewed";
import { resetDeleteRecentlyViewed } from "../../../../../modules/buyer/myEfindit/activity/resetDeleteRecentlyViewed";
import { activityResponseSelector } from "../../../../../modules/buyer/myEfindit/activity/selectors";
import { notify } from "../../../../../modules/notification/notify";
import { BASE_URLS } from "../../../../../utils/api.urls";
import { constant } from "../../../../../utils/constant";
import { RootState } from "../../../../../utils/types";
import BuyerHeader from "../../../BuyerHeader";
import GroupTab from "../../groupTab";
import ActivityNavPanel from "../ActivityNavPanel";
import "react-toastify/dist/ReactToastify.css";
import { getBuyAgain } from "../../../../../modules/buyer/myEfindit/activity/getBuyAgain";

import { addToCart } from "../../../../../modules/buyer/product/addToCart";
import { buyerProductSelector } from "../../../../../modules/buyer/product/selectors";
import { resetSave } from "../../../../../modules/buyer/product/resetSave";
import BuyerFooter from "../../../buyerFooter";
import { Link, useNavigate } from "react-router-dom";
import { resetDetail } from "../../../../../modules/buyer/product/resetDetail";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {getWebPImage, imageOnError} from "../../../../../utils/helpers";

const BuyAgain = () => {
  const dispatch = useDispatch();
  const isFirstRender2 = useRef(true);
  const isFirstRender = useRef(true);
  const navigate = useNavigate();

  let activityResponse = useSelector<RootState, RootState["activity"]>(
    activityResponseSelector
  );
  let productResponse = useSelector<RootState, RootState["buyerProduct"]>(
    buyerProductSelector
  );
  const [filter, setFilter] = useState(0);

  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(16);
  const [currentPage, setcurrentPage] = useState(0);
  const [subCategory, setSubCategory] = useState<any>();


  if (activityResponse.buyAgain !== null && pageCount === 0) {
    setPageCount(
      Math.ceil(activityResponse?.buyAgain?.data?.totalResult / itemCount)
    );
  }

  const handlePageChange = (selectedObject: any) => {
    setcurrentPage(selectedObject.selected);
  };
  const handleItemsChange = (event: any) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };

  //   useEffect(() => {
  //     if (activityResponse?.recentlyViewedDelete != null) {
  //       if (activityResponse?.recentlyViewedDelete.success == "Y") {
  //         notify(
  //           activityResponse?.recentlyViewedDelete.message,
  //           constant.SUCCESS
  //         );
  //         callRecentlyViewed();
  //       }
  //     }
  //     if (activityResponse?.recentlyViewedDeleteError !== null) {
  //       notify(activityResponse?.recentlyViewedDeleteError, constant.ERROR);
  //     }
  //     dispatch<any>(resetDeleteRecentlyViewed());
  //   }, [
  //     activityResponse?.recentlyViewedDelete,
  //     activityResponse?.recentlyViewedDeleteError,
  //   ]);

  const callBuyAgain = () => {
    dispatch<any>(
      getBuyAgain({
        currentPage: currentPage + 1,
        rows: itemCount,
        filter: filter,
      })
    ).then(() => {
      window.scrollTo(0, 0);
      if (currentPage === 0) setPageCount(0);
    });
  };
  useEffect(() => {
    if (!isFirstRender2.current) {
      callBuyAgain();
    }
    isFirstRender2.current = false;
  }, [currentPage, itemCount, filter]);
  useEffect(() => {
    if (isFirstRender.current) {
      callBuyAgain();
    }
    isFirstRender.current = false;
  }, []);
  const handleAddToCart = (product:any) => {
   
    
    if(product.quantity>0)
      dispatch<any>(
       addToCart({
        isCart: false,
        
        Cart:[{
         productId: product.productId         ,
         quantity: 1,
         VariantId:product.variantId,
       }]})
     )
     else
     notify("No Quantity Available", constant.ERROR ,"quant")
     
   }



   useEffect(() => {
    if (productResponse?.saveData != null) {
      if (productResponse?.saveData.success == "Y") {
        notify("Added to cart", constant.SUCCESS,"cartadded");
      } else if (productResponse?.saveData.success == "N") {
        notify(productResponse?.saveData.message, constant.ERROR,"cartadded");
      }
    }
    if (productResponse?.saveDataError !== null) {
      notify(productResponse?.saveDataError, constant.ERROR,"cartadded");
    }
    dispatch<any>(resetSave());
  }, [productResponse?.saveData, productResponse?.saveDataError]);

  const handleBuyItNow =(product:any)=>{


    
    if(product.quantity>0)
    {
  
      navigate(
        "/buyer/checkout",
        {
          state: {ProductId: product.productId,
          VariantId: product.variantId,
          quantity:1,
          type:"BuyItNow",
          CartIds: [
             
           
          ]},
        }
      )

    }
    else
    {
     notify("No quantity available", constant.ERROR,"quant")
      
  
        }
    }
  
  
  return (
    <DefaultLayout>
      <BuyerHeader subCategory={subCategory}  />
      <ToastContainer />

      <main className="min-section">
        <div className="container">
          <h1 className="sectionTitle mt-5">My eFindit</h1>
          <GroupTab currentPage="Activity" />
          <section className="withFilter">
            <ActivityNavPanel currentPage="BuyAgain" />

            <div className="right-wrapper">
              <section className="deals-group pt-0">
                <div className="deal-group-list four-col bgLight mt-0">
                  <div className="right-wrap-title-block">
                    <div className="row justify-content-between align-items-center">
                      <div className="col-md-auto">
                        <div className="right-wrap-title">Buy again</div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-end mb-5">
                    <div className="col-md-auto">
                      <div className="sortSelect d-inline-block mr-5">
                        <label htmlFor="">See orders from:</label>
                        <div className="selectBox">
                          <select
                            name=""
                            id=""
                            onChange={(e) =>
                              setFilter(parseInt(e.target.value))
                            }
                          >
                            <option value="0">Select</option>
                            <option value="30">Last 30 days</option>
                            <option value="60">Last 60 days</option>
                          </select>
                        </div>
                      </div>
                      {/* <div className="sortSelect d-inline-block">
                        <label htmlFor="">Filter by:</label>
                        <div className="selectBox">
                          <select name="" id="">
                            <option value="">All</option>
                          </select>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="wrap buy-again-block">
                    {activityResponse?.buyAgain?.data?.products?.map(
                      (item, Index) => (
                        <div className="recent-list-item">
                          <i className="timer-icon"></i>
                          <figure className="recent-media">
                          
                          <div className="buy-image-block" onClick={() =>
        dispatch<any>(
          resetDetail()).then(()=>navigate(`/buyer/productDetail/${item.productId}/${item.variantId}/${(new Date()).toISOString()}`))      
      }>
                              <picture>
                                <source srcSet={getWebPImage(item.defaultImage)} type="image/webp"/>
                                <LazyLoadImage
                                  className="prod-img"
                                  style={{ height: "240px" }}
                                  src={BASE_URLS.AWS_URL +
                                    "/Thumb" +
                                    item.defaultImage}
                                  onError={imageOnError}
                                  />
                              </picture>
                              </div>
                            
                          </figure>
                          <div className="recent-details">
                            <div>
                              <div
                                className="recent-title mb-2"
                              >
                                {item.title.substring(0, 40)}
                                {item.title.length > 40 && "..."}
                              </div>

                              <div className="fi-ship-status small mt-3">
                                {item.condition}
                                {
                                    JSON.parse(item.variant)?.map((product:any)=>(
                                     <div>{`${product.AttName} : ${product.Option}`}</div>
                                    ))
                                  }
                              </div>

                              <div className="recent-price mt-3">
                                ${item.buyNowPrice ?item.buyNowPrice :item.actualPrice}
                              </div>
                            </div>

                            <div>
                              <button  onClick={()=> 
                              
                       handleBuyItNow(item)    } className="buyAgainBtn d-block">
                                Buy again
                              </button>
                              <button onClick={()=>handleAddToCart(item)} className="addToCartBtn d-block">
                                Add to cart
                              </button>
                              <span style={{cursor:"pointer"}}  onClick={() => setSubCategory({id:item?.categoryId, name:item?.categoryName})} className="viewSimiBtn d-block">
                                View similar
                              </span>
                            </div>
                            
                          </div>
                        </div>
                      )
                    )}
                    {activityResponse?.buyAgain?.data == null && (
                      <div className="no-data-css">
                        <h3>No records found</h3>
                      </div>
                    )}
                  </div>

                  <nav className="pagination-block">
                    <p className="total">
                      {activityResponse?.buyAgain?.data?.totalResult} items
                      total
                    </p>

                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      forcePage={currentPage}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "pagination justify-content-center mt-4"
                      }
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                    <div className="customSelect">
                      <label htmlFor="">Items per page :</label>
                      <div className="selectBox">
                        <select name="" id="" onChange={handleItemsChange}>
                        <option value="16">16</option>
                            <option value="24">24</option>

                            <option value="32">32</option>
                        </select>
                      </div>
                    </div>
                  </nav>
                </div>
              </section>
            </div>
          </section>
        </div>
      </main>
      <BuyerFooter />
    </DefaultLayout>
  );
};

export default BuyAgain;
