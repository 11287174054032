import Api from "../../utils/api";
import { API_URLS } from "../../utils/api.urls";

export const forgetPassword = async (params: any | null): Promise<any> => {
  const data = await Api.post(API_URLS.FORGET_PASSWORD, params);
  return data;
};

export const fetchOtp = async (params: any | null): Promise<any> => {
  const data = await Api.post(API_URLS.GET_OTP, params);
  return data;
};


export const changePasswordApi = async (params: any | null): Promise<any> => {
  const data = await Api.post(API_URLS.CHANGE_PASSWORD, params);
  return data;
};
