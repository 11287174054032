import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/header/Header";
import { DefaultLayout } from "../../../layout/DefaultLayout";
import { ToastContainer } from "react-toastify";
import { BASE_URLS } from "../../../utils/api.urls";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../../components/footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../utils/types";
import { sellerStoreResponseSelector } from "../../../modules/sellerStore/selectors";
import { getFeedback } from "../../../modules/sellerStore/getFeedback";
import ReactPaginate from "react-paginate";
import { dateToLocal } from "../../../modules/date/date";
import { Rating } from "react-simple-star-rating";

export const StoreFeedback = (props: any) => {
  const [pageCount, setPageCount] = useState(0);

  const [itemCount, setItemCount] = useState(10);
  const [currentPage, setcurrentPage] = useState(0);
  const isFirstRender = useRef(true);
  const isFirstRender2 = useRef(true);

  let store = useSelector<RootState, RootState["sellerStore"]>(
    sellerStoreResponseSelector
  );
  const dispatch = useDispatch();
  if (store?.feedback?.data?.feedBacks?.totalResult !== 0 && pageCount === 0) {
    setPageCount(
      Math.ceil(store?.feedback?.data?.feedBacks?.totalResult / itemCount)
    );
  }
  const handlePageChange = (selectedObject: any) => {
    setcurrentPage(selectedObject.selected);
  };

  const handleItemsChange = (event: any) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };

  const callMystore = () => {
    dispatch<any>(
      getFeedback({
        currentPage: currentPage + 1,
        rows: itemCount,
      })
    ).then(() => {
      if (currentPage === 0) setPageCount(0);
    });
  };
  useEffect(() => {
    if (isFirstRender.current) {
      callMystore();
    }
    isFirstRender.current = false;
  }, []);

  useEffect(() => {
    if (!isFirstRender2.current) {
      callMystore();
    }
    isFirstRender2.current = false;
  }, [currentPage, itemCount]);

  const navigate = useNavigate();

  return (
    <DefaultLayout>
      <ToastContainer />
      <Header />
      <section className="seller-breadcrumb-links">
        <div className="container">
          <ul>
            <li>
              <Link to={"/seller/overview"}>eFindit</Link>
            </li>
            <li className="spacer">{">"}</li>
            <li>
              <Link to={"/seller/storeFeedback"}>eFindit stores</Link>
            </li>
            <li className="spacer">{">"}</li>
            <li className="active">
              {store?.feedback?.data?.myProfile?.storeName}
            </li>
          </ul>
        </div>
      </section>

      <section className="seller-store-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="seller-store-bg">
                <div className="profile-photo-block" style={{ height: 165 }}>
                  <div className="profile-photo-frame">
                    <img
                      src={
                        BASE_URLS.AWS_URL +
                        store?.feedback?.data?.myProfile?.profilePic
                      }
                      alt="profile pic"
                      className="img-thumbnail"
                    />
                  </div>
                  {/* <button className="profile-photo-btn">
                    Change Profile Pic{" "}
                    <input
                      type="file"
                      placeholder="Change Profile Pic"
                      className="custom-file-input profile-file-input"
                      name="mobile"
                      id="customFileMobile"
                      accept="image/png,image/jpeg,image/jpg"
                    />
                  </button> */}
                </div>
                <div className="profile-bio-info">
                  <div className="profile-store-title">
                    {store?.feedback?.data?.myProfile?.storeName}{" "}
                  </div>
                  <div className="profile-contact-panel d-flex">
                    <div className="d-flex">
                      <span className="star-img-icon"></span>
                      <span>
                        {store?.feedback?.data?.feedBackAndRating?.feedBack}%
                        Positive feedback
                      </span>
                      <span className="pipe-span">|</span>
                    </div>
                    <div className="d-flex">
                      <Rating
                        initialValue={
                          store?.feedback?.data?.feedBackAndRating?.rating
                        }
                        readonly={true}
                        allowFraction={true}
                        /* Available Props */
                        size={16}
                      />
                      <span className="pipe-span">|</span>
                      {/* <span className="saved-icon"></span> */}
                      {/* <span>Saved</span>
                      <span className="pipe-span">|</span> */}
                      {/* <span className="cont-icon"></span>
                      <span>Contact seller</span> */}
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={() => {
                          navigate("/seller/edit-profile");
                        }}
                      >
                        Edit profile
                      </button>
                    </div>
                  </div>
                  <div className="store-profile-text">
                    {store?.feedback?.data?.myProfile?.storeDescription}
                  </div>
                  
                  {/* <div className="feedback-label">Feedback ratings</div>
                  
                  <div className="feedback-rating-block d-flex">
                    <div className="rating-item-block">
                      <div className="rating-top-positive">
                        <span className="rating-icon"></span>
                        <span className="rating-text">
                          {
                            store?.feedback?.data?.feedBackAndRating
                              ?.positiveFeedback
                          }{" "}
                          Positive
                        </span>
                      </div>
                      <div className="rating-bottom-block d-flex">
                        <div className="rating-text-item">
                          <span>1 month</span>
                          <br />
                          <strong>
                            {
                              store?.feedback?.data?.feedBackAndRating
                                ?.positiveFeedback1Month
                            }{" "}
                          </strong>
                        </div>

                        <div className="rating-text-item">
                          <span>6 month</span>
                          <br />
                          <strong>
                            {
                              store?.feedback?.data?.feedBackAndRating
                                ?.positiveFeedback6Month
                            }{" "}
                          </strong>
                        </div>

                        <div className="rating-text-item">
                          <span>12 month</span>
                          <br />
                          <strong>
                            {
                              store?.feedback?.data?.feedBackAndRating
                                ?.positiveFeedback12Month
                            }{" "}
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div className="rating-item-block">
                      <div className="rating-top-negative">
                        <span className="rating-icon"></span>
                        <span className="rating-text">
                          {
                            store?.feedback?.data?.feedBackAndRating
                              ?.negativeFeedback
                          }{" "}
                          Negative
                        </span>
                      </div>
                      <div className="rating-bottom-block d-flex">
                        <div className="rating-text-item">
                          <span>1 month</span>
                          <br />
                          <strong>
                            {
                              store?.feedback?.data?.feedBackAndRating
                                ?.negativeFeedback1Month
                            }{" "}
                          </strong>
                        </div>

                        <div className="rating-text-item">
                          <span>6 month</span>
                          <br />
                          <strong>
                            {
                              store?.feedback?.data?.feedBackAndRating
                                ?.negativeFeedback6Month
                            }{" "}
                          </strong>
                        </div>

                        <div className="rating-text-item">
                          <span>12 month</span>
                          <br />
                          <strong>
                            {
                              store?.feedback?.data?.feedBackAndRating
                                ?.negativeFeedback12Month
                            }{" "}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div> */}

                </div>
              </div>
              <div className="feedback-listing-block">
                <div className="feedback-header-panel"></div>
                <div className="feedback-listing-panel">
                  <div className="feedback-listing-header d-flex">
                    <div className="feedback-view">
                      <b>{store?.feedback?.data?.feedBacks?.totalResult}</b>
                      &nbsp;Feedback received
                    </div>
                    {/* <div className="feedback-sort d-flex">
                      <div className="sort-label">Sort: </div>
                      <select>
                        <option>All</option>
                        <option>All</option>
                        <option>All</option>
                        <option>All</option>
                      </select>
                    </div> */}
                  </div>
                  {store?.feedback?.data?.feedBacks?.totalResult !== 0 ? (
                    store?.feedback?.data?.feedBacks?.feedBackReceived.map(
                      (item, Index) => (
                        <div className="feedback-block">
                          <div className="feedback-rating">
                            <div className="feedback-rating-block">
                              {/* <div
                                className={`${
                                  item.feedBack === "P"
                                    ? "feedback-p"
                                    : "feedback-n"
                                }`}
                              ></div> */}
                              <div className="feedback-rating-content">
                                <Rating
                                  initialValue={item.star}
                                  readonly={true}
                                  allowFraction={true}
                                  /* Available Props */
                                  size={16}
                                />
                                <div className="feedback-username text-break ">
                                  By {item.name.length>20? item.name.substring(0,20) +"...." : item.name}
                                </div>
                                <div className="feedback-date">
                                  {dateToLocal(item.feedBackDate)}
                                </div>
                                <div className="feedback-hand"></div>
                              </div>
                            </div>
                          </div>
                          <div className="feedback-content">
                            <h2 className="text-break">{item.heading}</h2>
                            <p className="text-break">{item.description}</p>
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <div>
                      <h2>No results to display</h2>
                    </div>
                  )}
                  <nav className="pagination-block ">
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      forcePage={currentPage}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "pagination justify-content-center mt-4"
                      }
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />

                    <div className="total-items-block">
                      <label>Items per page:</label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={handleItemsChange}
                        value={itemCount}
                      >
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                      </select>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </DefaultLayout>
  );
};
