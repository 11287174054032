import React from 'react'
import Messages from './messages'

const Allmessages = () => {
  return (
    <Messages nav="all"/>
  )
}

export default Allmessages
