import { Form, Formik } from "formik";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getUserLogin } from "../../modules/userLogin/getUserLogin";
import { UserLoginResponseSelector } from "../../modules/userLogin/selectors";
import { RootState } from "../../utils/types";
import * as Yup from "yup";
import {
  LoginSocialFacebook,
  LoginSocialApple,
  IResolveParams,
} from "reactjs-social-login";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { getUserLoginSocial } from "../../modules/userLogin/getUserLoginSocial";
import { setSocialDetails } from "../../modules/userLogin/setSocialDetails";
import { DefaultLayout } from "../../layout/DefaultLayout";
import { ResetUserLogin, ResetVerification } from "../../modules/userLogin/slice";
import { ToastContainer } from "react-toastify";
import ResendVerficationModel from "./ResendVerificationModel";
import "react-toastify/dist/ReactToastify.css";
import { notify } from "../../modules/notification/notify";


export const LoginSeller = (props: any) => {
  interface formValues {
    emailId: string;
    password: string;
    error: string;
    type:number;
    fCMToken: null;
  }

  let userResponse = useSelector<RootState, RootState["login"]>(
    UserLoginResponseSelector
  );

  const onLoginStart = useCallback(() => {
    // alert("login start");
  }, []);

  const [submitButtonText, setSubmitButtonText] = useState("Login");

  // useEffect(() => {
  //   if (userResponse.provider == "facebook" && userResponse.profile !== null)
  //     dispatch<any>(
  //       getUserLoginSocial({
  //         accesstoken: userResponse.profile.accessToken,
  //         socialType: 2,
  //         roleId: 3,
  //         roleType: "Seller",
  //       })
  //     );
  //   if (userResponse.provider == "google" && userResponse.profile !== null)
  //     dispatch<any>(
  //       getUserLoginSocial({
  //         accesstoken: userResponse.profile.tokenId,
  //         socialType: 1,
  //         roleId: 3,
  //         roleType: "Seller",
  //       })
  //     );
  // }, [userResponse.provider, JSON.stringify(userResponse.profile)]);
  useEffect(() => {
    const initClient = () => {
      gapi.client?.init({
        clientId:
          "423140527498-hcaae1gi0bfinumh7ovg7fhvln3ve9c6.apps.googleusercontent.com",
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  });
  const onSuccess = (res: any) => {
    dispatch<any>(
      setSocialDetails({
        provider: "google",
        profile: res,
        type: "login",
        time:new Date(),
      })).then(()=>res && dispatch<any>(
        getUserLoginSocial({
          accesstoken: res.tokenId,
          socialType: 1,
          roleId: 3,
          roleType: "Seller",
        })
      ));
    
  };
  const onFailure = (err: any) => {
    
  };
  const navigate = useNavigate();
  const isFirstRender = React.useRef(true);

  const dispatch = useDispatch();

  const [user, setUserValue] = useState<formValues>({
    emailId: "",
    password: "",
    error: "",
    type:3,
    fCMToken:null,
  });
  const [visible,setVisible]=useState(false);

  const setUserData = (e: any) => {
    setUserValue({
      ...user,
      [e.target.name]: e.target.value,
      error: "",
    });
  };

  const ProfileSchema = Yup.object().shape({
    emailId: Yup.string().email("Please enter a valid email.").min(2, "Too Short!").required("*Required!"),
    password: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("*Required!"),
  });

  useEffect(() => {
    if (!isFirstRender.current) {
      if (userResponse.login != null) {
        if (userResponse.login.success === "Y") {
          const accessToken = localStorage.getItem("authorization");
          //   localStorage.removeItem("error");
          if (
            userResponse.login.data.roleCode === "Seller" &&
            accessToken !== null
          ) {
            setUserValue({
              ...user,

              error: "",
            });
            if (
              userResponse.login.data.storeNameLinked !== null &&
              userResponse.login.data.storeAddressLinked !== null &&
              userResponse.login.data.payPalAccountLinked !== null
            ) {
              navigate("/seller/overview", { replace: true });
            }
            if (
              userResponse.login.data.storeNameLinked == null &&
              userResponse.login.data.storeAddressLinked == null &&
              userResponse.login.data.payPalAccountLinked == null
            ) {
              navigate("/seller/store-info", { replace: true });
            }
            if (
              userResponse.login.data.storeNameLinked !== null &&
              userResponse.login.data.storeAddressLinked == null &&
              userResponse.login.data.payPalAccountLinked == null
            ) {
              navigate("/seller/store-add", { replace: true });
            }
            if (
              userResponse.login.data.storeNameLinked !== null &&
              userResponse.login.data.storeAddressLinked !== null &&
              userResponse.login.data.payPalAccountLinked == null
            )
              navigate("/seller/payment-info", { replace: true });
          }
          
          // else {
          //   setUserValue({
          //     ...user,

          //     error: "Incorrect seller credentials",
          //   });
          // }
        }
        if(
          userResponse.login?.success==="N" && userResponse.login?.accountActivationToken
        )
        {
          navigate("/activation", {
            state: userResponse.login?.accountActivationToken,
          });
        }
        if(userResponse.login?.success==="N" && userResponse.login?.isVerified==false)
        {
          setVisible(true);
        }
      }
    }
    else{

      dispatch<any>(ResetUserLogin());
      isFirstRender.current = false;

    }
  }, [userResponse?.login,userResponse?.error]);
  const REDIRECT_URI = "https://efindit.net/";


  useEffect(() => {
    if(localStorage.getItem("role")==="Seller" &&  localStorage.getItem("authorization") )
    {
      if (
        localStorage.getItem("storeNameLinked") !== null &&
        localStorage.getItem("storeAddressLinked") !== null &&
        localStorage.getItem("payPalAccountLinked") !== null
      ) {
        navigate("/seller/overview", { replace: true });
      }
      if (
        localStorage.getItem("storeNameLinked") == null &&
        localStorage.getItem("storeAddressLinked")== null &&
        localStorage.getItem("payPalAccountLinked") == null
      ) {
        navigate("/seller/store-info", { replace: true });
      }
      if (
        localStorage.getItem("storeNameLinked") !== null &&
        localStorage.getItem("storeAddressLinked") == null &&
        localStorage.getItem("payPalAccountLinked") == null
      ) {
        navigate("/seller/store-add", { replace: true });
      }
      if (
        localStorage.getItem("storeNameLinked") !== null &&
        localStorage.getItem("storeAddressLinked") !== null &&
        localStorage.getItem("payPalAccountLinked") == null
      )
        navigate("/seller/payment-info", { replace: true });
    }
   
  }, []);
  useEffect(() => {
    if (userResponse.resendVerification != null) {
      if (userResponse.resendVerification.success == "Y") {
        notify(userResponse.resendVerification.message, "success");
      } else if (userResponse.resendVerification.success == "N") {
        notify(userResponse.resendVerification.message, "error");
      }
     
  
    }
    if (userResponse.resendVerificationError != null) {
      notify(userResponse?.resendVerificationError, "error");
   
    }
    dispatch<any>(ResetVerification());

  }, [userResponse?.resendVerification, userResponse?.resendVerificationError]);
  return (
    <>
      <DefaultLayout>
      <ToastContainer/>
  {visible &&  <ResendVerficationModel setVisible={setVisible} email={user?.emailId} />}
        <header className="header">
          <div className="logo-section">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="logo">
                    <Link to={"/home"}>
                      <i className="logo-img"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <section className="login-section min-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="login-container">
                  <Formik
                    initialValues={user}
                    validationSchema={ProfileSchema}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                      dispatch<any>(getUserLogin(user));
                    }}
                  >
                    {({ errors, touched }) => (
                      <>
                        <Form>
                          <h2 className="h2-head text-center">Seller Login </h2>

                          <div className="signin-text text-center">
                            Sign in to efindit or{" "}
                            <Link to={"/register"}>create an account</Link>
                          </div>

                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control login-input"
                              placeholder="Email"
                              value={user.emailId}
                              name="emailId"
                              onChange={setUserData}
                            />
                            {errors.emailId && touched.emailId ? (
                              <div className="invalid-feedback d-block">
                                {errors.emailId}
                              </div>
                            ) : null}
                          </div>

                          <div className="form-group">
                            <input
                              type="password"
                              className="form-control login-input"
                              placeholder="Password"
                              value={user.password}
                              name="password"
                              onChange={setUserData}
                            />
                            {errors.password && touched.password ? (
                              <div className="invalid-feedback d-block pb-0">
                                {errors.password}
                              </div>
                            ) : null}
                          </div>
                          {userResponse.error ? (
                            <div className="invalid-feedback d-block pb-0">
                              {userResponse.error}
                            </div>
                          ) : null}
                          {user.error !== "" ? (
                            <div className="invalid-feedback d-block pb-0">
                              {user.error}
                            </div>
                          ) : null}
                          <button
                            type="submit"
                            className="btn btn-primary login-btn form-button"
                          >
                            {submitButtonText}
                          </button>
                            <p style={{ textAlign:"left", fontSize:12}}><span style={{fontWeight:"bold"}}>Note:</span> Use same credentials as buyer.</p>
                          <div className="forgot-link">
                            <Link to={"/forgotPassword"}>Forgot Password</Link>
                          </div>
                        </Form>
                      </>
                    )}
                  </Formik>
                  <form className="form-block"></form>

                  <div className="line">
                    <div>
                      <span>or</span>
                    </div>
                  </div>

                  <div className="social-btn-block">
                    <LoginSocialFacebook
                      appId={
                       "1330044557617882"
                      }
                      fieldsProfile={
                        "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
                      }
                      onLoginStart={onLoginStart}
                      // onLogoutSuccess={onLogoutSuccess}
                      redirect_uri={REDIRECT_URI}
                      onResolve={({ provider, data }: IResolveParams) => {
                        dispatch<any>(
                          setSocialDetails({
                            provider: provider,
                            profile: data,
                            type: "login",
                          })
                        ).then(()=> data && dispatch<any>(
                          getUserLoginSocial({
                            accesstoken: data.accessToken,
                            socialType: 2,
                            roleId: 3,
                            roleType: "Seller",
                          })
                        ));
                      }}
                      onReject={(err) => {
                        console.log(err);
                      }}
                    >
                      <button className="btn btn-outline-primary f">
                        <i className="f-icon"></i>
                        <span>Continue with Facebook</span>
                      </button>
                    </LoginSocialFacebook>
                    <GoogleLogin
                      clientId="130661257099-p7t0sh7hbnu9a02gh6lel8s7a872idpd.apps.googleusercontent.com"
                      buttonText="Sign in with Google"
                      render={(renderProps) => (
                        <button
                          onClick={renderProps.onClick}
                          className="btn btn-outline-primary g"
                        >
                          <i className="g-icon"></i>
                          <span>Continue with Google</span>
                        </button>
                      )}
                      onSuccess={onSuccess}
                      onFailure={onFailure}
                      cookiePolicy={"single_host_origin"}
                      isSignedIn={false}
                    />

             
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer>
          <div className="footer-bottom">
            <p className="text-center">
              Copyright &copy;2023 efindit. All rights reserved
            </p>
          </div>
        </footer>
        { visible &&   <div className="modal-backdrop show"></div>}
      </DefaultLayout>
    </>
  );
};
