import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const SideNav = () => {

  const location = useLocation();
  const pathname = location.pathname;

  return (
    <div className="left-filter">

              <div className="left-lists-block">
                <div className="wrap">
                 
                  <div className="filters">
                    <div className="filterList border-0">
                      <button className="toggleBtn">
                        <img src="images/Rounded Rectangle 1 copy 2.png" alt="" />
                      </button>
                      <h4 className="mb-4">Personal info</h4>
                      <div className="filter-drop open">
                        <ul className="p-0">
                        <li className={pathname == "/buyer/myaccount" ? "active" :""}> <Link to={"/buyer/myaccount"}>• Personal information</Link> </li>
                        <li className={pathname == "/buyer/myaccount/security" ? "active" :""}><Link to={"/buyer/myaccount/security"}>• Sign in and security</Link> </li>
                        {localStorage.getItem("role")==="Customer" &&   <li className={pathname == "/buyer/myaccount/addresses" ? "active" :""}><Link to={"/buyer/myaccount/addresses"}>• Addresses</Link> </li>}
                          {/* <li className="active"><a href="">• Personal information</a></li>
                          <li><a href="">• Sign in and security</a></li>
                          <li><a href="">• Addresses</a></li> */}
                        </ul>
                      </div>
                    </div>

                    <div className="filterList border-0">
                      <button className="toggleBtn">
                        <img src="images/Rounded Rectangle 1 copy 2.png" alt="" />
                      </button>
                      {localStorage.getItem("role")==="Customer" &&  <>  <h4 className="mb-4">Payment Information</h4>
                      <div className="filter-drop open">
                        <ul className="p-0">
                        
                        <li className={pathname == "/buyer/myaccount/payments" ? "active" :""}><Link to={"/buyer/myaccount/payments"}>• Payments</Link> </li>

                          {/* <li><a href="">• PayPal Account</a></li> */}
                        </ul>
                      </div>
                      </>}
                    </div>

                  </div>
                </div>
              </div>
            </div>
  )
}

export default SideNav
