import { AppThunk } from "../../store";
import { DelinkPaypalSuccess, DelinkPaypalFailed } from "./slice";
import { fetchPaypalLink, removePaypal } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const delinkPaypal = (): AppThunk => async (dispatch) => {
  dispatch<any>(StartProgress());
  try {
    const UserData: any = await removePaypal();

    if (UserData.success === "N") {
      dispatch(DelinkPaypalFailed(UserData));
    } else {
      dispatch(DelinkPaypalSuccess(UserData));
    }
  } catch (err) {
    dispatch(DelinkPaypalFailed(err));
  }
  dispatch<any>(EndProgress());
};
