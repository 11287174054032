import moment from 'moment';
import React, { useEffect } from 'react'
import { useLocation } from 'react-router';
import { dateToLocal } from '../../../../modules/date/date';

const Print = () => {
    const location: any = useLocation();
    // const { message } = location.state;
    const state:any=JSON.parse(localStorage.getItem("print") || "null");
    useEffect(()=>{
        if(localStorage.getItem("print"))
       document.title = "";
        window.print()
    },[localStorage.getItem("print")])
  return (
    <div className="inboxMsgList">
    <div className="msgNav">
        {/* <a href="" className="active">All</a>
        <a href="">Unread</a>
        <a href="">Flagged</a> */}
    </div>
    <div className="actionBtns">
        {/* <a href="" className="msgActions">Reply to message</a> */}

        <h1> Subject: {state?.data?.subject}</h1>
    </div>
    <div className="msgBody">
        <div className="detailMsgs">
            <p className="msgDetail" style={{fontSize:24}}  dangerouslySetInnerHTML={{__html:state?.data.message}} ></p>
            <p className="small" style={{fontSize:20}} >From: {state?.data.from}</p>
            <p className="small" style={{fontSize:20}}>Sent: { moment( dateToLocal( state?.data.createdDate)).format("MMM-DD-YY hh:ss")}</p>
        </div>
        {/* <div className="ads">
            <img src="images/ads-img.png" alt="" />
        </div> */}
    </div>
</div>
  )
}

export default Print