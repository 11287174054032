import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getStateByCountryCode } from '../../../../../modules/common/getStateByCountryCode';
import { Common, ShippingAddresses } from '../../../../../utils/types';
import * as Yup from "yup";
import { updateShippingAddress } from '../../../../../modules/buyer/myEfindit/account/updateShippingAddress';
import { SaveDefaultAddressSuccess } from '../../../../../modules/buyer/myEfindit/account/slice';
import { saveAddress } from '../../../../../modules/sellerStore/saveAddress';


interface Props {
    shippingBtnTitle: string
    address: ShippingAddresses;
    common: Common;
    setNewShippingAddress: any
    isStore?:boolean;
}

const ShippingAddress = ({
    shippingBtnTitle,
    address,
    common,
    setNewShippingAddress,
    isStore
}: Props) => {

    const dispatch = useDispatch();
    const [newCallingCode, setNewCallingCode] = useState(address.CallingCode);

    const handleAddressChange = (e: any) => {
        setNewShippingAddress({
            ...address,
            [e.target.name]: e.target.value,
        });
    }

    const handleCountryChange = (e: any) => {
        setNewShippingAddress({
            ...address,
            [e.target.name]: e.target.value,
            StateId:0
        });

        let countryCode = e.target[e.target.selectedIndex].getAttribute("data-code");
        setNewCallingCode(e.target[e.target.selectedIndex].getAttribute("data-calling"));
        dispatch<any>(getStateByCountryCode(countryCode));
    };


    useEffect(()=>{
        let country=common?.countries?.value?.find((country)=> country.Id==address.CountryId);
        // let countryCode = e.target[e.target.selectedIndex].getAttribute("data-code");
        // setNewCallingCode(e.target[e.target.selectedIndex].getAttribute("data-calling"));
   country &&     dispatch<any>(getStateByCountryCode(country.CountryCode));
    },[address.CountryId])
    const ShippingSchema = Yup.object().shape({
        FirstName: Yup.string()
            .min(2, "Too Short, min 2 letters allowed!")
            .max(50, "Too Long, max 50 letters allowed!").matches(/^[A-Za-z]+$/, "Only alphabets allowed.")
            .required("*Required!"),
        LastName: Yup.string()
            .min(2, "Too Short, min 2 letters allowed!")
            .max(50, "Too Long, max 50 letters allowed!").matches(/^[A-Za-z]+$/, "Only alphabets allowed.")
            .required("*Required!"),
        MiddleName: Yup.string().max(50, "Too Long, max 50 letters allowed!").matches(/^[A-Za-z]+$/, "Only alphabets allowed."),
        City: Yup.string()
            .min(2, "Too Short, min 2 letters allowed!")
            .max(50, "Too Long, max 50 letters allowed!")
            .required("*Required!").nullable(),
        ZipCode: Yup.string()
            .required("*Required!").nullable(),
        MobileNo: Yup.string()
            .required("*Required!"),
        Address1: Yup.string()
            .min(5, "Too Short, min 5 letters allowed!")
            .max(100, "Too Long, max 100 letters allowed!")
            .required("*Required!").nullable(),
        Address2: Yup.string()
            .min(5, "Too Short, min 5 letters allowed!")
            .max(100, "Too Long, max 100 letters allowed!").nullable(),
        CountryId: Yup.string()
            .required("*Required!").nullable() .test(
                "Is positive?",
                "*Required!",
                (value) =>
                  value === undefined ||
                  value === null ||
                  (Number(value) !=0)
              ),
        StateId: Yup.string()
            .required("*Required!").nullable() .test(
                "Is positive?",
                "*Required!",
                (value) =>
                  value === undefined ||
                  value === null ||
                  (Number(value) !=0)
              ),


    });

    return (
        <Formik
            initialValues={address}
            validationSchema={ShippingSchema}
            enableReinitialize={true}
            onSubmit={(values) => {
                let postData = {
                    firstName: values.FirstName,
                    middleName: values.MiddleName,
                    lastName: values.LastName,
                    mobileNo: values.MobileNo,
                    callingCode: newCallingCode,
                    address1: values.Address1,
                    address2: values.Address2,
                    city: values.City,
                    stateId: values.StateId,
                    countryId: values.CountryId,
                    zipCode: values.ZipCode
                }

                if (shippingBtnTitle == 'Update')
                    Object.assign(postData, { Id: values.Id })

                if(isStore)
                dispatch<any>(saveAddress({
                  Id:values.Id,
                  firstName: values.FirstName,
                  middleName: values.MiddleName,
                  lastName: values.LastName,
                  mobileNo: values.MobileNo,
                  callingCode: newCallingCode,
                  address1: values.Address1,
                  address2: values.Address2,
                  city: values.City,
                  stateId: values.StateId,
                  countryId: values.CountryId,
                  zipCode: values.ZipCode
                }));
                else
                dispatch<any>(updateShippingAddress(postData));
            }}
        >
            {({ errors, touched }) => (
                <>
                    <Form>
                        <div className="signForm">
                            <div className="row formRow">
                                <div className="col-md-4">
                                    <input type="hidden" name="Id" value={address.Id} />
                                    <label htmlFor="">First name <span className="required">*</span></label>
                                    <input type="text" value={address.FirstName} onChange={handleAddressChange} name="FirstName" className="formControl" />
                                    {errors.FirstName && touched.FirstName ? (
                                        <div className="required">
                                            {errors.FirstName}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="">Middle name</label>
                                    <input type="text" value={address.MiddleName} onChange={handleAddressChange} name="MiddleName" className="formControl" />
                                    {errors.MiddleName && touched.MiddleName ? (
                                        <div className="required">
                                            {errors.MiddleName}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="">Last name <span className="required">*</span></label>
                                    <input type="text" value={address.LastName} onChange={handleAddressChange} name="LastName" className="formControl" />
                                    {errors.LastName && touched.LastName ? (
                                        <div className="required">
                                            {errors.LastName}
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="row formRow">
                                <div className="col-md-12">
                                    <label htmlFor="">Street address <span className="required">*</span></label>
                                    <input type="text" value={address.Address1} onChange={handleAddressChange} name="Address1" className="formControl" />
                                    {errors.Address1 && touched.Address1 ? (
                                        <div className="required">
                                            {errors.Address1}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row formRow">
                                <div className="col-md-12">
                                    <label htmlFor="">Street address 2 (Optional)</label>
                                    <input type="text" value={address.Address2} onChange={handleAddressChange} name="Address2" className="formControl" />
                                    {errors.Address2 && touched.Address2 ? (
                                        <div className="required">
                                            {errors.Address2}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row formRow">
                            <div className="col-md-6">
                                    <label htmlFor="">Country <span className="required">*</span></label>
                                    <div className="cutomSelect">
                                        <select name="CountryId" value={
                                            address.CountryId === null
                                                ? ""
                                                : address.CountryId
                                        }
                                            onChange={handleCountryChange}>
                                            <option value="">Choose country</option>
                                            {common.countries?.value.map(
                                                (item, Index) =>
                                                    item.Active === "Y" && (
                                                        <option
                                                            key={Index}
                                                            value={item.Id}
                                                            data-code={item.CountryCode}
                                                            data-calling={item.CallingCode}
                                                        >
                                                            {item.CountryName}
                                                        </option>
                                                    )
                                            )}
                                        </select>
                                        {errors.CountryId && touched.CountryId ? (
                                            <div className="required">
                                                {errors.CountryId}
                                            </div>
                                        ) : null}
                                       
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="">State <span className="required">*</span></label>
                                    <div className="cutomSelect">
                                        <select name="StateId"
                                            value={
                                                address.StateId === null
                                                    ? ""
                                                    : address.StateId
                                            }
                                            onChange={handleAddressChange}>
                                            <option value="">Choose state</option>
                                            {common.state?.value.map(
                                                (item, Index) =>
                                                    item.Active === "Y" && (
                                                        <option key={Index} value={item.Id}>
                                                            {item.StateName}
                                                        </option>
                                                    )
                                            )}
                                        </select>
                                        {errors.StateId && touched.StateId ? (
                                            <div className="required">
                                                {errors.StateId}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row formRow">
                              
                                <div className="col-md-6">
                                    <label htmlFor="">City <span className="required">*</span></label>
                                    <input type="text" value={address.City} onChange={handleAddressChange} name="City" className="formControl" />
                                    {errors.City && touched.City ? (
                                        <div className="required">
                                            {errors.City}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="">Zip code <span className="required">*</span></label>
                                    <input type="text"  value={address.ZipCode} onChange={handleAddressChange} name="ZipCode" className="formControl" />
                                    {errors.ZipCode && touched.ZipCode ? (
                                        <div className="required">
                                            {errors.ZipCode}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row formRow">
                                <div className="col-md-4">
                                    <label htmlFor="">Area code <span className="required">*</span></label>
                                    <input type="text" value={newCallingCode} disabled={true} name="CallingCode" className="formControl" />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="">Mobile no <span className="required">*</span></label>
                                    <input type="number" onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()} value={address.MobileNo} onChange={handleAddressChange} name="MobileNo" className="formControl" />
                                    {errors.MobileNo && touched.MobileNo ? (
                                        <div className="required">
                                            {errors.MobileNo}
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="pt-3">
                                <button className="save" type="submit">{shippingBtnTitle}</button>
                            </div>
                        </div>
                    </Form>
                </>
            )}
        </Formik>

    )
}

export default ShippingAddress
