import { notify } from "../modules/notification/notify";
import { constant } from "./constant";


const errorMsg = (response:any) => {
    Object.values(response).map((err:any)=>{
      err.map((errMsg:any)=> {
        notify(errMsg, constant.ERROR);
      })
    }) 
}

export default errorMsg
