import { AppThunk } from "../../../../store"
import {
    DeactivateUserSuccess,
    DeactivateUserFailed
} from "./slice"
import { deactivateSellerApi } from "./api";
import { EndProgress } from "../../../progressBar/endProgress";
import { StartProgress } from "../../../progressBar/startProgress";



export const deactivateSeller= (data: any): AppThunk => async (dispatch) => {

    dispatch<any>(StartProgress());
    try {

        const bannerData: any = await deactivateSellerApi(data)
        

        if (bannerData.success === "N") {
            
            dispatch(DeactivateUserFailed(bannerData));
        }
        else {
           
            dispatch(DeactivateUserSuccess(bannerData));
        }

    } catch (err) {
        
        
        dispatch(DeactivateUserFailed(err));
    }
    dispatch<any>(EndProgress());
}