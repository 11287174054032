import React, { useEffect, useRef, useState } from "react";
import { DefaultLayout } from "../../../layout/DefaultLayout";
import BuyerHeader from "../BuyerHeader";
import { RootState } from "../../../utils/types";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { BASE_URLS } from "../../../utils/api.urls";
import DefaultImage from "../../../assets/images/defaultImage.png";

import { Rating } from "react-simple-star-rating";
import { useDispatch, useSelector } from "react-redux";
import { buyerSetFeedback } from "../../../modules/buyer/myEfindit/activity/buyerSetFeedback";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { activityResponseSelector } from "../../../modules/buyer/myEfindit/activity/selectors";
import { notify } from "../../../modules/notification/notify";
import { constant } from "../../../utils/constant";
import { resetActivity } from "../../../modules/buyer/myEfindit/activity/resetActivity";
import { getProductReview } from "../../../modules/buyer/myEfindit/activity/getProductReview";
import { dateToLocal } from "../../../modules/date/date";
import BuyerFooter from "../buyerFooter";
import ReactPaginate from "react-paginate";
import { setVoteProductReview } from "../../../modules/buyer/myEfindit/activity/setVoteProductReview";
import { resetDetail } from "../../../modules/buyer/product/resetDetail";

const ProductReviews = () => {
  const { state }: any = useLocation();
  let activityResponse = useSelector<RootState, RootState["activity"]>(
    activityResponseSelector
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(16);
  const [currentPage, setcurrentPage] = useState(0);
  const isFirstRender2 = useRef(true);
  if (activityResponse?.productReview?.data?.ratingAndReviews !== null && pageCount === 0) {
    setPageCount(
      Math.ceil(activityResponse?.productReview?.data?.totalResult / itemCount)
    );
  }

  const handlePageChange = (selectedObject: any) => {
    setcurrentPage(selectedObject.selected);
  };
  const handleItemsChange = (event: any) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    if (state?.Id !== undefined) {
   
      callReviews()

    }
  }, [state]);
  const callReviews = () => {
    dispatch<any>(
      getProductReview({
        productId:state?.Id,
        currentPage: currentPage + 1,
        rows: itemCount,
      })
    ).then(() => {
      window.scrollTo(0, 0);

      if (currentPage === 0) setPageCount(0);
    });
  };

  useEffect(() => {
    if (!isFirstRender2.current) {
      callReviews();
    }
    isFirstRender2.current = false;
  }, [currentPage, itemCount]);
  // Catch Rating value
  useEffect(() => {
    if (activityResponse?.saveDataProduct?.success==="Y") {
   
      callReviews();
      dispatch<any>(resetActivity());
      
    }
  }, [activityResponse?.saveDataProduct]);

  const handleVote =(id:number, type:boolean)=>{
    
    dispatch<any>(setVoteProductReview({reviewId: id,
    liked: type
      }))

  }
  return (
    <DefaultLayout>
      <BuyerHeader />
      <ToastContainer />

      <section className="brecrumb">
        <div className="container">
          {/* <nav className="nav-2">
            <ul>
              <li>
                <Link to="/buyer/myaccount">Account</Link>
              </li>
              <li>Product reviews</li>
            </ul>
          </nav> */}
        </div>
      </section>
      <main className="min-section">
        <div className="container">
          <h1 className="sectionTitle mt-5">Product reviews</h1>
          <div className="feedbackBlock">
            <div className="headerWrap">
              <div className="row">
                <div className="col-md-auto">
                  <div className="img" >
                    <figure style={{cursor:"pointer"}} onClick={()=> dispatch<any>(
                              resetDetail()).then(()=>navigate(`/buyer/productDetail/${state?.Id}`))        }>
 <img
                      src={BASE_URLS.AWS_URL + "/Thumb" + state?.product?.data?.productDetail?.defaultImage}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = DefaultImage;
                      }}
                      alt=""
                    />
                    </figure>
                   
                  </div>
                </div>

                <div className="col-md-auto">
                  <div className="title">
                    <h2>{state?.product?.data?.productDetail?.title}</h2>
                   
                  </div>
                </div>

               

           

                {/* <div className="col-md-auto ml-auto">
                  <div className="sellerInfo">
                    <div className="label">Seller</div>
                    <div className="sellerName">{state.storeName}</div>
                    <a href="">View seller profile</a>
                  </div>
                </div> */}
              </div>
            </div>
            <section className="ratings">
              <div className="row justify-content-between align-items-center">
                <div className="col-auto">
                  <h3 className="mb-0">Ratings and reviews</h3>
                </div>
                <div className="col-auto">
                  {/* <a href="" className="writeReviewBtn">
                    Write a review{" "}
                    <img src="images/Shape 5 copy 2.png" alt="" />
                  </a> */}
                </div>
              </div>

              <div className="ratingBlock">
                <div className="row">
                  <div className="col-xl-3">
                    <div className="points">{activityResponse?.productReview?.data?.averageRating}</div>
                    <Rating
                        initialValue={
                          activityResponse?.productReview?.data?.averageRating
                        }
                        readonly={true}
                        // onClick={(rate) =>
                        //   handleRating(rate, "productRating")
                        // }
                        /* Available Props */
                        size={25}
                    />
                    <div className="num">{activityResponse?.productReview?.data?.totalProductRating} product ratings</div>
                  </div>
                  <div className="col-xl-9">
                    <div className="peopleRating">
                      <div className="rating-display-block">

                        <div className="rating-row">
                          <div className="rating-row-icon"></div>
                          <div className="rating-row-number">5</div>
                          <div className="rating-bg">
                            <span className="rating-percentage" style={{width: `${(activityResponse?.productReview?.data?.ratedStar?.star5/activityResponse?.productReview?.data?.totalProductRating)*100}%`}}></span>
                          </div>
                          <div className="rating-values">{activityResponse?.productReview?.data?.ratedStar?.star5 ? activityResponse?.productReview?.data?.ratedStar?.star5 : 0}</div>
                        </div>

                        <div className="rating-row">
                          <div className="rating-row-icon"></div>
                          <div className="rating-row-number">4</div>
                          <div className="rating-bg">
                            <span className="rating-percentage" style={{width: `${(activityResponse?.productReview?.data?.ratedStar?.star4/activityResponse?.productReview?.data?.totalProductRating)*100}%`}}></span>
                          </div>
                          <div className="rating-values">{activityResponse?.productReview?.data?.ratedStar?.star4 ? activityResponse?.productReview?.data?.ratedStar?.star4 : 0}</div>
                        </div>

                        <div className="rating-row">
                          <div className="rating-row-icon"></div>
                          <div className="rating-row-number">3</div>
                          <div className="rating-bg">
                            <span className="rating-percentage" style={{width: `${(activityResponse?.productReview?.data?.ratedStar?.star3/activityResponse?.productReview?.data?.totalProductRating)*100}%`}}></span>
                          </div>
                          <div className="rating-values">{activityResponse?.productReview?.data?.ratedStar?.star3 ? activityResponse?.productReview?.data?.ratedStar?.star3 : 0}</div>
                        </div>

                        <div className="rating-row">
                          <div className="rating-row-icon"></div>
                          <div className="rating-row-number">2</div>
                          <div className="rating-bg">
                            <span className="rating-percentage" style={{width: `${(activityResponse?.productReview?.data?.ratedStar?.star2/activityResponse?.productReview?.data?.totalProductRating)*100}%`}}></span>
                          </div>
                          <div className="rating-values">{activityResponse?.productReview?.data?.ratedStar?.star2 ? activityResponse?.productReview?.data?.ratedStar?.star2 : 0}</div>
                        </div>

                        <div className="rating-row">
                          <div className="rating-row-icon"></div>
                          <div className="rating-row-number">1</div>
                          <div className="rating-bg">
                            <span className="rating-percentage" style={{width: `${(activityResponse?.productReview?.data?.ratedStar?.star1/activityResponse?.productReview?.data?.totalProductRating)*100}%`}}></span>
                          </div>
                          <div className="rating-values">{activityResponse?.productReview?.data?.ratedStar?.star1 ? activityResponse?.productReview?.data?.ratedStar?.star1 : 0}</div>
                        </div>

                      </div>  
                      <img src="images/people-rating.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>

           {/* <div  className='d-flex justify-content-between'> 
           
            <h3>Most relevant reviews</h3> {activityResponse?.productReview?.data?.totalResult>0 && <h3 onClick={()=>   navigate(
                                          "/buyer/productReviews",
                                          {
                                            state: {product:product,
                                                      Id:Id},
                                          }
                                        )} style={{cursor:"pointer", color:"blue"}}>See all {activityResponse?.productReview?.data?.totalResult} reviews</h3>}
                                        
                                        </div>  */}
              <div className="reviews"> 
               {
               activityResponse?.productReview?.data?.ratingAndReviews?.map((review, Index)=>
               <div className="row mb-5">
               <div className="col-xl-3">
                 <div className="userRating">
                 <Rating
                        initialValue={
                          review?.star
                        }
                        readonly={true}
                        // onClick={(rate) =>
                        //   handleRating(rate, "productRating")
                        // }
                        /* Available Props */
                        size={15}
                    />
                 </div>
                 <div className="user">
                   by <span style={{color:"blue"}}>{review?.name}</span>
                 </div>
                 <div className="date">{review?.createdOn ? dateToLocal(review?.createdOn) : ""}</div>
               </div>
               <div className="col-xl-9">
                 <div className="title">
                   {review.heading}
                 </div>
                 <p>
                  {review.description}
                 </p>

                 <div className="d-flex">
                 <div className="like">
                     <button onClick={()=>handleVote(review?.reviewId, true)}></button>
                     <span>({review.liked})</span>
                   </div>
                   <div className="dislike">
                     <button onClick={()=>handleVote(review?.reviewId, false)}></button>
                     <span>({review?.unLiked})</span>
                   </div>
                 </div>
               </div>
             </div>
               
               )}
               
              

             
              </div>
              {activityResponse?.productReview?.data?.ratingAndReviews != null && (    
                  <nav className="pagination-block">
                    <p className="total">
                      {activityResponse?.productReview?.data?.totalResult}{" "}
                      items total
                    </p>

                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      forcePage={currentPage}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "pagination justify-content-center mt-4"
                      }
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                    <div className="customSelect">
                      <label htmlFor="">Items per page :</label>
                      <div className="selectBox">
                        <select name="" id="" onChange={handleItemsChange}>
                        <option value="16">16</option>
                            <option value="24">24</option>

                            <option value="32">32</option>
                        </select>
                      </div>
                    </div>
                  </nav>
                  )}
            </section>
         
          {/* //ss// */}
          </div>
        </div>
      </main>
   <BuyerFooter/>
    </DefaultLayout>
  );
};

export default ProductReviews;
