import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../../components/header/Header";
import { DefaultLayout } from "../../../layout/DefaultLayout";
import { dateToLocal } from "../../../modules/date/date";
import { getOrders } from "../../../modules/orders/getOrders";
import { SellerOrdersResponseSelector } from "../../../modules/orders/selectors";
import { RootState } from "../../../utils/types";
import ReactPaginate from "react-paginate";
import { BASE_URLS } from "../../../utils/api.urls";
import { getCommonData } from "../../../modules/common/getCommonData";
import { CommonResponseSelector } from "../../../modules/common/selectors";
import { getBuyerNames } from "../../../modules/orders/getBuyerNames";
import { setTrackingManually } from "../../../modules/orders/setTrackingManually";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notify } from "../../../modules/notification/notify";
import { constant } from "../../../utils/constant";
import { resetOrder } from "../../../modules/orders/resetOrder";
import { SellerProductResponseSelector } from "../../../modules/sellerProduct/selector";
import { getActiveListing } from "../../../modules/sellerProduct/getActiveListing";
import { reset } from "../../../modules/sellerProduct/reset";
import { resetCategorySuccess } from "../../../modules/categories/slice";
import ProdNavPanel from "../listing/ProdNavPanel";
import ProductFilter from "../listing/ProductFilter";
import { CategoryResponseSelector } from "../../../modules/categories/selector";
import { getSellerCategories } from "../../../modules/categories/getSellerCategories";
import { changeProductStatus } from "../../../modules/sellerProduct/changeProductStatus";
import SellerBreadCrumbs from "../../../components/sellerBreadCrumbs/SellerBreadCrumbs";
import Footer from "../../../components/footer/Footer";
import HeaderNavigationSeller from "../HeaderNavigationSeller";
import { getOffersSeller } from "../../../modules/sellerProduct/getOffersSeller";
import { getDealsNameSeller } from "../../../modules/sellerProduct/getDealsNameSeller";
import { Formik,Form } from "formik";
import * as Yup from "yup";

import { getDealsHeadings } from "../../../modules/buyer/deals/getDealsHeadings";
import { dealsResponseSelector } from "../../../modules/buyer/deals/selectors";
import { getProductsByCategory } from "../../../modules/sellerProduct/getProductsByCategory";
import { saveProductToDeal } from "../../../modules/sellerProduct/saveProductToDeal";
const AddNewProduct = () => {
  const { dealId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const product = useSelector<RootState, RootState["sellerProduct"]>(
    SellerProductResponseSelector
  );

  const categoryResponse = useSelector<RootState, RootState["categories"]>(
    CategoryResponseSelector
  );
 
  let dealsResponse = useSelector<RootState, RootState["deals"]>(
    dealsResponseSelector
  );

  const isFirstRender = useRef(true);
  const isFirstRender2 = useRef(true);
 interface dealvalues {
  dealId: number | string ,
  Active: string,
  StartOn: string,
  ExpiresOn: string,
  AplliesWithMinimumPurchase: null,
    AplliesLimitToUsePerCustomer: null,
    AppliesLimitOfUseOverall: null,
  SalesDiscount:number | null,
  IsShippingFree:boolean,
  categoryProduct: [
      {
          categoryId: string,
          productId: string
      }
  ]
  categoryId: string,
  productId: string
 }

  const [dealData, setDealData] = useState<dealvalues>({
    dealId: "",
    Active: "Y",
    StartOn: "",
    ExpiresOn: "",
    AplliesWithMinimumPurchase: null,
    AplliesLimitToUsePerCustomer: null,
    AppliesLimitOfUseOverall: null,
    SalesDiscount:null,
    IsShippingFree:false,
    categoryProduct: [
        {
            categoryId: "",
            productId: ""
        }
    ],
    categoryId: "",
    productId: ""

  })
  const [date, setDate] = useState({
StartOn:"",
ExpiresOn:"",
  });
  const [basePrice, setBasePrice] = useState("");
  const [catError, setCatError] = useState(0);

  // let today:any = new Date();
  // let dd = today.getDate();
  // let mm = today.getMonth() + 1;
  // let yyyy = today.getFullYear();
  // //
  // let hh = today.getHours();
  // let m = today.getMinutes();
  
  // if (dd < 10) {
  //    dd = Number('0' + dd);
  // }
  
  // if (mm < 10) {
  //    mm =  Number('0' + mm);
  // }
      
  // today = yyyy + "-" + mm + "-" + dd + "T" + hh + ":" + m;



  useEffect(()=>{
    dispatch<any>(
      getDealsHeadings())
      dispatch<any>(getSellerCategories());
  },[])

  useEffect(()=>{
 
      dispatch<any>(getProductsByCategory(dealData.categoryProduct?.[0]?.categoryId));
  },[dealData.categoryProduct?.[0]?.categoryId])
  useEffect(()=>{
 
    let data=product?.productsByCategory?.data?.find((item)=> item.id ===dealData.categoryProduct?.[0]?.productId)
   if(data?.actualPrice)
 {  setBasePrice(data.actualPrice.toString())}
 else
 setBasePrice("Its base price will depend on the variant");
},[dealData.categoryProduct?.[0]?.productId])

useEffect(() => {
  if (product.saveData != null) {
    if (product.saveData.success == "Y") {
      notify(product.saveData.message, constant.SUCCESS);
      
      navigate("/seller/deals")
    } 
    else if (product.saveData.success == "N") {
      notify(product.saveData.message, constant.ERROR);
    }
    dispatch<any>(reset("saveData"));
  }
}, [product.saveData]);


  const DealSchema = Yup.object().shape({
    dealId: Yup.string().required("*Required!"),
    StartOn: Yup.string().required("*Required!"),
    ExpiresOn: Yup.string().required("*Required!"),
    categoryId: Yup.string().required("*Required!"),
    productId: Yup.string().required("*Required!"),

    SalesDiscount: Yup.number()
    .nullable()
    .required("*Required!")
    .test(
      "Is positive?",
      "The value must be greater than 0 and less than 100",
      (value) =>
        value === undefined || value === null || (value > 0 && value < 100)
    ),
    
  });
  const onChange = (e: any) => {
   
if(e.target.name=="StartOn" || e.target.name=="ExpiresOn")
{
  let newDate=new Date(e.target.value);
  let isoDate=new Date(newDate.toUTCString()).toISOString();
  setDealData({
    ...dealData,
    [e.target.name]: isoDate
  });
setDate({
  ...date,
  [e.target.name]: e.target.value,
})
}
else
  {  setDealData({
      ...dealData,
      [e.target.name]: e.target.value,
    });}
  };

  return (
    <DefaultLayout>
      <Header />
      <ToastContainer />

    
      <section className="seller-breadcrumb-block">
        <div className="container">
          <ul>
            <li className="text">Deals </li>
            <li className="pipe">|</li>
            <li className="text-link">
              <a href="#">{localStorage.getItem("UserName")}</a>
            </li>
          </ul>
        </div>
      </section>

     <HeaderNavigationSeller
     currentPage="Deals"
     />

   
      <main className="seller-page-section">
        <div className="container seller-orders-section">
          <div className="row">
          <div className="col-md-2">
      <div className="leftNavBar">
        <ul>
          <li>
            <Link
             style={
           {}
            }
              to={"/seller/deals"}
            >
              All deals
            </Link>
           
          </li>
          
          {
              product?.dealsAddedBySeller?.data?.map((deal)=>
              <li>
            <Link
              style={
              dealId &&  dealId == deal.dealId.toString()
                  ? { background: "#d7d7d7", fontWeight: "450", color: "#000" }
                  : {}
              }
              to={"/seller/deals/"+deal.dealId}
            >
              {deal.dealName}
            </Link>
           
          </li>
              )
            }
        </ul>

     
      </div>
    </div>
            <div className="col-md-10">
              
              
              <div className="filter-result mt-3">
               

                <div className="">
                <Formik
        initialValues={dealData}
        validationSchema={DealSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          // if (submitAction === "primary") {
          //   let data = { ...values };
          //   data.Isdraft = true;
          //   submitFormData(data);
          // } else {
          //   // set state to show warning message
          //   submitFormData(values);
          // }

          setCatError(1)
          if(dealData.categoryProduct[0]?.categoryId!=="" && dealData.categoryProduct[0]?.productId!==""  )
          dispatch<any>(saveProductToDeal(values))
          
        }}
      >
        {({ handleSubmit, errors, touched }) => (
          <>
            <Form>
            <h2>
                         Add New Product
                        </h2>
            <div className="row mt-5">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="label-seller">
                          <span className="red-star">*</span>Offer
                        </label>
                        <select
                          className="form-control input-seller"
                          name="dealId"
                          value={dealData?.dealId}
                          onChange={onChange}
                        >
                          <option value=""> Select Offer</option>
                          {dealsResponse?.dealsHeaders?.value?.map(
                            (item, Index) => (
                              <option value={item.Id}>{item.Name}</option>
                            )
                          )}
                        </select>
                        {errors.dealId && touched.dealId ? (
                          <div className="required">{errors.dealId}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="label-seller">
                          <span className="red-star">*</span>Select category
                        </label>
                        <select
                          className="form-control input-seller"
                          name="category"
                          value={dealData.categoryProduct[0]?.categoryId}
                          onChange={(e)=>setDealData({...dealData,categoryId:e.target.value ,categoryProduct :[{categoryId:e.target.value ,productId:""}]})}
                        >
                          <option value="">Choose</option>
                          {
              categoryResponse?.sellerCategories?.data?.map(
                (item: any, Index: number) => (
                  <option value={item.categoryId} key={Index}>
                    {item.categoryName}
                  </option>
                )
              )}
                        </select>
                        {errors.categoryId && touched.categoryId ? (
                          <div className="required">{errors.categoryId}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div>&nbsp;</div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="label-seller">
                          <span className="red-star">*</span>Select product
                        </label>
                        <select
                          className="form-control input-seller"
                          name="product"
                          value={dealData.categoryProduct?.[0]?.productId}
                          onChange={(e)=>setDealData({...dealData,productId:e.target.value  ,categoryProduct :[{productId:e.target.value ,categoryId:dealData.categoryProduct?.[0]?.categoryId}]})}
                        >
                          <option value=""> Choose </option>
                          {product?.productsByCategory?.data?.map(
                            (item, Index) => (
                              <option value={item.id}>{item.name}</option>
                            )
                          )}
                        </select>
                        {/* {errors.deliveryIn && touched.deliveryIn ? (
                          <div className="required">{errors.deliveryIn}</div>
                        ) : null} */}
                         {errors.productId && touched.productId ? (
                          <div className="required">{errors.productId}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-4 ">
                      <label className="label-seller">
                        Base price ($)
                      </label>
                      <input
                        type="text"
                        name="quantity"
                        disabled={
                         true
                        }
                       
                        value={
                          basePrice
                        }
                        className="form-control input-seller"
                      />
                     
                    </div>
                  </div>
                 
                  <div>&nbsp;</div>
                  <div className="row">
                    <div className="col-md-4">
                    <label className="label-seller">
                        <span className="red-star">*</span>Start date
                      </label><br/>
                      <input  type="datetime-local" name="StartOn" value={date?.StartOn} onChange={onChange} style={{width:"100%" , padding:4, fontSize:14}} id="birthdaytime"   />
                      {errors.StartOn && touched.StartOn ? (
        <div className="required">{errors.StartOn}</div>
      ) : null}
                    </div>

                    <div className="col-md-4">
                    <label className="label-seller">
                        <span className="red-star">*</span>End date
                      </label><br/>
                      <input type="datetime-local" name="ExpiresOn" value={date?.ExpiresOn} onChange={onChange} style={{width:"100%" , padding:4, fontSize:14}} id="birthdaytime"   />
                      {errors.ExpiresOn && touched.ExpiresOn ? (
        <div className="required">{errors.ExpiresOn}</div>
      ) : null}
                    </div>
                  </div>
                  <div>&nbsp;</div>

<div className="row">
  <div className="col-md-4">
    <label className="label-seller">Free shipping</label>
    <div className="form-group">
      <div className="custom-control custom-checkbox custom-control-inline">
        <input
          type="checkbox"
          className="custom-control-input"
          id="requireFreeShipping"
          name="requireImmediatePayment"
          onChange={(e)=> setDealData({...dealData, IsShippingFree:e.target.checked ? true : false})}
          checked={
            dealData.IsShippingFree === false
              ? false
              : true
          }
        />
         <label
          className="custom-control-label"
          htmlFor="requireFreeShipping"
        >
          Free shipping
        </label>
       
      </div>
    </div>
  </div>

  <div className="col-md-4">
    <label className="label-seller">
      <span className="red-star">*</span>Sales discount
    </label>
    <div className="form-group form-group-schedule pb-0">
      <input
        type="number"
        name="SalesDiscount"
        onChange={onChange}
        value={
          dealData.SalesDiscount === null
            ? ""
            : dealData.SalesDiscount
        }
        className="form-control input-seller"
        placeholder="%"
      />
      {errors.SalesDiscount && touched.SalesDiscount ? (
        <div className="required">{errors.SalesDiscount}</div>
      ) : null}

    </div>
  
  </div>
</div>

<div className="seller-button">
                        <button
                          className="list-button"
                          type="submit"
                          name="submit"
                        >
                          Save 
                        </button>
                        <button
                          className="list-button bg-none"
                          onClick={()=>navigate("/seller/deals")}
                          style={{backgroundColor:"#D3D3D3" ,border:"none" , color:"black"}}
                        >
                          Cancel
                        </button>
                        {/* <button className="list-button bg-none" name="preview">Preview</button> */}
                       
                      </div>
            </Form>
          </>
        )}
      </Formik>
                </div>

             
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </DefaultLayout>
  );
};

export default AddNewProduct;
