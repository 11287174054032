import { AppThunk } from "../../../store";
import { DealsHeaderFailed, DealsHeaderSuccess } from "./slice";
import { fetchDealsHeadings } from "./api";
import { EndProgress } from "../../progressBar/endProgress";
import { StartProgress } from "../../progressBar/startProgress";

export const getDealsHeadings = (): AppThunk => async (dispatch) => {
  try {
    const data: any = await fetchDealsHeadings();

    if (data.success === "N") {
      dispatch(DealsHeaderFailed(data));
    } else {
      dispatch(DealsHeaderSuccess(data));
    }
  } catch (err) {
    dispatch(DealsHeaderFailed(err));
  }
};
