import { AppThunk } from "../../store";
import { AttributesSuccess, AttributesFailed } from "./slice";
import { fetchAttributes } from "./api";
import { EndProgress } from "../progressBar/endProgress";
import { StartProgress } from "../progressBar/startProgress";

export const getAttributes = (): AppThunk => async (dispatch) => {
  // dispatch<any>(StartProgress());
  try {
    const listingData: any = await fetchAttributes();
    

    if (listingData.success === "N") {
      
      dispatch(AttributesFailed(listingData));
    } else {
      
      dispatch(AttributesSuccess(listingData));
    }
  } catch (err) {
    
    dispatch(AttributesFailed(err));
  }

  // dispatch<any>(EndProgress());
};
