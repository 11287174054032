import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Rating } from "react-simple-star-rating";
import { ToastContainer } from "react-toastify";
import { DefaultLayout } from "../../../../../layout/DefaultLayout";
import { deleteRecentlyViewed } from "../../../../../modules/buyer/myEfindit/activity/deleteRecentlyViewed";
import { getRecentlyViewed } from "../../../../../modules/buyer/myEfindit/activity/getRecentlyViewed";
import { resetDeleteRecentlyViewed } from "../../../../../modules/buyer/myEfindit/activity/resetDeleteRecentlyViewed";
import { activityResponseSelector } from "../../../../../modules/buyer/myEfindit/activity/selectors";
import { notify } from "../../../../../modules/notification/notify";
import { BASE_URLS } from "../../../../../utils/api.urls";
import { constant } from "../../../../../utils/constant";
import { RootState } from "../../../../../utils/types";
import BuyerHeader from "../../../BuyerHeader";
import GroupTab from "../../groupTab";
import ActivityNavPanel from "../ActivityNavPanel";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { resetDetail } from "../../../../../modules/buyer/product/resetDetail";
import BuyerFooter from "../../../buyerFooter";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {getWebPImage, imageOnError} from "../../../../../utils/helpers";

const RecentlyViewed = () => {
  const dispatch = useDispatch();
  const isFirstRender2 = useRef(true);
  const isFirstRender = useRef(true);
  const navigate = useNavigate();

  let activityResponse = useSelector<RootState, RootState["activity"]>(
    activityResponseSelector
  );

  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(16);
  const [currentPage, setcurrentPage] = useState(0);

  if (activityResponse.recentlyViewed !== null && pageCount === 0) {
    setPageCount(
      Math.ceil(activityResponse?.recentlyViewed?.data?.totalResult / itemCount)
    );
  }

  const handlePageChange = (selectedObject: any) => {
    setcurrentPage(selectedObject.selected);
  };
  const handleItemsChange = (event: any) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };

  useEffect(() => {
    if (activityResponse?.recentlyViewedDelete != null) {
      if (activityResponse?.recentlyViewedDelete.success == "Y") {
        notify(
          activityResponse?.recentlyViewedDelete.message,
          constant.SUCCESS
        );
        callRecentlyViewed();
      }
    }
    if (activityResponse?.recentlyViewedDeleteError !== null) {
      notify(activityResponse?.recentlyViewedDeleteError, constant.ERROR);
    }
    dispatch<any>(resetDeleteRecentlyViewed());
  }, [
    activityResponse?.recentlyViewedDelete,
    activityResponse?.recentlyViewedDeleteError,
  ]);

  const callRecentlyViewed = () => {
    dispatch<any>(
      getRecentlyViewed({
        currentPage: currentPage + 1,
        rows: itemCount,
      })
    ).then(() => {
      window.scrollTo(0, 0);
      
      if (currentPage === 0) setPageCount(0);
    });
  };
  useEffect(() => {
    if (!isFirstRender2.current) {
      callRecentlyViewed();
    }
    isFirstRender2.current = false;
  }, [currentPage, itemCount]);
  useEffect(() => {
    if (isFirstRender.current) {
      callRecentlyViewed();
    }
    isFirstRender.current = false;
  }, []);

  return (
    <DefaultLayout>
      <BuyerHeader />
      <ToastContainer />

      <main className="min-section">
        <div className="container">
          <h1 className="sectionTitle mt-5">My efindit</h1>
          <GroupTab currentPage="Activity" />
          <section className="withFilter">
            <ActivityNavPanel currentPage="RecentlyViewed" />

            <div className="right-wrapper">
              <section className="deals-group pt-0">
                <div className="deal-group-list four-col bgLight mt-0">
                  <div className="right-wrap-title-block">
                    <div className="row justify-content-between align-items-center">
                      <div className="col-md-auto">
                        <div className="right-wrap-title">Recently viewed</div>
                      </div>
                      <div className="col-md-auto">
                        <button
                          type="button"
                          onClick={() => dispatch<any>(deleteRecentlyViewed())}
                          className="clearBtn"
                          disabled={
                            activityResponse?.recentlyViewed?.data === null
                              ? true
                              : false
                          }
                        >
                          Clear all
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="wrap">
                    {activityResponse?.recentlyViewed?.data?.products?.map(
                      (item, Index) => (
                        <div className="recent-list-item" onClick={() => dispatch<any>(resetDetail()).then(()=>navigate(`/buyer/productDetail/${item.productId}/${item.variantId}`))}>
                          <i className="timer-icon"></i>
                          <i className="blue-star"></i>
                          <figure className="recent-media">
                            <picture>
                              <source srcSet={getWebPImage(item.defaultImage)} type="image/webp"/>
                              <LazyLoadImage src={BASE_URLS.AWS_URL + "/Thumb" + item.defaultImage} onError={imageOnError} />
                            </picture>
                          </figure>
                          <div className="recent-details">
                            <div className="recent-title" style={{ minHeight: "50px" }}>
                              {item.title.substring(0, 40)}
                              {item.title.length > 40 && "..."}
                            </div>
                            <div className="fi-stars">
                              <Rating initialValue={item.rating} readonly={true} allowFraction={true} size={16}/>
                            </div>
                            <div className="fi-ship-status small">
                              {
                                JSON.parse(item.variant)?.map((product:any)=>(
                                  <p> {`${product.AttName} : ${product.Option}`} </p>
                                ))
                              }
                            </div>
                            <div className="recent-price">
                              $ {item.buyNowPrice ? item?.buyNowPrice : (item.actualPrice ? item.actualPrice : 0)}
                            </div>
                            <div className="recent-price-small">
                              <span className="price-cut">
                                US ${item.actualPrice ? item.actualPrice : 0}
                              </span>

                              {item.offer !== null && (
                                <>
                                  {" "}
                                  <span>|</span>
                                  <span className="price-bold">
                                    {item.offer} % OFF
                                  </span>
                                </>
                              )}
                            </div>
                            {item.isShippingFree && (
                              <div className="fi-ship-status small">
                                Free shipping
                              </div>
                            ) }

                            <div className="fi-stock-status small">
                              {
                                item.quantity <= constant.ALMOST_GONE_QUANTITY && 
                                item.quantity > constant.ONLY_FEW_LEFT ? 
                                  ( <p> Almost Gone</p> ) 
                                    : 
                                      item.quantity <= constant.ONLY_FEW_LEFT ? 
                                        ( <p> Only {item.quantity ? item.quantity : 0} left</p> ) 
                                      : ( "" )
                                }
                            </div>
                          </div>
                        </div>
                      )
                    )}
                    {activityResponse?.recentlyViewed?.data == null && (
                      <div className="no-data-css">
                          <h3>No records found</h3>
                      </div>
                    )}
                  </div>

                  {activityResponse?.recentlyViewed?.data != null && (    
                  <nav className="pagination-block">
                    <p className="total">
                      {activityResponse?.recentlyViewed?.data?.totalResult}{" "}
                      items total
                    </p>

                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      forcePage={currentPage}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "pagination justify-content-center mt-4"
                      }
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                    <div className="customSelect">
                      <label htmlFor="">Items per page :</label>
                      <div className="selectBox">
                        <select name="" id="" onChange={handleItemsChange}>
                        <option value="16">16</option>
                            <option value="24">24</option>

                            <option value="32">32</option>
                        </select>
                      </div>
                    </div>
                  </nav>
                  )}
                </div>
              </section>
            </div>
          </section>
        </div>
      </main>
      <BuyerFooter />
    </DefaultLayout>
  );
};

export default RecentlyViewed;
