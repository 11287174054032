import React, { useState } from "react";
import { useDispatch } from "react-redux";
// import { deactivateBuyer } from "../../../../../modules/buyer/myEfindit/account/deactivateBuyer";
// import { deactivateSeller } from "../../../../../modules/buyer/myEfindit/account/deactivateSeller";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { resendVerificationLink } from "../../modules/userLogin/resendVerificationLink";

// import { orderCancelled } from "../../../modules/orders/orderCancelled";

const ResendVerficationModel = ({
 email,
 setVisible

}: any) => {
  const dispatch = useDispatch();
  // interface deactivateUserValues {
  //   visible: string;
  //   reason: string | number ;
  //   description: string | null;
  // }
  // const [deactivateUser, setDeactivateUser] = useState<deactivateUserValues>({
  //   visible: "none",
  //   reason: "",
  //   description:null,
  // });
  const handleSubmit=()=>{

   
    dispatch<any>(resendVerificationLink({emailId:email}));

             setVisible(false);
  }


  // const checkDisabled =()=>{
  //   if(deactivateUser?.reason==="")
  //   {
  //     return true;
  //   }
  //   else if(deactivateUser?.reason==255 && deactivateUser?.description===null)
  //   {
  //     return true;
  //   } 
  //   else 
  //   return false;
  // }


  
  return (
    <div
      className="modal modal-order-popup"
      id="myModal"
      tabIndex={-1}
      role="dialog"
      style={{ display: "block" }}
    > 
   
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="order-modal">
            <div className="order-modal-head border-none">Account not verified</div>

            <div className="form-group spacer-feedback-select">
              <label className="label-modal">
              The account has not been verified yet, if you want to send the verification link again please click on the <span style={{cursor:"pointer", fontSize:14,color:"#0000EE"}} onClick={handleSubmit}>Send Verification Link</span>
              </label>
           {/* <p><span style={{cursor:"pointer", fontSize:14}} onClick={handleSubmit}>Resend link</span></p> */}
             
            </div>
      
      

            <div className="modal-btn-group spacer-feedback-btn">
              {/* <button
                type="submit"
                className="modal-add-btn modal-cancel-order-btn"
                // onClick={
                //  handleSubmit
                // }
                // disabled={
                 
                //   checkDisabled()
                // }
              >
                Deactivate
              </button> */}
              <button
                className="modal-cancel-btn"
                onClick={() =>
                 setVisible(false)
                }
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default ResendVerficationModel;
