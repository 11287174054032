import moment from 'moment';
import React, { useState } from 'react'


interface Props {
  product: any;
  handlePlaceBid : any;
  handleAddToWatchlist:any;

}
const ProductBid = ({
  product,handlePlaceBid, handleAddToWatchlist}: Props
  ) => {

    const [newBid, setNewBid] = useState("");
    const [bidError, setBidError] = useState("");

    const handleChangeBid = (e:any) => {
      setBidError("")
      setNewBid(e.target.value)
    }

    const savePlaceBid = () => {

      if (newBid <= product.productDetails?.data?.productDetail.actualPrice ) {
        setBidError("New bid must be greater that " + product.productDetails?.data?.productDetail.actualPrice )
      }else {

        handlePlaceBid(newBid)
      }
    }

  return (
    <div className="smallCartBlock">
        <h4>Current bid : $ {moment.utc().isAfter(product.productDetails?.data?.productDetail.listingExpiryDate) ? "": product.productDetails?.data?.productDetail.buyNowPrice
                                ? product.productDetails?.data?.productDetail.buyNowPrice
                                : product.productDetails?.data?.productDetail.actualPrice}</h4>
        
        <input type="number"
        className="enterBid form-control" disabled={(moment.utc().isAfter(product.productDetails?.data?.productDetail.listingExpiryDate)||  product?.productDetails?.data?.productDetail?.isSelfProduct)} value={newBid} onChange={handleChangeBid} placeholder="Enter bid amount"/>
        <span style={{color:"red",fontSize:"12px"}}>{bidError}</span>
        <div className="moreBid">Enter more than US $ {product.productDetails?.data?.productDetail.buyNowPrice
                                ? product.productDetails?.data?.productDetail.buyNowPrice 
                                : product.productDetails?.data?.productDetail.actualPrice } </div>
        <button  className="butItNow"
                  onClick={savePlaceBid}
                  disabled={(moment.utc().isAfter(product.productDetails?.data?.productDetail.listingExpiryDate) ||  product?.productDetails?.data?.productDetail?.isSelfProduct)}
        >
            Place bid
        </button>
        <button className="addToWishList" 
                onClick={handleAddToWatchlist}
                disabled={moment.utc().isAfter(product.productDetails?.data?.productDetail.listingExpiryDate) ||  product?.productDetails?.data?.productDetail?.isSelfProduct}
            >
                <img src="images/wishlistIconBtn.png" alt="" /> Add to
                watchlist
            </button>
    </div>
)
}

export default ProductBid
