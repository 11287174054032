import { AppThunk } from "../../../store";
import { SaveDataFailed, SaveDataSuccess } from "./slice";
import { postToCart } from "./api";
import { EndProgress } from "../../progressBar/endProgress";
import { StartProgress } from "../../progressBar/startProgress";

export const addToCart =
  (params: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch<any>(StartProgress());

      const data: any = await postToCart(params);
      

      if (data.success === "N") {
        
        dispatch(SaveDataFailed(data));
      } else {
        
        dispatch(SaveDataSuccess(data));
      }
    } catch (err) {
      
      dispatch(SaveDataFailed(err));
    }
    dispatch<any>(EndProgress());
  };
