import { AppThunk } from "../../store";
import { fetchCategoryTree } from "./api";
import { CategoryTreeSuccess, CategoryTreeFailed } from "./slice";
import { EndProgress } from "../progressBar/endProgress";
import { StartProgress } from "../progressBar/startProgress";

export const getCategoryTree =
  (): AppThunk =>
  async (dispatch) => {
    
    dispatch<any>(StartProgress());
    try {
      const categoryData: any = await fetchCategoryTree();
      

      if (categoryData.success === "N") {
        
        dispatch(CategoryTreeFailed(categoryData));
      } else {
        
        dispatch(CategoryTreeSuccess(categoryData));
      }
    } catch (err) {
      
      dispatch(CategoryTreeFailed(err));
    }
    dispatch<any>(EndProgress());
  };
