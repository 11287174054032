import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Account } from "../../../utils/buyer.types";
import { Deals } from "../../../utils/types";

type activityState = {
  deals: Deals["deals"] | null;
  dealsHeaders: Deals["dealsHeaders"] | null;
  dealsHeaderError: any | null;
  error: any | null;
};

const initialState: activityState = {
  deals: null,

  error: null,
  dealsHeaders: null,

  dealsHeaderError: null,
};
const slice = createSlice({
  name: "deals",
  initialState,
  reducers: {
    DealsSuccess: (state, action: PayloadAction<any>) => {
      state.deals = action.payload;
      state.error = null;
    },
    DealsFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.deals = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
    DealsHeaderSuccess: (state, action: PayloadAction<any>) => {
      state.dealsHeaders = action.payload;
      state.dealsHeaderError = null;
    },
    DealsHeaderFailed: (state, action: PayloadAction<any>) => {
      state.dealsHeaderError = action.payload;
      state.dealsHeaders = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.dealsHeaderError = errorMessage;
    },
  },
});

export const deals = slice.reducer;

export const {
  DealsFailed,
  DealsSuccess,
  DealsHeaderFailed,
  DealsHeaderSuccess,
} = slice.actions;
