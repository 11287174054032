import Api from "../../utils/api";
import { API_URLS } from "../../utils/api.urls";

export const fetchCategories = async (params: any | null): Promise<any> => {
  const data = await Api.get(
    params.rows !== 0
      ? API_URLS.CATEGORIES_CRUD +
          "/?$skip=" +
          params.currentPage +
          "&$top=" +
          params.rows +
          "&$count=true"
      : API_URLS.CATEGORIES_CRUD,
    []
  );
  return data;
};

export const fetchHeaderCategories = async (): Promise<any> => {
  const data = await Api.get(
    API_URLS.CATEGORIES_HEADER ,

    []
  );
  return data;
};

export const fetchSellerCategories = async (): Promise<any> => {
  const data = await Api.get(
    API_URLS.CATEGORIES_CRUD + "/productcategory",

    []
  );
  return data;
};

export const fetchCategoryTree = async (): Promise<any> => {
  const data = await Api.get(API_URLS.CATEGORIES_CRUD + "/categorytree", []);
  return data;
};

export const fetchCategoryById = async (id: any | null): Promise<any> => {
  const data = await Api.get(
    API_URLS.CATEGORIES_CRUD +
      "/" +
      id +
      "?$expand=Variants($filter= Active eq 'Y')",
    []
  );
  return data;
};

export const postCategories = async (params: any | null): Promise<any> => {
  const data = await Api.post(API_URLS.CATEGORIES_CRUD, params);
  return data;
};

export const putCategories = async (params: any | null): Promise<any> => {
  const data = await Api.post(API_URLS.CATEGORIES_CRUD, params);
  return data;
};

export const deleteCategory = async (id: any | null): Promise<any> => {
  const data = await Api.delete(API_URLS.CATEGORIES_CRUD + "/" + id, []);
  return data;
};

export const setCategoryStatus = async (params: any | null): Promise<any> => {
  const data = await Api.patch(API_URLS.CATEGORIES_CRUD, params);
  return data;
};

export const fetchCategoryTemplateByCategoryId = async (
  id: string | null
): Promise<any> => {
  const data = await Api.get(
    API_URLS.CATEGORY_TEMPLATE_CRUD +
      "/" +
      `?$filter=CategoryId eq ` +
      "'" +
      id +
      "'",
    []
  );
  return data;
};
