import { AppThunk } from "../../store"
import { _OnSuccess, _OnFailed } from "./slice"
import { fetchAddress } from "./api";
import { Sellers } from "../../utils/types";

export const getSellerAddress = (data: number): AppThunk => async (dispatch) => {
    try {
        const addrsData: Sellers['singleAddress'] = await fetchAddress(data);
        
        if (addrsData.success === "N") {
            dispatch(_OnFailed(addrsData));
        }else {
            dispatch(_OnSuccess(addrsData));
        }
        
    } catch (err) {
        dispatch(_OnFailed(err));
    }
}