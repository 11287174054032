import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { DefaultLayout } from '../../../layout/DefaultLayout'
import BuyerFooter from '../buyerFooter'
import BuyerHeader from '../BuyerHeader'
import DefaultImage from "../../../assets/images/defaultImage.png";
import { BASE_URLS } from '../../../utils/api.urls'
import { dateToLocal } from '../../../modules/date/date'
import moment from 'moment'

const ConfirmOrder = () => {

  const { state } = useLocation();
  const navigate = useNavigate();
  
  return (
    <DefaultLayout>
      <BuyerHeader/>
      <main className="min-section">
        <div className="container">
          <section>
            <div className="myCart mt-5 checkoutSection">
              <div className="row">
                <div className="col-xl-9">
                  <h1>Your order has been confirmed and placed successfully</h1>
                  <div className="cartProducts">
                    { state?.cart?.map((item:any, Index:number)=> 
                      <div className="cartProductLists">
                        <div className="productCount border-0 pt-0" key={Index}>
                          <div className="img">
                            <img  
                              src={BASE_URLS.AWS_URL  + "/Thumb" + item.defaultImage}
                              onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = DefaultImage;
                                      }} 
                              alt="Generic placeholder"
                            />
                          </div>
                          <div className="prodContext">
                            <div className="row">
                              <div className="col-lg-8">
                                <h3>{item.title}</h3>
                                <div className="brandNew">Condition:{item.condition}</div>
                                <div className='mt-2'>
                                  { item?.variant && JSON.parse(item.variant)?.map((item:any)=>
                                    ( <p> {`${item.AttName} : ${item.Option}`} </p> ))
                                  } 
                                </div> 
                              </div>
                              <div className="col-lg-12">
                                <div className="row">
                                  <div className="col-lg-2">
                                    <div className="text">Amount paid</div>
                                    <div className="price">US ${state?.data?.type==="BuyItNow"?  (Number( state?.data?.quantity * (item.buyNowPrice? item.buyNowPrice  : item.actualPrice ))+Number(item.shippingCharges?.[0].price ? item.shippingCharges?.[0].price : 0) ).toFixed(2):  (item.buyNowPrice? Number(item.buyNowPrice* item.quantity)+Number(item.shippingCharges?.[0].price ? item.shippingCharges?.[0].price : 0) : Number(item.actualPrice * item.quantity)+Number(item.shippingCharges?.[0].price ? item.shippingCharges?.[0].price : 0)).toFixed(2)}</div>
                                  </div>
                                  <div className="col-lg-2">
                                    <div className="text">Quantity</div>
                                    <div className="text mr-5 mt-2 "><strong className="boldTxt">{state?.data?.type==="BuyItNow"? state?.data?.quantity :  item.quantity}</strong></div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 pt-3">
                                <strong className="boldTxt">Delivery</strong>
                                <div className="text">
                                  Estimated delivery:{moment(dateToLocal(item?.estimatedDeliveryDateAndTime)).format("DD MMM YYYY") }<br/>
                                  {/* USPS First className Mail International / First className Package International Service- check */}
                                  {/* US $   {item.shippingCharges?.[0].price }  */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-3 mb-5">
                  <div className="text-right">
                    <button  className="shopMoreBtn" onClick={()=> navigate("/home")}>Shop more</button>
                    <div className="mb-3">
                      <img className="w-100" src="images/paypal-ad.png" alt=""/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
      <BuyerFooter/>
    </DefaultLayout>
  )
}

export default ConfirmOrder