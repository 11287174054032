import Api from "../../utils/api";
import { API_URLS } from "../../utils/api.urls";

export const fetchAddress = async (params:any): Promise<any> => {
  const data = await Api.get(API_URLS.RETURN_ORDERS + "/sellerdetail/" + params,[]);
  return data;
};

export const fetchReturnOrderTrackingDetails_Seller = async (params: any): Promise<any> =>{
  const data = await Api.get(API_URLS.GET_TRACKING_DETAILS_URL+"/"+params,[]);
  return data;
}