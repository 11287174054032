import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Header from "../../../components/header/Header";
import { DefaultLayout } from "../../../layout/DefaultLayout";
import { RootState } from "../../../utils/types";
import { ToastContainer } from "react-toastify";
import { getOrderDetail } from "../../../modules/orders/getOrderDetail";
import { SellerOrdersResponseSelector } from "../../../modules/orders/selectors";
import { BASE_URLS } from "../../../utils/api.urls";
import ContactBuyer from "./ContactBuyer";
import OrderFeedback from "./OrderFeedback";
import { CommonResponseSelector } from "../../../modules/common/selectors";
import { notify } from "../../../modules/notification/notify";
import { constant } from "../../../utils/constant";
import { resetOrder } from "../../../modules/orders/resetOrder";
import "react-toastify/dist/ReactToastify.css";
import { getCommonData } from "../../../modules/common/getCommonData";
import Footer from "../../../components/footer/Footer";
import { dateToLocal } from "../../../modules/date/date";
import moment from "moment";

const OrderDetail = () => {
  const { state } = useLocation();
  const orderID: any = state;
  const isFirstRender = useRef(true);
  const dispatch = useDispatch();
  let order = useSelector<RootState, RootState["sellerOrders"]>(
    SellerOrdersResponseSelector
  );

  const commonResponse = useSelector<RootState, RootState["common"]>(
    CommonResponseSelector
  );
  useEffect(() => {
    if (isFirstRender.current) {
      dispatch<any>(resetOrder("details")).then(
        dispatch<any>(getOrderDetail({ orderId: state }))
      );
      if (commonResponse?.common === null) dispatch<any>(getCommonData());
    }
    isFirstRender.current = false;
  }, [state]);

  interface feedbackValues {
    visible: string;
    orderId: string;
    definedMessage: string | null;
    message: string;
  }
  interface ContactBuyerValues {
    message: string;
    orderId: string;
    sendCopy: boolean;
    visible: string;
  }

  const [contactBuyer, setContactBuyer] = useState<ContactBuyerValues>({
    visible: "none",
    orderId: "",
    message: "",
    sendCopy: false,
  });

  const [feedback, setFeedback] = useState<feedbackValues>({
    visible: "none",
    orderId: "",
    definedMessage: null,
    message: "",
  });

  useEffect(() => {
    if (order.saveData != null) {
      if (order.saveData.success == "Y") {
        notify(order.saveData.message, constant.SUCCESS);
      } 
      setFeedback({
        visible: "none",
        orderId: "",
        definedMessage: null,
        message: "",
      });

      setContactBuyer({
        visible: "none",
        orderId: "",
        message: "",
        sendCopy: false,
      });
    }
      else if (order.saveDataError !== null)
        notify(order.saveDataError, constant.ERROR);
      dispatch<any>(resetOrder());
     
    
  }, [order.saveData, order.saveDataError]);
  return (
    <DefaultLayout>
      <Header />
      <ToastContainer />
      <ContactBuyer
        contactBuyer={contactBuyer}
        setContactBuyer={setContactBuyer}
      />

      {/* <OrderFeedback
        feedback={feedback}
        setFeedback={setFeedback}
        commonResponse={commonResponse}
      /> */}
      <section className="seller-breadcrumb-links">
        <div className="container">
          <ul>
            <li>
              <Link to={"/seller/overview"}>eFindit</Link>
            </li>
            <li className="spacer">{">"}</li>
            <li>
              <Link to={"/seller/orders"}>orders</Link>
            </li>
            <li className="spacer">{">"}</li>
            <li className="active">Order detail</li>
          </ul>
        </div>
      </section>

      <main className="seller-page-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="order-detail-heading">
                <h2 className="seller-head-style">Order detail</h2>
              </div>
              <div className="order-detail-block">
                <div className="order-id-style">
                  Bought by:{" "}
                  <a href="#">{order?.orderDetail?.data?.buyerName}</a> <br/>
                  Date & Time of purchase: {moment(dateToLocal( order?.orderDetail?.data?.orderDate)).format("LLL")}
                </div>
                <div className="order-detail-block-inner order-detail-seller">
                  <div className="order-detail-section-l">
                {order?.orderDetail?.data?.status==="Delivered"  ?     <div className="order-estimate-block">
                      Delivered date{" "}
                      <strong>
                        { moment(dateToLocal( new Date(order?.orderDetail?.data?.deliveryDate))).format("MMMM Do YYYY")}
                      </strong>
                    </div> : 
                    order?.orderDetail?.data?.exceptedDelivery ?
                    <div className="order-estimate-block">
                    Estimated delivery{" "}
                    <strong>
                      {moment(dateToLocal( new Date(order?.orderDetail?.data?.exceptedDelivery))).format("MMMM Do YYYY")}
                    </strong>
                  </div> : null
                    }
                    <div className="order-estimate-product">
                      <div className="table-order-detail">
                          <div className="order-detail-img-content">
                            <div className="order-detail-img-l">
                              <img
                                src={
                                  BASE_URLS.AWS_URL +
                                  "/Thumb" +
                                  order?.orderDetail?.data?.productImage
                                }
                                style={{
                                  height: 60,
                                  width: 60,
                                }}
                              />
                            </div>
                            <div className="order-detail-content-m">
                              <label className="order-product-label">
                                {order?.orderDetail?.data?.productName}
                              </label>
                              <p className="order-product-text">
                                Condition :{" "}
                                {order?.orderDetail?.data?.condition}
                              </p>
                              <table className="order-item-table">
                                <tr>
                                  <td>
                                    <label className="order-item-label">
                                      Order Id
                                    </label>
                                    <p className="order-item-text">{orderID}</p>
                                  </td>
                                  <td>
                                    <label className="order-item-label">
                                      Price
                                    </label>
                                    <p className="order-item-text">
                                      $ {order?.orderDetail?.data?.price}
                                    </p>
                                  </td>
                                  <td>
                                    <label className="order-item-label">
                                      Quantity
                                    </label>
                                    <p className="order-item-text">
                                      {order?.orderDetail?.data?.quantity}
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </div>
                            <div>
                              <div className="order-button-block">
                                {/* <button
                                  className="list-button"
                                  onClick={() =>
                                    setFeedback({
                                      ...feedback,
                                      visible: "block",
                                      orderId: orderID,
                                    })
                                  }
                                >
                                  Leave feedback
                                </button> */}

                                <button
                                  className="list-button bg-none"
                                  onClick={() =>
                                    setContactBuyer({
                                      ...contactBuyer,
                                      visible: "block",
                                      orderId: orderID,
                                    })
                                  }
                                >
                                  Contact buyer
                                </button>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>

                  <div className="order-detail-section-r">
                    <div className="delivery-add-block">
                      <div className="delivery-add-title">Delivery address</div>
                      <div className="delivery-small-text">
                        {order?.orderDetail?.data?.buyerName}
                      </div>
                      <div className="delivery-add-text">
                        {order?.orderDetail?.data?.address1},
                        <br /> {order?.orderDetail?.data?.address2}
                        <br /> {order?.orderDetail?.data?.zipCode}
                        <br /> {order?.orderDetail?.data?.state}
                        <br /> {order?.orderDetail?.data?.country}
                        <br /> {order?.orderDetail?.data?.callingCode}-
                        {order?.orderDetail?.data?.mobileNo}
                      </div>
                    </div>

                    <div className="order-total-block">
                      <div className="delivery-add-title order-total-btm">
                        Total order 
                      </div>
                      <div className="order-total-row border-btm">
                        <div className="order-total-col-l">Platform fee</div>
                        <div className="order-total-col-r">
                          $ {order?.orderDetail?.data?.platformFee}
                        </div>
                      </div>
                      <div className="order-total-row border-btm">
                        <div className="order-total-col-l">Transaction fee</div>
                        <div className="order-total-col-r">
                          {" "}
                          $ {order?.orderDetail?.data?.transactionFee}
                        </div>
                      </div>
                      <div className="order-total-row border-btm">
                        <div className="order-total-col-l">Shipment fee</div>
                        <div className="order-total-col-r">
                          ${order?.orderDetail?.data?.shipmentAmount}
                        </div>
                      </div>
                      <div className="order-total-row border-btm">
                        <div className="order-total-col-l">
                          Receivable amount
                        </div>
                        <div className="order-total-col-r">
                        
                          ${order?.orderDetail?.data?.sellerReceivableAmount}
                        
                        </div>
                      </div>
                      <div className="order-total-row border-btm">
                        <div className="order-total-col-l">Total order</div>
                        <div className="order-total-col-r">
                          ${" "}
                          {order?.orderDetail?.data?.orderTotal *
                            order?.orderDetail?.data?.quantity}
                        </div>
                      </div>
                      {/* <div className="order-total-row">
                        <div className="order-total-col-l">Postage</div>
                        <div className="order-total-col-r">check</div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </DefaultLayout>
  );
};

export default OrderDetail;
