import React, { useEffect, useState } from 'react'
// import "./printOrder.scss"
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../utils/types'
import { dateToLocal } from '../../../modules/date/date'
import { getListingReport } from '../../../modules/sellerProduct/getListingReport'
import { SellerProductResponseSelector } from '../../../modules/sellerProduct/selector'
import { reset } from '../../../modules/sellerProduct/reset'
import { DefaultLayout } from '../../../layout/DefaultLayout'
import { getPaymentsSellerReport } from '../../../modules/sellerProduct/getPaymentsSellerReport'
const PrintPayments = () => {
    const {state}= useLocation()
    const dispatch = useDispatch();
  const navigate = useNavigate();
   

      const [reportData, setReportData] = useState([] as any);
      const product = useSelector<RootState, RootState["sellerProduct"]>(
        SellerProductResponseSelector
      );

useEffect(()=>{
    dispatch<any>(
  
      getPaymentsSellerReport({
        currentPage: 1,
        rows:state?.rows ,
        Search: state?.Search,
        Format: state?.Format,
        Days: state?.Days,
      }))
},[state])


useEffect(() => {
  if (product?.paymentsReport) {
    let userData: any = [];
    product?.paymentsReport?.data?.payments?.map((payment:any) => {
        let datalist: any = {};
     
    
        datalist["orderId"] = payment?.orderId;
        datalist["orderDate"] = dateToLocal(payment?.orderDate);
        datalist["platFormFee"] = payment?.platFormFee;
        datalist["refundAmount"] = payment?.refundAmount;
        datalist["returnId"] = payment?.returnId;
        datalist["sellerReceivableAmount"] = payment?.sellerReceivableAmount;
        datalist["status"] = payment?.status;
        datalist["totalAmount"] = payment?.totalAmount;
        datalist["transactionFee"] = payment?.transactionFee;
     
        userData.push(datalist);
      });
    setReportData(userData);
dispatch<any>(reset("report"))

  } 
}, [product?.paymentsReport]);
  useEffect(()=>{
   if(reportData.length!==0 )
   window.print()
},[reportData])
  return (
    <DefaultLayout>
    <div>
    <button className='btn btn-primary m-3 p-2' onClick={()=>     dispatch<any>(reset("report")).then(()=> navigate("/seller/payments",
))} >Go Back</button>
    <table className="result-table">
    <thead>
      <tr>
   



    <th className="">Order Id</th>
                        <th className="date-width">Order date</th>

                        <th className="">Platform fee($)</th>

                        <th>Seller receivable amount($)</th>
                        <th>Transaction fee($)</th>
                        <th>Total amount($)</th>
                        <th className="">Return Id</th>
                        <th>Refund amount($)</th>

                        <th>Order status</th>


        
    

        
      </tr>
    </thead>
    <tbody>
    {reportData?.map((payment:any)=>
      <tr>
          <td>{payment.orderId}</td>
       
        <td>{payment.orderDate}</td>
        <td>{payment.platFormFee}</td>

        <td className="sold-for">${payment.sellerReceivableAmount}</td>
        <td className="total">
          {payment?.transactionFee}
        </td>
        <td className="total">{payment.totalAmount}</td>
        <td className="">{payment.returnId}</td>
        <td className="total">{payment.refundAmount}</td>
        <td className="total">{payment.status}</td>

       
       
      </tr>)}
      
     
    </tbody>
  </table>
  </div>
  </DefaultLayout>
  )
}

export default PrintPayments;