import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DefaultLayout } from "../../../layout/DefaultLayout";
import { RootState } from "../../../utils/types";
import BuyerHeader from "../BuyerHeader";
import { dealsResponseSelector } from "../../../modules/buyer/deals/selectors";
import { getDeals } from "../../../modules/buyer/deals/getDeals";
import { BASE_URLS } from "../../../utils/api.urls";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import BuyerFooter from "../buyerFooter";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getDealsHeadings } from "../../../modules/buyer/deals/getDealsHeadings";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { constant } from "../../../utils/constant";
import { resetDetail } from "../../../modules/buyer/product/resetDetail";

function Deal() {
  const dispatch = useDispatch();
  const { Id, name } = useParams();

  const navigate = useNavigate();

  const settings = {
    dots: true,
    arrows: true,
    accessibility: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  let dealsResponse = useSelector<RootState, RootState["deals"]>(
    dealsResponseSelector
  );
  useEffect(() => {
    if (Id) {
      dispatch<any>(getDeals(Id));
      dispatch<any>(getDealsHeadings());
    }
  }, [Id]);
  return (
    <div>
      <DefaultLayout>
        <BuyerHeader />
        <ToastContainer/>
        <main className="min-section">
          <section className="deals-group">
            <div className="container">
              <div className="row mb-4">
                <div className="col-md-4">
                  <h1>Deal</h1>
                </div>
                <div className="col-md-8">
                  <div className="link-tabs">
                    <ul className="nav-list">
                      {dealsResponse?.dealsHeaders?.value?.map(
                        (deal: any, Index: number) => {
                          return Index < 4 ? (
                            <li
                              className={`${
                                deal.Name === name ? "active" : ""
                              }`}
                            >
                              <Link to={`/buyer/deal/${deal.Id}/${deal.Name}`}>
                                {deal.Name}
                              </Link>
                            </li>
                          ) : null;
                        }
                      )}
                      <li className="">
                        <Link to={`/buyer/dailyDeal`}>more</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col-md-auto">
                  <h2 className="text-uppercase">
                    {dealsResponse?.deals?.data?.offerAndDeals?.[0].dealName}
                  </h2>
                </div>
              </div>
              <div className="deal-group-list">
                <div className="wrap">
                  {dealsResponse?.deals?.data ? (
                    dealsResponse?.deals?.data?.offerAndDeals?.[0].products?.map(
                      (product, Index: number) => (
                        <div
                          className="recent-list-item"
                          onClick={() =>
                            dispatch<any>(resetDetail()).then(()=>   navigate(
                              `/buyer/productDetail/${product.productId}`
                            ))
                          }
                        >
                          <i className="timer-icon"></i>
                          <figure className="recent-media">
                           <div style={{cursor:"pointer"}}>
                              <img
                                src={
                                  BASE_URLS.AWS_URL +
                                  "/Thumb" +
                                  product.defaultImage
                                }
                                alt=""
                              />
                        </div>
                          </figure>
                          <div className="recent-details">
                            <div className="recent-title">{product.title}</div>
                            <div className="recent-price">
                              $
                              {product.buyNowPrice
                                ? product.buyNowPrice
                                : product.actualPrice}
                            </div>
                            <div className="recent-price-small">
                              <span className="price-cut">
                                US ${product.actualPrice}
                              </span>
                              <span>|</span>
                              <span className="price-bold">
                                {product.salesDiscount}% OFF
                              </span>
                            {   product?.isShippingFree &&  <div className="fi-ship-status small">
                                Free shipping
                              </div>}
                            {product?.quantity < constant?.ALMOST_GONE_QUANTITY  && <div className="fi-stock-status small">
                                Almost Gone
                              </div>}
                            </div>
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <div className="no-data-css">
                      <h3>No records found</h3>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </main>

        <BuyerFooter />
      </DefaultLayout>
    </div>
  );
}

export default Deal;
