import React from "react";
import { Navigate } from "react-router-dom";

const RequireAuthSeller = ({ children }: any) => {
  const accessToken = localStorage.getItem("authorization");
  const role = localStorage.getItem("role");
  const storeNameLinked = localStorage.getItem("storeNameLinked");
  const storeAddressLinked = localStorage.getItem("storeAddressLinked");
  const payPalAccountLinked = localStorage.getItem("payPalAccountLinked");
  const currentURL = window.location.href;
  if (role === null || accessToken==null ||  role === "Customer" ) return <Navigate to="/home" />;

  // if (accessToken == null || role !== "Seller")
  // return <Navigate to="/login-seller" />;
  if (
    storeNameLinked == null &&
    storeAddressLinked == null &&
    payPalAccountLinked == null && !currentURL.includes("seller/store-info")
  ) {
  return  <Navigate to="/seller/store-info" />;
  } else if (
    storeNameLinked !== null &&
    storeAddressLinked == null &&
    payPalAccountLinked == null && !currentURL.includes("seller/store-add")
  ) {
   return <Navigate to="/seller/store-add" />;
  } else if (
    storeNameLinked !== null &&
    storeAddressLinked !== null &&
    payPalAccountLinked == null && !currentURL.includes("seller/payment-info")
  )
  { 
    return <Navigate to="/seller/payment-info" />;}

  return children;
};

export default RequireAuthSeller;
