import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { saveOffer } from "../../../../../modules/buyer/product/saveOffer";
import { buyerCounterOffer } from "../../../../../modules/buyer/myEfindit/activity/buyerCounterOffer";
// import { saveOffer } from "../../../modules/buyer/product/saveOffer";
// import { orderCancelled } from "../../../modules/orders/orderCancelled";

const MakeCounterOfferModel = ({
  makeOffer,
  setMakeOffer,
  
  
  
}: any) => {
  const dispatch = useDispatch();

const isNumeric=(value:any)=> {
  return /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/.test(value);
}


const handleSubmit =()=>{
  const now = new Date();

    const timezoneOffsetMinutes = now.getTimezoneOffset();
   const timezone= timezoneOffsetMinutes < 0 ? timezoneOffsetMinutes*-1 : timezoneOffsetMinutes;

    dispatch<any>(buyerCounterOffer({

      "OfferId": makeOffer?.offerId,
      "offerAmount": makeOffer?.offerAmount,
      "TimeZone": timezone

    })).then(()=>
    setMakeOffer({ ...makeOffer,   visible: "none",
                   
    quantity:0,
    buyNowPrice:0,
    offerId:0,
  
    offerAmount:"", })
    )
    
  }
  return (
    <div
      className="modal modal-order-popup"
      id="myModal"
      tabIndex={-1}
      role="dialog"
      style={{ display: makeOffer.visible }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="order-modal">
            <div className="order-modal-head border-none">Counter offer</div>
           
            <div className="form-group spacer-feedback-select">
            <h4>  Buying price : ${makeOffer.buyNowPrice }</h4>
              </div>
              <div className="form-group spacer-feedback-select">
            <h4>Quantity</h4>
            <input  name="quantity"
            style={{width:"30px", paddingLeft :5}}
                id="quantity"
                disabled={true}
                value={makeOffer.quantity} />
       
  {/* { quantityDropdown.length==0 &&        <p className="required">No quanity available</p>} */}
            </div>
            <div className="form-group spacer-feedback-select">
              <label className="label-modal">
                Your offer per item ($)
              </label>
            <input value={makeOffer.offerAmount} onChange={(e)=>setMakeOffer({...makeOffer, offerAmount:e.target.value})} className="form-control input-modal" type="text"/>
            </div>
           {!isNumeric(makeOffer.offerAmount) && makeOffer.offerAmount!=="" ? <p className="required">You've entered an invalid price</p>
           : isNumeric(makeOffer.offerAmount) && makeOffer.offerAmount!=="" && (makeOffer?.buyNowPrice && makeOffer?.buyNowPrice < Number(makeOffer.offerAmount) ) && <p className="required">Your offer is higher than the Buying Price.</p>}
            {/* <div className="form-group spacer-feedback-label">
              <label className="label-modal">Message (optional)</label>
              <textarea
                className="form-control buyer-textarea input-modal"
                rows={3}
                maxLength={500}
                placeholder="write some thing"
                value={
                  makeOffer.message !== null
                    ? makeOffer.message
                    : ""
                }
                onChange={(e) =>
                  setMakeOffer({
                    ...makeOffer,
                    message: e.target.value !== "" ? e.target.value : null,
                  })
                }
              ></textarea>
              <p>
                {makeOffer.message === null
                  ? 0
                  : makeOffer?.message?.length}
                /500
              </p>
            </div> */}

            <div className="modal-btn-group spacer-feedback-btn">
              <button
                className={`${ makeOffer.offerAmount === "" || !isNumeric(makeOffer.offerAmount) 
                || 
                
                 ( makeOffer?.buyNowPrice && makeOffer?.buyNowPrice < Number(makeOffer.offerAmount)  )

                ? "modal-cancel-btn" : "modal-add-btn "}  `}
                onClick={() => {
                 handleSubmit()
                 
                }}
                disabled={
                 
                  makeOffer.offerAmount === "" || !isNumeric(makeOffer.offerAmount) ||  

                  makeOffer?.buyNowPrice && makeOffer?.buyNowPrice < Number(makeOffer.offerAmount) 
                    ? true
                    : false
                }
              >
                Continue
              </button>
              <button
                className="modal-cancel-btn ml-3"
                onClick={() =>
                  setMakeOffer({
                    ...makeOffer,
                    visible: "none",
                   
                    quantity:0,
                    buyNowPrice:0,
                    offerId:0,
                  
                    offerAmount:"",
                  })
                }
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MakeCounterOfferModel;
