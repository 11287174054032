import React from "react";
import { Navigate } from "react-router-dom";

const RequireAuth = ({ children }: any) => {
  const accessToken = localStorage.getItem("authorization");
  if (accessToken == null) return <Navigate to="/home" />;
  return children;
};

export default RequireAuth;
