import { AppThunk } from "../../store";
import { reset } from "./slice";

export const resetVerifyOtp = (): AppThunk => async (dispatch) => {
  try {
    dispatch(reset());
  } catch (err) {
    
  }
};
