import React, { useEffect, useRef, useState } from "react";
import HeaderNormal from "../../../../components/header/HeaderNormal";
import FooterNormal from "../../../../components/footer/FooterNormal";
import { DefaultLayout } from "../../../../layout/DefaultLayout";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../utils/types";
import { saveStore } from "../../../../modules/sellerStore/saveStore";
import { notify } from "../../../../modules/notification/notify";
import { reset } from "../../../../modules/sellerStore/reset";
import { constant } from "../../../../utils/constant";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { sellerStoreResponseSelector } from "../../../../modules/sellerStore/selectors";
import { useNavigate } from "react-router-dom";
import BuyerFooter from "../../../buyer/buyerFooter";

export const StoreInfo = (props: any) => {
  interface storeInfoValues {
    storeName: string;
    description: string;
  }

  const [storeInfoData, setStoreInfoData] = useState<storeInfoValues>({
    storeName: "",
    description: "",
  });
  const StoreInfoResponse = useSelector<RootState, RootState["sellerStore"]>(
    sellerStoreResponseSelector
  );
  const onChange = (e: any) => {
    setStoreInfoData({
      ...storeInfoData,
      [e.target.name]: e.target.value,
    });
  };
  const storeInfoSchema = Yup.object().shape({
    storeName: Yup.string().required("*Required!"),
    description: Yup.string().required("*Required!"),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitFormData = (values: any) => {
    const formData = new FormData();
    formData.append("store", JSON.stringify(values));
    dispatch<any>(saveStore(formData));
  };

  useEffect(() => {
    // dispatch<any>(EndProgress());
    if (StoreInfoResponse.saveData != null) {
      if (StoreInfoResponse.saveData.success == "Y") {
        notify(StoreInfoResponse.saveData.message, constant.SUCCESS);
        localStorage.setItem("storeNameLinked", "true");

        navigate("/seller/store-add", { replace: true });
      } else if (StoreInfoResponse.saveData.success == "N") {
        notify(StoreInfoResponse.saveData.message, constant.ERROR);
      }

      // dispatch<any>(resetBanner());
    }
    if (StoreInfoResponse.saveDataError != null) {
      notify(StoreInfoResponse.saveDataError, "error");
    }
    dispatch<any>(reset());
  }, [StoreInfoResponse.saveData]);
  return (
    <DefaultLayout>
      <HeaderNormal />
      <ToastContainer />
      <section className="login-section min-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="login-container">
                <Formik
                  initialValues={storeInfoData}
                  validationSchema={storeInfoSchema}
                  enableReinitialize={true}
                  onSubmit={(values) => {
                    submitFormData(values);
                  }}
                >
                  {({ handleSubmit, errors, touched }) => (
                    <>
                      <Form>
                        <div className="form-block">
                          <h2 className="h2-head text-center">
                            Your Store Details
                          </h2>

                          <div className="signin-text text-center">
                            Please enter your Store Information
                          </div>

                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control login-input"
                              placeholder="Store Name"
                              name="storeName"
                              onChange={onChange}
                              value={storeInfoData.storeName}
                            />
                            {errors.storeName && touched.storeName ? (
                              <div className="invalid-feedback d-block pb-0">
                                {errors.storeName}
                              </div>
                            ) : null}
                          </div>

                          <div className="form-group">
                            <textarea
                              className="form-control login-input store-address"
                              placeholder="Store Description"
                              name="description"
                              onChange={onChange}
                              value={storeInfoData.description}
                            ></textarea>

                            {errors.description && touched.description ? (
                              <div className="invalid-feedback d-block pb-0">
                                {errors.description}
                              </div>
                            ) : null}
                          </div>

                          <button
                            type="submit"
                            className="btn btn-primary login-btn form-button"
                          >
                            Continue
                          </button>
                        </div>
                      </Form>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BuyerFooter />
    </DefaultLayout>
  );
};
