import { BASE_URLS } from "./api.urls";
import DefaultImage from "../assets/images/defaultImage.png";



const getWebPImage = (imageUrl: any) => {
  if(imageUrl)
 { const imageName = imageUrl.split(".");
  let webPImage = BASE_URLS.AWS_URL +"/Thumb"+imageName[0]+".webp";  
  return webPImage}
  
  return "";
};

const getWebPImageSavedSearch = (imageUrl: any) => {
  if(imageUrl)
  {
  const imageName = imageUrl.split(".");
  let webPImage = BASE_URLS.AWS_URL + imageName[0]+".webp";  
  return webPImage
}
  
return "";
};

const imageOnError = (event: any) => {
  event.error = null;
  event.currentTarget.parentNode.children[0].srcset = DefaultImage;
  event.currentTarget.src = DefaultImage;
};


export {getWebPImage, getWebPImageSavedSearch, imageOnError};