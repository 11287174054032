import { AppThunk } from "../../../../store"
import {
    ActivateUserSuccess,
    ActivateUserFailed
} from "./slice"
import { activateUserApi } from "./api";
import { EndProgress } from "../../../progressBar/endProgress";
import { StartProgress } from "../../../progressBar/startProgress";



export const activateUser= (data: any): AppThunk => async (dispatch) => {

    dispatch<any>(StartProgress());
    try {

        const bannerData: any = await activateUserApi(data)

        if (bannerData.success === "N") {
            dispatch(ActivateUserFailed(bannerData));
        }
        else {
            dispatch(ActivateUserSuccess(bannerData));
        }

    } catch (err) {
        dispatch(ActivateUserFailed(err));
    }
    dispatch<any>(EndProgress());
}