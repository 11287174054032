import { AppThunk } from "../../store"
import { _OnSuccess, _OnFailed } from "./slice"
import { fetchReturnOrderTrackingDetails_Seller } from "./api";
import { Sellers } from "../../utils/types";

export const getReturnOrderTrackingDetails_Seller = (data: number): AppThunk => async (dispatch) => {
    try {
        const getData: Sellers['trackingDetails'] = await fetchReturnOrderTrackingDetails_Seller(data);
        
        if (getData.success === "N") {
            dispatch(_OnFailed(getData));
        }else {
            dispatch(_OnSuccess({...getData,isTracking:true}));
        }
        
    } catch (err) {
        dispatch(_OnFailed(err));
    }
}