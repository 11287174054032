import { AppThunk } from "../../store";
import { MobileOtp } from "../../utils/types";
import { ConfirmEmailSuccess, ConfirmEmailFailed } from "./slice";
import { confirmEmail } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const verifyEmail =
  (Data: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch<any>(StartProgress());

      const UserData: any = await confirmEmail(Data);
      if (UserData.success === "N") {
        dispatch(ConfirmEmailFailed(UserData));
      } else dispatch(ConfirmEmailSuccess(UserData));
    } catch (err) {
      dispatch(ConfirmEmailFailed(err));
    }
    dispatch<any>(EndProgress());
  };
