import { AppThunk } from "../../../store";
import { SuggestionFailed, SuggestionSuccess } from "./slice";
import { fetchSuggestion } from "./api";
import { EndProgress } from "../../progressBar/endProgress";
import { StartProgress } from "../../progressBar/startProgress";

export const getSuggestion =
  (params: any): AppThunk =>
  async (dispatch) => {
    try {
      const data: any = await fetchSuggestion(params);
      

      if (data.success === "N") {
        
        dispatch(SuggestionFailed(data));
      } else {
        
        dispatch(SuggestionSuccess(data));
      }
    } catch (err) {
      
      dispatch(SuggestionFailed(err));
    }
  };
