import { toast } from "react-toastify"
import './notify.css'; 
import MyIcon from "../../assets/images/icon-sucess.png"
export const notify = (message: string, type: string ,id="") => {
    if(id !== '')
    {   
        if (type === 'success') {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
            icon: ({theme, type}) =>  <img src={MyIcon}/>,
            toastId: id
        })
    }
    else {
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
            toastId: id
        })
    }
    
    }
   
    else if(type === 'duplicateError')
    {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
            icon: ({theme, type}) =>  <img src={MyIcon}/>,
            toastId: "1234"
        })
    }
    else  if (type === 'success') {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
            icon: ({theme, type}) =>  <img src={MyIcon}/>
        })

    } 
    else {
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT
        })
    }
   
}