import { AppThunk } from "../../store";
import { VerificationEmailFailed, VerificationEmailSuccess } from "./slice";
import { deleteRefreshToken, sendVerificationEmail } from "./api";
import { EndProgress } from "../progressBar/endProgress";
import { StartProgress } from "../progressBar/startProgress";
// import { EndProgress } from "../progressBar/endProgress";
// import { StartProgress } from "../progressBar/startProgress";

export const resendVerificationLink =
  (data: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
      const UserData: any = await sendVerificationEmail(data);

      if (UserData.success === "N") {
        dispatch(VerificationEmailFailed(UserData));
      } else {
        dispatch(VerificationEmailSuccess(UserData));
      }
    } catch (err) {
      dispatch(VerificationEmailFailed(err));
    }
    dispatch<any>(EndProgress());
  };
