import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Messages } from "../../../../utils/types";

type messageState = {
  allMessages: Messages["allMessages"] | null;
  saveData: Messages["saveData"] | null;

  messagesCount: Messages["messagesCount"] | null;
  messagesCountError: any | null;

  error: any | null;
};

const initialState: messageState = {
  allMessages: null,
  saveData: null,
  messagesCount:null,

  messagesCountError:null,
  error: null,
};
const slice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    resetMessage: (state) => {
      
      state.saveData = null;
      state.error = null;
    },
    MessagesSuccess: (state, action: PayloadAction<any>) => {
      state.allMessages = action.payload;
      state.error = null;
      
    },
    MessagesFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.allMessages = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.error = errorMessage;
    },

    MessagesCountSuccess: (state, action: PayloadAction<any>) => {
      state.messagesCount = action.payload;
      state.messagesCountError = null;
      
    },
    MessagesCountFailed: (state, action: PayloadAction<any>) => {
      state.messagesCountError = action.payload;
      state.messagesCount = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.messagesCountError = errorMessage;
    },
    HandleMessageSuccess: (state, action: PayloadAction<any>) => {
      state.saveData = action.payload;
      state.error = null;
      
    },
    HandleMessageFailed: (state, action: PayloadAction<any>) => {
      
      
      state.error = action.payload;
      state.saveData = action.payload;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
  },
});

export const messages = slice.reducer;

export const {
  resetMessage,
    MessagesSuccess,
    MessagesFailed,
    HandleMessageSuccess,
    HandleMessageFailed,
    MessagesCountFailed,
    MessagesCountSuccess
} = slice.actions;
