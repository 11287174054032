import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Account } from "../../../../utils/buyer.types";

type accountState = {
  profile: Account["profile"] | null;
  error: any | null;
  errors: any | null;
  saveData: Account["saveData"] | null;
  payments: Account["payments"] | null;
  paymentsErrror:string | null;
  deactivateUser: Account["deactivateUser"] | null;
  deactivateUserError:string | null;
  activateUser: Account["activateUser"] | null;
  activateUserError:string | null;

};

const initialState: accountState = {
    profile: null,
    error: null,
    errors: null,
    saveData: null,
    payments:null,
    paymentsErrror:null,
    deactivateUser:null,
    deactivateUserError:null,
    activateUser:null,
    activateUserError:null,
};
const slice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetAccount: (state) => {
      
      state.saveData = null;
      state.error = null;
      state.errors = null;
    },
    resetDeactiveUser: (state) => {
      
      state.deactivateUser = null;
      state.deactivateUserError = null;
    },
    resetActiveUser: (state) => {
      state.activateUser = null;
      state.activateUserError = null;
    },
    ProfileSuccess: (state, action: PayloadAction<any>) => {
      state.profile = action.payload;
      state.error = null;
    },
    ProfileFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.profile = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.error = errorMessage;
    },
    PaymentsSuccess: (state, action: PayloadAction<any>) => {
      state.payments = action.payload;
      state.paymentsErrror = null;
      
    },
    PaymentsFailed: (state, action: PayloadAction<any>) => {
      state.paymentsErrror = action.payload;
      state.payments = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.paymentsErrror = errorMessage;
    },
    DeactivateUserSuccess: (state, action: PayloadAction<any>) => {
      state.deactivateUser = action.payload;
      state.deactivateUserError = null;
      
    },
    DeactivateUserFailed: (state, action: PayloadAction<any>) => {
      // state.deactivateUserErrror = action.payload;
      state.deactivateUser = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.deactivateUserError = errorMessage;
    },
    ActivateUserSuccess: (state, action: PayloadAction<any>) => {
      state.activateUser = action.payload;
      state.activateUserError = null;
      
    },
    ActivateUserFailed: (state, action: PayloadAction<any>) => {
      // state.activateUserErrror = action.payload;
      state.activateUser = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.activateUserError = errorMessage;
    },
    SaveUserNameSuccess: (state, action: PayloadAction<any>) => {
      state.saveData = action.payload;
      state.error = null;
      
    },
    SaveUserNameFailed: (state, action: PayloadAction<any>) => {
      
      
      state.error = action.payload;
      state.saveData = action.payload;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
    SaveMobileNoSuccess: (state, action: PayloadAction<any>) => {
      state.saveData = action.payload;
      state.error = null;
      
    },
    SaveMobileNoFailed: (state, action: PayloadAction<any>) => {
      
      
      state.error = action.payload;
      state.saveData = action.payload;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
      
      // state.errors=action.payload.response.data.errors;
    },
    SaveDefaultAddressSuccess: (state, action: PayloadAction<any>) => {
      state.saveData = action.payload;
      state.error = null;
      
    },
    SaveDefaultAddressFailed: (state, action: PayloadAction<any>) => {
      
      
      state.error = action.payload;
      state.saveData = action.payload;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
    SaveProfileAddressSuccess: (state, action: PayloadAction<any>) => {
      state.saveData = action.payload;
      state.error = null;
      
    },
    SaveProfileAddressFailed: (state, action: PayloadAction<any>) => {
      
      
      state.error = action.payload;
      state.saveData = action.payload;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
    SaveProfileAddressError: (state, action: PayloadAction<any>) => {
      
      
      state.errors = action.payload.response.data.errors;
    },
    SaveShippingAddressSuccess: (state, action: PayloadAction<any>) => {
      state.saveData = action.payload;
      state.error = null;
      
    },
    SaveShippingAddressFailed: (state, action: PayloadAction<any>) => {
      
      
      state.error = action.payload;
      state.saveData = action.payload;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
    SaveShippingAddressError: (state, action: PayloadAction<any>) => {
      
      
      state.errors = action.payload.response.data.errors;
    },
    DeleteShippingAddressSuccess: (state, action: PayloadAction<any>) => {
      state.saveData = action.payload;
      state.error = null;
      
    },
    DeleteShippingAddressFailed: (state, action: PayloadAction<any>) => {
      
      
      state.error = action.payload;
      state.saveData = action.payload;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
    DeleteSavedPaymentsSuccess: (state, action: PayloadAction<any>) => {
      state.saveData = action.payload;
      state.error = null;
      
    },
    DeleteSavedPaymentsFailed: (state, action: PayloadAction<any>) => {
      
      
      state.error = action.payload;
      state.saveData = action.payload;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
  },
});

export const account = slice.reducer;

export const { ProfileSuccess, 
  ProfileFailed, 
  SaveUserNameSuccess, 
  SaveUserNameFailed, 
  SaveMobileNoSuccess, 
  SaveMobileNoFailed, 
  SaveProfileAddressSuccess, 
  SaveProfileAddressFailed, 
  SaveProfileAddressError,
  SaveShippingAddressSuccess, 
  SaveShippingAddressFailed,
  SaveShippingAddressError,
  DeleteShippingAddressSuccess, 
  DeleteShippingAddressFailed, 
  SaveDefaultAddressFailed,
  SaveDefaultAddressSuccess,
  resetAccount,
  PaymentsFailed,
  PaymentsSuccess,
  DeleteSavedPaymentsFailed,
  DeleteSavedPaymentsSuccess,
  DeactivateUserFailed,
  DeactivateUserSuccess,
  resetDeactiveUser,
  ActivateUserFailed,
  ActivateUserSuccess,
  resetActiveUser
 } =
  slice.actions;
