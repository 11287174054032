import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import { DefaultLayout } from "../../../layout/DefaultLayout";
import {
  resetCategoryData,
  resetCategorySuccess,
} from "../../../modules/categories/slice";
import { getSellerProductDetails } from "../../../modules/sellerProduct/getSellerProductDetails";
import { reset } from "../../../modules/sellerProduct/reset";
import CreateListing from "./CreateListing";
import SellFaster from "./SellFaster";
import SellingDetails from "./SellingDetails";
import ShippingDetails from "./ShippingDetails";

const Listing = () => {
  const dispatch = useDispatch();
  const isFirstRender = useRef(false);

  const [step, setstep] = useState(1);
  const [productId, setProductId] = useState<string | null>(
    localStorage.getItem("productId") !== null
      ? localStorage.getItem("productId")
      : ""
  );
  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setstep(step - 1);
  };
  useEffect(() => {
    if (!isFirstRender.current) {
      if (localStorage.getItem("productId") !== null)
        dispatch<any>(reset()).then(dispatch<any>(resetCategoryData()));
      if (
        localStorage.getItem("productId") !== "" &&
        localStorage.getItem("productId") !== null
      )
        dispatch<any>(
          getSellerProductDetails(localStorage.getItem("productId"))
        );
    }
    isFirstRender.current = true;
  }, []);
  // useEffect(() => {
  //   setProductId(
  //     localStorage.getItem("productId") !== null
  //       ? localStorage.getItem("productId")
  //       : ""
  //   );
  // }, [localStorage.getItem("productId")]);
  return (
    <DefaultLayout>
      <Header />
      {/* breadcrumb */}
      <section className="seller-breadcrumb-block">
        <div className="container">
          <ul>
            <li className="text">Create Your Listing</li>
            <li className="pipe">|</li>
            <li className="text-link">
              <Link to={"/seller/overview"}>
                {localStorage.getItem("UserName")} (225)
              </Link>
            </li>
          </ul>
        </div>
      </section>
      <main className="seller-page-section">
        <div className="container">
          <div className="tabs-nav-block">
            {(() => {
              switch (step) {
                case 1:
                  return (
                    <CreateListing
                      nextStep={nextStep}
                      setProductId={setProductId}
                      productId={productId}
                      setstep={setstep}
                    />
                  );
                case 2:
                  return (
                    <SellingDetails
                      nextStep={nextStep}
                      setProductId={setProductId}
                      productId={productId}
                      prevStep={prevStep}
                      setstep={setstep}
                    />
                  );
                case 3:
                  return (
                    <ShippingDetails
                      nextStep={nextStep}
                      setProductId={setProductId}
                      productId={productId}
                      prevStep={prevStep}
                      setstep={setstep}
                    />
                  );
                case 4:
                  return (
                    <SellFaster
                      nextStep={nextStep}
                      setProductId={setProductId}
                      productId={productId}
                      prevStep={prevStep}
                      setstep={setstep}
                    />
                  );
              }
            })()}
          </div>
        </div>
      </main>
      <Footer />
    </DefaultLayout>
  );
};

export default Listing;
