import React from "react";

function FooterNormal() {
  return (
    <footer>
      <div className="footer-bottom">
        <p className="text-center">
          Copyright &copy;2023 efindit. All rights reserved
        </p>
      </div>
    </footer>
  );
}
export default FooterNormal;
