import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface Props {
  currentPage: string;
}
const OrderNavPanel = ({ currentPage }: Props) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const toggleSubMenu = () => { setIsSubMenuOpen(!isSubMenuOpen); };

  useEffect(() =>{
    if(currentPage == "RetunOrderShipmentPaidBySeller" || currentPage == "RetunOrder"){
      setIsSubMenuOpen(true);
    }
  },[currentPage])

  return (
    <div className="col-md-2">
      <div className="leftNavBar">
        <ul>
          <li> <Link style={currentPage == "AllOrder" ? { background: "#d7d7d7", fontWeight: "450", color: "#000" } : {}} to={"/seller/orders"}> All orders</Link> </li>
          <li> <Link style={ currentPage == "AwaitingShip" ? { background: "#d7d7d7", fontWeight: "450", color: "#000" } : {} } to={"/seller/awaitingShip"}> Awaiting shipment</Link> </li>
          <li> <Link style={ currentPage == "PaidShipped" ? { background: "#d7d7d7", fontWeight: "450", color: "#000"  } : {} } to={"/seller/orderPaidShipped"}>Paid and shipped</Link> </li>
          <li> <Link style={currentPage == "Delivered" ? { background: "#d7d7d7", fontWeight: "450", color: "#000"  } : {}} to={"/seller/deliveredOrder"}>Delivered</Link> </li>
        </ul>

        <ul>
          <li> <Link style={ currentPage == "Cancellation" ? { background: "#d7d7d7", fontWeight: "450", color: "#000" } : {} } to={"/seller/orderCancellation"}>Cancellations</Link> </li>
          <li> 
            <Link style={(currentPage == "RetunOrder" || currentPage == "RetunOrderShipmentPaidBySeller") ? { background: "#d7d7d7", fontWeight: "450", color: "#000" } : {}} to={"/seller/orderReturn"} onClick={toggleSubMenu}>
              Returns {!isSubMenuOpen  ? '▲' : '▼'}
            </Link> 
            {isSubMenuOpen &&
              (
                <ul className="sub-menu ml-3 mt-3">
                  <li><Link style={currentPage == "RetunOrderShipmentPaidBySeller" ? { background: "#d7d7d7", fontWeight: "450", color: "#000" } : {}} to= "/seller/orderReturn/shipmentPaidBySeller">Paid by seller</Link></li>
                </ul>
              )
            }
          </li>
          {/*  <li> <a href="#">Request and Disputes</a> </li> */}
        </ul>

        {/* 
          <ul>
            <li> <a href="#">Return Preferences</a> </li>
            <li> <a href="#">Learning Resources</a> </li>
          </ul> 
        */}
      </div>
    </div>
  );
};

export default OrderNavPanel;
