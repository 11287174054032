import { AppThunk } from "../../store";
import {  DealsAddedBySellerFailed, DealsAddedBySellerSuccess } from "./slice";
import {  fetchDealsNameSeller } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const getDealsNameSeller =
  (): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
      const productData: any = await fetchDealsNameSeller();

      if (productData.success === "N") {
        dispatch(DealsAddedBySellerFailed(productData));
      } else {
        dispatch(DealsAddedBySellerSuccess(productData));
      }
    } catch (err) {
      dispatch(DealsAddedBySellerFailed(err));
    }
    dispatch<any>(EndProgress());
  };
