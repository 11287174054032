import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Header from "../../../components/header/Header";
import { DefaultLayout } from "../../../layout/DefaultLayout";
import { RootState } from "../../../utils/types";
import { ToastContainer } from "react-toastify";
import { getOrderDetail } from "../../../modules/orders/getOrderDetail";
import { SellerOrdersResponseSelector } from "../../../modules/orders/selectors";
import { BASE_URLS } from "../../../utils/api.urls";
import ContactBuyer from "./ContactBuyer";
import OrderFeedback from "./OrderFeedback";
import { CommonResponseSelector } from "../../../modules/common/selectors";
import { notify } from "../../../modules/notification/notify";
import { constant } from "../../../utils/constant";
import { resetOrder } from "../../../modules/orders/resetOrder";
import "react-toastify/dist/ReactToastify.css";
import { getCancelOrderDetail } from "../../../modules/orders/getCancelOrderDetail";
import { dateToLocal } from "../../../modules/date/date";
import { getCommonData } from "../../../modules/common/getCommonData";
import Footer from "../../../components/footer/Footer";

const CancelOrderDetail = () => {
  const { state } = useLocation();
  const orderID: any = state;
  const isFirstRender = useRef(true);
  const dispatch = useDispatch();
  let order = useSelector<RootState, RootState["sellerOrders"]>(
    SellerOrdersResponseSelector
  );

  const commonResponse = useSelector<RootState, RootState["common"]>(
    CommonResponseSelector
  );
  useEffect(() => {
    if (isFirstRender.current) {
      dispatch<any>(resetOrder("details")).then(
        dispatch<any>(getCancelOrderDetail({ orderId: state }))
      );
      if (commonResponse?.common === null) dispatch<any>(getCommonData());
    }

    isFirstRender.current = false;
  }, [state]);

  interface feedbackValues {
    visible: string;
    orderId: string;
    definedMessage: string | null;
    message: string;
  }
  interface ContactBuyerValues {
    message: string;
    orderId: string;
    sendCopy: boolean;
    visible: string;
  }

  const [contactBuyer, setContactBuyer] = useState<ContactBuyerValues>({
    visible: "none",
    orderId: "",
    message: "",
    sendCopy: false,
  });

  const [feedback, setFeedback] = useState<feedbackValues>({
    visible: "none",
    orderId: "",
    definedMessage: null,
    message: "",
  });

  useEffect(() => {
    if (order.saveData != null) {
      if (order.saveData.success == "Y") {
        notify(order.saveData.message, constant.SUCCESS);
      } else if (order.saveData.success == "N") {
        notify(order.saveData.message, constant.ERROR);
      } else if (order.saveDataError !== null)
        notify("PreDefinedMessage or Message is required", constant.ERROR);
      dispatch<any>(resetOrder());
      setFeedback({
        visible: "none",
        orderId: "",
        definedMessage: null,
        message: "",
      });

      setContactBuyer({
        visible: "none",
        orderId: "",
        message: "",
        sendCopy: false,
      });
    }
  }, [order.saveData, order.saveDataError]);
  return (
    <DefaultLayout>
      <Header />
      <ToastContainer />
      <ContactBuyer
        contactBuyer={contactBuyer}
        setContactBuyer={setContactBuyer}
      />

      {/* <OrderFeedback
        feedback={feedback}
        setFeedback={setFeedback}
        commonResponse={commonResponse}
      /> */}
      <section className="seller-breadcrumb-links">
        <div className="container">
          <ul>
            <li>
              <Link to={"/seller/overview"}>eFindit</Link>
            </li>
            <li className="spacer">{">"}</li>
            <li>
              <Link to={"/seller/orders"}>orders</Link>
            </li>
            <li className="spacer">{">"}</li>
            <li className="active">Order detail</li>
          </ul>
        </div>
      </section>

      <main className="seller-page-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="order-detail-heading">
                <h2 className="seller-head-style">
                  Cancelled order -(Order number {orderID}){" "}
                </h2>
              </div>
              <div className="order-detail-block">
                <div className="order-id-style">
                  <p>
                    Bought by:
                    <span className="order-estimate-block">
                      {order?.cancelOrderDetail?.data?.buyerName}
                    </span>
                  </p>
                </div>
                <div className="order-detail-block-inner cancel-order-estimate">
                  <div className="order-detail-section-l">
                    <div className="order-estimate-block">
                      {/* Estimate delivery{" "}
                      <strong>
                    
                      </strong> */}
                      {/* <table className="order-item-table">
                        <tr>
                          <td>
                            <label className="order-item-label">
                              Order Cancelled
                            </label>
                            By {order?.cancelOrderDetail?.data?.buyerName}
                          </td>
                          <td>
                            <label className="order-item-label">Price</label>
                          </td>
                          <td>
                            <label className="order-item-label">Quantity</label>
                          </td>
                        </tr>
                      </table> */}
                      <p>
                        <span style={{ color: "red" }}> Order cancelled</span>
                        <span style={{ color: "black" }}> by </span>
                        {order?.cancelOrderDetail?.data?.cancelledBy}{" "}
                        <span style={{ color: "black" }}>
                          | Requested On{" "}
                          {dateToLocal(
                            order?.cancelOrderDetail?.data?.cancelledDate
                          )}{" "}
                          | Status{" "}
                        </span>
                        {order?.cancelOrderDetail?.data?.status}
                      </p>
                    </div>
                    <div className="order-estimate-product">
                      <table className="table-order-detail">
                        <tbody>
                          <tr>
                            <td valign="top">
                              <img
                                src={
                                  BASE_URLS.AWS_URL +
                                  "/Thumb" +
                                  order?.cancelOrderDetail?.data?.productImage
                                }
                                style={{
                                  height: 60,
                                  width: 60,
                                }}
                                alt="product"
                              />
                            </td>
                            <td className="order-product-td">
                              <label className="order-product-label">
                                {order?.cancelOrderDetail?.data?.productName}
                              </label>
                              <p className="order-product-text">
                                condition:{" "}
                                {order?.cancelOrderDetail?.data?.condition}
                              </p>
                              <table className="order-item-table">
                                <tr>
                                  <td>
                                    <label className="order-item-label">
                                      Order Id
                                    </label>
                                    <p className="order-item-text">{orderID}</p>
                                  </td>
                                  <td>
                                    <label className="order-item-label">
                                      Price
                                    </label>
                                    <p className="order-item-text">
                                      $ {order?.cancelOrderDetail?.data?.price}
                                    </p>
                                  </td>
                                  <td>
                                    <label className="order-item-label">
                                      Quantity
                                    </label>
                                    <p className="order-item-text">
                                      {order?.cancelOrderDetail?.data?.quantity}
                                    </p>
                                  </td>
                                </tr>
                                <tr className="order-estimate-block">
                                  Reason for cancel
                                </tr>
                                <tr className="order-item-label">
                                  {order?.cancelOrderDetail?.data?.reason}
                                  {
                                    order?.cancelOrderDetail?.data
                                      ?.cancelledDescription
                                  }
                                </tr>
                              </table>
                            </td>
                            <td align="right" valign="top">
                              <div className="order-button-block">
                                {/* <button
                                  className="list-button"
                                  onClick={() =>
                                    setFeedback({
                                      ...feedback,
                                      visible: "block",
                                      orderId: orderID,
                                    })
                                  }
                                >
                                  Leave feedback
                                </button> */}

                                <button
                                  className="list-button bg-none"
                                  onClick={() =>
                                    setContactBuyer({
                                      ...contactBuyer,
                                      visible: "block",
                                      orderId: orderID,
                                    })
                                  }
                                >
                                  Contact buyer
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="order-detail-section-r">
                    <div className="delivery-add-block">
                      <div className="delivery-add-title">Delivery address</div>
                      <div className="delivery-small-text">
                        {" "}
                        {order?.cancelOrderDetail?.data?.buyerName}
                      </div>
                      <div className="delivery-add-text">
                        {order?.cancelOrderDetail?.data?.address1},
                        <br /> {order?.cancelOrderDetail?.data?.address2}
                        <br /> {order?.cancelOrderDetail?.data?.zipCode}
                        <br /> {order?.cancelOrderDetail?.data?.city}
                        <br /> {order?.cancelOrderDetail?.data?.state}
                        <br /> {order?.cancelOrderDetail?.data?.country}
                        <br /> {order?.cancelOrderDetail?.data?.callingCode}-
                        {order?.cancelOrderDetail?.data?.mobileNo}
                      </div>
                    </div>

                    <div className="order-total-block">
                      <div className="delivery-add-title order-total-btm">
                        Total order
                      </div>
                      <div className="order-total-row border-btm">
                        <div className="order-total-col-l">Total order</div>
                        <div className="order-total-col-r">
                          ${" "}
                          {order?.cancelOrderDetail?.data?.price *
                            order?.cancelOrderDetail?.data?.quantity}
                        </div>
                      </div>
                      <div className="order-total-row border-btm">
                        <div className="order-total-col-l">Amount refunded</div>
                        <div className="order-total-col-r">
                          ${" "}
                          {order?.cancelOrderDetail?.data?.price *
                            order?.cancelOrderDetail?.data?.quantity}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </DefaultLayout>
  );
};

export default CancelOrderDetail;
