import Api from "../../../utils/api";
import { API_URLS } from "../../../utils/api.urls";
const d = new Date();
let TimeZone = d.getTimezoneOffset() * -1;
export const fetchSuggestion = async (params: any): Promise<any> => {
  const data = await Api.get(
    API_URLS.GET_SUGGESTION + "Keyword=" + params + "&Limit=5",
    []
  );
  return data;
};

export const postRecentlyViewed = async (params: any): Promise<any> => {
  const data = await Api.post(API_URLS.RECENTLY_VIEWED, params);
  return data;
};
export const postStoreTraffic = async (params: any): Promise<any> => {
  const data = await Api.post(API_URLS.STORE_TRAFFIC, params);
  return data;
};

export const postWatchlist = async (params: any): Promise<any> => {
  const data = await Api.post(API_URLS.BUYER.GET_WATCHLIST, params);
  return data;
};
export const postToCart = async (params: any): Promise<any> => {
  const data = await Api.post(API_URLS.ADD_TO_CART, params);
  return data;
};
export const postToSaveSearch = async (params: any): Promise<any> => {
  const data = await Api.post(API_URLS.SAVED_SEARCH, params);
  return data;
};
export const deleteSaveSearch = async (params: any): Promise<any> => {
  const data = await Api.delete(API_URLS.DELETE_SAVED_SEARCH +"/"+params, []);
  return data;
};

export const deleteAllSaveSearch = async (): Promise<any> => {
  const data = await Api.delete(API_URLS.DELETE_ALL_SAVED_SEARCH , []);
  return data;
};

export const fetchSaveSearch = async (params:any): Promise<any> => {
  const data = await Api.get(API_URLS.GET_SAVED_SEARCH  +  "PageNumber=" +
  params.currentPage +
  "&TotalRows=" +
  params.rows,[]);
  return data;
};
export const removeFromCart = async (params: any): Promise<any> => {
  const data = await Api.delete(API_URLS.ADD_TO_CART + "/" + params.id, []);
  return data;
};
export const saveForCart = async (params: any): Promise<any> => {
  const data = await Api.patch(API_URLS.ADD_TO_CART +"/SaveForLater/" + params.id, []);
  return data;
};

export const getSaveForCart = async (): Promise<any> => {
  const data = await Api.get(API_URLS.ADD_TO_CART +"/SaveForLaterItems/" , []);
  return data;
};

export const fetchCart = async (): Promise<any> => {
  const data = await Api.get(API_URLS.ADD_TO_CART, []);
  return data;
};

export const fetchCheckOut = async (params:any): Promise<any> => {
  const data = await Api.post(API_URLS.CHECKOUT, params);
  return data;
};


export const fetchProductCategoryPage = async (params: any): Promise<any> => {
  const data = await Api.get(API_URLS.BUYER.PRODUCT_CATEGORY_PAGE + params, []);
  return data;
};
export const postSaveSeller = async (params: any): Promise<any> => {
  const data = await Api.post(API_URLS.BUYER.SAVED_SELLER, params);
  return data;
};
export const fetchProductDetail = async (params: any): Promise<any> => {
  const data = await Api.get(
    API_URLS.SELLER_PRODUCT + "/ProductsDetails/" + params,
    []
  );
  return data;
};

export const fetchSearchResult = async (params: any): Promise<any> => {
  let newCondition =
    params.filter.new !== "" ? "&New=" + params.filter.new : "";
  let used = params.filter.used !== "" ? "&Used=" + params.filter.used : "";
  let notSpecified =
    params.filter.notSpecified !== ""
      ? "&NotSpecified=" + params.filter.notSpecified
      : "";
  let titleAndDescription =
    params.filter.titleAndDescription !== ""
      ? "&TitleAndDescription=" + params.filter.titleAndDescription
      : "";
  let showItemPriceFrom =
    params.filter.showItemPriceFrom !== ""
      ? "&ShowItemPriceFrom=" + params.filter.showItemPriceFrom
      : "";
  let search =
    params.search && params.search !== "" ? "&Search=" + params.search : "";
  let auction =
    params.filter.auction !== "" ? "&Auction=" + params.filter.auction : "";
  let fixed = params.filter.fixed !== "" ? "&Fixed=" + params.filter.fixed : "";
  let condition =
    params.filter.condition !== ""
      ? "&Condition=" + params.filter.condition
      : "";
  let startPrice =
    params.filter.startPrice !== ""
      ? "&StartPrice=" + params.filter.startPrice
      : "";
  let endPrice =
    params.filter.endPrice !== "" ? "&EndPrice=" + params.filter.endPrice : "";
  let buyingFormat =
    params.filter.buyingFormat !== ""
      ? "&BuyingFormat=" + params.filter.buyingFormat
      : "";
  let minPrice =
    params.filter.minPrice !== "" && Number(params.filter.minPrice) > 0
      ? "&MinPrice=" + params.filter.minPrice
      : "";
  let maxPrice =
    params.filter.maxPrice !== "" && Number(params.filter.maxPrice) > 0
      ? "&MaxPrice=" + params.filter.maxPrice
      : "";
  let soldItems =
    params.filter.soldItems !== ""
      ? "&soldItems=" + params.filter.soldItems
      : "";
  let completedListings =
    params.filter.completedListings !== ""
      ? "&CompletedListings=" + params.filter.completedListings
      : "";
  let noOfBidsFrom =
    params.filter.noOfBidsFrom !== ""
      ? "&NoOfBidsFrom=" + params.filter.noOfBidsFrom
      : "";
  let minBiddingNo =
    params.filter.minBiddingNo !== ""
      ? "&MinBiddingNo=" + params.filter.minBiddingNo
      : "";
  let maxBiddingNo =
    params.filter.maxBiddingNo !== ""
      ? "&MaxBiddingNo=" + params.filter.maxBiddingNo
      : "";
  let categoryId =
    params.filter.categoryId !== ""
      ? "&CategoryId=" + params.filter.categoryId
      : "";
      let sortBy =
    params.filter.shortBy !== ""
      ? "&" + params.filter.shortBy + "=true"
      : "";
  const data = await Api.get(
    API_URLS.SELLER_PRODUCT +
      "/Products?PageNumber=" +
      params.currentPage +
      search +
      auction +
      fixed +
      condition +
      startPrice +
      endPrice +
      categoryId +
      buyingFormat +
      titleAndDescription +
      showItemPriceFrom +
      minPrice +
      maxPrice +
      newCondition +
      used +
      notSpecified +
      soldItems +
      completedListings +
      noOfBidsFrom +
      minBiddingNo +
      maxBiddingNo +
      sortBy+
      "&TotalRows=" +
      params.rows,
    []
  );
  return data;
};

export const fetchProductsSellerStore = async (params: any): Promise<any> => {
  let newCondition =
    params.filter.new1 !== "" ? "&New=" + params.filter.new1 : "";
  let used = params.filter.used1 !== "" ? "&Used=" + params.filter.used1 : "";
  let notSpecified =
    params.filter.notSpecified1 !== ""
      ? "&NotSpecified=" + params.filter.notSpecified1
      : "";
  let titleAndDescription =
  params.filter.titleAndDescription &&  params.filter.titleAndDescription !== ""
      ? "&TitleAndDescription=" + params.filter.titleAndDescription
      : "";
  let showItemPriceFrom =
  params.filter.showItemPriceFrom && params.filter.showItemPriceFrom !== ""
      ? "&ShowItemPriceFrom=" + params.filter.showItemPriceFrom
      : "";
  let search =
    params.filter.search  ? "&Search=" + params.filter.search : "";
  let auction =
    params.filter.auction !== "" ? "&Auction=" + params.filter.auction : "";
  let fixed = params.filter.fixed !== "" ? "&Fixed=" + params.filter.fixed : "";
  let condition =
    params.filter.condition !== ""
      ? "&Condition=" + params.filter.condition
      : "";
  let startPrice =
  params.filter.startPrice &&  params.filter.startPrice !== ""
      ? "&StartPrice=" + params.filter.startPrice
      : "";
  let endPrice =
  params.filter.endPrice && params.filter.endPrice !== "" ? "&EndPrice=" + params.filter.endPrice : "";
  let buyingFormat =
    params.filter.buyingFormat !== ""
      ? "&BuyingFormat=" + params.filter.buyingFormat
      : "";
  let minPrice =
    params.filter.minPrice !== "" && Number(params.filter.minPrice) > 0
      ? "&MinPrice=" + params.filter.minPrice
      : "";
  let maxPrice =
    params.filter.maxPrice !== "" && Number(params.filter.maxPrice) > 0
      ? "&MaxPrice=" + params.filter.maxPrice
      : "";
  let soldItems =
    params.filter.soldItems !== ""
      ? "&soldItems=" + params.filter.soldItems
      : "";
  let completedListings =
    params.filter.completedListings !== ""
      ? "&CompletedListings=" + params.filter.completedListings
      : "";
  let noOfBidsFrom =
    params.filter.noOfBidsFrom !== ""
      ? "&NoOfBidsFrom=" + params.filter.noOfBidsFrom
      : "";
  let minBiddingNo =
    params.filter.minBiddingNo !== ""
      ? "&MinBiddingNo=" + params.filter.minBiddingNo
      : "";
  let maxBiddingNo =
    params.filter.maxBiddingNo !== ""
      ? "&MaxBiddingNo=" + params.filter.maxBiddingNo
      : "";
  let categoryId =
    params.filter.categoryId !== ""
      ? "&CategoryId=" + params.filter.categoryId
      : "";
      let sortBy = params.filter.shortBy !== ""
      ? "&" + params.filter.shortBy + "=true"
      : "";
  const data = await Api.get(
    API_URLS.SELLER_STORE_PRODUCT+params.sellerId +
      "&PageNumber=" +
      params.currentPage +
      search +
      auction +
      fixed +
      condition +
      startPrice +
      endPrice +
      categoryId +
      buyingFormat +
      titleAndDescription +
      showItemPriceFrom +
      minPrice +
      maxPrice +
      newCondition +
      used +
      notSpecified +
      soldItems +
      completedListings +
      noOfBidsFrom +
      minBiddingNo +
      maxBiddingNo +
      sortBy+
      "&TotalRows=" +
      params.rows,
    []
  );
  return data;
};
export const postPlaceBid = async (params: any): Promise<any> => {
  const data = await Api.post(API_URLS.PLACE_BID, params);
  return data;
};
export const postSaveOffer = async (params: any): Promise<any> => {
  const data = await Api.post(API_URLS.OFFERS, params);
  return data;
};

export const postSaveCounterOffer = async (params: any): Promise<any> => {
  const data = await Api.post(API_URLS.COUNTER_OFFER_SELLER, params);
  return data;
};