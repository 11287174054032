import { AppThunk } from "../../store";
import { Buyers } from "../../utils/types";
import { resetOrderSuccess, resetOrders, resetDetails, resetMerchantId, resetAllOrdersReport } from "./slice";

export const resetOrder =
  (data = ""): AppThunk =>
  async (dispatch) => {
    try {
      if (data === "orders") {
        dispatch(resetOrders());
      } else if (data === "details") dispatch(resetDetails());
      else if (data === "merchant") dispatch(resetMerchantId());
      else if (data === "reportAllOrder") dispatch(resetAllOrdersReport());
      else {
        dispatch(resetOrderSuccess());
      }
    } catch (err) {
      
    }
  };
