import React from "react";
import { useDispatch } from "react-redux";
import { setBidNotes } from "../../../../modules/buyer/myEfindit/activity/setBidNotes";
import { setOffersNotes } from "../../../../modules/buyer/myEfindit/activity/setOffersNotes";
import { setSavedSellersNotes } from "../../../../modules/buyer/myEfindit/activity/setSavedSellersNotes";
import { setWatchlistNotes } from "../../../../modules/buyer/myEfindit/activity/setWatchlistNotes";

const NoteBidsOffersModel = ({ note, setNote, checked }: any) => {
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (checked === "Bids")
      dispatch<any>(setBidNotes({ productId: note.Id, note: note.Note }));
    else if (checked === "Offers")
      dispatch<any>(setOffersNotes({ Id: note.Id, note: note.Note }));
    else if (checked === "Watchlist")
      dispatch<any>(
        setWatchlistNotes({ watchListId: note.Id, note: note.Note })
      );
    else if (checked === "SavedSellers")
      dispatch<any>(setSavedSellersNotes({ Id: note.Id, note: note.Note }));
  };

  return (
    <div
      className="modal modal-order-popup"
      id="myModal"
      tabIndex={-1}
      role="dialog"
      style={{ display: note.visible }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="order-modal">
            <div className="order-modal-head border-none">Note</div>

            <div className="form-group spacer-feedback-label">
              <label className="label-modal">
                {" "}
                Write something
              </label>
              <textarea
                className="form-control buyer-textarea input-modal"
                rows={3}
                maxLength={500}
                placeholder="write a note"
                value={note.Note}
                onChange={(e) =>
                  setNote({
                    ...note,
                    Note: e.target.value,
                  })
                }
              ></textarea>
              <p>{note.Note.length}/500</p>
            </div>

            <div className="modal-btn-group spacer-feedback-btn">
              <button
                className="modal-add-btn"
                onClick={() => {
                  handleSubmit();
                  setNote({ ...note, visible: "none" });
                }}
                // disabled={note.Note === "" ? true : false}
              >
                Submit
              </button>
              {/* <button
                className="modal-add-btn modal-cancel-order-btn"
                onClick={() => {
                  // dispatch<any>(deleteOrderNote(note));
                  setNote({ ...note, visible: "none" });
                }}
              >
                Delete
              </button> */}
              <button
                className="modal-cancel-btn"
                onClick={() => setNote({ ...note, visible: "none" })}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteBidsOffersModel;
