import { AppThunk } from "../../../store";
import { PlaceOfferSuccess, PlaceOfferFailed } from "./slice";
import { postPlaceBid, postSaveOffer } from "./api";
import { EndProgress } from "../../progressBar/endProgress";
import { StartProgress } from "../../progressBar/startProgress";

export const saveOffer =
  (params: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());

    try {
      const data: any = await postSaveOffer(params);
      

      if (data.success === "N") {
        
        dispatch(PlaceOfferFailed(data));
      } else {
        
        dispatch(PlaceOfferSuccess(data));
      }
    } catch (err) {
      
      dispatch(PlaceOfferFailed(err));
    }
    dispatch<any>(EndProgress());
  };
