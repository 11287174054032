import Api from "../../utils/api";
import { API_URLS } from "../../utils/api.urls";

export const fetchPaypalLink = async (): Promise<any> => {
  const data = await Api.post(API_URLS.GET_PAYPAL_LINK, []);
  return data;
};
export const removePaypal = async (): Promise<any> => {
  const data = await Api.patch(API_URLS.DELINK_PAYPAL, []);
  return data;
};
export const linkPaypalAccount = async (params: any): Promise<any> => {
  const data = await Api.post(API_URLS.LINK_PAYPAL_ACCOUNT, params);
  return data;
};
