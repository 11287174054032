import matchers from "@testing-library/jest-dom/matchers";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../components/header/Header";
import { DefaultLayout } from "../../../layout/DefaultLayout";
import { getDashboard } from "../../../modules/sellerDashboard/getDashboard";
import { getDashboardFeedbacks } from "../../../modules/sellerDashboard/getDashboardFeedbacks";
import { getDashboardTasks } from "../../../modules/sellerDashboard/getDashboardTasks";
import { DashboardResponseSelector } from "../../../modules/sellerDashboard/selectors";
import { getSales } from "../../../modules/sellerSales/getSales";
import { SalesResponseSelector } from "../../../modules/sellerSales/selectors";
import { getTraffic } from "../../../modules/sellerTraffic/getTraffic";
import { TrafficResponseSelector } from "../../../modules/sellerTraffic/selectors";
import { RootState } from "../../../utils/types";
import { Dashboard } from "../../dashboard/dashboard";
import { Chart } from "react-google-charts";
import { Link, useNavigate } from "react-router-dom";
import { reset } from "../../../modules/sellerProduct/reset";
import SellerBreadCrumbs from "../../../components/sellerBreadCrumbs/SellerBreadCrumbs";
import Footer from "../../../components/footer/Footer";
import { resetCategorySuccess } from "../../../modules/categories/slice";

export const Bidding = () => {
 
  return (
    <DefaultLayout>
      <Header />
      <SellerBreadCrumbs />

      


      <div className="container">
        <div className="groupTabs">
          <ul className="list-unstyled tabList">
            <li className="active"><a href="">Activity</a></li>
            <li><a href="">Messages (5)</a></li>
            <li><a href="">Account</a></li>
          </ul>
          <div className="name">
            <a href="#">Jacob (0)</a>
          </div>
        </div>
      </div>

      <Footer />
    </DefaultLayout>
  );
};
