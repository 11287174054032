import { AppThunk } from "../../store"
import {
  SaveSellingDetailsSuccess,
  SaveSellingDetailsFailed
} from "./slice"
import { postSellingDetails } from "./api"
import { EndProgress } from "../progressBar/endProgress";
import { StartProgress } from "../progressBar/startProgress";


export const saveSellingDetails = (data: any): AppThunk => async (dispatch) => {

  dispatch<any>(StartProgress());
  try {

    const sellingData: any = await postSellingDetails(data)

    if (sellingData.success === "N") {
      
      dispatch(SaveSellingDetailsFailed(sellingData));
    }
    else {
      dispatch(SaveSellingDetailsSuccess(sellingData));
    }

  } catch (err) {
    dispatch(SaveSellingDetailsFailed(err));
  }

  dispatch<any>(EndProgress());
}