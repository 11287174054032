import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Orders, SellerOrders } from "../../utils/types";

type OrdersState = {
  sellerOrders: SellerOrders["sellerOrders"] | null;
  error: string | null;
  searchOrder: Orders["searchOrder"] | null;
  searchOrderError: Orders["searchOrderError"] | null;
  isLoading: Orders["isLoading"];
  buyerNames: SellerOrders["buyerNames"] | null;
  buyerNamesError: string | null;
  trackingManually: SellerOrders["trackingManually"] | null;
  trackingManuallyError: string | null;
  saveData: SellerOrders["saveData"] | null;
  saveDataError: string | null;
  orderDetail: SellerOrders["orderDetail"] | null;
  orderDetailError: string | null;
  cancelOrderDetail: SellerOrders["cancelOrderDetail"] | null;
  cancelOrderDetailError: string | null;
  returnDetail: SellerOrders["returnDetail"] | null;
  returnDetailError: string | null;
  merchantId:SellerOrders["merchantId"] | null;
  sellerOrdersReport: SellerOrders["sellerOrdersReport"] | null;
  sellerOrdersReportError: string | null;
  shippingBuyerDetail: SellerOrders["shippingBuyerDetail"] | null;
  shippingBuyerDetailError: string | null;
  shipmentDetails: SellerOrders["shipmentDetails"] | null;
  shipmentDetailsError: string | null;
  trackingDetails: SellerOrders["trackingDetails"] | null;
  trackingDetailsError: string | null;
  saveReturnBuyer: SellerOrders["saveReturnBuyer"] | null;
  saveReturnBuyerError: string | null;
};

const initialState: OrdersState = {
  sellerOrders: null,
  error: null,
  searchOrder: null,
  searchOrderError: null,
  isLoading: true,
  buyerNames: null,
  buyerNamesError: null,
  trackingManually: null,
  trackingManuallyError: null,
  saveData: null,
  saveDataError: null,
  orderDetail: null,
  orderDetailError: null,
  cancelOrderDetail: null,
  cancelOrderDetailError: null,
  returnDetail: null,
  returnDetailError: null,
  merchantId:null,
  sellerOrdersReport:null,
  sellerOrdersReportError:null,
  shippingBuyerDetail:null,
  shippingBuyerDetailError:null,
  shipmentDetails:null,
  shipmentDetailsError:null,
  trackingDetails:null,
  trackingDetailsError:null,
  saveReturnBuyer:null,
  saveReturnBuyerError:null,
};

const slice = createSlice({
  name: "sellerOrders",
  initialState,
  reducers: {
    setIsLoading: (state) => {
      state.isLoading = true;
    },
    resetOrderSuccess: (state) => {
      
      // state.sellerOrders = null;
      state.trackingManually = null;
      state.trackingManuallyError = null;
      state.saveDataError = null;
      state.saveData = null;
      state.saveReturnBuyer=null;
      state.saveReturnBuyerError=null;
    },
    resetMerchantId: (state) => {
      
      // state.sellerOrders = null;
      state.merchantId = null;
      state.error=null;
     
    },
    resetOrders: (state) => {
      
      state.sellerOrders = null;
      state.error = null;
    },
    resetAllOrdersReport: (state) => {
      
      state.sellerOrdersReport = null;
      state.sellerOrdersReportError = null;
    },
    resetDetails: (state) => {
      
      state.orderDetail = null;
      state.orderDetailError = null;
      state.shipmentDetails=null;
      state.shipmentDetailsError=null;
      state.cancelOrderDetail = null;
      state.cancelOrderDetailError = null;
      state.returnDetail = null;
      state.returnDetailError = null;
    },
    
    OrderSuccess: (state, action: PayloadAction<any>) => {
      
      
      state.sellerOrders = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    OrderFailed: (state, action: PayloadAction<any>) => {
      
      
      state.error = action.payload;
      state.sellerOrders = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
      state.isLoading = false;
    },
    OrderReportSuccess: (state, action: PayloadAction<any>) => {
      
      state.sellerOrdersReport = action.payload;
      state.sellerOrdersReportError = null;
      state.isLoading = false;
    },
    OrderReportFailed: (state, action: PayloadAction<any>) => {
      state.sellerOrdersReportError = action.payload;
      state.sellerOrdersReport = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.sellerOrdersReportError = errorMessage;
    },
    ShipperBuyerDetailSuccess: (state, action: PayloadAction<any>) => {
      
      state.shippingBuyerDetail = action.payload;
      state.shippingBuyerDetailError = null;
    },
    ShipperBuyerDetailFailed: (state, action: PayloadAction<any>) => {
  
      state.shippingBuyerDetail = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.shippingBuyerDetailError = errorMessage;
    },
    OrderDetailSuccess: (state, action: PayloadAction<any>) => {
      
      
      state.orderDetail = action.payload;
      state.orderDetailError = null;
    },
    OrderDetailFailed: (state, action: PayloadAction<any>) => {
      
      
      state.orderDetailError = action.payload;
      state.orderDetail = null;
      let errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.orderDetailError = errorMessage;
    },
    CancelOrderDetailSuccess: (state, action: PayloadAction<any>) => {
      
      
      state.cancelOrderDetail = action.payload;
      state.cancelOrderDetailError = null;
    },
    CancelOrderDetailFailed: (state, action: PayloadAction<any>) => {
      
      
      state.cancelOrderDetailError = action.payload;
      state.cancelOrderDetail = null;
      let errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.cancelOrderDetailError = errorMessage;
    },
    ReturnDetailSuccess: (state, action: PayloadAction<any>) => {
      state.returnDetail = action.payload;
      state.returnDetailError = null;
    },
    ReturnDetailFailed: (state, action: PayloadAction<any>) => {
      state.returnDetailError = action.payload;
      state.returnDetail = null;
      let errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.returnDetailError = errorMessage;
    },
    ShipmentDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.shipmentDetails = action.payload;
      state.shipmentDetailsError = null;
    },
    ShipmentDetailsFailed: (state, action: PayloadAction<any>) => {
      state.shipmentDetailsError = action.payload;
      state.shipmentDetails = null;
      let errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.shipmentDetailsError = errorMessage;
    },
    TrackingDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.trackingDetails = action.payload;
      state.trackingDetailsError = null;
    },
    TrackingDetailsFailed: (state, action: PayloadAction<any>) => {
      state.trackingDetails = null;
      let errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.trackingDetailsError = errorMessage;
    },
    BuyerNameSuccess: (state, action: PayloadAction<any>) => {
      
      
      
      state.buyerNames = action.payload;
      state.buyerNamesError = null;
    },
    BuyerNameFailed: (state, action: PayloadAction<any>) => {
      
      
      
      state.buyerNames = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.buyerNamesError = errorMessage;
    },
    TrackingManuallySuccess: (state, action: PayloadAction<any>) => {
      
      
      
      state.trackingManually = action.payload;
      state.trackingManuallyError = null;
    },
    TrackingManuallyFailed: (state, action: PayloadAction<any>) => {
      
      
      
      state.trackingManually = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.trackingManuallyError = errorMessage;
    },
    SaveDataSuccess: (state, action: PayloadAction<any>) => {
      
      
      
      state.saveData = action.payload;
      state.saveDataError = null;
    },
    SaveDataFailed: (state, action: PayloadAction<any>) => {
      state.saveData = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.saveDataError = errorMessage;
    },
    SaveDataReturnSuccess: (state, action: PayloadAction<any>) => {
      
      
      
      state.saveReturnBuyer = action.payload;
      state.saveReturnBuyerError = null;
    },
    SaveDataReturnFailed: (state, action: PayloadAction<any>) => {
      state.saveReturnBuyer = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.saveReturnBuyerError = errorMessage;
    },
    
    OrderSearchSuccess: (state, action: PayloadAction<any>) => {
      
      
      state.searchOrder = action.payload;
      state.searchOrderError = null;
      state.isLoading = false;
    },
    OrderSearchFailed: (state, action: PayloadAction<any>) => {
      
      
      state.searchOrderError = action.payload;
      state.searchOrder = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.searchOrderError = errorMessage;
      state.isLoading = false;
    },
    MerchantSuccess: (state, action: PayloadAction<any>) => {
      
      
      state.merchantId = action.payload;
      state.error = null;
    },
    MerchantFailed: (state, action: PayloadAction<any>) => {
      
      
      state.error = action.payload;
      state.merchantId = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
  },
});

export const sellerOrders = slice.reducer;

export const {
  OrderFailed,
  OrderSuccess,
  OrderSearchFailed,
  OrderSearchSuccess,
  setIsLoading,
  resetOrderSuccess,
  resetOrders,
  BuyerNameFailed,
  BuyerNameSuccess,
  TrackingManuallyFailed,
  TrackingManuallySuccess,
  SaveDataFailed,
  SaveDataSuccess,
  OrderDetailFailed,
  OrderDetailSuccess,
  CancelOrderDetailFailed,
  CancelOrderDetailSuccess,
  resetDetails,
  MerchantFailed,
  MerchantSuccess,
  resetMerchantId,
  ReturnDetailFailed,
  ReturnDetailSuccess,
  OrderReportFailed,
  OrderReportSuccess,
  resetAllOrdersReport,
  ShipperBuyerDetailFailed,
  ShipperBuyerDetailSuccess,
  ShipmentDetailsFailed,
  ShipmentDetailsSuccess,
  TrackingDetailsFailed,
  TrackingDetailsSuccess,
  SaveDataReturnFailed,
  SaveDataReturnSuccess
} = slice.actions;
