import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";


export const FindLoading = (props: any) => {

  const sliderFindSettings = {
    320: {
      slidesPerView: 2,
    },
    480: {
      slidesPerView: 2,
    },
    576: {
      slidesPerView: 4,
    },
    1200: {
      slidesPerView: 6,
      spaceBetween: 20
    }
  };

  return (
    <section className="find-section find-loading">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="h2-block">
            <h2 className="h2-section-title">Find what you need</h2>
          </div>
          <Swiper
            // install Swiper modules
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={20}
            slidesPerView={6}
            breakpoints={sliderFindSettings}
            onSwiper={(swiper) => console.log(swiper)}
            className="findSwiper"
            onSlideChange={() => console.log("slide change")}
          >
            
                <SwiperSlide className="recentListSwiper">
                  <div>
                    <div className="category-list">
                      <figure className="category-media loading-animate">
                        
                      </figure>
                    </div>
                    <div className="category-content">
                      <h4 className="category-name loading-animate">
                      </h4>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide className="recentListSwiper">
                  <div>
                    <div className="category-list">
                      <figure className="category-media loading-animate">
                        
                      </figure>
                    </div>
                    <div className="category-content">
                      <h4 className="category-name loading-animate">
                      </h4>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide className="recentListSwiper">
                  <div>
                    <div className="category-list">
                      <figure className="category-media loading-animate">
                        
                      </figure>
                    </div>
                    <div className="category-content">
                      <h4 className="category-name loading-animate">
                      </h4>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide className="recentListSwiper">
                  <div>
                    <div className="category-list">
                      <figure className="category-media loading-animate">
                        
                      </figure>
                    </div>
                    <div className="category-content">
                      <h4 className="category-name loading-animate">
                      </h4>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide className="recentListSwiper">
                  <div>
                    <div className="category-list">
                      <figure className="category-media loading-animate">
                        
                      </figure>
                    </div>
                    <div className="category-content">
                      <h4 className="category-name loading-animate">
                      </h4>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide className="recentListSwiper">
                  <div>
                    <div className="category-list">
                      <figure className="category-media loading-animate">
                        
                      </figure>
                    </div>
                    <div className="category-content">
                      <h4 className="category-name loading-animate">
                      </h4>
                    </div>
                  </div>
                </SwiperSlide>

          </Swiper>
        </div>
      </div>
    </div>
  </section>
  );

}