import { createSlice } from "@reduxjs/toolkit";
import { Progress } from "../../utils/types";

type ReturnOfferState = {
  progress: Progress["progress"] | null;
};

const initialState: ReturnOfferState = {
  progress: 0,
};

const slice = createSlice({
  name: "progress",
  initialState,
  reducers: {
    progressStartSuccess: (state) => {
      
      state.progress = 40;
    },

    progressSuccess: (state) => {
      
      state.progress = 100;
    },

    progressResetSuccess: (state) => {
      
      state.progress = 0;
    },
  },
});

export const progress = slice.reducer;

export const { progressStartSuccess, progressSuccess, progressResetSuccess } =
  slice.actions;
