import Api from "../../utils/api";
import { API_URLS } from "../../utils/api.urls";
import { Orders } from "../../utils/types";
const d = new Date();
let TimeZone = d.getTimezoneOffset() * -1;



export const fetchOrders = async (params: any | null): Promise<any> => {
  const data = await Api.get(
    params.rows
      ? API_URLS.ORDERS +
          "/SellerAllOrders" +
          "/?PageNumber=" +
          params.currentPage +
          "&TotalRows=" +
          params.rows +
          "&userId=" +
          params.userId +
          "&Keyword=" +
          params.orderId +
          "&StartDate=" +
          params.StartDate +
          "&EndDate=" +
          params.EndDate +
          "&TimeZone=" +
          TimeZone
      : API_URLS.ORDERS +
          "/SellerAllOrders" +
          "/?PageNumber=" +
          params.currentPage +
          "&TimeZone=" +
          TimeZone,
    []
  );
  return data;
};

export const fetchDeliveredOrders = async (
  params: any | null
): Promise<any> => {
  const data = await Api.get(
    params.rows
      ? API_URLS.ORDERS +
          "/delivered" +
          "?PageNumber=" +
          params.currentPage +
          "&TotalRows=" +
          params.rows +
          "&userId=" +
          params.userId +
          "&Keyword=" +
          params.orderId +
          "&StartDate=" +
          params.StartDate +
          "&EndDate=" +
          params.EndDate +
          "&TimeZone=" +
          TimeZone
      : API_URLS.ORDERS +
          "/SellerAllOrders" +
          "/?PageNumber=" +
          params.currentPage +
          "&TimeZone=" +
          TimeZone,
    []
  );
  return data;
};

export const fetchAwaitingShipment = async (
  params: any | null
): Promise<any> => {
  const data = await Api.get(
    params.rows
      ? API_URLS.ORDERS +
          "/AwaitingShipment" +
          "/?PageNumber=" +
          params.currentPage +
          "&TotalRows=" +
          params.rows +
          "&userId=" +
          params.userId +
          "&Keyword=" +
          params.orderId +
          "&StartDate=" +
          params.StartDate +
          "&EndDate=" +
          params.EndDate +
          "&TimeZone=" +
          TimeZone
      : API_URLS.ORDERS +
          "/SellerAllOrders" +
          "/?PageNumber=" +
          params.currentPage +
          "&TimeZone=" +
          TimeZone,
    []
  );
  return data;
};
export const fetchOrderPaidShipped = async (
  params: any | null
): Promise<any> => {
  const data = await Api.get(
    params.rows
      ? API_URLS.ORDERS +
          "/OrderPaidAndShipped" +
          "/?PageNumber=" +
          params.currentPage +
          "&TotalRows=" +
          params.rows +
          "&userId=" +
          params.userId +
          "&Keyword=" +
          params.orderId +
          "&StartDate=" +
          params.StartDate +
          "&EndDate=" +
          params.EndDate +
          "&TimeZone=" +
          TimeZone
      : API_URLS.ORDERS +
          "/SellerAllOrders" +
          "/?PageNumber=" +
          params.currentPage +
          "&TimeZone=" +
          TimeZone,
    []
  );
  return data;
};

export const fetchCancellation = async (params: any | null): Promise<any> => {
  const data = await Api.get(
    params.rows
      ? API_URLS.ORDERS +
          "/Cancellations" +
          "/?PageNumber=" +
          params.currentPage +
          "&TotalRows=" +
          params.rows +
          "&userId=" +
          params.userId +
          "&Keyword=" +
          params.orderId +
          "&StartDate=" +
          params.StartDate +
          "&EndDate=" +
          params.EndDate +
          "&TimeZone=" +
          TimeZone
      : API_URLS.ORDERS +
          "/SellerAllOrders" +
          "/?PageNumber=" +
          params.currentPage +
          "&TimeZone=" +
          TimeZone,
    []
  );
  return data;
};

export const fetchReturnOrders = async (params: any | null): Promise<any> => {
  const {PaidBySeller,...rest} = params
  const data = await Api.get(
    PaidBySeller && PaidBySeller !== undefined ?
      (
        rest.rows 
          ? API_URLS.RETURN_ORDERS + "/ReturnOrders" + 
            "/?PageNumber=" + rest.currentPage + 
            "&TotalRows=" + rest.rows + 
            "&userId=" + rest.userId + 
            "&Keyword=" + rest.orderId + 
            "&StartDate=" + rest.StartDate + 
            "&EndDate=" + rest.EndDate + 
            "&TimeZone=" + TimeZone + 
            "&SellerPaysForReturnShipping=" + PaidBySeller 
          : API_URLS.ORDERS + "/SellerAllOrders" + 
            "/?PageNumber=" + rest.currentPage + 
            "&TimeZone=" + TimeZone 
      )
    :
      (
        rest.rows 
          ? API_URLS.RETURN_ORDERS + "/ReturnOrders" + 
            "/?PageNumber=" + rest.currentPage + 
            "&TotalRows=" + rest.rows + 
            "&userId=" + rest.userId + 
            "&Keyword=" + rest.orderId + 
            "&StartDate=" + rest.StartDate + 
            "&EndDate=" + rest.EndDate + 
            "&TimeZone=" + TimeZone 
          : API_URLS.ORDERS + "/SellerAllOrders" + 
            "/?PageNumber=" + rest.currentPage + 
            "&TimeZone=" + TimeZone 
      )  
    ,[]
  );

  return data;
};

export const fetchReturnDetails = async (params:any): Promise<any> => {
  const data = await Api.get(
    API_URLS.RETURN_ORDERS + "/ReturnDetails/"+params,

    []
  );
  return data;
};
export const fetchBuyerNames = async (): Promise<any> => {
  const data = await Api.get(
    API_URLS.ORDERS + "/BuyerUserNames",

    []
  );
  return data;
};
export const setTracking = async (params: any): Promise<any> => {
  const data = await Api.patch(
    API_URLS.ORDERS + "/UpdateTrackingNoManualy",

    params
  );
  return data;
};

export const postOrder = async (params: any): Promise<any> => {
  const data = await Api.post(
    API_URLS.ORDERS + "/PlaceOrder",

    params
  );
  return data;
};

export const fetchMerchantId = async (params: any): Promise<any> => {

  const data = await Api.post(
    API_URLS.ORDERS + "/MerchantIds",
    

    params
  );
  return data;
};

export const capturePayment = async (params: any): Promise<any> => {
  const data = await Api.post(
    API_URLS.ORDERS +"/" +params+'/CapturePayment',

    []
  );
  return data;
};

export const setFeedback = async (params: any): Promise<any> => {
  const data = await Api.post(
    API_URLS.SELLER_ORDER_FEEDBACK,

    params
  );
  return data;
};

export const setOrderContactBuyer = async (params: any): Promise<any> => {
  let data:any;
  if(params.paidBySeller){
    data = await Api.post(API_URLS.SHARE_SHIPMENT_LABEL_URL,params.form_data);
  }else{
    data = await Api.post(API_URLS.CONTACT_BUYER,params);
  }
  return data;
};

export const setReturnRequest = async (params: any): Promise<any> => {
  const data = await Api.post(
    API_URLS.RETURN_ORDERS+"/fullrefundreturnrequest",

    params
  );
  return data;
};
export const setRefundReturn = async (params: any): Promise<any> => {
  const data = await Api.post(
    API_URLS.RETURN_ORDERS+"/FullRefundOrder/"+params,

    []
  );
  return data;
};
export const setReportBuyer = async (params: any): Promise<any> => {
  const data = await Api.post(
    API_URLS.REPORT_BUYER+"/reportbuyer",

    params
  );
  return data;
};
export const setReportItem = async (params: any): Promise<any> => {
  const data = await Api.post(
    API_URLS.REPORT_BUYER,

    params
  );
  return data;
};
export const postNote = async (params: any): Promise<any> => {
  const data = await Api.patch(
    API_URLS.ORDERS + "/SellerOrderNote",

    params
  );
  return data;
};

export const deleteNote = async (params: any): Promise<any> => {
  const data = await Api.delete(
    API_URLS.ORDERS + "/RemoveSellerOrderNote/" + params.orderId,

    []
  );
  return data;
};

export const postCancel = async (params: any): Promise<any> => {
  let type=localStorage.getItem("authorization") !== null && localStorage.getItem("role") === "Seller" ? "/seller" :"";
  const data = await Api.post(
    API_URLS.ORDERS + type+ "/Cancel",

    params
  );
  return data;
};
export const postCancelPickup = async (params: any): Promise<any> => {
  const data = await Api.post(
    API_URLS.COURIER + "/cancelpickupbooking",

    params
  );
  return data;
};
export const postCreateShipment = async (params: any): Promise<any> => {
  const {type,...rest} = params;
  let shipmentEndpoint:string = ''
  if(type === 'default'){
    shipmentEndpoint = "/createshipment"
  }else if(type === 'manual'){
    shipmentEndpoint = "/createManualshipment"
  }
  const data = await Api.post(API_URLS.COURIER + shipmentEndpoint,rest);
  return data;
};
export const postPickupDate = async (params: any): Promise<any> => {
  const data = await Api.post(
    API_URLS.COURIER + "/pickupbooking",

    params
  );
  return data;
};
export const postReturnRequestBuyer = async (params: any): Promise<any> => {
  const data = await Api.post(
    API_URLS.RETURN_ORDERS +  "/RequestReturnOrder",

    params
  );
  return data;
};
export const setShipped = async (params: any): Promise<any> => {
  const data = await Api.patch(
    API_URLS.ORDERS + "/MarkOrderShipped/" + params.orderId,

    []
  );
  return data;
};
export const fetchOrderDetail = async (params: any | null): Promise<any> => {
  const data = await Api.get(
    API_URLS.ORDERS + "/OrdersDetail/" + params.orderId,

    []
  );
  return data;
};
export const fetchOrderDetailShipment = async (params: any | null): Promise<any> => {
  const data = await Api.get(
    API_URLS.COURIER + "/createdshipmentdetail/" + params.orderId,

    []
  );
  return data;
};
export const fetchOrderTracking = async (params: any | null): Promise<any> => {
  const data = await Api.get(
    API_URLS.COURIER + "/tracking/" + params.shipmentId,

    []
  );
  return data;
};

export const fetchShipperBuyerDetail = async (params: any | null): Promise<any> => {
  const data = await Api.get(
    API_URLS.COURIER + "/shipperbuyerdetail/" + params.orderId,

    []
  );
  return data;
};
export const fetchCancelOrderDetail = async (
  params: any | null
): Promise<any> => {
  const data = await Api.get(
    API_URLS.ORDERS + "/CancelledOrderDetail/" + params.orderId,

    []
  );
  return data;
};
// export const searchOrder = async (search: string): Promise<any> => {
//   const data = await Api.get(
//     API_URLS.ORDERS + "/SearchUserByName/" + search,
//     []
//   );
//   return data;
// };
