import React from 'react'
import HeartIcon from "../../../assets/images/833472 copy.png"
interface Props {
    product: any;
    quantity : any;
    quantityDropdown : any;
    setQuantity : any;
    handleAddToCart : any;
    handleAddToWatchlist:any;
    variantDetail:any;
    handleMakeOffer:any;
    handleReviewOffer:any;
    handleBuyItNow:any;

  }

const ProductBuy = ({
    product, quantity,quantityDropdown,setQuantity ,handleBuyItNow, handleAddToCart,handleAddToWatchlist,variantDetail, handleMakeOffer,handleReviewOffer}: Props
    ) => {

    return (
        <div className="smallCartBlock">
            <h4>Quantity</h4>
            <select
                name="quantity"
                id="quantity"
                value={quantity}
                onChange={(e) => setQuantity(Number(e.target.value))}
            >
                {/* <option  value={0}>
                        Select quantity
                    </option> */}
                {quantityDropdown?.map((qty:number, Index:number) => (
                    <option key={qty} value={qty}>
                        {" "}
                        {qty}
                    </option>
                ))}
            </select>
            <button onClick={()=>handleBuyItNow()} 
                disabled={product?.productDetails?.data?.productDetail?.isSelfProduct}
            
            className="butItNow">
                Buy it now
            </button>
            <button
                className="addToCart"
                onClick={handleAddToCart}
                disabled={product?.productDetails?.data?.productDetail?.isSelfProduct}
                // disabled={(product?.productDetails?.data?.productVariantInfos!==null && variantDetail.id===undefined) || quantityDropdown.length==0 }
            >
                Add to cart
            </button>
       { product.productDetails.data.productDetail.letBuyerMakeOffer && product.productDetails.data.makeOffer.pending==null &&   
       <button  className="butItNow"
       disabled={product?.productDetails?.data?.productDetail?.isSelfProduct}

                     onClick={() => {
                       handleMakeOffer()
                      }}
        >
            Make offer
        </button>}
        { product.productDetails.data.productDetail.letBuyerMakeOffer && product.productDetails.data.makeOffer.pending==true &&   <button  className="butItNow"
                     onClick={() => {
                       handleReviewOffer()
                      }}
        >
            Review offer
        </button>}
            <button className="addToWishList" 
                onClick={handleAddToWatchlist}
                disabled={product?.productDetails?.data?.productDetail?.isSelfProduct}
            
            >
                { variantDetail?.inWatchList===true  ||  product.productDetails.data.productDetail.inWatchList===true ?  <img src={HeartIcon} alt="" /> : ""} Add to
                watchlist
            </button>
        </div>
    )
}

export default ProductBuy
