import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { getBuyerProfile } from "../../../../../modules/buyer/myEfindit/account/getBuyerProfile";
import { accountResponseSelector } from "../../../../../modules/buyer/myEfindit/account/selectors";
import { updateMobileNo } from "../../../../../modules/buyer/myEfindit/account/updateMobileNo";
import { updateProfileAddress } from "../../../../../modules/buyer/myEfindit/account/updateProfileAddress";
import { updateUserName } from "../../../../../modules/buyer/myEfindit/account/updateUserName";
import { getCountries } from "../../../../../modules/common/getCountries";
import { getStateByCountryCode } from "../../../../../modules/common/getStateByCountryCode";
import { CommonResponseSelector } from "../../../../../modules/common/selectors";
import { notify } from "../../../../../modules/notification/notify";
import { reset as resetAccount } from "../../../../../modules/buyer/myEfindit/account/reset";
import { constant } from "../../../../../utils/constant";
import { RootState } from "../../../../../utils/types";
import BuyerHeader from "../../../BuyerHeader";
import GroupTab from "../../groupTab";
import SideNav from "../sideNav";
import PersonalAddress from "./personalAddress";
import BuyerFooter from "../../../buyerFooter";
import errorMsg from "../../../../../utils/errorMsg";
import Header from "../../../../../components/header/Header";
import HeaderNavigationSeller from "../../../../seller/HeaderNavigationSeller";
import ShippingAddress from "./shippingAddress";
import { sellerStoreResponseSelector } from "../../../../../modules/sellerStore/selectors";
import { reset } from "../../../../../modules/sellerStore/reset";
import { reset as resetPay } from "../../../../../modules/paypal/reset";

import { delinkPaypal } from "../../../../../modules/paypal/delinkPaypal";
import { PaypalResponseSelector } from "../../../../../modules/paypal/selectors";
import { useNavigate } from "react-router-dom";
import { DefaultLayout } from "../../../../../layout/DefaultLayout";
import AccountDeactivateModel from "./AccountDeactivateModel";
import { setSocialDetails } from "../../../../../modules/userLogin/setSocialDetails";
import { deleteToken } from "../../../../../modules/userLogin/deleteToken";
import { resetLogout } from "../../../../../modules/userLogin/resetLogout";
import { getCommonData } from "../../../../../modules/common/getCommonData";

const PersonalInformation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isFirstRender = useRef(true);
  const [render, setRender] = useState(0);
  interface deactivateUserValues {
    visible: string;
    reason: string | null;
    description: string | null;
  }
  const [deactivateUser, setDeactivateUser] = useState<deactivateUserValues>({
    visible: "none",
    reason: "",
    description:null,
  });
  const [callingCode, setCallingCode] = useState(1);
  const [mobileNoError, setMobileNoError] = useState("");
  const [userNameError, setUserNameError] = useState("");

  const [profileData, setProfileData] = useState({
    UserName: "",
    RoleId: 2,
    MobileNo: "",
    EmailId: "",
    CallingCode:0,
  });

  const [profileAddress, setProfileAddress] = useState({
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Address1: "",
    Address2: "",
    City: "",
    StateId: 0,
    CountryId: 0,
    ZipCode: "",
    State: {
      StateName: "",
    },
    Country: {
      CountryName: "",
    },
  });
  const [storeAddress, setStoreAddress] = useState({
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Address1: "",
    Address2: "",
    City: "",
    StateId: 0,
    CountryId: 0,
    ZipCode: "",
    CallingCode:0,
    MobileNo:"",
    State: {
      StateName: "",
    },
    Country: {
      CountryName: "",
    },
  });
  const [newProfileData, setNewProfileData] = useState({
    UserName: "",
    MobileNo: "",
    EmailId: "",
    CallingCode:0,
  });

  const [newProfileAddress, setNewProfileAddress] = useState({
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Address1: "",
    Address2: "",
    City: "",
    StateId: 0,
    CountryId: 2,
    ZipCode: "",
  });
  const [newStoreAddress, setNewStoreAddress] = useState({
    Id:"",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Address1: "",
    Address2: "",
    City: "",
    StateId: 0,
    CountryId: 2,
    ZipCode: "",
    CallingCode: 0,
      MobileNo: "",
  });
  let accountResponse = useSelector<RootState, RootState["account"]>(
    accountResponseSelector
  );
  let paypalResponse = useSelector<RootState, RootState["paypal"]>(
    PaypalResponseSelector
  );
let sellerStore=useSelector<RootState, RootState["sellerStore"]>(
  sellerStoreResponseSelector
);
  const commonResponse = useSelector<RootState, RootState["common"]>(
    CommonResponseSelector
  );

  useEffect(() => {
    if (isFirstRender.current) {
      dispatch<any>(getBuyerProfile());
      dispatch<any>(getCountries());
      dispatch<any>(getCommonData());
    }
    isFirstRender.current = false;
  }, []);
  useEffect(() => {
    accountResponse.profile && setProfileData(accountResponse.profile.value[0]);
    accountResponse.profile &&
      setNewProfileData(accountResponse.profile?.value[0]);
      // accountResponse.profile &&
      // setCallingCode(accountResponse.profile?.value[0]?.CallingCode ? accountResponse.profile?.value[0]?.CallingCode  : 1);
    accountResponse.profile &&
      accountResponse.profile.value[0].Addresses?.map((address: any) => {
        if (address.IsPersonal === true) {
          setProfileAddress({
            FirstName: address.FirstName,
            MiddleName: address.MiddleName !== null ? address.MiddleName : "",
            LastName: address.LastName,
            Address1: address.Address1,
            Address2: address.Address2 !== null ? address.Address2 : "",
            City: address.City,
            StateId: address.StateId,
            CountryId: address.CountryId,
            ZipCode: address.ZipCode,
            State: {
              StateName: address?.State?.StateName,
            },
            Country: {
              CountryName: address?.Country?.CountryName,
            },
          });

          setNewProfileAddress({
            FirstName: address.FirstName,
            MiddleName: address.MiddleName !== null ? address.MiddleName : "",
            LastName: address.LastName,
            Address1: address.Address1,
            Address2: address.Address2 !== null ? address.Address2 : "",
            City: address.City,
            StateId: address.StateId,
            CountryId: address.CountryId,
            ZipCode: address.ZipCode,
          });
          localStorage.setItem("UserName",address.FirstName + " " + address.LastName)
        }
        if (address.IsStore === true) {
          setStoreAddress({
            FirstName: address.FirstName,
            MiddleName: address.MiddleName !== null ? address.MiddleName : "",
            LastName: address.LastName,
            Address1: address.Address1,
            Address2: address.Address2 !== null ? address.Address2 : "",
            City: address.City,
            StateId: address.StateId,
            CountryId: address.CountryId,
            ZipCode: address.ZipCode,
            CallingCode: address.CallingCode,
      MobileNo: address.MobileNo,

            State: {
              StateName: address?.State?.StateName,
            },
            Country: {
              CountryName: address?.Country?.CountryName,
            },
          });

          setNewStoreAddress({
            Id:address.Id,
            FirstName: address.FirstName,
            MiddleName: address.MiddleName !== null ? address.MiddleName : "",
            LastName: address.LastName,
            Address1: address.Address1,
            Address2: address.Address2 !== null ? address.Address2 : "",
            City: address.City,
            StateId: address.StateId,
            CountryId: address.CountryId,
            ZipCode: address.ZipCode,
            CallingCode: address.CallingCode,
      MobileNo: address.MobileNo,
          });
          // localStorage.setItem("UserName",address.FirstName + " " + address.LastName)
        }
      });
    
  }, [accountResponse.profile]);

  useEffect(() => {
  if(localStorage.getItem("role")==="Customer"){  let countryData =
      commonResponse.countries &&
      commonResponse?.countries?.value.find(
        (item: any) =>
          item.Id == accountResponse?.profile?.value[0].Addresses[0].CountryId
      );

    if (commonResponse.countries && countryData !== undefined && render == 0) {
      dispatch<any>(getStateByCountryCode(countryData?.CountryCode));
      setRender(1);
    }}
  }, [accountResponse.profile, commonResponse.countries]);
  useEffect(() => {
    if(localStorage.getItem("role")==="Seller"){   let countryData =
      commonResponse.countries &&
      commonResponse?.countries?.value.find(
        (item: any) =>
          item.Id == storeAddress?.CountryId
      );

    if (commonResponse.countries && countryData !== undefined && render == 0) {
      dispatch<any>(getStateByCountryCode(countryData?.CountryCode));
      setRender(1);
    }}
  }, [storeAddress, commonResponse.countries]);

  const [userNameInput, setUserNameInput] = useState({
    data: true,
    input: false,
    updateBtn: false,
    editBtn: true,
    cancelBtn: false,
  });

  const [mobileNoInput, setMobileNoInput] = useState({
    data: true,
    input: false,
    updateBtn: false,
    editBtn: true,
    cancelBtn: false,
  });

  const [addressInput, setAddressInput] = useState({
    data: true,
    input: false,
    editBtn: true,
    cancelBtn: false,
  });
  const [storeaddressInput, setStoreAddressInput] = useState({
    data: true,
    input: false,
    editBtn: true,
    cancelBtn: false,
  });

  const Logout = () => {
    dispatch<any>(
      setSocialDetails({
        provider: "",
        profile: "",
        type: "logout",
      })
    )
      .then(dispatch<any>(deleteToken({})))
      .then(dispatch<any>(resetLogout()));
  };
  const showInput = (type: string) => {
    if (type === "username") {
      setUserNameInput({
        ...userNameInput,
        data: false,
        input: true,
        updateBtn: true,
        editBtn: false,
        cancelBtn: true,
      });
      setNewProfileData({...newProfileData, UserName:profileData.UserName })
      
    }
    

    if (type === "mobile") {
      setMobileNoInput({
        ...mobileNoInput,
        data: false,
        input: true,
        updateBtn: true,
        editBtn: false,
        cancelBtn: true,
      });
      setNewProfileData({...newProfileData, MobileNo:profileData.MobileNo , CallingCode:profileData.CallingCode})
      setCallingCode(profileData.CallingCode ?profileData.CallingCode : 1);
    }

    if (type === "address") {
    
      dispatch<any>(getCountries());
      setAddressInput({
        ...addressInput,
        data: false,
        input: true,
        editBtn: false,
        cancelBtn: true,
      });
      setStoreAddressInput({
        ...storeaddressInput,
        data: true,
        input: false,
        editBtn: true,
        cancelBtn: false,
      });
      let countryData=
      commonResponse.countries &&
      commonResponse?.countries?.value.find(
        (item: any) =>
          item.Id == profileAddress?.CountryId
      );

    if (commonResponse.countries && countryData !== undefined ) {
      dispatch<any>(getStateByCountryCode(countryData?.CountryCode));
      setRender(1);
    }
    }
    if (type === "storeAddress") {
     
      dispatch<any>(getCountries());
      setStoreAddressInput({
        ...storeaddressInput,
        data: false,
        input: true,
        editBtn: false,
        cancelBtn: true,
      });
      setAddressInput({
        ...addressInput,
        data: true,
        input: false,
        editBtn: true,
        cancelBtn: false,
      });
      let countryData=
      commonResponse.countries &&
      commonResponse?.countries?.value.find(
        (item: any) =>
          item.Id == storeAddress?.CountryId
      );

    if (commonResponse.countries && countryData !== undefined) {
      dispatch<any>(getStateByCountryCode(countryData?.CountryCode));
      setRender(1);
    }
    }
  };

  const handleOnChange = (e: any) => {

    if (e.target.name == 'CallingCode') {
      setCallingCode(e.target.value)
    }

    setNewProfileData({
      ...newProfileData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDelink =()=>{
    dispatch<any>(delinkPaypal());
  }
  const handleUpdate = (type: string) => {
    if (type === "username") {
      setUserNameError("")
      var specialChar = /[`!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;
      const whitespaceChars = /\s/;
      const numbers = /^\d+$/;

      if (newProfileData.UserName.length < 2) {
        setUserNameError("Too Short, min 2 letters allowed!")
      }else if (newProfileData.UserName.length > 50){
        setUserNameError("Too Long, max 50 letters allowed!")
      }else if (whitespaceChars.test(newProfileData.UserName)) {
        setUserNameError("Space not allowed.")
      }else if (specialChar.test(newProfileData.UserName)) {
        setUserNameError("Special character not allowed.")
      }else if (numbers.test(newProfileData.UserName)){
        setUserNameError("Only numbers not allowed.")

      }else {

        dispatch<any>(updateUserName({ userName: newProfileData.UserName }));
      }

    }

    if (type === "mobile") {
      var specialChar = /[`!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;
      if(newProfileData.MobileNo.length ==0)
{
  setMobileNoError("Only Numeric data allowed")
  return false
}
      // if (newProfileData.MobileNo.length < 10) {
      //   setMobileNoError("Too Short!, min 10 digits allowed!")

      //   return false;
      // }
      // if (newProfileData.MobileNo.length > 15) {
      //   setMobileNoError("Too Long, max 15 digits allowed!")

      //   return false;
      // }
      dispatch<any>(
        updateMobileNo({ callingCode: callingCode, mobileNo: newProfileData.MobileNo })
      );
    }

    if (type === "address") {
      dispatch<any>(
        updateProfileAddress({
          firstName: newProfileAddress.FirstName,
          middleName: newProfileAddress.MiddleName,
          lastName: newProfileAddress.LastName,
          address1: newProfileAddress.Address1,
          address2: newProfileAddress.Address2,
          city: newProfileAddress.City,
          stateId: newProfileAddress.StateId,
          countryId: newProfileAddress.CountryId,
          zipCode: newProfileAddress.ZipCode,
        })
      );
    }
  };

  const handleCancel = (type: string) => {
    if (type === "username") {
      setUserNameError("")
      
      setUserNameInput({
        ...userNameInput,
        data: true,
        input: false,
        updateBtn: false,
        editBtn: true,
        cancelBtn: false,
      });
    }

    if (type === "mobile") {
      setMobileNoError("")
      setMobileNoInput({
        ...mobileNoInput,
        data: true,
        input: false,
        updateBtn: false,
        editBtn: true,
        cancelBtn: false,
      });
    }

    if (type === "address") {
      setNewProfileAddress({
        FirstName: profileAddress.FirstName,
        MiddleName: profileAddress.MiddleName !== null ? profileAddress.MiddleName : "",
        LastName: profileAddress.LastName,
        Address1: profileAddress.Address1,
        Address2: profileAddress.Address2 !== null ? profileAddress.Address2 : "",
        City: profileAddress.City,
        StateId: profileAddress.StateId,
        CountryId: profileAddress.CountryId,
        ZipCode: profileAddress.ZipCode,
      });
      setAddressInput({
        ...addressInput,
        data: true,
        input: false,
        editBtn: true,
        cancelBtn: false,
      });
    }
    if (type === "storeAddress") {
      setStoreAddressInput({
        ...storeaddressInput,
        data: true,
        input: false,
        editBtn: true,
        cancelBtn: false,
      });
      setNewStoreAddress({
        ...newStoreAddress,
        FirstName: storeAddress.FirstName,
        MiddleName: storeAddress.MiddleName !== null ? storeAddress.MiddleName : "",
        LastName: storeAddress.LastName,
        Address1: storeAddress.Address1,
        Address2: storeAddress.Address2 !== null ? storeAddress.Address2 : "",
        City: storeAddress.City,
        StateId: storeAddress.StateId,
        CountryId: storeAddress.CountryId,
        ZipCode: storeAddress.ZipCode,
        CallingCode: storeAddress.CallingCode,
  MobileNo: storeAddress.MobileNo,
      });
    }
  };

  useEffect(() => {
    if (!isFirstRender.current) {
      if (accountResponse.saveData != null) {
        if (accountResponse.saveData.success === "Y") {
          notify(accountResponse.saveData.message, constant.SUCCESS);

          setUserNameInput({
            ...userNameInput,
            data: true,
            input: false,
            updateBtn: false,
            editBtn: true,
            cancelBtn: false,
          });

          setMobileNoInput({
            ...mobileNoInput,
            data: true,
            input: false,
            updateBtn: false,
            editBtn: true,
            cancelBtn: false,
          });

          setAddressInput({
            ...addressInput,
            data: true,
            input: false,
            editBtn: true,
            cancelBtn: false,
          });
          setStoreAddressInput({
            ...storeaddressInput,
            data: true,
            input: false,
            editBtn: true,
            cancelBtn: false,
          });
          dispatch<any>(getBuyerProfile());
        } 
        // else if (accountResponse.saveData.success === "N") {
        //   notify(accountResponse.error, constant.ERROR);
        // }
       
      }
      if (accountResponse.error != null) {
        notify(accountResponse.error, constant.ERROR);   
      }
      if (accountResponse.errors != null) {
        errorMsg(accountResponse.errors)       
      }
      dispatch<any>(resetAccount());
      
    }
  }, [accountResponse.saveData, accountResponse.errors, accountResponse.error]);
  useEffect(() => {
    if (!isFirstRender.current) {
      if (paypalResponse.delinkPaypal != null) {
        if (paypalResponse.delinkPaypal.success === "Y") {
          // /seller/payment-info
          localStorage.removeItem("payPalAccountLinked");
          navigate("/seller/payment-info")
        } else if (paypalResponse.delinkPaypal.success === "N") {
          notify(paypalResponse.delinkPaypal.message, constant.ERROR);
        }
       
      }
   
      if (paypalResponse.delinkPaypalError != null) {
        notify(paypalResponse.delinkPaypalError, constant.ERROR); 
      }
      dispatch<any>(resetPay());
      
    }
  }, [paypalResponse.delinkPaypal, paypalResponse.delinkPaypalError]);
  useEffect(() => {
    if (!isFirstRender.current) {

      if (accountResponse.deactivateUser != null) {

        if (accountResponse.deactivateUser.success === "Y") {
          // /seller/payment-info
          notify("Your account has been deactivated", constant.SUCCESS);

          setTimeout(  Logout  , 5000);
          // dispatch<any>(
          //   setSocialDetails({
          //     provider: "",
          //     profile: "",
          //     type: "logout",
          //   })
          // )
          //   .then(dispatch<any>(deleteToken({})))
          //   .then(dispatch<any>(resetLogout()));
          // navigate("/seller/payment-info")
        } else if (accountResponse.deactivateUser.success === "N") {
          notify(accountResponse.deactivateUser.message, constant.ERROR);
        }
       
      }
 
      if (accountResponse.deactivateUserError != null) {
        notify(accountResponse.deactivateUserError, constant.ERROR); 
      }
      dispatch<any>(resetAccount("resetDeactiveUser"));
      
    }
  }, [accountResponse.deactivateUser, accountResponse.deactivateUserError]);
  useEffect(() => {
    if (!isFirstRender.current) {
      if (sellerStore.saveData != null) {
        if (sellerStore.saveData.success === "Y") {
          notify(sellerStore.saveData.message, constant.SUCCESS);

          setUserNameInput({
            ...userNameInput,
            data: true,
            input: false,
            updateBtn: false,
            editBtn: true,
            cancelBtn: false,
          });

          setMobileNoInput({
            ...mobileNoInput,
            data: true,
            input: false,
            updateBtn: false,
            editBtn: true,
            cancelBtn: false,
          });

          setAddressInput({
            ...addressInput,
            data: true,
            input: false,
            editBtn: true,
            cancelBtn: false,
          });
          setStoreAddressInput({
            ...storeaddressInput,
            data: true,
            input: false,
            editBtn: true,
            cancelBtn: false,
          });
          dispatch<any>(getBuyerProfile());
        } 
        else if (sellerStore.saveData.success === "N") {
          notify(sellerStore.saveData.message, constant.ERROR);
        }
       
      }
      else if (sellerStore.saveDataError!==null) {
        notify(sellerStore.saveDataError, constant.ERROR);
      }
      
      dispatch<any>(reset());
      
    }
  }, [sellerStore.saveData, sellerStore.saveDataError]);
  return (
    <>
    <DefaultLayout>
     {localStorage.getItem("role")==="Customer" && <BuyerHeader />}
     {localStorage.getItem("role")==="Seller" &&  <> <Header />  <section className="seller-breadcrumb-block">
        <div className="container">
          <ul>
            <li className="text">Account</li>
            <li className="pipe">|</li>
            <li className="text-link">
              <a href="#">{localStorage.getItem("UserName")}</a>
            </li>
          </ul>
        </div>
      </section>   <HeaderNavigationSeller
     currentPage="Account"
     /></>}

   
      <main className="min-section">
        <div className="container">
        {localStorage.getItem("role")==="Customer" && <>   <h1 className="sectionTitle mt-5">My eFindit</h1>

           <GroupTab currentPage="Account" />
</>}
          <section className="withFilter">
            <SideNav />
       {deactivateUser?.visible==="block" &&     <AccountDeactivateModel
        deactivateUserModal={deactivateUser}
        setDeactivateUserModal={setDeactivateUser}
        commonResponse={commonResponse}
      />}
            <ToastContainer />

            {profileData && (
              <div className="right-wrapper">
                <section className="deals-group pt-0">
                  <div className="deal-group-list four-col bg-white mt-0">
                    <div className="right-wrap-title-block">
                      <div className="row align-items-center">
                        <div className="col-md-auto">
                          <div className="right-wrap-title">
                            Personal information
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accountInfo">
                      <div className="acountRow">
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="title">Username <span className="required">*</span></div>
                          </div>
                          <div className="col-lg-5 signForm">
                            {userNameInput.data && (
                              <p>{profileData.UserName}</p>
                            )}
                            {userNameInput.input && (
                              <input
                                type="text"
                                className="formControl"
                                name="UserName"
                                value={newProfileData.UserName}
                                onChange={handleOnChange}
                              />
                            )}
                             {userNameError && <span className="required">{userNameError}</span> }
                          </div>
                          <div className="col-lg-3 text-right">
                            {userNameInput.updateBtn && (
                              <button
                                className="btn verify"
                                onClick={() => handleUpdate("username")}
                              >
                                Update
                              </button>
                            )}
                            {userNameInput.editBtn && (
                              <button
                                className="btn edit"
                                onClick={() => showInput("username")}
                              >
                                Edit
                              </button>
                            )}
                            {userNameInput.cancelBtn && (
                              <button
                                className="btn edit"
                                onClick={() => handleCancel("username")}
                              >
                                Cancel
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="acountRow">
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="title">Account type</div>
                          </div>
                          <div className="col-lg-5">
                            <p>
                              {localStorage.getItem("role")==="Customer"
                                ? "Individual"
                                :localStorage.getItem("role")==="Seller" ? "Business" :""}
                            </p>
                          </div>
                        </div>
                      </div>
                 { localStorage.getItem("role")==="Seller" &&      <div className="acountRow">
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="title">Paypal account</div>
                          </div>
                          <div className="col-lg-5">
                          <button
                                      className="btn verify"
                                      onClick={() => handleDelink()}
                                    >
                                      Unlink
                                    </button>
                          </div>
                        </div>
                      </div>}

                      {     <div className="acountRow">
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="title">Account</div>
                          </div>
                          <div className="col-lg-5">
                          <button
                                      className="btn verify" style={{width:"80px"}}
                                      onClick={() => setDeactivateUser({...deactivateUser, visible:"block"})}
                                    >
                                      Deactivate
                                    </button>
                          </div>
                        </div>
                      </div>}
                      <div className="acountRow">
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="title">Contact info <span className="required">*</span></div>
                          </div>
                          <div className="col-lg-8">
                            <div className="acountRow">
                              <div className="row">
                                <div className="col-lg-7">
                                  <label htmlFor="">Mobile no </label>
                                  {mobileNoInput.data && (
                                    <p>{profileData.MobileNo}</p>
                                  )}
                                  {mobileNoInput.input && (
                                    <>
                                    <div className="signForm row">
                                      <div className="col-3">
                                      <select name="CallingCode" className="formControl"
                                        onChange={handleOnChange} defaultValue={callingCode}>
                                        {commonResponse.countries?.value.map(
                                          (item, Index) =>
                                            item.Active === "Y" && (
                                              <option
                                                key={Index}
                                                value={item.CallingCode}
                                              >
                                                {item.CallingCode}
                                              </option>
                                            )
                                        )}
                                      </select>
                                      </div>
                                      <div className="col-9">

                                      <input
                                       type="number" 
                                       onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                                        className="formControl"
                                        name="MobileNo"
                                        value={newProfileData?.MobileNo ?? ""}
                                        onChange={handleOnChange}
                                      />
                                      {mobileNoError && <span className="required">{mobileNoError}</span> }
                                      </div>
                                      
                                      
                                      </div>
                                    </>
                                  )}
                                </div>
                                <div className="col-lg-5 text-right">
                                  {mobileNoInput.updateBtn && (
                                    <button
                                      className="btn verify"
                                      onClick={() => handleUpdate("mobile")}
                                    >
                                      Update
                                    </button>
                                  )}
                                  {mobileNoInput.editBtn && (
                                    <button
                                      className="btn edit"
                                      onClick={() => showInput("mobile")}
                                    >
                                      Edit
                                    </button>
                                  )}
                                  {mobileNoInput.cancelBtn && (
                                    <button
                                      className="btn edit"
                                      onClick={() => handleCancel("mobile")}
                                    >
                                      Cancel
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="acountRow border-0 mb-0 p-0">
                              <div className="row">
                                <div className="col-lg-7">
                                  <label htmlFor="">Email address</label>
                                  <p>{profileData.EmailId}</p>
                                  {/* <p className="notVerified">Not Verified</p> */}
                                </div>
                                {/* <div className="col-lg-5 text-right">
                                  <button className="btn verify">Verify</button>
                                  <button className="btn edit">Edit</button>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                         <div className="acountRow">
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="title">Personal info <span className="required">*</span></div>
                          </div>
                          <div className="col-lg-5">
                            <label htmlFor="">Owner name, address</label>
                            {addressInput.data && (
                              <>
                                <p>
                                  {profileAddress.FirstName +
                                    " " +
                                    profileAddress.MiddleName +
                                    " " +
                                    profileAddress.LastName}
                                </p>
                                {profileAddress.Address1 && (
                                  <p>
                                    {profileAddress.Address1}
                                    { profileAddress.Address2 && <><br />{profileAddress.Address2}</> } <br/>
                                    {`${profileAddress.City}, ${profileAddress.State.StateName}  ${profileAddress.ZipCode}`} <br />
                                    {profileAddress.Country.CountryName} <br/>
                                  </p>
                                  
                                )}{" "}
                              </>
                            )}

                            {addressInput.input && (
                              <PersonalAddress
                                address={newProfileAddress}
                                common={commonResponse}
                                setNewProfileAddress={setNewProfileAddress}
                              />
                            )}
                          </div>

                          <div className="col-lg-3 text-right">
                            {addressInput.editBtn && (
                              <button
                                className="btn edit"
                                onClick={() => showInput("address")}
                              >
                                Edit
                              </button>
                            )}
                            {addressInput.cancelBtn && (
                              <button
                                className="btn edit"
                                onClick={() => handleCancel("address")}
                              >
                                Cancel
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      {localStorage.getItem("role")==="Seller" &&        <div className="acountRow">
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="title">Store address <span className="required">*</span></div>
                          </div>
                          <div className="col-lg-5">
                            <label htmlFor="">Owner name, address</label>
                            {storeaddressInput.data && (
                              <>
                                <p>
                                  {storeAddress.FirstName +
                                    " " +
                                    storeAddress.MiddleName +
                                    " " +
                                    storeAddress.LastName}
                                </p>
                                {storeAddress.Address1 && (
                                 
                                  <p>
                                  {storeAddress.Address1}
                                  { storeAddress.Address2 && <><br />{storeAddress.Address2}</> } <br/>
                                  {`${storeAddress.City}, ${storeAddress.State.StateName}  ${storeAddress.ZipCode}`} <br />
                                  {storeAddress.Country.CountryName} <br/>
                                </p>
                                )}{" "}
                              </>
                            )}

                            {storeaddressInput.input && (
                               <ShippingAddress
                               shippingBtnTitle={"Save"}
                               address={newStoreAddress}
                               common={commonResponse}
                               setNewShippingAddress={setNewStoreAddress}
                               isStore={true}

                             />
                             
                            )}
                          </div>

                          <div className="col-lg-3 text-right">
                            {storeaddressInput.editBtn && (
                              <button
                                className="btn edit"
                                onClick={() => showInput("storeAddress")}
                              >
                                Edit
                              </button>
                            )}
                            {storeaddressInput.cancelBtn && (
                              <button
                                className="btn edit"
                                onClick={() => handleCancel("storeAddress")}
                              >
                                Cancel
                              </button>
                            )}
                          </div>
                        </div>
                      </div>}
                    </div>
                  </div>
                </section>
              </div>
            )}
          </section>
        </div>
      </main>
      <BuyerFooter/>
      { deactivateUser.visible==="block"    &&   <div className="modal-backdrop show"></div>}
      </DefaultLayout>
    </>
   
  );
};

export default PersonalInformation;
