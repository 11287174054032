import { AppThunk } from "../../../store";
import { SearchFailed, SearchSuccess } from "./slice";
import { fetchSearchResult } from "./api";
import { EndProgress } from "../../progressBar/endProgress";
import { StartProgress } from "../../progressBar/startProgress";

export const getSearchResult =
  (params: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());

    try {
      const data: any = await fetchSearchResult(params);
      

      if (data.success === "N") {
        
        dispatch(SearchFailed(data));
      } else {
        
        dispatch(SearchSuccess(data));
      }
    } catch (err) {
      
      dispatch(SearchFailed(err));
    }
    dispatch<any>(EndProgress());
  };
