import { AppThunk } from "../../../../store"
import { resetAccount, resetActiveUser, resetDeactiveUser } from "./slice";

export const reset = (data = ""): AppThunk => async (dispatch) => {
  try {

    if(data==="resetDeactiveUser")
    dispatch(resetDeactiveUser());
    if(data==="resetActiveUser")
    dispatch(resetActiveUser());
  else
  dispatch(resetAccount());

  } catch (err) {
  }
};
