import Api from "../../utils/api";
import { API_URLS } from "../../utils/api.urls";
const d = new Date();
let TimeZone = d.getTimezoneOffset() * -1;
export const fetchStore = async (): Promise<any> => {
  const data = await Api.get(API_URLS.SELLER_STORE, []);
  return data;
};

export const fetchFeedback = async (params: any): Promise<any> => {
  const data = await Api.get(
    API_URLS.SELLER_STORE_FEEDBACK +
      "?PageNumber=" +
      params.currentPage +
      "&TotalRows=" +
      params.rows,
    []
  );
  return data;
};

export const setStore = async (params: any | null): Promise<any> => {
  const data = await Api.post(API_URLS.SAVE_SELLER_STORE, params);
  return data;
};
export const postProfilePic = async (params: any | null): Promise<any> => {
  const data = await Api.post(API_URLS.SAVE_PROFILE_PIC, params);
  return data;
};
//
export const setStoreAddress = async (params: any | null): Promise<any> => {
  const data = await Api.post(API_URLS.STORE_ADDRESS, params);
  return data;
};
