import { AppThunk } from "../../store";
import { SaveSellFasterSuccess, SaveSellFasterFailed } from "./slice";
import { postSellFaster } from "./api";
import { EndProgress } from "../progressBar/endProgress";
import { StartProgress } from "../progressBar/startProgress";

export const saveSellFaster =
  (data: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
      const shippingData: any = await postSellFaster(data);
      

      if (shippingData.success === "N") {
        
        dispatch(SaveSellFasterFailed(shippingData));
      } else {
        
        localStorage.removeItem("productId");
        dispatch(SaveSellFasterSuccess(shippingData));
      }
    } catch (err) {
      
      dispatch(SaveSellFasterFailed(err));
    }

    dispatch<any>(EndProgress());
  };
