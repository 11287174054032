import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { deleteToken } from "../../modules/userLogin/deleteToken";
import { setSocialDetails } from "../../modules/userLogin/setSocialDetails";

interface props {
  activation?:boolean;
}

function HeaderNormal({activation}:props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const Logout = () => {
    dispatch<any>(
      setSocialDetails({
        provider: "",
        profile: "",
        type: "logout",
      })
    ).then(dispatch<any>(deleteToken({})));
  };
  return (
    <header className="header">
      <div className="logo-section">
        <div className="container">
          <div className="row">
            <div className="col-md-10">
              <div className="logo" style={{cursor:"pointer"}}  onClick={()=> activation===true && navigate("/home", { replace: true })}>

              <Link
                 
                 to="/seller/overview"
               >
                 <i className="logo-img"></i>
               </Link>
              </div>
            </div>
            <div className="col-md-2">
              <div className="logo">
            { activation===true ?   <button
                  style={{ fontSize: "16px" }}
                  onClick={()=>  navigate("/home", { replace: true })}
                  className="btn bg-transparent"
                >
                  Home
                </button> :

                <button
                  style={{ fontSize: "16px" }}
                  onClick={Logout}
                  className="btn bg-transparent"
                >
                  Logout
                </button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderNormal;
