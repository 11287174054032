import { AppThunk } from "../../store";
import { Orders } from "../../utils/types";
import { OrderReportSuccess, OrderReportFailed } from "./slice";
import { fetchAwaitingShipment, fetchCancellation, fetchDeliveredOrders, fetchOrderPaidShipped, fetchOrders, fetchReturnOrders } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const getAllOrdersReport =
  (data: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch<any>(StartProgress());
let ordersData:any;

if(data?.type==="Delivered")
ordersData = await fetchDeliveredOrders(data) ;

if(data?.type==="Awaiting")
ordersData = await  fetchAwaitingShipment(data)  ;

if(data?.type==="PaidShipped")
ordersData = await  fetchOrderPaidShipped(data);
if(data?.type==="Cancellation")
ordersData = await  fetchCancellation(data);
if(data?.type==="Return")
ordersData = await fetchReturnOrders(data);
if(data?.type==="")
 ordersData = await fetchOrders(data) ;
      

      if (ordersData.success === "N") {
        
        dispatch(OrderReportFailed(ordersData));
      } else {
        
        dispatch(OrderReportSuccess(ordersData));
      }

      //return DashboardData
    } catch (err) {
      
      dispatch(OrderReportFailed(err));
      //return err.toString()
    }
    dispatch<any>(EndProgress());
  };
