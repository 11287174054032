import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BuyerProduct, Homepage } from "../../../utils/types";

type activityState = {
  homepage: Homepage["homepage"] | null;

  error: any | null;
};

const initialState: activityState = {
  homepage: null,

  error: null,
};
const slice = createSlice({
  name: "homepage",
  initialState,
  reducers: {
    HomepageSuccess: (state, action: PayloadAction<any>) => {
      state.homepage = action.payload;
      state.error = null;
    },
    HomepageFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.homepage = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
  },
});

export const homepage = slice.reducer;

export const { HomepageFailed, HomepageSuccess } = slice.actions;
