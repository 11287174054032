import React from 'react'
import Messages from './messages'

const EfindIt = () => {
  return (
    <Messages nav="efindit"/>
  )
}

export default EfindIt
