import React from "react";
import { Link } from "react-router-dom";
interface Props {
  currentPage: string;
}
function ActivityNavPanel({ currentPage }: Props) {
  return (
    <div className="left-filter">

      <div className="left-lists-block side-nav-list">
        <div className="wrap">
          <ul className="list-unstyled">
            <li
              className={`${currentPage === "RecentlyViewed" ? "active" : ""}`}
            >
              <Link to="/buyer/activity/recentlyViewed">Recently viewed</Link>
            </li>
            <li className={`${currentPage === "Bids/Offers" ? "active" : ""}`}>
              <Link to="/buyer/activity/bidsOffers">Bids/Offers</Link>
            </li>
            <li
              className={`${currentPage === "PurchaseHistory" ? "active" : ""}`}
            >
              <Link to="/buyer/activity/purchaseHistory">Purchase history</Link>
            </li>
            <li className={`${currentPage === "BuyAgain" ? "active" : ""}`}>
              <Link to="/buyer/activity/buyAgain">Buy again</Link>
            </li>
            <li className={`${currentPage === "Watchlist" ? "active" : ""}`}>
              <Link to="/buyer/activity/watchlist">Watching</Link>
            </li>
            <li className={`${currentPage === "SavedSearches" ? "active" : ""}`}>
              <Link to="/buyer/activity/savedSearches">Saved searches</Link>
            </li>
            <li className={`${currentPage === "SavedSellers" ? "active" : ""}`}>
              <Link to="/buyer/activity/savedSellers">Saved sellers</Link>
            </li>
           
            <li>
            <Link to={"/login-seller"}>Sell</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ActivityNavPanel;
