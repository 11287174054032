import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DefaultLayout } from "../../../layout/DefaultLayout";
import { RootState } from "../../../utils/types";
import BuyerHeader from "../BuyerHeader";
import { dealsResponseSelector } from "../../../modules/buyer/deals/selectors";
import { getDeals } from "../../../modules/buyer/deals/getDeals";
import { BASE_URLS } from "../../../utils/api.urls";
import { Link, useNavigate } from "react-router-dom";
import { getDealsHeadings } from "../../../modules/buyer/deals/getDealsHeadings";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "react-toastify/dist/ReactToastify.css";

import refurbisImg from "../../../../src/assets/images/Layer 793.png";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { ToastContainer } from "react-toastify";
import BuyerFooter from "../buyerFooter";
import { constant } from "../../../utils/constant";
import { resetDetail } from "../../../modules/buyer/product/resetDetail";
function DailyDeal() {
  const dispatch = useDispatch();

  const settings = {
    dots: true,
    arrows: true,
    accessibility: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const navigate = useNavigate();

  const isFirstRender = useRef(true);
  let dealsResponse = useSelector<RootState, RootState["deals"]>(
    dealsResponseSelector
  );
  useEffect(() => {
    if (isFirstRender.current) {
      dispatch<any>(getDeals(""));
      dispatch<any>(getDealsHeadings());
    }
    isFirstRender.current = false;
  }, []);
  return (
    <div>
      <DefaultLayout>
        <BuyerHeader />
        <ToastContainer/>
        <main className="min-section">
          <section className="spotlight-deal">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <h1>Deal</h1>
                </div>
                <div className="col-md-8">
                  <div className="link-tabs">
                    <ul className="nav-list">
                      {dealsResponse?.dealsHeaders?.value?.map(
                        (deal: any, Index: number) => {
                          return Index < 4 ? (
                            <li className="">
                              <Link to={`/buyer/deal/${deal.Id}/${deal.Name}`}>
                                {deal.Name}
                              </Link>
                            </li>
                          ) : null;
                        }
                      )}
                      <li className="">
                        <Link to={`/buyer/dailyDeal`}>more</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="spotlight-wrap">
                <div className="row">
                  <div className="col-12">
                    <h3>SPOTLIGHT DEAL</h3>
                  </div>
                </div>
                <div className="row">
                  {dealsResponse?.deals?.data?.spotlightDeals.map(
                    (product, Index) => (
                      <div className="col-xl-6">
                        <div
                          className="deal-content"
                          onClick={() =>
                            dispatch<any>(resetDetail()).then(()=>   navigate(
                              `/buyer/productDetail/${product.productId}`
                            ))
                          }
                        >
                          <figure className="deals-media" style={{cursor:"pointer"}}>
                            <i className="timer-icon"></i>
                          
                              <img
                                className=""
                                style={{
                                  marginLeft: 25,
                                  marginRight: 20,
                                }}
                                src={
                                  BASE_URLS.AWS_URL +
                                  "/Thumb" +
                                  product.defaultImage
                                }
                                alt=""
                              />
                         
                          </figure>
                          <div className="deal-desc">
                            {/* <a href="#" className="wishlist-link">
                              <i className="wishlist-icon"></i>
                            </a> */}
                            <h4>
                              <div className="ui-ellipsis-3" style={{cursor:"pointer"}}>
                                {product.title}
                              </div>
                            </h4>
                            <div className="fi-price">
                              US ${product.buyNowPrice}
                            </div>
                            <div className="fi-discount">
                              <del>US ${product.actualPrice}</del> |{" "}
                              <strong>{product.salesDiscount}% OFF</strong>
                            </div>
                            <div className="fi-ship-status">
                              {product.isShippingFree ? "Free shipping" : ""}
                            </div>
                            <div className="fi-stock-status">
                              {product.quantity < constant
                              ?.ALMOST_GONE_QUANTITY ? "Almost Gone" : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </section>

          {dealsResponse?.deals?.data?.offerAndDeals.map((item, Index) => (
            <section className="recently-view-section camera">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="h2-block"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/buyer/deal/${item.dealId}/${item.dealName}`)
                      }
                    >
                      <h2 className="h2-section-title text-uppercase">
                        {item.dealName}
                      </h2>
                    </div>
                    <div className="recent-view-container">
                      {/* <a href="javascript:void(0);" className="prev-slide">
                        <i className="prev-icon"></i>
                      </a>
                      <a href="javascript:void(0);" className="next-slide">
                        <i className="next-icon"></i>
                      </a> */}

                      <div className="recent-listing-block">
                        <div className="swiper-container swiper dealSwiper">
                          <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            spaceBetween={20}
                            slidesPerView={4}
                            onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={() => console.log("slide change")}
                          >
                            {item.products.map((product, Index) => (
                              <SwiperSlide>
                                <div className="deal-list-item">
                                  <div
                                    className="recent-list-item"
                                    onClick={() =>
                                      dispatch<any>(resetDetail()).then(()=>   navigate(
                                        `/buyer/productDetail/${product.productId}`
                                      ))
                                    }
                                  >
                                    <i className="timer-icon"></i>
                                    <figure className="recent-media">
                                      <div style={{cursor:"pointer"}}>
                                        <img
                                          src={
                                            BASE_URLS.AWS_URL +
                                            "/Thumb" +
                                            product.defaultImage
                                          }
                                          alt=""
                                        />
                                </div>
                                    </figure>
                                    <div className="recent-details">
                                      <div className="recent-title">
                                        {product.title}
                                      </div>
                                      <div className="recent-price">
                                        ${product.buyNowPrice}
                                      </div>
                                      <div className="recent-price-small">
                                        <span className="price-cut">
                                          US ${product.actualPrice}
                                        </span>
                                        <span>|</span>
                                        <span className="price-bold">
                                          {product.salesDiscount}% OFF
                                        </span>
                                        <div className="recent-ship-status">
                                          {product.isShippingFree
                                            ? "Free shipping"
                                            : ""}
                                        </div>
                                        <div className="fi-stock-status">
                                          {product.quantity < 10
                                            ? "Almost Gone"
                                            : ""}
                                        </div>
                                        {/* <div className="referbished">
                                          <img src={refurbisImg} alt="" />
                                        </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ))}

          <div className="container">
            <section className="sellWatch">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <h4>Sell and watch the money roll in</h4>
                  <p>
                    Pocket extra cash this summer when you sell old loves on
                    efindit.
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="text-md-right">
                    <Link to="/login-seller" className="sellNowBtn">
                      Sell now <img src="images/Shape 5 copy 2.png" alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>

      <BuyerFooter/>
      </DefaultLayout>
    </div>
  );
}

export default DailyDeal;
