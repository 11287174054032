import styled from "styled-components";

export const ProfileContainer = styled.section`
  width: 100%;
`;
export const ProfileSubContainer = styled.div`
  background-image: linear-gradient(#2e3757, #3453c8);
  min-height: 80vh;
  margin: 0px 0px 2rem 0px;
  display: flex;
  padding: 1.7rem;
`;
export const ProfilePhotoContainer = styled.div`
  width: 162px;
  height: 188px;
  background-color: #fff;
  position: relative;
`;

export const ProfilePhotoImage = styled.div`
  height: 163px;
  overflow: hidden;
`;

export const ProfileButton = styled.button`
  background-color: #0eb5ec;
  font-size: 1.2rem;
  line-height: 2.4rem;
  color: #ffffff;
  font-weight: 400;
  border: 0;
  width: 100%;
`;

export const ProfileFormContainer = styled.div`
  display: block;
  margin-left: 2.6rem;
  max-width: 730px;
  width: 100%;
`;

export const FormGroup = styled.div`
  margin-bottom: 1rem;
`;
export const InputGroup = styled.div`
  display: block;
  position: relative;
  margin-bottom: 1rem;
`;
export const Label = styled.label`
  font-size: 1.2rem;
  line-height: 2.4rem;
  color: #ffffff;
  font-weight: 400;
`;
export const Input = styled.input.attrs((props: any) => ({
  type: "text",
  className: "form-control",
}))`
  font-size: 1.4rem;
  letter-spacing: 0px;
  color: #333333;
  font-weight: 400;
  height: 4rem;
`;

export const TextArea = styled.textarea.attrs((props: any) => ({
  className: "form-control",
}))`
  font-size: 1.4rem;
  letter-spacing: 0px;
  color: #333333;
  font-weight: 400;
  height: 18rem !important;
`;

export const SaveButton = styled.button`
  font-size: 1.2rem;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #3366cc;
  font-weight: 500;
  width: 115px;
  height: 35px;
  background: linear-gradient(to bottom, #fdfdfd, #e8e8e8);
  border: 0px;
  border-radius: 3px;
`;

export const CancelButton = styled(SaveButton)`
  color: #6f6f6f;
  margin-left: 1rem;
`;
