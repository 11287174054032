import { AppThunk } from "../../store";
import { SalesSuccess, SalesFailed } from "./slice";
import { fetchSales } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const getSales = (): AppThunk => async (dispatch) => {
  dispatch<any>(StartProgress());
  try {
    const UserData: any = await fetchSales();

    if (UserData.success === "N") {
      dispatch(SalesFailed(UserData));
    } else {
      dispatch(SalesSuccess(UserData));
    }
  } catch (err) {
    dispatch(SalesFailed(err));
  }
  dispatch<any>(EndProgress());
};
