import React from "react";
import { useDispatch } from "react-redux";
import { orderReturnRequestBuyer } from "../../../../modules/orders/orderReturnRequestBuyer";

const ReturnOrderModel = ({
  returnOrder,
  setReturnOrder,
  commonResponse,
}: any) => {
  const dispatch = useDispatch();

  return (
    <div
      className="modal modal-order-popup"
      id="myModal"
      tabIndex={-1}
      role="dialog"
      style={{ display: returnOrder.visible }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="order-modal">
            <div className="order-modal-head border-none">Return Order</div>

            <div className="form-group spacer-feedback-select">
              <label className="label-modal">
                Select from predefined message
              </label>
              <select
                className="form-control input-modal"
                value={returnOrder.reason !== null ? returnOrder.reason : ""}
                onChange={(e) =>
                  setReturnOrder({
                    ...returnOrder,
                    reason: e.target.value !== "" ? e.target.value : null,
                  })
                }
              >
                <option value="">Select</option>
                { localStorage.getItem("authorization") !== null &&
                        localStorage.getItem("role") === "Customer" ? 
                        commonResponse?.common?.returnReason?.map(
                          (item: any, Index: number) => (
                            <option key={item.key} value={item.value}>
                              {item.label}
                            </option>
                          )
                        )
                        
                        :      commonResponse?.common?.orderCancelledReason?.map(
                  (item: any, Index: number) => (
                    <option key={item.key} value={item.value}>
                      {item.label}
                    </option>
                  )
                )}
              </select>
            </div>

            <div className="form-group spacer-feedback-label">
              <label className="label-modal">Or Type anything</label>
              <textarea
                className="form-control buyer-textarea input-modal"
                rows={3}
                maxLength={500}
                placeholder="write some thing"
                value={
                  returnOrder.description !== null
                    ? returnOrder.description
                    : ""
                }
                onChange={(e) =>
                  setReturnOrder({
                    ...returnOrder,
                    description: e.target.value !== "" ? e.target.value : null,
                  })
                }
              ></textarea>
              <p>
                {returnOrder.description === null
                  ? 0
                  : returnOrder?.description?.length}
                /500
              </p>
            </div>

            <div className="modal-btn-group spacer-feedback-btn">
              <button
                className="modal-add-btn modal-cancel-order-btn"
                onClick={() => {
                  dispatch<any>(orderReturnRequestBuyer(returnOrder));
                  setReturnOrder({ ...returnOrder, visible: "none" });
                }}
                disabled={
                  returnOrder.description === null &&
                  returnOrder.reason === null
                    ? true
                    : false
                }
              >
                Return Order
              </button>
              <button
                className="modal-cancel-btn"
                onClick={() =>
                  setReturnOrder({
                    ...returnOrder,
                    visible: "none",
                    reason: null,
                    description: null,
                  })
                }
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnOrderModel;
