import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { DefaultLayout } from '../../../layout/DefaultLayout'
import { BASE_URLS } from '../../../utils/api.urls'
import BuyerFooter from '../buyerFooter'
import BuyerHeader from '../BuyerHeader'
import DefaultImage from "../../../assets/images/defaultImage.png";
import ADD from "../../../assets/images/add.png";
import CROSS from "../../../assets/images/cross.png";
import { set } from 'immer/dist/internal'
import ImageUploading from "react-images-uploading";
import { saveContactSeller } from '../../../modules/buyer/myEfindit/activity/api'
import { buyerContactSeller } from '../../../modules/buyer/myEfindit/activity/buyerContactSeller'
import { resetDetail } from '../../../modules/buyer/product/resetDetail'
import { activityResponseSelector } from '../../../modules/buyer/myEfindit/activity/selectors'
import { RootState } from '../../../utils/types'
import { notify } from '../../../modules/notification/notify'
import { constant } from '../../../utils/constant'
import { resetActivity } from '../../../modules/buyer/myEfindit/activity/resetActivity'
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
const ContactSeller = () => {

  const { state }: any = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let activityResponse = useSelector<RootState, RootState["activity"]>(
    activityResponseSelector
  );
 const [images, setImages] = React.useState<any>([]);

 const[ contactSeller, setContactSeller]=useState({
  message:"",
  orderId:"",
  sendCopy:false,
  Sellerid:"",
  productId:null,
 })


 const onImageChange = (imageList: any, addUpdateIndex: any) => {
  // data for submit
 

  setImages(imageList);
};
useEffect(()=>{
if(state)
setContactSeller({...contactSeller, orderId:state?.product?.orderId ? state?.product?.orderId : "0", Sellerid:state?.product?.sellerId, productId:state?.product?.productId ?state?.product?.productId:null})
},[state])

const reDirect=()=>{
  if(state?.route=="/buyer/checkout/")
  navigate(
    "/buyer/checkout",
    {
      state: {ProductId: state?.ProductId,
        quantity:state?.quantity,
      VariantId: state?.VariantId,
      type:state?.type,
      CartIds: state?.CartIds},

    }
  )
  else
  navigate( state?.route) ;
}
useEffect(()=>{
if(activityResponse?.saveDataSeller?.success==="Y")
{
  notify(activityResponse.saveDataSeller.message, constant.SUCCESS);
  reset();
// setTimeout ( navigate( state?.route) ;
setTimeout(  reDirect  , 3000);

}
if(activityResponse?.saveDataError)
{
  notify(activityResponse.saveDataError, constant.SUCCESS);
  
}
dispatch<any>(resetActivity());

  },[activityResponse?.saveDataSeller, activityResponse?.saveDataError])


const handleSubmit=()=>{
  let formData = new FormData();
  formData.append("contactSellerBuyerDto", JSON.stringify(contactSeller));

  for (var a = 0; a < images.length; a++) {
    if (images[a]["file"] !== undefined)
      formData.append("photos", images[a]["file"]);
  }
  dispatch<any>(buyerContactSeller(formData));
}

const reset=()=>{
  setContactSeller({...contactSeller,message:"",
  sendCopy:false,})
  setImages([]);
}

  return (
  <DefaultLayout>
    <BuyerHeader/>
    <ToastContainer/>
    <main className="min-section">
    <div className="container">
      <section>
        <div className="row">
          <div className="col-xl-9">
            <div className="report mt-5">
              <h1>Contact seller <span style={{color:"blue"}}>{state?.product?.storeName}</span></h1>
             
             {state?.product?.productName && <div className="prodInfo">
                <div className="title">
                 
               {state?.product?.productName}</div>
                <div className="info">
                  <div className="row">
                    <div className="col-auto">
                      <div className="p-img">
                        <img 
                         src={BASE_URLS.AWS_URL + "/Thumb" + state?.product?.productImage}
                         onError={({ currentTarget }) => {
                           currentTarget.onerror = null; // prevents looping
                           currentTarget.src = DefaultImage;
                         }}
                         alt=""
                        />
                      </div>
                    </div>
                    <div className="col-auto">
                      <label htmlFor="">Order Id:</label>
                      <p>{state?.product?.orderId}</p>
                    </div>
                    <div className="col-auto">
                      <label htmlFor="">Price</label>
                      <p>${state?.product?.totalPrice}</p>
                    </div>
                    {/* <div className="col-auto">
                      <label htmlFor="">Time Left</label>
                      <p>-</p>
                    </div> */}
                  </div>
                </div>
              </div>}

              <h3> <span className="red-star">*</span> Write to seller</h3>

              <div className="reportForm">
                <div className="formRow">
                  <textarea style={{color:"black"}} maxLength={500} name="" id="" value={contactSeller?.message} onChange={(e:any)=>setContactSeller({...contactSeller, message:e.target.value})} className="form-control" placeholder="Write some thing"></textarea>
                  <p> {contactSeller?.message === "" && (
                          <span style={{ color: "red" }}>*Required! </span>
                        )}
                {contactSeller?.message?.length}
                /500  
              </p>
            
                </div>
                <p>
                  To keep everyone safe on eFindit, please don't send messages that include contact info. Repeated attempts could lead to an account suspension or other penalties. 
                  Learn more about our member-to-member contact policy.
                </p>
                
                <div className="addPhoto">
                { state?.route=="/buyer/checkout/" ? null :     <div>
                  <label htmlFor="">Add photos   (Allowed format:jpg,png)</label>
                  <div className="num">{images.length} of 10</div>
                  <div className="">
                    <ImageUploading
                      multiple
                      value={images}
                      onChange={onImageChange}
                      maxNumber={10}
                      dataURLKey="data_url"
                      acceptType={["jpg", "png"]}
                      maxFileSize={10000000}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                        errors,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                        
                        
                          {/* <button type="button" onClick={onImageRemoveAll}>
                            Remove all images
                          </button> */}
                          <div className="d-flex image-flex-wrapper">
                            {imageList.map((image, index) => (
                              <div key={index} className="image-item ">
                                <img
                                  src={image["data_url"]}
                                  alt=""
                                  width="100"
                                  height="120"
                                />
                                <div className="d-flex justify-content-between">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                      onImageUpdate(index);
                                    }}
                                  >
                                    Update
                                  </button>
                                  <div
                                    className="remove-img-close"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                    
                                      onImageRemove(index);
                                    }}
                                  >
                                    <img src={CROSS} alt="" />
                                  </div>
                                </div>
                              </div>
                            ))}
                            {imageList.length < 10 && (
                              <div
                                className="add-img-upload d-flex"
                                style={
                                  isDragging ? { color: "red" } : undefined
                                }
                                // type="button"
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                <img
                                  src={ADD}
                                  alt=""
                                  width="100"
                                  height="120"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                            )}
                            {errors?.maxFileSize && (
                              <span>
                                Selected file size exceed maxFileSize 10 MB
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                  {/* <div className="photoBlock">
                    <div className="addBtn">
                      +
                    </div>
                  </div> */
                  }
                  </div>}
                  <div className="custom-check d-inline-block align-middle">
                    Send a copy to my email address
                    <div className="wrap">
                      <input type="checkbox" id="copyemail" checked={contactSeller?.sendCopy} onChange={()=>setContactSeller({...contactSeller, sendCopy:!contactSeller.sendCopy})} />
                      <label htmlFor="copyemail"></label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btnBlock">
                <button onClick={handleSubmit} disabled={
                  contactSeller.message === "" 
                    ? true
                    : false
                } className="continue">Send message</button>
                <button   onClick={() => {
                  dispatch<any>(
                    resetDetail()).then(()=>
                    
                   { if(state?.route=="/buyer/checkout/")
                   navigate(
                     "/buyer/checkout",
                     {
                       state: {ProductId: state?.ProductId,
                         quantity:state?.quantity,
                       VariantId: state?.VariantId,
                       type:state?.type,
                       CartIds: state?.CartIds},
                 
                     }
                   )
                   else
                    navigate( state?.route)     })
                                    
                                    }} className="cancel">CANCEL</button>
              </div>

              <p className="note">
                Don't exchange contact info to <a href="">buy or sell outside efindit</a>. We scan and analyze messages to identify potential fraud and policy. 
                Sometimes it will keep us from sending your message, even when there is no intention to commit fraud.
              </p>
            </div>
          </div>

          <div className="col-xl-3 mt-5">
            <div className="helpBlock">
              <h4>Help</h4>
              <p className="subTitle">To keep the efindit marketplace functioning effectively, we know it is important for members to be able to communicate easily with each other.</p>

              <h5>We don't allow our members to:</h5>

              <ul>
                <li>Make offers to buy and sell outside of efindit</li>
                <li>Send spam</li>
                <li>Threaten others, or use profanity or hate speech</li>
                <li>Exchange any personal information (except names), including email addresses, phone numbers, or other contact information</li>
                <li>Send viruses, malicious code, or software through our messaging service</li>
              </ul>

              <p>To find out more about our policies related to member-to-member communications, please read our full policy guidelines below.</p>
            </div>
          </div>
        </div>
      </section>

    </div>
  </main>
    <BuyerFooter/>
  </DefaultLayout>
  )
}

export default ContactSeller