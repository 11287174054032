import { AppThunk } from "../../../store";
import { HomepageFailed, HomepageSuccess } from "./slice";
import { fetchHomepage } from "./api";
import { EndProgress } from "../../progressBar/endProgress";
import { StartProgress } from "../../progressBar/startProgress";

export const getHomepage = (): AppThunk => async (dispatch) => {
  dispatch<any>(StartProgress());

  try {
    const data: any = await fetchHomepage();

    if (data.success === "N") {
      dispatch(HomepageFailed(data));
    } else {
      dispatch(HomepageSuccess(data));
    }
  } catch (err) {
    dispatch(HomepageFailed(err));
  }
  dispatch<any>(EndProgress());
};
