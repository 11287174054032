import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCategoryTree } from "../../../modules/categories/getCategoryTree";
import { CategoryResponseSelector } from "../../../modules/categories/selector";
import { getCommonData } from "../../../modules/common/getCommonData";
import { CommonResponseSelector } from "../../../modules/common/selectors";
import { RootState } from "../../../utils/types";
import CategorySelect from "./CategorySelect";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-toastify/dist/ReactToastify.css";
import ImageUploading from "react-images-uploading";
import "react-tabs/style/react-tabs.css";
import { viewItemSpecification } from "../../../modules/categories/viewItemSpecification";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { string, object, array } from "yup";
import { SellerProductResponseSelector } from "../../../modules/sellerProduct/selector";
import { constant } from "../../../utils/constant";
import { notify } from "../../../modules/notification/notify";
import { saveListingDetails } from "../../../modules/sellerProduct/saveListingDetails";
import { ToastContainer } from "react-toastify";
import { reset } from "../../../modules/sellerProduct/reset";
import { getSellerProductDetails } from "../../../modules/sellerProduct/getSellerProductDetails";
import { BASE_URLS } from "../../../utils/api.urls";
import { removeImage } from "../../../modules/sellerProduct/removeImage";
import ADD from "../../../assets/images/add.png";
import CROSS from "../../../assets/images/cross.png";
import MyCustomUploadAdapterPlugin from "../../../modules/image/imageUpload";
import CategoryTree from "./categoryTree";
import { resetCategories } from "../../../modules/categories/resetCategories";
import TagsInput from "react-tagsinput";

import "react-tagsinput/react-tagsinput.css";
import { getPlatformFees } from "../../../modules/sellerProduct/getPlatformFees";
import { useNavigate } from "react-router-dom";

interface Props {
  nextStep: any;
  setProductId: any;
  productId: any;
  setstep?: any;
}

const CreateListing = ({
  nextStep,
  setProductId,
  productId,
  setstep,
}: Props) => {
  const categoryResponse = useSelector<RootState, RootState["categories"]>(
    CategoryResponseSelector
  );
  const commonResponse = useSelector<RootState, RootState["common"]>(
    CommonResponseSelector
  );

  const sellerProductResponse = useSelector<
    RootState,
    RootState["sellerProduct"]
  >(SellerProductResponseSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let submitAction: string | undefined = undefined;
  const [draft, setDraft] = useState(false);

  const isFirstRender = useRef(true);
  const isfirst = useRef(true);
  const [editcat, setEditcat] = useState([{}]);
  const [tags, setTags] = useState([]);

  const [breadcrumb, setBreacrumb] = useState<any>([{}]);
  const [selectedCategory, setSelectedCategory] = useState<any>({ id: "" });
  const [categoryFlow, setCategoryFlow] = useState<any>([]);
  const [itemSpecification, setItemSpecification] = useState<any>([]);
  // const firstRef = React.useRef<any>(false);
  const [firstRef, setfirstRef] = React.useState(false);
  const [check, setCheck] = React.useState(0);
  const [prodTimes, setProdTimes] = React.useState(0);

  const [images, setImages] = React.useState<any>([]);
  const maxNumber = 10;
  //state for form data
  interface formValues {
    categoryId: string;
    Title: string;
    isBoldTitleRequired: boolean;
    condition: string | number;
    note: string;
    upc: string;
    isdraft: boolean;
    additonalFeatures: string;
    ProductSpecifications: any;
    // ProductVariant: any;
    tags: any;
    DefaultImage: string;
  }

  const [listingData, setListingData] = useState<formValues>({
    categoryId: "",
    Title: "",
    isBoldTitleRequired: false,
    condition: "",
    note: "",
    isdraft: false,
    upc: "",
    additonalFeatures: "",
    ProductSpecifications: [{ title: "", uid: "", text: "" }],
    // ProductVariant: [],
    tags: [],
    DefaultImage: "",
  });
  const handleTagChange = (tags: any) => {
    let newTags: any = [];
    tags.forEach((element: any) => {
      newTags.push({ tagName: element });
    });
    setListingData({ ...listingData, tags: newTags });
    setTags(tags);
  };
  const callGetCategories = () => {
    if (categoryResponse?.categoryTree == null)
      dispatch<any>(getCategoryTree());
    if (commonResponse?.common == null) dispatch<any>(getCommonData());
    dispatch<any>(getPlatformFees());
    // if (sellerProductResponse.sellerProductDetails === null)
    //   dispatch<any>(getSellerProductDetails(localStorage.getItem("productId")));
  };

  useEffect(() => {
    if (isFirstRender.current) {
      callGetCategories();
    }
    isFirstRender.current = false;
  }, []);
  useEffect(() => {
    if (
      categoryResponse.template?.value[0]?.ItemSpec !== undefined &&
      selectedCategory !== undefined
    ) {
      if (
        sellerProductResponse?.sellerProductDetails?.value?.length > 0 &&
        selectedCategory.id ===
          sellerProductResponse?.sellerProductDetails?.value[0]?.CategoryId
      ) {
        type jsonObj2 = {
          Options: string;
          Required: string;
          Title: string;
          UID: string;
          Text: string;
        };
        let newItemSpec2: jsonObj2[] = [];

        JSON.parse(categoryResponse?.template?.value[0]?.ItemSpec).forEach(
          (element: any, Index: number) => {
            let uid= JSON.parse(sellerProductResponse?.sellerProductDetails?.value[0]
              .ItemSpecification)!==null ?  JSON.parse(sellerProductResponse?.sellerProductDetails?.value[0]
            .ItemSpecification).findIndex((item:any) => item.Uid == element.UID) : -1;
         
            newItemSpec2.push({
              Options: element.Options,
              Required: element.Required,

              Title: element.Title,
              UID: element.UID,
              Text:
              uid !== -1 &&
                JSON.parse(
                  sellerProductResponse?.sellerProductDetails?.value[0]
                    ?.ItemSpecification
                )[uid].Text !== undefined
                  ? JSON.parse(
                      sellerProductResponse?.sellerProductDetails?.value[0]
                        ?.ItemSpecification
                    )[uid].Text
                  : "",
            });
          }
        );

        setItemSpecification(newItemSpec2);

        type jsonObj = {
          title: string;
          uid: string;
          text: string;
        };

        let newItemSpec: jsonObj[] = [];

        // JSON.parse(
        //   sellerProductResponse?.sellerProductDetails?.value[0]
        //     ?.ItemSpecification
        // ) !== null &&
        //   JSON.parse(
        //     sellerProductResponse?.sellerProductDetails?.value[0]
        //       ?.ItemSpecification
        //   ).map((itemSpec: any) => {
        //     newItemSpec.push({
        //       title: itemSpec.Title,
        //       uid: itemSpec.Uid,
        //       text: itemSpec.Text,
        //     });
        //   });
        JSON.parse(categoryResponse?.template?.value[0]?.ItemSpec).forEach(
          (element: any, Index: number) => {
            let uid= JSON.parse(sellerProductResponse?.sellerProductDetails?.value[0]
              .ItemSpecification)!==null ?  JSON.parse(sellerProductResponse?.sellerProductDetails?.value[0]
            .ItemSpecification).findIndex((item:any) => item.Uid == element.UID) : -1;
            newItemSpec.push({
            

              title: element.Title,
              uid: element.UID,
              text:
              uid !== -1 &&
                JSON.parse(
                  sellerProductResponse?.sellerProductDetails?.value[0]
                    ?.ItemSpecification
                )[uid].Text !== undefined
                  ? JSON.parse(
                      sellerProductResponse?.sellerProductDetails?.value[0]
                        ?.ItemSpecification
                    )[Index].Text
                  : "",
            });
          }
        );
        setListingData({
          ...listingData,
          ProductSpecifications: newItemSpec,
        });
        isfirst.current = false;
      } else {

        type jsonObj2 = {
          Options: string;
          Required: string;
          Title: string;
          UID: string;
          Text: string;
        };
        let newItemSpec2: jsonObj2[] = [];

        JSON.parse(categoryResponse.template.value[0].ItemSpec).forEach(
          (element: any, Index: number) => {
            newItemSpec2.push({
              Options: element.Options,
              Required: element.Required,

              Title: element.Title,
              UID: element.UID,
              Text: "",
            });
          }
        );

        setItemSpecification(newItemSpec2);

        type jsonObj = {
          title: string;
          uid: string;
          text: string;
        };

        let newItemSpec: jsonObj[] = [];
        JSON.parse(categoryResponse.template.value[0].ItemSpec).map(
          (itemSpec: any) => {
            newItemSpec.push({
              title: itemSpec.Title,
              uid: itemSpec.UID,
              text: "",
            });

            // if(itemSpec.Required == 'Y') {
            //  formSchema.push({name : "asds"})
            // }
          }
        );
        setListingData({
          ...listingData,
          ProductSpecifications: newItemSpec,
        });
      }
    } else {
      setListingData({
        ...listingData,
        ProductSpecifications: null,
      });
      setItemSpecification([]);
    }
  }, [categoryResponse.template]);
  
  useEffect(() => {
    selectedCategory &&
      setCategoryFlow([...categoryFlow, selectedCategory.name]);

    if (
      selectedCategory?.id !== undefined &&
      categoryResponse?.categoryTree !== null
    ) {
      let x = findParent(
        categoryResponse?.categoryTree?.data,
        selectedCategory.id
      );
      setBreacrumb(x);
      // setCheck(0);
    }
    // if (
    //   (selectedCategory && selectedCategory.childs == null) ||
    //   (selectedCategory && selectedCategory.childs.length == 0)
    // ) {
    if (selectedCategory) {
      setListingData({
        ...listingData,
        categoryId: selectedCategory.id,
      });
      if (sellerProductResponse.sellerProductDetails !== null)
        selectedCategory.id !== "" &&
          prodTimes == 1 &&
          dispatch<any>(viewItemSpecification(selectedCategory.id));
      else
        selectedCategory.id !== "" &&
          dispatch<any>(viewItemSpecification(selectedCategory.id));
    }
  }, [selectedCategory.id]);

  // useEffect(() => {
  //   if (localStorage.getItem("reload") == null) {
  //     localStorage.setItem("reload", "true");
  //     window.location.reload();
  //   }
  // }, []);

  useEffect(() => {
    // dispatch<any>(EndProgress());
    if (sellerProductResponse.saveData != null) {
      if (sellerProductResponse.saveData.success == "Y") {
        notify(sellerProductResponse.saveData.message, constant.SUCCESS);
        // resetForm();
//ddd
          if(draft)
          {
           navigate("/seller/draftListing");
          }
          else
          {
            setProductId(sellerProductResponse.saveData.productId);
            localStorage.setItem(
              "productId",
              sellerProductResponse.saveData.productId
            );
            // localStorage.removeItem("reload");
            // dispatch<any>(reset())
            //   .then(dispatch<any>(resetCategorySuccess()))
            //   .then(
            //     dispatch<any>(
            //       getSellerProductDetails(localStorage.getItem("productId"))
            //     )
            //   )
            //   .then(nextStep());
    
            dispatch<any>(
              getSellerProductDetails(localStorage.getItem("productId"))
            ).then(nextStep());
          }
      

      } else if (sellerProductResponse.saveData.success == "N") {
        notify(sellerProductResponse.saveData.message, constant.ERROR);
      }
      dispatch<any>(reset("saveData"));
      // dispatch<any>(resetBanner());
    }
  }, [sellerProductResponse.saveData]);

  useEffect(() => {
    if (
      sellerProductResponse?.sellerProductDetails?.value?.length > 0 &&
      prodTimes >= 0
    ) {
      setListingData({
        ...listingData,
        categoryId: sellerProductResponse?.sellerProductDetails?.value[0]
          .CategoryId
          ? sellerProductResponse?.sellerProductDetails?.value[0].CategoryId
          : "",
        Title: sellerProductResponse?.sellerProductDetails?.value[0].Title
          ? sellerProductResponse?.sellerProductDetails?.value[0].Title
          : "",
        isBoldTitleRequired: sellerProductResponse?.sellerProductDetails
          ?.value[0].IsBoldTitleRequired
          ? sellerProductResponse?.sellerProductDetails?.value[0]
              .IsBoldTitleRequired
          : false,
        condition: sellerProductResponse?.sellerProductDetails?.value[0]
          .Condition
          ? sellerProductResponse?.sellerProductDetails?.value[0].Condition
          : "",
        note: sellerProductResponse?.sellerProductDetails?.value[0].Note
          ? sellerProductResponse?.sellerProductDetails?.value[0].Note
          : "",
        isdraft: sellerProductResponse?.sellerProductDetails?.value[0].Isdraft
          ? sellerProductResponse?.sellerProductDetails?.value[0].Isdraft
          : false,
        upc: sellerProductResponse?.sellerProductDetails?.value[0].Upc
          ? sellerProductResponse?.sellerProductDetails?.value[0].Upc
          : "",
        additonalFeatures: sellerProductResponse?.sellerProductDetails?.value[0]
          .AdditonalFeatures
          ? sellerProductResponse?.sellerProductDetails?.value[0]
              .AdditonalFeatures
          : "",

        // ProductVariant: sellerProductResponse?.sellerProductDetails?.value[0]
        //   .Variants
        //   ? sellerProductResponse?.sellerProductDetails?.value[0].Variants
        //   : [],
        tags: sellerProductResponse?.sellerProductDetails?.value[0].Taggings
          ? sellerProductResponse?.sellerProductDetails?.value[0].Taggings
          : [],
        DefaultImage: sellerProductResponse?.sellerProductDetails?.value[0]
          .DefaultImage
          ? sellerProductResponse?.sellerProductDetails?.value[0].DefaultImage
          : "",

        // Isdraft: sellerProductResponse?.sellerProductDetails?.value[0].DomesticShipping ?sellerProductResponse?.sellerProductDetails?.value[0].DomesticShipping  : false,
      });
      let imagesNew: any = [];

      sellerProductResponse?.sellerProductDetails?.value[0].Images.forEach(
        (element) => {
          if (element.AttributeId === null) {
            let image: any = {
              data_url: BASE_URLS.AWS_URL + "/Thumb" + element.ImageName,
              id: element.Id,
              productId: localStorage.getItem("productId"),
            };
            imagesNew.push(image);
          }
        }
      );
      setImages(imagesNew);

      let tagsData: any = [];
      sellerProductResponse?.sellerProductDetails?.value[0]?.Taggings?.forEach(
        (element: any) => {
          tagsData.push(element.TagName);
        }
      );
      setTags(tagsData);
      sellerProductResponse?.sellerProductDetails?.value[0].CategoryId &&
        //   dispatch<any>(
        //     viewItemSpecification(
        //       sellerProductResponse?.sellerProductDetails?.value[0].CategoryId
        //     )
        //   );
        setSelectedCategory({
          id: sellerProductResponse?.sellerProductDetails?.value[0].CategoryId,
        });
      setProdTimes(1);
    } else {
      setItemSpecification([]);
    }
  }, [sellerProductResponse.sellerProductDetails, prodTimes]);

 
  const findParent = (
    arr: any,
    id: string,
    hierarchy: any = [] // Use an array here to store parents instead of using an external variable.
  ) => {
    for (let i = 0; i < arr.length; i++) {
      // If id is found, push it to the result array, stop the loop, then return the result.
      if (arr[i].id === id) {
        hierarchy.push(arr[i]);
        return hierarchy;
      }

      // If id is not found, proceed to its children
      if (arr[i].childs && arr[i].childs.length) {
        const childResult: any = findParent(
          arr[i].childs,
          id,
          [...hierarchy, arr[i]] // Pass the found parents so far to its child recursion.
        );

        // Only stop the loop and return the result if id is found in child.
        if (childResult) {
          return childResult;
        }
      }

      // Proceed to the next loop if id is not found in current item AND its offspring children.
    }

    // Return null if id is not found in this branch.
    return null;
  };

  const onImageChange = (imageList: any, addUpdateIndex: any) => {
    // data for submit
    if (images.length === imageList.length && addUpdateIndex !== undefined) {

      if (images[addUpdateIndex].id !== undefined)
        dispatch<any>(removeImage(images[addUpdateIndex]));
    }

    setImages(imageList);
  };
  const onEditorStateChange = (event: any, editor: any) => {
    const data = editor.getData();
    setListingData({
      ...listingData,
      additonalFeatures: data,
    });
  };

  const handleItemSpecification = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // listingData.ProductSpecifications.map((proSpec: any) => {
    //   if (proSpec.title == event.target.name) {
    //     proSpec.text = event.target.value;
    //   }
    // });
    let data = [...listingData.ProductSpecifications];
    data.forEach((proSpec) => {
      if (proSpec.title == event.target.name) {
        proSpec.text = event.target.value;
      }
    });
    setListingData({ ...listingData, ProductSpecifications: data });
    itemSpecification.map((proSpec: any) => {
      if (proSpec.Title == event.target.name) {
        proSpec.Text = event.target.value;
      }
    });

  };
  const onChange = (e: any) => {
    setListingData({
      ...listingData,
      [e.target.name]: e.target.value,
    });
  };

  const handleBoldCheck = (e: any) => {
    let checked = e.target.checked == true ? true : false;
    setListingData({
      ...listingData,
      isBoldTitleRequired: checked,
    });
  };

  const submitFormData = (values: any) => {
    // checking if value of first name and last name is empty show error else take to step 2
    if (images.length === 0) notify("Image is required", constant.ERROR);
    else {
      let formData = new FormData();
      formData.append("listing", JSON.stringify(values));

      for (var a = 0; a < images.length; a++) {
        if (images[a]["file"] !== undefined)
          formData.append("files", images[a]["file"]);
      }
      dispatch<any>(saveListingDetails(formData));
    }
    // setProductId("41F84D18-02D5-4835-BDE0-5D22740FB8C8");
    // nextStep();
  };

  // const formSchema = {
  //   name: string().required("Name is required").min(7, 'Message'),
  // };

  const ListingSchema = Yup.object().shape({
    Title: Yup.string()
      .min(2, "min characters 2!")
      .max(500, "max charactes 500")
      .required("*Required!")
      .test(
        "Must contain at least 1 alphabet",
        " is not valid",
        (value, context) => {
          if (value === undefined || value === null) return true;
          const hasUpperCase = /[A-Z]/.test(value);
          const hasLowerCase = /[a-z]/.test(value);

          let validConditions = 0;
          const numberOfMustBeValidConditions = 1;
          const conditions = [hasLowerCase, hasUpperCase];
          conditions.forEach((condition) =>
            condition ? validConditions++ : null
          );
          if (validConditions >= numberOfMustBeValidConditions) {
            return true;
          }
          return false;
        }
      ),

    // upc: Yup.string()
    //   .typeError("you must specify a number")
    //   .matches(/^[0-9]+$/, "Must be only digits")
    //   .min(12, "Min value 12 digit.")
    //   .max(12, "Max value 12 digit.")
    //   .required("*Required!"),
    condition: Yup.string().required("*Required!"),
    categoryId: Yup.string().required("*Required!"),
    additonalFeatures: Yup.string()
      .nullable()
      .max(4000, "Max characters limit reached"),
    //   test: array()
    //  .of(object().shape(formSchema))
  });
  return (
    <>
      <ToastContainer />
      <div className="tabs-nav-block">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <div
              className="nav-link active"
              style={{
                cursor: "pointer",
              }}
              onClick={() => setstep(1)}
            >
              Listing detail
            </div>
          </li>
          <li className="nav-item">
            <div
              className="nav-link"
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                sellerProductResponse?.sellerProductDetails &&
                sellerProductResponse?.sellerProductDetails?.value[0]
                  ?.Format !== null &&
                setstep(2)
              }
            >
              Selling details
            </div>
          </li>
          <li className="nav-item">
            <div
              className="nav-link"
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                sellerProductResponse?.sellerProductDetails &&
                sellerProductResponse?.sellerProductDetails?.value[0]
                  ?.DomesticCourierServiceId !== null &&
                setstep(3)
              }
            >
              Shipping details
            </div>
          </li>
          <li className="nav-item">
            <div
              className="nav-link"
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                sellerProductResponse?.sellerProductDetails &&
                sellerProductResponse?.sellerProductDetails?.value[0]
                  ?.VolumePricing &&
                setstep(4)
              }
            >
              Sell it faster
            </div>
          </li>
        </ul>
      </div>
      <Formik
        initialValues={listingData}
        validationSchema={ListingSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          let data =
            productId === ""
              ? { ...values }
              : { ...values, productId: productId };
          if (submitAction === "primary") {
            data.isdraft = true;
            submitFormData(data);
            setDraft(true);
          } else {
            // set state to show warning message
            
           if( sellerProductResponse?.sellerProductDetails?.value[0].IsListingActive==null || sellerProductResponse?.sellerProductDetails?.value[0].IsListingActive==false )
             data.isdraft = true;
            submitFormData(data);
          }
        }}
      >
        {({ handleSubmit, errors, touched }) => (
          <>
            <Form>
              <div className="seller-form-block">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="label-seller">
                          <span className="red-star">*</span>Title
                        </label>
                        <input
                          type="text"
                          name="Title"
                          onChange={onChange}
                          value={listingData.Title}
                          className="form-control input-seller"
                          placeholder="Search your order"
                        />
                        {errors.Title && touched.Title ? (
                          <div className="required">{errors.Title}</div>
                        ) : null}
                        {/* <div className="checkbox-seller">
                          <input
                            type="checkbox"
                            name="isBoldTitleRequired"
                            onChange={handleBoldCheck}
                            value={
                              listingData.isBoldTitleRequired ? "yes" : "no"
                            }
                          />
                          <span>
                            Stand out with a bold title in search results
                            ($2.00)
                          </span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <div>
                    <select
                      className="form-select form-select-sm"
                      name="ParentCategoryId"
                      value={listingData.categoryId}
                      onChange={(e) =>
                        setSelectedCategory({ id: e.target.value })
                      }
                    >
                      <option value="">None</option>
                      <CategoryTree></CategoryTree>
                    </select>
                  </div> */}

                  <div className="col-md-2" style={{}}>
                    <div className="form-group">
                      <label className="label-seller">
                        <span className="red-star">*</span>Category
                      </label>

                      <select
                        className="form-control input-seller"
                        name="ParentCategoryId"
                        value={listingData.categoryId}
                        onChange={(e) =>
                          setSelectedCategory({ id: e.target.value })
                        }
                      >
                        <option value="">None</option>
                        <CategoryTree></CategoryTree>
                      </select>

                      {errors.categoryId && touched.categoryId ? (
                        <div className="required">{errors.categoryId}</div>
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="row">
                    {categoryResponse.categoryTree && (
                      <CategorySelect
                        categoryData={categoryResponse.categoryTree.data}
                        setCategory={setSelectedCategory}
                        title="Category"
                        data={editcat}
                        setCheck={setCheck}
                        check={check}
                      />
                    )}

                    {breadcrumb.map((element: any, Index: number) => {
                      if (
                        element?.childs !== undefined &&
                        element?.childs !== null &&
                        element?.childs?.length > 0
                      )
                        return (
                          <CategorySelect
                            categoryData={element.childs}
                            setCategory={setSelectedCategory}
                            title="Sub Category"
                            data={editcat.slice(Index + 1)}
                            setCheck={setCheck}
                            check={check}
                          />
                        );
                      else return null;
                    })}
                  </div> */}
                  <div>
                    {/* {categoryFlow &&
                      categoryFlow.map((cflow: any, cflowIndex: any) => (
                        <span key={cflowIndex}>
                          {cflow} {"> "}  
                        </span>
                      ))} */}

                    {breadcrumb &&
                      breadcrumb.map((cflow: any, cflowIndex: any) => (
                        <span key={cflowIndex}>
                          {cflow.name} {"> "}
                        </span>
                      ))}
                    <span> change category</span>
                  </div>
                  <div className="">
                    <ImageUploading
                      multiple
                      value={images}
                      onChange={onImageChange}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                      acceptType={["jpg", "png"]}
                      maxFileSize={10000000}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                        errors,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          <label className="label-seller">
                            <span className="red-star">*</span> Add images
                            (Allowed format:jpg,png)
                          </label>
                          &nbsp;
                          <button
                            className="btn btn-primary"
                            style={isDragging ? { color: "red" } : undefined}
                            type="button"
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            Click or drop here
                          </button>
                          <h6>Max images:10</h6>
                          &nbsp;
                          {/* <button type="button" onClick={onImageRemoveAll}>
                            Remove all images
                          </button> */}
                          <div className="d-flex image-flex-wrapper">
                            {imageList.map((image, index) => (
                              <div key={index} className="image-item ">
                                <img
                                  src={image["data_url"]}
                                  alt=""
                                  width="100"
                                  height="120"
                                />
                                <div className="d-flex justify-content-between">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                      onImageUpdate(index);
                                    }}
                                  >
                                    Update
                                  </button>
                                  <div
                                    className="remove-img-close"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      if (image.id !== undefined)
                                        dispatch<any>(removeImage(image));
                                      onImageRemove(index);
                                    }}
                                  >
                                    <img src={CROSS} alt="" />
                                  </div>
                                </div>
                              </div>
                            ))}
                            {imageList.length < 10 && (
                              <div
                                className="add-img-upload d-flex"
                                style={
                                  isDragging ? { color: "red" } : undefined
                                }
                                // type="button"
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                <img
                                  src={ADD}
                                  alt=""
                                  width="100"
                                  height="120"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                            )}
                            {errors?.maxFileSize && (
                              <span>
                                Selected file size exceed maxFileSize 10 MB
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                  <div className="row">
                    {/* <div className="col-md-6">
                      <div className="form-group">
                        <label className="label-seller">
                          <span className="red-star">*</span>UPC
                        </label>
                        <input
                          type="text"
                          className="form-control input-seller"
                          name="upc"
                          value={listingData.upc}
                          onChange={onChange}
                        />
                        {errors.upc && touched.upc ? (
                          <div className="required">{errors.upc}</div>
                        ) : null}
                      </div>
                    </div> */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="label-seller">
                          <span className="red-star">*</span>Condition
                        </label>

                        <select
                          className="form-control input-seller"
                          name="condition"
                          value={listingData.condition}
                          onChange={onChange}
                        >
                          <option value="">Select</option>
                          {commonResponse.common &&
                            commonResponse.common.condition.map(
                              (condition: any) => (
                                <option
                                  key={condition.key}
                                  value={condition.value}
                                >
                                  {condition.label}
                                </option>
                              )
                            )}
                        </select>
                        {errors.condition && touched.condition ? (
                          <div className="required">{errors.condition}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="item-group">
                        <label className="label-seller">Item specifics</label>
                        <br />
                        <small>
                          Buyers need these item specifics about your item
                        </small>
                      </div>
                    </div>
                    {itemSpecification &&
                      itemSpecification.map((item: any, Index: any) => (
                        <div className="col-md-3" key={item.UID}>
                          <div className="form-group">
                            <label className="label-seller item-specify">
                              {item.Required == "Y" ? (
                                <span className="red-star">*</span>
                              ) : (
                                ""
                              )}
                              {item.Title}
                            </label>
                            <div>
                              <input
                                type="text"
                                list={`datalist${Index}`}
                                className="form-control input-seller"
                                placeholder="Search"
                                name={item.Title}
                                key={item.UID}
                                required={item.Required == "Y" ? true : false}
                                value={item.Text}
                                onChange={(e) => handleItemSpecification(e)}
                              />
                              <datalist id={`datalist${Index}`}>
                                {item &&
                                  item.Options.split(",").map(
                                    (itemOptions: any, itemIndex: any) => (
                                      <option className="w-50" key={itemIndex}>
                                        {" "}
                                        {itemOptions}
                                      </option>
                                    )
                                  )}
                              </datalist>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div>
                    <label className="label-seller">Tags</label>
                    <TagsInput
                      value={tags}
                      onChange={(tags) => handleTagChange(tags)}
                    />
                  </div>
                  <div className="row row-additional">
                    <div className="col-md-12">
                      <div className="item-group">
                        <label className="label-seller">Additional</label>
                        <br />
                        <p className="sub-text">
                          Buyers may also be interested in these item specifics
                        </p>
                        {categoryResponse.categoryTree && (
                          <CKEditor
                            editor={ClassicEditor}
                            data={listingData.additonalFeatures}
                            onChange={onEditorStateChange}
                            config={{
                              extraPlugins: [MyCustomUploadAdapterPlugin],
                            }} //use
                          />
                        )}
                        {errors.additonalFeatures &&
                        touched.additonalFeatures ? (
                          <div className="required">
                            {errors.additonalFeatures}
                          </div>
                        ) : null}
                        <p className="para-font-size">Max characters:0-4000</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="seller-bottom-block">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="fees-block">
                        <div className="fee-text">
                          Fees:<i className="fee-icon"></i>{" "}
                          <span className="fee-price">{sellerProductResponse?.platformFees?.data?.platFormFee}%</span>
                        </div>
                      </div>
                      <div className="seller-bottom-buttons">
                        <div className="seller-btm-text">
                          By selecting List item, you agree to pay the above
                          fees; accept the eFindit{" "}
                          <a href="#">User agreement</a>,{" "}
                          <a href="#">Payments terms</a> of Use and Marketing
                          terms of Service; acknowledge reading the User Privacy
                          Notice; and assume full responsibility for the item
                          offered and the content of your listing.
                        </div>
                      </div>
                      <div className="seller-button">
                        <button type="submit" className="list-button">
                          Save & next
                        </button>

                        <button
                          className="list-button bg-none"
                          type="button"
                          onClick={() => {
                            submitAction = "primary";
                            handleSubmit();
                          }}
                        >
                          Save as draft
                        </button>
                        {/* <button className="list-button bg-none">Cancel</button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default CreateListing;
