import { AppThunk } from "../../../store";
import { SaveSellerFailed, SaveSellerSuccess } from "./slice";
import { postSaveSeller } from "./api";
import { EndProgress } from "../../progressBar/endProgress";
import { StartProgress } from "../../progressBar/startProgress";

export const saveSeller =
  (params: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());

    try {
      const data: any = await postSaveSeller(params);
      

      if (data.success === "N") {
        
        dispatch(SaveSellerFailed(data));
      } else {
        
        dispatch(SaveSellerSuccess(data));
      }
    } catch (err) {
      
      dispatch(SaveSellerFailed(err));
    }
    dispatch<any>(EndProgress());
  };
