import React, { useEffect, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { getMessages } from '../../../../modules/buyer/myEfindit/message/getMessages';
import { messageResponseSelector } from '../../../../modules/buyer/myEfindit/message/selectors';
import { RootState } from '../../../../utils/types';
import BuyerHeader from '../../BuyerHeader'
import GroupTab from "../groupTab";
import moment from "moment";
import { useParams, Link, useLocation, useNavigate } from 'react-router-dom';
import { notify } from '../../../../modules/notification/notify';
import { constant } from '../../../../utils/constant';
import { reset } from '../../../../modules/buyer/myEfindit/message/reset';
import { deleteMessageByUser } from '../../../../modules/buyer/myEfindit/message/deleteMessageByUser';
import { ToastContainer } from 'react-toastify';
import { archivesMessage } from '../../../../modules/buyer/myEfindit/message/archivesMessage';
import { readUnReadMessage } from '../../../../modules/buyer/myEfindit/message/readUnReadMessage';
import { flagUnFlagMessage } from '../../../../modules/buyer/myEfindit/message/flagUnFlagMessage';
import { dateToLocal } from '../../../../modules/date/date';


interface Props {
    nav: string
}

const Messages = ({ nav }: Props) => {
    const dispatch = useDispatch();
    const isFirstRender = useRef(true);
    const [pageCount, setPageCount] = useState(0);
    const [itemCount, setItemCount] = useState(16);
    const [currentPage, setcurrentPage] = useState(0);
    const [selectedId, setSelectedId] = useState<any[]>([]);
    const { search, pathname } = useLocation();
    const navigate = useNavigate();

    function useQuery() {
      return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    let msgType = query.get("type");
    interface paramValues {
        efindit: null | boolean;
        flagged: null | boolean;
        archived: null | boolean;
        read: null | boolean;
    }

    const ref = useRef<any[]>([]);

    let messageResponse = useSelector<RootState, RootState["messages"]>(
        messageResponseSelector
    );

    useEffect(() => {
        messageResponse.allMessages &&
            setPageCount(
                Math.ceil(messageResponse.allMessages?.data?.totalResult / itemCount)
            );
    }, [messageResponse.allMessages]);

    const checkNavAndType = () => {
        let flagged = msgType === 'flagged'  ? true : null;
        let read = msgType === 'unread' ? false : null;
        let archived = msgType === 'archived' ? true : null;
        let efindit = nav === 'efindit' ? true : false;
        let data = {
            flagged: flagged,
            read: read,
            archived: archived,
            efindit: efindit
        }
        return data;
    }

    const callGetMessages = () => {
        const key = checkNavAndType();
        if (nav == 'all') {
            dispatch<any>(getMessages({
                currentPage: currentPage + 1,
                rows: itemCount,
                read: key.read,
                msgType:msgType,
            }));
        } else {
            dispatch<any>(getMessages({
                currentPage: currentPage + 1,
                rows: itemCount,
                efindit: key.efindit,
                flagged: key.flagged,
                archived: key.archived,
                read: key.read,
                msgType:msgType,
            }));
        }
    };

    // useEffect(() => {
    //   if (isFirstRender.current) {
    //     // callGetMessages();
    //   }
    //   isFirstRender.current = false;
    // }, []);

    useEffect(() => {
        callGetMessages();
        setSelectedId([])
        Unchecked();
    }, [currentPage, itemCount, query]);

    const handlePageChange = (selectedObject: any) => {
        setcurrentPage(selectedObject.selected);
    };

    const handleItemsChange = (event: any) => {
        setItemCount(event.target.value);
        setcurrentPage(0);
    };

    const handleCheck = (e: any) => {
        if (e.target.checked) {
            setSelectedId([...selectedId, e.target.value])
        } else {
            setSelectedId((prevState) =>
                prevState.filter((prevItem) => prevItem !== e.target.value)
            );
        }
    }

    const handleDelete = () => {
        selectedId.length!==0 &&   dispatch<any>(deleteMessageByUser({ ids: selectedId }));
    }

    const handleArchieved = () => {
        let archievedData: any = [];
        selectedId.map((id) => {
            archievedData.push({
                id: id,
                IsArchived: true
            })
        })
        archievedData.length != 0 &&  dispatch<any>(archivesMessage({ archiveMessages: archievedData }));
    }

    const handleUnArchive = () => {
        let unarchievedData: any = [];
        selectedId.map((id) => {
            unarchievedData.push({
                id: id,
                IsArchived: false
            })
        })
        unarchievedData.length != 0 &&  dispatch<any>(archivesMessage({ archiveMessages: unarchievedData }));
    }

    const handleRead = () => {
        let readData: any = [];
        selectedId.map((id) => {
            readData.push({
                id: id,
                isRead: true
            })
        })
        readData.length != 0 &&  dispatch<any>(readUnReadMessage({ readUnReadMessages: readData }));
    }

    const handleUnRead = () => {
        let unreadData: any = [];
        selectedId.map((id) => {
            unreadData.push({
                id: id,
                isRead: false
            })
        })
        unreadData.length != 0 &&  dispatch<any>(readUnReadMessage({ readUnReadMessages: unreadData }));
    }

    const handleFlagged = () => {
        let falggedData: any = [];
        selectedId.map((id) => {
            falggedData.push({
                id: id,
                IsFlagged: true
            })
        })
        falggedData.length != 0 &&  dispatch<any>(flagUnFlagMessage({ FlagUnFlagMessages: falggedData }));
    }

    const handleUnFlagged = () => {
        let unflaggedData: any = [];
        selectedId.map((id) => {
            unflaggedData.push({
                id: id,
                IsFlagged: false
            })
        })
        unflaggedData.length != 0 && dispatch<any>(flagUnFlagMessage({ FlagUnFlagMessages: unflaggedData }));
    }

    const Unchecked = () => {
        for (let i = 0; i < ref.current.length; i++) {
            if (ref.current[i] !== null) {
                ref.current[i].checked = false;
            }
        }
    }

    useEffect(() => {
        if (messageResponse.saveData != null) {
            if (messageResponse.saveData.success === "Y") {
                notify(messageResponse.saveData.message, constant.SUCCESS);
                Unchecked();
                callGetMessages();
            } else if (messageResponse.saveData.success === "N") {
                notify(messageResponse.saveData.message, constant.ERROR);
            }
        }

        if (messageResponse.error != null) {
            notify(messageResponse.error, constant.ERROR);
        }
        dispatch<any>(reset());
    }, [messageResponse.saveData, messageResponse.error]);

    const handleMessageClick =(item:any) => {
        let stateData = {
            nav : nav,
            data : item
        }
        let readData: any = [{
            id: item.id,
            isRead: true
        }];    
        dispatch<any>(readUnReadMessage({ readUnReadMessages: readData })).then(()=>dispatch<any>(reset()));
        navigate(
            "/buyer/messages/details",
            {
              state: stateData,
            }
          );
    }

// -------------------------------------------------------------------------------------------

    return (
        <>
            <BuyerHeader />
            <main className="min-section">
                <div className="container">
                    <h1 className="sectionTitle mt-5">My eFindit</h1>
                    <GroupTab currentPage="Messages" />
                    <ToastContainer />
                    <section className="withFilter">
                        <div className="left-filter">
                            <div className="left-lists-block">
                                <div className="wrap">
                                    <div className="block-title">
                                        <strong>Skip navigation</strong>
                                    </div>
                                    <ul className="list-unstyled msglinks">
                                        <li className={nav == 'inbox' ? "active" : ""}><Link to="/buyer/messages/inbox">Inbox</Link></li>
                                        <li className={nav == 'all' ? "active" : ""}><Link to="/buyer/messages/all">All messages</Link></li>
                                        <li className={nav == 'efindit' ? "active" : ""}><Link to="/buyer/messages/efindit">From efindit</Link></li>
                                        {/* <li><a href="">From members</a></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="right-wrapper">
                            <section className="msgInbox pt-0">
                                <div className="msgTitle">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-md-auto">
                                            <h2>{nav} : {msgType === 'all' ? "" : msgType} messages</h2>
                                        </div>
                                        {/* <div className="col-md-auto">
                                            <div className="searchBox">
                                                <input type="text" placeholder="Search your order" />
                                                <button className="searchBtn">Search</button>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                {messageResponse?.messagesCount?.data !== 0 ?
                                    <>            
                                        <div className="inboxMsgList">
                                            <div className="msgNav">
                                                <Link className={msgType == 'all' || msgType == null ? "active" : ""} to={pathname + "?type=all"}>All</Link>
                                                <Link className={msgType == 'unread' ? "active" : ""} to={pathname + "?type=unread"}>Unread</Link>
                                                {nav !== 'all' && <>
                                                    <Link className={msgType == 'flagged' ? "active" : ""} to={pathname + "?type=flagged"}>Flagged</Link>
                                                    <Link className={msgType == 'archived' ? "active" : ""} to={pathname + "?type=archived"}>Archived</Link>
                                                </>
                                                }
                                            </div>
                                            
                                                    <div className="actionBtns">
                                                        <a className="msgActions" onClick={handleDelete}>Delete</a>
                                                    {msgType !== 'archived' && <a className="msgActions" onClick={handleArchieved}>Archive</a> }
                                                        <div className="btn-group">
                                                            <div className="dropdown">
                                                                <a className="btn btn-secondary dropdown-toggle msgActions" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                                                                    Mark As
                                                                </a>
                                                                <div className="dropdown-menu">
                                                                    <a className="dropdown-item seleted" onClick={handleRead}>Read</a>
                                                                    {/* {msgType !== 'all' && msgType !== null && msgType !== 'unread'  && msgType !== 'flagged' && msgType !== 'archived' && */}
                                                                    {/* {msgType !== 'unread'  && */}
                                                                    <a className="dropdown-item" onClick={handleUnRead}>Unread</a> 
                                                                    {/* } */}
                                                                    {/* {msgType !== 'flagged' && msgType !== 'archived' && */}
                                                                    {/* {msgType !== 'flagged' &&  */}
                                                                    <a className="dropdown-item" onClick={handleFlagged}>Flagged</a>
                                                                    {/* } */}
                                                                    {/* { msgType !== null && msgType != 'unread' && msgType !== 'archived' && */}
                                                                    {/* {msgType !== 'archived'  &&   msgType !=="unread"  && */}
                                                                    <a className="dropdown-item" onClick={handleUnFlagged}>Unflagged</a>
                                                                    {/* } */}
                                                                    {/* } */}
                                                                    {/* {msgType !== 'all' && msgType !== null && msgType != 'unread' && msgType !== 'flagged' && */}
                                                                    {/* {msgType !== 'unread'   && msgType !== 'flagged'   && */}
                                                                    <a className="dropdown-item" onClick={handleUnArchive}>Unarchived</a>
                                                                    {/* } */}
                                                                    {/* } */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="msgBody">
                                                        <div className="table-responsive">
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ width: "5%" }}>
                                                                        </th>
                                                                        <th style={{ width: "5%" }}>
                                                                            <img src="images/attachment-icon.png" alt="" />
                                                                        </th>
                                                                        <th>
                                                                            From
                                                                        </th>
                                                                        <th style={{ width: "40%" }}>Subject</th>
                                                                        {/* <th style={{ width: "20%" }}>Item ends</th> */}
                                                                        <th style={{ width: "15%" }}>Received
                                                                            {/* 
                                                                                <div className="dropdown">
                                                                                    <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">Received</button>
                                                                                    <div className="dropdown-menu">
                                                                                        <a className="dropdown-item" href="#">Action</a>
                                                                                        <a className="dropdown-item" href="#">Another action</a>
                                                                                        <a className="dropdown-item" href="#">Something else here</a>
                                                                                    </div>
                                                                                </div> 
                                                                            */}
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {messageResponse?.allMessages &&
                                                                        messageResponse?.allMessages?.data?.messages?.map(
                                                                            (item, Index) => (
                                                                                <tr key={Index} className={(item.isRead == false || item.isRead == null) ? "unread" : ""}  >
                                                                                    <td>
                                                                                        <div className="custom-check">
                                                                                            <div className="wrap">
                                                                                                <input type="checkbox" value={item.id} ref={(element: any) => { ref.current[Index] = element }} id={"msg-" + item.id} onClick={handleCheck} />
                                                                                                <label htmlFor={"msg-" + item.id}></label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                
                                                                                    <td></td>
                                                                                    <td className="messageLink"  onClick={() => handleMessageClick(item)}>
                                                                                    {item.from}
                                                                                    </td>
                                                                                    <td className="messageLink"  onClick={() => handleMessageClick(item)}>
                                                                                        {item.subject}
                                                                                    </td>
                                                                                    {/* <td> -- </td> */}
                                                                                    <td>
                                                                                        {moment(dateToLocal( new Date(item.createdDate))).format("MMM Do YY")}
                                                                                    </td>
                                                                                
                                                                                </tr>
                                                                            )
                                                                        )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <nav className="pagination-block">
                                                            {/* <p className="total">
                                                                {messageResponse?.allMessages?.data?.totalResult} {" "}
                                                                items total
                                                            </p> */}
                                                            <ReactPaginate
                                                                previousLabel={"previous"}
                                                                nextLabel={"next"}
                                                                breakLabel={"..."}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={2}
                                                                pageRangeDisplayed={3}
                                                                forcePage={currentPage}
                                                                onPageChange={handlePageChange}
                                                                containerClassName={"pagination justify-content-center mt-4"}
                                                                pageClassName={"page-item"}
                                                                pageLinkClassName={"page-link"}
                                                                previousClassName={"page-item"}
                                                                previousLinkClassName={"page-link"}
                                                                nextClassName={"page-item"}
                                                                nextLinkClassName={"page-link"}
                                                                breakClassName={"page-item"}
                                                                breakLinkClassName={"page-link"}
                                                                activeClassName={"active"}
                                                            />
                                                        
                                                            <div className="customSelect">
                                                                <label htmlFor="">Items per page :</label>
                                                                <div className="selectBox">
                                                                    <select name="" id="" onChange={handleItemsChange}>
                                                                        <option value="16">16</option>
                                                                        <option value="24">24</option>
                                                                        <option value="32">32</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </nav>
                                                    </div>
                                                
                                        </div>
                                    </>
                                :
                                    <div className="no-data-css">
                                        <h3>No records found</h3>
                                    </div>
                                }
                            </section>
                        </div>
                    </section>
                </div>
            </main>
        </>
    )
}

export default Messages
