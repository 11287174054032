import { AppThunk } from "../../store"
import {
    progressSuccess,

} from "./slice"

export const EndProgress = (): AppThunk => async (dispatch) => {
    try {

        dispatch(progressSuccess());

    } catch (err) {
        
    }
}
