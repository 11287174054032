import { AppThunk } from "../../store";
import { Buyers } from "../../utils/types";
import { Reset } from "./slice";

export const resetLogout =
  (data = ""): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(Reset());
    } catch (err) {
      
    }
  };
