import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MobileOtp } from "../../utils/types";

type initialDataState = {
  data: MobileOtp | null;
  error: any | null;
};

const initialState: initialDataState = {
  data: null,
  error: null,
};
const slice = createSlice({
  name: "mobileotp",
  initialState,
  reducers: {
    resetMobileOtp: (state) => {
      state.data = null;
      state.error = null;
    },
    mobileOtpSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = null;
      
    },
    mobileOtpFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.data = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      
      state.error = errorMessage;
    },
  },
});

export const mobileotp = slice.reducer;

export const { mobileOtpFailed, mobileOtpSuccess, resetMobileOtp } =
  slice.actions;
