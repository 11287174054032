import React from "react";
import { useDispatch } from "react-redux";
import { deleteOrderNote } from "../../../modules/orders/deleteOrderNote";
import { setOrderNote } from "../../../modules/orders/setOrderNote";

const NoteModel = ({ note, setNote }: any) => {
  const dispatch = useDispatch();

  return (
    <div
      className="modal modal-order-popup"
      id="myModal"
      tabIndex={-1}
      role="dialog"
      style={{ display: note.visible }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="order-modal">
            <div className="order-modal-head border-none">Note</div>

            <div className="form-group spacer-feedback-label">
              <label className="label-modal">Write something</label>
              <textarea
                className="form-control buyer-textarea input-modal"
                rows={3}
                maxLength={500}
                placeholder="write some thing"
                value={note.Note}
                onChange={(e) =>
                  setNote({
                    ...note,
                    Note: e.target.value,
                  })
                }
              ></textarea>
              <p>{note.Note.length}/500</p>
            </div>

            <div className="modal-btn-group spacer-feedback-btn">
              <button
                className="modal-add-btn"
                onClick={() => {
                  dispatch<any>(setOrderNote(note));
                  setNote({ ...note, visible: "none" });
                }}
                disabled={note.Note === "" ? true : false}
              >
                Submit
              </button>
              <button
                className="modal-add-btn modal-cancel-order-btn"
                onClick={() => {
                  dispatch<any>(deleteOrderNote(note));
                  setNote({ ...note, visible: "none" });
                }}
                disabled={note.Note === "" ? true : false}
              >
                Delete
              </button>
              <button
                className="modal-cancel-btn"
                onClick={() => setNote({ ...note, visible: "none" })}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteModel;
