import Api from "../../../../utils/api";
import { API_URLS } from "../../../../utils/api.urls";

export const fetchMessages = async (params: any): Promise<any> => {

  const fromEfindIt = params?.efindit == null  ? "" : "&FromEfindIt=" + params.efindit;
  const read = params?.read == null || params.msgType==null || params.msgType=="all" ? "" : "&Read=" + params.read;
  const flagged = params?.flagged == null || params.msgType==null || params.msgType=="all" ? "" : "&Flagged=" + params.flagged;
  const archived = params?.archived == null || params.msgType==null || params.msgType=="all" ? "" : "&Archived=" + params.archived;

  const data = await Api.get(
    API_URLS.ALL_MESSAGES +
    "?PageNumber=" +
    params.currentPage +
    "&TotalRows=" +
    params.rows + fromEfindIt + read + flagged + archived,
    []
  );
  return data;
};

export const readUnreadMessageApi = async (params: any | null): Promise<any> => {
  const data = await Api.post(API_URLS.READ_UNREAD_MESSAGE, params);
  return data;
};

export const fetchMessagesCount = async (): Promise<any> => {
  const data = await Api.get(API_URLS.MESSAGE_COUNT, []);
  return data;
};

export const deleteMessageByUserApi = async (params: any | null): Promise<any> => {
  const data = await Api.delete(API_URLS.DELETE_MESSAGE_BY_USER, params);
  return data;
};

export const flagUnFlagMessageApi = async (params: any | null): Promise<any> => {
  const data = await Api.post(API_URLS.FLAG_UNFLAG_MESSAGE, params);
  return data;
};

export const archivesMessageApi = async (params: any | null): Promise<any> => {
  const data = await Api.post(API_URLS.ARCHIVES_MESSAGE, params);
  return data;
};
