import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Login } from "../../utils/types";

type LoginState = {
  login: Login | null;
  error: any | null;
  deleteTokenError: any | null;

  autoLogout: boolean;
  deleteToken: boolean;
  provider: string;
  profile: any | null;
  resendVerification : Login["resendVerification"] | null;
  resendVerificationError:  string| null;
};

const initialState: LoginState = {
  login: null,
  error: null,
  deleteTokenError: null,

  autoLogout: false,
  deleteToken: false,
  provider: "",
  profile: null,
  resendVerification:null,
  resendVerificationError:null,
};

const slice = createSlice({
  name: "login",
  initialState,
  reducers: {
    UserLoginSuccess: (state, action: PayloadAction<any>) => {
      state.login = action.payload;
      state.error = null;
      localStorage.setItem("authorization", action.payload.jwtToken);
      localStorage.setItem("refresh", action.payload.refreshToken);
      localStorage.setItem("role", action.payload.data.roleCode);
      localStorage.setItem("userId", action.payload.data.userId);
      localStorage.setItem(
        "UserName",
        action.payload.data.firstName.charAt(0).toUpperCase() +
          action.payload.data.firstName.slice(1) +
          " " +
          action.payload.data.lastName
      );


      if (action.payload.data.storeAddressLinked) {
        localStorage.setItem(
          "storeAddressLinked",
          action.payload.data.storeAddressLinked
        );
      }

      if (action.payload.data.storeNameLinked) {
        localStorage.setItem(
          "storeNameLinked",
          action.payload.data.storeNameLinked
        );
      }

      if (action.payload.data.payPalAccountLinked) {
        localStorage.setItem(
          "payPalAccountLinked",
          action.payload.data.payPalAccountLinked
        );
      }

      localStorage.removeItem("autoLogout");
    },
    UserLoginFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.login = action.payload;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
      if(action?.payload?.accountActivationToken)
      {
        localStorage.setItem("activationToken",action?.payload?.accountActivationToken );

      }
      else
     { localStorage.removeItem("activationToken" );
     }
    },

    RefreshTokenSuccess: (state, action: PayloadAction<any>) => {
      state.login = action.payload;
      state.error = null;
      localStorage.setItem("authorization", action.payload.jwtToken);
    },
    RefreshTokenFailed: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.login = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
      localStorage.removeItem("authorization");
      localStorage.removeItem("refresh");
      localStorage.removeItem("error");
      localStorage.removeItem("userId");
      localStorage.removeItem("UserName");
      localStorage.removeItem("productId");
      localStorage.removeItem("role");
      localStorage.setItem("autoLogout", "true");
      // window.location.reload();
    },
    VerificationEmailSuccess: (state, action: PayloadAction<any>) => {
      state.resendVerification = action.payload;
      state.resendVerificationError = null;
    },
    VerificationEmailFailed: (state, action: PayloadAction<any>) => {
      state.resendVerification = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.resendVerificationError = errorMessage;
      
      // window.location.reload();
    },
    DeleteRefreshTokenSuccess: (state, action: PayloadAction<any>) => {
      state.login = action.payload;
      state.deleteTokenError = null;
      state.deleteToken = true;
    },
    DeleteRefreshTokenFailed: (state, action: PayloadAction<any>) => {
      state.deleteTokenError = action.payload;
      state.login = null;
      state.deleteToken = false;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
      localStorage.removeItem("authorization");
      localStorage.removeItem("refresh");
      localStorage.removeItem("error");
      localStorage.removeItem("userId");
    },
    ResetUserLogin: (state) => {
      state.login = null;
      state.error = null;
      state.autoLogout = false;
      state.deleteToken = false;
    },
    ResetVerification: (state) => {
      state.resendVerification = null;
      state.resendVerificationError = null;
  
    },
    ResetSocial: (state) => {
      state.profile = null;
      state.provider = "";
    },
    SetSocial: (state, action: PayloadAction<any>) => {
      state.profile = action.payload.profile;
      state.provider = action.payload.provider;
    },
    Reset: (state) => {
    },
  },
});

export const login = slice.reducer;

export const {
  UserLoginFailed,
  UserLoginSuccess,
  RefreshTokenSuccess,
  RefreshTokenFailed,
  DeleteRefreshTokenSuccess,
  DeleteRefreshTokenFailed,
  ResetUserLogin,
  SetSocial,
  ResetSocial,
  Reset,
  VerificationEmailFailed,
  VerificationEmailSuccess,
  ResetVerification
} = slice.actions;
