import React, { useEffect, useState } from "react";
import Header from "../../../components/header/Header";
import { DefaultLayout } from "../../../layout/DefaultLayout";
import { ToastContainer } from "react-toastify";
import {
  ProfileContainer,
  ProfileSubContainer,
  ProfilePhotoContainer,
  ProfilePhotoImage,
  ProfileButton,
  ProfileFormContainer,
  SaveButton,
  CancelButton,
  FormGroup,
  InputGroup,
  Label,
  Input,
  TextArea,
} from "./editProfile.style";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSocialMedia } from "../../../modules/socialMedia/getSocialMedia";
import { RootState } from "../../../utils/types";
import { SocialMediaResponseSelector } from "../../../modules/socialMedia/selector";
import { BASE_URLS } from "../../../utils/api.urls";
import { sellerStoreResponseSelector } from "../../../modules/sellerStore/selectors";
import { getStore } from "../../../modules/sellerStore/getStore";
import { saveStore } from "../../../modules/sellerStore/saveStore";
import { reset } from "../../../modules/sellerStore/reset";
import { notify } from "../../../modules/notification/notify";
import "react-toastify/dist/ReactToastify.css";
import { saveProfilePic } from "../../../modules/sellerStore/saveProfilePic";
import { getImageDimension, resizeImage } from "../../../modules/image/image";
import { errorMessage } from "../../../modules/notification/error";
import Footer from "../../../components/footer/Footer";
import { Link } from "react-router-dom";

export const EditProfile = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isFirstRender = React.useRef(true);

  const socialMediaResponse = useSelector<RootState, RootState["socialMedias"]>(
    SocialMediaResponseSelector
  );
  const storeResponse = useSelector<RootState, RootState["sellerStore"]>(
    sellerStoreResponseSelector
  );
  const [profilePic, setProfilePic]: any = useState([]);
  const [profile, setProfile] = useState({
    storeName: "",
    description: "",
    TinNo: "",
    OfficialId: "",
    TaxPayerId: "",
  });
  const [links, setLinks] = useState<any>([{ socialMediaId: "", Link: "" }]);
  const handleformChange = (e: any) => {
    // let newFormValues = profile;
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };
  // const handleChangeImage = (e: any) => {
  //   if (e.target.files) {
  //     if (e.target.files[0] != undefined) {
  //       if (e.target.files[0].name.match(/.(jpg|jpeg|png)$/i)) {
  //         const prevObj = {
  //           preview: URL.createObjectURL(e.target.files[0]),
  //           raw: e.target.files[0],
  //         };
  //         setProfilePic(() => [prevObj]);
  //       } else {
  //         // errorMessage(constant.IMAGE_FORMAT_ERROR);
  //         e.target.value = "";
  //       }
  //     }
  //   }
  // };
  const handleChangeImage = async (e: any) => {
    if (e.target.files) {
      
      if (e.target.files[0] != undefined) {
        if (e.target.files[0].name.match(/.(jpg|jpeg|png)$/i)) {
          const params: any = await getImageDimension(e.target.files[0]);
          if (
            e.target.files[0].size <= 10000000
            // params.width >= constant.CATEGORY_WIDTH &&
            // params.height >= constant.CATEGORY_HEIGHT
          ) {
            const resizedImage: any = await resizeImage(
              e.target.files[0],
              162,
              188
            );

            const prevObj = {
              preview: URL.createObjectURL(resizedImage),
              raw: resizedImage,
            };
            setProfilePic(() => [prevObj]);
          } else {
            errorMessage("Image size should be less than 10 MB");
            e.target.value = "";
          }
        } else {
          errorMessage("Image size should be less than 10 MB");
          e.target.value = "";
        }
      }
    }
  };
  const handleSubmit = () => {
    // if (content.categoryName === "" || image.length <= 0)
    //   return errorMessage(constant.NAME_AND_IMAGE_ERROR);
    // if (!checkProperties(formValues))
    //   return errorMessage(constant.VARIANT_NAME_ERROR);
    const formData = new FormData();
    const formData2 = new FormData();

    const data = {
      storeName: profile.storeName,
      description: profile.description,
      TinNo: profile.TinNo,
      OfficialId: profile.OfficialId,
      TaxPayerId: profile.TaxPayerId,
      links: links,
    };

    formData.append("store", JSON.stringify(data));
    if (profilePic.length > 0) {
      formData2.append("profilePic", profilePic[0].raw);
      dispatch<any>(saveProfilePic(formData2));
    }
    dispatch<any>(saveStore(formData));
  };
  const handleLinkChange = (e: any, i: any) => {
    // const index = links.findIndex((e: any) => e.socialMediaId == i);
    let newFormValues: any = [...links];
    newFormValues[i]["Link"] = e.target.value;
    setLinks(newFormValues);
  };
  const callGetSocialMedia = () => {
    dispatch<any>(getSocialMedia({}));
  };
  //   useEffect(() => {
  //     if (isFirstRender.current) {
  //       dispatch<any>(resetSocialMedia());
  //     }

  //     isFirstRender.current = false;
  //   }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      dispatch<any>(getSocialMedia({}));
      dispatch<any>(getStore());
    }
    isFirstRender.current = false;
  }, [storeResponse.sellerStore]);
  useEffect(() => {
    if (
      socialMediaResponse.socialMedias !== null &&
      storeResponse.sellerStore !== null &&
      storeResponse.sellerStore.value.length > 0
    ) {
      let profileData = {
        storeName: storeResponse.sellerStore.value[0].StoreName,
        description: storeResponse.sellerStore.value[0].Description,
        TinNo: storeResponse.sellerStore.value[0].TinNo,
        OfficialId: storeResponse.sellerStore.value[0].OfficialId,
        TaxPayerId: storeResponse.sellerStore.value[0].TaxPayerId,
      };

      let data = [
        ...JSON.parse(
          JSON.stringify(storeResponse.sellerStore.value[0].SocialMedia)
        ),
      ];
      socialMediaResponse.socialMedias.value.forEach((element) => {
        if (
          storeResponse.sellerStore.value[0].SocialMedia.findIndex(
            (e) => e.SocialMediaId == element.Id
          ) === -1
        ) {
          data.push({
            SocialMediaId: element.Id,
            Link: "",
          });
        }
      });

      setLinks(data);
      setProfile(profileData);
    }
  }, [socialMediaResponse.socialMedias, storeResponse.sellerStore]);

  useEffect(() => {
    if (!isFirstRender.current) {
      if (storeResponse.saveData != null) {
        if (storeResponse.saveData.success === "Y") {
          notify("Profile updated", "success");
          navigate("/seller/storeFeedback");
          dispatch<any>(getStore());
        } else if (storeResponse.saveData.success === "N") {
          notify(storeResponse.saveData.message, "error");
        }
        dispatch<any>(reset());
      }
    }
  }, [storeResponse.saveData]);
  return (
    <DefaultLayout>
      <ToastContainer />
      <Header />
      <section className="seller-breadcrumb-links">
        <div className="container">
          <ul>
            <li>
              <Link to={"/seller/overview"}>eFindit</Link>
            </li>
            <li className="spacer">{">"}</li>
            <li>
              <Link to={"/seller/storeFeedback"}>eFindit stores</Link>
            </li>
            <li className="spacer">{">"}</li>
            <li className="active">edit profile</li>
          </ul>
        </div>
      </section>
      <ProfileContainer>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ProfileSubContainer>
                <ProfilePhotoContainer>
                  <ProfilePhotoImage>
                    {profilePic.length !== 0 ? (
                      profilePic.map((element: any, index: any) => {
                        return (
                          <div key={index}>
                            <img
                              src={element.preview}
                              alt="pImg"
                              className="img-thumbnail"
                            />
                          </div>
                        );
                      })
                    ) : (
                      <img
                        src={
                          BASE_URLS.AWS_URL +
                          storeResponse.sellerStore?.value[0].ProfilePic
                        }
                        alt="profile pic"
                        className="img-thumbnail"
                      />
                    )}
                  </ProfilePhotoImage>
                  <ProfileButton>
                    Change Profile Pic
                    <input
                      type="file"
                      placeholder="Change Profile Pic"
                      className="custom-file-input profile-file-input"
                      name="mobile"
                      id="customFileMobile"
                      onChange={handleChangeImage}
                      accept="image/png,image/jpeg,image/jpg"
                    />
                  </ProfileButton>
                  <p style={{ color: "white" }}> max size: 10MB</p>
                </ProfilePhotoContainer>

                <ProfileFormContainer>
                  <FormGroup>
                    <Label>
                      <span className="red-star">*</span> Store Name (You can
                      not change more than once in a month)
                    </Label>

                    <Input
                      value={profile.storeName}
                      name="storeName"
                      onChange={(e) => handleformChange(e)}
                      required
                      placeholder="Your Best Store"
                    ></Input>
                    {profile.storeName === "" && (
                      <p style={{ color: "white" }}>*Required!</p>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <Label>Social Media Link</Label>
                    {socialMediaResponse.socialMedias &&
                      storeResponse.sellerStore !== null &&
                      storeResponse.sellerStore.value.length > 0 &&
                      socialMediaResponse.socialMedias.value.map(
                        (social: any, Index: any) => {
                          let i = links.findIndex(
                            (e: any) => e.SocialMediaId == social.Id
                          );
                          return (
                            <>
                              {social.Active === "Y" && (
                                <InputGroup key={social.Id} className="d-flex">
                                  {social.Logo && (
                                    <img
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                        position: "absolute",
                                      }}
                                      src={BASE_URLS.AWS_URL + social.Logo}
                                      alt=""
                                    />
                                  )}
                                  {i !== -1 && (
                                    <Input
                                      style={{
                                        paddingLeft: "40px",
                                      }}
                                      value={links[i]["Link"]}
                                      onChange={(e) => handleLinkChange(e, i)}
                                      placeholder={social.Name + " Link"}
                                    ></Input>
                                  )}
                                </InputGroup>
                              )}
                            </>
                          );
                        }
                      )}
                  </FormGroup>

                  <FormGroup>
                    <Label>Short Description</Label>
                    <TextArea
                      value={profile.description}
                      name="description"
                      maxLength={500}
                      onChange={(e) => handleformChange(e)}
                    ></TextArea>
                    <p style={{ color: "white" }}>
                      {profile?.description?.length + "/500"}
                    </p>
                  </FormGroup>

                  <SaveButton
                    onClick={handleSubmit}
                    disabled={profile.storeName === "" ? true : false}
                  >
                    Save
                  </SaveButton>
                  <CancelButton onClick={()=>navigate("/seller/storeFeedback")}>Cancel</CancelButton>
                </ProfileFormContainer>
              </ProfileSubContainer>
            </div>
          </div>
        </div>
      </ProfileContainer>

      <Footer />
    </DefaultLayout>
  );
};
