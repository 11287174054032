import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../../../components/header/Header";
import { DefaultLayout } from "../../../layout/DefaultLayout";
import { getCommonData } from "../../../modules/common/getCommonData";
import { CommonResponseSelector } from "../../../modules/common/selectors";
import { BASE_URLS } from "../../../utils/api.urls";
import { RootState } from "../../../utils/types";
import * as Yup from "yup";
import { string, object, array } from "yup";
import { postReportBuyer } from "../../../modules/orders/postReportBuyer";
import { SellerOrdersResponseSelector } from "../../../modules/orders/selectors";
import { notify } from "../../../modules/notification/notify";
import { constant } from "../../../utils/constant";
import { resetOrder } from "../../../modules/orders/resetOrder";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../../components/footer/Footer";
import BuyerHeader from "../BuyerHeader";
import DefaultImage from "../../../assets/images/defaultImage.png"
import { resetDetail } from "../../../modules/buyer/product/resetDetail";
import { postReportItem } from "../../../modules/orders/postReportItem";

const ReportItem = () => {
  const navigate = useNavigate();

  const { state }: any = useLocation();
  const commonResponse = useSelector<RootState, RootState["common"]>(
    CommonResponseSelector
  );

  let order = useSelector<RootState, RootState["sellerOrders"]>(
    SellerOrdersResponseSelector
  );

  const isFirstRender = useRef(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isFirstRender.current) {
      if (commonResponse?.common === null) dispatch<any>(getCommonData());
    }
    isFirstRender.current = false;
  }, []);
  const reDirect=()=>{
    navigate( state?.route) ;
  }
  useEffect(() => {
    if (order.saveData != null) {
      if (order.saveData.success == "Y") {
        notify(order.saveData.message, constant.SUCCESS);
        reset();
        setTimeout(  reDirect  , 3000);
      } else if (order.saveData.success == "N") {
        notify(order.saveData.message, constant.ERROR);
      }
    }
    if (order.saveDataError !== null) {
      notify(order.saveDataError, constant.ERROR);
    }
    dispatch<any>(resetOrder());
  }, [order.saveData, order.saveDataError]);

  interface reportValues {
    reportCategory: string | number;
    reasonForReport: string | number;
    detailReason: string;
  }
  const [report, setReport] = useState<reportValues>({
    reportCategory: "",
    reasonForReport: "",
    detailReason: "",
  });

  const reportSchema = Yup.object().shape({
    reportCategory: Yup.string().required("*Required!"),
    reasonForReport: Yup.string().required("*Required!"),
  });

  const submitData = (data: any) => {
    dispatch<any>(
      postReportItem({
        orderId: state.product?.orderId ? state.product?.orderId  :0,
        ProductId:state.product?.orderId ? null : state.product?.productId ,  
        reportCategory: report?.reportCategory,
        reasonForReport: report?.reasonForReport,
        detailReason: report?.detailReason,
      })
    );
  };
  const handleChange = (e: any) => {
    setReport({ ...report, [e.target.name]: e.target.value });
  };

  const reset=()=>{
    setReport({...report, reportCategory: "",
    reasonForReport: "",
    detailReason: "",})
  }
  return (
    <DefaultLayout>
      <BuyerHeader />
      <ToastContainer />
      {state?.route==="/buyer/activity/purchaseHistory" && <section className="seller-breadcrumb-links">
        <div className="container">
          <ul>
            <li>
              <Link to={"/home"}>eFindit</Link>
            </li>
            <li className="spacer">{">"}</li>
            <li>
              <Link to={"/buyer/activity/purchaseHistory"}>purchaseHistory</Link>
            </li>
            <li className="spacer">{">"}</li>
            <li className="active">Report item</li>
          </ul>
        </div>
      </section>}
      <main className="min-section">
    <div className="container">
      <section>
        <div className="row">
          <div className="col-xl-9">
            <div className="report mt-5">
              <h1>Report item</h1>
             
             {state?.product?.productName && <div className="prodInfo">
                <div className="title">
                 
               {state?.product?.productName}</div>
                <div className="info">
                  <div className="row">
                    <div className="col-auto">
                      <div className="p-img">
                        <img 
                         src={BASE_URLS.AWS_URL + "/Thumb" + state?.product?.productImage}
                         onError={({ currentTarget }) => {
                           currentTarget.onerror = null; // prevents looping
                           currentTarget.src = DefaultImage;
                         }}
                         alt=""
                        />
                      </div>
                    </div>
                 {state?.product?.orderId &&   <div className="col-auto">
                      <label htmlFor="">Order Id:</label>
                      <p>{state?.product?.orderId}</p>
                    </div>}
                   {state?.product?.totalPrice && <div className="col-auto">
                      <label htmlFor="">Price</label>
                      <p>${state?.product?.totalPrice}</p>
                    </div>}
                    <div className="col-auto">
                      <label htmlFor="">Seller name</label>
                      <p>{state?.product?.storeName}</p>
                    </div>
                    {/* <div className="col-auto">
                      <label htmlFor="">Time Left</label>
                      <p>-</p>
                    </div> */}
                  </div>
                </div>
              </div>}

            <div className="reportForm">

            <Formik
                      initialValues={report}
                      enableReinitialize={true}
                      validationSchema={reportSchema}
                      onSubmit={(values) => {
                        submitData(values);
                      }}
                    >
                      {({ handleSubmit, errors, touched }) => (
                        <Form>
                          <div className="buyer-contact-form">
                            
                            <div className="buyer-contact-form-col report-buyer-padd">
                              <div className="form-group">
                                <label className="label-buyer">
                                  Report category
                                </label>
                                <span className="red-star">*</span>
                                <select
                                  className="form-control input-buyer"
                                  name="reportCategory"
                                  value={report.reportCategory}
                                  style={{color:"black"}}

                                  onChange={(e) => handleChange(e)}
                                >
                                  <option value="">Select</option>

                                  {commonResponse?.common?.reasonForReportCategoryItem?.map( 
                                    (item: any, Index: number) => (
                                      <option key={item.key} value={item.value}>
                                        {item.label}
                                      </option>
                                    )
                                  )}
                                </select>
                                {errors.reportCategory &&
                                touched.reportCategory ? (
                                  <div
                                    className="required"
                                    style={{ color: "red" }}
                                  >
                                    {errors.reportCategory}
                                  </div>
                                ) : null}
                              </div>
                              <div className="spacer-report"></div>
                              <div className="form-group">
                                <label className="label-buyer">
                                  Reason for report
                                </label>
                                <span className="red-star">*</span>
                                <select
                                  className="form-control input-buyer"
                                  name="reasonForReport"
                                  value={report.reasonForReport}
                                  style={{color:"black"}}
                                  onChange={(e) => handleChange(e)}
                                >
                                  <option value="">Select</option>
                                  {commonResponse?.common?.reasonForReportItem?.map(
                                    (item: any, Index: number) => (
                                      <option key={item.key}  value={item.value}>
                                        {item.label}
                                      </option>
                                    )
                                  )}
                                </select>
                                {errors.reasonForReport &&
                                touched.reasonForReport ? (
                                  <div
                                    className="required"
                                    style={{ color: "red" }}
                                  >
                                    {errors.reasonForReport}
                                  </div>
                                ) : null}
                              </div>
                              <div className="spacer-report"></div>
                              <div className="form-group">
                                <label className="label-buyer">
                                  Detailed reason
                                </label>
                                <div className="form-group spacer-feedback-label">
                                  <textarea
                                    className="form-control buyer-textarea input-modal"
                                    rows={3}
                                    maxLength={500}
                                  style={{color:"black"}}

                                    placeholder="write some thing"
                                    value={report.detailReason}
                                    onChange={(e) =>
                                      setReport({
                                        ...report,
                                        detailReason: e.target.value,
                                      })
                                    }
                                  ></textarea>
                                  <p>
                                    {report.detailReason.length}
                                    /500
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="seller-button contact-buyer-btn">
                            <button type="submit" className="list-button">Continue</button>
                            <button className="list-button bg-none"  onClick={() => {
                  dispatch<any>(
                    resetDetail()).then(()=>navigate( state?.route))     
                                    
                                    }}>
                              Cancel
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
            </div>

          
            

         
            </div>
          </div>

    
        </div>
      </section>

    </div>
  </main>

      <Footer />
    </DefaultLayout>
  );
};

export default ReportItem;
