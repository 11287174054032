import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { number } from "yup";
import { DefaultLayout } from "../../../layout/DefaultLayout";
import { getCart } from "../../../modules/buyer/product/getCart";
import { buyerProductSelector } from "../../../modules/buyer/product/selectors";
import { RootState } from "../../../utils/types";
import BuyerFooter from "../buyerFooter";
import BuyerHeader from "../BuyerHeader";
import DefaultImage from "../../../assets/images/defaultImage.png";
import { BASE_URLS } from "../../../utils/api.urls";
import { addToCart } from "../../../modules/buyer/product/addToCart";
import { deleteFromCart } from "../../../modules/buyer/product/deleteFromCart";
import { Link, useNavigate } from "react-router-dom";
import { resetSave } from "../../../modules/buyer/product/resetSave";
import { saveForLaterCart } from "../../../modules/buyer/product/saveForLaterCart";
import { constant } from "../../../utils/constant";
import { notify } from "../../../modules/notification/notify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getSaveForLaterCart } from "../../../modules/buyer/product/getSaveForLaterCart";
import { resetDetail } from "../../../modules/buyer/product/resetDetail";
import { setCartItems } from "../../../modules/cartItems/setCartItems";
import { getCartItems } from "../../../modules/cartItems/getCartItems";

const MyCart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let productResponse = useSelector<RootState, RootState["buyerProduct"]>(
    buyerProductSelector
  );


  const isFirstRender = useRef(true);
  // const [quantityDropdown, setQuantityDropdown] = useState<number[]>([]);
  const [itemTotal, setItemTotal] = useState(0);
  const [isItemOutOfStock, setIsItemOutOfStock] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState("");
  const [localCart, setLocalCart] = useState<any>([]);
  // const [cartId, setCartId] = useState<any>([]);

  useEffect(() => {
    if (isFirstRender.current) {
      let cart=JSON.parse(localStorage.getItem("cart") || "[]");
      if(cart.length>0 && localStorage.getItem("authorization") !== null &&
      localStorage.getItem("role") === "Customer") 
      {
        let newCart:any=[];
        cart.forEach((element:any) => {
          newCart.push({  productId: element.productId,
            quantity: element.quantity,
            VariantId:element.variantId,})
        });
       dispatch<any>( addToCart({
        isCart: false,
        
        Cart:newCart})).then(()=>localStorage.setItem("cart", JSON.stringify([]))).then(()=>dispatch<any>(getCart())).then(()=>dispatch<any>(resetSave()))
      dispatch<any>(getSaveForLaterCart());
       
      }

      else
      dispatch<any>(getCart());
      dispatch<any>(getSaveForLaterCart());
    }

    if (
      localStorage.getItem("authorization") == null &&
      localStorage.getItem("role") !== "Customer"
    ) {
      setIsLoggedIn("false");
      let data=JSON.parse(localStorage.getItem("cart") || "[]");
      setLocalCart(JSON.parse(localStorage.getItem("cart") || "[]"));
    }
    isFirstRender.current = false;
  }, []);

  useEffect(() => {
    if (productResponse?.cart) {
      let outOfStock=false;
      let total = 0;
      productResponse?.cart?.data?.forEach((item) => {
        total =
          total +
          (item.buyNowPrice
            ? item.buyNowPrice * item.quantity
            : item.actualPrice * item.quantity);

            if(item?.maxQuantity==0)
            outOfStock=true;
          
      });
      setItemTotal(total);
      setIsItemOutOfStock(outOfStock);
    }
  }, [productResponse?.cart]);

  useEffect(() => {
    if (isLoggedIn === "false") {
      let total = 0;
      localCart.forEach((item: any) => {
        total =
          total +
          (item.buyNowPrice
            ? item.buyNowPrice * item.quantity
            : item.actualPrice * item.quantity);
      });
      setItemTotal(total);
    }
  }, [localCart]);
  const handleQuantityChange = (quantity: number, productId: string , VariantId:number) => {
    if (isLoggedIn === "") {
      dispatch<any>(
        addToCart({
        isCart: true,
          
          Cart:[{
          productId: productId,
          quantity: quantity,
          VariantId:VariantId
        }]})
      ).then(() => dispatch<any>(getCart())).then(()=>dispatch<any>(resetSave()));
    } else {
      const copyCartObj = JSON.parse(localStorage.getItem("cart") || "[]");
      let objIndex = copyCartObj.findIndex(
        (obj: any) =>( obj.productId === productId && (obj.variantId===null || obj.variantId === VariantId  ))
      );
      copyCartObj[objIndex].quantity = quantity;

      setLocalCart(copyCartObj);
      localStorage.setItem("cart", JSON.stringify(copyCartObj));
    }
  };

  const handleDelete = (id: number) => {
    dispatch<any>(
      deleteFromCart({
        id: id,
      })
    ).then(() => dispatch<any>(getCart())).then(()=>dispatch<any>(resetSave()));
  };
  const handleSaveForLater = (id: number) => {
    dispatch<any>(
      saveForLaterCart({
        id: id,
      })
    )
  };
  const handleLocalDelete = (id: number) => {
    let data = [...localCart];
    let Index = data.findIndex((obj: any) => obj.productId === id);
    data.splice(Index, 1);
    setLocalCart(data);
    localStorage.setItem("cart", JSON.stringify(data));

    let cartData = JSON.parse(localStorage.getItem("cart") || "[]");
    dispatch<any>(setCartItems(cartData.length));

  };

  const quantityDropdown = (num: number) => {
    let rows = [],
      i = 0;

    while (++i <= num && i <= 10) rows.push(i);

    return (
      <>
        {rows.map(function (i) {
          return (
            <option key={i} value={i}>
              {i}
            </option>
          );
        })}
      </>
    );
  };

  useEffect(() => {
    if (productResponse?.saveData != null) {
      if (productResponse?.saveData.success == "Y") {
        notify(productResponse?.saveData.message, constant.SUCCESS);
        dispatch<any>(getCart());
        dispatch<any>(getSaveForLaterCart());
        dispatch<any>(getCartItems());
      } else if (productResponse?.saveData.success == "N") {
        notify(productResponse?.saveData.message, constant.ERROR);
      }
    }
    if (productResponse?.saveDataError !== null) {
      notify(productResponse?.saveDataError, constant.ERROR);
    }
    dispatch<any>(resetSave());
  }, [productResponse?.saveData, productResponse?.saveDataError]);


  const calculateItems=()=>{
    let total=0;
    if(isLoggedIn !== "false")
   
   { productResponse?.cart?.data?.forEach((item)=>{
total=total+ item.quantity;
    })
    
  }
  if(isLoggedIn==="false")
  {
    localCart.map((item:any)=>{
      total=total+ item.quantity;
    })
  }
  
    return total
  }

  const calculateItemsSaveForLater=()=>{
    let total=0;
   
   
    productResponse?.saveForLater?.data?.forEach((item)=>{
total=total+ item.quantity;
    })
    
  
  
  
    return total
  }

  const handleCheckout =()=>{
   
   if(isItemOutOfStock)
   {
    notify("Please remove products that are out of stock", constant.ERROR ,"outOfStock")
   }
   else{
    let CartId:number[]=[];
    productResponse?.cart?.data?.forEach((item)=>{
     CartId.push(item.cartId)
          })
          navigate(
            "/buyer/checkout",
            {
              state: {ProductId: null,
                quantity:null,
              VariantId: null,
              type:"Cart",
              CartIds: CartId},
      
            }
          )
   }
        }

  
  const handleAddToCart=(quantity: number, productId: string , VariantId:number)=>{
    dispatch<any>(
      addToCart({
        isCart: true,
        
        Cart:[{
        productId: productId,
        quantity: quantity,
        VariantId:VariantId
      }]})
    ).then(() => dispatch<any>(getCart())).then(()=>dispatch<any>(resetSave()));
  }


   
  const handlePayOnlyThisSeller=(cartId:number)=>{
    dispatch<any>(resetDetail()).then(()=>
    navigate(
      "/buyer/checkout",
      {
        state: {ProductId: null,
        VariantId: null,
        type:"Cart",
        CartIds: [cartId]},

      }
    )
    )
         
  }
  return (
    <DefaultLayout>
      <BuyerHeader />
      <ToastContainer />

      <main className="min-section">
        <div className="container">
          <section>
            <div className="row">
              <div className={`${((isLoggedIn === "false" && localCart.length>0) || (isLoggedIn !== "false" && productResponse?.cart?.data?.length>0)) ?"col-xl-9" : "col-xl-12" }`}>
                <div className="myCart mt-5">
                  <h1>
                    Shopping cart (
                    {calculateItems() }
                   {" "} items)
                  </h1>

                  <div className="cartProducts">
                    { productResponse?.cart?.data?.map((product, Index) => (
                      <div className="cartProductLists" key={Index}>
                        <div className="row justify-content-between align-items-center mb-5 mb-md-0">
                          <div className="col-md-auto">
                            <div className="sellerName">
                              <span>Seller</span>
                              <a href="#">{product?.storeName}</a>
                            </div>
                          </div>
                          <div className="col-md-auto">
                            <div className="paySeller">
                              <div style={{cursor:"pointer", fontSize:12, color:"blue"}} onClick={()=>handlePayOnlyThisSeller(product.cartId)}>Pay this seller </div>
                            </div>  
                          </div>
                        </div>

                        <div className="productCount">
                          <div className="img"  onClick={() =>
                                dispatch<any>(resetDetail()).then(()=>navigate(`/buyer/productDetail/${product.productId}/${product.variantId}`))
                              } style={{cursor:"pointer"}}>
                            <img
                              src={
                                BASE_URLS.AWS_URL +
                                "/Thumb" +
                                product.defaultImage
                              }
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = DefaultImage;
                              }}
                              alt=""
                            />
                          </div>
                          <div className="prodContext">
                            <div className="row">
                              <div className="col-lg-5">
                                <h3>
                                  {product.title.substring(0, 40)}
                                  {product.title.length > 39 && "..."}
                                </h3>
                                <div className="brandNew">
                                  Condition:{product.condition}
                                </div>
                                <div className="specs">
                                 {
                                  JSON.parse(product.variant)?.map((item:any,Index:number)=>(
                                  <p key={Index}> {`${item.AttName} : ${item.Option}`} </p>
                                  ))

                                 }
                                </div>
                                { (product?.volumePricing && product?.quantity>1 )&& <div className="specs">
                                <span style={{fontWeight:"bold"}}>Discount applied:</span> <span style={{color:"green", fontWeight:"bold"}}>{product?.quantity ===2 ? product?.buy2ItemDiscount :product?.quantity ===3 ? product?.buy3ItemDiscount : product?.buy4ItemDiscount }%</span> off each item as you are purchasing <span style={{fontWeight:"bold"}}>{product.quantity}</span> quantity of this product.
                               
                               
                                </div>}
                              </div>

                              <div className="col-lg-4">
                                <div className="qty">
                                  <div>
                                    <label htmlFor="">QTY</label>
                                  </div>
                                  <select
                                    name=""
                                    id=""
                                    value={product.quantity}
                                    onChange={(e) =>
                                      handleQuantityChange(
                                        Number(e.target.value),
                                        product.productId, product.variantId
                                        
                                      )
                                    }
                                  >
                                    {quantityDropdown(product.maxQuantity)}
                                  </select>
                                 { product?.maxQuantity==0 && <p style={{color:"red", fontSize:14}} className="mt-2">Out of stock</p>}
                                </div>

                                <div className="text">
                                  {/* USPS First className Mail International /{" "}
                                  <br />
                                  First className Package International Service */}
                                </div>
                              </div>

                              <div className="col-lg-3">
                                {product.actualPrice && (
                                  <div className="disPrice">
                                    US ${product.actualPrice}
                                  </div>
                                )}
                                <div className="price">
                                  US $
                                  {product.buyNowPrice
                                    ? product?.buyNowPrice
                                    : product.actualPrice}
                                </div>
                               
                              </div>
                            </div>

                            <div className="btnBlock">
                              <button className="saveForLater"   onClick={() => handleSaveForLater(product.cartId)}>
                                Save for later
                              </button>
                              <button
                                className="remove"
                                onClick={() => handleDelete(product.cartId)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
          {isLoggedIn==="" && productResponse?.cart?.data===null  &&
          <h3 className="text-danger">You don't have any items in your cart.</h3>
 
          }
                    {isLoggedIn === "false" &&
                      localCart.map((product: any, Index: number) => (
                        <div
                          className="cartProductLists"
                          key={product.productId}
                        >
                          <div className="row justify-content-between align-items-center mb-5 mb-md-0">
                            <div className="col-md-auto">
                              <div className="sellerName">
                                <span>Seller</span>
                                <a href="#">{product?.storeName}</a>
                              </div>
                            </div>
                            <div className="col-md-auto">
                            <div style={{cursor:"pointer", fontSize:12, color:"blue"}} onClick={()=>notify("Please login to continue",constant.ERROR)}>Pay this seller </div>

                            </div>
                          </div>

                          <div className="productCount">
                            <div className="img">
                              <img
                                src={
                                  BASE_URLS.AWS_URL +
                                  "/Thumb" +
                                  product.defaultImage
                                }
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = DefaultImage;
                                }}
                                alt=""
                              />
                            </div>
                            <div className="prodContext">
                              <div className="row">
                                <div className="col-lg-5">
                                  <h3>
                                    {product.title.substring(0, 40)}
                                    {product.title.length > 39 && "..."}
                                  </h3>
                                  <div className="brandNew">
                                    Condition:{product.condition}
                                  </div>
                                  <div className="specs">
                                 {
                                  JSON.parse(product.variant)?.map((item:any,Index:number)=>(
                                  <p key={Index}> {`${item.AttName} : ${item.Option}`} </p>
                                  ))

                                 }
                                </div>
                                </div>

                                <div className="col-lg-4">
                                  <div className="qty">
                                    <div>
                                      <label htmlFor="">QTY</label>
                                    </div>
                                    <select
                                      name=""
                                      id=""
                                      value={product.quantity}
                                      onChange={(e) =>
                                        handleQuantityChange(
                                          Number(e.target.value),
                                          product.productId,
                                        product.variantId
                                        )
                                      }
                                    >
                                      {quantityDropdown(product.maxQuantity)}
                                    </select>
                                  </div>

                                  {/* <div className="text">
                                    USPS First className Mail International /{" "}
                                    <br />
                                    First className Package International
                                    Service
                                  </div> */}
                                </div>

                                <div className="col-lg-3">
                                  {product.buyNowPrice && (
                                    <div className="disPrice">
                                      US ${product.buyNowPrice}
                                    </div>
                                  )}
                                  <div className="price">
                                    US $
                                    {product.buyNowPrice
                                      ? product?.buyNowPrice
                                      : product.actualPrice}
                                  </div>
                                  {/* <div className="packPrice">
                                    + $33.60 {product.shippingCost} check
                                    <span>(US $24.41)</span>
                                  </div> */}
                                </div>
                              </div>

                              <div className="btnBlock">
                           
                                <button
                                  className="remove"
                                  onClick={() =>
                                    handleLocalDelete(product.productId)
                                  }
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

         { ((isLoggedIn === "false" && localCart.length>0) || (isLoggedIn !== "false" && productResponse?.cart?.data?.length>0)) &&   <div className="col-xl-3">
                <div className="checkoutBlock mt-5">
              
                {isLoggedIn === "false" && localCart.length>0 &&     <Link className="checkoutBtn" to="/buyer/checkout">Checkout</Link>
}
{isLoggedIn !== "false" && productResponse?.cart?.data?.length>0 &&   
<button className="checkoutBtn" 
onClick={handleCheckout
    }
    >Checkout</button>
}
                  <div className="itemPrice">
                    <div className="row">
                      <div className="col-6">
                        <p>Items ({calculateItems() })</p>
                      </div>
                      <div className="col-6 text-right">
                        <p>US ${itemTotal.toFixed(2)}</p>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-6">
                        <p>Shipping to check</p>
                      </div>
                      <div className="col-6 text-right">
                        <p>US check</p>
                      </div>
                    </div> */}
                  </div>
                  <div className="subtotal">
                    <div className="row">
                      <div className="col-6">
                        <p>Subtotal</p>
                      </div>
                      <div className="col-6 text-right">
                        <p>US ${itemTotal.toFixed(2)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
            </div>
          </section>
<div>

{isLoggedIn==="" &&  <div className={`${( ( productResponse?.cart?.data?.length>0)) ?"col-xl-9" : "col-xl-12" }`}>
                <div className="myCart mt-5">
                  <h1>
                    Saved for later (
                    {calculateItemsSaveForLater()}
                    )
                  </h1>

                  <div className="cartProducts">
                    {productResponse?.saveForLater?.data?.map((product, Index) => (
                      <div className="cartProductLists" key={Index}>
                        {/* <div className="row justify-content-between align-items-center mb-5 mb-md-0">
                          <div className="col-md-auto">
                            <div className="sellerName">
                              <span>Seller</span>
                              <a href="">{product?.storeName}</a>
                            </div>
                          </div>
                          <div className="col-md-auto">
                          
                          </div>
                        </div> */}

                        <div className="productCount">
                          <div className="img">
                            <img
                              src={
                                BASE_URLS.AWS_URL +
                                "/Thumb" +
                                product.defaultImage
                              }
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = DefaultImage;
                              }}
                              alt=""
                            />
                          </div>
                          <div className="prodContext">
                            <div className="row">
                              <div className="col-lg-5">
                                <h3>
                                  {product.title.substring(0, 40)}
                                  {product.title.length > 39 && "..."}
                                </h3>
                                <div className="brandNew">
                                  Condition:{product.condition}
                                </div>
                                <div className="specs">
                                 {
                                  JSON.parse(product.variant)?.map((item:any, Index:number)=>(
                                  <p key={Index}> {`${item.AttName} : ${item.Option}`} </p>
                                  ))

                                 }
                                  {
                                
                                  <p> {`Quantity : ${product.quantity}`} </p>
                                  

                                 }
                              
                                    { (product?.volumePricing && product?.quantity>1 )&& <div className="specs">
                                <span style={{fontWeight:"bold"}}>Discount applied:</span> <span style={{color:"green", fontWeight:"bold"}}>{product?.quantity ===2 ? product?.buy2ItemDiscount :product?.quantity ===3 ? product?.buy3ItemDiscount : product?.buy4ItemDiscount }%</span> off each item as you are purchasing <span style={{fontWeight:"bold"}}>{product.quantity}</span> quantity of this product.
                               
                               
                                </div>}
                                </div>
                              </div>

                              <div className="col-lg-4">
                                {/* <div className="qty">
                                  <div>
                                    <label htmlFor="">QTY</label>
                                  </div>
                                  <select
                                    name=""
                                    id=""
                                    value={product.quantity}
                                    onChange={(e) =>
                                      handleQuantityChange(
                                        Number(e.target.value),
                                        product.productId, product.variantId
                                        
                                      )
                                    }
                                  >
                                    {quantityDropdown(product.maxQuantity)}
                                  </select>
                                </div> */}

                                <div className="text">
                                  {/* USPS First className Mail International /{" "}
                                  <br />
                                  First className Package International Service */}
                                </div>
                              </div>

                              <div className="col-lg-3">
                                {product.actualPrice && (
                                  <div className="disPrice">
                                    US ${product.actualPrice}
                                  </div>
                                )}
                                <div className="price">
                                  US $
                                  {product.buyNowPrice
                                    ? product?.buyNowPrice
                                    : product.actualPrice}
                                </div>
                                {/* <div className="packPrice">
                                  + shipping cost {product.shippingCost} check
                               
                                </div> */}
                              </div>
                            </div>

                            <div className="btnBlock">
                              <button className="saveForLater"   onClick={() => handleAddToCart( Number(product.quantity),
                                        product.productId, product.variantId)}>
                                Add to cart
                              </button>
                              <button
                                className="remove"
                                onClick={() => handleDelete(product.cartId)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                
                  </div>
                </div>
              </div>}
</div>
          {/* <section className="recently-view-section mb-5">
            <div className="row">
              <div className="col-md-12">
                <div className="h2-block">
                  <h2 className="h2-section-title">Related sponsored items</h2>
                </div>
                <div className="recent-view-container">
                  <a href="javascript:void(0);" className="prev-slide">
                    <i className="prev-icon"></i>
                  </a>
                  <a href="javascript:void(0);" className="next-slide">
                    <i className="next-icon"></i>
                  </a>

                  <div className="recent-listing-block">
                    <div className="swiper-container swiper recentListSwiper">
                      <div className="swiper-wrapper">
                        <div className="swiper-slide">
                          <div className="recent-list-item">
                            <figure className="recent-media">
                              <a href="#">
                                <img src="images/Layer 5.jpg" />
                              </a>
                            </figure>
                            <div className="recent-details">
                              <div className="recent-price">$17.99</div>
                              <div className="recent-price-small">
                                <span className="price-cut">US $34.77</span>
                                <span>|</span>
                                <span className="price-bold">20% OFF</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="swiper-slide">
                          <div className="recent-list-item">
                            <figure className="recent-media">
                              <a href="#">
                                <img src="images/Layer 6.jpg" />
                              </a>
                            </figure>
                            <div className="recent-details">
                              <div className="recent-price">$17.99</div>
                              <div className="recent-price-small">
                                <span className="price-cut">US $34.77</span>
                                <span>|</span>
                                <span className="price-bold">20% OFF</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="swiper-slide">
                          <div className="recent-list-item">
                            <figure className="recent-media">
                              <a href="#">
                                <img src="images/Layer 7.jpg" />
                              </a>
                            </figure>
                            <div className="recent-details">
                              <div className="recent-price">$17.99</div>
                              <div className="recent-price-small">
                                <span className="price-cut">US $34.77</span>
                                <span>|</span>
                                <span className="price-bold">20% OFF</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="swiper-slide">
                          <div className="recent-list-item">
                            <figure className="recent-media">
                              <a href="#">
                                <img src="images/Layer 8.jpg" />
                              </a>
                            </figure>
                            <div className="recent-details">
                              <div className="recent-price">$17.99</div>
                              <div className="recent-price-small">
                                <span className="price-cut">US $34.77</span>
                                <span>|</span>
                                <span className="price-bold">20% OFF</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="swiper-slide">
                          <div className="recent-list-item">
                            <figure className="recent-media">
                              <a href="#">
                                <img src="images/Layer 9.jpg" />
                              </a>
                            </figure>
                            <div className="recent-details">
                              <div className="recent-price">$17.99</div>
                              <div className="recent-price-small">
                                <span className="price-cut">US $34.77</span>
                                <span>|</span>
                                <span className="price-bold">20% OFF</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="swiper-slide">
                          <div className="recent-list-item">
                            <figure className="recent-media">
                              <a href="#">
                                <img src="images/Layer 5.jpg" />
                              </a>
                            </figure>
                            <div className="recent-details">
                              <div className="recent-price">$17.99</div>
                              <div className="recent-price-small">
                                <span className="price-cut">US $34.77</span>
                                <span>|</span>
                                <span className="price-bold">20% OFF</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="swiper-slide">
                          <div className="recent-list-item">
                            <figure className="recent-media">
                              <a href="#">
                                <img src="images/Layer 6.jpg" />
                              </a>
                            </figure>
                            <div className="recent-details">
                              <div className="recent-price">$17.99</div>
                              <div className="recent-price-small">
                                <span className="price-cut">US $34.77</span>
                                <span>|</span>
                                <span className="price-bold">20% OFF</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="swiper-slide">
                          <div className="recent-list-item">
                            <figure className="recent-media">
                              <a href="#">
                                <img src="images/Layer 7.jpg" />
                              </a>
                            </figure>
                            <div className="recent-details">
                              <div className="recent-price">$17.99</div>
                              <div className="recent-price-small">
                                <span className="price-cut">US $34.77</span>
                                <span>|</span>
                                <span className="price-bold">20% OFF</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="swiper-slide">
                          <div className="recent-list-item">
                            <figure className="recent-media">
                              <a href="#">
                                <img src="images/Layer 8.jpg" />
                              </a>
                            </figure>
                            <div className="recent-details">
                              <div className="recent-price">$17.99</div>
                              <div className="recent-price-small">
                                <span className="price-cut">US $34.77</span>
                                <span>|</span>
                                <span className="price-bold">20% OFF</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="swiper-slide">
                          <div className="recent-list-item">
                            <figure className="recent-media">
                              <a href="#">
                                <img src="images/Layer 9.jpg" />
                              </a>
                            </figure>
                            <div className="recent-details">
                              <div className="recent-price">$17.99</div>
                              <div className="recent-price-small">
                                <span className="price-cut">US $34.77</span>
                                <span>|</span>
                                <span className="price-bold">20% OFF</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
        </div>
      </main>
      <BuyerFooter />
    </DefaultLayout>
  );
};

export default MyCart;
