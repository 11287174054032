import React, { useEffect, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { dateToLocal } from '../../../modules/date/date';
import { notify } from '../../../modules/notification/notify';
import { getSellerOfferUserList } from '../../../modules/sellerProduct/getSellerOfferUserList';
import { reset } from '../../../modules/sellerProduct/reset';
import { saveAcceptRejectOffer } from '../../../modules/sellerProduct/saveAcceptRejectOffer';
import { SellerProductResponseSelector } from '../../../modules/sellerProduct/selector';
import { BASE_URLS } from '../../../utils/api.urls';
import { constant } from '../../../utils/constant';
import { RootState } from '../../../utils/types';
import { saveCounterOffer } from '../../../modules/sellerProduct/saveCounterOffer';
// import { saveCounterOffer } from '../../../modules/buyer/product/saveCounterOffer';



const OffersModal = ({ closeModal,offersModal}:any) => {
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(10);
  const [currentPage, setcurrentPage] = useState(0);
  const [counterOffer, setCounterOffer] = useState({
    isCounterClicked:false,
    counterId:0,
    previousOffer:0,
    quantity:0,
    offerAmount:"",
  });
  // const [counterOffer, setCounterOffer] = useState(0);
  const isFirstRender = useRef(true);
  const isFirstRender2 = useRef(true);
  const dispatch = useDispatch();

  const product = useSelector<RootState, RootState["sellerProduct"]>(
    SellerProductResponseSelector
  );
  if (product?.offersUsers?.data?.offerUserDetail !== null && pageCount === 0) {
    setPageCount(Math.ceil(product?.offersUsers?.data?.totalResult / itemCount));
  }
  const isNumeric=(value:any)=> {
    return /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/.test(value);
  }
  useEffect(() => {
    if (isFirstRender.current && offersModal?.productID!=="") {
      dispatch<any>(
        getSellerOfferUserList({
          currentPage: currentPage + 1,
          rows: itemCount,
          
          productId: offersModal?.productId,
        })
      ).then(() => {
        if (currentPage === 0) setPageCount(0);
      });
    }
    isFirstRender.current = false;

   
  }, [offersModal?.productID]);

  useEffect(() => {
    if (!isFirstRender2.current && offersModal?.productID!=="") {
      dispatch<any>(
        getSellerOfferUserList({
          currentPage: currentPage + 1,
          rows: itemCount,
          
          productId: offersModal?.productId,
        })
      ).then(() => {
        if (currentPage === 0) setPageCount(0);
      });
    }
    isFirstRender2.current = false;
  }, [currentPage, itemCount,offersModal?.productID]);
  
  const handlePageChange = (selectedObject: any) => {
    setcurrentPage(selectedObject.selected);
  };

  useEffect(() => {
    if (product.saveData != null) {
      if (product.saveData.success == "Y") {
        notify(product.saveData.message, constant.SUCCESS);
        setCounterOffer({
          isCounterClicked:false,
    counterId:0,
    previousOffer:0,
    quantity:0,
    offerAmount:"",
        })
        dispatch<any>(
          getSellerOfferUserList({
            currentPage: currentPage + 1,
            rows: itemCount,
            
            productId: offersModal?.productId,
          })
        ).then(() => {
          if (currentPage === 0) setPageCount(0);
        });
      } 
    }
    if (product.error !== null) {
      notify(product.error, constant.ERROR);
    }
    dispatch<any>(reset("saveData"));
  }, [product.saveData, product.error]);

  // const handleItemsChange = (event: any) => {
  //   setItemCount(event.target.value);
  //   setcurrentPage(0);
  // };
  const handleSubmit =()=>{
    const now = new Date();
  
      const timezoneOffsetMinutes = now.getTimezoneOffset();
     const timezone= timezoneOffsetMinutes < 0 ? timezoneOffsetMinutes*-1 : timezoneOffsetMinutes;
  
      dispatch<any>(saveCounterOffer({
  
        "OfferId": counterOffer?.counterId,
        "offerAmount": counterOffer?.offerAmount,
        "TimeZone": timezone

      }))
    }
  return (
    <div className="modal modal-order-popup offers-modal-popup" id="myModal" tabIndex={-1} role="dialog"  style={{ display: offersModal.visible }}>
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="order-modal">
        <button type="button" className="btn-close-icon close" onClick={()=>closeModal()} data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      
            <div className="order-modal-head">Offers</div>



            <div className="seller-table-popup-block">
            <table className="table table-photo-offer">
              <thead>
                <tr>
                  <th style={{width:"40px"}}>Photo</th>
                  <th style={{width:"300px"}} scope="col">Title</th>
                  <th style={{width:"100px"}} scope="col">Base price</th>
                  <th scope="col">Offers</th>
                </tr>
              </thead>
              <tbody>
               {product?.offersUsers?.data?.productDetail?.length && <tr>
                  <th scope="row">
                    <div className="offer-photo"><img  src={
                                    BASE_URLS.AWS_URL +
                                    "/Thumb" +
                                    product?.offersUsers?.data?.productDetail[0]?.productImage
                                  }
                                  alt="dummy image"
                                  /></div>
                  </th>
                  <td>{  product?.offersUsers?.data?.productDetail[0]?.productName}</td>
                  <td><div className="blue-link">${  product?.offersUsers?.data?.productDetail[0]?.actualPrice}</div></td>
                  <td className="blue-link"> {`$ ${ product?.offersUsers?.data?.productDetail[0]?.maxOffer} | ${ product?.offersUsers?.data?.productDetail[0]?.offers} Offers`}</td>
                </tr>}
              </tbody>
            </table>
      
            <table className="table table-report-data">
            { counterOffer?.isCounterClicked ?
          <><thead className="offer-thead">
          <tr>
            <th style={{width:"150px"}} >Offer price per item</th>
            <th scope="col">Quantity</th>

            <th style={{width:"216px"}}  scope="col">Your offer per item ($)</th>
            {/* <th style={{width:"100px"}}  scope="col">Offer price</th> */}
            {/* <th scope="col">Offer date & time</th> */}
            {/* <th scope="col">Status</th> */}
            <th scope="col"></th>

            <th scope="col">Action</th>
          </tr>
        </thead>
           <tbody>  <tr >
                 <th scope="row"  >$ {counterOffer?.previousOffer}</th>
                 <td>{counterOffer?.quantity}</td>
        <td> <input placeholder='Enter Counter Offer Value'  value={counterOffer?.offerAmount}  onChange={(e)=>setCounterOffer({...counterOffer, offerAmount:e.target.value})} className="form-control input-modal" type="text"/></td>
   <td>
   {!isNumeric(counterOffer.offerAmount) && counterOffer.offerAmount!=="" ? <p className="required">You've entered an invalid price</p>
           : isNumeric(counterOffer.offerAmount) && counterOffer.offerAmount!=="" && (product?.offersUsers?.data?.productDetail[0]?.actualPrice && product?.offersUsers?.data?.productDetail[0]?.actualPrice < Number(counterOffer.offerAmount) ) && <p className="required">Your offer is higher than the Buying Price.</p>}
     
   </td>
        <td> <div className="modal-btn-group"><button className="modal-cancel-btn " style={{width:"90px"}} onClick={() =>
                               
                              handleSubmit()
        }
                               disabled={
                 
                                counterOffer.offerAmount === "" || !isNumeric(counterOffer.offerAmount) ||  
              
                                 product?.offersUsers?.data?.productDetail[0]?.actualPrice < Number(counterOffer.offerAmount) 
                                  ? true
                                  : false
                              }
                             >Counter Offer</button><button className="modal-cancel-btn  ml-2"  onClick={() =>
                               
                              setCounterOffer({
                                isCounterClicked:false,
                          counterId:0,
                          previousOffer:0,
                          quantity:0,
                          offerAmount:"",
                              })
                             }>Cancel</button> </div></td>

           </tr>
           {/* <tr>
                       {!isNumeric(counterOffer.offerAmount) && counterOffer.offerAmount!=="" ? <p className="required">You've entered an invalid price</p>
           : isNumeric(counterOffer.offerAmount) && counterOffer.offerAmount!=="" && (product?.offersUsers?.data?.productDetail[0]?.actualPrice && product?.offersUsers?.data?.productDetail[0]?.actualPrice < Number(counterOffer.offerAmount) ) && <p className="required">Your offer is higher than the Buying Price.</p>}
           </tr> */}
           
           </tbody> </>:
             <>  <thead className="offer-thead">
                <tr>
                  <th style={{width:"150px"}} >Name</th>
                  {/* <th style={{width:"216px"}}  scope="col">Email</th> */}
                  <th style={{width:"100px"}}  scope="col">Offer price per item</th>
                  <th style={{width:"100px"}}  scope="col">Quantity</th>

                  <th scope="col">Offer date & time</th>
                  <th scope="col">Status</th>

                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
              {product?.offersUsers?.data?.offerUserDetail?.map((offer, Index)=>  
              
              {
              
           
                return(
              <tr key={Index}>
                 
                  <th scope="row">{offer?.buyerName}</th>
                  {/* <td>{offer?.email}</td> */}
                  <td><div className="blue-link">${offer?.offerAmount}</div></td>
                  <td>{offer?.quantity}</td>

                  <td>{dateToLocal(offer?.offerDate)}</td>
                  <td>{offer?.status ===2 ? "Accepted" : offer?.status ===3 ? "Rejected" : offer?.status===4 ? "Expired": offer?.status===5 ? "Pending with buyer" :"Pending"}</td>

                  <td>
                   {offer?.status==1 &&  <div className="modal-btn-group">
                      <button className="modal-add-btn" onClick={() =>
                                  dispatch<any>(
                                    saveAcceptRejectOffer({
                                      offerId: offer?.offerId,
                                      accepted: true
                                    })
                                  )
                                } >Accept</button>
                      <button className="modal-cancel-btn" onClick={() =>
                                  dispatch<any>(
                                    saveAcceptRejectOffer({
                                      offerId: offer?.offerId,
                                      accepted: false
                                    })
                                  )
                                }>Decline</button>
                                  <button className="modal-cancel-btn ml-2"  style={{width:"90px"}} onClick={() =>
                                  
                                  setCounterOffer({...counterOffer,quantity:offer?.quantity, counterId:offer?.offerId,previousOffer:offer?.offerAmount, isCounterClicked:true})
                                }>Counter Offer</button>
                    </div>}
                  </td>
                </tr>)
              }
           )  }
               
              </tbody>
              </>
       
            }
            </table>
            </div>
 {!counterOffer?.isCounterClicked  && <div>
  <nav className="pagination-block ">
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    forcePage={currentPage}
                    onPageChange={handlePageChange}
                    containerClassName={
                      "pagination justify-content-center mt-4"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />

                  {/* <div className="total-items-block">
                    <label>Items per page:</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={handleItemsChange}
                      value={itemCount}
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </div> */}
                </nav>
  </div>}
        </div>
      </div>
    </div>
  </div>
  )
}

export default OffersModal