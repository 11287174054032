import Api from "../../utils/api";
import { API_URLS } from "../../utils/api.urls";
const d = new Date();
let TimeZone = d.getTimezoneOffset() * -1;
export const fetchDashboard = async (): Promise<any> => {
  const data = await Api.get(
    API_URLS.GET_SELLER_DASHBOARD + "?timeZone=" + TimeZone,
    []
  );
  return data;
};
export const fetchDashboardTasks = async (): Promise<any> => {
  const data = await Api.get(
    API_URLS.GET_SELLER_DASHBOARD + "Task?timeZone=" + TimeZone,
    []
  );
  return data;
};
export const fetchDashboardFeedbacks = async (): Promise<any> => {
  const data = await Api.get(
    API_URLS.GET_SELLER_DASHBOARD + "Feedback?timeZone=" + TimeZone,
    []
  );
  return data;
};
