import { AppThunk } from "../../../../store";
import { BidsFailed, BidsSuccess } from "./slice";
import { fetchBids } from "./api";
import { EndProgress } from "../../../progressBar/endProgress";
import { StartProgress } from "../../../progressBar/startProgress";

export const getBids =
  (params: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
      const data: any = await fetchBids(params);
      

      if (data.success === "N") {
        
        dispatch(BidsFailed(data));
      } else {
        
        dispatch(BidsSuccess(data));
      }
    } catch (err) {
      
      dispatch(BidsFailed(err));
    }
    dispatch<any>(EndProgress());
  };
