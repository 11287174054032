import { AppThunk } from "../../../../store";

import { resetRecentlyViewedDelete } from "./slice";

export const resetDeleteRecentlyViewed =
  (data = ""): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(resetRecentlyViewedDelete());
    } catch (err) {
      
    }
  };
