import Api from "../../utils/api";
import { API_URLS } from "../../utils/api.urls";

//const api: string|undefined= process.env.REACT_APP_API;
// const d = new Date();
// let TimeZone = d.getTimezoneOffset() * -1;

export const fetchCommonData = async (): Promise<any> => {
  const data = await Api.get(API_URLS.COMMON, []);
  return data;
};

export const fetchAddress = async (id: string | null): Promise<any> => {
  const data = await Api.get(
    API_URLS.ADDRESS +
      id +
      "?$expand=Addresses($expand=Country($select=CountryName)&$expand=State($select=StateName))&$select=UserId",
    []
  );
  return data;
};
export const fetchSinglePage = async (pageCode: any | null): Promise<any> => {
  const data = await Api.get(API_URLS.CMS_CRUD + '/' + pageCode , [])
  return data;
}
export const fetchCountry = async (): Promise<any> => {
  const data = await Api.get(API_URLS.GET_COUNTRY , [])
  return data;
}

export const fetchCountryById = async (id :string | null): Promise<any> => {
  const data = await Api.get(API_URLS.GET_COUNTRY + "?$filter=Id eq "+ id  , [])
  return data;
}

export const fetchStateByCountry = async (code: any | null): Promise<any> => {
  const data = await Api.get(API_URLS.GET_STATE + "?$filter=CountryCode eq '"+ code +"'" , [])
  return data;
}