import { AppThunk } from "../../store";
import { DealsSellerFailed, DealsSellerSuccess } from "./slice";
import { fetchActiveListing, fetchDealsSeller, fetchOffersSeller } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const getDealsSeller =
  (data: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
      const productData: any = await fetchDealsSeller(data);
      if (productData.value.length === 0) {
        dispatch(DealsSellerFailed(productData));
      } else {
        dispatch(DealsSellerSuccess(productData));
      }
    } catch (err) {
      dispatch(DealsSellerFailed(err));
    }
    dispatch<any>(EndProgress());
  };
