import { AppThunk } from "../../store";
import { Login } from "../../utils/types";
import { UserLoginSuccess, UserLoginFailed } from "./slice";
import { fetchUserLoginData } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const getUserLogin =
  (Data: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());

    try {
      const UserData: any = await fetchUserLoginData(Data);

      if (UserData.success === "N") {
        dispatch(UserLoginFailed(UserData));
      } else if (UserData.data.roleCode === "Admin") {
        var data = { message: "Access Denied!" };
        dispatch(UserLoginFailed(data));
      } else if(Data.type===3 && UserData.data.roleCode === "Seller" ){
        dispatch(UserLoginSuccess(UserData));
      }
      else if(Data.type===2 && UserData.data.roleCode === "Customer" ){
        dispatch(UserLoginSuccess(UserData));
      }
      else 
      dispatch(UserLoginFailed({message:"Invalid Credentials"}));
    } catch (err) {
      dispatch(UserLoginFailed(err));
    }
    dispatch<any>(EndProgress());
  };
