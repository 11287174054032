import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { deleteShippingAddress } from "../../../../../modules/buyer/myEfindit/account/deleteShippingAddress";
import { getBuyerProfile } from "../../../../../modules/buyer/myEfindit/account/getBuyerProfile";
import { accountResponseSelector } from "../../../../../modules/buyer/myEfindit/account/selectors";
import { updateProfileAddress } from "../../../../../modules/buyer/myEfindit/account/updateProfileAddress";
import { updateShippingAddress } from "../../../../../modules/buyer/myEfindit/account/updateShippingAddress";
import { reset } from "../../../../../modules/buyer/myEfindit/account/reset";
import { getCountries } from "../../../../../modules/common/getCountries";
import { getStateByCountryCode } from "../../../../../modules/common/getStateByCountryCode";
import { CommonResponseSelector } from "../../../../../modules/common/selectors";
import { notify } from "../../../../../modules/notification/notify";
import { constant } from "../../../../../utils/constant";
import errorMsg from "../../../../../utils/errorMsg";
import { RootState } from "../../../../../utils/types";
import BuyerFooter from "../../../buyerFooter";
import BuyerHeader from "../../../BuyerHeader";
import GroupTab from "../../groupTab";
import SideNav from "../sideNav";
import PersonalAddress from "./personalAddress";
import ShippingAddress from "./shippingAddress";
import { useLocation, useNavigate } from "react-router-dom";
import { setDeafultShippingAddress } from "../../../../../modules/buyer/myEfindit/account/setDefautShippingAddress";

const Addresses = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isFirstRender = useRef(true);
  const [render, setRender] = useState(0);
  const { state } = useLocation();

  const [shippingBtnTitle, setShippingBtnTitle] = useState("Save");

  const [profileAddress, setProfileAddress] = useState({
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Address1: "",
    Address2: "",
    City: "",
    StateId: 0,
    CountryId: 0,
    ZipCode: "",
    State: {
      StateName: "",
    },
    Country: {
      CountryName: "",
    },
  });

  const [newProfileAddress, setNewProfileAddress] = useState({
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Address1: "",
    Address2: "",
    City: "",
    StateId: 0,
    CountryId: 2,
    ZipCode: "",
  });

  const [shippingAddress, setShippingAddress] = useState([
    {
      Id: "",
      FirstName: "",
      MiddleName: "",
      LastName: "",
      Address1: "",
      Address2: "",
      City: "",
      StateId: 0,
      CountryId: 0,
      ZipCode: "",
      CallingCode: 0,
      MobileNo: "",
      Active: "",
      IsDefaultAddress:"",

      State: {
        StateName: "",
      },
      Country: {
        CountryName: "",
      },
    },
  ]);
  const [newShippingAddress, setNewShippingAddress] = useState({
    Id: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Address1: "",
    Address2: "",
    City: "",
    StateId: 0,
    CountryId: 0,
    ZipCode: "",
    CallingCode: 91,
    MobileNo: "",
  });

  let accountResponse = useSelector<RootState, RootState["account"]>(
    accountResponseSelector
  );

  const commonResponse = useSelector<RootState, RootState["common"]>(
    CommonResponseSelector
  );

  useEffect(() => {
    if (isFirstRender.current) {
      dispatch<any>(getBuyerProfile());
    }
    isFirstRender.current = false;
  }, []);


  useEffect(() => {
    let newShippingData: any = [];
    accountResponse.profile &&
      accountResponse.profile.value[0].Addresses.map((address: any) => {
        if (address.IsShipping === true) {
          newShippingData.push({
            Id: address.Id,
            FirstName: address.FirstName,
            MiddleName: address.MiddleName !== null ? address.MiddleName : "",
            LastName: address.LastName,
            CallingCode: address.CallingCode,
            MobileNo: address.MobileNo,
            Address1: address.Address1,
            Address2: address.Address2 !== null ? address.Address2 : "",
            City: address.City,
            StateId: address.StateId,
            CountryId: address.CountryId,
            ZipCode: address.ZipCode,
            Active: address.Active,
            IsDefaultAddress:address.IsDefaultAddress,

            State: {
              StateName: address.State.StateName,
            },
            Country: {
              CountryName: address.Country.CountryName,
            },
          });
        }
        if (address.IsPersonal === true) {
          setProfileAddress({
            FirstName: address.FirstName,
            MiddleName: address.MiddleName !== null ? address.MiddleName : "",
            LastName: address.LastName,
            Address1: address.Address1,
            Address2: address.Address2 !== null ? address.Address2 : "",
            City: address.City,
            StateId: address.StateId,
            CountryId: address.CountryId,
            ZipCode: address.ZipCode,
            State: {
              StateName: address?.State?.StateName,
            },
            Country: {
              CountryName: address?.Country?.CountryName,
            },
          });

          setNewProfileAddress({
            FirstName: address.FirstName,
            MiddleName: address.MiddleName !== null ? address.MiddleName : "",
            LastName: address.LastName,
            Address1: address.Address1,
            Address2: address.Address2 !== null ? address.Address2 : "",
            City: address.City,
            StateId: address.StateId,
            CountryId: address.CountryId,
            ZipCode: address.ZipCode,
          });
        }
      });

    setShippingAddress(newShippingData);
    
  }, [accountResponse.profile]);

  useEffect(() => {
    let countryData =
      commonResponse.countries &&
      commonResponse?.countries?.value.find(
        (item: any) =>
          item.Id == accountResponse?.profile?.value[0].Addresses[0].CountryId
      );

   
    if (commonResponse.countries && countryData !== undefined && render == 0) {
      dispatch<any>(getStateByCountryCode(countryData?.CountryCode));
      setRender(1);
    }
  }, [accountResponse.profile, commonResponse.countries]);

  
  const [addressInput, setAddressInput] = useState({
    data: true,
    input: false,
    editBtn: true,
    cancelBtn: false,
  });

  const [shippingAddressInput, setShippingAddressInput] = useState({
    data: true,
    input: false,
    addBtn: true,
    cancelBtn: false,
  });

  const editShippingAddress = (addr: any) => {
    setNewShippingAddress(addr);
    setShippingBtnTitle("Update");
    showInput("shippingAddress");
  };

  const handleDeleteShippingAddress = (Id: number) => {
    dispatch<any>(deleteShippingAddress(Id));
  };

  const addShippingAddress = () => {
    setNewShippingAddress({
      Id: "",
      FirstName: "",
      MiddleName: "",
      LastName: "",
      Address1: "",
      Address2: "",
      City: "",
      StateId: 0,
      CountryId: 0,
      ZipCode: "",
      CallingCode: 91,
      MobileNo: "",
    });
    setShippingBtnTitle("Save");
    showInput("shippingAddress");
  };

  const showInput = (type: string) => {
    if (type === "address") {
      dispatch<any>(getCountries());
      setAddressInput({
        ...addressInput,
        data: false,
        input: true,
        editBtn: false,
        cancelBtn: true,
      });
    }

    if (type === "shippingAddress") {
      dispatch<any>(getCountries());
      setShippingAddressInput({
        ...shippingAddressInput,
        data: false,
        input: true,
        addBtn: false,
        cancelBtn: true,
      });
    }
  };

  const handleCancel = (type: string) => {
    if (type === "address") {
      setAddressInput({
        ...addressInput,
        data: true,
        input: false,
        editBtn: true,
        cancelBtn: false,
      });
    }
    if (type === "shippingAddress") {
      setShippingAddressInput({
        ...shippingAddressInput,
        data: true,
        input: false,
        addBtn: true,
        cancelBtn: false,
      });
    }
  };

  useEffect(() => {
    if (!isFirstRender.current) {
      if (accountResponse.saveData != null) {
        if (accountResponse.saveData.success === "Y") {
          notify(accountResponse.saveData.message, constant.SUCCESS);
          setAddressInput({
            ...addressInput,
            data: true,
            input: false,
            editBtn: true,
            cancelBtn: false,
          });
          setShippingAddressInput({
            ...shippingAddressInput,
            data: true,
            input: false,
            cancelBtn: false,
          });
          dispatch<any>(getBuyerProfile());
        } else if (accountResponse.saveData.success === "N") {
          notify(accountResponse.saveData.message, constant.ERROR);
        }  
      }

      if (accountResponse.errors != null) {
        errorMsg(accountResponse.errors)       
      }
      dispatch<any>(reset());
    }
  }, [accountResponse.saveData, accountResponse.errors]);

  return (
    <>
      <BuyerHeader />
      <main className="min-section">
        <div className="container">
          <h1 className="sectionTitle mt-5">My eFindit</h1>
          <GroupTab currentPage="Account" />
          <section className="withFilter">
            <SideNav />
            <ToastContainer />
            <div className="right-wrapper">
              <section className="deals-group pt-0">
                <div className="deal-group-list four-col bg-white mt-0">
                  <div className="right-wrap-title-block">
                    <div className="row align-items-center">
                      <div className="col-md-auto">
                        <div className="right-wrap-title">Addresses</div>
                      </div>
                    {state?.isCheckout &&  <div>
                      <button type="button" className="btn btn-warning" onClick={()=> navigate("/buyer/checkout")}>Back to Checkout</button>
                      </div>}
                    </div>
                  </div>
                  <div className="accountInfo">
                    {/* <div className="acountRow">
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="title">Registration address, <br />
                                                        email and phone number</div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <p>Create a password or modify your existing one.</p>
                                                </div>
                                                <div className="col-lg-3 text-right">
                                                    <button className="btn edit">Edit</button>
                                                </div>
                                            </div>
                                        </div> */}
                    <div className="acountRow">
                      <div className="row">
                        <div className="col-lg-3">
                          <div className="title">Shipping address  <span className="required">*</span></div>
                        </div>
                        <div className="col-lg-7">
                          <p className="pTitle">
                            Manage your shipping addresses.
                          </p>
                        </div>
                        <div className="col-lg-2 text-right">
                          {/* {shippingAddressInput.addBtn && ( */}


                            <button
                              className="btn add"
                              onClick={() => addShippingAddress()}
                            >
                              Add new
                            </button>
                          {/* )} */}
                          {shippingAddressInput.cancelBtn && (
                            <button
                              className="btn edit"
                              onClick={() => handleCancel("shippingAddress")}
                            >
                              Cancel
                            </button>
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-7">
                          {shippingAddressInput.data &&
                            shippingAddress.map((addr: any, index: any) =>
                              addr.Active == "Y" ? (
                                <div key={index}>
                                  <hr />
                                  <div className="shipaddress">
                                    <p>
                                      {addr.FirstName +
                                        " " +
                                        addr.MiddleName +
                                        " " +
                                        addr.LastName}
                                    </p>
                                    {addr.Address1 && (
                                      // <p>
                                      //   {addr.Address1 + ","} <br />
                                      //   {addr.Address2 && addr.Address2 + ","} {addr.City}
                                      //   <br />
                                      //   {addr.State.StateName} ,{" "}
                                      //   {addr.Country.CountryName} ,{" "}
                                      //   {addr.ZipCode}{" "}

                                        <p>
                                    {addr.Address1}
                                    { addr.Address2 && <><br />{addr.Address2}</> } <br/>
                                    {`${addr.City}, ${addr.State.StateName}  ${addr.ZipCode}`} <br />
                                    {addr.Country.CountryName} <br/>
                                  </p>
                                      // </p>

                                    )}
                                  </div>

                                  <button
                                    className="btn edit"
                                    onClick={() => editShippingAddress(addr)}
                                  >
                                    Edit
                                  </button>
                           
                                  <button
                                    className="btn remove"
                                    onClick={() =>
                                      handleDeleteShippingAddress(addr.Id)
                                    }
                                  >
                                    Remove
                                  </button>
                                  { addr?.IsDefaultAddress===null  &&  <button
                                    className="btn edit set"
                                    onClick={() => dispatch<any>(setDeafultShippingAddress(addr?.Id))}
                                  >
                                    Set as default
                                  </button>}
                                </div>
                              ) : null
                            )}

                          {shippingAddressInput.input && (
                            <ShippingAddress
                              shippingBtnTitle={shippingBtnTitle}
                              address={newShippingAddress}
                              common={commonResponse}
                              setNewShippingAddress={setNewShippingAddress}
                            />
                          )}
                        </div>
                        <div className="col-lg-2 text-right"></div>
                      </div>
                    </div>

                    <div className="acountRow">
                      <div className="row">
                        <div className="col-lg-3">
                          <div className="title">Personal info  <span className="required">*</span></div>
                        </div>
                        <div className="col-lg-7">
                          <label htmlFor="">Owner name, address</label>
                          {addressInput.data && (
                            <>
                              <p>
                                {profileAddress.FirstName +
                                  " " +
                                  profileAddress.MiddleName +
                                  " " +
                                  profileAddress.LastName}
                              </p>
                              {profileAddress.Address1 && (
                             
                                <p>
                                {profileAddress.Address1}
                                { profileAddress.Address2 && <><br />{profileAddress.Address2}</> } <br/>
                                {`${profileAddress.City}, ${profileAddress.State.StateName}  ${profileAddress.ZipCode}`} <br />
                                {profileAddress.Country.CountryName} <br/>
                              </p>
                              )}{" "}
                            </>
                          )}

                          {addressInput.input && (
                            <PersonalAddress
                              address={newProfileAddress}
                              common={commonResponse}
                              setNewProfileAddress={setNewProfileAddress}
                            />
                          )}
                        </div>

                        <div className="col-lg-2 text-right">
                          {addressInput.editBtn && (
                            <button
                              className="btn edit"
                              onClick={() => showInput("address")}
                            >
                              Edit
                            </button>
                          )}
                          {addressInput.cancelBtn && (
                            <button
                              className="btn edit"
                              onClick={() => handleCancel("address")}
                            >
                              Cancel
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div>
      </main>
      <BuyerFooter/>
    </>
  );
};

export default Addresses;
