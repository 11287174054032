import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../../../components/header/Header";
import { DefaultLayout } from "../../../layout/DefaultLayout";
import { RootState } from "../../../utils/types";
import { ToastContainer } from "react-toastify";
import Footer from "../../../components/footer/Footer";

const ConfirmReturnRequest = () => {

  return (
    <DefaultLayout>
      <Header />
      <ToastContainer />
      <section className="seller-breadcrumb-links">
        <div className="container">
          <ul>
            <li><a href="#">eFindit</a></li>
            <li className="spacer">{'>'}</li>
            <li><a href="#">Return request</a></li>
            <li className="spacer">{'>'}</li>
            <li>Confirm your return address</li>
          </ul>
        </div>
      </section>

      <main className="seller-page-section">
        <div className="container">
            <div className="row">
              <div className="col-md-12">

                <div className="order-detail-heading">
                  <h2 className="seller-head-style">Confirm your return address</h2>
                </div>  
                <div className="order-detail-block">
                  <div className="return-request-head-padd">
                     
                     <div className="refund-checkbox-block">
                        <div className="ch-item seller-ch-item">
                          <p>
                            <input type="radio" id="test1" name="radio-group"/>
                            <label><span>Return Started</span></label>
                          </p>
                        </div>

                        <div className="ch-item seller-ch-item">
                          <p>
                            <input type="radio" id="test2" name="radio-group"/>
                            <label><span>Item delivered</span></label>
                          </p>
                        </div>

                        <div className="ch-item seller-ch-item">
                          <p>
                            <input type="radio" id="test3" name="radio-group"/>
                            <label><span>Refund Sent</span></label>
                          </p>
                        </div>
                     </div>
                  </div>
                  <div className="return-request-padd">
                    <div className="return-request-form-block">
                      <div className="return-title">The buyer requested a return.</div>
                      <div className="return-small-text spacer-confirm-one">Please confirm your return address by Sep7, 2021, so that the buyer can ship the item back to you</div>
                      <div className="return-title">Your return address</div>
                      <div className="return-form-address-row">
                        <select className="form-control input-buyer">
                          <option>123 Somewhere Place Worchestville, ST 4343434</option>
                          <option>Test Category</option>
                          <option>Test Category</option>
                          <option>Test Category</option>
                          <option>Test Category</option>
                        </select>
                        <div className="address-group-btn">
                          <button type="button" className="btn btn-outline-dark">+ Add</button>
                          <button type="button" className="btn btn-outline-dark">Edit</button>
                        </div>
                      </div>

                      <div className="address-border">
                        <button type="button" className="btn btn-primary continue-btn form-button btn-padd-request">Continue</button>
                        <div className="return-small-text spacer-confirm-one">If you prefer you can refund amount,else you can wait till you recieved your item.</div>
                      </div>

                    </div>

                    <div className="order-summary-block">
                        <div className="return-title">Order summary</div>
                        <div className="order-product-block">
                            <div className="order-product-left">
                                <div className="order-product-img"></div>
                                <div className="order-product-text">Apple iPhone 7 - 128GB - Black Unlocked Smartphone</div>
                            </div>   
                            <div className="order-product-right">
                                <div className="order-product-row">
                                      <div className="order-product-col-left">
                                            Quantity
                                      </div>
                                      <div className="order-product-col-right">
                                            19.00
                                      </div>
                                </div>
                                <div className="order-product-row">
                                      <div className="order-product-col-left">
                                            <strong>Sub total</strong>
                                      </div>
                                      <div className="order-product-col-right">
                                            $19.00
                                      </div>
                                </div>
                                <div className="order-product-row border-bottom-0">
                                      <div className="order-product-col-left">
                                            <strong>Estimate refund</strong>
                                      </div>
                                      <div className="order-product-col-right">
                                            $19.00
                                      </div>
                                </div>

                            </div>     
                        </div> 
                    </div>




                  </div>
                </div>

              </div>
            </div>
        </div>
      </main>
    <Footer/>
    </DefaultLayout>
  );
};

export default ConfirmReturnRequest;
