import React from "react";
import { Link } from "react-router-dom";

interface Props {
  setFilter: any;
  filter: any;
  order: any;
  handleFilterChange: any;
  setSearch: any;
  search: string;
  resetFilter: any;
}
const OrderFilter = ({
  setFilter,
  filter,
  order,
  setSearch,
  search,
  handleFilterChange,
  resetFilter,
}: Props) => {

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      // Handle the Enter key press here
      handleFilterChange({
        target: {
          name: "orderId",
        },
      })
      // You can call a function or perform any action
    }
  };
  return (
    <div className="all-orders-filters d-flex">
      <div className="date-filter-group">
        <div className="form-group ">
          <label>Start date</label>
          <input
            type="date"
            className="form-control input-seller"
            // disabled={scheduleIsDisabled}
            name="scheduleDate"
            // min={minDate}
            max={filter.endDate}
            value={filter.startDate}
            onChange={(e) => setFilter({ ...filter, startDate: e.target.value })}
          />
        </div>
        
        <div className="form-group ml-3">
          <label>End date</label>
          <input
            type="date"
            className="form-control input-seller"
            // disabled={scheduleIsDisabled}
            name="scheduleDate"
            min={filter.startDate}
            value={filter.endDate}
            onChange={(e) => setFilter({ ...filter, endDate: e.target.value })}
          />
        </div>
      </div>

      <div className="search-filter-group">
      <div className="form-group ml-3 search-ml-form">
        <label>Search by buyer name</label>
        <select
          className="form-control input-seller"
          name="userId"
          value={filter.userId}
          onChange={(e) => handleFilterChange(e)}
        >
          <option value="">Select</option>
          {order?.buyerNames?.data.map((item: any, Index: number) => (
            <option value={item.userId} key={Index}>{item.name}</option>
          ))}
        </select>
      </div>

      <div className="form-group ">
        <label className="pl-10" style={{ paddingLeft: 10 }}>
          Search by order ID
        </label>
        <div className="d-flex ml-3">
          <div className="d-flex search-bar">
            <input
              type="text"
              placeholder="Search"
              className="form-control"
              value={search}
              onKeyDown={handleKeyPress}
              onChange={(e) => setSearch(e.target.value)}
            />
            <button
              className="search-icon"
              name="orderId"
              onClick={(e) =>
                handleFilterChange({
                  target: {
                    name: "orderId",
                  },
                })
              }
            >
              <span></span>
            </button>
          </div>

          <button className="reload-icon" onClick={() => resetFilter()}>
            <span></span>
          </button>

        </div>
      </div>
      </div>

    </div>
  );
};

export default OrderFilter;
