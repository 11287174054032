import React from 'react'
import { useDispatch } from 'react-redux';
import { getStateByCountryCode } from '../../../../../modules/common/getStateByCountryCode';
import { Common, PersonalAddresses } from '../../../../../utils/types';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { updateProfileAddress } from '../../../../../modules/buyer/myEfindit/account/updateProfileAddress';
import { saveAddress } from '../../../../../modules/sellerStore/saveAddress';



interface Props {
  address: PersonalAddresses;
  common: Common;
  setNewProfileAddress: any;
  isStore?:boolean;
}

const PersonalAddress = ({
  address,
  common,
  setNewProfileAddress,
  isStore
}: Props) => {


  const dispatch = useDispatch();
  const handleAddressChange = (e: any) => {
    setNewProfileAddress({
      ...address,
      [e.target.name]: e.target.value,
    });
  }


  const handleCountryChange = (e: any) => {
    setNewProfileAddress({
      ...address,
      [e.target.name]: e.target.value,
      StateId:0
    });

    let countryCode =
      e.target[e.target.selectedIndex].getAttribute("data-code");
    dispatch<any>(getStateByCountryCode(countryCode));
  };

  const ProfileSchema = Yup.object().shape({
    FirstName: Yup.string()
      .min(2, "Too Short, min 2 letters allowed!")
      .max(50, "Too Long, max 50 letters allowed!").matches(/^[A-Za-z]+$/, "Only alphabets allowed.")
      .required("*Required!"),
    LastName: Yup.string()
      .min(2, "Too Short, min 2 letters allowed!")
      .max(50, "Too Long, max 50 letters allowed!").matches(/^[A-Za-z]+$/, "Only alphabets allowed.")
      .required("*Required!"),
    MiddleName: Yup.string().max(50, "Too Long, max 50 letters allowed!").matches(/^[A-Za-z]+$/, "Only alphabets allowed."),
    City: Yup.string()
      .min(2, "Too Short, min 2 letters allowed!")
      .max(50, "Too Long, max 50 letters allowed!")
      .required("*Required!").nullable(),
    ZipCode: Yup.string()
      .required("*Required!").nullable(),
    Address1: Yup.string()
      .min(5, "Too Short, min 5 letters allowed!")
      .max(100, "Too Long, max 100 letters allowed!")
      .required("*Required!").nullable(),
    Address2: Yup.string()
      .min(5, "Too Short, min 5 letters allowed!")
      .max(100, "Too Long, max 100 letters allowed!").nullable(),
    StateId: Yup.string()
      .required("*Required!").nullable() .test(
        "Is positive?",
        "*Required!",
        (value) =>
          value === undefined ||
          value === null ||
          (Number(value) !=0)
      ),
    CountryId: Yup.string()
      .required("*Required!").nullable() .test(
        "Is positive?",
        "*Required!",
        (value) =>
          value === undefined ||
          value === null ||
          (Number(value) !=0)
      ),


  });

  return (
    <Formik
      initialValues={address}
      validationSchema={ProfileSchema}
      enableReinitialize={true}
      onSubmit={(values) => {
       
        dispatch<any>(updateProfileAddress({
          firstName: values.FirstName,
          middleName: values.MiddleName,
          lastName: values.LastName,
          address1: values.Address1,
          address2: values.Address2,
          city: values.City,
          stateId: values.StateId,
          countryId: values.CountryId,
          zipCode: values.ZipCode,
        }));
      }}
    >
      {({ errors, touched }) => (
        <>
          <Form>
            <div className="signForm">
              <div className="row formRow">
                <div className="col-md-4">
                  <label htmlFor="">First Name <span className="required">*</span></label>
                  <input type="text" value={address.FirstName} onChange={handleAddressChange} name="FirstName" className="formControl" />
                  {errors.FirstName && touched.FirstName ? (
                    <div className="required">
                      {errors.FirstName}
                    </div>
                  ) : null}
                </div>
                <div className="col-md-4">
                  <label htmlFor="">Middle Name</label>
                  <input type="text" value={address.MiddleName} onChange={handleAddressChange} name="MiddleName" className="formControl" />
                  {errors.MiddleName && touched.MiddleName ? (
                    <div className="required">
                      {errors.MiddleName}
                    </div>
                  ) : null}
                </div>
                <div className="col-md-4">
                  <label htmlFor="">Last Name <span className="required">*</span></label>
                  <input type="text" value={address.LastName} onChange={handleAddressChange} name="LastName" className="formControl" />
                  {errors.LastName && touched.LastName ? (
                    <div className="required">
                      {errors.LastName}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="row formRow">
                <div className="col-md-12">
                  <label htmlFor="">Street Address <span className="required">*</span></label>
                  <input type="text" value={address.Address1} onChange={handleAddressChange} name="Address1" className="formControl" />
                  {errors.Address1 && touched.Address1 ? (
                    <div className="required">
                      {errors.Address1}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="row formRow">
                <div className="col-md-12">
                  <label htmlFor="">Street Address 2 (Optional)</label>
                  <input type="text" value={address.Address2} onChange={handleAddressChange} name="Address2" className="formControl" />
                  {errors.Address2 && touched.Address2 ? (
                    <div className="required">
                      {errors.Address2}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="row formRow">
              
                <div className="col-md-6">
                  <label htmlFor="">Country <span className="required">*</span></label>
                  <div className="cutomSelect">
                    <select name="CountryId" value={
                      address.CountryId === null
                        ? ""
                        : address.CountryId
                    }
                      onChange={handleCountryChange}>
                      <option value="">Choose country</option>
                      {common.countries?.value.map(
                        (item, Index) =>
                          item.Active === "Y" && (
                            <option
                              key={Index}
                              value={item.Id}
                              data-code={item.CountryCode}
                            >
                              {item.CountryName}
                            </option>
                          )
                      )}
                    </select>
                    {errors.CountryId && touched.CountryId ? (
                      <div className="required">
                        {errors.CountryId}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="">State <span className="required">*</span></label>
                  <div className="cutomSelect">
                    <select name="StateId"
                      value={
                        address.StateId === null
                          ? ""
                          : address.StateId
                      }
                      onChange={handleAddressChange}>
                      <option value="">Choose state <span className="required">*</span></option>
                      {common.state?.value.map(
                        (item, Index) =>
                          item.Active === "Y" && (
                            <option key={Index} value={item.Id}>
                              {item.StateName}
                            </option>
                          )
                      )}
                    </select>
                    {errors.StateId && touched.StateId ? (
                      <div className="required">
                        {errors.StateId}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row formRow">
              <div className="col-md-6">
                  <label htmlFor="">City <span className="required">*</span></label>
                  <input type="text" value={address.City} onChange={handleAddressChange} name="City" className="formControl" />
                  {errors.City && touched.City ? (
                    <div className="required">
                      {errors.City}
                    </div>
                  ) : null}
                </div>
               
                <div className="col-md-6">
                  <label htmlFor="">Zip code <span className="required">*</span></label>
                  <input type="text" value={address.ZipCode} onChange={handleAddressChange} name="ZipCode" className="formControl" />
                  {errors.ZipCode && touched.ZipCode ? (
                    <div className="required">
                      {errors.ZipCode}
                    </div>
                  ) : null}
                </div>
              </div>


            </div>
            <div className="pt-3">
              <button className="save" type="submit">Save</button>
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default PersonalAddress
