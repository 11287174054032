import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../../components/header/Header";
import { DefaultLayout } from "../../../layout/DefaultLayout";
import { dateToLocal } from "../../../modules/date/date";
import { RootState } from "../../../utils/types";
import ReactPaginate from "react-paginate";
import { getCommonData } from "../../../modules/common/getCommonData";
import { CommonResponseSelector } from "../../../modules/common/selectors";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SellerProductResponseSelector } from "../../../modules/sellerProduct/selector";
import { CategoryResponseSelector } from "../../../modules/categories/selector";
import { getSellerCategories } from "../../../modules/categories/getSellerCategories";
import Footer from "../../../components/footer/Footer";
import HeaderNavigationSeller from "../HeaderNavigationSeller";
import { getPaymentsSeller } from "../../../modules/sellerProduct/getPaymentsSeller";
import PaymentFilter from "./PaymentFilter";
import { getPaymentsSellerReport } from "../../../modules/sellerProduct/getPaymentsSellerReport";
import { CSVLink } from "react-csv";
import { reset } from "../../../modules/sellerProduct/reset";
import { constant } from "../../../utils/constant";

const PaymentSeller = () => {
  const product = useSelector<RootState, RootState["sellerProduct"]>(
    SellerProductResponseSelector
  );
  const commonResponse = useSelector<RootState, RootState["common"]>(
    CommonResponseSelector
  );

  const navigate = useNavigate();
  const csvLink = useRef<any>()
  const [reportData, setReportData] = useState([] as any);
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(10);
  const [currentPage, setcurrentPage] = useState(0);
  const [filter, setFilter] = useState({
    Days: "",
    Search: "",
    Format: "",
   
  });
  const isFirstRender = useRef(true);
  const isFirstRender2 = useRef(true);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  if (product?.payments?.data?.payments !== null && pageCount === 0) {
    setPageCount(Math.ceil(product?.payments?.data?.totalResult / itemCount));
  }
  useEffect(() => {
    if (isFirstRender.current) {
      dispatch<any>(
        getPaymentsSeller({
          currentPage: currentPage + 1,
          rows: itemCount,
          Days: filter.Days,
          Search: filter.Search,
          Format: filter.Format,
         
        })
      ).then(() => {
        if (currentPage === 0) setPageCount(0);
      });
    }
    isFirstRender.current = false;

    if (commonResponse?.common === null) dispatch<any>(getCommonData());
    dispatch<any>(getSellerCategories());
  }, []);

  useEffect(() => {
    if (!isFirstRender2.current) {
      dispatch<any>(
        getPaymentsSeller({
          currentPage: currentPage + 1,
          rows: itemCount,
          Days: filter.Days,
          Search: filter.Search,
          Format: filter.Format,
          
        })
      ).then(() => {
        if (currentPage === 0) setPageCount(0);
      });
    }
    isFirstRender2.current = false;
  }, [currentPage, itemCount, filter]);

  
  const handlePageChange = (selectedObject: any) => {
    setcurrentPage(selectedObject.selected);
  };

  const handleItemsChange = (event: any) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };
  const handleFilterChange = (event: any) => {
    setcurrentPage(0);
    if (event.target.name === "Search")
      setFilter({ ...filter, Search: search });
    else setFilter({ ...filter, [event.target.name]: event.target.value });
  };
  const resetFilter = () => {
    setcurrentPage(0);
    setFilter({
      Days: "",
      Search: "",
      Format: "",
     
    });
    setSearch("");
  };
  useEffect(() => {
    if (product?.paymentsReport) {
      let userData: any = [];
      product?.paymentsReport?.data?.payments?.map((payment:any) => {
          let datalist: any = {};
       
      
          datalist["orderId"] = payment?.orderId;
          datalist["orderDate"] = dateToLocal(payment?.orderDate);
          datalist["platFormFee"] = payment?.platFormFee;
          datalist["refundAmount"] = payment?.refundAmount;
          datalist["returnId"] = payment?.returnId;
          datalist["sellerReceivableAmount"] = payment?.sellerReceivableAmount;
          datalist["status"] = payment?.status;
          datalist["totalAmount"] = payment?.totalAmount;
          datalist["transactionFee"] = payment?.transactionFee;
       
          userData.push(datalist);
        });
      setReportData(userData);
    } 
  }, [product?.paymentsReport]);


useEffect(()=>{

if(reportData.length>0)
{
csvLink.current.link.click()}
},[reportData])

  return (
    <DefaultLayout>
      <Header />
      <ToastContainer />

    
      <section className="seller-breadcrumb-block">
        <div className="container">
          <ul>
            <li className="text">Listing</li>
            <li className="pipe">|</li>
            <li className="text-link">
              <a href="#">{localStorage.getItem("UserName")}</a>
            </li>
          </ul>
        </div>
      </section>

     <HeaderNavigationSeller
     currentPage="Payment"
     />

   
      <main className="seller-page-section">
        <div className="container seller-orders-section">
          <div className="row">
          <div className="col-md-2">
      <div className="leftNavBar">
        <ul>
          <li>
            <Link
              style={
              { background: "#d7d7d7", fontWeight: "450", color: "#000" }
                 
              }
              to={"/seller/payments"}
            >
              Payouts
            </Link>
          </li>
          
          
        </ul>

     
      </div>
    </div>
            <div className="col-md-10">
        
              <PaymentFilter
                filter={filter}
                common={commonResponse}
                handleFilterChange={handleFilterChange}
                setSearch={setSearch}
                search={search}
                resetFilter={resetFilter}
              />
              <div className="filter-result mt-3">
                <div className="total-result d-flex justify-content-between mb-4">
                  <h5>Results: {product?.payments?.data?.totalResult ? product?.payments?.data?.totalResult :0}</h5>
            { product?.payments?.data?.totalResult &&     <ul className="d-flex">
                  <li>
                    <span style={{cursor:"pointer", color:"blue"}}  onClick={()=>
                          navigate("/seller/printPayments", {
                            state: {
                              currentPage: 1,
                              rows:product?.payments?.data?.totalResult ,
                              Search: filter.Search,
                              Days: filter.Days,
                              Format: filter.Format,},
                          })
                         } >Print</span>
                    </li>
                    <li className="pipe">|</li>
                    <li>
                    <span style={{cursor:"pointer", color:"blue"}} onClick={()=>dispatch<any>(
      getPaymentsSellerReport({
        currentPage: 1,
        rows:product?.payments?.data?.totalResult ,
        Search: filter.Search,
        Days: filter.Days,
        Format: filter.Format,
      }))} >Download reports</span>
      <CSVLink
         data={reportData}
         filename='report.csv'
         className='hidden'
         onClick={() =>
          dispatch<any>(reset("report"))
            .then({
              done() {},
            })
        }
         headers={constant?.PAYMENT_LISTING}
         ref={csvLink}
         target='_blank'
      />
                    </li>
                  </ul>}
                </div>

                <div className="result-table-wrapper">
                  <table className="result-table">
                    <thead>
                      <tr>
                        <th className="">Order Id</th>
                        <th className="">Order date</th>

                        <th className="">Platform fee($)</th>

                        <th>Seller receivable amount($)</th>
                        <th>Transaction fee($)</th>
                        <th>Total amount($)</th>
                        <th className="">Return Id</th>
                        <th>Refund amount($)</th>

                        <th>Order status</th>
                      </tr>
                    </thead>
                    <tbody>
                      { product?.payments && product?.payments?.data?.payments!==null ? 
                    (  product?.payments?.data?.payments.map(
                        (payment, Index: number) => (
                          <tr key={Index}>
                            
                            <td
                              
                            >
                              {payment.orderId}
                            </td>
                            <td className="action">
                          {dateToLocal(payment.orderDate)}
                            </td>
                          
                            <td className="">{payment.platFormFee}</td>

                         
                            <td className="">
                             
                              {payment.sellerReceivableAmount}
                             
                            </td>

                            <td className="">{payment.transactionFee}</td>
                            <td className="">   {payment.totalAmount}</td>
                            <td
                              
                              >
                                {payment.returnId}
                              </td>
                            <td className="">
                            {payment.refundAmount}
                            </td>
                            <td className="">{payment.status}</td>
                           
                          </tr>
                        )
                      )) :
                      <h2 className="p-3 m-2">No records found</h2>
                      }
                    </tbody>
                  </table>
                </div>

                <nav className="pagination-block ">
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    forcePage={currentPage}
                    onPageChange={handlePageChange}
                    containerClassName={
                      "pagination justify-content-center mt-4"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />

                  <div className="total-items-block">
                    <label>Items per page:</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={handleItemsChange}
                      value={itemCount}
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </DefaultLayout>
  );
};

export default PaymentSeller;
