import { AppThunk } from "../../../store";
import { SaveSearchFailed, SaveSearchSuccess } from "./slice";
import { fetchSaveSearch, postRecentlyViewed, postToSaveSearch } from "./api";
import { StartProgress } from "../../progressBar/startProgress";
import { EndProgress } from "../../progressBar/endProgress";

export const getSavedSearch =
  (params:any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());

    try {
      const data: any = await fetchSaveSearch(params);
      

      if (data.success === "N") {
        
        dispatch(SaveSearchFailed(data));
      } else {
        
        dispatch(SaveSearchSuccess(data));
      }
    } catch (err) {
      
      dispatch(SaveSearchFailed(err));
    }
    dispatch<any>(EndProgress());

  };
