import { AppThunk } from "../../../../store"
import { resetMessage } from "./slice";

export const reset = (): AppThunk => async (dispatch) => {
  try {
    dispatch(resetMessage());
  } catch (err) {
    
  }
};
