import Api from "../../utils/api";
import { API_URLS } from "../../utils/api.urls";
const d = new Date();
let TimeZone = d.getTimezoneOffset() * -1;
export const fetchProductDetails = async (id: any | null): Promise<any> => {
  const data = await Api.get(
    API_URLS.SELLER_PRODUCT +
      "/" +
      id +
      "?$expand=Images&$expand=Variants($filter=active eq 'Y')&$expand=Taggings",
    []
  );
  return data;
};
export const fetchCourierProviderDetails = async (id: any | null): Promise<any> => {
  const data = await Api.get(
    API_URLS.COURIER +
      "/GetCourierProviderDetails?CourierProviderType=" +
      id ,
    []
  );
  return data;
};
export const fetchCourierServiceDetails = async (params:any): Promise<any> => {

   let returnId= params ? "?ReturnId="+params :"";

  const data = await Api.get(
    API_URLS.COURIER +
      "/GetCourierServiceDetails"+returnId ,
    []
  );
  return data;
};
export const postListingDetails = async (params: any | null): Promise<any> => {
  const data = await Api.post(API_URLS.SELLER_PRODUCT + "/Listing", params);
  return data;
};

export const fetchAttributes = async (): Promise<any> => {
  const data = await Api.get(API_URLS.GET_ATTRIBUTES, []);
  return data;
};

export const fetchPlatformFees = async (): Promise<any> => {
  const data = await Api.get(API_URLS.PLATFORM_FEES, []);
  return data;
};

export const fetchAttributeOption = async (
  params: any | null
): Promise<any> => {
  const data = await Api.get(API_URLS.GET_ATTRIBUTES + "/" + params, []);
  return data;
};

export const postAttributeImage = async (params: any | null): Promise<any> => {
  const data = await Api.post(
    API_URLS.SELLER_PRODUCT + "/ProductVariantImage",
    params
  );
  return data;
};

export const postChangeStatus = async (params: any | null): Promise<any> => {
  const data = await Api.patch(API_URLS.SELLER_PRODUCT + "/Deactivate", params);
  return data;
};

export const deleteImage = async (params: any | null): Promise<any> => {
  const data = await Api.delete(
    API_URLS.SELLER_PRODUCT +
      "/DeleteProductImage/" +
      params.id +
      "/" +
      params.productId,
    []
  );
  return data;
};
export const deleteVariantImage = async (params: any | null): Promise<any> => {
  const data = await Api.delete(
    `${API_URLS.SELLER_PRODUCT}/deleteproductvaraintimage/${params.id}/${params.productId}/ ${params.temporary}`,
    []
  );
  return data;
};
export const fetchActiveListing = async (params: any | null): Promise<any> => {
  const data = await Api.get(
    API_URLS.SELLER_PRODUCT +
      "/ActiveListing" +
      "/?PageNumber=" +
      params.currentPage +
      "&TotalRows=" +
      params.rows +
      "&CategoryId=" +
      params.CategoryId +
      "&Format=" +
      params.Format +
      "&Search=" +
      params.Search +
      "&StartDate=" +
      params.StartDate +
      "&EndDate=" +
      params.EndDate +
      "&TimeZone=" +
      TimeZone,
    []
  );
  return data;
};

export const fetchOffersSeller = async (params: any | null): Promise<any> => {
  const data = await Api.get(
    API_URLS.SELLER_OFFERS +
     
      "?PageNumber=" +
      params.currentPage +
      "&TotalRows=" +
      params.rows
       +
      "&CategoryId=" +
      params.CategoryId +
      "&Format=" +
      params.Format +
      "&Search=" +
      params.Search +
      "&StartDate=" +
      params.StartDate +
      "&EndDate=" +
      params.EndDate +
      "&TimeZone=" +
      TimeZone,
    []
  );
  return data;
};
export const deleteDealSeller= async (params: any | null): Promise<any> => {
  const data = await Api.delete(
    `${API_URLS.SAVE_NEW_DEAL_SELLER}/DeleteSingleDealProduct/${params}`,
    []
  );
  return data;
};
export const fetchDealsSeller = async (params: any | null): Promise<any> => {
let filter="", filter2="", filter3="";
  if(params.dealId===undefined && params.CategoryId=="" && params.Search==="")
  filter=""
  else
  {

 filter=params.dealId ? ("&$Filter=DealId eq "+params.dealId ): "";
 filter2=   params.CategoryId!==""? (`${params.dealId ? "and " :"&$Filter="}CategoryId eq '` +params.CategoryId +"'"): "";
  }
//  filter=filter+ ( params.CategoryId)? `${params.dealId ? "and " :""}CategoryId eq` +params.CategoryId : "";
//  filter=filter+ ( params.Search)? `${(params.dealId || params.CategoryId) ? "and " :""}contains(Title,'"`+params.Search+"" : "";


// "&$filter=CategoryId eq '" +
// params.filter.category +
// "'and contains(Title,'" +
// params.filter.search +
  const data = await Api.get(
    API_URLS.SAVE_NEW_DEAL_SELLER +
     
    "?$skip=" +
    params.currentPage +
    "&$top=" +
    params.rows + filter+filter2
       +
      "&$count=true&$select=Id,CategoryId,ProductId,DealId,StartOn,ExpiresOn,SalesDiscount,IsShippingFree,IsOfferEnded&$expand=Product($select=Title,ActualPrice,DefaultImage)&$orderby=Id desc"
    ,[]
  );
  return data;
};

export const fetchBidsSeller = async (params: any | null): Promise<any> => {
  const data = await Api.get(
    API_URLS.SELLER_BIDS +
     
      "?PageNumber=" +
      params.currentPage +
      "&TotalRows=" +
      params.rows
       +
      "&CategoryId=" +
      params.CategoryId +
      "&Format=" +
      params.Format +
      "&Search=" +
      params.Search +
      "&StartDate=" +
      params.StartDate +
      "&EndDate=" +
      params.EndDate +
      "&TimeZone=" +
      TimeZone,
    []
  );
  return data;
};
export const fetchPaymentsSeller = async (params: any | null): Promise<any> => {
  const data = await Api.get(
    API_URLS.SELLER_PAYMENTS +
     
      "?PageNumber=" +
      params.currentPage +
      "&TotalRows=" +
      params.rows
       +
      "&Days=" +
      params.Days +
      "&RefundType=" +
      params.Format +
      "&SearchId=" +
      params.Search +
      "&StartDate=" +
      params.StartDate +
      "&EndDate=" +
      params.EndDate +
      "&TimeZone=" +
      TimeZone,
    []
  );
  return data;
};
export const fetchSellerOfferUserList = async (params: any | null): Promise<any> => {
  const data = await Api.get(
    API_URLS.SELLER_OFFERS_USER_LIST +
     
      "?PageNumber=" +
      params.currentPage +
      "&TotalRows=" +
      params.rows
      
       +
      "&TimeZone=" +
      TimeZone  +
      "&ProductId=" +
      params.productId ,
    []
  );
  return data;
};

export const fetchSellerBidUserList = async (params: any | null): Promise<any> => {
  const data = await Api.get(
    API_URLS.SELLER_BIDS_USER_LIST +
     
      "?PageNumber=" +
      params.currentPage +
      "&TotalRows=" +
      params.rows
      
       +
      "&TimeZone=" +
      TimeZone  +
      "&ProductId=" +
      params.productId ,
    []
  );
  return data;
};
export const fetchDealsNameSeller = async (): Promise<any> => {
  const data = await Api.get(
    API_URLS.DEALS_ADDED_BY_SELLER ,
    []
  );
  return data;
};
export const postProductToDeal= async (params:any): Promise<any> => {
  const data = await Api.post(
    API_URLS.SAVE_NEW_DEAL_SELLER ,
    params
  );
  return data;
};
export const fetchProductsByCategory = async (params:any): Promise<any> => {
  const data = await Api.get(
    API_URLS.SELLER_PRODUCTS_BY_CATEGORY + "/"+params,
    []
  );
  return data;
};

export const fetchUnsoldListing = async (params: any | null): Promise<any> => {
  const data = await Api.get(
    API_URLS.SELLER_PRODUCT +
      "/UnsoldListing" +
      "/?PageNumber=" +
      params.currentPage +
      "&TotalRows=" +
      params.rows +
      "&CategoryId=" +
      params.CategoryId +
      "&Format=" +
      params.Format +
      "&Search=" +
      params.Search +
      "&StartDate=" +
      params.StartDate +
      "&EndDate=" +
      params.EndDate +
      "&TimeZone=" +
      TimeZone,
    []
  );
  return data;
};

export const fetchSoldListing = async (params: any | null): Promise<any> => {
  const data = await Api.get(
    API_URLS.SELLER_PRODUCT +
      "/soldlisting" +
      "/?PageNumber=" +
      params.currentPage +
      "&TotalRows=" +
      params.rows +
      "&CategoryId=" +
      params.CategoryId +
      "&Format=" +
      params.Format +
      "&Search=" +
      params.Search +
      "&StartDate=" +
      params.StartDate +
      "&EndDate=" +
      params.EndDate +
      "&TimeZone=" +
      TimeZone,
    []
  );
  return data;
};

export const fetchDraftListing = async (params: any | null): Promise<any> => {
  const data = await Api.get(
    API_URLS.SELLER_PRODUCT +
      "/DraftListing" +
      "/?PageNumber=" +
      params.currentPage +
      "&TotalRows=" +
      params.rows +
      "&CategoryId=" +
      params.CategoryId +
      "&Format=" +
      params.Format +
      "&Search=" +
      params.Search +
      "&StartDate=" +
      params.StartDate +
      "&EndDate=" +
      params.EndDate +
      "&TimeZone=" +
      TimeZone,
    []
  );
  return data;
};

export const fetchEndedListing = async (params: any | null): Promise<any> => {
  const data = await Api.get(
    API_URLS.SELLER_PRODUCT +
      "/EndedListing" +
      "/?PageNumber=" +
      params.currentPage +
      "&TotalRows=" +
      params.rows +
      "&CategoryId=" +
      params.CategoryId +
      "&Format=" +
      params.Format +
      "&Search=" +
      params.Search +
      "&StartDate=" +
      params.StartDate +
      "&EndDate=" +
      params.EndDate +
      "&TimeZone=" +
      TimeZone,
    []
  );
  return data;
};

export const fetchScheduledListing = async (
  params: any | null
): Promise<any> => {
  const data = await Api.get(
    API_URLS.SELLER_PRODUCT +
      "/ScheduledListing" +
      "/?PageNumber=" +
      params.currentPage +
      "&TotalRows=" +
      params.rows +
      "&CategoryId=" +
      params.CategoryId +
      "&Format=" +
      params.Format +
      "&Search=" +
      params.Search +
      "&StartDate=" +
      params.StartDate +
      "&EndDate=" +
      params.EndDate +
      "&TimeZone=" +
      TimeZone,
    []
  );
  return data;
};

export const postSellingDetails = async (params: any | null): Promise<any> => {
  const data = await Api.post(
    API_URLS.SELLER_PRODUCT + "/SellingDetails",
    params
  );
  return data;
};
export const postAcceptRejectOffer = async (params: any | null): Promise<any> => {
  const data = await Api.post(
    API_URLS.ACCEPT_REJECT_OFFER ,
    params
  );
  return data;
};
export const postAcceptRejectBid = async (params: any | null): Promise<any> => {
  const data = await Api.post(
    API_URLS.ACCEPT_REJECT_BID ,
    params
  );
  return data;
};
export const delVariant = async (params: any | null): Promise<any> => {
  const data = await Api.delete(API_URLS.DELETE_VARIANT + params, []);
  return data;
};
export const postShippingDetails = async (params: any | null): Promise<any> => {
  const data = await Api.post(
    API_URLS.SELLER_PRODUCT + "/ShippingDetails",
    params
  );
  return data;
};

export const postSellFaster = async (params: any | null): Promise<any> => {
  const data = await Api.post(
    API_URLS.SELLER_PRODUCT + "/VolumePricing",
    params
  );
  return data;
};
