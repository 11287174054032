import React from 'react'
import { Link } from 'react-router-dom'

interface props {
    currentPage:string;
}

const HeaderNavigationSeller = ({currentPage}:props) => {
  return (
    <section className="seller-tab-section">
        <div className="container">
          <ul>
            <li>
              <Link className={`${currentPage=="Overview" ? "active": ""}`}  to={"/seller/overview"}>Overview</Link>
            </li>
            <li>
              <Link className={`${currentPage=="Order" ? "active": ""}`} to={"/seller/orders"}>Order</Link>
            </li>
            <li>
              <Link  className={`${currentPage=="Offers" ? "active": ""}`} to={"/seller/offers"}>Offers</Link>
            </li>
            <li>
              <Link  className={`${currentPage=="Bids" ? "active": ""}`} to={"/seller/bids"}>Bids</Link>
            </li>
            <li>
              <Link className={`${currentPage=="Listing" ? "active": ""}`} to={"/seller/listing"}>
                Listing
              </Link>
            </li>
            <li>
              <Link className={`${currentPage=="Deals" ? "active": ""}`} to={"/seller/deals"}>
                Deals
              </Link>
            </li>
            <li>
              <Link className={`${currentPage=="Account" ? "active": ""}`} to={"/buyer/myaccount"}>
                Account
              </Link>
            </li>
            <li>
            <Link className={`${currentPage=="Payment" ? "active": ""}`} to={"/seller/payments"}>
            Payment
              </Link>
            </li>
            
            
          </ul>
        </div>
      </section>
  )
}

export default HeaderNavigationSeller