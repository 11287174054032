import { AppThunk } from "../../store"

import {
    UpdatePasswordSuccess,
    UpdatePasswordFailed
} from "./slice"
import { changePasswordApi } from "./api";
import { EndProgress } from "../progressBar/endProgress";
import { StartProgress } from "../progressBar/startProgress";



export const changePassword = (data: any): AppThunk => async (dispatch) => {

    dispatch<any>(StartProgress());
    try {

        const updateData: any = await changePasswordApi(data)

        if (updateData.success === "N") {
            
            dispatch(UpdatePasswordFailed(updateData));
        }
        else {
           
            dispatch(UpdatePasswordSuccess(updateData));
        }

    } catch (err) {
        
        dispatch(UpdatePasswordFailed(err));
    }
    dispatch<any>(EndProgress());
}