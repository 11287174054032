import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { saveOffer } from "../../../modules/buyer/product/saveOffer";
import { orderCancelled } from "../../../modules/orders/orderCancelled";

const MakeOfferModel = ({
  makeOffer,
  setMakeOffer,
  product,
  quantityDropdown
  
}: any) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);


const isNumeric=(value:any)=> {
  return /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/.test(value);
}
  return (
    <div
      className="modal modal-order-popup"
      id="myModal"
      tabIndex={-1}
      role="dialog"
      style={{ display: makeOffer.visible }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="order-modal">
            <div className="order-modal-head border-none">Make an offer</div>
           
            <div className="form-group spacer-feedback-select">
            <h4>  Buying Price : ${product?.buyNowPrice ? product?.buyNowPrice :product?.actualPrice}</h4>
              </div>
              <div className="form-group spacer-feedback-select">
            <h4>Quantity</h4>
            <select
                name="quantity"
                id="quantity"
                value={makeOffer.Quantity}
                onChange={(e) => setMakeOffer({...makeOffer,Quantity: Number(e.target.value)})}
            >
                {/* <option  value={0}>
                        Select quantity
                    </option> */}
                {quantityDropdown?.map((qty:number, Index:number) => (
                    <option key={qty} value={qty}>
                        {" "}
                        {qty}
                    </option>
                ))}
            </select>
  { quantityDropdown.length==0 &&        <p className="required">No quanity available</p>}
            </div>
            <div className="form-group spacer-feedback-select">
              <label className="label-modal">
                Your offer per item ($)
              </label>
            <input value={makeOffer.offerAmount  } onChange={(e)=>setMakeOffer({...makeOffer, offerAmount:e.target.value})} className="form-control input-modal" type="text"/>
            </div>
           {!isNumeric(makeOffer.offerAmount) && makeOffer.offerAmount!=="" ? <p className="required">You've entered an invalid price</p>
           : isNumeric(makeOffer.offerAmount) && makeOffer.offerAmount!=="" && (product?.buyNowPrice ? product?.buyNowPrice < Number(makeOffer.offerAmount) :product?.actualPrice  < Number(makeOffer.offerAmount)) && <p className="required">Your offer is higher than the Buying Price.</p>}
           {makeOffer?.offerAmount ==="" && <p className="required">*Required!</p>}
            <div className="form-group spacer-feedback-label">
              <label className="label-modal">Message (optional)</label>
              <textarea
                className="form-control buyer-textarea input-modal"
                rows={3}
                maxLength={500}
                placeholder="write some thing"
                value={
                  makeOffer.message !== null
                    ? makeOffer.message
                    : ""
                }
                onChange={(e) =>
                  setMakeOffer({
                    ...makeOffer,
                    message: e.target.value !== "" ? e.target.value : null,
                  })
                }
              ></textarea>
              <p>
                {makeOffer.message === null
                  ? 0
                  : makeOffer?.message?.length}
                /500
              </p>
            </div>

            <div className="modal-btn-group spacer-feedback-btn">
              <button
                className={`${ makeOffer.offerAmount === "" || !isNumeric(makeOffer.offerAmount) ||  quantityDropdown.length==0 
                || 
                
                 ( product?.buyNowPrice ? product?.buyNowPrice < Number(makeOffer.offerAmount) :product?.actualPrice  < Number(makeOffer.offerAmount) )

                ? "modal-cancel-btn" : "modal-add-btn "}  `}
                onClick={() => {
                  dispatch<any>(saveOffer(makeOffer));
                  setMakeOffer({ ...makeOffer, visible: "none" });
                }}
                disabled={
                 
                  makeOffer.offerAmount === "" || !isNumeric(makeOffer.offerAmount) ||  quantityDropdown.length==0 ||

                  (product?.buyNowPrice ? product?.buyNowPrice < Number(makeOffer.offerAmount) :product?.actualPrice  < Number(makeOffer.offerAmount))
                    ? true
                    : false
                }
              >
                Continue
              </button>
              <button
                className="modal-cancel-btn ml-3"
                onClick={() =>
                  setMakeOffer({
                    ...makeOffer,
                    visible: "none",
                    offerAmount: "",
                    message: null,
                  })
                }
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MakeOfferModel;
