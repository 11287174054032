import { AppThunk } from "../../../store";
import { reset } from "./slice";

export const resetSave = (): AppThunk => async (dispatch) => {
  try {
    dispatch(reset());
  } catch (err) {
    
  }
};
