export const constant = {
  SAVE_DATA: "saveData",
  SUCCESS: "success",
  ERROR: "error",
  DUPLICATE_ERROR: "duplicateError",

  NAME_ERROR: "Name should not be empty",
  VARIANT_NAME_ERROR: "Variant Name cannot not be empty",
  REACT_APP_GOOGLE_MAP_API_KEY: "AIzaSyBe41ate7ci6RGSMbjw1RLViMifLit_Bzc",

  NAME_AND_LOGO_ERROR: "Name and Logo should not be empty",
  NAME_AND_IMAGE_ERROR: "Name and Image should not be empty",
  CATEGORY_AND_TEMPLATE_ERROR:
    "Category and Template fields should not be empty",
  NAME_AND_CONTENT_ERROR: "Name or Content should not be empty",
  NAME_DESKTOP_AND_MOBILE_ERROR:
    "Name, Desktop banner and Mobile banner should not be empty",
  IMAGE_FORMAT_ERROR: "Image format is not Supported",
  BANNER_DESKTOP_ERROR:
    "Image size should be max 2 mb, width and height should be minimum 1564 px & 328 px",
  BANNER_MOBILE_ERROR:
    "Image size should be max 2 mb, width and height should be minimum 875 px & 328 px",
  CATEGORY_IMAGE_ERROR:
    "Image size should be max 2 mb, width and height should be minimum 271 & 269 px",

  MESSAGE_ERROR: "Message length should be in the range 1-500",
  OFFER_ACTIVE: "Are you sure to activate?",
  OFFER_INACTIVE: "Are you sure to deactivate?",
  PASSWORD_EMPTY: "Password is empty",
  PASSWORD_UPPERCASE: "At least one Uppercase",
  PASSWORD_LOWERCASE: "At least one Lowercase",
  PASSWORD_DIGIT: "At least one digit",
  PASSWORD_SPECIAL_CHARACTER: "At least one Special Characters",
  PASSWORD_CHARACTER_LIMIT: "At least minumum 8 characters",
  PASSWORD_ENTER: "Please enter your current password",
  PASSWORD_VALID: "Please enter your valid new password",
  PASSWORD_CONFIRM: "Please confirm your new password",
  PASSWORD_NOT_MATCHED: "Confirm password is not matched",
  BANNER_DESKTOP_HEIGHT: 328,
  BANNER_DESKTP_WIDTH: 1564,
  BANNER_MOBILE_HEIGHT: 328,
  BANNER_MOBILE_WIDTH: 875,
  CATEGORY_HEIGHT: 269,
  CATEGORY_WIDTH: 271,
  IMAGE_SIZE: 2000000,
  ALMOST_GONE_QUANTITY: 10,
  ONLY_FEW_LEFT: 5,
  GOOGLE_CLIENT_ID:"706313954154-ctlqdfibt2aub10k4v85fkc5dbs120t1.apps.googleusercontent.com",
  Buyer_COLUMNS: [
    {
      key: "firstName",
      label: "First Name",
    },
    {
      key: "lastName",
      label: "Last name",
    },
    {
      key: "type",
      label: "Type",
    },
    {
      key: "registrationDate",
      label: "Registration Date",
    },
    {
      key: "signIn",
      label: "Last Login",
    },
    {
      key: "active",
      label: "Status",
    },
  ],
  SELLER_COLUMNS: [
    {
      key: "firstName",
      label: "First Name",
    },
    {
      key: "lastName",
      label: "Last name",
    },
    {
      key: "storeName",
      label: "Store Name",
    },
    {
      key: "registrationDate",
      label: "Registration Date",
    },
    {
      key: "timeSinceLastSale",
      label: "Last Login",
    },
    {
      key: "productCount",
      label: "Product Count",
    },
    {
      key: "orderCount",
      label: "Order Count",
    },
    {
      key: "acceptedOrderRatio",
      label: "Accepted Order Ratio",
    },
    {
      key: "returnSaleRatio",
      label: "Return Sale Ratio",
    },
    {
      key: "ratings",
      label: "Ratings ",
    },
  ],
  ORDER_COLUMNS: [
    {
      key: "orderId",
      label: "Order Id",
    },
    {
      key: "buyerFirstName",
      label: "Buyer name",
    },
    {
      key: "sellerFirstName",
      label: "Seller name",
    },
    {
      key: "orderDate",
      label: "Order date",
    },
    {
      key: "purchasingPrice",
      label: "Purchase Price",
    },
    {
      key: "paymentMode",
      label: "Payment Mode",
    },
    {
      key: "quantity",
      label: "Quantity ",
    },
    {
      key: "deliveryType",
      label: "Delivery Type ",
    },
    {
      key: "deliveryDate",
      label: "Delivery date ",
    },
  ],
  RETURN_ORDER_COLUMNS: [
    {
      key: "orderId",
      label: "Order Id",
    },
    {
      key: "buyerFirstName",
      label: "Buyer name",
    },
    {
      key: "sellerFirstName",
      label: "Seller name",
    },
    {
      key: "returnId",
      label: "Return Id ",
    },
    {
      key: "returningReason",
      label: "Returning Reason ",
    },
    {
      key: "requestedDate",
      label: "Requested Date",
    },
    {
      key: "completedDate",
      label: "Completed Date ",
    },
    {
      key: "amountRefunded",
      label: "Amount refunded ",
    },
  ],
  PRODUCT_COLUMNS: [
    {
      key: "title",
      label: "Product name",
    },
    {
      key: "brand",
      label: "Brand",
    },
    {
      key: "model",
      label: "Model",
    },
    {
      key: "categoryName",
      label: "Category Name",
    },

    {
      key: "active",
      label: "Status",
    },
  ],
  SELLER_ALL_ORDERS_COLUMNS: [
    {
      key: "orderId",
      label: "Order Id",
    },
    {
      key: "buyerName",
      label: "Buyer name",
    },
    {
      key: "productName",
      label: "Product name",
    },
    {
      key: "condition",
      label: "Condition",
    },
    {
      key: "quantity",
      label: "Quantity",
    },

    {
      key: "stockAvailable",
      label: "Available Stock",
    },
    {
      key: "soldFor",
      label: "Sold for",
    },
    {
      key: "total",
      label: "Total",
    },
    {
      key: "trackingNo",
      label: "Tracking no",
    },
    {
      key: "orderDate",
      label: "Date Sold",
    },
    {
      key: "buyerPaidDate",
      label: "Date Buyer Paid",
    },
    {
      key: "zipCode",
      label: "Zip code",
    },
    {
      key: "status",
      label: "Status",
    },
    {
      key: "shippingAmount",
      label: "Shipping Amount",
    },
    {
      key: "sellerNote",
      label: "Note",
    },
    {
      key: "shippingDate",
      label: "Shipping Date",
    },
  ],
  SELLER_ALL_ORDERS_CANCEL: [
    {
      key: "orderId",
      label: "Order Id",
    },
    {
      key: "buyerName",
      label: "Buyer name",
    },
    {
      key: "productName",
      label: "Product name",
    },
    {
      key: "condition",
      label: "Condition",
    },
    
    {
      key: "refund",
      label: "Refund",
    },
    
   
    {
      key: "requestedDate",
      label: "Requested Date",
    },
    {
      key: "reason",
      label: "Reason",
    },
    {
      key: "cancelledDescription",
      label: "Cancelled Description",
    },
    {
      key: "status",
      label: "Status",
    },
    
    {
      key: "sellerNote",
      label: "Note",
    },
   
  ],
  SELLER_ALL_ORDERS_RETURN: [
    {
      key: "orderId",
      label: "Order Id",
    },
    {
      key: "returnId",
      label: "Return Id",
    },
    {
      key: "buyerName",
      label: "Buyer name",
    },
    {
      key: "productName",
      label: "Product name",
    },
    {
      key: "condition",
      label: "Condition",
    },
    
    {
      key: "refund",
      label: "Refund",
    },
    
   
  
    {
      key: "reason",
      label: "Reason",
    },
    {
      key: "description",
      label: "Description",
    },
    {
      key: "status",
      label: "Status",
    },
    {
      key: "requestedDate",
      label: "Requested Date",
    },
    {
      key: "acceptedDate",
      label: "Accepted Date",
    },
    {
      key: "itemReceivedDate",
      label: "Item Received Date",
    },
   
    {
      key: "completedDate",
      label: "Completed Date",
    },
    
    {
      key: "sellerNote",
      label: "Note",
    },
   
  ],
  ACTIVE_LISTING: [
    {
      key: "active",
      label: "Status",
    },
    
    {
      key: "productName",
      label: "Product name",
    },
    {
      key: "buyNowPrice",
      label: "Current price",
    },
    
    {
      key: "quantity",
      label: "Available quantity",
    },
    
   
  
    {
      key: "upc",
      label: "UPC",
    },
    {
      key: "bids",
      label: "Bids",
    },
    
   
  ],
  ENDED_LISTING: [
    {
      key: "active",
      label: "Status",
    },
    
    {
      key: "productName",
      label: "Product name",
    },
    {
      key: "buyNowPrice",
      label: "Current price",
    },
    
    {
      key: "quantity",
      label: "Available quantity",
    },
    
   
  
    {
      key: "upc",
      label: "UPC",
    },
    {
      key: "views",
      label: "Views",
    },
    
   
  ],
  UNSOLD_LISTING: [
    {
      key: "active",
      label: "Status",
    },
    
    {
      key: "productName",
      label: "Product name",
    },
    {
      key: "buyNowPrice",
      label: "Current price",
    },
    
    {
      key: "quantity",
      label: "Available quantity",
    },
    
   
  
    {
      key: "upc",
      label: "UPC",
    },
    {
      key: "views",
      label: "Views",
    },
    {
      key: "relistStatus",
      label: "Relist status",
    },
    
   
  ],
  DRAFT_LISTING: [
    {
      key: "active",
      label: "Status",
    },
    
    {
      key: "productName",
      label: "Product name",
    },
    {
      key: "format",
      label: "Format",
    },
    {
      key: "duration",
      label: "Duration",
    },
    {
      key: "buyNowPrice",
      label: "Current price",
    },
    
    {
      key: "quantity",
      label: "Available quantity",
    },
    
   
  
    {
      key: "upc",
      label: "UPC",
    },
    {
      key: "lastUpdatedDate",
      label: "Last updated date",
    },
    {
      key: "createdDate",
      label: "Date created",
    },
    
   
  ],
  SCHEDULED_LISTING: [
    {
      key: "active",
      label: "Status",
    },
    
    {
      key: "productName",
      label: "Product name",
    },
    {
      key: "format",
      label: "Format",
    },
    
    {
      key: "buyNowPrice",
      label: "Current price",
    },
    
    {
      key: "quantity",
      label: "Available quantity",
    },
    
   
  
    {
      key: "upc",
      label: "UPC",
    },
    {
      key: "startDate",
      label: "Start date",
    },
    
    
   
  ],
  PAYMENT_LISTING: [
    {
      key: "orderId",
      label: "Order Id",
    },
    
    {
      key: "orderDate",
      label: "Order date",
    },
    {
      key: "platFormFee",
      label: "Platform fee($)",
    },
    
    {
      key: "sellerReceivableAmount",
      label: "Seller receivable amount($)",
    },
    
    {
      key: "transactionFee",
      label: "Transaction fee($)",
    },
    
   
  
    {
      key: "totalAmount",
      label: "Total amount($)",
    },
    {
      key: "returnId",
      label: "Return Id",
    },
    {
      key: "refundAmount",
      label: "Refund amount($)",
    },
    {
      key: "status",
      label: "Order status",
    },
    
    
   
  ]

};
