import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ForgotPassword } from "../../utils/types";

type initialDataState = {
  data: ForgotPassword | null;
  error: any | null;
};

const initialState: initialDataState = {
  data: null,
  error: null,
};
const slice = createSlice({
  name: "forgetpassword",
  initialState,
  reducers: {
    resetForgotPassword: (state) => {
      state.data = null;
      state.error = null;
    },
    forgetPasswordSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = null;
    },
    forgetPasswordFailed: (state, action: PayloadAction<any>) => {
      state.data = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
    UpdatePasswordSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = null;
      
    },
    UpdatePasswordFailed: (state, action: PayloadAction<any>) => {
      
      
      state.error = action.payload;
      state.data = action.payload;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
  },
});

export const forgotpassword = slice.reducer;

export const {
  forgetPasswordFailed,
  forgetPasswordSuccess,
  resetForgotPassword,
  UpdatePasswordSuccess,
  UpdatePasswordFailed
} = slice.actions;
