import { toast } from "react-toastify";
import Resizer from "react-image-file-resizer";

export const getImageDimension = async (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event: any) => {
      const image = new Image();
      image.src = event.target.result;
      image.onload = () => {
        resolve({ width: image.width, height: image.height });
        const data = { width: image.width, height: image.height };
        return data;
      };
      reader.onerror = (err) => reject(err);
    };
  });
};
export const resizeImage = (file: any, width: number, height: number) => {
  let quality = 60;
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width + 1,
      height + 1,
      "JPEG",
      quality,
      0,
      (uri) => {
        resolve(uri);
      },
      "file",
      width,
      height
    );
  });
};
