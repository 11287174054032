import { API_URLS } from "../../utils/api.urls";

class MyUploadAdapter {
  public loader: any;
  public url: string;
  public xhr: any;
  public token: string;

  constructor(loader: any) {
    this.loader = loader;

    // change "environment.BASE_URL" key and API path
    this.url = API_URLS.NOTES_UPLOAD_IMAGE;

    // change "token" value with your token
    this.token = localStorage.getItem("authorization") as string;
  }

  upload() {
    return new Promise(async (resolve, reject) => {
      this.loader.file.then((file: any) => {
        this._initRequest();
        this._initListeners(resolve, reject, file);
        this._sendRequest(file);
      });
    });
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());
    xhr.open("POST", this.url, true);

    // change "Authorization" header with your header
    xhr.setRequestHeader("Authorization", "Bearer " + this.token);

    // xhr.responseType = "json";
  }

  _initListeners(resolve: any, reject: any, file: any) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = "Couldn't upload file:" + ` ${file.name}.`;

    xhr.addEventListener("error", () => reject(genericErrorText));
    xhr.addEventListener("abort", () => reject());

    xhr.addEventListener("load", () => {
      const response = xhr.response;

      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        );
      }

      // change "response.data.fullPaths[0]" with image URL
      resolve({
        default: JSON.parse(response).imagePath,
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener("progress", (evt: any) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  _sendRequest(file: any) {
    const data = new FormData();

    // change "attachments" key
    data.append("image", file);

    this.xhr.send(data);
  }
}

export default function MyCustomUploadAdapterPlugin(editor: any) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
    // Configure the URL to the upload script in your back-end here!
    return new MyUploadAdapter(loader);
  };
}
