import React, { useEffect, useRef, useState } from "react";
import HeaderNormal from "../../../../components/header/HeaderNormal";
import FooterNormal from "../../../../components/footer/FooterNormal";
import { DefaultLayout } from "../../../../layout/DefaultLayout";
import { useDispatch, useSelector } from "react-redux";
import { getPaypalLink } from "../../../../modules/paypal/getPaypalLink";
import { RootState } from "../../../../utils/types";
import { PaypalResponseSelector } from "../../../../modules/paypal/selectors";
import { useLocation, useNavigate } from "react-router-dom";
import { setPaypalAccount } from "../../../../modules/paypal/setPaypalAccount";
import { notify } from "../../../../modules/notification/notify";
import { reset } from "../../../../modules/paypal/reset";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import BuyerFooter from "../../../buyer/buyerFooter";

export const PaymentInfo = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let paypalResponse = useSelector<RootState, RootState["paypal"]>(
    PaypalResponseSelector
  );
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch<any>(getPaypalLink());
  };
  const search = useLocation().search;
  const isFirstRender = React.useRef(true);

  const merchantId = new URLSearchParams(search).get("merchantId");
  const merchantIdInPayPal = new URLSearchParams(search).get(
    "merchantIdInPayPal"
  );
  const consentStatus = new URLSearchParams(search).get("consentStatus");
  const accountStatus = new URLSearchParams(search).get("accountStatus");
  // useEffect(() => {
  //   if (paypalResponse.paypalLink !== null) {
  //     window.open(
  //       paypalResponse.paypalLink.signUpUrl,
  //       "_blank",
  //       "noopener,noreferrer"
  //     );
  //   }
  // }, [paypalResponse]);
  useEffect(() => {
    if (merchantId !== null) {
      dispatch<any>(
        setPaypalAccount({
          merchantId: merchantId,
          merchantIdInPayPal: merchantIdInPayPal,
          consentStatus: consentStatus === "true" ? true : false,
          accountStatus: accountStatus,
        })
      );
    }
  }, [merchantId]);

  useEffect(() => {
    if (!isFirstRender.current) {
      if (paypalResponse.paypalAccountLink != null) {
        if (paypalResponse.paypalAccountLink?.success === "Y") {
          notify(paypalResponse.paypalAccountLink.message, "success");
          localStorage.setItem("payPalAccountLinked", "true");

          navigate("/seller/overview", { replace: true });
        }

        if (paypalResponse.paypalAccountLink.success === "N") {
          notify(paypalResponse.paypalAccountLink.message, "error");
        }
      }
      if (paypalResponse.paypalAccountLinkError != null) {
        notify(paypalResponse.paypalAccountLinkError, "error");
      }
      dispatch<any>(reset());
    }
    isFirstRender.current = false;
  }, [paypalResponse]);

  useEffect(() => {
    if (paypalResponse.paypalLink != null) {
      if (paypalResponse.paypalLink?.success === "Y") {
        window.open(
          paypalResponse.paypalLink.signUpUrl,
          "_blank",
          "noopener,noreferrer"
        );
        // notify(paypalResponse.paypalLink.message, "success");
      }

      if (paypalResponse.paypalLink.success === "N") {
        notify(paypalResponse.paypalLink.message, "error");
      }
    }
    if (paypalResponse.paypalLinkError != null) {
      notify(paypalResponse.paypalLinkError, "error");
    }
    dispatch<any>(reset());
  }, [paypalResponse]);
  return (
    <DefaultLayout>
      <HeaderNormal />
      <ToastContainer />

      <section className="login-section min-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="login-container">
                <form className="form-block">
                  <h2 className="h2-head text-center">Payment Details</h2>

                  <div className="signin-text text-center">
                    Please click the button to link with Paypal
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary login-btn form-button"
                    onClick={(e: any) => handleSubmit(e)}
                  >
                    Connect To Paypal
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BuyerFooter />
    </DefaultLayout>
  );
};
