import { AppThunk } from "../../../../store";
import { EndProgress } from "../../../progressBar/endProgress";
import { StartProgress } from "../../../progressBar/startProgress";
import { createReturnShipment } from "./api";
import { SaveReturnShipmentFailed, SaveReturnShipmentSuccess } from "./slice";

export const createReturnShipment_Buyer = (params: any): AppThunk => async (dispatch) => {
    
    dispatch<any>(StartProgress());
    //-----------------------------
    try {
      const data: any = await createReturnShipment(params);
      if (data.success === "N") {
        dispatch(SaveReturnShipmentFailed(data));
      } else {
        dispatch(SaveReturnShipmentSuccess(data));
      }
    } catch (err) {
      dispatch(SaveReturnShipmentFailed(err));
    }
    //-----------------------------
    dispatch<any>(EndProgress());
    
  };
