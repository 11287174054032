import React, { useEffect, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { dateToLocal } from '../../../modules/date/date';
import { notify } from '../../../modules/notification/notify';
import { getSellerBidUserList } from '../../../modules/sellerProduct/getSellerBidUserList';
import { getSellerOfferUserList } from '../../../modules/sellerProduct/getSellerOfferUserList';
import { reset } from '../../../modules/sellerProduct/reset';
import { saveAcceptRejectBid } from '../../../modules/sellerProduct/saveAcceptRejectBid';
import { saveAcceptRejectOffer } from '../../../modules/sellerProduct/saveAcceptRejectOffer';
import { SellerProductResponseSelector } from '../../../modules/sellerProduct/selector';
import { BASE_URLS } from '../../../utils/api.urls';
import { constant } from '../../../utils/constant';
import { RootState } from '../../../utils/types';



const BidsModal = ({ closeModal,bidsModal}:any) => {
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(10);
  const [currentPage, setcurrentPage] = useState(0);
 
  const isFirstRender = useRef(true);
  const isFirstRender2 = useRef(true);
  const dispatch = useDispatch();

  const product = useSelector<RootState, RootState["sellerProduct"]>(
    SellerProductResponseSelector
  );
useEffect(()=>{

  if (product?.bidsUsers?.data?.userBids !== null && pageCount === 0) {
    setPageCount(Math.ceil(product?.bidsUsers?.data?.totalResult / itemCount));
  }
},[JSON.stringify(product?.bidsUsers?.data?.userBids), pageCount])

  
  useEffect(() => {
    if (isFirstRender.current && bidsModal?.productID!=="") {
      dispatch<any>(
        getSellerBidUserList({
          currentPage: currentPage + 1,
          rows: itemCount,
          
          productId: bidsModal?.productId,
        })
      ).then(() => {
        if (currentPage === 0) setPageCount(0);
      });
    }
    isFirstRender.current = false;

   
  }, [bidsModal?.productID]);

  useEffect(() => {
    if (!isFirstRender2.current && bidsModal?.productID!=="") {
      dispatch<any>(
        getSellerBidUserList({
          currentPage: currentPage + 1,
          rows: itemCount,
          
          productId: bidsModal?.productId,
        })
      ).then(() => {
        if (currentPage === 0) setPageCount(0);
      });
    }
    isFirstRender2.current = false;
  }, [currentPage, itemCount,bidsModal?.productID]);
  
  const handlePageChange = (selectedObject: any) => {
    setcurrentPage(selectedObject.selected);
  };

  useEffect(() => {
    if (product.saveData != null) {
      if (product.saveData.success == "Y") {
        notify(product.saveData.message, constant.SUCCESS);
        dispatch<any>(
          getSellerBidUserList({
            currentPage: currentPage + 1,
            rows: itemCount,
            
            productId: bidsModal?.productId,
          })
        ).then(() => {
          if (currentPage === 0) setPageCount(0);
        });
      } 
    }
    if (product.error !== null) {
      notify(product.error, constant.ERROR);
    }
    dispatch<any>(reset("saveData"));
  }, [product.saveData, product.error]);

  // const handleItemsChange = (event: any) => {
  //   setItemCount(event.target.value);
  //   setcurrentPage(0);
  // };
  
  return (
    <div className="modal modal-order-popup offers-modal-popup" id="myModal" tabIndex={-1} role="dialog"  style={{ display: bidsModal.visible }}>
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="order-modal">
        <button type="button" className="btn-close-icon close" onClick={()=>closeModal()} data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      
            <div className="order-modal-head">Bids</div>



            <div className="seller-table-popup-block">
            <table className="table table-photo-offer">
              <thead>
                <tr>
                  <th style={{width:"40px"}}>Photo</th>
                  <th style={{width:"300px"}} scope="col">Title</th>
                  <th style={{width:"100px"}} scope="col">Base price</th>
                  <th scope="col">Bids</th>
                </tr>
              </thead>
              <tbody>
               { <tr>
                  <th scope="row">
                    <div className="offer-photo"><img  src={
                                    BASE_URLS.AWS_URL +
                                    "/Thumb" +
                                    bidsModal?.image
                                  }
                                  alt="dummy image"
                                  /></div>
                  </th>
                  <td>{  bidsModal?.title}</td>
                  <td><div className="blue-link">${   bidsModal?.basePrice}</div></td>
                  <td className="blue-link"> {`$ ${  bidsModal?.maxBids} | ${  bidsModal?.totalBids} Bids`}</td>
                </tr>}
              </tbody>
            </table>
  
            <table className="table table-report-data">
              <thead className="offer-thead">
                <tr>
                  <th style={{width:"150px"}} >Name</th>
                  <th style={{width:"216px"}}  scope="col">Email</th>
                  <th style={{width:"100px"}}  scope="col">Bid price</th>
                  <th scope="col">Bid date & time</th>
                  <th scope="col">Status</th>

                  {/* <th scope="col">Action</th> */}
                </tr>
              </thead>
              <tbody>
              {product?.bidsUsers?.data?.userBids?.map((bid)=>  <tr>
                  <th scope="row">{bid?.userName}</th>
                  <td>{bid?.email}</td>
                  <td><div className="blue-link">${bid?.biddingAmount}</div></td>
                  <td>{dateToLocal(bid?.biddingDate)}</td>
                  <td>
                    {bid?.acceptRejectStatus ===1 ? "Accepted" : bid?.acceptRejectStatus ===2 ? "Rejected" : bid?.acceptRejectStatus===4 ? "Expired" :"Pending"}
                    </td>

                  {/* <td>
                  { bid?.acceptRejectStatus==3 &&  <div className="modal-btn-group">
                      <button className="modal-add-btn" onClick={() =>
                                  dispatch<any>(
                                    saveAcceptRejectBid({
                                      bidId: bid?.bidId,
                                      Accepted: true
                                    })
                                  )
                                } >Accept</button>
                      <button className="modal-cancel-btn" onClick={() =>
                                  dispatch<any>(
                                    saveAcceptRejectBid({
                                      bidId: bid?.bidId,
                                      Accepted: false
                                    })
                                  )
                                }>Decline</button>
                    </div>}
                  </td> */}
                </tr>
           )  }
               
              </tbody>
            </table>
            </div>
  <div>
  <nav className="pagination-block ">
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    forcePage={currentPage}
                    onPageChange={handlePageChange}
                    containerClassName={
                      "pagination justify-content-center mt-4"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />

                  {/* <div className="total-items-block">
                    <label>Items per page:</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={handleItemsChange}
                      value={itemCount}
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </div> */}
                </nav>
  </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default BidsModal