import { AppThunk } from "../../store";
import { TrafficSuccess, TrafficFailed } from "./slice";
import { fetchTraffic } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const getTraffic = (): AppThunk => async (dispatch) => {
  dispatch<any>(StartProgress());
  try {
    const UserData: any = await fetchTraffic();

    if (UserData.success === "N") {
      dispatch(TrafficFailed(UserData));
    } else {
      dispatch(TrafficSuccess(UserData));
    }
  } catch (err) {
    dispatch(TrafficFailed(err));
  }
  dispatch<any>(EndProgress());
};
