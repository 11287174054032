import { AppThunk } from "../../store";
import { RemoveImageSuccess, RemoveImageFailed } from "./slice";
import { deleteImage, postListingDetails } from "./api";
import { EndProgress } from "../progressBar/endProgress";
import { StartProgress } from "../progressBar/startProgress";

export const removeImage =
  (data: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
      const listingData: any = await deleteImage(data);
      

      if (listingData.success === "N") {
        
        dispatch(RemoveImageFailed(listingData));
      } else {
        
        dispatch(RemoveImageSuccess(listingData));
      }
    } catch (err) {
      
      dispatch(RemoveImageFailed(err));
    }

    dispatch<any>(EndProgress());
  };
