import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../../../components/header/Header";
import { DefaultLayout } from "../../../layout/DefaultLayout";
import { getCommonData } from "../../../modules/common/getCommonData";
import { CommonResponseSelector } from "../../../modules/common/selectors";
import { BASE_URLS } from "../../../utils/api.urls";
import { RootState } from "../../../utils/types";
import * as Yup from "yup";
import { string, object, array } from "yup";
import { postReportBuyer } from "../../../modules/orders/postReportBuyer";
import { SellerOrdersResponseSelector } from "../../../modules/orders/selectors";
import { notify } from "../../../modules/notification/notify";
import { constant } from "../../../utils/constant";
import { resetOrder } from "../../../modules/orders/resetOrder";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../../components/footer/Footer";

const ReportBuyer = () => {
  const { state }: any = useLocation();
  const navigate = useNavigate();
  const commonResponse = useSelector<RootState, RootState["common"]>(
    CommonResponseSelector
  );

  let order = useSelector<RootState, RootState["sellerOrders"]>(
    SellerOrdersResponseSelector
  );

  const isFirstRender = useRef(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isFirstRender.current) {
      if (commonResponse?.common === null) dispatch<any>(getCommonData());
    }
    isFirstRender.current = false;
  }, []);

  

  interface reportValues {
    reportCategory: string | number;
    reasonForReport: string | number;
    detailReason: string;
  }
  const [report, setReport] = useState<reportValues>({
    reportCategory: "",
    reasonForReport: "",
    detailReason: "",
  });

  const reportSchema = Yup.object().shape({
    reportCategory: Yup.string().required("*Required!"),
    reasonForReport: Yup.string().required("*Required!"),
  });

  const submitData = (data: any) => {
    dispatch<any>(
      postReportBuyer({
        orderId: state.orderId,
        reportCategory: report.reportCategory,
        reasonForReport: report.reasonForReport,
        detailReason: report.detailReason,
      })
    );
  };
  const handleChange = (e: any) => {
    setReport({ ...report, [e.target.name]: e.target.value });
  };

  const reDirect=()=>{
    navigate("/seller/orders") ;
  }
  useEffect(() => {
    if (order.saveData != null) {
      if (order.saveData.success == "Y") {
        notify(order.saveData.message, constant.SUCCESS);

        setReport({...report,    reportCategory: "",
        reasonForReport: "",
        detailReason: "", })
        setTimeout(  reDirect  , 3000);
      } else if (order.saveData.success == "N") {
        notify(order.saveData.message, constant.ERROR);
      }
    }
    if (order.saveDataError !== null) {
      notify(order.saveDataError, constant.ERROR);
    }
    dispatch<any>(resetOrder());
  }, [order.saveData, order.saveDataError]);
  return (
    <DefaultLayout>
      <Header />
      <ToastContainer />
      <section className="seller-breadcrumb-links">
        <div className="container">
          <ul>
            <li>
              <Link to={"/seller/overview"}>eFindit</Link>
            </li>
            <li className="spacer">{">"}</li>
            <li>
              <Link to={"/seller/orders"}>orders</Link>
            </li>
            <li className="spacer">{">"}</li>
            <li className="active">Report buyer</li>
          </ul>
        </div>
      </section>
      <main className="seller-page-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="contact-buyer-block">
                <div className="contact-buyer-section">
                  <h3>
                    Report buyer <a href="#">{state?.buyerName}</a>{" "}
                  </h3>
                  <div className="contact-buyer-layout">
                    <div className="buyer-product-items">
                      <div className="buyer-checkbox-items">
                        <p>
                          <label htmlFor="checkbox3">{state?.productName}</label>
                        </p>
                      </div>
                    </div>

                    <div className="buyer-product-list-items report-list-items">
                      <table className="table-buyer-product">
                        <tr>
                          <td>
                            <img
                              src={
                                BASE_URLS.AWS_URL +
                                "/Thumb" +
                                state?.productImage
                              }
                              style={{
                                height: 60,
                                width: 60,
                              }}
                              alt="product"
                            />
                          </td>
                          <td>
                            <label>Order ID</label>
                            <p>{state?.orderId}</p>
                          </td>
                          <td>
                            <label>Price</label>
                            <p>${state?.soldFor}</p>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <Formik
                      initialValues={report}
                      enableReinitialize={true}
                      validationSchema={reportSchema}
                      onSubmit={(values) => {
                        submitData(values);
                      }}
                    >
                      {({ handleSubmit, errors, touched }) => (
                        <Form>
                          <div className="buyer-contact-form">
                            <label className="buyer-form-label">
                              Write to buyer
                            </label>
                            <div className="buyer-contact-form-col report-buyer-padd">
                              <div className="form-group">
                                <label className="label-buyer">
                                  Report category
                                </label>
                                <span className="red-star">*</span>
                                <select
                                  className="form-control input-buyer"
                                  name="reportCategory"
                                  onChange={(e) => handleChange(e)}
                                  value={report.reportCategory}
                                >
                                  <option value="">Select</option>

                                  {commonResponse?.common?.reportCategory?.map(
                                    (item: any, Index: number) => (
                                      <option key={item.key} value={item.value}>
                                        {item.label}
                                      </option>
                                    )
                                  )}
                                </select>
                                {errors.reportCategory &&
                                touched.reportCategory ? (
                                  <div
                                    className="required"
                                    style={{ color: "red" }}
                                  >
                                    {errors.reportCategory}
                                  </div>
                                ) : null}
                              </div>
                              <div className="spacer-report"></div>
                              <div className="form-group">
                                <label className="label-buyer">
                                  Reason for report
                                </label>
                                <span className="red-star">*</span>
                                <select
                                  className="form-control input-buyer"
                                  name="reasonForReport"
                                  onChange={(e) => handleChange(e)}
                                  value={report.reasonForReport}

                                >
                                  <option value="">Select</option>
                                  {commonResponse?.common?.reasonForReport?.map(
                                    (item: any, Index: number) => (
                                      <option key={item.key} value={item.value}>
                                        {item.label}
                                      </option>
                                    )
                                  )}
                                </select>
                                {errors.reasonForReport &&
                                touched.reasonForReport ? (
                                  <div
                                    className="required"
                                    style={{ color: "red" }}
                                  >
                                    {errors.reasonForReport}
                                  </div>
                                ) : null}
                              </div>
                              <div className="spacer-report"></div>
                              <div className="form-group">
                                <label className="label-buyer">
                                  Detailed reason
                                </label>
                                <div className="form-group spacer-feedback-label">
                                  <textarea
                                    className="form-control buyer-textarea input-modal"
                                    rows={3}
                                    maxLength={500}
                                    placeholder="write some thing"
                                    value={report.detailReason}
                                    onChange={(e) =>
                                      setReport({
                                        ...report,
                                        detailReason: e.target.value,
                                      })
                                    }
                                  ></textarea>
                                  <p>
                                    {report.detailReason.length}
                                    /500
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="seller-button contact-buyer-btn">
                            <button className="list-button">Continue</button>
                            <button className="list-button bg-none"
                             onClick={() => {
                              dispatch<any>(
                                resetOrder()).then(()=>navigate("/seller/orders"))     
                                                
                                                }}
                            >
                              Cancel
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                    <p className="buyer-bottom-text">
                      Don't exchange contact info to{" "}
                      <a href="#">buy or sell outside eFindit.</a> We scan and
                      analyze messages to identify potential fraud and policy.
                      <br />
                      Sometimes it will keep us from sending your message, even
                      when there is no intention to commit fraud.
                    </p>
                  </div>
                </div>
                <div className="help-section">
                  <div className="inner">
                    <h3 className="help-head">Help</h3>
                    <p className="help-para">
                      To keep the eFindit marketplace functioning effectively,
                      we know it is important for members to be able to
                      communicate easily with each other.
                    </p>
                    <div className="help-text-small">
                      We don't allow our members to:
                    </div>
                    <ul>
                      <li>Make offers to buy and sell outside of eFindit</li>
                      <li>Send spam</li>
                      <li>
                        Exchange any personal information (except names),
                        including email addresses, phone numbers, or other
                        contact information
                      </li>
                      <li>
                        Send viruses, malicious code, or software through our
                        messaging service
                      </li>
                    </ul>
                    <p className="help-bottom-text">
                      To find out more about our policies related to
                      member-to-member communications, please read our full
                      policy guidelines below.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </DefaultLayout>
  );
};

export default ReportBuyer;
