import React from "react";
import { Link } from "react-router-dom";
import FbIcon from "../../assets/images/fb.png"
import XIcon from "../../assets/images/x.png"
const BuyerFooter = () => {
  return (
    <footer>
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-6 col-xs-6">
              <div className="footer-block">
                <h3>Buy</h3>
                <ul className="list-unstyled">
                {   localStorage.getItem("authorization") === null &&   <li>
                   <Link to={"/register"}>Registration</Link>
                  </li>}
                  {/* <li>
                    <a href="#">Bidding &amp; buying help</a>
                  </li>
                  <li>
                    <a href="#">Stores</a>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 col-xs-6">
              <div className="footer-block">
                <h3>Sell</h3>
                <ul className="list-unstyled">
                  <li>
                  <Link to={"/login-seller"}>Start selling</Link>
                  </li>
                  {/* <li>
                    <a href="#">Learn to sell</a>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="footer-block">
                {/* <h3>Tools &amp; apps</h3>
                <ul className="list-unstyled">
                  <li>
                    <a href="#">Site map</a>
                  </li>
                </ul> */}
                <a href="#" className="google-play-icon"></a>
                <a href="#" className="apple-icon"></a>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="footer-block">
                <h3>Stay connected</h3>
                <ul className="list-unstyled">
                <li>
                    <a href="https://www.facebook.com/profile.php?id=61553115670847&mibextid=2JQ9oc" target="_blank" ><img src={FbIcon} style={{height:"17px",width:"17px"}}/> Facebook </a>
                  </li>
                  <li>
                  <a href="https://twitter.com/EfinditInc" target="_blank"><img src={XIcon} style={{height:"17px",width:"17px"}}/> Twitter</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="footer-block">
                <h3>About efindit</h3>
                <ul className="list-unstyled">
                  <li>
                    <Link to="/about-us">About us</Link>
                  </li>
                  <li>
                  <Link to="/terms-and-conditions">Terms and conditions</Link>
                  </li>
                  <li>
                  <Link to="/privacy-policy">Privacy policy</Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="footer-block">
                <h3>Help &amp; Contact</h3>
                <ul className="list-unstyled">
                  <li>
                    <a href="#">Contact us</a>
                  </li>
                  <li>
                    <a href="#">FAQs</a>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p className="text-center">
          Copyright &copy;2023 eFindit. All rights reserved
        </p>
      </div>
    </footer>
  );
};

export default BuyerFooter;
