import { AppThunk } from "../../store";
import { DashboardFeedbackSuccess, DashboardFeedbackFailed } from "./slice";
import { fetchDashboardFeedbacks } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const getDashboardFeedbacks = (): AppThunk => async (dispatch) => {
  dispatch<any>(StartProgress());
  try {
    const UserData: any = await fetchDashboardFeedbacks();

    if (UserData.success === "N") {
      dispatch(DashboardFeedbackFailed(UserData));
    } else {
      dispatch(DashboardFeedbackSuccess(UserData));
    }
  } catch (err) {
    dispatch(DashboardFeedbackFailed(err));
  }
  dispatch<any>(EndProgress());
};
