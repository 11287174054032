import { AppThunk } from "../../../store";
import { SaveRecentlyViewedFailed, SaveRecentlyViewedSuccess } from "./slice";
import { postRecentlyViewed } from "./api";

export const setRecentlyViewed =
  (params: any): AppThunk =>
  async (dispatch) => {
    try {
      const data: any = await postRecentlyViewed(params);
      

      if (data.success === "N") {
        
        dispatch(SaveRecentlyViewedFailed(data));
      } else {
        
        dispatch(SaveRecentlyViewedSuccess(data));
      }
    } catch (err) {
      
      dispatch(SaveRecentlyViewedFailed(err));
    }
  };
