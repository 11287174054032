import { Link } from "react-router-dom";

const PageNotFound_404 = () =>{
    return (
        <div className="not-found">
            <div className="not-found-container">
                <h1 className="not-found-title text-danger">404</h1>
                <p className="not-found-message">Sorry, the page you are looking for cannot be found.</p>
                <Link to="/" className="not-found-link">Back to Home</Link>
            </div>
        </div>
    )
}

export default PageNotFound_404;