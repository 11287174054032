import React, { useState, useEffect, useCallback, useRef } from "react";
import { Validator } from "react";

import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../utils/types";
import { resetPasswordResponseSelector } from "../../modules/resetPassword/selectors";
import { getResetPassword } from "../../modules/resetPassword/getResetPassword";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { notify } from "../../modules/notification/notify";
import { reset } from "../../modules/resetPassword/reset";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";


export const ResetPass = (props: any) => {
  const search = useLocation().search;
  const resetId = new URLSearchParams(search).get("id");
 const navigate=useNavigate();
  const dispatch = useDispatch();
  let resetPasswordResponse = useSelector<
    RootState,
    RootState["resetpassword"]
  >(resetPasswordResponseSelector);
  const [user, setUserValue] = useState({
    password: "",
    confirmPassword: "",
  });
  
  const firstRender= useRef(true);
useEffect(()=>{
  if(firstRender.current
    )
    {
      dispatch<any>(reset());
firstRender.current=false;

    }

},[])

  useEffect(() => {
if(!firstRender.current)
{
    if (resetPasswordResponse?.data != null) {
      if (resetPasswordResponse?.data.success === "Y") {
        notify(resetPasswordResponse.data.message, "success");
      }

      if (resetPasswordResponse.data.success === "N") {
        notify(resetPasswordResponse.data.message, "error");
      }
      
      // dispatch<any>(reset());


    }
    if (resetPasswordResponse?.error != null) {
      notify(resetPasswordResponse.error, "error");
      dispatch<any>(reset());
    }
    setUserValue({
      password: "",
      confirmPassword: "",
    });
  }
  }, [resetPasswordResponse]);

  const ProfileSchema = Yup.object().shape({
    password: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });
  const handleChange = (e: any) => {
    setUserValue({ ...user, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <ToastContainer />

      <header className="header">
        <div className="logo-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="logo-container">
                  <div className="logo">
                    <a href="#">
                      <i className="logo-img"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="login-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="login-container">
                <Formik
                  initialValues={user}
                  validationSchema={ProfileSchema}
                  enableReinitialize={true}
                  onSubmit={(values) => {
                    dispatch<any>(
                      getResetPassword({ id: resetId, password: user.password })
                    );
                  }}
                >
                  {({ errors, touched }) => (
                    <>
                      <Form>
     {resetPasswordResponse?.data?.success!=="Y"  ?           
      <div>     
                    <h2 className="h2-head text-center">Reset Password</h2>

                        <div className="reset-form">
                          <div className="form-group">
                            <input
                              type="password"
                              placeholder="Password"
                              name="password"
                              className="form-control login-input"
                              value={user?.password}
                              required
                              onChange={handleChange}
                            />
                            {errors.password && touched.password ? (
                              <div className="invalid-feedback d-block">
                                {errors.password}
                              </div>
                            ) : null}
                          </div>

                          <div className="form-group">
                            <input
                              type="password"
                              placeholder="Confirm Password"
                              className="form-control login-input"
                              name="confirmPassword"
                              value={user?.confirmPassword}

                              onChange={handleChange}
                              required
                            />
                            {errors.confirmPassword &&
                            touched.confirmPassword ? (
                              <div className="invalid-feedback d-block">
                                {errors.confirmPassword}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <button
                          type="submit"
                          className="btn btn-primary login-btn form-button"
                        >
                          Reset
                        </button>
                        </div> :                <div>         <p
                        className=" p-1 pl-2 ml-2 mt-3"
                        style={{
                          fontSize: "12px",
                          backgroundColor: "#90EE90",
                        }}
                      >
                      Password has been successfully changed 
                      </p>
                      {/* <Link
                         
                          className="btn btn-primary login-btn form-button pt-3"
                        to="/home"
                        >
                          Go back to efindit
                        </Link> */}
                        </div> }
                      </Form>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <div className="footer-bottom">
          <p className="text-center">
            Copyright &copy;2023 efindit. All rights reserved
          </p>
        </div>
      </footer>
    </div>
  );
};
