import { AppThunk } from "../../../../store";
import { PurchaseHistoryFailed, PurchaseHistorySuccess } from "./slice";
import { fetchPurchaseHistory } from "./api";
import { EndProgress } from "../../../progressBar/endProgress";
import { StartProgress } from "../../../progressBar/startProgress";

export const getPurchaseHistory =
  (params: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
      const data: any = await fetchPurchaseHistory(params);
      

      if (data.success === "N") {
        
        dispatch(PurchaseHistoryFailed(data));
      } else {
        
        dispatch(PurchaseHistorySuccess(data));
      }
    } catch (err) {
      
      dispatch(PurchaseHistoryFailed(err));
    }
    dispatch<any>(EndProgress());
  };
