import { AppThunk } from "../../store";
import { ShipperBuyerDetailSuccess, ShipperBuyerDetailFailed } from "./slice";
import { fetchOrderDetail, fetchShipperBuyerDetail } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const getShipperBuyerDetail =
  (data: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch<any>(StartProgress());

      const ordersData: any = await fetchShipperBuyerDetail(data);
      

      if (ordersData.success === "N") {
        
        dispatch(ShipperBuyerDetailFailed(ordersData));
      } else {
        
        dispatch(ShipperBuyerDetailSuccess(ordersData));
      }

      //return DashboardData
    } catch (err) {
      
      dispatch(ShipperBuyerDetailFailed(err));
      //return err.toString()
    }
    dispatch<any>(EndProgress());
  };
