import { AppThunk } from "../../store";
import {  ProductsByCategoryFailed, ProductsByCategorySuccess } from "./slice";
import {  fetchDealsNameSeller, fetchProductsByCategory } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const getProductsByCategory =
  (data:string): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
      const productData: any = await fetchProductsByCategory(data);

      if (productData.success === "N") {
        dispatch(ProductsByCategoryFailed(productData));
      } else {
        dispatch(ProductsByCategorySuccess(productData));
      }
    } catch (err) {
      dispatch(ProductsByCategoryFailed(err));
    }
    dispatch<any>(EndProgress());
  };
