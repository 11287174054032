import { AppThunk } from "../../store";
import { BidsUsersFailed, BidsUsersSuccess } from "./slice";
import {  fetchSellerBidUserList, fetchSellerOfferUserList } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const getSellerBidUserList =
  (data: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
      const productData: any = await fetchSellerBidUserList(data);

      if (productData.success === "N") {
        dispatch(BidsUsersFailed(productData));
      } else {
        dispatch(BidsUsersSuccess(productData));
      }
    } catch (err) {
      dispatch(BidsUsersFailed(err));
    }
    dispatch<any>(EndProgress());
  };
