import { AppThunk } from "../../../../store";
import {
  SaveDataProductFailed,
  SaveDataProductSuccess,
  SaveDataSellerFailed,
  SaveDataSellerSuccess,
} from "./slice";
import { saveFeedback } from "./api";
import { EndProgress } from "../../../progressBar/endProgress";
import { StartProgress } from "../../../progressBar/startProgress";

export const buyerSetFeedback =
  (params: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
      const data: any = await saveFeedback(params);
      

      if (data.success === "N") {
        
        if (params.feedbackForSeller) dispatch(SaveDataSellerFailed(data));
        else dispatch(SaveDataProductFailed(data));
      } else {
        
        if (params.feedbackForSeller) dispatch(SaveDataSellerSuccess(data));
        else dispatch(SaveDataProductSuccess(data));
      }
    } catch (err) {
      
      if (params.feedbackForSeller) dispatch(SaveDataSellerFailed(err));
      else dispatch(SaveDataProductFailed(err));
    }
    dispatch<any>(EndProgress());
  };
