import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Common } from "../../utils/types"

type CommonState = {
  common: Common["common"] | null,
  countries: Common["countries"] | null,
  country: Common["country"] | null,
  address: Common["address"] | null,
  state: Common["state"] | null,
  error: null | string,
  webSinglePage: Common["webSinglePage"] | null,
  webSinglePageError:null | string,
}



const initialState: CommonState = {
  common: null,
  countries: null,
  country: null,
  address: null,
  state: null,
  error: null,
  webSinglePage:null,
  webSinglePageError:null,

}

const slice = createSlice({
  name: "common",
  initialState,
  reducers: {
    SinglePageReset: (state) => {
      state.webSinglePage = null;
      state.webSinglePageError = null;

    },
    CommonSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      
      
      
      state.common = action.payload
      state.error = null
    },
    CommonFailed: (state, action: PayloadAction<any>) => {
      
      
      state.error = action.payload
      state.common = null
      var errorMessage = action.payload.message ? action.payload.message : 'Something went wrong. Please try again'
    },
    CountriesSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      
      
      
      state.countries = action.payload
      state.error = null
    },
    CountriesFailed: (state, action: PayloadAction<any>) => {
      
      
      state.error = action.payload
      state.countries = null
      var errorMessage = action.payload.message ? action.payload.message : 'Something went wrong. Please try again'
    },
    CountrySuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      
      
      
      state.country = action.payload
      state.error = null
    },
    CountryFailed: (state, action: PayloadAction<any>) => {
      
      
      state.error = action.payload
      state.country = null
      var errorMessage = action.payload.message ? action.payload.message : 'Something went wrong. Please try again'
    },
    StateSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      
      
      
      state.state = action.payload
      state.error = null
    },
    StateFailed: (state, action: PayloadAction<any>) => {
      
      
      state.error = action.payload
      state.state = null
      var errorMessage = action.payload.message ? action.payload.message : 'Something went wrong. Please try again'
    },
    AddressSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      
      
      
      state.address = action.payload
      state.error = null
    },
    AddressFailed: (state, action: PayloadAction<any>) => {
      
      
      state.error = action.payload
      state.country = null
      var errorMessage = action.payload.message ? action.payload.message : 'Something went wrong. Please try again'
    },
    WebsiteSingleContentSuccess: (
      state,
      action: PayloadAction<any>
  ) => {
      state.webSinglePage = action.payload
      state.webSinglePageError = null
  },
  WebsiteSingleContentFailed: (state, action: PayloadAction<any>) => {
    state.error = action.payload
    state.webSinglePage = null
    var errorMessage = action.payload.message ? action.payload.message : 'Something went wrong. Please try again'
    state.webSinglePageError = errorMessage
},

  }
})


export const common = slice.reducer

export const {
  CommonSuccess,
  CommonFailed,
  CountrySuccess,
  CountryFailed,
  StateSuccess,
  StateFailed,
  AddressSuccess,
  AddressFailed,
  CountriesSuccess,
  CountriesFailed,
SinglePageReset,
WebsiteSingleContentFailed,
WebsiteSingleContentSuccess

} = slice.actions