import { AppThunk } from "../../store";
import { SellerProductDetailSuccess, SellerProductDetailFailed } from "./slice";
import { fetchProductDetails } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const getSellerProductDetails = (data:any): AppThunk => async (dispatch) => {
  dispatch<any>(StartProgress());
  try {
    const productData: any = await fetchProductDetails(data);

    if (productData.success === "N") {
      dispatch(SellerProductDetailFailed(productData));
    } else {
      dispatch(SellerProductDetailSuccess(productData));
    }
  } catch (err) {
    dispatch(SellerProductDetailFailed(err));
  }
  dispatch<any>(EndProgress());
};
