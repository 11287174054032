import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Login, Register, SellerTraffic } from "../../utils/types";

type TrafficState = {
  sellerTraffic: SellerTraffic["sellerTraffic"] | null;
  error: any | null;
};

const initialState: TrafficState = {
  sellerTraffic: null,
  error: null,
};

const slice = createSlice({
  name: "traffic",
  initialState,
  reducers: {
    TrafficSuccess: (state, action: PayloadAction<any>) => {
      state.sellerTraffic = action.payload;
      state.error = null;
      
    },
    TrafficFailed: (state, action: PayloadAction<any>) => {
      state.sellerTraffic = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },

    ResetTraffic: (state) => {
      state.sellerTraffic = null;
      state.error = null;
    },
  },
});

export const sellerTraffic = slice.reducer;

export const {
  TrafficFailed,
  TrafficSuccess,

  ResetTraffic,
} = slice.actions;
