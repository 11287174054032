import Api from "../../../utils/api";
import { API_URLS } from "../../../utils/api.urls";

export const fetchDeals = async (params: string): Promise<any> => {
  const data = await Api.get(`${API_URLS.DEALS}${params && "/" + params} `, []);
  return data;
};
export const fetchDealsHeadings = async (): Promise<any> => {
  const data = await Api.get(API_URLS.DEALS_HEADINGS, []);
  return data;
};
