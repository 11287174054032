import { AppThunk } from "../../store";
import { fetchSocialMedia } from "./api";
import { SocialMediaSuccess, SocialMediaFailed } from "./slice";
import { EndProgress } from "../progressBar/endProgress";
import { StartProgress } from "../progressBar/startProgress";

export const getSocialMedia =
  (data: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
      const socialMediaData: any = await fetchSocialMedia(data);

      if (socialMediaData.success === "N") {
        
        dispatch(SocialMediaFailed(socialMediaData));
      } else {
        
        dispatch(SocialMediaSuccess(socialMediaData));
      }
    } catch (err) {
      
      dispatch(SocialMediaFailed(err));
    }

    dispatch<any>(EndProgress());
  };
