import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import BuyerHeader from '../../BuyerHeader'
import GroupTab from '../groupTab'
import moment from "moment";
import { dateToLocal } from '../../../../modules/date/date'

const Details = () => {
    const dispatch = useDispatch();
    const isFirstRender = useRef(true);
    const navigate = useNavigate();
    const { search, pathname, state }: any = useLocation();

    let id = 106
    function useQuery() {

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();
    let msgType = query.get("type");
    localStorage.setItem("print", JSON.stringify(state))

    return (
        <>
            <BuyerHeader />
            <main className="min-section">
                <div className="filterBtn">
                    <img src="images/filter-filled-tool-symbol.png" alt="" />
                </div>
                <div className="container">
                    <h1 className="sectionTitle mt-5">My eFindit</h1>
                    <GroupTab currentPage="Messages" />
                    <ToastContainer />
                    <section className="withFilter">
                        <div className="left-filter">
                            <div className="left-lists-block">
                                <div className="wrap">
                                    <div className="block-title">
                                        <strong>Skip navigation</strong>
                                    </div>
                                    <ul className="list-unstyled msglinks">
                                        <li className={state?.nav == 'inbox' ? "active" : ""}><Link to="/buyer/messages/inbox">Inbox</Link></li>
                                        <li className={state?.nav == 'all' ? "active" : ""}><Link to="/buyer/messages/all">All messages</Link></li>
                                        <li className={state?.nav == 'efindit' ? "active" : ""}><Link to="/buyer/messages/efindit">From efindit</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="right-wrapper">
                            <section className="msgInbox pt-0">
                                <div className="msgTitle">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-md-auto">
                                            <h3>Subject: {state?.data?.subject}</h3>
                                        </div>
                                        {/* <div className="col-md-auto">
                                            <div className="searchBox">
                                                <input type="text" placeholder="Search your order" />
                                                <button className="searchBtn">Search</button>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="inboxMsgList">
                                    <div className="msgNav">
                                        {/* <a href="" className="active">All</a>
                                        <a href="">Unread</a>
                                        <a href="">Flagged</a> */}
                                    </div>
                                    <div className="actionBtns">
                                        {/* <a href="" className="msgActions">Reply to message</a> */}

                                        <div className="rightBtn">
                                        <Link to="/buyer/messages/print" target="_blank" rel="noopener">Print</Link>
                                            {/* <a onClick={() => navigate('/buyer/messages/print',{state:state})} style={{ cursor: "pointer" }}>Print</a> */}
                                            <span className="mx-3">|</span>
                                            <a onClick={() => navigate('/buyer/messages/inbox')} style={{ cursor: "pointer" }}>Back to inbox</a>
                                        </div>
                                    </div>
                                    <div className="msgBody">
                                        <div className="detailMsgs">
                                            <p className="msgDetail" dangerouslySetInnerHTML={{__html:state?.data?.message}} ></p>
                                            <p className="small">From: {state?.data?.from}</p>
                                            <p className="small">Sent: {moment(dateToLocal( state?.data?.createdDate)).format("MMM-DD-YY hh:ss")}</p>
                                        </div>
                                        {/* <div className="ads">
                                            <img src="images/ads-img.png" alt="" />
                                        </div> */}
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                </div>
            </main>
        </>
    )
}

export default Details
