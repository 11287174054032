import { AppThunk } from "../../store";
import { SaveDataFailed, SaveData } from "./slice";
import { fetchActiveListing, fetchOffersSeller, fetchSellerOfferUserList, postAcceptRejectOffer } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";
import { postSaveCounterOffer } from "../buyer/product/api";

export const saveCounterOffer =
  (params: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());

    try {
      const data: any = await postSaveCounterOffer(params);
      

      if (data.success === "N") {
        
        dispatch(SaveDataFailed(data));
      } else {
        
        dispatch(SaveData(data));
      }
    } catch (err) {
      
      dispatch(SaveDataFailed(err));
    }
    dispatch<any>(EndProgress());
  };
