import { AppThunk } from "../../store";
import { Orders } from "../../utils/types";
import { BuyerNameSuccess, BuyerNameFailed } from "./slice";
import { fetchBuyerNames } from "./api";
import { StartProgress } from "../progressBar/startProgress";
import { EndProgress } from "../progressBar/endProgress";

export const getBuyerNames = (): AppThunk => async (dispatch) => {
  try {
    dispatch<any>(StartProgress());

    const ordersData: any = await fetchBuyerNames();
    

    if (ordersData.success === "N") {
      
      dispatch(BuyerNameFailed(ordersData));
    } else {
      
      dispatch(BuyerNameSuccess(ordersData));
    }

    //return DashboardData
  } catch (err) {
    
    dispatch(BuyerNameFailed(err));
    //return err.toString()
  }
  dispatch<any>(EndProgress());
};
