import React from "react";
import { Link } from "react-router-dom";
interface Props {
  currentPage: string;
}
const ProdNavPanel = ({ currentPage }: Props) => {
  return (
    <div className="col-md-2">
      <div className="leftNavBar">
        <ul>
          <li>
            <Link
              style={
                currentPage == "Active"
                  ? { background: "#d7d7d7", fontWeight: "450", color: "#000" }
                  : {}
              }
              to={"/seller/listing"}
            >
              All listing
            </Link>
          </li>
          <li>
            <Link
              style={
                currentPage == "Unsold"
                  ? { background: "#d7d7d7", fontWeight: "450", color: "#000" }
                  : {}
              }
              to={"/seller/unsoldListing"}
            >
              Unsold
            </Link>
          </li>
          <li>
            <Link
              style={
                currentPage == "Sold"
                  ? { background: "#d7d7d7", fontWeight: "450", color: "#000" }
                  : {}
              }
              to={"/seller/soldListing"}
            >
              Sold
            </Link>
          </li>

          <li>
            <Link
              style={
                currentPage == "Drafts"
                  ? { background: "#d7d7d7", fontWeight: "450", color: "#000" }
                  : {}
              }
              to={"/seller/draftListing"}
            >
              Drafts
            </Link>
          </li>
          <li>
            <Link
              style={
                currentPage == "Scheduled"
                  ? { background: "#d7d7d7", fontWeight: "450", color: "#000" }
                  : {}
              }
              to={"/seller/scheduledListing"}
            >
              Scheduled
            </Link>
          </li>
          <li>
            <Link
              style={
                currentPage == "Ended"
                  ? { background: "#d7d7d7", fontWeight: "450", color: "#000" }
                  : {}
              }
              to={"/seller/endedListing"}
            >
              Ended
            </Link>
          </li>
        </ul>

        <ul>
          {/* <li>
            <a href="#">Learning Resources</a>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default ProdNavPanel;
