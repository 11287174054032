import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Sellers } from "../../utils/types";

type SellerState = {
    singleAddress   : Sellers['singleAddress'] | null,
    trackingDetails : Sellers['trackingDetails'] | null,
    isLoading       : boolean,
    error           : any | null,
}

const initialState:SellerState ={
    singleAddress   : null,
    trackingDetails : null,
    isLoading       : true,
    error           : null
};

const slice = createSlice({
    name : "seller",
    initialState,
    reducers:{
        _OnSuccess:(state,action:PayloadAction<Sellers['singleAddress'] | Sellers['trackingDetails'] | any>) =>{
            state.singleAddress   = (!action.payload.isTracking && action.payload);
            state.trackingDetails = (action.payload.isTracking && action.payload);
            state.error           = false;
            state.isLoading       = false;
        },

        _OnFailed:(state,action:PayloadAction<string | unknown>) =>{
            state.singleAddress   = null;
            state.trackingDetails = null;
            state.error           = action.payload;
            state.isLoading       = false;
        },
    },
});

export const sellerReducer = slice.reducer;
export const {
    _OnSuccess,
    _OnFailed
} = slice.actions