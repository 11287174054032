import { AppThunk } from "../../store";
import { forgetPasswordSuccess, forgetPasswordFailed } from "./slice";
import { forgetPassword } from "./api";

export const getForgetPassword =
  (Data: any): AppThunk =>
  async (dispatch) => {
    try {
      const UserData: any = await forgetPassword(Data);

      if (UserData.success === "N") {
        dispatch(forgetPasswordFailed(UserData));
      } else {
        dispatch(forgetPasswordSuccess(UserData));
      }
    } catch (err) {
      dispatch(forgetPasswordFailed(err));
    }
  };
