import { AppThunk } from "../../store";
import {
  ResetBidsUsers,
  ResetDeleteVariant,
  ResetOffersUsers,
  ResetReport,
  ResetSaveData,
  ResetSellerProductDetails,
} from "./slice";

export const reset =
  (data = ""): AppThunk =>
  async (dispatch) => {
    try {
      if (data === "saveData") dispatch(ResetSaveData());
      else if (data === "deleteVariant") dispatch(ResetDeleteVariant());
      else if (data === "offersUsers") dispatch(ResetOffersUsers());
      else if (data === "bidsUsers") dispatch(ResetBidsUsers());
      else if (data === "report") dispatch(ResetReport());

      else dispatch(ResetSellerProductDetails());
    } catch (err) {
      
    }
  };
