import React, { useEffect, useRef, useState } from "react";
import { DefaultLayout } from "../../../layout/DefaultLayout";
import BuyerHeader from "../BuyerHeader";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DefaultImage from "../../../assets/images/defaultImage.png";
import Plus from "../../../assets/images/plus.png"
import Minus from "../../../assets/images/minus.png"
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getSearchResult } from "../../../modules/buyer/product/getSearchResult";
import { RootState } from "../../../utils/types";
import { buyerProductSelector } from "../../../modules/buyer/product/selectors";
import { BASE_URLS } from "../../../utils/api.urls";
import { CommonResponseSelector } from "../../../modules/common/selectors";
import { getCommonData } from "../../../modules/common/getCommonData";
import { Rating } from "react-simple-star-rating";
import { activityResponseSelector } from "../../../modules/buyer/myEfindit/activity/selectors";
import { getRecentlyViewed } from "../../../modules/buyer/myEfindit/activity/getRecentlyViewed";
import CategoryTreeFilter from "./CategoryTreeFilter";
import { resetDetail } from "../../../modules/buyer/product/resetDetail";
import { setSavedSearch } from "../../../modules/buyer/product/setSavedSearch";
import { notify } from "../../../modules/notification/notify";
import { constant } from "../../../utils/constant";
import { resetSave } from "../../../modules/buyer/product/resetSave";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import BuyerFooter from "../buyerFooter";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {getWebPImage, imageOnError} from "../../../utils/helpers";

const SearchResult = () => {
  const { state } = useLocation();
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const commonResponse = useSelector<RootState, RootState["common"]>(
    CommonResponseSelector
  );
  const isFirstRender2 = useRef(true);
  const isFirstRender = useRef(true);
  const [pageView, setPageView] = useState("0");

  let searchResultResponse = useSelector<RootState, RootState["buyerProduct"]>(
    buyerProductSelector
  );

  let activityResponse = useSelector<RootState, RootState["activity"]>(
    activityResponseSelector
  );
  const [subCategory, setSubCategory] = useState<any>(state?.subCategory);
  const [toggleCategories, setToggleCategories] = useState<any>([]);

  const [filter, setFilter] = useState({
    search: state?.search ? state.search :state?.savedSearch?.search!==undefined ?state.savedSearch.search : "",
    auction:
    state?.savedSearch?.auction!==undefined ? state?.savedSearch?.auction : state?.advance?.auction === undefined ? "" : state?.advance?.auction,
    fixed:state?.savedSearch?.fixed!==undefined ? state?.savedSearch?.fixed : state?.advance?.fixed === undefined ? "" : state?.advance?.fixed,
    condition: state?.savedSearch?.condition === undefined ? "" : state?.savedSearch?.condition,
    startPrice: state?.savedSearch?.startPrice === undefined ? "" : state?.savedSearch?.startPrice,
    endPrice: state?.savedSearch?.endPrice === undefined ? "" : state?.savedSearch?.endPrice,
    buyingFormat: state?.savedSearch?.buyingFormat === undefined ? "" : state?.savedSearch?.buyingFormat,
    itemLocation: state?.savedSearch?.itemLocation === undefined ? "" : state?.savedSearch?.itemLocation,
    deliveryOption: state?.savedSearch?.deliveryOption === undefined ? "" : state?.savedSearch?.deliveryOption,
    userId: state?.savedSearch?.userId === undefined ? "" : state?.savedSearch?.userId,
    titleAndDescription:
    state?.savedSearch?.titleAndDescription!==undefined ? state?.savedSearch?.titleAndDescription : state?.advance?.titleAndDescription === undefined
        ? ""
        : state?.advance?.titleAndDescription,
    showItemPriceFrom:  
     state?.savedSearch?.showItemPriceFrom!==undefined ? state?.savedSearch?.showItemPriceFrom : state?.advance?.showItemPriceFrom === undefined
        ? ""
        : state?.advance?.showItemPriceFrom,
    minPrice:
    state?.savedSearch?.minPrice!==undefined ? state?.savedSearch?.minPrice :  state?.advance?.minPrice === undefined ? "" : state?.advance?.minPrice,
    maxPrice:
    state?.savedSearch?.maxPrice!==undefined ? state?.savedSearch?.maxPrice :   state?.advance?.maxPrice === undefined ? "" : state?.advance?.maxPrice,
    shortBy: state?.savedSearch?.shortBy=== undefined ? "" : state?.savedSearch?.shortBy,
    categoryId:
    state?.savedSearch?.categoryId!==undefined ? state?.savedSearch?.categoryId : state?.advance?.categoryId === undefined
        ? ""
        : state?.advance?.categoryId,
        brand: state?.savedSearch?.brand=== undefined ? "" : state?.savedSearch?.brand,
        freeReturns: state?.savedSearch?.freeReturns=== undefined ? "" : state?.savedSearch?.freeReturns,
        returnsAccepted: state?.savedSearch?.returnsAccepted=== undefined ? "" : state?.savedSearch?.returnsAccepted,
    soldItems:
    state?.savedSearch?.soldItems!==undefined ? state?.savedSearch?.soldItems : state?.advance?.soldItems === undefined ? "" : state?.advance?.soldItems,
    dealAndSavings: state?.savedSearch?.dealAndSavings === undefined ? "" : state?.savedSearch?.dealAndSavings,
    completedListings:
    state?.savedSearch?.completedListings!==undefined ? state?.savedSearch?.completedListings : state?.advance?.completedListings === undefined
        ? ""
        : state?.advance?.completedListings,
    endingWithIn: "",
    noOfBidsFrom:
    state?.savedSearch?.noOfBidsFrom!==undefined ? state?.savedSearch?.noOfBidsFrom :   state?.advance?.noOfBidsFrom === undefined
        ? ""
        : state?.advance?.noOfBidsFrom,
    minBiddingNo:
    state?.savedSearch?.minBiddingNo!==undefined ? state?.savedSearch?.minBiddingNo : state?.advance?.minBiddingNo === undefined
        ? ""
        : state?.advance?.minBiddingNo,
    maxBiddingNo:
    state?.savedSearch?.maxBiddingNo!==undefined ? state?.savedSearch?.maxBiddingNo : state?.advance?.maxBiddingNo === undefined
        ? ""
        : state?.advance?.maxBiddingNo,
        saleItem: state?.savedSearch?.saleItem === undefined ? "" : state?.savedSearch?.saleItem,
        bestOffer: state?.savedSearch?.bestOffer === undefined ? "" : state?.savedSearch?.bestOffer,
        freeInternationalShipping: state?.savedSearch?.freeInternationalShipping === undefined ? "" : state?.savedSearch?.freeInternationalShipping,
    new:state?.savedSearch?.new!==undefined ? state?.savedSearch?.new : state?.advance?.new === undefined ? "" : state?.advance?.new,
    used:state?.savedSearch?.used!==undefined ? state?.savedSearch?.used : state?.advance?.used === undefined ? "" : state?.advance?.used,
    listing:
    state?.savedSearch?.listing!==undefined ? state?.savedSearch?.listing : state?.advance?.listing === undefined ? "" : state?.advance?.listing,
    notSpecified:
    state?.savedSearch?.notSpecified!==undefined ? state?.savedSearch?.notSpecified :  state?.advance?.notSpecified === undefined
        ? ""
        : state?.advance?.notSpecified,
  });
  const [priceRadio, setPriceRadio] = useState(state?.savedSearch?.priceRadio!==undefined ? state?.savedSearch?.priceRadio :"");
  const [price, setPrice] = useState({
    min: state?.savedSearch?.minPrice!==undefined ? state?.savedSearch?.minPrice :"",
    max: state?.savedSearch?.maxPrice!==undefined ? state?.savedSearch?.maxPrice :"",
  });
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(16);
  const [currentPage, setcurrentPage] = useState(0);

  if (searchResultResponse.searchResult !== null && pageCount === 0) {
    setPageCount(
      Math.ceil(
        searchResultResponse?.searchResult?.data?.totalResult / itemCount
      )
    );
  }

  const handlePageChange = (selectedObject: any) => {
    setcurrentPage(selectedObject.selected);
  };
  const handleItemsChange = (event: any) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };
  const handleDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };
  const handleCategoryToggle =(id:any)=>{

    let data=[...toggleCategories]
    let index=toggleCategories.findIndex((item:any)=>item.id==id)
    data[index].show=!data[index].show;
    setToggleCategories(data)
}
useEffect(()=>{
  if(searchResultResponse?.searchResult)
  {
  let categories:any=[];
  searchResultResponse?.searchResult?.data?.categories?.forEach((element)=>{
      categories.push({
          id:element.id,
          show:false
      })
  })
  setToggleCategories(categories)

  }

},[searchResultResponse?.searchResult?.data?.categories])

  const handleSaveSearch = () => {
    const accessToken = localStorage.getItem("authorization");
    const role = localStorage.getItem("role");
    if (accessToken == null || role !== "Customer")
    {
      notify("Login as a customer to save this search", constant.ERROR)
    }
  else if(searchResultResponse?.searchResult?.data !== null )
   {
    let filters=JSON.stringify({...filter, priceRadio:priceRadio});
    let image=searchResultResponse?.searchResult?.data?.products[0]?.defaultImage;
    dispatch<any>(
      setSavedSearch({
        FilterSearches:{...filter, priceRadio:priceRadio},
        Image:image
      }))
   }
  };
  
  const callSearch = () => {
    dispatch<any>(
      getSearchResult({
        currentPage: currentPage + 1,
        rows: itemCount,
        search: filter.search,
        filter: filter,
      })
    ).then(() => {
      window.scrollTo(0, 0);

      if (currentPage === 0) setPageCount(0);
    });

    if (commonResponse?.common == null) dispatch<any>(getCommonData());
    dispatch<any>(
      getRecentlyViewed({
        currentPage: 1,
        rows: 10,
      })
    );
  };
  useEffect(() => {
    if (subCategory !== undefined && subCategory) {
      setFilter({ ...filter, categoryId: subCategory.id });
    }
  }, [subCategory]);
 
  useEffect(() => {
    if (searchResultResponse?.saveData != null) {
      if (searchResultResponse?.saveData.success == "Y") {
        notify(searchResultResponse?.saveData.message, constant.SUCCESS);
      } else if (searchResultResponse?.saveData.success == "N") {
        notify(searchResultResponse?.saveData.message, constant.ERROR);
      }
    }
    if (searchResultResponse?.saveDataError !== null) {
      notify(searchResultResponse?.saveDataError, constant.ERROR);
    }
    dispatch<any>(resetSave());
  }, [searchResultResponse?.saveData, searchResultResponse?.saveDataError]);


  useEffect(() => {
    if (!isFirstRender2.current) {
      callSearch();
    }
    isFirstRender2.current = false;
  }, [currentPage, itemCount, filter]);

  useEffect(() => {
    if (isFirstRender.current) {
      callSearch();
    }
    isFirstRender.current = false;
  }, []);
  useEffect(() => {
    if (searchResultResponse?.searchResult !== null) {
      window.history.replaceState({}, document.title);
    }
  }, [searchResultResponse]);
  const handleRadioClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    
    setPriceRadio(value);
    if (value === "five")
      {setFilter({
        ...filter,
        showItemPriceFrom: "true",
        maxPrice: "5",
        minPrice: "",
      });
      setPrice({min:"",max:"5"});
    
    }
    else if (value === "eight")
     { setFilter({
        ...filter,
        showItemPriceFrom: "true",
        minPrice: "5",
        maxPrice: "8",
      });
      setPrice({min:"5",max:"8"});
    
    }
    else if (value === "overEight")
    {  setFilter({
        ...filter,
        showItemPriceFrom: "true",
        minPrice: "8",
        maxPrice: "",
      });
      setPrice({min:"8",max:""});}
  };
  const handlePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    setPrice({ ...price, [name]: value });
  };

  const sliderSettings = {
    320: {
      slidesPerView: 2,
    },
    480: {
      slidesPerView: 2,
    },
    576: {
      slidesPerView: 3,
    },
    1200: {
      slidesPerView: 5,
      spaceBetween: 20
    }
  };

  const swiperRef = useRef();

  useEffect(()=>{
    setcurrentPage(0);
  },[filter])
  return (
    <DefaultLayout>
      <BuyerHeader
        filter={filter}
        setFilter={setFilter}
        setcurrentPage={setcurrentPage}
        subCategory={subCategory}
        option={subCategory}
      />
         <ToastContainer />
      
      <div className="mt-5"></div>

      <main className="min-section">
        <div className="container">
          <section className="withFilter">
            <div className="left-filter">
              <p className="filter-main-title">Shop by category</p>
              <div className="filters">
                <div className="filterList">
                
                  {toggleCategories.length>0 && searchResultResponse?.searchResult?.data?.categories?.map ((item, Index:number)=>
     {   
        
        let catItem=toggleCategories?.find((cat:any)=>cat.id==item.id)

        return<>  
            
          {item.childs &&  
              <div className="filterExpand">
              <button className="t" onClick={()=>handleCategoryToggle(item.id)}>
              <img src={catItem?.show? Minus: Plus} alt=""/>
              </button>
              </div> }
              <div className="filter-title" style={{cursor:"pointer"}} onClick={()=> setSubCategory(item)} >
                {item.name}
              </div>
          {catItem?.show &&    <div className="filter-drop open">
                <ul>
                    {
                        item.childs?.map((data:any,index:any)=>
                  <li key={index}><div style={{cursor:"pointer"}} onClick={()=> setSubCategory(data)}>- {data.name}</div></li>
                        
                        )
                    }
                  
                </ul>
              </div>}
           
              </> }   )}
             
                </div>
               

                <div className="filterList">
                  <button className="toggleBtn">
                    <img src="images/Rounded Rectangle 1 copy 2.png" alt="" />
                  </button>
                  <div className="filter-title">
                    <strong>Price</strong>
                  </div>
                  <div className="filter-drop">
                    <ul className="p-0">
                      <li>
                        
                        <div className="radio">
                          <input
                            id="five"
                            type="radio"
                            value="five"
                            name="five"
                            checked={priceRadio === "five" ? true : false}
                            onChange={(e) => handleRadioClick(e)}
                          />
                          <label htmlFor="five" className="radio-label">
                            Under $5.00
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="radio">
                          <input
                            id="eight"
                            value="eight"
                            name="eight"
                            onChange={(e) => handleRadioClick(e)}
                            type="radio"
                            checked={priceRadio === "eight" ? true : false}
                          />
                          <label htmlFor="eight" className="radio-label">
                            $5.00 to $8.00
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="radio">
                          <input
                            id="overEight"
                            value="overEight"
                            name="overEight"
                            onChange={(e) => handleRadioClick(e)}
                            checked={priceRadio === "overEight" ? true : false}
                            type="radio"
                          />
                          <label htmlFor="overEight" className="radio-label">
                            Over $8.00
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="min-max">
                          <input
                            type="number"
                            min="0"
                            placeholder="min"
                            name="min"
                            value={price.min}
                            onChange={handlePrice}
                          />
                          <span>-</span>
                          <input
                            type="number"
                            min="0"
                            placeholder="max"
                            name="max"
                            value={price.max}
                            onChange={handlePrice}
                          />
                          <button
                            className="go"
                            onClick={() => {
                              setPriceRadio("");
                              setFilter({
                                ...filter,
                                showItemPriceFrom:price.min === "" && price.max === "" ? "false" : "true",
                                minPrice:
                                  price.min && Number(price.min) > 0
                                    ? price.min
                                    : "",
                                maxPrice:
                                  price.max && Number(price.max) > 0
                                    ? price.max
                                    : "",
                              });
                            }}
                          
                          >
                            go
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

              

                
              </div>

              
            </div>

            <div className="right-wrapper">
             
              <section className="prod-filter">
                <div className="top">
                  <div className="row justify-content-between">
                    <div className="col-md-auto">
                      
                    </div>
                    <div className="col-md-auto">
                      <div className="row">
                        <div className="col-auto">
                          <div className="sort-select">
                            <label htmlFor="">sort</label>
                            <div className="selectBox">
                              <select name="shortBy" id="" value={filter.shortBy} onChange={(e)=>handleDropdownChange(e)}>
                                <option value="">Best Match</option>
                                <option value="LowestPrice">Lowest Price</option>
                                <option value="HighestPrice">Highest Price</option>
                                <option value="NewlyListed">Newly Listed</option>
                                <option value="BidsFewestFirst">Number of bids:fewest first</option>
                                <option value="BidsMostFirst">Number of bids:most first</option>


                               
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                        <div className="sort-select view-select">
                          <label htmlFor="">view</label>
                          <div className={`selectBox${pageView=="0" ?" css-list":""}`}>
                            <select name="" id="" value={pageView} onChange={(e:any)=>setPageView(e.target.value)}>
                              <option value="0" >list</option>
                              <option value="1" >grid</option>

                            </select>
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div className="result-found">
                    <span>
                      <strong className="fw700">
                        {searchResultResponse?.searchResult?.data?.totalResult}
                      </strong>{" "}
                      results found
                    </span>
                    <div className="save-search" style={{cursor:"pointer",
                  color:"blue"}} onClick={handleSaveSearch}>
                      <img src="images/833472 copy.png" alt="" /> Save this
                      search
                    </div>
                   
                  </div>
                </div>
                <div className="bottom">
                  <div className="sort-select auto-select">
                    <div className="selectBox">
                      <select
                        name="condition"
                        id="condition"
                        value={filter.condition}
                        onChange={(e) => handleDropdownChange(e)}
                      >
                        <option value="">Condition</option>
                        {commonResponse?.common?.condition?.map(
                          (item, Index) => (
                            <option value={item.value} key={item.key}>
                              {item.label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                 

                  <div className="sort-select auto-select">
                    <div className="selectBox">
                      <select
                        name="buyingFormat"
                        id="buyingFormat"
                        value={filter.buyingFormat}
                        onChange={(e) => handleDropdownChange(e)}
                      >
                        <option value="">Buying Format</option>
                        {commonResponse?.common?.format?.map((item, Index) => (
                          <option value={item.value} key={item.key}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                 
               
               
                       
                   
                </div>
              </section>

              <section className="deals-group search-result-list">
                <div className={`deal-group-list ${pageView=="0" ? "one-col" : "four-col"}`}>
                  <div className="wrap">
                    {searchResultResponse?.searchResult?.data?.products?.map(
                      (product, Index) => (
                        <div
                          className="recent-list-item" key={Index}
                         
                        >
                          <i className="timer-icon"></i>
                          <i className="blue-star"></i>
                          <figure className="recent-media"  onClick={() =>
                            dispatch<any>(
                              resetDetail()).then(()=>navigate(`/buyer/productDetail/${product.productId}`))         
                          }>
                            <div>
                             

                              <picture>
                                <source srcSet={getWebPImage(product.defaultImage)} type="image/webp"/>
                                <LazyLoadImage
                                  src={BASE_URLS.AWS_URL +
                                    "/Thumb" +
                                    product.defaultImage}
                                    onError={imageOnError}
                                  />
                              </picture>
                            </div>

                          </figure>
                          <div className="recent-details">
                            <div className="recent-title">
                              {product.title.substring(0, 30)}
                              {product.title.length > 30 && "..."}
                            </div>
                            <div className="fi-stars">
                              <Rating
                                initialValue={product.rating}
                                readonly={true}
                                // allowFraction={true}
                                /* Available Props */
                                size={16}
                              />
                            </div>
                            <div className="recent-price">
                             US $
                              {product.buyNowPrice
                                ? product.buyNowPrice
                                : product.actualPrice}
                            </div>
                            {product.actualPrice && (
                              <div className="recent-price-small">
                                <span className="price-cut">
                                <del> US ${product.actualPrice}</del>
                                </span>
                                <span>|</span>
                                <span className="price-bold">
                                  {product.offer}% OFF
                                </span>
                              </div>
                            )}
                           
                            <div className="fi-stock-status small">
                              {product.quantity && product.quantity >= constant?.ONLY_FEW_LEFT && product.quantity <= constant?.ALMOST_GONE_QUANTITY
                                ? "Almost Gone"
                                : product.quantity < constant?.ONLY_FEW_LEFT
                                ? "Only " + product.quantity + " left"
                                : ""}
                          <p>     {product.sold
                                ? product.sold +" Sold"
                                : 
                                "0 Sold"
                                }</p> 
                            </div>
                          </div>
                        </div>
                      )
                    )}
                    {searchResultResponse?.searchResult?.data == null && (
                      <h3>No Results Found</h3>
                    )}
                  </div>

                  <nav className="pagination-block">
                    <p className="total">
                      {searchResultResponse?.searchResult?.data?.totalResult}{" "}
                      items total
                    </p>

                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      forcePage={currentPage}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "pagination justify-content-center mt-4"
                      }
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                    <div className="customSelect">
                      <label htmlFor="">Items per page :</label>
                      <div className="selectBox">
                        <select name="" id="" onChange={handleItemsChange}>
                        <option value="16">16</option>
                            <option value="24">24</option>

                            <option value="32">32</option>
                        </select>
                      </div>
                    </div>
                  </nav>
                </div>
              </section>

              {activityResponse?.recentlyViewed?.data?.products? (
              <section className="recently-view-section mb-5">
                <div className="h2-block pt-0">
                  <h2 className="h2-section-title">Recently View</h2>
                </div>
                <div className="recent-view-container">
                  <div className="prev-slide">
                    <i className="prev-icon"></i>
                  </div>
                  <div className="next-slide">
                    <i className="next-icon"></i>
                  </div>

                  <div className="recent-listing-block">
                  <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={20}
                        slidesPerView={5}
                        breakpoints={sliderSettings}
                        navigation={{
                          nextEl: '.next-slide',
                          prevEl: '.prev-slide',
                        }}
                        pagination={{ clickable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log("slide change")}
                      >
                        {activityResponse?.recentlyViewed?.data?.products?.map(
                          (item, Index) => (
                            <SwiperSlide key={Index}
                              className="recentListSwiper"
                              onClick={() =>
                                dispatch<any>(resetDetail()).then(()=>navigate(`/buyer/productDetail/${item.productId}/${item.variantId}`))
                              }
                            >
                              <div className="recent-list-item">
                                
                                <figure className="recent-media">
                                  <picture>
                                    <source srcSet={getWebPImage(item.defaultImage)} type="image/webp"/>
                                    <LazyLoadImage
                                      className="prod-img"
                                      style={{ height: "240px" }}
                                      src={BASE_URLS.AWS_URL +
                                        "/Thumb" +
                                        item.defaultImage}
                                      onError={imageOnError}
                                      />
                                  </picture>
                                </figure>

                                <div className="recent-details">
                                  <div className="recent-price">
                                   US $
                                    {item.buyNowPrice
                                      ? item.buyNowPrice
                                      : item.actualPrice}
                                  </div>
                                  {item.buyNowPrice && (
                                    <div className="recent-price-small">
                                      <span className="price-cut">
                                        <del>US ${item.actualPrice}</del>
                                      </span>
                                      <span>|</span>
                                      <span className="price-bold">
                                        {item.offer}% OFF
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </SwiperSlide>
                          )
                        )}
                      </Swiper>
                  </div>
                </div>
              </section>)
              : ""}
            </div>
          </section>
        </div>
      </main>

      <BuyerFooter />
      
    </DefaultLayout>
  );
};

export default SearchResult;
