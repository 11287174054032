import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import { getProductReview } from '../../../modules/buyer/myEfindit/activity/getProductReview';
import { resetActivity } from '../../../modules/buyer/myEfindit/activity/resetActivity';
import { activityResponseSelector } from '../../../modules/buyer/myEfindit/activity/selectors';
import { setVoteProductReview } from '../../../modules/buyer/myEfindit/activity/setVoteProductReview';
import { dateToLocal } from '../../../modules/date/date';
import { RootState } from '../../../utils/types';

interface props{
    Id:string
    product:any;
}

const ReviewSection = ({Id, product}:props) => {
    let activityResponse = useSelector<RootState, RootState["activity"]>(
        activityResponseSelector
      );
      const dispatch = useDispatch();
      const navigate = useNavigate();


      useEffect(() => {
        if (Id !== undefined) {
       
          dispatch<any>(getProductReview({productId:Id,
            currentPage:  1,
            rows: 4,
          }))
    
        }
      }, [Id]);

      useEffect(() => {
        if (activityResponse?.saveDataProduct?.success==="Y") {
       
          dispatch<any>(getProductReview({productId:Id,
            currentPage:  1,
            rows: 4,
          })).then(()=>dispatch<any>(resetActivity()))
          
        }
      }, [activityResponse?.saveDataProduct]);

      const handleVote =(id:number, type:boolean)=>{
        
        dispatch<any>(setVoteProductReview({reviewId: id,
        liked: type
          }))

      }

  return (
      <section className="ratings">
              <div className="row justify-content-between align-items-center">
                <div className="col-auto">
                  <h3 className="mb-0">Ratings and reviews</h3>
                </div>
                <div className="col-auto">
                  {/* <a href="" className="writeReviewBtn">
                    Write a review{" "}
                    <img src="images/Shape 5 copy 2.png" alt="" />
                  </a> */}
                </div>
              </div>

              <div className="ratingBlock">
              {activityResponse?.productReview?.data?.averageRating ? <div className="row">
                  <div className="col-xl-3">
                    <div className="points">{activityResponse?.productReview?.data?.averageRating}</div>
                    <Rating
                        initialValue={
                          activityResponse?.productReview?.data?.averageRating
                        }
                        readonly={true}
                        // onClick={(rate) =>
                        //   handleRating(rate, "productRating")
                        // }
                        /* Available Props */
                        size={25}
                    />
                    <div className="num">{activityResponse?.productReview?.data?.totalProductRating} product ratings</div>
                  </div>
                  <div className="col-xl-9">
                    <div className="peopleRating">
                      <div className="rating-display-block">

                        <div className="rating-row">
                          <div className="rating-row-icon"></div>
                          <div className="rating-row-number">5</div>
                          <div className="rating-bg">
                            <span className="rating-percentage" style={{width: `${(activityResponse?.productReview?.data?.ratedStar?.star5/activityResponse?.productReview?.data?.totalProductRating)*100}%`}}></span>
                          </div>
                          <div className="rating-values">{activityResponse?.productReview?.data?.ratedStar?.star5 ? activityResponse?.productReview?.data?.ratedStar?.star5 : 0}</div>
                        </div>

                        <div className="rating-row">
                          <div className="rating-row-icon"></div>
                          <div className="rating-row-number">4</div>
                          <div className="rating-bg">
                            <span className="rating-percentage" style={{width: `${(activityResponse?.productReview?.data?.ratedStar?.star4/activityResponse?.productReview?.data?.totalProductRating)*100}%`}}></span>
                          </div>
                          <div className="rating-values">{activityResponse?.productReview?.data?.ratedStar?.star4 ? activityResponse?.productReview?.data?.ratedStar?.star4 : 0}</div>
                        </div>

                        <div className="rating-row">
                          <div className="rating-row-icon"></div>
                          <div className="rating-row-number">3</div>
                          <div className="rating-bg">
                            <span className="rating-percentage" style={{width: `${(activityResponse?.productReview?.data?.ratedStar?.star3/activityResponse?.productReview?.data?.totalProductRating)*100}%`}}></span>
                          </div>
                          <div className="rating-values">{activityResponse?.productReview?.data?.ratedStar?.star3 ? activityResponse?.productReview?.data?.ratedStar?.star3 : 0}</div>
                        </div>

                        <div className="rating-row">
                          <div className="rating-row-icon"></div>
                          <div className="rating-row-number">2</div>
                          <div className="rating-bg">
                            <span className="rating-percentage" style={{width: `${(activityResponse?.productReview?.data?.ratedStar?.star2/activityResponse?.productReview?.data?.totalProductRating)*100}%`}}></span>
                          </div>
                          <div className="rating-values">{activityResponse?.productReview?.data?.ratedStar?.star2 ? activityResponse?.productReview?.data?.ratedStar?.star2 : 0}</div>
                        </div>

                        <div className="rating-row">
                          <div className="rating-row-icon"></div>
                          <div className="rating-row-number">1</div>
                          <div className="rating-bg">
                            <span className="rating-percentage" style={{width: `${(activityResponse?.productReview?.data?.ratedStar?.star1/activityResponse?.productReview?.data?.totalProductRating)*100}%`}}></span>
                          </div>
                          <div className="rating-values">{activityResponse?.productReview?.data?.ratedStar?.star1 ? activityResponse?.productReview?.data?.ratedStar?.star1 : 0}</div>
                        </div>

                      </div>  
                      <img src="images/people-rating.png" alt="" />
                    </div>
                  </div>
                </div> :
                <h1>No Reviews Found</h1>}
              </div>

{activityResponse?.productReview?.data?.averageRating &&           <div  className='d-flex justify-content-between'>  <h3>Most relevant reviews</h3>
            {activityResponse?.productReview?.data?.totalResult>0 && <h3 onClick={()=>   navigate(
                                          "/buyer/productReviews",
                                          {
                                            state: {product:product,
                                                      Id:Id},
                                          }
                                        )} style={{cursor:"pointer", color:"blue"}}>See all {activityResponse?.productReview?.data?.totalResult} {activityResponse?.productReview?.data?.totalResult > 1 ? "reviews" :"review"}</h3>} </div> }
              <div className="reviews"> 
               {
               activityResponse?.productReview?.data?.ratingAndReviews?.map((review, Index)=>
               <div className="row mb-5">
               <div className="col-xl-3">
                 <div className="userRating">
                 <Rating
                        initialValue={
                          review?.star
                        }
                        readonly={true}
                        // onClick={(rate) =>
                        //   handleRating(rate, "productRating")
                        // }
                        /* Available Props */
                        size={15}
                    />
                 </div>
                 <div className="user">
                   by <span style={{color:"blue"}} className='text-break'>{review?.name.length>20 ? review?.name.substring(0,20)+"...." :review?.name}</span>
                 </div>
                 <div className="date">{review?.createdOn ? dateToLocal(review?.createdOn) : ""}</div>
               </div>
               <div className="col-xl-9">
                 <div className="title text-break">
                   {review.heading}
                 </div>
                 <p className='text-break'>
                  {review.description}
                 </p>

                 <div className="d-flex">
                   <div className="like" style={{cursor:"pointer"}} onClick={()=>handleVote(review?.reviewId, true)}>
                     <button ></button>
                     <span>({review.liked})</span>
                   </div>
                   <div className="dislike" style={{cursor:"pointer"}}  onClick={()=>handleVote(review?.reviewId, false)} >
                     <button ></button>
                     <span>({review?.unLiked})</span>
                   </div>
                 </div>
               </div>
             </div>
               
               )}
               
              

             
              </div>
            </section>
  )
}

export default ReviewSection