import React from 'react';
import { Rating } from "react-simple-star-rating";
import moment from 'moment';
import { dateToLocal } from '../../../modules/date/date';
import CardList from "../../../assets/images/card-list.png"

interface Props {
  product: any;
}
const AuctionDetails = ({product}: Props) => {

    function ShowDaysAndHours(date:any){

        const startTime = moment.utc();
        let end = moment(date); 
        let duration = moment.duration(end.diff(startTime));
        let hours = duration.asHours();

        let Days = Math.floor(hours/24);
        let Remainder = hours % 24;
        let Hours = Math.floor(Remainder);

        let dayText = Days == 1 ?" Day ":" Days ";

        let returnTime = Days == 0 ? Hours + " Hours" : Days + dayText + Hours + " Hours"

        return  returnTime;
    }


  return (
    <div className="productText">
        <h2>
            {
                product?.productDetails?.data?.productDetail?.title
            }
        </h2>
        <p className="condition">
            Condition:{" "}
            {
                product.productDetails?.data?.productDetail.condition
            }
        </p>
        <div className="productRating">
            <div className="productStars">
                <Rating
                    initialValue={
                        product.productDetails?.data?.productDetail.productRating
                    }
                    readonly={true}
                    // onClick={(rate) =>
                    //   handleRating(rate, "productRating")
                    // }
                    /* Available Props */
                    size={17}
                />
            </div>
            <div className="reviews">
            <span style={{
                            color:"#3453c8",
                            fontSize:12
                        }}>
                    {product.productDetails?.data?.productDetail.totalProductRating
                        ? product.productDetails?.data?.productDetail.totalProductRating
                        : 0}{" "}
                    Product rating
                </span>
            </div>
        </div>

        <div className="prodOverview">
            {/* <div className="row">
                <div className="col-lg-3">
                    <div className="smallTitle">Condition :</div>
                </div>
                <div className="col-lg-9">
                    <strong>
                        {
                            product.productDetails?.data?.productDetail.condition
                        }
                    </strong>
                 
                </div>
            </div> */}
            <div className="row">
                <div className="col-lg-3">
                    <div className="smallTitle">Time Left :</div>
                </div>
                <div className="col-lg-9">
                 {moment.utc().isAfter(product.productDetails?.data?.productDetail.listingExpiryDate)  ? <strong  style={{color:"red"}}>Expired</strong> :   <strong className="boldText">
                        { ShowDaysAndHours(product.productDetails?.data?.productDetail.listingExpiryDate)} {"| "}
                        {
                           
                            product.productDetails?.data?.productDetail.listingExpiryDate && moment(product.productDetails?.data?.productDetail.listingExpiryDate).format('dddd, HH:mm A')
                        }
                    </strong>}
                </div>
            </div>
        </div>
        <div className="prodOverview">
         { moment.utc().isBefore(product.productDetails?.data?.productDetail.listingExpiryDate) &&  <div className="row">
                <div className="col-lg-3">
                    <div className="smallTitle">Current bid :</div>
                </div>
                <div className="col-lg-9">
                    <div className="priceTag">
                        $
                        {product.productDetails?.data?.productDetail.buyNowPrice
                                ? product.productDetails?.data?.productDetail.buyNowPrice
                                : product.productDetails?.data?.productDetail.actualPrice}
                        <a href="" className="i-icon">
                            <img src="images/i-icon.png" alt="" />
                        </a>
                    </div>
                    <div className="totalBid">{product.productDetails?.data?.productDetail.totalBid} Bids</div>
                </div>
            </div>}
        </div>
        <div className="prodOverview sold">
            <div className="row">
                <div className="col-lg-6">
                    <div className="smallTitle soldItems">
                        {product.productDetails?.data?.productDetail.sold
                            ? product.productDetails?.data?.productDetail.sold
                            : 0}{" "}
                        sold
                    </div>
                </div>
                {/* <div className="col-lg-4">
                    <div className="smallTitle">
                        Same day shipping check
                    </div>
                </div> */}
                <div className="col-lg-6">
                    <div className="smallTitle">
                        {
                            product.productDetails?.data?.productDetail?.returnInDays
                        }
                        -day returns.{product.productDetails?.data?.productDetail?.buyerPaysForReturnShipping &&
                            "Buyer pays for return shipping."}
                    </div>
                </div>
            </div>
        </div>
        <div className="prodOverview border-0">
            <div className="row">
                <div className="col-lg-3">
                    <div className="smallTitle">Shipping:</div>
                </div>
                <div className="col-lg-9">
                {product.productDetails?.data?.productDetail?.shippingCharges  && <strong>
                            ${ product.productDetails?.data?.productDetail?.shippingCharges[0].price} 
                        </strong>}
                </div>
            </div>
        </div>
        <div className="prodOverview border-0 pt-0">
            <div className="row">
                <div className="col-lg-3">
                    <div className="smallTitle">Delivery:</div>
                </div>
                <div className="col-lg-9">
                    <strong>Estimated between</strong>{" "}
                    <strong className="boldText">
                            { product.productDetails?.data?.productDetail.estimatedDeliveryDateAndTime ? moment(dateToLocal(product.productDetails?.data?.productDetail.estimatedDeliveryDateAndTime)).format("DD MMM YYYY") : "Not Deliverable"}
                        </strong>
                    <p>
                    Seller ships within 1 day after
                           receiving cleared payment
                    </p>
                </div>
            </div>
        </div>
        <div className="prodOverview border-0 pt-0">
            <div className="row">
                <div className="col-lg-3">
                    <div className="smallTitle">Payments :</div>
                </div>
                <div className="col-lg-9">
                    <div>
                    <img src={CardList} alt="" />
                    </div>
                </div>
            </div>
        </div>
        {/* <div className="prodOverview border-0 pt-0">
            <div className="row">
                <div className="col-lg-3">
                    <div className="smallTitle">Returns :</div>
                </div>
                <div className="col-lg-9">
                    <strong className="boldText">
                        {
                            product.productDetails?.data?.productDetail?.returnInDays
                        }{" "}
                        day returns.
                        {product.productDetails?.data?.productDetail?.buyerPaysForReturnShipping &&
                            "Buyer pays for return shipping."}
                    </strong>{" "}
                   
                </div>
            </div>
        </div> */}
    </div>
)
}

export default AuctionDetails
