import React, { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { saveSellFaster } from "../../../modules/sellerProduct/saveSellFaster";
import { useDispatch, useSelector } from "react-redux";
import { getSellerProductDetails } from "../../../modules/sellerProduct/getSellerProductDetails";
import { RootState } from "../../../utils/types";
import { SellerProductResponseSelector } from "../../../modules/sellerProduct/selector";
import { notify } from "../../../modules/notification/notify";
import { constant } from "../../../utils/constant";
import { reset } from "../../../modules/sellerProduct/reset";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface Props {
  nextStep: any;
  setProductId: any;
  prevStep: any;
  productId: any;
  setstep?: any;
}

const SellFaster = ({
  prevStep,
  nextStep,
  setProductId,
  productId,
  setstep,
}: Props) => {
  interface sellFasterValues {
    productId: string;
    volumePricing: boolean;
    buy2ItemDiscount: number;
    buy3ItemDiscount: number;
    buy4ItemDiscount: number;
    Isdraft: boolean;
  }
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let submitAction: string | undefined = undefined;
  const [draft, setDraft] = useState(false);
  const sellerProductResponse = useSelector<
    RootState,
    RootState["sellerProduct"]
  >(SellerProductResponseSelector);
  
  const [sellFasterData, setSellFasterData] = useState<sellFasterValues>({
    productId:
      localStorage.getItem("productId") !== null
        ? localStorage.getItem("productId")
        : productId,
    volumePricing: false,
    buy2ItemDiscount: 10,
    buy3ItemDiscount: 20,
    buy4ItemDiscount: 30,
    Isdraft: false,
  });

  let percentArr = [10, 20, 30, 40, 50, 60];
  const isFirstRender = useRef(true);
 

  const submitFormData = (values: any) => {
    // checking if value of first name and last name is empty show error else take to step 2

    // let formData = new FormData();
    // formData.append("listing", JSON.stringify(values));
    dispatch<any>(saveSellFaster(JSON.stringify(values)));

    // setProductId("41F84D18-02D5-4835-BDE0-5D22740FB8C8")
    // nextStep();
  };

  const onCheckboxChange = (e: any) => {
    let value = e.target.checked ? true : false;
    setSellFasterData({
      ...sellFasterData,
      [e.target.name]: value,
    });
  };

  const onChange = (e: any) => {
    setSellFasterData({
      ...sellFasterData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (isFirstRender.current) {
      if (sellerProductResponse.sellerProductDetails === null)
        dispatch<any>(getSellerProductDetails(sellFasterData.productId));
    }
    isFirstRender.current = false;
  }, []);

  useEffect(() => {
    if (sellerProductResponse?.sellerProductDetails?.value?.length > 0) {
      setSellFasterData({
        ...sellFasterData,
        productId:
          localStorage.getItem("productId") !== null
            ? localStorage.getItem("productId")
            : productId,

        volumePricing: sellerProductResponse?.sellerProductDetails?.value[0]
          .VolumePricing
          ? sellerProductResponse?.sellerProductDetails?.value[0].VolumePricing
          : false,
        buy2ItemDiscount: sellerProductResponse?.sellerProductDetails?.value[0]
          .Buy2ItemDiscount
          ? sellerProductResponse?.sellerProductDetails?.value[0]
              .Buy2ItemDiscount
          : 10,
        buy3ItemDiscount: sellerProductResponse?.sellerProductDetails?.value[0]
          .Buy3ItemDiscount
          ? sellerProductResponse?.sellerProductDetails?.value[0]
              .Buy3ItemDiscount
          : 20,
        buy4ItemDiscount: sellerProductResponse?.sellerProductDetails?.value[0]
          .Buy4ItemDiscount
          ? sellerProductResponse?.sellerProductDetails?.value[0]
              .Buy4ItemDiscount
          : 30,
        // Isdraft: sellerProductResponse?.sellerProductDetails?.value[0].DomesticShipping ?sellerProductResponse?.sellerProductDetails?.value[0].DomesticShipping  : false,
      });
    }
  }, [sellerProductResponse.sellerProductDetails]);

  useEffect(() => {
    // dispatch<any>(EndProgress());
    if (sellerProductResponse.saveData != null) {
      if (sellerProductResponse.saveData.success == "Y") {
        notify(sellerProductResponse.saveData.message, constant.SUCCESS);
        // resetForm();
        // setSellingData({
        //   ...sellingData,
        //   productId: sellerProductResponse.saveData.productId,
        // });
        if(draft)
        {
         navigate("/seller/draftListing");
        }
        else
        {
        navigate("/seller/listing");
        }
      } else if (sellerProductResponse.saveData.success == "N") {
        notify(sellerProductResponse.saveData.message, constant.ERROR);
      }
      dispatch<any>(reset("saveData"));

      // dispatch<any>(resetBanner());
    }
  }, [sellerProductResponse.saveData]);

  const goToPrevious = (e: any) => {
    e.preventDefault();

    prevStep();
    // prevStep();
  };
  return (
    <>
      <ToastContainer />

      <div className="tabs-nav-block">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <div
              className="nav-link "
              style={{
                cursor: "pointer",
              }}
              onClick={() => setstep(1)}
            >
              Listing detail
            </div>
          </li>
          <li className="nav-item">
            <div
              className="nav-link"
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                sellerProductResponse?.sellerProductDetails &&
                sellerProductResponse?.sellerProductDetails?.value[0]
                  ?.Format !== null &&
                setstep(2)
              }
            >
              Selling details
            </div>
          </li>
          <li className="nav-item">
            <div
              className="nav-link"
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                sellerProductResponse?.sellerProductDetails &&
                sellerProductResponse?.sellerProductDetails?.value[0]
                  ?.DomesticCourierServiceId !== null &&
                setstep(3)
              }
            >
              Shipping details
            </div>
          </li>
          <li className="nav-item">
            <div
              className="nav-link active"
              style={{
                cursor: "pointer",
              }}
              onClick={() =>
                sellerProductResponse?.sellerProductDetails &&
                sellerProductResponse?.sellerProductDetails?.value[0]
                  ?.VolumePricing &&
                setstep(4)
              }
            >
              Sell it faster
            </div>
          </li>
        </ul>
      </div>

      <Formik
        initialValues={sellFasterData}
        enableReinitialize={true}
        onSubmit={(values) => {
          let data = { ...values };
          if (submitAction === "primary") {
            data.Isdraft = true;
            submitFormData(data);
            setDraft(true);

          } else {
            // set state to show warning message
            submitFormData(values);
          }
        }}
      >
        {({ handleSubmit, errors, touched }) => (
          <>
            <Form>
              <div className="seller-form-block">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="label-seller">Volume pricing</label>
                        <div className="item-sub-group">
                          <div className="custom-control custom-checkbox custom-control-inline checkbox-xl">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck1"
                              name="volumePricing"
                              onChange={onCheckboxChange}
                              checked={
                                sellFasterData.volumePricing === true
                                  ? true
                                  : false
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck1"
                            >
                              Offer a discount when buyers purchase more than
                              one item at a time.
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="sell-item">
                        <div className="sell-item-text">
                          Buy 2 and save
                        </div>
                        <select
                          className="form-control input-seller"
                          name="buy2ItemDiscount"
                          onChange={onChange}
                          value={sellFasterData.buy2ItemDiscount}
                        >
                          {percentArr.map((percent: number, Index: number) => (
                            <option value={percent} key={Index}>
                              {percent}%
                            </option>
                          ))}
                        </select>
                        <div className="sell-item-text">off each item</div>
                      </div>

                      <div className="sell-item">
                        <div className="sell-item-text">
                          Buy 3 and save
                        </div>
                        <select
                          className="form-control input-seller"
                          name="buy3ItemDiscount"
                          onChange={onChange}
                          value={sellFasterData.buy3ItemDiscount}
                        >
                          {percentArr.map((percent: number, Index: number) => (
                            <option value={percent} key={Index}>
                              {percent}%
                            </option>
                          ))}
                        </select>
                        <div className="sell-item-text">off each item</div>
                      </div>

                      <div className="sell-item">
                        <div className="sell-item-text">
                          Buy 4 or more and save
                        </div>
                        <select
                          className="form-control input-seller"
                          name="buy4ItemDiscount"
                          onChange={onChange}
                          value={sellFasterData.buy4ItemDiscount}
                        >
                          {percentArr.map((percent: number, Index: number) => (
                            <option value={percent} key={Index}>
                              {percent}%
                            </option>
                          ))}
                        </select>
                        <div className="sell-item-text">off each item</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="seller-bottom-block">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="fees-block">
                        <div className="fee-text">
                          Fees:<i className="fee-icon"></i>{" "}
                          <span className="fee-price">{sellerProductResponse?.platformFees?.data?.platFormFee}%</span>
                        </div>
                      </div>
                      <div className="seller-bottom-buttons">
                        <div className="seller-btm-text">
                          By selecting List item, you agree to pay the above
                          fees; accept the eFindit{" "}
                          <a href="#">User agreement</a>,{" "}
                          <a href="#">Payments terms</a> of Use and Marketing
                          terms of Service; acknowledge reading the User Privacy
                          Notice; and assume full responsibility for the item
                          offered and the content of your listing.
                        </div>
                      </div>

                      <div className="seller-button">
                        <button className="list-button" type="submit">
                          List item
                        </button>
                        {/* <button className="list-button bg-none">Preview</button> */}
                        <button
                          className="list-button bg-none"
                          onClick={goToPrevious}
                        >
                          Back
                        </button>
                        <button
                          type="button"
                          className="list-button bg-none"
                          onClick={() => {
                            submitAction = "primary";
                            handleSubmit();
                          }}
                        >
                          Save as draft
                        </button>
                        {/* <button className="list-button bg-none">Cancel</button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default SellFaster;
