import React, { useEffect, useState } from "react";
import { DefaultLayout } from "../../../layout/DefaultLayout";
import BuyerHeader from "../BuyerHeader";
import { RootState } from "../../../utils/types";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import { BASE_URLS } from "../../../utils/api.urls";
import DefaultImage from "../../../assets/images/defaultImage.png";

import { Rating } from "react-simple-star-rating";
import { useDispatch, useSelector } from "react-redux";
import { buyerSetFeedback } from "../../../modules/buyer/myEfindit/activity/buyerSetFeedback";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { activityResponseSelector } from "../../../modules/buyer/myEfindit/activity/selectors";
import { notify } from "../../../modules/notification/notify";
import { constant } from "../../../utils/constant";
import { resetActivity } from "../../../modules/buyer/myEfindit/activity/resetActivity";
import { buyerGetFeedback } from "../../../modules/buyer/myEfindit/activity/buyerGetFeedback";
import { setProductReview } from "../../../modules/buyer/myEfindit/activity/setProductReview";
import { getSingleProductReview } from "../../../modules/buyer/myEfindit/activity/getSingleProductReview";
import BuyerFooter from "../buyerFooter";

const LeaveFeedback = () => {
  const { state }: any = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if(state)
      dispatch<any>(buyerGetFeedback(state?.orderId));
      dispatch<any>(getSingleProductReview(state?.orderId));

    
  }, [state]);


  let activityResponse = useSelector<RootState, RootState["activity"]>(
    activityResponseSelector
  );

  const [feedback, setFeedback] = useState({
    productRating: 1,
    sellerRating: 1,
    productFeedback: "P",
    productHeading: "",
    productDescription: "",
    sellerFeedback: "P",
    sellerHeading: "",
    sellerDescription: "",
  });

 
   const [review, setReview] = useState({
    id: "",
    OrderId: state?.orderId ? state?.orderId: "",
    star: 1,
    heading: "",
    description:""
  });


  useEffect(() => {
    
  if(activityResponse?.feedbackBuyer)
  {
    
    setFeedback({...feedback,
       productRating:activityResponse?.feedbackBuyer?.data?.productFeedback ? activityResponse?.feedbackBuyer?.data?.productFeedback[0]?.star :1,
    productHeading:activityResponse?.feedbackBuyer?.data?.productFeedback? activityResponse?.feedbackBuyer?.data?.productFeedback[0]?.heading : "",
    productDescription:activityResponse?.feedbackBuyer?.data?.productFeedback ? activityResponse?.feedbackBuyer?.data?.productFeedback[0]?.description :"",
    sellerRating:activityResponse?.feedbackBuyer?.data?.sellerFeedback ? activityResponse?.feedbackBuyer?.data?.sellerFeedback[0]?.star : 1,
    sellerHeading:activityResponse?.feedbackBuyer?.data?.sellerFeedback ? activityResponse?.feedbackBuyer?.data?.sellerFeedback[0]?.heading : "",
    sellerDescription:activityResponse?.feedbackBuyer?.data?.sellerFeedback ? activityResponse?.feedbackBuyer?.data?.sellerFeedback[0]?.description :"",
    })  }
    setReview({
...review,
id: activityResponse?.singleProductReview?.data?.ratingReviewId ? activityResponse?.singleProductReview?.data?.ratingReviewId.toString() :"",

star:activityResponse?.singleProductReview?.data?.star ? activityResponse?.singleProductReview?.data?.star :1,
heading:activityResponse?.singleProductReview?.data?.heading? activityResponse?.singleProductReview?.data?.heading : "",
description:activityResponse?.singleProductReview?.data?.description ? activityResponse?.singleProductReview?.data?.description :"",
    })
  
}, [activityResponse?.feedbackBuyer]);
useEffect(() => {
    
  if(activityResponse?.singleProductReview)
  {
    
  
    setReview({
...review,
id: activityResponse?.singleProductReview?.data?.ratingReviewId ? activityResponse?.singleProductReview?.data?.ratingReviewId.toString() :"",

star:activityResponse?.singleProductReview?.data?.star ? activityResponse?.singleProductReview?.data?.star :1,
heading:activityResponse?.singleProductReview?.data?.heading? activityResponse?.singleProductReview?.data?.heading : "",
description:activityResponse?.singleProductReview?.data?.description ? activityResponse?.singleProductReview?.data?.description :"",
    })
  }
}, [activityResponse?.singleProductReview]);
  // Catch Rating value
  const handleRating = (rate: number, type: string) => {
    setFeedback({ ...feedback, [type]: rate });
  };
  const handleSubmitProduct = () => {

    let formData = new FormData();
    formData.append("ratingAndReview", JSON.stringify(review));
    dispatch<any>(

      

    
      setProductReview(formData)
    );
  };
  const handleSubmitSeller = () => {
    dispatch<any>(
      buyerSetFeedback({
        productId: state.productId,
        orderId: state.orderId,
        star: feedback.sellerRating,
        heading: feedback.sellerHeading,
        description: feedback.sellerDescription,
        // feedback: feedback.sellerFeedback,
        feedbackForSeller: true,
      })
    );
  };

  useEffect(() => {
    if (activityResponse.saveDataError !== null) {
      notify(activityResponse.saveDataError, constant.ERROR);
    }
    // dispatch<any>(resetActivity());
  }, [activityResponse.saveDataError]);
  useEffect(() => {
    dispatch<any>(resetActivity());
  }, []);

  const handleEditProductFeedback =()=>{
    dispatch<any>(resetActivity("productFeedback"));
  }
  const handleEditSellerFeedback =()=>{
    dispatch<any>(resetActivity("sellerFeedback"));
  }
  return (
    <DefaultLayout>
      <BuyerHeader />
      <ToastContainer />

      <section className="brecrumb">
        <div className="container">
          <nav className="nav-2">
            <ul>
              <li>
                <Link to="/buyer/activity/purchaseHistory">Purchase history</Link>
              </li>
              <li>Leave feedback</li>
            </ul>
          </nav>
        </div>
      </section>
      <main className="min-section">
        <div className="container">
          <h1 className="sectionTitle mt-5">Leave feedback</h1>
          <div className="feedbackBlock">
            <div className="headerWrap">
              <div className="row">
                <div className="col-md-auto">
                  <div className="img">
                    <img
                      src={BASE_URLS.AWS_URL + "/Thumb" + state?.productImage}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = DefaultImage;
                      }}
                      alt=""
                    />
                  </div>
                </div>

                <div className="col-md-auto">
                  <div className="title">
                    <h2>{state?.productName}</h2>
                    <div className="condition">{state?.condition}
                    
                   {state?.variant && JSON.parse(state?.variant)?.map((product:any)=>(
                                  <p> {`${product.AttName} : ${product.Option}`} </p>
                                  ))}
                    </div>
                  </div>
                </div>

                <div className="col-md-auto">
                  <div className="order">
                    <div className="label">Order placed</div>
                    <div className="dated">
                      {moment(state?.orderDate).format("DD MMM YYYY")}
                    </div>
                  </div>
                </div>

                <div className="col-md-auto">
                  <div className="order">
                    <div className="label">Order ID</div>
                    <div className="order-id">( {state?.orderId} )</div>
                  </div>
                </div>

                <div className="col-md-auto ml-auto">
                  <div className="sellerInfo">
                    <div className="label">Seller</div>
                    <div className="sellerName">{state?.storeName}</div>
                  
                    <Link to={`/buyer/sellerStore/${state?.sellerId}`}>View seller profile </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="rateProd">
              <div className="row">
                <div className="col-xl-6">
                  {activityResponse.saveDataProduct === null ? (
                    <div className="rateProdBlock">
                      <h3>Rate product</h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="starRating d-flex">
                            <Rating
                              initialValue={review.star}
                              onClick={(rate) =>
                                setReview({
                                  ...review,
                                  star: rate,
                                })
                              }
                              size={30}
                            />
                          </div>
                        </div>
                    
                      </div>

                      <div className="inputRow">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Comment heading"
                          value={review.heading}
                          onChange={(e) =>
                            setReview({
                              ...review,
                              heading: e.target.value,
                            })
                          }
                        />
                        {review.heading === "" && (
                          <p style={{ color: "red" }}>*Required!</p>
                        )}
                        {review.heading.length > 100 && (
                          <p style={{ color: "red" }}>
                            Maximum 100 characters allowed
                          </p>
                        )}
                      </div>
                      <div className="inputRow">
                        <textarea
                          className="form-control"
                          placeholder="Description"
                          value={review.description}
                          maxLength={500}
                          onChange={(e) =>
                            setReview({
                              ...review,
                              description: e.target.value,
                            })
                          }
                        ></textarea>

                        <p>
                          {review.description === "" && (
                            <span style={{ color: "red" }}>*Required!</span>
                          )}{" "}
                          {review.description.length}/500
                        </p>
                      </div>
                      <div className="inputRow">
                        <button
                          className="submitFeed"
                          disabled={
                            review.heading === "" ||
                            review.description === "" ||
                            review.heading.length > 100
                              ? true
                              : false
                          }
                          onClick={() => handleSubmitProduct()}
                        >
                          {activityResponse?.singleProductReview?.data ?"Update " : "Submit "} Review
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="rateProdBlock">
                      <h3>Your Review Submitted</h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="starRating d-flex">
                            <Rating
                              initialValue={review.star}
                              readonly={true}
                              // onClick={(rate) =>
                              //   handleRating(rate, "productRating")
                              // }
                              /* Available Props */
                              size={30}
                            />
                          </div>
                        </div>
                      </div>

                      <p
                        className=" p-1 pl-2 ml-2 mt-3"
                        style={{
                          fontSize: "12px",
                          backgroundColor: "#90EE90",
                        }}
                      >
                        Thanks! Your feedback help us improve your Marketplace
                        experience
                      </p>
                      <div className="inputRow">
                        <button
                          className="submitFeed"
                        
                          onClick={() => handleEditProductFeedback()}
                        >
                          Edit feedback
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-xl-6">
                  {activityResponse.saveDataSeller === null ? (
                    <div className="rateProdBlock">
                      <h3>
                        Rate seller  <Link to={`/buyer/sellerStore/${state?.sellerId}`}>({state?.storeName}) </Link>
                        
                       
                      </h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="starRating d-flex">
                            <Rating
                              initialValue={feedback.sellerRating}
                              // readonly={true}
                              onClick={(rate) =>
                                handleRating(rate, "sellerRating")
                              }
                              /* Available Props */
                              size={30}
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="like">
                            <span
                              style={{
                                cursor: "pointer",
                              }}
                              className={`${
                                feedback.sellerFeedback === "P"
                                  ? "icon-like sel"
                                  : "icon-like"
                              }`}
                              onClick={() =>
                                setFeedback({
                                  ...feedback,
                                  sellerFeedback: "P",
                                })
                              }
                            ></span>
                            <span
                              style={{
                                cursor: "pointer",
                              }}
                              className={`${
                                feedback.sellerFeedback === "N"
                                  ? "icon-dislike sel"
                                  : "icon-dislike"
                              }`}
                              onClick={() =>
                                setFeedback({
                                  ...feedback,
                                  sellerFeedback: "N",
                                })
                              }
                            ></span>
                          </div>
                        </div> */}
                      </div>

                      <div className="inputRow">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Comment heading"
                          value={feedback.sellerHeading}
                          onChange={(e) =>
                            setFeedback({
                              ...feedback,
                              sellerHeading: e.target.value,
                            })
                          }
                        />
                        {feedback.sellerHeading === "" && (
                          <p style={{ color: "red" }}>*Required!</p>
                        )}
                        {feedback.sellerHeading.length > 100 && (
                          <p style={{ color: "red" }}>
                            Maximum 100 characters allowed
                          </p>
                        )}
                      </div>
                      <div className="inputRow">
                        <textarea
                          className="form-control"
                          placeholder="Description"
                          value={feedback.sellerDescription}
                          maxLength={500}
                          onChange={(e) =>
                            setFeedback({
                              ...feedback,
                              sellerDescription: e.target.value,
                            })
                          }
                        ></textarea>
                        <p>
                          {feedback.sellerDescription === "" && (
                            <span style={{ color: "red" }}>*Required!</span>
                          )}{" "}
                          {feedback.sellerDescription.length}/500
                        </p>
                      </div>
                      <div className="inputRow">
                        <button
                          className="submitFeed"
                          disabled={
                            feedback.sellerHeading === "" ||
                            feedback.sellerDescription === "" ||
                            feedback.sellerHeading.length > 100
                              ? true
                              : false
                          }
                          onClick={() => handleSubmitSeller()}
                        >
                          {activityResponse?.feedbackBuyer?.data?.sellerFeedback  ?"Update " : "Submit "} Feedback
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="rateProdBlock">
                      <h3>Your feedback submitted</h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="starRating d-flex">
                            <Rating
                              initialValue={feedback.sellerRating}
                              readonly={true}
                              // onClick={(rate) =>
                              //   handleRating(rate, "productRating")
                              // }
                              /* Available Props */
                              size={30}
                            />
                          </div>
                        </div>
                      </div>

                      <p
                        className=" p-1 pl-2 ml-2 mt-3"
                        style={{
                          fontSize: "12px",
                          backgroundColor: "#90EE90",
                        }}
                      >
                        Thanks! Your feedback help us improve your marketplace
                        experience
                      </p>
                      <div className="inputRow">
                        <button
                          className="submitFeed"
                        
                          onClick={() => handleEditSellerFeedback()}
                        >
                          Edit feedback
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
   <BuyerFooter/>
    </DefaultLayout>
  );
};

export default LeaveFeedback;
