import React, { useState, useEffect, useCallback, useRef } from "react";
import { ToastContainer } from "react-toastify";
import { DefaultLayout } from "../../layout/DefaultLayout";
import BuyerFooter from "../buyer/buyerFooter";
import BuyerHeader from "../buyer/BuyerHeader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../utils/types";
import { CommonResponseSelector } from "../../modules/common/selectors";
import { getSingleWebPage } from "../../modules/common/getSinglePage";
import { resetSinglePage } from "../../modules/common/resetSinglePage";
import Header from "../../components/header/Header";
import HeaderNormal from "../../components/header/HeaderNormal";

export const PrivacyPolicy = (props: any) => {
  

  const commonResponse = useSelector<RootState, RootState["common"]>(
    CommonResponseSelector
  );
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      dispatch<any>(resetSinglePage()).then(   dispatch<any>(getSingleWebPage("PrivacyPolicy")));
     
    }
    window.scrollTo(0, 0);
    isFirstRender.current = false;
  }, []);
  return (
    <DefaultLayout>
        {localStorage.getItem("role")==="Seller" && localStorage.getItem("storeNameLinked") !== null &&
        localStorage.getItem("storeAddressLinked") !== null &&
        localStorage.getItem("payPalAccountLinked") !== null ?  <Header />: localStorage.getItem("role")==="Seller"    ?<HeaderNormal/> :<BuyerHeader /> }
       
    <ToastContainer/>
    <section className="privacy-policy">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1 className="sectionTitle mt-5">Privacy policy</h1>
                <div className="page-block" dangerouslySetInnerHTML={{__html:commonResponse?.webSinglePage?.value[0]?.Html}}>

                </div>
              </div>
            </div>
          </div>
    </section>
    <BuyerFooter />
    </DefaultLayout>
  );
};
