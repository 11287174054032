import { AppThunk } from "../../../../store"
import {
    SaveMobileNoSuccess,
    SaveMobileNoFailed
} from "./slice"
import { updateMobileNoApi } from "./api";
import { EndProgress } from "../../../progressBar/endProgress";
import { StartProgress } from "../../../progressBar/startProgress";



export const updateMobileNo = (data: any): AppThunk => async (dispatch) => {

    dispatch<any>(StartProgress());
    try {

        const bannerData: any = await updateMobileNoApi(data)
        

        if (bannerData.success === "N") {
            
            dispatch(SaveMobileNoFailed(bannerData));
        }
        else {
           
            dispatch(SaveMobileNoSuccess(bannerData));
        }

    } catch (err) {
        
        
        dispatch(SaveMobileNoFailed(err));
    }
    dispatch<any>(EndProgress());
}