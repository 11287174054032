import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Categories } from "../../utils/types";

type ReturnBannerState = {
  error: any | null;
  categories: Categories["categories"] | null;
  allCategories: Categories["allCategories"] | null;
  category: Categories["category"] | null;
  saveData: Categories["saveData"] | null;
  categoryTree: Categories["categoryTree"] | null;
  template: Categories["template"] | null;
  sellerCategories: Categories["sellerCategories"] | null;
  categoryBuyerMenu: Categories["categoryBuyerMenu"] | null;
};

const initialState: ReturnBannerState = {
  error: null,
  categories: null,
  allCategories: null,
  category: null,
  saveData: null,
  categoryTree: null,
  template: null,
  sellerCategories: null,
  categoryBuyerMenu: null,
};

const slice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    resetCategorySuccess: (state) => {
      
      state.saveData = null;
      state.category = null;
      state.error = null;
      state.categoryTree = null;
      state.template = null;
    },
    resetCategoryData: (state) => {
      
      state.saveData = null;
      state.category = null;
      state.error = null;
      state.template = null;
    },
    resetSaveDataSuccess: (state) => {
      
      state.saveData = null;
      state.error = null;
    },
    CategorySuccess: (state, action: PayloadAction<any>) => {
      
      
      state.categories = action.payload;
      state.error = null;
    },
    CategoryFailed: (state, action: PayloadAction<any>) => {
      
      
      state.error = action.payload;
      state.categories = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },

    CategoryHeaderSuccess: (state, action: PayloadAction<any>) => {
      
      
      
      state.categoryBuyerMenu = action.payload;
      state.error = null;
    },
    CategoryHeaderFailed: (state, action: PayloadAction<any>) => {
      
      
      
      state.error = action.payload;
      state.categoryBuyerMenu = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
    SellerCategorySuccess: (state, action: PayloadAction<any>) => {
      
      
      
      state.sellerCategories = action.payload;
      state.error = null;
    },
    SellerCategoryFailed: (state, action: PayloadAction<any>) => {
      
      
      
      state.error = action.payload;
      state.sellerCategories = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
    AllCategorySuccess: (state, action: PayloadAction<any>) => {
      
      
      
      state.allCategories = action.payload;
      state.error = null;
    },
    AllCategoryFailed: (state, action: PayloadAction<any>) => {
      
      
      
      state.error = action.payload;
      state.allCategories = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
    CategoryTreeSuccess: (state, action: PayloadAction<any>) => {
      
      
      
      state.categoryTree = action.payload;
      state.error = null;
    },
    CategoryTreeFailed: (state, action: PayloadAction<any>) => {
      
      
      state.error = action.payload;
      state.categoryTree = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
    SingleCategorySuccess: (state, action: PayloadAction<any>) => {
      
      
      state.category = action.payload;
      state.saveData = null;
      state.error = null;
    },
    SingleCategoryFailed: (state, action: PayloadAction<any>) => {
      
      
      state.error = action.payload;
      state.category = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
    SaveCategorySuccess: (state, action: PayloadAction<any>) => {
      state.saveData = action.payload;
      state.error = null;
      
    },
    SaveCategoryFailed: (state, action: PayloadAction<any>) => {
      
      
      state.error = action.payload;
      state.saveData = action.payload;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
    SingleCategoryTemplateSuccess: (state, action: PayloadAction<any>) => {
      
      state.template = action.payload;
      state.saveData = null;
      state.error = null;
    },
    SingleCategoryTemplateFailed: (state, action: PayloadAction<any>) => {
      
      
      state.error = action.payload;
      state.template = null;
      var errorMessage = action.payload.message
        ? action.payload.message
        : "Something went wrong. Please try again";
      state.error = errorMessage;
    },
  },
});

export const categories = slice.reducer;

export const {
  CategorySuccess,
  CategoryFailed,
  AllCategorySuccess,
  AllCategoryFailed,
  CategoryTreeSuccess,
  CategoryTreeFailed,
  SingleCategorySuccess,
  SingleCategoryFailed,
  SaveCategorySuccess,
  SaveCategoryFailed,
  resetCategorySuccess,
  resetSaveDataSuccess,
  SellerCategoryFailed,
  SellerCategorySuccess,
  SingleCategoryTemplateSuccess,
  SingleCategoryTemplateFailed,
  resetCategoryData,
  CategoryHeaderFailed,
  CategoryHeaderSuccess,
} = slice.actions;
