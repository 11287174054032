import { AppThunk } from "../../../../store";
import { ReturnDetailsFailed, ReturnDetailsSuccess } from "./slice";
import { fetchOrderDetailBuyer, fetchPurchaseHistory, fetchReturnDetailBuyer } from "./api";
import { EndProgress } from "../../../progressBar/endProgress";
import { StartProgress } from "../../../progressBar/startProgress";

export const getRetunDetailBuyer =
  (params: any): AppThunk =>
  async (dispatch) => {
    dispatch<any>(StartProgress());
    try {
      const data: any = await fetchReturnDetailBuyer(params);
      

      if (data.success === "N") {
        
        dispatch(ReturnDetailsFailed(data));
      } else {
        
        dispatch(ReturnDetailsSuccess(data));
      }
    } catch (err) {
      
      dispatch(ReturnDetailsFailed(err));
    }
    dispatch<any>(EndProgress());
  };
