import { AppThunk } from "../../store"
import {
    progressStartSuccess,

} from "./slice"

export const StartProgress = (): AppThunk => async (dispatch) => {
    try {

        dispatch(progressStartSuccess());

    } catch (err) {
        
    }
}
